import { SupportedRedirectUrl } from "modules/connected-stores/utils/shopify-supported-redirect-url";
import Text from "ui/typography/Text";

import styles from "./ConnectStores.module.scss";
import ConnectStoresCard from "./ConnectStoresCard";

const requiredConnectedStores = [
  {
    type: "Shopify" as const,
    className: styles.shopify,
  },
  {
    type: "Amazon" as const,
    className: styles.amazon,
  },
];

const optionalConnectedStores = [
  {
    type: "Stripe" as const,
    className: styles.stripe,
  },
  {
    type: "PayPal" as const,
    className: styles.paypal,
  },
];

type ConnectStoreProps = {
  redirectPathKey: SupportedRedirectUrl;
};

const ConnectStores: React.FC<ConnectStoreProps> = ({ redirectPathKey }) => (
  <>
    <div className={styles.cardSubtext}>
      <Text size={14} weight="bold">
        Stores
      </Text>
      <Text size={14}>1 required</Text>
    </div>
    <div className={styles.container}>
      {requiredConnectedStores.map(({ type, className }) => (
        <ConnectStoresCard
          key={type}
          type={type}
          className={className}
          redirectPathKey={redirectPathKey}
        />
      ))}
    </div>

    <div className={styles.cardSubtext}>
      <Text size={14} weight="bold">
        Payment processors
      </Text>
      <Text size={14}>Optional</Text>
    </div>
    <div className={styles.container}>
      {optionalConnectedStores.map(({ type, className }) => (
        <ConnectStoresCard
          key={type}
          type={type}
          className={className}
          redirectPathKey={redirectPathKey}
        />
      ))}
    </div>
  </>
);

export default ConnectStores;
