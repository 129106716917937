import { ChatConfigRep, ChatConfigUpdateRep } from "modules/chat/reps/ChatConfigRep";
import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";

type ChatConfigApi = {
  getByBusiness: (businessGuid: string) => Promise<ChatConfigRep>;
  update: (chatConfigId: string, update: ChatConfigUpdateRep) => Promise<ChatConfigRep>;
};

const builder: ApiBuilder<ChatConfigApi> = (api) => ({
  getByBusiness: async (businessGuid) => {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/chat/chat-configs?${queryParams}`;
    return (await api.get<ChatConfigRep>(url))!;
  },
  update: async (chatConfigId, update) => {
    const url = `/chat/chat-configs/${chatConfigId}`;
    return (await api.patch<ChatConfigRep>(url, update))!;
  },
});

const useChatConfigApi = () => useBackendV2Api(builder);

export default useChatConfigApi;
