import { useIsBusinessActive } from "modules/business/queries/useBusiness";
import SettingsAccountingSoftwarePage from "pages/settings/SettingsAccountingSoftwarePage";
import SettingsBanksAndCardsPage from "pages/settings/SettingsBanksAndCardsPage";
import SettingsBusinessPage from "pages/settings/SettingsBusinessPage";
import SettingsPage from "pages/settings/SettingsPage";
import SettingsSecurityPage from "pages/settings/SettingsSecurityPage";
import SettingsStoresPage from "pages/settings/SettingsStoresPage";
import SettingsUsersPage from "pages/settings/SettingsUsersPage";
import { Route, Routes } from "react-router-dom";

const SettingsRoutes: React.FC = () => {
  const isBusinessActive = useIsBusinessActive();

  return (
    <Routes>
      <Route path="/" element={<SettingsPage />} />
      <Route path="/stores" element={<SettingsStoresPage />} />
      <Route path="/banks-and-cards" element={<SettingsBanksAndCardsPage />} />
      {isBusinessActive && <Route path="/business" element={<SettingsBusinessPage />} />}
      <Route path="/accounting-software" element={<SettingsAccountingSoftwarePage />} />
      <Route path="/users" element={<SettingsUsersPage />} />
      <Route path="/security" element={<SettingsSecurityPage />} />
    </Routes>
  );
};

export default SettingsRoutes;
