import { useSuspenseQuery } from "@tanstack/react-query";
import useQueryOptions from "utils/react-query/useQueryOptions";
import useRefreshQuery, { UseRefreshQueryOptions } from "utils/react-query/useRefreshQuery";

import useAccountingAccountApi from "../api/useAccountingAccountApi";

export const BASE_QUERY_KEY = "accounting-account";

// NB(lev): This query key does *not* share common components with the
// `useAccountingAccounts` query. Unlike `useAccountingAccounts`, this query
// is not parameterized by a business guid.
export const makeQueryKey = (accountingAccountId: string) => [
  BASE_QUERY_KEY,
  { accountingAccountId },
];

const useAccountingAccountQueryOptions = (accountingAccountId: string) => {
  const accountingAccountApi = useAccountingAccountApi();

  return useQueryOptions({
    queryKey: makeQueryKey(accountingAccountId),
    queryFn: () => accountingAccountApi.get(accountingAccountId),
  });
};

const useAccountingAccount = (accountingAccountId: string) => {
  const { data } = useSuspenseQuery(useAccountingAccountQueryOptions(accountingAccountId));
  return data;
};

export const useRefreshAccountingAccountQuery = (
  accountingAccountId: string,
  options: UseRefreshQueryOptions = {}
) => useRefreshQuery(makeQueryKey(accountingAccountId), options);

export default useAccountingAccount;
