import {
  ComponentPropsWithoutRef,
  FC,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  RefObject,
} from "react";
import cn from "utils/tailwind/cn";

import { BarChartProvider, useBarChartContext } from "./bar-chart-context";
import styles from "./BarChart.module.scss";

export type BarChartBarColor =
  | "black"
  | "green-disabled"
  | "green"
  | "grey-solid"
  | "grey-striped"
  | "orange-striped"
  | "purple"
  | "red-striped"
  | "yellow";

type BarChartBarProps = {
  color: BarChartBarColor;
  widthPercentage: number;
  className?: string;
  // Useful for ensuring that the bar is clearly visible if there is any value at all.
  minWidthPercentageWhenGreaterThanZero?: number;
  // Useful for ensuring that a bar is always visible.
  minWidthPercentageWhenZero?: number;
  barRef?: RefObject<HTMLDivElement>;
};

const BarChartBar: FC<BarChartBarProps> = ({
  className,
  color,
  widthPercentage,
  minWidthPercentageWhenGreaterThanZero = 1,
  minWidthPercentageWhenZero = 0,
  barRef,
}) => {
  const { height } = useBarChartContext();

  const widthStylePercentage =
    widthPercentage === 0
      ? Math.max(minWidthPercentageWhenZero, 0)
      : Math.max(minWidthPercentageWhenGreaterThanZero, widthPercentage);

  return (
    <div
      ref={barRef}
      className={cn(styles[`bar--${color}`], "peer", height > 12 ? "rounded" : "", className)}
      style={{ width: `${widthStylePercentage}%`, height: "100%" }}
    />
  );
};

export type BarChartBackgroundVariant = "grey-solid" | "grey-striped" | "transparent";

export type BarChartBorderVariant = "grey-light";

export type BarChartRoundedCornerVariant =
  | "rounded-full"
  | "rounded-l-full"
  | "rounded-r-full"
  | "rounded-none";

type BarChartProps = {
  height: number;
  // Defaults to `height`, but can be specified if you want the containing element to be larger than the bar, especially useful if you want an outline.
  barHeight?: number;
  border?: BarChartBorderVariant;
  backgroundVariant?: BarChartBackgroundVariant;
  children: ReactNode;
  className?: string;
  roundedCornerVariant?: BarChartRoundedCornerVariant;
} & ComponentPropsWithoutRef<"div">;

const BarChart: ForwardRefRenderFunction<HTMLDivElement, BarChartProps> = (
  {
    className,
    backgroundVariant = "grey-solid",
    height,
    barHeight = height,
    children,
    border,
    roundedCornerVariant = "rounded-full",
    ...divProps
  },
  ref
) => {
  const paddingY =
    (border
      ? height - barHeight - 2 // Account for border if enabled.
      : height - barHeight) / 2;
  const paddingX = border ? (height - barHeight) / 2 : 0;

  return (
    <BarChartProvider value={{ height }}>
      <div
        {...divProps}
        ref={ref}
        style={{
          height: height,
          paddingTop: paddingY,
          paddingBottom: paddingY,
          paddingLeft: paddingX,
          paddingRight: paddingX,
        }}
        className={cn(
          "flex items-center",
          border && "rounded-full border",
          border === "grey-light" && "border-grey-200",
          className
        )}
      >
        <div
          className={cn(
            "flex h-full w-full flex-1 gap-x-[2px] overflow-hidden",
            roundedCornerVariant,
            styles[`background--${backgroundVariant}`]
          )}
        >
          {children}
        </div>
      </div>
    </BarChartProvider>
  );
};

export default Object.assign(forwardRef(BarChart), {
  Bar: BarChartBar,
});
