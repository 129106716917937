import { FileText } from "@phosphor-icons/react";
import { FC } from "react";
import IconWithTooltip from "ui/overlay/IconWithTooltip";
import UnorderedList from "ui/typography/UnorderedList";
import cn from "utils/tailwind/cn";

type Props = {
  className?: string;
};

const VerifyYourBusinessWhatYoullNeed: FC<Props> = ({ className }) => {
  return (
    <div className={cn("text-sm", className)}>
      <div className="flex items-center gap-x-2 font-bold text-grey-800">
        <FileText className="text-grey-900" size={20} weight="light" /> Info you’ll need
      </div>
      <UnorderedList className="mt-4 text-grey-800">
        <UnorderedList.Item>
          <UnorderedList.Content>
            Employer identification number (EIN){" "}
            <IconWithTooltip tooltip="You can find this on your tax documents or W2, 1099 forms." />
          </UnorderedList.Content>
        </UnorderedList.Item>
        <UnorderedList.Item>
          <UnorderedList.Content>
            Social security number (SSN) or passport number
          </UnorderedList.Content>
        </UnorderedList.Item>
        <UnorderedList.Item>
          <UnorderedList.Content>
            Any shareholder with more than 25% ownership
          </UnorderedList.Content>
        </UnorderedList.Item>
      </UnorderedList>
    </div>
  );
};

export default VerifyYourBusinessWhatYoullNeed;
