import useBusiness from "modules/business/queries/useBusiness";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useResetRecoilState } from "recoil";
import isSuperuseringState, { useIsSuperusering } from "state/auth/isSuperusering";
import { Span } from "ui/typography";

const SuperuserBanner = () => {
  const isSuperusering = useIsSuperusering();
  const resetIsSuperusering = useResetRecoilState(isSuperuseringState);
  const business = useBusiness();
  const [isDismissed, setIsDismissed] = useState<boolean>(false);

  if (!isSuperusering || isDismissed) {
    return null;
  }

  const onExitSuperuser = () => {
    resetIsSuperusering();
    window.location.reload();
  };

  return (
    <div className="absolute z-[var(--superuser-z-index)] flex w-full items-center justify-between bg-[rgba(235,160,21,0.8)] px-1.5 text-xl leading-tight">
      <Span>Please be careful, you are viewing business {business.internalName}.</Span>
      <div className="flex items-center gap-x-1.5 text-sm text-grey-900">
        <Link to="/settings" className="mr-6 p-px leading-tight underline">
          Go to business settings
        </Link>

        <button onClick={onExitSuperuser} className="border border-black p-px hover:bg-white">
          Exit superuser
        </button>

        <button
          onClick={() => setIsDismissed(true)}
          className="border border-black p-px hover:bg-white"
        >
          Dismiss
        </button>
      </div>
    </div>
  );
};

export default SuperuserBanner;
