import {
  Address as UnitAddress,
  CreateInlinePaymentRequest,
  CreateVerifiedPaymentRequest,
  CreateWirePaymentRequest,
  CreateBookPaymentRequest,
} from "@highbeam/unit-node-sdk";
import BankAccountRep from "reps/BankAccountRep";
import BusinessRep from "reps/BusinessRep";
import {
  HighbeamBankAccountTransferOption,
  isDifferentHighbeamBankTransfer,
  isHighbeamBankAccount,
  isLargeDifferentHighbeamBankTransfer,
  isPlaidBankAccount,
  PlaidBankAccountTransferOption,
} from "utils/transfers";

import { Transfer } from "./TransferInfo/utils";

export const getInternalTransferPaymentRequest = (
  transfer: Transfer,
  idempotencyKey: string,
  bankAccountsByUnitId: Map<string, BankAccountRep.Complete>,
  business: BusinessRep.Complete,
  businessAddress: UnitAddress | null
): CreateBookPaymentRequest | CreateInlinePaymentRequest | CreateWirePaymentRequest => {
  const businessName = business.name!;
  const fromBankAccount = transfer.from! && bankAccountsByUnitId.get(transfer.from.value)!;
  const toBankAccount = transfer.to! && bankAccountsByUnitId.get(transfer.to.value)!;
  const amountInCents = transfer.amountInCents;

  const baseAttributes = {
    amount: amountInCents,
    idempotencyKey,
  };
  const baseCounterpartyDetails = {
    routingNumber: toBankAccount.routingNumber,
    accountNumber: toBankAccount.accountNumber,
    name: businessName,
  };
  const relationships = {
    account: {
      data: {
        type: "depositAccount",
        id: fromBankAccount.unitCoDepositAccountId,
      },
    },
  };

  if (isLargeDifferentHighbeamBankTransfer(transfer, bankAccountsByUnitId)) {
    return {
      type: "wirePayment",
      attributes: {
        ...baseAttributes,
        description: transfer.description,
        counterparty: {
          ...baseCounterpartyDetails,
          address: businessAddress!,
        },
      },
      relationships,
    };
  }

  if (isDifferentHighbeamBankTransfer(transfer, bankAccountsByUnitId)) {
    return {
      type: "achPayment",
      attributes: {
        ...baseAttributes,
        direction: "Credit",
        description: "Internal",
        addenda: transfer.description,
        counterparty: {
          ...baseCounterpartyDetails,
          accountType: "Checking",
        },
      },
      relationships,
    };
  }

  return {
    type: "bookPayment",
    attributes: {
      ...baseAttributes,
      direction: "Credit",
      description: transfer.description,
    },
    relationships: {
      ...relationships,
      counterpartyAccount: {
        data: {
          type: "depositAccount",
          id: toBankAccount.unitCoDepositAccountId,
        },
      },
    },
  };
};

export const getHighbeamAndCounterparty = (
  transfer: Transfer
): [HighbeamBankAccountTransferOption, PlaidBankAccountTransferOption] => {
  if (
    transfer.from &&
    isHighbeamBankAccount(transfer.from) &&
    transfer.to &&
    isPlaidBankAccount(transfer.to)
  ) {
    return [transfer.from!, transfer.to!];
  }

  if (
    transfer.from &&
    isPlaidBankAccount(transfer.from) &&
    transfer.to &&
    isHighbeamBankAccount(transfer.to)
  ) {
    return [transfer.to!, transfer.from!];
  }

  throw new Error("Invalid transfer: must have one highbeam account and one plaid account");
};

export const getConnectedAccountTransferPaymentRequest = (
  business: BusinessRep.Complete,
  transfer: Transfer,
  idempotencyKey: string,
  useUserDescription: boolean | number
): CreateVerifiedPaymentRequest => {
  const [highbeamAccount, counterpartyAccount] = getHighbeamAndCounterparty(transfer);

  const direction = isAchDebit(transfer) ? "Debit" : "Credit";
  const generatedAddenda =
    direction === "Debit" ? "Internal transfer to Highbeam" : "Internal transfer from Highbeam";
  const addenda = useUserDescription ? transfer.description : generatedAddenda;
  return {
    type: "achPayment",
    attributes: {
      amount: transfer.amountInCents,
      direction,
      counterpartyName: business.name!,
      plaidProcessorToken: counterpartyAccount.processorToken,
      sameDay: highbeamAccount.isThreadAccount,
      description: addenda,
      addenda,
      idempotencyKey,
    },
    relationships: {
      account: {
        data: {
          type: highbeamAccount.type,
          id: highbeamAccount.value,
        },
      },
    },
  };
};

export const getBankAccountGuids = (transfer: Transfer): string[] => {
  if (isConnectedAccountTransfer(transfer)) {
    const [highbeamAccount] = getHighbeamAndCounterparty(transfer);
    return [highbeamAccount.guid];
  }
  if (
    transfer.from &&
    transfer.to &&
    isHighbeamBankAccount(transfer.from) &&
    isHighbeamBankAccount(transfer.to)
  ) {
    return [transfer.from.guid, transfer.to.guid];
  }
  return [];
};

export const isAchDebit = (transfer: Transfer) =>
  transfer.from && isPlaidBankAccount(transfer.from);

export const isAchCredit = (transfer: Transfer) => transfer.to && isPlaidBankAccount(transfer.to);

export const isConnectedAccountTransfer = (transfer: Transfer) =>
  (transfer.from && isPlaidBankAccount(transfer.from)) ||
  (transfer.to && isPlaidBankAccount(transfer.to));
