import {
  CalendarBlank,
  Check,
  Coins,
  DotsThreeCircle,
  Question,
  XCircle,
} from "@phosphor-icons/react";
import dayjs from "dayjs";
import { FEATURE_FLAGS } from "flags";
import DashboardPage from "layouts/DashboardPage";
import CapitalFaqs from "modules/capital/components/CapitalFaqs";
import UpsellCapitalHighlightItem from "modules/capital/components/UpsellCapitalHighlightItem";
import ApplicationRequirementsModal from "modules/capital/dialogs/ApplicationRequirementsModal";
import StartCreditApplicationTeaser from "modules/credit-application/components/StartCreditApplicationTeaser";
import useMostRecentCreditApplication from "modules/credit-application/queries/useMostRecentCreditApplication";
import getHasPassedApplicationRequirements from "modules/credit-application/utils/hasPassedApplicationRequirements";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import CreditApplicationRep from "reps/CreditApplicationRep";
import colors from "styles/colors";
import Teaser from "ui/data-display/Teaser";
import Button from "ui/inputs/Button";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import ButtonLinkWithTooltip from "ui/inputs/ButtonLink/ButtonLinkWithTooltip";
import { Heading2, Paragraph } from "ui/typography";
import Text from "ui/typography/Text";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import useIsAllowedToAcceptLineOfCredit from "utils/permissions/useIsAllowedToAcceptLineOfCredit";
import useSearchParamBoolean from "utils/search-params/useSearchParamBoolean";

import styles from "./UpsellCapitalView.module.scss";

const NOT_ALLOWED_TO_APPLY_FOR_HIGHBEAM_CAPITAL_TOOLTIP_CONTENT = (
  <Paragraph>You don’t have permission to apply for Highbeam Capital.</Paragraph>
);

const StartApplicationButton = () => {
  const isAllowedToAcceptLineOfCredit = useIsAllowedToAcceptLineOfCredit();
  const mostRecentCreditApplication = useMostRecentCreditApplication();
  const navigate = useNavigate();
  const { segmentTrack } = useSegment();

  const [isApplicationRequirementsModalOpen, setIsApplicationRequirementsModalOpen] =
    useSearchParamBoolean("start-application", false);

  const isApplicationRequirementModalFlagEnabled = useFeatureFlag(
    FEATURE_FLAGS["credit-application-requirements-modal"]
  );

  const hasPassedApplicationRequirements = useMemo(() => {
    return mostRecentCreditApplication
      ? getHasPassedApplicationRequirements(mostRecentCreditApplication)
      : false;
  }, [mostRecentCreditApplication]);

  if (isApplicationRequirementModalFlagEnabled) {
    return (
      <>
        {isApplicationRequirementsModalOpen && (
          <ApplicationRequirementsModal
            onClose={() => setIsApplicationRequirementsModalOpen(false)}
            onSuccess={() => navigate("/capital/application")}
          />
        )}

        <>
          {hasPassedApplicationRequirements ? (
            <ButtonLinkWithTooltip
              variant="primary"
              to={"/capital/application"}
              disabled={!isAllowedToAcceptLineOfCredit}
              tooltip={
                !isAllowedToAcceptLineOfCredit &&
                NOT_ALLOWED_TO_APPLY_FOR_HIGHBEAM_CAPITAL_TOOLTIP_CONTENT
              }
            >
              Continue application
            </ButtonLinkWithTooltip>
          ) : (
            <ButtonWithTooltip
              variant="primary"
              onClick={() => {
                setIsApplicationRequirementsModalOpen(true);
                segmentTrack(SEGMENT_EVENTS.CREDIT_APPLICATION_REQUIREMENTS_MODAL_OPENED);
              }}
              disabled={!isAllowedToAcceptLineOfCredit}
              tooltip={
                !isAllowedToAcceptLineOfCredit &&
                NOT_ALLOWED_TO_APPLY_FOR_HIGHBEAM_CAPITAL_TOOLTIP_CONTENT
              }
            >
              Start application
            </ButtonWithTooltip>
          )}
        </>
      </>
    );
  } else {
    return (
      <ButtonLinkWithTooltip
        to={"/capital/application"}
        variant="primary"
        disabled={!isAllowedToAcceptLineOfCredit}
        tooltip={
          !isAllowedToAcceptLineOfCredit &&
          NOT_ALLOWED_TO_APPLY_FOR_HIGHBEAM_CAPITAL_TOOLTIP_CONTENT
        }
      >
        {mostRecentCreditApplication?.state === "New"
          ? "Continue application"
          : "Start application"}
      </ButtonLinkWithTooltip>
    );
  }
};

const ReviewProgressSection = () => {
  return (
    <div className={styles.progressTracker}>
      <div className={styles.step}>
        <div className={styles.stepNumberFirst}>
          <Check size={12} color={colors.white} />
        </div>
        <div className={styles.dividerPurple} />
        <div className={styles.stepNumberFirst}>2</div>
        <div className={styles.dividerStripes} />
        <div className={styles.stepNumberLast}>3</div>
      </div>

      <div className={styles.step}>
        <Text size={14} color={colors.grey[500]}>
          Applied
        </Text>
        <Text size={14} color={colors.purple[500]} weight="bold">
          In review
        </Text>
        <Text size={14} color={colors.grey[500]}>
          Offer
        </Text>
      </div>
    </div>
  );
};

const ReviewInProgressTeaser = () => (
  <Teaser className={styles.teaserContainerReview}>
    <Teaser.MainSection className={styles.teaserOuterSection}>
      <div className={styles.teaserMainSectionReview}>
        <div className={styles.inProgress}>
          <DotsThreeCircle color={colors.purple[500]} size={20} weight="thin" />
          <Text size={14} color={colors.purple[500]}>
            Review in progress
          </Text>
        </div>
        <div className={styles.teaserText}>
          <Heading2>We’re reviewing your application</Heading2>
          <Text size={16} color={colors.grey[500]}>
            We’ll reach out with any follow up questions. Otherwise, we’ll email you with your offer
            in <strong>8&ndash;10 business days</strong>.
          </Text>
        </div>
        <ReviewProgressSection />
      </div>
    </Teaser.MainSection>
  </Teaser>
);

type NotApprovedTeaserProps = {
  mostRecentCreditApplication: CreditApplicationRep.Complete;
};

const NotApprovedTeaser: FC<NotApprovedTeaserProps> = ({ mostRecentCreditApplication }) => {
  const submittedAtDate = mostRecentCreditApplication.submittedAt
    ? dayjs(mostRecentCreditApplication.submittedAt)
    : null;
  const reapplyDate = submittedAtDate ? submittedAtDate.add(60, "day") : null;
  const isPastReapplyDate = reapplyDate && reapplyDate.isBefore(dayjs());
  const intercomMessage = isPastReapplyDate
    ? "Hi, I would like to re-apply for Highbeam Capital."
    : "Hi, my credit application was not approved. Can you please help me understand why?";

  return (
    <Teaser className={styles.teaserContainerRejected}>
      <Teaser.MainSection className={styles.teaserOuterSection}>
        <div className={styles.rejected}>
          <XCircle color={colors.red[500]} size={20} weight="regular" />
          <Text size={14} color={colors.red[500]} weight="medium">
            Application not approved
          </Text>
        </div>
        <div className={styles.teaserText}>
          <Heading2>Sorry, we can’t provide you with a capital offer at this time</Heading2>
          {reapplyDate && (
            <div className={styles.teaserSubText}>
              <CalendarBlank size={24} color={colors.grey[900]} weight="light" />
              <Text size={16} color={colors.grey[900]}>
                You can re-apply any time after{" "}
                <strong>{reapplyDate.format("MMMM DD, YYYY")}</strong>
              </Text>
            </div>
          )}
        </div>
        <Button
          variant="tertiary"
          className={styles.teaserButton}
          onClick={() => {
            Intercom("showNewMessage", intercomMessage);
          }}
        >
          <Question size={24} />
          {isPastReapplyDate ? "Get in touch" : "Learn why"}
        </Button>
      </Teaser.MainSection>
    </Teaser>
  );
};

const UpsellCapitalTeaser: FC = () => {
  const mostRecentCreditApplication = useMostRecentCreditApplication();

  switch (mostRecentCreditApplication?.state) {
    case "RejectionNotified":
    case "Rejected":
      return <NotApprovedTeaser mostRecentCreditApplication={mostRecentCreditApplication} />;
    case undefined:
    case "New":
    case "OfferRejected":
    case "OfferExpired":
    case "Canceled":
      return (
        <StartCreditApplicationTeaser
          showInProgressBadge={mostRecentCreditApplication?.state === "New"}
          startApplicationButton={<StartApplicationButton />}
        />
      );
    case "Submitted":
      return <ReviewInProgressTeaser />;
    case "Activated":
    case "ApprovalProcessed":
    case "Approved":
    case "OfferAccepted":
    case "OfferNotified":
    case "PayoutsConnected":
      return <ReviewInProgressTeaser />;
  }
};

const UpsellCapitalView = () => {
  return (
    <>
      <DashboardPage.Header>
        <DashboardPage.Header.IconTile icon={<Coins />} />
        <DashboardPage.Header.Title>Capital</DashboardPage.Header.Title>
      </DashboardPage.Header>

      <DashboardPage.Section>
        <UpsellCapitalTeaser />
      </DashboardPage.Section>

      <DashboardPage.Section>
        <DashboardPage.Section.Header heading="Capital products" />
        <div className={styles.highlightItemsContainer}>
          <UpsellCapitalHighlightItem type="line-of-credit" className={styles.highlightItemCard} />
          <UpsellCapitalHighlightItem type="advance" className={styles.highlightItemCard} />
        </div>
      </DashboardPage.Section>

      <DashboardPage.Section>
        <DashboardPage.Section.Header heading="Highbeam Card" />
        <div className={styles.highlightItemsContainer}>
          <UpsellCapitalHighlightItem type="cash-card" className={styles.highlightItemCard} />
          <UpsellCapitalHighlightItem type="flex-card" className={styles.highlightItemCard} />
          <UpsellCapitalHighlightItem type="extend-card" className={styles.highlightItemCard} />
        </div>
      </DashboardPage.Section>

      <DashboardPage.Section>
        <DashboardPage.Section.Header heading="FAQs" />

        <CapitalFaqs sections={["general", "highbeam-cards"]} />
      </DashboardPage.Section>
    </>
  );
};

export default UpsellCapitalView;
