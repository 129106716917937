import useBusiness from "modules/business/queries/useBusiness";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";

import { useReferralLinkApi } from "../api/useReferralLinkApi";

type Params = {
  referralSlugOrGuid: string | null;
};

const referralLinkQueryHooks = makeQueryHooksV2({
  useQueryFnParams: () => {
    const referralLinkApi = useReferralLinkApi();
    return { referralLinkApi };
  },
  makeQueryKey: (params: Params) => {
    return ["referralLink", params];
  },
  makeQueryFn: ({ referralLinkApi }, { referralSlugOrGuid }) => {
    return () => {
      if (!referralSlugOrGuid) {
        return null;
      }
      return referralLinkApi.get(referralSlugOrGuid);
    };
  },
});

const useReferral = (params: Params) => {
  return referralLinkQueryHooks.useData({ params });
};

const useReferralReceivedByBusiness = () => {
  const business = useBusiness();
  return useReferral({ referralSlugOrGuid: business.referralLinkGuid });
};

export { useReferral, useReferralReceivedByBusiness };
