import { ArrowRight } from "@phosphor-icons/react";
import env from "env";
import useSignUpWithRedirect from "modules/auth/hooks/useSignUpWithRedirect";
import { FC, useState } from "react";
import Card from "ui/data-display/Card";
import Button from "ui/inputs/Button";
import { Heading1, Heading4, Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";

import ReferralLinkRep from "../../reps/ReferralLinkRep";

type Props = {
  referral: ReferralLinkRep;
  referrerAvatar?: string;
  className?: string;
};

const DefaultReferralCard: FC<Props> = ({ referral, referrerAvatar, className }) => {
  const signUpWithRedirect = useSignUpWithRedirect();
  const [ctaPending, setCtaPending] = useState(false);
  const acceptInvite = () => {
    setCtaPending(true);
    signUpWithRedirect({
      referralLinkSlug: referral.slug,
    });
  };

  return (
    <Card shadow="xs" className={cn("w-full max-w-2xl", className)}>
      <div className="flex flex-col items-center justify-center p-8">
        {Boolean(referrerAvatar) && (
          <img src={referrerAvatar} alt={referral.slug} className="w-10 rounded-lg" />
        )}
        <Heading1 className="mb-1 mt-4">
          {referral.senderName ? <>{referral.senderName} invited you</> : <>You’ve been invited</>}{" "}
          to join Highbeam.
        </Heading1>

        <Paragraph className="max-w-xl text-center text-sm text-grey-600">
          Highbeam is the business banking solution designed for ecommerce brands.
          <br />
          Accept your invite and skip the waitlist.
        </Paragraph>

        <Button
          variant="primary"
          className="mb-4 mt-6"
          onClick={acceptInvite}
          isLoading={ctaPending}
        >
          Join Highbeam
          <ArrowRight size={20} />
        </Button>
      </div>

      <div
        className={cn("flex flex-col gap-8 gap-y-12 rounded-b-lg bg-grey-50 p-8 tablet:flex-row")}
      >
        <div className="flex basis-1/2 flex-col items-center justify-between gap-y-6">
          <div className="text-center">
            <Heading4 className="mb-1">Want to learn more?</Heading4>
            <Paragraph className="text-sm text-grey-600">
              Visit our website to learn more about how Highbeam helps ecommerce businesses with
              banking, credit, and cash management.
            </Paragraph>
          </div>
          <a
            href={env.HIGHBEAM_MARKETING_SITE_URL}
            target="_blank"
            rel="noreferrer"
            className="w-full tablet:w-auto"
          >
            <Button variant="tertiary" className="w-full" disabled={ctaPending}>
              Learn more
            </Button>
          </a>
        </div>

        <div className="flex basis-1/2 flex-col items-center justify-between gap-y-6">
          <div className="text-center">
            <Heading4 className="mb-1">Want to chat?</Heading4>
            <Paragraph className="text-sm text-grey-600">
              Schedule a demo to learn more about our solutions and see if Highbeam is a fit for
              your business.
            </Paragraph>
          </div>
          <a
            className="w-full tablet:w-auto"
            href={env.CALENDLY_DEMO_LINK}
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="tertiary" className="w-full" disabled={ctaPending}>
              Schedule a call
            </Button>
          </a>
        </div>
      </div>
    </Card>
  );
};

export default DefaultReferralCard;
