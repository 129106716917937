import { Feedback } from "modules/chat/reps/ExchangeRep";
import { ComponentRef, forwardRef } from "react";
import colors from "styles/colors";
import ThumbIcon from "ui/icons/ThumbIcon";
import Button from "ui/inputs/Button";
import cn from "utils/tailwind/cn";

type Props = {
  className?: string;
  value: Feedback | null;
  onChange: (feedback: Feedback | null) => void;
  isLoading?: boolean;
};

const UserFeedbackButtons = forwardRef<ComponentRef<"div">, Props>(
  ({ className, onChange, value, isLoading, ...props }, forwardedRef) => {
    const selectedFeedback = value?.value ?? null;

    return (
      <div className={className} ref={forwardedRef} {...props}>
        <div className="flex items-center">
          <Button
            aria-label="Rate positive"
            size="sm"
            variant="plain"
            className="mr-2 text-grey-800 hover:bg-grey-50"
            onClick={() =>
              selectedFeedback === "Positive"
                ? onChange(null)
                : onChange({ value: "Positive", reason: null })
            }
            paddingVariant="bare"
            disabled={isLoading}
          >
            <ThumbIcon
              color={selectedFeedback === "Positive" ? colors.grey[900] : colors.grey[500]}
              direction="up"
              size={20}
              selected={selectedFeedback === "Positive"}
              className={cn(selectedFeedback === "Positive" ? "text-grey-900" : "text-grey-600")}
            />
          </Button>
          <Button
            aria-label="Rate negative"
            size="sm"
            variant="plain"
            className="text-grey-800 hover:bg-grey-50"
            onClick={() =>
              selectedFeedback === "Negative"
                ? onChange(null)
                : onChange({ value: "Negative", reason: null })
            }
            paddingVariant="bare"
            disabled={isLoading}
          >
            <ThumbIcon
              color={selectedFeedback === "Negative" ? colors.grey[900] : colors.grey[500]}
              direction="down"
              size={20}
              selected={selectedFeedback === "Negative"}
              className={cn(selectedFeedback === "Negative" ? "text-grey-900" : "text-grey-600")}
            />
          </Button>
        </div>
      </div>
    );
  }
);

export default UserFeedbackButtons;
