import useQueryOptions from "utils/react-query/useQueryOptions";

import useInsightsCategoryApi from "../api/useInsightsCategoryApi";

const useInsightsCategoriesQueryOptions = () => {
  const insightsCategoryApi = useInsightsCategoryApi();

  return useQueryOptions({
    queryKey: ["insights-categories"],
    queryFn: () => {
      return insightsCategoryApi.search();
    },
  });
};

export { useInsightsCategoriesQueryOptions };
