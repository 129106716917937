import { ApiBuilder, useBackendV1Api } from "modules/highbeam-api/base";
import BankAccountRep from "reps/BankAccountRep";

export type BankAccountApi = {
  create: (creation: BankAccountRep.Creation) => Promise<BankAccountRep.Complete>;
  get: (accountGuid: string) => Promise<BankAccountRep.Complete | null>;
  getByBusiness: (businessGuid: string) => Promise<BankAccountRep.Complete[]>;
  close: (accountGuid: string) => Promise<BankAccountRep.Complete>;
  update: (
    accountGuid: string,
    update: BankAccountRep.Update
  ) => Promise<BankAccountRep.Complete | null>;
  migrateToThread: (update: BankAccountRep.MigrateToThreadUpdate) => Promise<void>;
};

const builder: ApiBuilder<BankAccountApi> = (api) => ({
  create: async (creation) => {
    const url = `/bank-accounts`;
    return (await api.post<BankAccountRep.Complete>(url, creation))!;
  },
  get: async (accountGuid) => {
    const url = `/bank-accounts/${accountGuid}`;
    return await api.get<BankAccountRep.Complete>(url);
  },
  getByBusiness: async (businessGuid) => {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/bank-accounts?${queryParams.toString()}`;
    return (await api.get<BankAccountRep.Complete[]>(url))!;
  },
  close: async (accountGuid) => {
    const url = `/bank-accounts/${accountGuid}/close`;
    return (await api.post<BankAccountRep.Complete>(url))!;
  },
  update: async (accountGuid, update) => {
    const url = `/bank-accounts/${accountGuid}`;
    return (await api.patch<BankAccountRep.Complete>(url, update))!;
  },
  migrateToThread: async (update) => {
    const qp = new URLSearchParams({
      ...(update.accountNamePostfix ? { accountNamePostfix: update.accountNamePostfix } : {}),
      businessGuid: update.businessGuid,
      ...(update.copyAllAccounts ? { copyAllAccounts: update.copyAllAccounts.toString() } : {}),
    });
    const url = `/bank-accounts/migrate?${qp.toString()}`;
    await api.post<void>(url);
  },
});

const useBankAccountApi = () => useBackendV1Api(builder);

export default useBankAccountApi;
