import React from "react";

/**
 * Mostly copied over from Radix's `createContext` wrapper, with a couple of modifications.
 * - We preserve the underlying `Context.Provider`.
 * - We default the `consumerName` to `rootComponentName`.
 *
 * Radix's `createContext` code here: https://github.com/radix-ui/primitives/blob/main/packages/react/context/src/createContext.tsx
 */

const createComponentContext = <ContextValueType extends object | null>(
  rootComponentName: string,
  defaultContext?: ContextValueType
) => {
  const Context = React.createContext<ContextValueType | undefined>(defaultContext);

  const Provider: React.FC<React.ComponentProps<typeof Context.Provider>> = (props) => {
    return <Context.Provider {...props} />;
  };

  Provider.displayName = rootComponentName + "Provider";

  const useContext = (consumerName: string = rootComponentName) => {
    const context = React.useContext(Context);
    if (context) return context;
    if (defaultContext !== undefined) return defaultContext;
    // if a defaultContext wasn't specified, it's a required context.
    throw new Error(`\`${consumerName}\` must be used within \`${rootComponentName}\``);
  };

  return [Provider, useContext] as const;
};

export default createComponentContext;
