import { captureMessage } from "@sentry/react";
import { useSuspenseQuery } from "@tanstack/react-query";
import Fuse from "fuse.js";
import { useCounterpartyReportQueryOptions } from "modules/insights/queries/useCounterpartyReport";
import { useInsightsCategoriesQueryOptions } from "modules/insights/queries/useInsightsCategories";
import CounterpartyReportRowRep from "modules/insights/reps/CounterpartyReportRowRep";
import InsightsCategoryRep from "modules/insights/reps/InsightsCategoryRep";
import { useMemo } from "react";
import { Merge } from "type-fest";
import { isNotNull } from "utils/array";

import { useCashFlowContext } from "../providers/CashFlowProvider";
import getCounterpartyReportQueryParamsBySelectedDuration from "../utils/getCounterpartyReportQueryParamsBySelectedDuration";

// NB(alex) 3/11/2025: This is an old pattern, please do not copy it.

// NB(alex): Still not sure what pattern to use for merging query data, please don't assume this is a good pattern.
export type CashFlowTableDatum = Merge<
  CounterpartyReportRowRep.Complete,
  {
    // This replaces the `category` field in the matching `InsightsCategoryRep.Complete` object.
    category: InsightsCategoryRep.Complete;
  }
> & {
  // This is needed for `TableV2`. We should design `TableV3` to not need this.
  key: string;
};

const useCashFlowTableData = (): CashFlowTableDatum[] => {
  const { activeTab, duration, search, showUncategorizedOnly } = useCashFlowContext();

  // NB(alex): We can optimize this if we want, but this is fine for now -- just note this isn't necessarily the best way to handle merging 2 queries (and I'm still not sure what the best way is :P)
  const { data: insightsCategoriesByValue } = useSuspenseQuery({
    ...useInsightsCategoriesQueryOptions(),
    select: (insightsCategories) => {
      return new Map(
        insightsCategories.map((insightsCategory) => [insightsCategory.value, insightsCategory])
      );
    },
  });

  const { data: counterpartyReport } = useSuspenseQuery(
    useCounterpartyReportQueryOptions(getCounterpartyReportQueryParamsBySelectedDuration(duration))
  );

  const counterpartyReportRowsWithInsightsCategories = useMemo(() => {
    const rows = counterpartyReport.rows.map((counterpartyReportRow, index) => {
      const insightsCategoryMatch =
        insightsCategoriesByValue.get(counterpartyReportRow.category) ?? null;

      if (!insightsCategoryMatch) {
        // There should always be a match, but if there isn't, we report it and filter it out.
        captureMessage(
          `ALERT(alex): Insights category not found for category: ${counterpartyReportRow.category}`
        );
        return null;
      }

      return {
        ...counterpartyReportRow,
        key: `${counterpartyReportRow.counterparty}-${index}`,
        category: insightsCategoryMatch,
      };
    });

    const rowsWithoutNulls = rows.filter(isNotNull);

    const rowsSortedByImportance = rowsWithoutNulls.sort((a, b) => {
      return b.importance - a.importance;
    });

    return rowsSortedByImportance;
  }, [counterpartyReport, insightsCategoriesByValue]);

  const rowsFilteredByTab = useMemo(() => {
    return counterpartyReportRowsWithInsightsCategories.filter((row) => {
      if (activeTab === "money-in") {
        return (
          row.category.groupDirectionValue === "In" ||
          (Number(row.amount.amount) > 0 &&
            ["Bidirectional", "Uncategorized"].includes(row.category.groupDirectionValue))
        );
      } else {
        return (
          row.category.groupDirectionValue === "Out" ||
          (Number(row.amount.amount) <= 0 &&
            ["Bidirectional", "Uncategorized"].includes(row.category.groupDirectionValue))
        );
      }
    });
  }, [counterpartyReportRowsWithInsightsCategories, activeTab]);

  const filteredResults = useMemo(() => {
    const rowsWithUncategorizedOnly = showUncategorizedOnly
      ? rowsFilteredByTab.filter((row) => {
          return row.category.value === "Uncategorized";
        })
      : rowsFilteredByTab;

    if (!search) {
      return rowsWithUncategorizedOnly;
    }

    const fuse = new Fuse(rowsWithUncategorizedOnly, {
      threshold: 0.3,
      keys: ["counterparty", "category.value", "category.categoryDisplayName"],
      shouldSort: false,
    });

    return fuse.search(search).map((result) => result.item);
  }, [rowsFilteredByTab, search, showUncategorizedOnly]);

  return filteredResults;
};

export default useCashFlowTableData;
