import BusinessMemberSummaryRep from "reps/BusinessMemberSummaryRep";

const useShouldShowAddNewBusiness = (businessSummaries: BusinessMemberSummaryRep.Complete[]) => {
  const hasAtLeastOneActiveBusiness = businessSummaries.some(
    (businessSummary) => businessSummary.businessStatus === "Active"
  );
  const hasNoBusinesses = businessSummaries.length === 0;
  return hasAtLeastOneActiveBusiness || hasNoBusinesses;
};

export default useShouldShowAddNewBusiness;
