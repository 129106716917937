import { actionItemsQueryHooks } from "./useActionItems";

const useHasSkippedOnboarding = () => {
  return actionItemsQueryHooks.useData({
    params: {
      milestone: "Onboarding",
    },
    select: (items) => {
      return items.some((item) => item.name === "VerifyBusiness");
    },
  });
};

export { useHasSkippedOnboarding };
