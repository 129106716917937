import { Children, FC, ReactNode } from "react";
import PlatformAvatar, { PlatformAvatarVariant } from "ui/icons/PlatformAvatar";
import { Span } from "ui/typography";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

export type PlatformAvatarGroupOverlapSide = "left-over-right" | "right-over-left";

type Props = {
  children: ReactNode;
  variant?: PlatformAvatarVariant;
  overlapSide?: PlatformAvatarGroupOverlapSide;
  className?: string;
  truncateAt?: number;
};

const PlatformAvatarGroup: FC<Props> = ({
  children,
  variant = "circle",
  overlapSide = "left-over-right",
  className,
  truncateAt,
}) => {
  const childrenArray = Children.toArray(children);

  const numChildren = childrenArray.length;

  const truncatedChildrenArray =
    truncateAt && numChildren - truncateAt >= 1
      ? childrenArray.slice(0, truncateAt - 1)
      : childrenArray;

  const remainingChildrenCount = childrenArray.length - truncatedChildrenArray.length;

  if (remainingChildrenCount > 0) {
    truncatedChildrenArray.push(
      <PlatformAvatar variant={variant}>
        <Span className="-ml-0.5 block text-xs font-medium leading-none text-[#aaaaaa]">
          {remainingChildrenCount <= 9 ? (
            // NB(lev): Insert a non-breaking space to prevent the "+" from getting cut off.
            <>&nbsp;+{remainingChildrenCount}</>
          ) : (
            <>{remainingChildrenCount}</>
          )}
        </Span>
      </PlatformAvatar>
    );
  }

  return (
    <div className={cn("flex items-center", className)}>
      {Children.map(truncatedChildrenArray, (child, index) => {
        const smallGreyCircleClasses = cn(
          "rounded-full border-4 border-white",
          index === 0 ? "-ml-1" : "-ml-2.5",
          index === childrenArray.length - 1 && "-mr-1"
        );
        return (
          <div
            className={cn(
              variants(variant, {
                circle: "-ml-1.5",
                "rounded-square": "-ml-3.5",
                "small-grey-circle": smallGreyCircleClasses,
                "small-grey-circle-interactive": smallGreyCircleClasses,
              })
            )}
            style={{
              zIndex: variants(overlapSide, {
                "left-over-right": childrenArray.length - index,
                "right-over-left": index,
              }),
            }}
            key={index}
          >
            {child}
          </div>
        );
      })}
    </div>
  );
};

export default PlatformAvatarGroup;
