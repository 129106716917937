import { UploadSimple } from "@phosphor-icons/react";
import { FC, ReactNode } from "react";
import Banner from "ui/data-display/Banner";
import IconTile from "ui/icons/IconTile";

type Props = {
  button: ReactNode;
  className?: string;
};

const UploadDocumentsToFinishVerificationBanner: FC<Props> = ({ button, className }) => {
  return (
    <Banner
      color="white"
      className={className}
      icon={<IconTile className="bg-purple-50" icon={<UploadSimple weight="light" />} />}
      title="Upload documents to finish verification"
      paragraph="Please upload your documents so we can verify your business."
      button={button}
    />
  );
};

export default UploadDocumentsToFinishVerificationBanner;
