import { CalculatorChatElementRep } from "modules/chat/reps/ChatElementRep";
import React, { ReactNode } from "react";
import { Heading4 } from "ui/typography";
import cn from "utils/tailwind/cn";

import CashConversionCycleCalculator from "./CashConversionCycleCalculator";
import YieldCalculator from "./YieldCalculator";

type Props = {
  element: CalculatorChatElementRep;
};

const Calculator: React.FC<Props> = ({ element }) => {
  switch (element.calculator.type) {
    case "CashConversionCycle":
      return <CashConversionCycleCalculator calculator={element.calculator} />;
    case "Yield":
      return <YieldCalculator calculator={element.calculator} />;
  }
};

type FrameProps = {
  children: ReactNode;
};

const Frame: React.FC<FrameProps> = ({ children }) => {
  return (
    <div className="flex flex-col gap-3 rounded-lg border border-grey-200 p-6 pt-4">{children}</div>
  );
};

type TitleProps = {
  icon?: ReactNode;
  title: string;
};

const Title: React.FC<TitleProps> = ({ icon, title }) => {
  return (
    <div className="flex h-11 flex-row items-center gap-3">
      {icon}
      <Heading4>{title}</Heading4>
    </div>
  );
};

type SectionProps = {
  children: ReactNode;
  className?: string;
};

const Section: React.FC<SectionProps> = ({ children, className }) => {
  return <div className={cn("flex flex-col", className)}>{children}</div>;
};

type RowProps = {
  left?: ReactNode;
  right?: ReactNode;
};

const Row: React.FC<RowProps> = ({ left, right }) => {
  return (
    <div className="flex flex-row items-center gap-4 border-b border-grey-50 py-3">
      {left && <div>{left}</div>}
      {right && <div className="ml-auto">{right}</div>}
    </div>
  );
};

type RowContentProps = {
  children: ReactNode;
  className?: string;
};

const RowContent: React.FC<RowContentProps> = ({ children, className }) => {
  return <div className={cn("flex flex-row items-center gap-2", className)}>{children}</div>;
};

export default Object.assign(Calculator, { Frame, Title, Section, Row, RowContent });
