import { Phone } from "@phosphor-icons/react";
import BlockInfo from "components/BlockInfo";
import HighbeamCard from "components/HighbeamCard";
import useCard from "modules/cards/queries/useCard";
import { getCardBillingAddress } from "modules/cards/utils";
import getCardSupportNumber from "modules/cards/utils/getCardSupportNumber";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import InfoIcon from "ui/icons/InfoIcon";
import ModalV4 from "ui/overlay/ModalV4";
import Text from "ui/typography/Text";
import { formatDate } from "utils/date";

type Props = {
  onClose: () => void;
  cardId: string;
  amount: number;
  date: string;
  vendor: string;
};

const InstructionRow: React.FC<{ number: number; children: React.ReactNode }> = ({
  number,
  children,
}) => (
  <div className="flex items-center justify-center gap-3 py-2">
    <Text
      size={14}
      color={colors.grey[800]}
      weight="medium"
      className="bg-gray-100 inline-block h-8 min-w-[32px] self-baseline rounded-full text-center leading-8"
    >
      {number}
    </Text>
    <Text size={14} color={colors.grey[800]}>
      {children}
    </Text>
  </div>
);

const CardDisputeInstructionModal: React.FC<Props> = ({
  onClose,
  cardId,
  amount,
  date,
  vendor,
}) => {
  const card = useCard({ cardId, required: true });
  const address = getCardBillingAddress(card);

  return (
    <ModalV4 onClose={onClose}>
      <ModalV4.Form onSubmit={(_e, { closeModalWithAnimation }) => closeModalWithAnimation()}>
        <ModalV4.Header>Dispute card purchase</ModalV4.Header>
        <ModalV4.Body>
          <Text size={16} className="mb-4">
            Please follow these instructions to contact Visa support and dispute your card purchase.
          </Text>
          <div className="flex flex-col items-start gap-2">
            <InstructionRow number={1}>
              Be prepared to share these transaction and card details
              <div className="mt-4">
                <div className="mb-4">
                  <BlockInfo>
                    <BlockInfo.Key>Amount</BlockInfo.Key>
                    <BlockInfo.Value>
                      <MoneyAmount cents={amount} direction="negative" />
                    </BlockInfo.Value>
                    <BlockInfo.Key>Vendor</BlockInfo.Key>
                    <BlockInfo.Value>{vendor}</BlockInfo.Value>
                    <BlockInfo.Key>Date</BlockInfo.Key>
                    <BlockInfo.Value>{formatDate(date)}</BlockInfo.Value>
                  </BlockInfo>
                </div>
                <HighbeamCard card={card} clickable={false} showDetailsButton />
              </div>
            </InstructionRow>

            <InstructionRow number={2}>
              Call Visa support at <Phone size={16} className="ml-1 mr-1 pt-1" />{" "}
              {getCardSupportNumber(card)}
            </InstructionRow>

            <InstructionRow number={3}>
              A temporary credit may be applied to your account to allow for further transactions
              while the case is in review.
            </InstructionRow>

            <InstructionRow number={4}>
              After review, Visa will either approve or deny your dispute and will send a
              confirmation letter to the billing address at{" "}
              {address && (
                <Text weight="medium" className="inline">
                  {address.street2 ? `${address.street}, ${address.street2}` : `${address.street}`}{" "}
                  {`${address.city}, ${address.state} ${address.postalCode}`}
                </Text>
              )}
            </InstructionRow>

            <div className="flex items-center justify-center gap-3 py-2">
              <Text size={16} color={colors.grey[800]} weight="medium" className="ml-2">
                <InfoIcon size={20} variant="info" />
              </Text>
              <Text size={14} color={colors.grey[800]}>
                Visa may not be able to dispute your transaction if they’re unable to locate the
                transaction, or if it is not a card purchase.
              </Text>
            </div>
          </div>
        </ModalV4.Body>
        <ModalV4.Footer>
          <ModalV4.SubmitButton>Done</ModalV4.SubmitButton>
        </ModalV4.Footer>
      </ModalV4.Form>
    </ModalV4>
  );
};

export default CardDisputeInstructionModal;
