import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";

type Params = {
  businessGuid: string;
};

export const insightsSheetQueryHooks = makeQueryHooksV2({
  useQueryFnParams: () => {
    const highbeamApi = useHighbeamApi();
    return { highbeamApi };
  },
  makeQueryKey: (params: Params) => {
    return ["insightsSheets", params] as const;
  },
  makeQueryFn: ({ highbeamApi }, { businessGuid }) => {
    return () => highbeamApi.insightsSheet.getPrimarySheet(businessGuid);
  },
});

export const useInsightsSheet = (params: Params) => {
  return insightsSheetQueryHooks.useData({ params });
};
