import { captureMessage } from "@sentry/react";
import BankAccountBar from "modules/bank-accounts/components/BankAccountBar";
import { useBankAccountByUnitAccountId } from "modules/bank-accounts/queries/useBankAccount";
import useUnitCoCheckDeposit from "modules/unit-co-check-deposits/queries/useUnitCheckDeposit";
import { FC } from "react";

type Props = {
  checkDepositId: string;
};

const CheckDepositFlexpaneBankAccountParty: FC<Props> = ({ checkDepositId }) => {
  const checkDeposit = useUnitCoCheckDeposit({ checkDepositId });
  const bankAccount = useBankAccountByUnitAccountId(checkDeposit.relationships.account.data.id);

  if (!bankAccount) {
    // TODO(alex): Required but not found error?
    captureMessage(`Bank account not found for check deposit ${checkDeposit.id}`);
    return null;
  }

  return <BankAccountBar bankAccount={bankAccount} />;
};

export default CheckDepositFlexpaneBankAccountParty;
