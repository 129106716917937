import TransactionFlexpaneAmount from "components/common/transaction-flexpane/TransactionFlexpaneAmount";
import useUnitCoCheckDeposit from "modules/unit-co-check-deposits/queries/useUnitCheckDeposit";
import { FC } from "react";
import Flexpane from "ui/overlay/Flexpane";

type Props = {
  checkDepositId: string;
};

const CheckDepositFlexpaneAmountSection: FC<Props> = ({ checkDepositId }) => {
  const checkDeposit = useUnitCoCheckDeposit({ checkDepositId });

  return (
    <Flexpane.Section>
      <TransactionFlexpaneAmount cents={checkDeposit.attributes.amount} direction="positive" />
    </Flexpane.Section>
  );
};

export default CheckDepositFlexpaneAmountSection;
