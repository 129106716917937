import { ComponentPropsWithoutRef, FC } from "react";
import Button from "ui/inputs/Button";

import { SupportedRedirectUrl } from "../utils/shopify-supported-redirect-url";
import useConnectShopifyStore from "../utils/useConnectShopifyStore";

type Props = Omit<ComponentPropsWithoutRef<typeof Button>, "children" | "onClick"> & {
  storeName: string;
  redirectPathKey: SupportedRedirectUrl;
};

const ShopifyStoreUpdatePermissionsButton: FC<Props> = ({
  storeName,
  redirectPathKey,
  ...props
}) => {
  const { connectShopifyStore, isLoading } = useConnectShopifyStore();

  return (
    <Button
      variant="secondary"
      className="w-full"
      isLoading={isLoading}
      {...props}
      onClick={() =>
        connectShopifyStore({
          storeName,
          redirectPathKey,
        })
      }
    >
      Update permissions
    </Button>
  );
};

export default ShopifyStoreUpdatePermissionsButton;
