import { FEATURE_FLAGS } from "flags";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import { ActionItemStep } from "modules/action-items/reps/ActionItemRep";
import { useIsBusinessActive } from "modules/business/queries/useBusiness";
import useIsAllowedToNavigateToChatRoutes from "modules/chat/hook/useIsAllowedToNavigateToChatRoutes";
import {
  suggestedQuestionsQueryHooks,
  useHasSuggestedQuestionsPermission,
} from "modules/chat/queries/useSuggestedQuestions";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import VerifyBusinessBannerRenderer from "modules/onboarding/components/verify-business/VerifyBusinessBannerRenderer";
import {
  DEFAULT_ACTIVE_TAB,
  usePayoutInstructionsModal,
} from "pages/PayoutInstructionModal/state/payoutInstructionsModalState";
import { Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

import AccountsOverviewBalance from "./components/AccountsOverviewBalance";
import AccountsOverviewBalanceLoading from "./components/AccountsOverviewBalanceLoading";
import AccountsOverviewBalanceSection from "./components/AccountsOverviewBalanceSection";
import AccountsOverviewCleaningTransactionsRenderer from "./components/AccountsOverviewCleaningTransactionsRenderer";
import AccountsOverviewPageHeader from "./components/AccountsOverviewPageHeader";
import AiAnalystNudgeSection from "./components/AiAnalystNudgeSection";
import BankAccountsSection from "./components/BankAccountsSection";
import CapitalAccountsSection from "./components/CapitalAccountsSection";
import ConnectedStoresSection from "./components/ConnectedStoresSection";
import PlaidBankAccountsSection from "./components/PlaidBankAccountsSection";
import PlaidCreditCardsSection from "./components/PlaidCreditCardsSection";

const AccountsOverviewPageContent = () => {
  const isAllowedToNavigateToChatRoutes = useIsAllowedToNavigateToChatRoutes();

  const hasSuggestedQuestionsPermission = useHasSuggestedQuestionsPermission();
  const { data: suggestedQuestions = [] } = suggestedQuestionsQueryHooks.useQuery({
    params: {
      useCase: "accounts-overview",
      limit: 4,
    },
    enabled: hasSuggestedQuestionsPermission,
  });
  const hasSuggestedQuestions = suggestedQuestions.length > 0;

  const isNewBalanceChartEnabled = useFeatureFlag(
    FEATURE_FLAGS["home-page-new-total-balance-chart"]
  );

  const isBusinessActive = useIsBusinessActive();

  return (
    <>
      <AccountsOverviewPageHeader />

      {!isBusinessActive && <VerifyBusinessBannerRenderer className="mt-8" />}

      {isNewBalanceChartEnabled && (
        <Suspense fallback={null}>
          <AccountsOverviewCleaningTransactionsRenderer className="mt-8" />
        </Suspense>
      )}

      {isNewBalanceChartEnabled ? (
        <Suspense fallback={<AccountsOverviewBalanceLoading className="mt-14" />}>
          <AccountsOverviewBalance className="mt-14" />
        </Suspense>
      ) : (
        <AccountsOverviewBalanceSection />
      )}

      {isAllowedToNavigateToChatRoutes && hasSuggestedQuestions && (
        <Suspense fallback={null}>
          <AiAnalystNudgeSection suggestedQuestions={suggestedQuestions} />
        </Suspense>
      )}

      <BankAccountsSection />

      <CapitalAccountsSection />

      <PlaidBankAccountsSection />

      <PlaidCreditCardsSection />

      <ConnectedStoresSection />
    </>
  );
};

type LocationState = {
  fromStep: ActionItemStep;
};

const AccountsOverviewPage = () => {
  const locationState = useLocation().state as LocationState | undefined;
  const { open: openPayoutInstructionsModal } = usePayoutInstructionsModal();

  useEffect(() => {
    if (locationState?.fromStep === "ReceiveStorePayouts") {
      openPayoutInstructionsModal({ activeTab: DEFAULT_ACTIVE_TAB });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState]);

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>Accounts</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <AccountsOverviewPageContent />
      </DashboardPage>
    </>
  );
};

export default AccountsOverviewPage;
