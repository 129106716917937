import usePayeeClassCodeApi from "modules/accounting-class-codes/api/usePayeeClassCodeApi";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { getInternationalWireCountry } from "pages/SendMoneyPage/internationalWires";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import PayeeRep from "reps/PayeeRep";
import { internationalAddressState } from "state/payments/international/address";
import { internationalBankingInfoState } from "state/payments/international/bankingInfo";
import { internationalEntityState } from "state/payments/international/entity";
import { isLocalPaymentState } from "state/payments/international/isLocalPayment";
import { isValidInternationalPaymentState } from "state/payments/international/isValidInternationalPayment";
import { quoteCurrencyState } from "state/payments/international/quoteCurrency";
import { selectedBankCountryOptionState } from "state/payments/international/selectedBankCountry";
import { adressState } from "state/payments/international/usAdress";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { EntityIndividual } from "utils/entity";

import { CreatePayeeFormContext, createPayeeFormFields } from "./CreatePayeeForm";
import styles from "./CreatePayeePage.module.scss";

const ActionsSection = () => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();
  const navigate = useNavigate();
  const currency = useRecoilValue(quoteCurrencyState);
  const form = useContext(CreatePayeeFormContext);
  const isLocalPayment = useRecoilValue(isLocalPaymentState);
  const [address] = useRecoilState(adressState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [address1] = useRecoilState(internationalAddressState);
  const [internationalBankingInfo] = useRecoilState(internationalBankingInfoState);
  const selectedBankCountryOption = useRecoilValue(selectedBankCountryOptionState);
  const payeeClassCodeApi = usePayeeClassCodeApi();

  const cancel = () => {
    navigate(-1);
  };
  const entity = useRecoilValue(internationalEntityState);

  const save = () => {
    const fields = createPayeeFormFields(form);

    let isValid = fields.every((field) => field.isValid); // eslint-disable-line functional/no-let
    if (!isValid) {
      fields.forEach((field) => {
        field.setTouched();
      });
      notify("warning", "Please correct the errors above, then try again.");
    }

    const ach = form.paymentInfo.ach;
    const achValues = [ach.accountNumber.value, ach.routingNumber.value];
    const hasAch = achValues.every(Boolean);
    const isAchInfoSectionValid = hasAch || !achValues.some(Boolean);
    ach.hasError[1](!isAchInfoSectionValid);
    if (!isAchInfoSectionValid) {
      isValid = false;
    }

    const wire = form.paymentInfo.wire;
    const wireValues = [
      wire.accountNumber.value,
      wire.routingNumber.value,
      address.addressLine1,
      address.city,
      address.state,
      address.zipCode,
    ];

    const isValidEntity = Boolean(
      entity.entityType === EntityIndividual ? entity.firstName : entity.companyName
    );

    const hasWire = wireValues.every(Boolean);
    const isValidInternational = isValidEntity && isValidInternationalPaymentState;
    const isWireInfoSectionValid = hasWire || !wireValues.some(Boolean) || isValidInternational;
    wire.hasError[1](!isWireInfoSectionValid);
    if (!isWireInfoSectionValid) {
      isValid = false;
    }
    if (!isValid) return;

    const country = getInternationalWireCountry(address1.country?.value ?? "");
    const paymentType = isLocalPayment ? country.local.deliveryMethod : "Priority";

    setIsSubmitting(true);
    (async () => {
      const formObject: PayeeRep.Creation = {
        name: form.payee.name.value,
        emailAddress: form.payee.emailAddress.value || undefined,
        phone: form.payee.phoneNumber.value || undefined,
        defaultChartOfAccountId: form.payee.defaultChartOfAccountId.value || undefined,
        address: hasWire
          ? {
              addressLine1: address.addressLine1!.value,
              addressLine2: address.addressLine2 ? address.addressLine2 : undefined,
              city: address.city,
              state: address.state!.value,
              zipCode: address.zipCode,
              country: address.country?.value!,
            }
          : undefined,
        achTransferMethod: hasAch
          ? {
              routingNumber: ach.routingNumber.value,
              accountNumber: ach.accountNumber.value,
            }
          : undefined,
        wireTransferMethod: hasWire
          ? {
              routingNumber: wire.routingNumber.value,
              accountNumber: wire.accountNumber.value,
            }
          : undefined,
        internationalWireTransferMethod: isValidInternational
          ? {
              type: paymentType,
              entity: { ...entity },
              address: {
                addressLine1: address1.addressLine1!.value,
                addressLine2: address1.addressLine2 ? address1.addressLine2 : undefined,
                city: address1.city,
                state: address1.state?.value ?? "",
                zipCode: address1.zipCode,
                country: address1.country?.value!,
              },
              bankCountry: selectedBankCountryOption?.value,
              accountNumber: internationalBankingInfo.accountNumber?.value,
              bankCode: internationalBankingInfo.bankCode?.value,
              branchCode: internationalBankingInfo.branchCode?.value,
              bsbCode: internationalBankingInfo.bsbCode?.value,
              clabe: internationalBankingInfo.clabe?.value,
              cnaps: internationalBankingInfo.cnaps?.value,
              iban: internationalBankingInfo.iban?.value,
              ifsc: internationalBankingInfo.ifsc?.value,
              sortCode: internationalBankingInfo.sortCode?.value,
              swift: internationalBankingInfo.swift?.value,
              currency: currency.value,
            }
          : undefined,
      };

      try {
        const { guid: payeeGuid } = await highbeamApi.payee.create(businessGuid, formObject);
        const classCodeId = form.payee.classCodeId.value;
        if (classCodeId) {
          await payeeClassCodeApi.upsert({
            payeeGuid,
            businessGuid,
            classCodeId,
          });
        }
        notify("success", "Payee created");
        navigate(`/payments/payees/${payeeGuid}`);
      } catch {
        notify("error", "Something went wrong! Please try again.");
      } finally {
        setIsSubmitting(false);
      }
    })();
  };

  return (
    <div className={styles.actionsContainer}>
      <Button variant="tertiary" disabled={isSubmitting} onClick={cancel}>
        Cancel
      </Button>

      <Button variant="primary" disabled={isSubmitting} onClick={save}>
        Save
      </Button>
    </div>
  );
};

export default ActionsSection;
