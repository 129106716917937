import TransactionFlexpaneAmount from "components/common/transaction-flexpane/TransactionFlexpaneAmount";
import TransactionFlexpaneParties from "components/common/transaction-flexpane/TransactionFlexpaneParties";
import TransactionFlexpaneStatus from "components/common/transaction-flexpane/TransactionFlexpaneStatus";
import dayjs from "dayjs";
import { FC } from "react";
import MetadataList from "ui/data-display/MetadataList";
import Flexpane from "ui/overlay/Flexpane";
import { Heading3, Span } from "ui/typography";
import { formatBankingDate } from "utils/date";

import { useNormalizedAccountsAugmented } from "../queries/useNormalizedAccountsAugmented";
import { EnrichedTransactionRep } from "../reps/EnrichedTransactionRep";

import EnrichedTransactionCounterpartyBar from "./EnrichedTransactionCounterpartyBar";
import InsightsCategoryIcon from "./InsightsCategoryIcon/InsightsCategoryIcon";
import NormalizedAccountAugmentedBar from "./NormalizedAccountRepBar/NormalizedAccountAugmentedBar";

type EnrichedTransactionFlexpaneToFromSectionContentProps = {
  enrichedTransaction: EnrichedTransactionRep;
};

const EnrichedTransactionFlexpaneToFromSectionContent: FC<
  EnrichedTransactionFlexpaneToFromSectionContentProps
> = ({ enrichedTransaction }) => {
  const normalizedAccountsAugmented = useNormalizedAccountsAugmented();
  const normalizedAccountAugmented = normalizedAccountsAugmented.find(
    (na) => na.id === enrichedTransaction.normalizedAccountId
  );

  const amountInCents = Number(enrichedTransaction.amount.amount) * 100;

  const accountBar = (
    <Span className="text-sm font-medium text-grey-800">
      {normalizedAccountAugmented ? (
        <NormalizedAccountAugmentedBar normalizedAccountAugmented={normalizedAccountAugmented} />
      ) : (
        <>
          {enrichedTransaction.accountSubtype}
          {" •• "}
          {enrichedTransaction.accountMaskDigits}
        </>
      )}
    </Span>
  );

  const counterpartyBar = (
    <EnrichedTransactionCounterpartyBar enrichedTransaction={enrichedTransaction} />
  );

  return (
    <TransactionFlexpaneParties
      from={
        <>
          {amountInCents > 0 ? counterpartyBar : accountBar}
          <Span className="text-sm text-grey-500">
            {formatBankingDate(enrichedTransaction.date)}
          </Span>
        </>
      }
      status={
        <TransactionFlexpaneStatus
          methodName={
            // Hack
            normalizedAccountAugmented?.type === "Credit" ? "Card transaction" : "Transaction"
          }
          pillColor="green-100"
          status="Completed"
        />
      }
      to={amountInCents > 0 ? accountBar : counterpartyBar}
    />
  );
};

type EnrichedTransactionFlexpaneContentProps = {
  enrichedTransaction: EnrichedTransactionRep;
};

const EnrichedTransactionFlexpaneContent: FC<EnrichedTransactionFlexpaneContentProps> = ({
  enrichedTransaction,
}) => {
  const amountInCents = Number(enrichedTransaction.amount.amount) * 100;

  return (
    <>
      <Flexpane.Section>
        <EnrichedTransactionFlexpaneToFromSectionContent
          enrichedTransaction={enrichedTransaction}
        />
      </Flexpane.Section>

      <Flexpane.Section>
        <TransactionFlexpaneAmount
          cents={amountInCents}
          direction={amountInCents > 0 ? "positive" : "negative"}
        />
      </Flexpane.Section>

      <Flexpane.Section>
        <Heading3>Details</Heading3>

        <MetadataList>
          <MetadataList.Item>
            <MetadataList.ItemLabel>Source</MetadataList.ItemLabel>
            <MetadataList.ItemValue>{enrichedTransaction.transactionSource}</MetadataList.ItemValue>
          </MetadataList.Item>
          {enrichedTransaction.at && (
            <MetadataList.Item>
              <MetadataList.ItemLabel>Timestamp</MetadataList.ItemLabel>
              <MetadataList.ItemValue>
                {dayjs(enrichedTransaction.at).format("MMM D, YYYY [at] h:mm A")}
              </MetadataList.ItemValue>
            </MetadataList.Item>
          )}
          <MetadataList.Item>
            <MetadataList.ItemLabel>Category</MetadataList.ItemLabel>
            <MetadataList.ItemValue>
              <InsightsCategoryIcon
                className="mr-1.5 text-grey-800"
                size={20}
                category={enrichedTransaction.category.value}
              />
              {enrichedTransaction.category.categoryDisplayName}
              {" / "}
              {enrichedTransaction.category.subcategoryDisplayName}
            </MetadataList.ItemValue>
          </MetadataList.Item>
          <MetadataList.Item>
            <MetadataList.ItemLabel>To / From</MetadataList.ItemLabel>
            <MetadataList.ItemValue>{enrichedTransaction.counterparty}</MetadataList.ItemValue>
          </MetadataList.Item>
          <MetadataList.Item>
            <MetadataList.ItemLabel>Bank descriptor</MetadataList.ItemLabel>
            <MetadataList.ItemValue>{enrichedTransaction.rawDescriptor}</MetadataList.ItemValue>
          </MetadataList.Item>
        </MetadataList>
      </Flexpane.Section>
    </>
  );
};

type Props = {
  enrichedTransaction?: EnrichedTransactionRep;
  onClose: () => void;
};

const EnrichedTransactionFlexpane: FC<Props> = ({ enrichedTransaction, onClose }) => {
  return (
    <Flexpane isOpen={Boolean(enrichedTransaction)} onClose={onClose}>
      {enrichedTransaction && (
        <>
          <Flexpane.Header onClose={onClose}>Transaction info</Flexpane.Header>
          <EnrichedTransactionFlexpaneContent enrichedTransaction={enrichedTransaction} />
        </>
      )}
    </Flexpane>
  );
};

export default EnrichedTransactionFlexpane;
