import { ApiBuilder, useBackendV1Api } from "modules/highbeam-api/base";

type AuthorizedUserApi = {
  create: (businessGuid: string, userGuid: string) => Promise<void>;
};

const builder: ApiBuilder<AuthorizedUserApi> = (api) => ({
  create: async (businessGuid, userGuid) => {
    const url = `/businesses/${businessGuid}/authorized-users/${userGuid}`;
    await api.put<void>(url);
  },
});

const useAuthorizedUserApi = () => useBackendV1Api(builder);

export default useAuthorizedUserApi;
