import { ParagraphChatElementRep } from "modules/chat/reps/ChatElementRep";
import React, { createContext, useContext } from "react";
import { Paragraph } from "ui/typography";

import ChatElement from ".";

type ParagraphChatElementContextValue = {
  isInParagraph: boolean;
};

const ParagraphChatElementContext = createContext<ParagraphChatElementContextValue>({
  isInParagraph: false,
});

export const useParagraphChatElementContext = () => {
  return useContext(ParagraphChatElementContext);
};

type Props = {
  element: ParagraphChatElementRep;
};

const ParagraphChatElement: React.FC<Props> = ({ element }) => {
  return (
    <ParagraphChatElementContext.Provider value={{ isInParagraph: true }}>
      <Paragraph>
        {element.content.map((element, i) => (
          <ChatElement key={i} element={element} />
        ))}
      </Paragraph>
    </ParagraphChatElementContext.Provider>
  );
};

export default ParagraphChatElement;
