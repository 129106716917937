import {
  ComponentRef,
  CSSProperties,
  forwardRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { composeRefs } from "utils/react-helpers/compose-refs";
import cn from "utils/tailwind/cn";

type SharedProps = {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
};

const ScrollWithInsetShadowVertical = forwardRef<ComponentRef<"div">, SharedProps>(
  ({ children, className, style }, ref) => {
    const [isAtTop, setIsAtTop] = useState(true);
    const [isAtBottom, setIsAtBottom] = useState(false);

    const scrollRef = useRef<HTMLDivElement | null>(null);
    const topMarkerRef = useRef<HTMLDivElement | null>(null);
    const bottomMarkerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      const topMarker = topMarkerRef.current;
      const bottomMarker = bottomMarkerRef.current;

      const observerOptions: IntersectionObserverInit = {
        root: scrollRef.current,
        threshold: 0.1, // Fires when at least 10% of the marker is visible
      };

      const observerCallback: IntersectionObserverCallback = (entries) => {
        entries.forEach((entry) => {
          if (entry.target === topMarker) {
            setIsAtTop(entry.isIntersecting);
          } else if (entry.target === bottomMarker) {
            setIsAtBottom(entry.isIntersecting);
          }
        });
      };

      const observer = new IntersectionObserver(observerCallback, observerOptions);

      if (topMarker) observer.observe(topMarker);
      if (bottomMarker) observer.observe(bottomMarker);

      // Cleanup the observer on component unmount
      return () => {
        if (topMarker) observer.unobserve(topMarker);
        if (bottomMarker) observer.unobserve(bottomMarker);
      };
    }, []);

    return (
      <div
        ref={composeRefs(ref, scrollRef)}
        className={cn(
          "overflow-auto",
          isAtTop && "shadow-inset-b",
          isAtBottom && "shadow-inset-t",
          !isAtTop && !isAtBottom && "shadow-inset-y",
          isAtTop && isAtBottom && "shadow-none",
          className
        )}
        style={style}
      >
        <div ref={topMarkerRef} aria-hidden="true" />
        {children}
        <div ref={bottomMarkerRef} aria-hidden="true" />
      </div>
    );
  }
);

const ScrollWithInsetShadowHorizontal = forwardRef<ComponentRef<"div">, SharedProps>(
  ({ children, className, style }, ref) => {
    const [isAtLeft, setIsAtLeft] = useState(true);
    const [isAtRight, setIsAtRight] = useState(false);

    const scrollRef = useRef<HTMLDivElement | null>(null);
    const leftMarkerRef = useRef<HTMLDivElement | null>(null);
    const rightMarkerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      const leftMarker = leftMarkerRef.current;
      const rightMarker = rightMarkerRef.current;

      const observerOptions: IntersectionObserverInit = {
        root: scrollRef.current,
        threshold: 0.1, // Fires when at least 10% of the marker is visible
      };

      const observerCallback: IntersectionObserverCallback = (entries) => {
        entries.forEach((entry) => {
          if (entry.target === leftMarker) {
            // console.log("entry.isIntersecting", entry.isIntersecting);
            setIsAtLeft(entry.isIntersecting);
          } else if (entry.target === rightMarker) {
            setIsAtRight(entry.isIntersecting);
          }
        });
      };

      const observer = new IntersectionObserver(observerCallback, observerOptions);

      if (leftMarker) observer.observe(leftMarker);
      if (rightMarker) observer.observe(rightMarker);

      return () => {
        if (leftMarker) observer.unobserve(leftMarker);
        if (rightMarker) observer.unobserve(rightMarker);
      };
    }, []);

    return (
      <div
        ref={composeRefs(ref, scrollRef)}
        className={cn(
          "flex overflow-auto",
          isAtLeft && "shadow-inset-r",
          isAtRight && "shadow-inset-l",
          !isAtLeft && !isAtRight && "shadow-inset-x",
          isAtLeft && isAtRight && "shadow-none",
          className
        )}
        style={style}
      >
        <div ref={leftMarkerRef} aria-hidden="true" className="pointer-events-none" />
        <div className="flex-1">{children}</div>
        <div
          ref={rightMarkerRef}
          aria-hidden="true"
          className="pointer-events-none ml-[-1px] w-px" // NB(alex): Doesn't work for our table without adding the width for some reason. Unsure if there's a more elegant way to achieve this but it seems to work.
        />
      </div>
    );
  }
);

export type ScrollWithInsetShadowOrientation = "vertical" | "horizontal";

type Props = SharedProps & {
  orientation?: ScrollWithInsetShadowOrientation;
};

const ScrollWithInsetShadow = forwardRef<ComponentRef<"div">, Props>(
  ({ orientation = "vertical", ...sharedProps }, ref) => {
    switch (orientation) {
      case "vertical":
        return <ScrollWithInsetShadowVertical {...sharedProps} ref={ref} />;
      case "horizontal":
        return <ScrollWithInsetShadowHorizontal {...sharedProps} ref={ref} />;
    }
  }
);

export default ScrollWithInsetShadow;
