import { Bank } from "@phosphor-icons/react";
import { ComponentProps, FC } from "react";
import AccountBar from "ui/data-display/AccountBar";

import { EnrichedTransactionRep } from "../reps/EnrichedTransactionRep";

import InsightsCategoryIcon from "./InsightsCategoryIcon/InsightsCategoryIcon";

type Props = Pick<ComponentProps<typeof AccountBar>, "className" | "size"> & {
  enrichedTransaction: EnrichedTransactionRep;
};

const EnrichedTransactionCounterpartyBar: FC<Props> = ({
  enrichedTransaction,
  size = 18,
  ...props
}) => {
  return (
    <AccountBar
      icon={
        enrichedTransaction.counterpartyLogoUrl ? (
          <img
            src={enrichedTransaction.counterpartyLogoUrl}
            alt=""
            crossOrigin="anonymous"
            width={size}
            height={size}
            className="shrink-0"
          />
        ) : enrichedTransaction.category.value === "Uncategorized" ? (
          <Bank />
        ) : (
          <InsightsCategoryIcon
            category={enrichedTransaction.category.value}
            size={size}
            className="shrink-0"
          />
        )
      }
      accountName={enrichedTransaction.counterparty}
      size={size}
      {...props}
    />
  );
};

export default EnrichedTransactionCounterpartyBar;
