import PlaidInstitutionLogo from "modules/plaid/components/PlaidInstitutionLogo";
import React from "react";
import PaymentSwitcherAutoPaymentGroupRep from "reps/PaymentSwitcherAutoPaymentGroupRep";
import Text from "ui/typography/Text";

import styles from "./AccountHeader.module.scss";

type Props = {
  group: PaymentSwitcherAutoPaymentGroupRep;
};

const AccountHeader: React.FC<Props> = ({ group }) => {
  return (
    <div className={styles.container}>
      <PlaidInstitutionLogo institutionId={group.accountInstitutionId} />
      <Text as="p" size={14} weight="bold">
        {group.transactionSource}
      </Text>
    </div>
  );
};

export default AccountHeader;
