import { FC } from "react";
import UserRoleRep from "reps/UserRoleRep";

const UserRoleOptionDescription: FC<{ type: UserRoleRep.UserRoleType }> = ({ type }) => {
  switch (type) {
    case "Admin":
      return (
        <>
          Can send money to anyone, create bills, create cards, apply for credit, and manage other
          users.
        </>
      );
    case "AccountsPayable":
    case "Bookkeeper":
      return <>Can create bills, view transactions, and submit payment requests for approval.</>;
    case "Employee":
      return (
        <>
          Can make purchases, upload receipts, and view transactions for all cards assigned to them.
        </>
      );
    case "Custom":
      return <></>;
  }
};

export default UserRoleOptionDescription;
