import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";

import ExchangeRep, { ExchangeCreatorRep, ExchangeUpdateRep } from "../reps/ExchangeRep";

type ExchangeApi = {
  searchChannel: (channelId: string, latestExclusive: string | null) => Promise<ExchangeRep[]>;
  searchThread: (channelId: string, parentId: string) => Promise<ExchangeRep[]>;
  create: (creator: ExchangeCreatorRep) => Promise<ExchangeRep>;
  update: (exchangeId: string, update: ExchangeUpdateRep) => Promise<ExchangeRep>;
  delete: (exchangeId: string) => Promise<ExchangeRep>;
  deleteByChannel: (channelId: string) => Promise<void>;
};

const builder: ApiBuilder<ExchangeApi> = (api) => ({
  searchChannel: async (channelId, latestExclusive) => {
    const queryParams = new URLSearchParams({ channelId });
    if (latestExclusive) queryParams.set("latestExclusive", latestExclusive);
    const url = `/chat/exchanges?${queryParams}`;
    return (await api.get(url))!;
  },

  searchThread: async (channelId, parentId) => {
    const queryParams = new URLSearchParams({ channelId, parentId });
    const url = `/chat/exchanges?${queryParams}`;
    return (await api.get(url))!;
  },

  create: async (creator) => {
    const url = "/chat/exchanges";
    return (await api.post(url, creator))!;
  },

  update: async (exchangeId, update) => {
    const url = `/chat/exchanges/${exchangeId}`;
    return (await api.patch(url, update))!;
  },

  delete: async (exchangeId) => {
    const url = `/chat/exchanges/${exchangeId}`;
    return (await api.delete(url))!;
  },

  deleteByChannel: async (channelId) => {
    const queryParams = new URLSearchParams({ channelId });
    const url = `/chat/exchanges?${queryParams}`;
    return (await api.delete<void>(url))!;
  },
});

const useExchangeApi = () => useBackendV2Api(builder);

export default useExchangeApi;
