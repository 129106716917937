import { ComponentProps, FC } from "react";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

import transactionCleaningExamples from "../../assets/transaction-cleaning-examples.webp";
import OnboardingHero from "../shared/OnboardingHero";

const WereCleaningYourTransactionsView: FC<PropsWithChildrenAndClassName> = ({
  className,
  children,
}) => {
  return <OnboardingHero className={className}>{children}</OnboardingHero>;
};

const HeroImage: FC<Omit<ComponentProps<typeof OnboardingHero.HeroImage>, "src" | "alt">> = ({
  ...props
}) => {
  return (
    <OnboardingHero.HeroImage
      src={transactionCleaningExamples}
      alt="Transaction cleaning examples"
      {...props}
    />
  );
};

const Heading: FC<PropsWithChildrenAndClassName> = ({
  children = "We’re cleaning your transactions ...",
  className,
}) => {
  return (
    <OnboardingHero.Heading
      // Hack - the image is fairly large and contains the drop shadow.
      className={cn("-mt-3", className)}
    >
      {children}
    </OnboardingHero.Heading>
  );
};

const Subheading: FC<PropsWithChildrenAndClassName> = ({
  children = "Highbeam Intelligence is cleaning vendor names and assigning categories",
  className,
}) => {
  return <OnboardingHero.Subheading className={className}>{children}</OnboardingHero.Subheading>;
};

const Footer: FC<ComponentProps<typeof OnboardingHero.Footer>> = (props) => {
  return <OnboardingHero.Footer {...props} />;
};

export default Object.assign(WereCleaningYourTransactionsView, {
  HeroImage: HeroImage,
  Heading: Heading,
  Subheading: Subheading,
  Footer: Footer,
});
