import { useSuspenseQueries } from "@tanstack/react-query";
import {
  ConnectedStore,
  ShopifyConnectedStore,
  connectedStoresQueryHooks,
} from "modules/connected-stores/queries/useConnectedStores";
import { rutterConnectionsQueryHooks } from "modules/connected-stores/queries/useRutterConnections";
import { plaidAccountsQueryHooks } from "modules/plaid/queries/usePlaidAccounts";
import RutterConnectionRep from "reps/RutterConnectionRep";
import { isRutterAccountingPlatform } from "utils/rutter/typeguards";

const useDataSources = () => {
  return useSuspenseQueries({
    queries: [
      {
        ...connectedStoresQueryHooks.useQueryOptions({}),
        // NB(lev): Only Shopify is actually used as a data source in chat right now.
        select: (connectedStores: ConnectedStore[]) => {
          return connectedStores.filter(
            (connectedStore): connectedStore is ShopifyConnectedStore => {
              return connectedStore.type === "Shopify";
            }
          );
        },
      },
      {
        ...rutterConnectionsQueryHooks.useQueryOptions({}),
        select: (rutterConnections: RutterConnectionRep.Complete[]) => {
          return rutterConnections.filter(
            (
              rutterConnection
            ): rutterConnection is RutterConnectionRep.RutterCommercePlatformComplete => {
              return isRutterAccountingPlatform(rutterConnection.platformName);
            }
          );
        },
      },
      plaidAccountsQueryHooks.useQueryOptions({}),
    ],
    combine: ([
      { data: connectedStores },
      { data: accountingConnections },
      { data: plaidAccounts },
    ]) => {
      return {
        connectedStores,
        accountingConnections,
        plaidAccounts,
      };
    },
  });
};

type DataSources = ReturnType<typeof useDataSources>;

export { useDataSources, type DataSources };
