import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import { ConnectedStoreType } from "../queries/useConnectedStores";
import useRefreshAllConnectedStoresQueries from "../queries/useRefreshAllConnectedStoresQueries";

type Variables = {
  connectedStoreGuid: string;
  connectedStoreType: ConnectedStoreType;
};

const useDeleteConnectedStoreMutation = (
  additionalOptions?: MutationAdditionalOptions<void, Variables>
) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const refreshAllConnectedStoresQueries = useRefreshAllConnectedStoresQueries();

  return useMutationWithDefaults(
    {
      mutationFn: async (variables) => {
        // NB(alex): Not the cleanest way to do this, but it ensures we handle every case in case we add connected stores.
        const getDeleteFn = (
          connectedStoreType: ConnectedStoreType
        ):
          | typeof highbeamApi.shopifyConnection.delete
          | typeof highbeamApi.rutterConnection.delete => {
          switch (connectedStoreType) {
            case "Shopify":
              return (businessGuid: string, connectionGuid: string) =>
                highbeamApi.shopifyConnection.delete(businessGuid, connectionGuid);
            case "Shopify-rutter":
            case "Amazon":
            case "Stripe":
            case "PayPal":
              return highbeamApi.rutterConnection.delete;
          }
        };
        const deleteFn = getDeleteFn(variables.connectedStoreType);
        return void (await deleteFn(businessGuid, variables.connectedStoreGuid));
      },
      onSuccess: async () => {
        await refreshAllConnectedStoresQueries();
      },
    },
    additionalOptions ?? {}
  );
};

export default useDeleteConnectedStoreMutation;
