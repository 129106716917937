import { X } from "@phosphor-icons/react";
import { cloneElement, ComponentProps, FC, ReactElement, ReactNode } from "react";
import { RequireOneOrNone } from "type-fest";
import Button, { ButtonProps } from "ui/inputs/Button";
import { Heading4, Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

type BannerColor =
  | "green"
  | "grey"
  | "orange"
  | "purple"
  | "red"
  | "white"
  | "yellow"
  | "blue"
  | "transparent";

type BannerPadding = "none" | "compact" | "normal" | "lg";

type ResponsiveMode = "media-query" | "container-query";

type Props = {
  color: BannerColor;
  padding?: BannerPadding;
  icon?: ReactElement<{ size?: number }>;
  title?: ReactNode;
  body?: ReactNode;
  paragraph?: ReactNode;
  button?: ReactNode;
  footer?: ReactNode;
  className?: string;
  responsiveMode?: ResponsiveMode;
} & RequireOneOrNone<{
  body: ReactNode;
  paragraph: ReactNode;
}>;

const Banner: FC<Props> = ({
  color,
  padding = "normal",
  icon,
  title,
  body,
  paragraph,
  button,
  footer,
  className,
  responsiveMode = "media-query",
}) => {
  return (
    <div
      className={cn(
        "overflow-hidden rounded-lg border",
        variants(color, {
          blue: "border-blue-light",
          green: "border-green-200",
          grey: "border-grey-200",
          orange: "border-orange-100",
          purple: "border-purple-100",
          red: "border-red-100",
          white: "border-grey-200",
          yellow: "border-yellow-100",
          transparent: "border-transparent",
        }),
        className
      )}
    >
      <div
        className={cn(
          "flex flex-col items-start justify-between gap-x-4 gap-y-6 p-8",
          responsiveMode === "media-query" && "tablet:flex-row tablet:items-center",
          responsiveMode === "container-query" && "@lg:flex-row @lg:items-center",
          variants(color, {
            blue: "bg-blue-light text-blue-dark",
            green: "bg-green-50 text-green-800",
            grey: "bg-grey-50 text-grey-800",
            orange: "bg-orange-50 text-orange-800",
            purple: "bg-purple-50 text-purple-800",
            red: "bg-red-50 text-red-800",
            white: "bg-white text-grey-800",
            yellow: "bg-yellow-50 text-yellow-800",
            transparent: "bg-transparent",
          }),
          variants(padding, {
            none: "p-0",
            compact: "p-3",
            normal: "p-6",
            lg: "p-8",
          })
        )}
      >
        <div
          className={cn(
            "flex w-full",
            variants(padding, {
              none: "gap-x-2",
              compact: "gap-x-2",
              normal: "gap-x-4",
              lg: "gap-x-4",
            })
          )}
        >
          {icon && cloneElement(icon, { size: icon.props.size ?? 20 })}

          <div className={"flex flex-1 flex-col justify-center gap-y-1"}>
            {title && <Heading4>{title}</Heading4>}

            {body}
            {paragraph && <Paragraph className="text-sm font-regular">{paragraph}</Paragraph>}
          </div>
        </div>

        {button}
      </div>

      {footer}
    </div>
  );
};

const BannerDismissButton: FC<ButtonProps> = ({ className, ...props }) => {
  return (
    <Button variant="ghost" className={cn("mx-auto", className)} {...props}>
      <X size={16} />
    </Button>
  );
};

type BannerFooterProps = ComponentProps<"div"> & {
  padding?: BannerPadding;
};

const BannerFooter: FC<BannerFooterProps> = ({
  padding = "normal",
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={cn(
        "px-6 py-6",
        variants(padding, {
          none: "px-0 py-0",
          compact: "px-3 pb-4 pt-1",
          normal: "px-6 py-4",
          lg: "px-8 py-6",
        }),
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default Object.assign(Banner, {
  DismissButton: BannerDismissButton,
  Footer: BannerFooter,
});
