import dayjs from "dayjs";

// When we display dates on the x-axis for balance history charts, the dates
// may be from the current year or from previous years. We include the full
// year in the date string if it's from a previous year. This is notable in
// particular for longer relative timeframes (like the last 12 months) where
// we can have dates from the same month but in different years. Rechart does
// some voodoo magic with the x-axis tick labels, so we need to make sure these
// dates are disambiguated by year, otherwise Recharts will incorrectly assume
// that the labels belong next to each other.
const formatDateForXAxis = (date: dayjs.ConfigType) => {
  const target = dayjs(date);
  if (target.year() === dayjs().year()) {
    return target.format("MMMM D");
  }
  return target.format("MMMM D, YYYY");
};

export default formatDateForXAxis;
