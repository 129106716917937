import { HighbeamAccountTypeName } from "reps/BankAccountRep";

export const DEPOSIT_ACCOUNT_YIELD = 2.02;
export const HIGH_YIELD_ACCOUNT_YIELD = 4.04;

const yieldPercentByAccountType: { [key in HighbeamAccountTypeName]: string } = {
  DepositAccount: `${DEPOSIT_ACCOUNT_YIELD.toFixed(2)}%`,
  HighYield: `${HIGH_YIELD_ACCOUNT_YIELD.toFixed(2)}%`,
};

export { yieldPercentByAccountType };
