import { FEATURE_FLAGS } from "flags";
import ConnectedStoresTable from "modules/connected-stores/components/ConnectedStoresTable";
import useConnectedStoreBalances from "modules/connected-stores/queries/useConnectedStoreBalances";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import SectionV2 from "ui/data-display/SectionV2";

const ConnectedStoresSection = () => {
  const hideConnectedStores = useFeatureFlag(FEATURE_FLAGS["hide-connected-stores"]);

  // NB(alex): We need this hack until we have empty states for the table.
  // NOTE: PR #17325 filters shopifyConnections for isShopifyPaymentsEnabled. If connectedStoreBalances is empty, the Connected Stores section does not display.
  const connectedStoreBalances = useConnectedStoreBalances();

  if (hideConnectedStores || connectedStoreBalances.length === 0) {
    return null;
  }

  return (
    <SectionV2 variant="dashboard-page">
      <SectionV2.Header>
        <SectionV2.Heading>Store accounts</SectionV2.Heading>
      </SectionV2.Header>

      <SectionV2.Body>
        <ConnectedStoresTable connectedStoreBalances={connectedStoreBalances} />
      </SectionV2.Body>
    </SectionV2>
  );
};

export default ConnectedStoresSection;
