import dollarSignPurpleIcon from "assets/dollar-sign-purple.svg";
import emptyBalanceGraph from "assets/empty-balance-graph.svg";
import logoWithoutLabel from "assets/highbeam-logo-without-label.svg";
import classNames from "classnames";
import { usePrimaryBankAccount } from "modules/bank-accounts/queries/useBankAccount";
import { SETTINGS_PLAID_LINK_MODAL_KEY } from "modules/plaid/components/PlaidConnectionsList";
import { plaidAccountsQueryHooks } from "modules/plaid/queries/usePlaidAccounts";
import {
  DEFAULT_ACTIVE_TAB,
  usePayoutInstructionsModal,
} from "pages/PayoutInstructionModal/state/payoutInstructionsModalState";
import colors from "styles/colors";
import Card from "ui/data-display/Card";
import CashDisplay from "ui/data-display/money/CashDisplay";
import Button from "ui/inputs/Button";
import ButtonLink from "ui/inputs/ButtonLink";
import { Heading2 } from "ui/typography";
import Text from "ui/typography/Text";

import styles from "./HomeBalance.module.scss";

// NB(alex): We can probably do a better job of consolidating both components.
export const sharedCardClasses = "w-full border border-grey-200 p-5 extra-large-desktop:max-w-xl";

const FundYourAccount = () => {
  const { open: openPayoutInstructionsModal } = usePayoutInstructionsModal();
  const primaryBankAccount = usePrimaryBankAccount();
  const hasPlaidAccountConnections = plaidAccountsQueryHooks.useData({
    params: {},
    select: (plaidAccounts) => {
      return plaidAccounts.some(
        (account) => account.accountType === "DEPOSITORY" && account.isConnectionActive
      );
    },
  });

  return (
    <>
      <div className={classNames(styles.container, styles.containerEmpty)}>
        <div className={styles["balance-container"]}>
          <Heading2>
            <img className={styles.logo} src={logoWithoutLabel} alt="Highbeam logo" />
            Highbeam balance
          </Heading2>
          <div className={styles["balance-container__balance"]}>
            <CashDisplay color={colors.black} cents={0} />
            <Text size={12} className={styles.text}>
              as of {"today"}
            </Text>
          </div>
        </div>

        <div className={styles.emptyBalanceContainer}>
          <Card shadow="none" className={sharedCardClasses}>
            <img src={dollarSignPurpleIcon} alt="dollar sign icon" />
            <div className={styles.fundAccountDetails}>
              <Text size={14} weight="bold">
                Fund your Highbeam account
              </Text>
              <Text size={14}>
                Transfer funds from your existing bank or connect your store payouts to flow to
                Highbeam.
              </Text>
            </div>
            <div className={styles.fundAccountButtons}>
              {primaryBankAccount && hasPlaidAccountConnections ? (
                <ButtonLink
                  variant="primary"
                  to="/transfer-money"
                  state={{ toAccountGuid: primaryBankAccount.guid }}
                  size="sm"
                >
                  Fund account
                </ButtonLink>
              ) : (
                <ButtonLink
                  variant="primary"
                  to={`/settings/banks-and-cards?${SETTINGS_PLAID_LINK_MODAL_KEY}=true`}
                  size="sm"
                >
                  Connect accounts
                </ButtonLink>
              )}
              <Button
                variant="secondary"
                onClick={() => openPayoutInstructionsModal({ activeTab: DEFAULT_ACTIVE_TAB })}
                size="sm"
              >
                Connect your payouts
              </Button>
            </div>
          </Card>
          <div className={styles.emptyChartContainer}>
            <img src={emptyBalanceGraph} alt="empty balance graph" className={styles.emptyChart} />
          </div>
        </div>
      </div>
    </>
  );
};

export default FundYourAccount;
