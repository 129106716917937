import { Authorization } from "@highbeam/unit-node-sdk";
import TransactionsV2 from "components/TransactionsV2";
import DashboardPage from "layouts/DashboardPage";
import { unitAuthorizationsQueryHooks } from "modules/unit/queries/useUnitAuthorizations";
import { FC, Suspense } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import PageIndicator from "ui/navigation/PageIndicator";
import { DISABLE_SCROLL_TO_TOP_STATE } from "ui/navigation/ScrollToTopOnNavigate";
import DateTimeCell from "ui/table/cells/DateTimeCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import Text from "ui/typography/Text";
import useSearchParamNumber from "utils/search-params/useSearchParamNumber";

type Props = {
  accountId: string;
};

const authorizationColumns: Column<Authorization>[] = [
  {
    title: "Date",
    cellRender: ({ attributes }) => <DateTimeCell date={attributes.createdAt} />,
    width: 120,
  },
  {
    title: "Vendor",
    cellRender: ({ attributes }) => (
      <Text size={14} weight="medium" color={colors.grey[800]}>
        {attributes.merchant.name}
      </Text>
    ),
  },
  {
    title: "Amount",
    align: TableColumnAlignment.RIGHT,
    cellRender: ({ attributes: { amount } }) => (
      <MoneyAmount
        as="div"
        cents={amount}
        direction="negative"
        color={colors.grey[800]}
        weight="medium"
      />
    ),
  },
];

const useCurrentPageSearchParam = () => useSearchParamNumber("pendingPage", 1);

const PENDING_TRANSACTIONS_PAGE_SIZE = 10;

const AccountAuthorizationsTable: FC<Props> = ({ accountId }) => {
  const [currentPage, setCurrentPage] = useCurrentPageSearchParam();

  const {
    data: authorizations,
    meta: { pagination },
  } = unitAuthorizationsQueryHooks.useData({
    params: {
      accountId: accountId,
      status: "Authorized",
      offset: PENDING_TRANSACTIONS_PAGE_SIZE * (currentPage - 1),
      limit: PENDING_TRANSACTIONS_PAGE_SIZE,
      includeNonAuthorized: true,
    },
  });

  const totalPages = Math.ceil(pagination.total / pagination.limit);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  if (authorizations.length === 0) {
    return null;
  }

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Body>
        <TransactionsV2
          title={<TransactionsV2.Title>Pending</TransactionsV2.Title>}
          table={
            <Suspense
              fallback={
                <Table.Loading columns={authorizationColumns.map(({ title }) => ({ title }))} />
              }
            >
              <TransactionsV2.TransactionsTable
                data={authorizations}
                rowKey={({ id }) => id}
                columns={authorizationColumns}
                onRowClick={(authorization) => {
                  const cardId = authorization.relationships.card.data.id;
                  navigate(`/cards/${cardId}/pending/${authorization.id}?${searchParams}`, {
                    state: DISABLE_SCROLL_TO_TOP_STATE,
                  });
                }}
              />
            </Suspense>
          }
          footer={
            <TransactionsV2.Footer
              pageIndicator={
                totalPages > 1 && (
                  <PageIndicator
                    currentPage={currentPage - 1}
                    setCurrentPage={(value) => setCurrentPage(value + 1)}
                    totalPages={totalPages}
                  />
                )
              }
            />
          }
        />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};

export default AccountAuthorizationsTable;
