import { StatementsListParams } from "@highbeam/unit-node-sdk";
import { bankAccountsQueryHooks } from "modules/bank-accounts/queries/useBankAccounts";
import { useUnitApiOrThrow } from "modules/unit-co-customer-token/queries/useUnitApi";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

export const ROOT_UNIT_CO_STATEMENTS_QUERY_NAME = "unitCoStatements-root";

export const useRefreshAllUnitCoStatementsQueries = () => {
  return useRefreshQuery([ROOT_UNIT_CO_STATEMENTS_QUERY_NAME]);
};

export const unitStatementsQueryHooks = makeQueryHooksV2({
  useQueryFnParams: () => {
    const unitApi = useUnitApiOrThrow();
    return { unitApi };
  },
  useDefaultParams: () => {
    const bankAccountUnitIds = bankAccountsQueryHooks.useData({
      params: {},
      select: (bankAccounts) =>
        bankAccounts.map((bankAccount) => bankAccount.unitCoDepositAccountId),
    });
    return {
      sort: "-period" as const,
      accountIds: bankAccountUnitIds,
    };
  },
  makeQueryKey: (params: StatementsListParams) => {
    return [ROOT_UNIT_CO_STATEMENTS_QUERY_NAME, "unitCoStatements", params] as const;
  },
  makeQueryFn: ({ unitApi }, params) => {
    return () => {
      return unitApi.statements.list(params);
    };
  },
});
