import TransactionFlexpaneParties from "components/common/transaction-flexpane/TransactionFlexpaneParties";
import useUnitCoCheckDeposit from "modules/unit-co-check-deposits/queries/useUnitCheckDeposit";
import getCheckDepositCounterpartyName from "modules/unit-co-check-deposits/utils/getCheckDepositCounterpartyName";
import { FC } from "react";
import colors from "styles/colors";
import Flexpane from "ui/overlay/Flexpane";
import Text from "ui/typography/Text";
import { formatBankingDate } from "utils/date";

import CheckDepositFlexpaneBankAccountParty from "./CheckDepositFlexpaneBankAccountParty";
import CheckDepositFlexpaneStatusPill from "./CheckDepositFlexpaneStatusPill";

type Props = {
  checkDepositId: string;
};

const CheckDepositFlexpaneToFromSection: FC<Props> = ({ checkDepositId }) => {
  const checkDeposit = useUnitCoCheckDeposit({ checkDepositId });

  return (
    <Flexpane.Section>
      <TransactionFlexpaneParties
        from={
          <>
            <Text color={colors.grey[800]} size={14} weight="medium">
              {getCheckDepositCounterpartyName(checkDeposit)}
            </Text>
            <Text size={14} color={colors.grey[500]}>
              Initiated {formatBankingDate(checkDeposit.attributes.createdAt)}
            </Text>
          </>
        }
        status={<CheckDepositFlexpaneStatusPill checkDepositId={checkDepositId} />}
        to={<CheckDepositFlexpaneBankAccountParty checkDepositId={checkDepositId} />}
      />
    </Flexpane.Section>
  );
};

export default CheckDepositFlexpaneToFromSection;
