import { FC, useState } from "react";
import { AiTaskUpdateRep } from "reps/ai-tasks/AiTaskRep";
import Divider from "ui/data-display/Divider";
import { notify } from "ui/feedback/Toast";
import TextArea from "ui/inputs/TextArea";
import { Paragraph } from "ui/typography";

import useUpdateAiTaskMutation from "../../mutations/useUpdateAiTaskMutation";
import { useRefreshAiTasks } from "../../queries/useAiTasks";
import AiTaskPillComponent from "../AiTaskPillComponent";

import BaseAiTaskModal, { BaseAiTaskModalProps } from "./BaseAiTaskModal";
import { ModalIcon } from "./ModalIcon";

type Props = Omit<BaseAiTaskModalProps, "variant"> & {
  onTaskSubmittedForReview?: () => void;
};

const EditTaskModal: FC<Props> = ({
  isOpen,
  onClose,
  title,
  onConfirm,
  onCancel,
  className,
  children,
  taskDescription,
  task,
  onTaskSubmittedForReview,
}) => {
  const [editTaskChanges, setEditTaskChanges] = useState("");
  const refreshTasks = useRefreshAiTasks();

  const updateTaskMutation = useUpdateAiTaskMutation(task!.id, {
    onSuccess: () => {
      refreshTasks();
      notify("success", "Task edit submitted for review");
      if (onTaskSubmittedForReview) {
        onTaskSubmittedForReview();
      }
    },
  });

  const config = {
    title: title || "Edit task",
    confirmButtonText: "Submit for review",
    confirmButtonVariant: "primary" as const,
    confirmButtonClassName: "",
    cancelButtonText: "Cancel",
  };

  const handleConfirm = () => {
    const updatePayload: AiTaskUpdateRep = {
      status: "PendingEdits",
      description: editTaskChanges.trim(),
    };

    updateTaskMutation.mutate(updatePayload);
  };

  return (
    <BaseAiTaskModal
      isOpen={isOpen}
      onClose={onClose}
      title={title || config.title}
      config={config}
      onConfirm={onConfirm}
      onCancel={onCancel}
      className={className}
      isConfirmDisabled={!editTaskChanges.trim()}
      onConfirmClick={handleConfirm}
      task={task}
    >
      <div className="mb-6">
        <div className="mb-4 flex items-center gap-3">
          <ModalIcon taskType={task!.type} />
          {taskDescription && (
            <Paragraph className="px-2 text-md font-bold">{taskDescription}</Paragraph>
          )}
          <AiTaskPillComponent status={task!.status} className="ml-auto" />
        </div>
        <Divider />
        <Paragraph className="mt-4 text-grey-600">
          Please describe any edits you&apos;d like to make to the task (Ex. changing the frequency
          of the task or changing the actions it takes).
        </Paragraph>
        <div className="mt-4">
          <TextArea
            placeholder="Describe changes here..."
            value={editTaskChanges}
            onChange={(e) => setEditTaskChanges(e)}
          >
            <TextArea.Input />
          </TextArea>
        </div>
      </div>
      {children}
    </BaseAiTaskModal>
  );
};

export default EditTaskModal;
