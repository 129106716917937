import {
  bankAccountsQueryHooks,
  makeFilterBankAccountsFilter,
} from "modules/bank-accounts/queries/useBankAccounts";
import getBankAccountDisplayName from "modules/bank-accounts/utils/getBankAccountDisplayName";
import businessesQueryHooks from "modules/businesses/queries/businessesQueryHooks";
import capitalAccountsQueryHooks from "modules/capital-accounts/queries/useCapitalAccounts";
import cardsQueryHooks from "modules/cards/queries/useCards";
import { getCardName } from "modules/cards/utils";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { payeesQueryHooks } from "modules/payees/queries/usePayees";
import useCanSuperuser from "modules/superuser/hooks/useCanSuperuser";
import { useMemo } from "react";
import BusinessRep from "reps/BusinessRep";
import CapitalAccountRep from "reps/CapitalAccountRep";

import { useMatchKeywords } from "./command-palette-utils";

const useCommandPaletteBankAccounts = (search: string) => {
  const businessGuid = useBusinessGuid();
  const { data: bankAccounts = [] } = bankAccountsQueryHooks.useQuery({
    params: {},
    enabled: Boolean(businessGuid),
    select: (bankAccounts) => {
      return bankAccounts.map((bankAccount) => {
        return {
          ...bankAccount,
          displayName: getBankAccountDisplayName(bankAccount),
        };
      });
    },
  });

  const bankAccountsMatch = useMatchKeywords(search, ["bank", "accounts"]);

  return useMemo(() => {
    if (bankAccountsMatch) {
      return bankAccounts.filter(makeFilterBankAccountsFilter({ status: "open" }));
    }
    return bankAccounts.filter((bankAccount) => {
      const displayName = getBankAccountDisplayName(bankAccount);
      return displayName.toLowerCase().includes(search.toLowerCase());
    });
  }, [bankAccounts, search, bankAccountsMatch]);
};

const useCommandPaletteCards = (search: string) => {
  const { data: cards = [] } = cardsQueryHooks.useQuery({
    params: { status: "default" },
  });

  const cardsMatch = useMatchKeywords(search, ["cards"]);

  return useMemo(() => {
    if (cardsMatch) {
      return cards.filter((card) => card.attributes.status === "Active");
    }
    return cards.filter((card) => {
      return getCardName(card).toLowerCase().includes(search.toLowerCase());
    });
  }, [cards, search, cardsMatch]);
};

const useCommandPaletteCapitalAccounts = (search: string) => {
  const { data: capitalAccounts = [] } = capitalAccountsQueryHooks.useQuery({
    params: {},
  });

  const capitalAccountsMatch = useMatchKeywords(search, ["capital", "accounts"]);

  return useMemo(() => {
    if (capitalAccountsMatch) {
      return capitalAccounts.filter(
        (capitalAccount) => capitalAccount.state === CapitalAccountRep.State.Active
      );
    }
    return capitalAccounts.filter((capitalAccount) => {
      return capitalAccount.name.toLowerCase().includes(search.toLowerCase());
    });
  }, [capitalAccounts, search, capitalAccountsMatch]);
};

const useCommandPalettePayees = (search: string) => {
  const { data: payees = [] } = payeesQueryHooks.useQuery({
    params: { status: "Active" },
  });

  const payeesMatch = useMatchKeywords(search, ["payments", "payees"]);

  return useMemo(() => {
    if (payeesMatch) {
      return payees;
    }
    return payees.filter((payee) => {
      return payee.name.toLowerCase().includes(search.toLowerCase());
    });
  }, [payees, search, payeesMatch]);
};

const getSuperuserBusinessName = (business: BusinessRep.Complete) => {
  if (business.dba && business.name) {
    return `${business.name} (${business.dba})`;
  } else if (business.name) {
    return business.name;
  } else {
    return business.guid;
  }
};

const useCommandPaletteSuperuserBusinesses = (search: string) => {
  const canSuperuser = useCanSuperuser();

  const { data = [], isLoading } = businessesQueryHooks.useQuery({
    enabled: canSuperuser,
    select: (businesses) => {
      return businesses.map((business) => {
        return {
          ...business,
          name: getSuperuserBusinessName(business),
        };
      });
    },
  });

  const businesses = useMemo(() => {
    if (!search) return [];
    const searchTerm = search.toLowerCase();

    return data
      .filter((business) => {
        return (
          business.name.toLowerCase().includes(searchTerm) ||
          business.guid.toLowerCase().includes(searchTerm) ||
          business.unitCoCustomerId?.toLowerCase().includes(searchTerm)
        );
      })
      .slice(0, 20);
  }, [data, search]);

  return {
    businesses,
    isLoading,
  };
};

export {
  useCommandPaletteBankAccounts,
  useCommandPaletteCards,
  useCommandPaletteCapitalAccounts,
  useCommandPalettePayees,
  useCommandPaletteSuperuserBusinesses,
};
