import { ApiBuilder, useBackendV1Api } from "modules/highbeam-api/base";
import BusinessDetailsRep from "reps/BusinessDetailsRep";

type BusinessDetailsApi = {
  get: (businessGuid: string) => Promise<BusinessDetailsRep | null>;
  update: (
    businessGuid: string,
    updater: BusinessDetailsRep.Updater
  ) => Promise<BusinessDetailsRep>;
};

const builder: ApiBuilder<BusinessDetailsApi> = (api) => ({
  get: async (businessGuid) => {
    const url = `/businesses/${businessGuid}/details`;
    return await api.get<BusinessDetailsRep>(url);
  },
  update: async (businessGuid, updater) => {
    const url = `/businesses/${businessGuid}/details`;
    return (await api.patch<BusinessDetailsRep>(url, updater))!;
  },
});

const useBusinessDetailsApi = () => useBackendV1Api(builder);

export default useBusinessDetailsApi;
