import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import { useIsSuperusering } from "state/auth/isSuperusering";
import useHasPermission from "utils/permissions/useHasPermission";

const useIsAllowedToNavigateToChatRoutes = (): boolean => {
  const isSuperusering = useIsSuperusering();
  const featureFlagIsEnabled = useFeatureFlag("ai-chat") as boolean;
  const hasChatPermission = useHasPermission("chat");

  if (isSuperusering) return true;
  if (!featureFlagIsEnabled) return false;
  return hasChatPermission;
};

export default useIsAllowedToNavigateToChatRoutes;
