function findFirstNumericCharacter(str: string): number {
  return str.split("").findIndex((char) => !isNaN(Number(char)));
}

type Options = {
  // If false, the formatted amount will remove subunits
  // The rounding is handled by NumberFormat
  withCents?: boolean;
  currency?: string;
  // If true, the formatted amount will be stripped of all non-numeric characters
  // This is useful for passing the value back to an input component
  rawValue?: boolean;
  // If true, the currency code will be appended to the end of the formatted amount
  // NumberFormat does not support this natively, so we need to do some string manipulation
  trailingCurrencyCode?: boolean;
};

/**
 * DEPREACTED: Please use `<MoneyAmount>` instead.
 */
export const formatAmount = (
  amount: number,
  {
    withCents = true,
    currency = "USD",
    rawValue = false,
    trailingCurrencyCode = false,
  }: Options = {}
) => {
  const formatProperties = {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
    ...(!withCents && { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
    // Please do not modify this as it is configured to match the rounding behavior of the backend
    roundingMode: "halfEven",
  } satisfies Intl.NumberFormatOptions;

  const formattedAmount = new Intl.NumberFormat("en-US", formatProperties).format(amount);

  if (rawValue) {
    // Replace all non-numeric characters
    return formattedAmount.replace(/[^0-9.]+/g, "");
  }

  if (trailingCurrencyCode) {
    // Not all currencies have a single symbol, so we need to find the first numeric character
    const firstNumericCharacter = findFirstNumericCharacter(formattedAmount);
    return `${formattedAmount.slice(firstNumericCharacter, formattedAmount.length)} ${currency}`;
  }

  return formattedAmount;
};

export const percentage = (partialValue: number, totalValue: number) =>
  (100 * partialValue) / totalValue;

export const toKebabCase = (str: string) => {
  const match = str
    .replace(/([A-Z])([A-Z])/g, "$1-$2")
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-");
  if (match) {
    return match.toLowerCase();
  }

  return "";
};

export const isDigitsOnly = (val: string) => /^\d*$/.test(val);

export const addAOrAn = (word: string) => {
  if (word.match("^[aieouAIEOU].*")) {
    return "an " + word;
  } else {
    return "a " + word;
  }
};

export const pluralize = (amount: number, noun: string, nounPlural?: string) => {
  const plural = nounPlural || noun + "s";
  return `${amount} ${amount === 1 ? noun : plural}`;
};

export const removeTextFromBeginningAndTrim = (str: string, textToRemove: string) => {
  if (str.startsWith(textToRemove)) {
    str = str.slice(textToRemove.length);
  }

  return str.trim();
};

export const removeTextFromEndAndTrim = (str: string, textToRemove: string) => {
  if (str.endsWith(textToRemove)) {
    str = str.slice(0, str.length - textToRemove.length);
  }

  return str.trim();
};

export const toTitleCase = (input: string) =>
  input
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

export const stripNonNumeric = (input: string) => input.replace(/\D/g, "");
