import { useUnitApiOrThrow } from "modules/unit-co-customer-token/queries/useUnitApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const UNIT_ACCOUNT_LIMITS_QUERY_KEY = "unit-co-account-limits";

type Params = {
  unitCoBankAccountIds: string[];
};

const useUnitCoAccountLimitsQueryOptions = ({ unitCoBankAccountIds }: Params) => {
  const unitApi = useUnitApiOrThrow();

  return useQueryOptions({
    queryKey: [UNIT_ACCOUNT_LIMITS_QUERY_KEY, unitCoBankAccountIds],
    queryFn: async () => {
      const allAccountsLimits = unitCoBankAccountIds.map((accountId) =>
        unitApi.accounts.limits(accountId)
      );
      const responses = await Promise.all(allAccountsLimits);
      return responses.map((response) => response.data);
    },
  });
};

export default useUnitCoAccountLimitsQueryOptions;
