import { useEffect } from "react";
import usePrevious from "utils/customHooks/usePrevious";

import { useSidePanelContext } from "./context";

const useSidePanelEventEffects = () => {
  const { isOpen, showChildren, onCloseStart, onCloseEnd, onOpenStart, onOpenEnd } =
    useSidePanelContext();

  const prevIsOpen = usePrevious(isOpen);

  useEffect(() => {
    if (isOpen) {
      onOpenStart?.();
    }
  }, [isOpen, onOpenStart]);

  useEffect(() => {
    if (prevIsOpen && !isOpen) {
      onCloseStart?.();
    }
  }, [prevIsOpen, isOpen, onCloseStart]);

  useEffect(() => {
    if (showChildren) {
      onOpenEnd?.();
    }
  }, [showChildren, onOpenEnd]);

  useEffect(() => {
    if (prevIsOpen && !showChildren) {
      onCloseEnd?.();
    }
  }, [prevIsOpen, showChildren, onCloseEnd]);
};

export default useSidePanelEventEffects;
