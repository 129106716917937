import { useQuery } from "@tanstack/react-query";
import useUnitCoReceivedPaymentsQueryOptions from "modules/unit-co-received-payments/queries/useUnitCoReceivedPaymentsQueryOptions";

type Params = {
  accountId?: string;
};

const useFailedDebitPaymentsQuery = (params: Params = {}) => {
  return useQuery(
    useUnitCoReceivedPaymentsQueryOptions({
      accountId: params.accountId,
      status: ["MarkedForReturn"],
    })
  );
};

export { useFailedDebitPaymentsQuery };
