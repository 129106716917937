import { ErrorBoundary } from "@sentry/react";
import OnboardingLayoutV2 from "layouts/OnboardingLayoutV2";
import { useHasSkippedOnboarding } from "modules/action-items/queries/useHasSkippedOnboarding";
import { useHasFilledOutBankApplication } from "modules/bank-application/queries/useBankApplicationQueryOptions";
import { useIsCurrentBusinessMemberOnboarded } from "modules/business-members/queries/businessMemberQueryHooks";
import LoadingPage from "modules/loading/pages/LoadingPage";
import OnboardingErrorCard from "modules/onboarding/components/OnboardingErrorCard";
import { useReferralReceivedByBusiness } from "modules/user-referrals/queries/useReferral";
import CleaningTransactionsInProgressPage from "pages/onboarding/CleaningTransactionsInProgressPage";
import { onboardingRoutes, onboardingRoutesWithoutPrefix } from "pages/onboarding/config";
import ConnectAccountsConnectionsPage from "pages/onboarding/ConnectAccountsConnectionsPage";
import ConnectAccountsExplainerPage from "pages/onboarding/ConnectAccountsExplainerPage";
import VerifyBusinessPage from "pages/onboarding/VerifyBusinessPage";
import WouldYouLikeACashFlowSheetPage from "pages/onboarding/WouldYouLikeACashFlowSheetPage";
import { Suspense } from "react";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import variants from "utils/ts/variants";

const OnboardingRoutes = () => {
  const [searchParams] = useSearchParams();
  const isOnboarded = useIsCurrentBusinessMemberOnboarded();
  const hasSkippedOnboarding = useHasSkippedOnboarding();
  const hasFilledOutBankApplication = useHasFilledOutBankApplication();

  const shouldRedirect =
    (isOnboarded || hasSkippedOnboarding || hasFilledOutBankApplication) &&
    !searchParams.get("forceOnboarding");

  const referral = useReferralReceivedByBusiness();

  if (shouldRedirect) {
    return <Navigate to="/accounts" replace />;
  }

  return (
    <ErrorBoundary
      fallback={() => (
        <OnboardingLayoutV2 className="flex items-center">
          <div className="py-24 tablet:py-48">
            <OnboardingErrorCard />
          </div>
        </OnboardingLayoutV2>
      )}
    >
      <Suspense fallback={<LoadingPage location={OnboardingRoutes.name} />}>
        <Routes>
          <Route
            path={onboardingRoutesWithoutPrefix["/connect-accounts-get-started"]}
            element={
              <OnboardingLayoutV2 progressBarPercentage={5}>
                <ConnectAccountsExplainerPage />
              </OnboardingLayoutV2>
            }
          />
          <Route
            path={onboardingRoutesWithoutPrefix["/connect-accounts"]}
            element={
              <OnboardingLayoutV2 progressBarPercentage={10}>
                <ConnectAccountsConnectionsPage />
              </OnboardingLayoutV2>
            }
          />
          <Route
            path={onboardingRoutesWithoutPrefix["/cleaning-transactions-in-progress"]}
            element={
              <OnboardingLayoutV2 progressBarPercentage={30}>
                <CleaningTransactionsInProgressPage />
              </OnboardingLayoutV2>
            }
          />
          <Route
            path={onboardingRoutesWithoutPrefix["/would-you-like-a-cash-flow-sheet"]}
            element={
              <OnboardingLayoutV2 progressBarPercentage={50}>
                <WouldYouLikeACashFlowSheetPage />
              </OnboardingLayoutV2>
            }
          />
          <Route
            path={onboardingRoutesWithoutPrefix["/verify-business"]}
            element={
              <OnboardingLayoutV2 progressBarPercentage={70}>
                <VerifyBusinessPage />
              </OnboardingLayoutV2>
            }
          />
          <Route
            path="/*"
            element={
              <Navigate
                to={
                  referral?.landingPage
                    ? variants(referral.landingPage, {
                        BankingApplication: onboardingRoutes["/onboarding/verify-business"],
                        CapitalApplication: "/capital",
                        Default: onboardingRoutes["/onboarding/connect-accounts-get-started"],
                        PlaidConnection:
                          onboardingRoutes["/onboarding/connect-accounts-get-started"],
                      })
                    : onboardingRoutes["/onboarding/connect-accounts-get-started"]
                }
                replace
              />
            }
          />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default OnboardingRoutes;
