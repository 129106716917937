import DashboardPage from "layouts/DashboardPage";
import ErrorText from "modules/error/components/ErrorText";
import { useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { LineChart } from "ui/data-visualization/chart";
import Button from "ui/inputs/Button";
import TextArea from "ui/inputs/TextArea";
import TextInput from "ui/inputs/TextInput";
import { Heading4 } from "ui/typography";

import { exampleRawText } from "./example-raw-text";

type Datum = {
  date: string;
  benchmarkDollars: number;
  businessDollars: number;
};

const convertRawTextToData = (rawText: string): Datum[] => {
  const lines = rawText.trim().split("\n");
  const data = lines.map((line) => {
    const [date, benchmarkDollars, businessDollars] = line.split("\t");
    return {
      date,
      benchmarkDollars: Number(benchmarkDollars.replace(/[$,]/g, "")),
      businessDollars: Number(businessDollars.replace(/[$,]/g, "")),
    };
  });
  return data;
};

const getXAxisData = (data: Datum[]): string[] => {
  return data.map((datum) => datum.date);
};

const convertDataToSeries = (data: Datum[]): echarts.RegisteredSeriesOption["line"][] => {
  return [
    {
      name: "Benchmark",
      data: data.map((datum) => datum.benchmarkDollars),
    },
    {
      name: "Business",
      data: data.map((datum) => datum.businessDollars),
    },
  ];
};

type SuperuserBenchmarkingChartPageContentProps = {
  textareaValue: string;
  setTextareaValue: (value: string) => void;
};

const SuperuserBenchmarkingChartPageContent = ({
  textareaValue,
  setTextareaValue,
}: SuperuserBenchmarkingChartPageContentProps) => {
  const data = useMemo(() => {
    if (!textareaValue) return null;
    return convertRawTextToData(textareaValue);
  }, [textareaValue]);

  const xAxisData = useMemo(() => {
    if (!data) return [];
    return getXAxisData(data);
  }, [data]);

  const series = useMemo(() => {
    if (!data) return [];
    return convertDataToSeries(data);
  }, [data]);

  const [width, setWidth] = useState<number | null>(null);

  return (
    <>
      <DashboardPage.Section>
        <div className="flex flex-col gap-2">
          <Heading4>Benchmark values: date | benchmark | business</Heading4>
          <TextArea rows={10} value={textareaValue} onChange={setTextareaValue}>
            <TextArea.Input />
          </TextArea>
          <div className="flex justify-end gap-x-2">
            <Button onClick={() => setTextareaValue("")}>Clear</Button>
            <Button onClick={() => setTextareaValue(exampleRawText)}>Reset example values</Button>
          </div>

          <TextInput
            type="number"
            value={width?.toString()}
            onChange={(value) => setWidth(value === "" ? null : Number(value))}
            className="w-fit"
            label="Width (px)"
          />
        </div>
      </DashboardPage.Section>
      <DashboardPage.Section>
        <LineChart
          xAxisData={xAxisData}
          xAxisType="YYYY-MM-DD"
          yAxisType="money"
          series={series}
          style={{ width: width ?? undefined }}
        />
      </DashboardPage.Section>
    </>
  );
};

const SuperuserBenchmarkingChartPage = () => {
  const [textareaValue, setTextareaValue] = useState(exampleRawText);
  return (
    <DashboardPage>
      <DashboardPage.Header>
        <DashboardPage.Header.Title>[SU] Benchmarking chart</DashboardPage.Header.Title>
      </DashboardPage.Header>
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => {
          return (
            <div>
              <ErrorText error={error} />
              <Button
                onClick={() => {
                  setTextareaValue("");
                  resetErrorBoundary();
                }}
                variant="primary"
                className="mt-4"
              >
                Reset
              </Button>
            </div>
          );
        }}
      >
        <SuperuserBenchmarkingChartPageContent
          textareaValue={textareaValue}
          setTextareaValue={setTextareaValue}
        />
      </ErrorBoundary>
    </DashboardPage>
  );
};

export default SuperuserBenchmarkingChartPage;
