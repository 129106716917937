import { Bank, Coins } from "@phosphor-icons/react";
import { ComponentRef, CSSProperties, forwardRef } from "react";
import cn from "utils/tailwind/cn";

import amex from "./assets/amex.png";
import bankOfAmerica from "./assets/bank-of-america.png";
import capitalOne from "./assets/capital-one.png";
import chase from "./assets/chase.png";
import chime from "./assets/chime.png";
import creditUnionOfColorado from "./assets/credit-union-of-colorado.png";
import dwightLogo from "./assets/dwight.png";
import emberLogo from "./assets/ember.png";
import fcu from "./assets/fcu.png";
import mercury from "./assets/mercury.png";
import novo from "./assets/novo.png";
import shopify from "./assets/shopify.png";
import td from "./assets/td.png";
import uncappedLogo from "./assets/uncapped.png";
import usBank from "./assets/us-bank.png";
import wellsFargo from "./assets/wells-fargo.png";

export type LogoSquareProps = {
  className?: string;
  size?: number;
  style?: CSSProperties;
};

const DEFAULT_SIZE = 16;

const forwardLogoSvgRef = forwardRef<ComponentRef<"svg">, LogoSquareProps>;

const forwardLogoImgRef = forwardRef<ComponentRef<"img">, LogoSquareProps>;

const LogoImg = forwardRef<ComponentRef<"img">, LogoSquareProps & { alt: string; src: string }>(
  ({ size = DEFAULT_SIZE, alt, src, className, ...props }, ref) => {
    return (
      <img
        alt={alt}
        ref={ref}
        src={src}
        width={size}
        height={size}
        className={cn("shrink-0", className)}
        {...props}
      />
    );
  }
);

/**
 * Highbeam
 */

const HighbeamLogo = forwardLogoSvgRef(({ size = DEFAULT_SIZE, className, ...props }, ref) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      className={cn("shrink-0", className)}
      {...props}
      ref={ref}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25033 4.00009V4H7.81611V4.00009L9.49986 4.00009V4H11.0656V4.00009L12.7502 4.00009V4H14.316C14.316 5.27116 14.6849 6.50804 15.3673 7.52497L15.5015 7.72497C16.4743 9.17463 17.0002 10.9378 17.0002 12.7499V15.9999H16.2502V16.0001L6.25033 16V16H5.68419V14.5C5.68419 13.0666 5.26821 11.6718 4.49868 10.525C3.52587 9.07536 3.00001 7.31216 3.00001 5.50007V5.44835H3V4.00008H3.00001H4.56578L6.25033 4.00009ZM15.4344 12.7499V14.5518L13.7498 14.5518V13.2499C13.7498 11.4378 13.2239 9.67463 12.2511 8.22496C11.6985 7.40148 11.3283 6.4501 11.1634 5.44835L12.8744 5.44835C13.0696 6.57594 13.4914 7.64613 14.1147 8.57496L14.2489 8.77496C15.0184 9.92171 15.4344 11.3165 15.4344 12.7499ZM12.184 13.2499V14.5518H10.5003V13.9999C10.5003 12.8768 10.2983 11.7726 9.91312 10.75L11.7458 10.75C12.0334 11.5389 12.184 12.3874 12.184 13.2499ZM10.9819 9.25006C10.2348 8.12818 9.75443 6.82036 9.57695 5.44835L7.8297 5.44835C7.90701 6.72254 8.3126 7.94817 9.00163 8.97496C9.06236 9.06545 9.12134 9.15718 9.17857 9.25006L10.9819 9.25006ZM8.93452 13.9999V14.5518L7.24997 14.5518V14.5C7.24997 12.6879 6.7241 10.9247 5.7513 9.47504C4.98177 8.32828 4.56578 6.93351 4.56578 5.50007V5.44835L6.26108 5.44835C6.34036 7.10152 6.85664 8.69515 7.74901 10.0249C8.51854 11.1717 8.93452 12.5665 8.93452 13.9999Z"
        fill="#3B20C6"
      />
    </svg>
  );
});

const HighbeamCapitalLogo = forwardLogoSvgRef(
  ({ size = DEFAULT_SIZE, className, ...props }, ref) => {
    return (
      <Coins
        ref={ref}
        alt="Capital"
        className={cn("shrink-0 text-purple-500", className)}
        size={size}
        {...props}
      />
    );
  }
);

const HighbeamHighYieldLogo = forwardLogoSvgRef(
  ({ size = DEFAULT_SIZE, className, ...props }, ref) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        className={cn("shrink-0", className)}
        {...props}
        ref={ref}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.55768 4.53362V4.48074V3H4.13664H5.83543H7.41439H9.11231H10.6913H12.39H12.6418H13.969C13.969 4.29967 14.341 5.56428 15.0291 6.60402L15.1645 6.8085C15.5634 7.41127 15.8878 8.06707 16.1323 8.75768C15.4979 8.38123 14.7782 8.13344 14.0091 8.05023C13.974 7.99361 13.938 7.93754 13.9013 7.88204L13.766 7.67756C13.1374 6.72788 12.7121 5.63365 12.5152 4.48074L10.7898 4.48074C10.9561 5.50499 11.3295 6.47774 11.8868 7.31971C12.0588 7.57966 12.217 7.84948 12.3609 8.1277C10.4823 8.51364 8.96651 9.89398 8.38778 11.6944C8.20114 10.7822 7.84845 9.91813 7.34672 9.16006C6.44682 7.80042 5.92619 6.17102 5.84626 4.48074L4.13664 4.48074V4.53362C4.13664 5.99921 4.55612 7.42526 5.33213 8.59773C6.31313 10.0799 6.84342 11.8827 6.84342 13.7354V13.7887L8.15529 13.7887C8.20102 14.3074 8.32148 14.8046 8.50585 15.2695L5.83543 15.2695V15.269H5.26446V13.7354C5.26446 12.2698 4.84498 10.8437 4.06897 9.67127C3.08797 8.18909 2.55768 6.38635 2.55768 4.53362ZM16.5074 10.1489C15.9613 9.61884 15.2786 9.22872 14.5172 9.03642C14.5546 9.1257 14.5904 9.21586 14.6243 9.30682C15.3892 9.53374 16.0625 9.97351 16.5757 10.5575C16.5559 10.4206 16.5331 10.2843 16.5074 10.1489ZM12.7377 8.95663C11.9476 9.08156 11.2272 9.41606 10.6352 9.90141H11.0183C11.5431 9.5298 12.1572 9.27608 12.8222 9.17884C12.795 9.10435 12.7668 9.03027 12.7377 8.95663ZM9.81014 10.7928C9.8368 10.8948 9.86179 10.9974 9.88509 11.1005C9.48264 11.7432 9.24999 12.5031 9.24999 13.3173C9.24999 14.0224 9.42444 14.6867 9.73254 15.2695H9.47155C9.18092 14.6809 9.01761 14.0182 9.01761 13.3173C9.01761 12.3785 9.31061 11.5082 9.81014 10.7928ZM10.6068 8.36779C9.85347 7.22073 9.36901 5.88355 9.19004 4.48074L7.42808 4.48074C7.50604 5.78354 7.91504 7.03668 8.60988 8.08652C8.67112 8.17905 8.73061 8.27283 8.78832 8.36779L10.6068 8.36779Z"
          fill="#3B20C6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.8942 13.3175C17.8942 15.7815 15.8967 17.779 13.4327 17.779C10.9686 17.779 8.97113 15.7815 8.97113 13.3175C8.97113 10.8535 10.9686 8.85596 13.4327 8.85596C15.8967 8.85596 17.8942 10.8535 17.8942 13.3175ZM13.2654 11.9883C13.2654 12.63 12.7452 13.1501 12.1035 13.1501C11.4618 13.1501 10.9416 12.63 10.9416 11.9883C10.9416 11.3466 11.4618 10.8264 12.1035 10.8264C12.7452 10.8264 13.2654 11.3466 13.2654 11.9883ZM12.8192 11.9883C12.8192 12.3836 12.4988 12.704 12.1035 12.704C11.7082 12.704 11.3878 12.3836 11.3878 11.9883C11.3878 11.593 11.7082 11.2726 12.1035 11.2726C12.4988 11.2726 12.8192 11.593 12.8192 11.9883ZM15.4308 11.3193C15.5179 11.4064 15.5179 11.5477 15.4308 11.6348L11.75 15.3156C11.6629 15.4027 11.5217 15.4027 11.4345 15.3156C11.3474 15.2285 11.3474 15.0872 11.4345 15.0001L15.1153 11.3193C15.2024 11.2322 15.3437 11.2322 15.4308 11.3193ZM14.7618 15.8085C15.4035 15.8085 15.9237 15.2883 15.9237 14.6466C15.9237 14.0049 15.4035 13.4848 14.7618 13.4848C14.1202 13.4848 13.6 14.0049 13.6 14.6466C13.6 15.2883 14.1202 15.8085 14.7618 15.8085ZM14.7618 15.3623C15.1571 15.3623 15.4775 15.0419 15.4775 14.6466C15.4775 14.2513 15.1571 13.9309 14.7618 13.9309C14.3666 13.9309 14.0461 14.2513 14.0461 14.6466C14.0461 15.0419 14.3666 15.3623 14.7618 15.3623Z"
          fill="#3B20C6"
        />
      </svg>
    );
  }
);

const BankLogoGeneric = forwardLogoSvgRef(({ size = DEFAULT_SIZE, className, ...props }, ref) => {
  return (
    <Bank
      weight="fill"
      size={size}
      className={cn("shrink-0 text-grey-600", className)}
      {...props}
      ref={ref}
    />
  );
});

/**
 * External
 */

const AmexLogo = forwardLogoImgRef((props, ref) => {
  return <LogoImg alt="American Express" ref={ref} src={amex} {...props} />;
});

const BankOfAmericaLogo = forwardLogoImgRef((props, ref) => {
  return <LogoImg alt="Bank of America" ref={ref} src={bankOfAmerica} {...props} />;
});

const BrexLogo = forwardLogoImgRef((props, ref) => {
  return (
    <LogoImg
      src="data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgMjAgMTYiIHdpZHRoPSIyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMTIuOTkxOSAxLjI4Yy0uNzgxNiAxLjA0MDAxLS45NzAzIDEuMzg2NjctMS43MjUgMS4zODY2N2gtMTEuMjY2OXYxMy4zMzMzM2g0LjQyMDQ4Yy45OTczIDAgMS45Njc2Ny0uNDggMi41ODc2MS0xLjI4LjgwODYyLTEuMDY2Ny45MTY0NC0xLjM4NjcgMS42OTgxMi0xLjM4NjdoMTEuMjkzNzl2LTEzLjMzMzNoLTQuNDQ3NGMtLjk5NzMgMC0xLjk2NzcuNDgtMi41NjA3IDEuMjh6bTMuNTA0MSA4LjYxMzM0aC00LjcxN2MtMS4wMjQzIDAtMS45Njc2Ny40MjY2Ni0yLjU4NzYzIDEuMjUzMzYtLjgwODYyIDEuMDY2Ni0uOTcwMzQgMS4zODY2LTEuNzI1MDYgMS4zODY2aC0zLjk4OTIzdi02LjM5OTk2aDQuNzE2OTljMS4wMjQyNiAwIDEuOTY3NjMtLjQ4MDAxIDIuNTg3NjMtMS4yODAwMS44MDg2LTEuMDQgMS4wMjQyLTEuMzU5OTkgMS43NTItMS4zNTk5OWgzLjk2MjN6IiBmaWxsPSIjMTcxNzE3Ii8+PC9zdmc+"
      alt="Brex"
      ref={ref}
      {...props}
    />
  );
});

const CapitalOneLogo = forwardLogoImgRef((props, ref) => {
  return <LogoImg alt="Capital One" ref={ref} src={capitalOne} {...props} />;
});

const ChaseLogo = forwardLogoImgRef((props, ref) => {
  return <LogoImg alt="Chase" ref={ref} src={chase} {...props} />;
});

const ChimeLogo = forwardLogoImgRef((props, ref) => {
  return <LogoImg alt="Chime" ref={ref} src={chime} {...props} />;
});

const CreditUnionOfColoradoLogo = forwardLogoImgRef((props, ref) => {
  return (
    <LogoImg alt="Credit Union of Colorado" ref={ref} src={creditUnionOfColorado} {...props} />
  );
});

const DwightLogo = forwardLogoImgRef((props, ref) => {
  return <LogoImg alt="Dwight" ref={ref} className={props.className} src={dwightLogo} {...props} />;
});

const EmberLogo = forwardLogoImgRef((props, ref) => {
  return <LogoImg alt="Ember" ref={ref} className={props.className} src={emberLogo} {...props} />;
});

const FcuLogo = forwardLogoImgRef((props, ref) => {
  return <LogoImg alt="Schools First FCU" ref={ref} src={fcu} {...props} />;
});

const MercuryLogo = forwardLogoImgRef((props, ref) => {
  return <LogoImg alt="Mercury" ref={ref} src={mercury} {...props} />;
});

const NovoLogo = forwardLogoImgRef((props, ref) => {
  return <LogoImg alt="Novo Bank" ref={ref} src={novo} {...props} />;
});

const ShopifyLogo = forwardLogoImgRef((props, ref) => {
  return <LogoImg alt="Shopify" ref={ref} src={shopify} {...props} />;
});

const TdLogo = forwardLogoImgRef((props, ref) => {
  return <LogoImg alt="Toronto Dominion" ref={ref} src={td} {...props} />;
});

const UncappedLogo = forwardLogoImgRef((props, ref) => {
  return (
    <LogoImg alt="Uncapped" ref={ref} className={props.className} src={uncappedLogo} {...props} />
  );
});

const UsBankLogo = forwardLogoImgRef((props, ref) => {
  return <LogoImg alt="U.S. Bank" ref={ref} src={usBank} {...props} />;
});

const WellsFargoLogo = forwardLogoImgRef((props, ref) => {
  return <LogoImg alt="Wells Fargo" ref={ref} src={wellsFargo} {...props} />;
});

export {
  AmexLogo,
  BankLogoGeneric,
  BankOfAmericaLogo,
  BrexLogo,
  CapitalOneLogo,
  ChaseLogo,
  ChimeLogo,
  CreditUnionOfColoradoLogo,
  DwightLogo,
  EmberLogo,
  FcuLogo,
  HighbeamCapitalLogo,
  HighbeamHighYieldLogo,
  HighbeamLogo,
  MercuryLogo,
  NovoLogo,
  ShopifyLogo,
  TdLogo,
  UncappedLogo,
  UsBankLogo,
  WellsFargoLogo,
};
