import { ArrowRight, CalendarPlus } from "@phosphor-icons/react";
import dayjs from "dayjs";
import env from "env";
import UserReferralRep from "modules/user-referrals/reps/ReferralLinkRep";
import { FC } from "react";
import colors from "styles/colors";
import ButtonLink from "ui/inputs/ButtonLink";
import Text from "ui/typography/Text";

import styles from "../Referral.module.scss";

type Props = {
  referral: UserReferralRep;
};

const ReferralContent: FC<Props> = ({ referral }) => {
  const referralExpiresAt = referral.expiresAt
    ? dayjs(referral.expiresAt).format("MM/DD/YY")
    : null;
  const hasExpired = referral.expiresAt ? dayjs(referral.expiresAt).isBefore(dayjs()) : false;

  return (
    <>
      <ButtonLink
        variant="primary"
        className="w-full tablet:w-auto"
        to={`${env.HIGHBEAM_MARKETING_SITE_URL}/qualify?source=${referral.slug}`}
        rel="noreferrer"
      >
        Apply now <ArrowRight size={20} />
      </ButtonLink>

      {referralExpiresAt && !hasExpired && (
        <div className={styles.expiresAt}>
          <CalendarPlus size={16} color={colors.grey[500]} />
          <Text size={12} color={colors.grey[500]}>
            Invitation expires {referralExpiresAt}
          </Text>
        </div>
      )}
      <div className={styles.bookDemo}>
        <Text size={14} color={colors.grey[600]} className={styles.noWrap}>
          Have questions?
        </Text>

        <ButtonLink
          variant="ghost"
          color={colors.purple[500]}
          className="w-full text-nowrap tablet:w-auto"
          to={env.CALENDLY_DEMO_LINK}
          target="_blank"
          rel="noreferrer"
        >
          Book a demo
        </ButtonLink>
      </div>
    </>
  );
};

export default ReferralContent;
