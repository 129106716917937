import * as AvatarPrimitive from "@radix-ui/react-avatar";
import { ElementRef, forwardRef, ComponentPropsWithoutRef } from "react";
import Shimmer from "ui/feedback/ShimmerV2";
import cn from "utils/tailwind/cn";

const ChatAvatar = forwardRef<
  ElementRef<typeof AvatarPrimitive.Root>,
  ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn(
      "relative flex size-8 shrink-0 overflow-hidden rounded-lg border-2 border-grey-100",
      className
    )}
    {...props}
  />
));

const ChatAvatarImage = forwardRef<
  ElementRef<typeof AvatarPrimitive.Image>,
  ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn("aspect-square h-full w-full", className)}
    {...props}
  />
));

const ChatAvatarFallback = forwardRef<
  ElementRef<typeof AvatarPrimitive.Fallback>,
  ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn(
      "flex h-full w-full items-center justify-center bg-grey-50 text-xs font-medium",
      className
    )}
    {...props}
  />
));

const ChatAvatarShimmer = () => {
  return <Shimmer className="size-8 rounded-none" />;
};

export default Object.assign(ChatAvatar, {
  Image: ChatAvatarImage,
  Fallback: ChatAvatarFallback,
  Shimmer: ChatAvatarShimmer,
});
