import { ChatCircleDots } from "@phosphor-icons/react";
import { useChatWidget } from "components/ChatWidget";
import { useBankApplication } from "modules/bank-application/queries/useBankApplicationQueryOptions";
import VerifyYourBusinessModal, {
  useVerifyYourBusinessModal,
} from "modules/onboarding/dialogs/VerifyYourBusinessModal";
import { FC, ReactNode } from "react";
import BankApplicationRep from "reps/BankApplicationRep";
import Button from "ui/inputs/Button";
import ButtonLink from "ui/inputs/ButtonLink";
import { Span } from "ui/typography";

import UploadDocumentsToFinishVerificationBanner from "./UploadDocumentsToFinishVerificationBanner";
import VerificationInProgressBanner from "./VerificationInProgressBanner";
import VerifyBusinessBanner from "./VerifyBusinessBanner";

type VerifyBusinessBannerImplProps = {
  blurb?: ReactNode;
  className?: string;
};

const VerifyBusinessBannerImpl: FC<VerifyBusinessBannerImplProps> = ({ blurb, className }) => {
  const verifyYourBusinessModal = useVerifyYourBusinessModal();

  return (
    <>
      {verifyYourBusinessModal.isOpen && (
        <VerifyYourBusinessModal onClose={verifyYourBusinessModal.close} />
      )}

      <VerifyBusinessBanner
        button={
          <Button
            onClick={() => {
              verifyYourBusinessModal.open({});
            }}
          >
            Verify business
          </Button>
        }
        paragraph={blurb}
        className={className}
      />
    </>
  );
};

const VerificationInProgressBannerImpl: FC<{ className?: string }> = ({ className }) => {
  const chat = useChatWidget();

  return (
    <VerificationInProgressBanner
      button={
        <div className="flex items-center gap-x-2">
          <Span className="whitespace-nowrap text-sm text-grey-600">Have questions?</Span>
          <Button
            onClick={() => {
              chat.message("Hi, I have a question about my bank application.");
            }}
          >
            <ChatCircleDots weight="light" size={24} /> Contact support
          </Button>
        </div>
      }
      className={className}
    />
  );
};

type UploadDocumentsToFinishVerificationBannerImplProps = {
  className?: string;
  bankApplication: BankApplicationRep.Complete;
};

const UploadDocumentsToFinishVerificationBannerImpl: FC<
  UploadDocumentsToFinishVerificationBannerImplProps
> = ({ className, bankApplication }) => {
  return (
    <UploadDocumentsToFinishVerificationBanner
      button={
        <ButtonLink
          to={bankApplication.url!} // Should always exist if `AwaitingDocuments`.
        >
          Upload documents
        </ButtonLink>
      }
      className={className}
    />
  );
};

type Props = {
  defaultBlurb?: ReactNode;
  className?: string;
};

const VerifyBusinessBannerRenderer: FC<Props> = ({ defaultBlurb, className }) => {
  const bankApplication = useBankApplication();
  const status = bankApplication?.status ?? "FormCreated";

  switch (status) {
    case "FormCreated":
      return <VerifyBusinessBannerImpl blurb={defaultBlurb} className={className} />;
    case "AwaitingDocuments":
      return (
        <UploadDocumentsToFinishVerificationBannerImpl
          className={className}
          bankApplication={bankApplication!} // We know the application exists for this status.
        />
      );
    case "Pending":
    case "PendingReview":
    case "Denied":
      return <VerificationInProgressBannerImpl className={className} />;
    case "Approved":
      return null;
  }
};

export default VerifyBusinessBannerRenderer;
