import { Dayjs } from "dayjs";
import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";
import { API_LOCAL_MONTH_FORMAT } from "utils/date";

import CounterpartyReportRep from "../reps/CounterpartyReportRep";

type CounterpartyReportApi = {
  search: (
    businessGuid: string,
    monthRangeStart: Dayjs,
    monthRangeEndInclusive: Dayjs
  ) => Promise<CounterpartyReportRep.Complete>;
};

const builder: ApiBuilder<CounterpartyReportApi> = (api) => ({
  search: async (businessGuid, monthRangeStart, monthRangeEndInclusive) => {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
      monthRangeStart: monthRangeStart.format(API_LOCAL_MONTH_FORMAT),
      monthRangeEndInclusive: monthRangeEndInclusive.format(API_LOCAL_MONTH_FORMAT),
    });
    const url = `/insights/counterparty-reports?${qp}`;
    return (await api.get<CounterpartyReportRep.Complete>(url))!;
  },
});

const useCounterpartyReportApi = () => useBackendV2Api(builder);

export default useCounterpartyReportApi;
