import React from "react";
import cn from "utils/tailwind/cn";

import DotsLoader from "../DotsLoader";

type Props = {
  variant: "page" | "section";
};

const DotsPageLoader: React.FC<Props> = ({ variant }) => (
  <div className={cn("flex grow items-center justify-center", variant === "page" && "size-full")}>
    <DotsLoader dots={variant === "page" ? 5 : 3} />
  </div>
);

export default DotsPageLoader;
