import { LockSimple } from "@phosphor-icons/react";
import useDeleteExchangesByChannelMutation from "modules/chat/mutations/useDeleteExchangesByChannelMutation";
import { FC, ReactNode, useCallback } from "react";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import { Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";

type Props = {
  className?: string;
  children: ReactNode;
};

const AiChatFooter: FC<Props> = ({ className, children }) => {
  return (
    <div className={cn("flex items-center justify-center gap-x-2", className)}>{children}</div>
  );
};

const Disclaimer: FC = () => {
  return (
    // NB(lev): Needs to support wrapping onto multiple lines,
    // so be careful with using display: flex here.
    <Paragraph className="text-xs leading-normal text-grey-600">
      <span>AI can make mistakes. Check important info.</span>
      <span className="px-3 align-middle">•</span>
      <span>
        <LockSimple className="mr-2 fill-grey-600 align-middle" size={16} />
        We never let third parties train on your data.
      </span>
    </Paragraph>
  );
};

type ClearChannelButtonProps = {
  channelId: string;
};

const ClearChannelButton: FC<ClearChannelButtonProps> = ({ channelId }) => {
  const { mutate, isPending } = useDeleteExchangesByChannelMutation(channelId, {
    onError: () => {
      notify("error", "Something went wrong, please try again!");
    },
  });

  const handleClick = useCallback(() => {
    if (isPending) return;
    mutate();
  }, [isPending, mutate]);

  return (
    <Button onClick={() => handleClick()} size="xs" className="text-purple-300">
      [SU] Clear channel
    </Button>
  );
};

export default Object.assign(AiChatFooter, { Disclaimer, ClearChannelButton });
