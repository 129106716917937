import { ArrowsLeftRight, Info } from "@phosphor-icons/react";
import PaymentDetailsCard from "components/PaymentDetailsCard";
import { ACH_CUTOFF_WARNING } from "config/constants/banking";
import { FEATURE_FLAGS } from "flags";
import { useBankAccountsByUnitAccountIdMap } from "modules/bank-accounts/queries/useBankAccounts";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import { PaymentMethod } from "pages/SendMoneyPage/utils";
import { Transfer } from "pages/TransferMoneyPage/TransferInfo/utils";
import Banner from "ui/data-display/Banner";
import Button from "ui/inputs/Button";
import { StepType } from "ui/navigation/Steps";
import StepHeader from "ui/navigation/Steps/StepHeader";
import Text from "ui/typography/Text";
import { startOfBankingDay } from "utils/date";
import { isPlaidBankAccount, getTransferDeliveryMessage } from "utils/transfers";

import { isConnectedAccountTransfer } from "../utils";

type Props = {
  isLoading: boolean;
  onNextPress: () => void;
  onPrevPress: () => void;
  step: StepType;
  transfer: Transfer;
};

type AchDebitFooterProps = {
  accountName?: string;
};

const AchDebitFooter: React.FC<AchDebitFooterProps> = (props: AchDebitFooterProps) => {
  const footerText = props.accountName
    ? `By clicking transfer, I authorize the above amount to be debited from ${props.accountName}.`
    : "By clicking transfer, I authorize the above amount to be debited from the bank account shown.";
  return <Text size={14}>{footerText}</Text>;
};

const TransferMoneyConfirmDetails: React.FC<Props> = ({
  isLoading,
  onNextPress,
  onPrevPress,
  step: stepType,
  transfer,
}) => {
  const openBankAccountsByUnitAccountIdMap = useBankAccountsByUnitAccountIdMap({
    filters: { status: "open" },
  });
  const showDescription = useFeatureFlag(FEATURE_FLAGS["allow-transfer-description-ui"]);

  return (
    <>
      <StepHeader
        handleBackButtonClick={onPrevPress}
        disableBackButton={isLoading}
        stepNumber={stepType.number}
        disableForwardButton
        title={stepType.title}
      />

      <PaymentDetailsCard
        paymentMethod={PaymentMethod.TRANSFER}
        iconBesideAmount={<ArrowsLeftRight size={16} />}
        from={transfer.from?.label!}
        amountInCents={transfer.amountInCents}
        to={transfer.to?.label!}
        descriptionMessage={showDescription ? transfer.description : undefined}
        deliveryMessage={getTransferDeliveryMessage(transfer, openBankAccountsByUnitAccountIdMap)}
        footer={
          transfer.from && isPlaidBankAccount(transfer.from) ? (
            <AchDebitFooter accountName={transfer.from?.accountName} />
          ) : null
        }
        scheduledDate={startOfBankingDay()}
      />

      <div className="my-5 flex flex-col gap-x-5 gap-y-4 tablet:flex-row">
        <Button onClick={onPrevPress} variant="tertiary" disabled={isLoading}>
          Back
        </Button>
        <Button variant="primary" onClick={onNextPress} isLoading={isLoading} autoFocus>
          Transfer
        </Button>
      </div>

      {isConnectedAccountTransfer(transfer) && (
        <Banner className="mt-8" icon={<Info />} color="blue" paragraph={ACH_CUTOFF_WARNING} />
      )}
    </>
  );
};
export default TransferMoneyConfirmDetails;
