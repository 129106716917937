import { useFinishActionItemMutation } from "modules/action-items/mutations/useFinishActionItemMutation";
import useCreateBankApplicationMutation from "modules/bank-application/mutations/useCreateBankApplicationMutation";
import { useBankApplication } from "modules/bank-application/queries/useBankApplicationQueryOptions";
import { OnboardingDotsLoader } from "modules/onboarding/components/shared/onboarding-common-components";
import VerifyBusinessHero from "modules/onboarding/components/verify-business/VerifyBusinessHero";
import VerifyBusinessView from "modules/onboarding/components/verify-business/VerifyBusinessView";
import { useNavigate } from "react-router-dom";
import Button from "ui/inputs/Button";
import ButtonLink from "ui/inputs/ButtonLink";
import useMountEffect from "utils/customHooks/useMountEffect";

import OnboardingSideBySide from "./OnboardingSideBySide";

const VerifyBusinessPage = () => {
  const navigate = useNavigate();
  const bankApplication = useBankApplication();
  const { mutate: createBankApplication } = useCreateBankApplicationMutation();
  const { mutate: finishActionItem, isPending: isFinishActionItemPending } =
    useFinishActionItemMutation("Onboarding", {
      onSuccess: () => {
        navigate("/accounts");
      },
    });

  useMountEffect(() => {
    if (!bankApplication) {
      createBankApplication();
    }
  });

  // Show the loading page while the bank application is being created.
  if (!bankApplication) {
    return <OnboardingDotsLoader />;
  }

  return (
    <OnboardingSideBySide>
      <OnboardingSideBySide.Left>
        <OnboardingSideBySide.LeftPadding>
          <VerifyBusinessView className="mx-auto mt-8 max-w-xl @xl:mt-24 @3xl:mt-44">
            <VerifyBusinessView.ShieldCheckIconTile />
            <VerifyBusinessView.Heading />
            <VerifyBusinessView.Subheading />
            <VerifyBusinessView.WhatYoullNeedCard />
            <VerifyBusinessView.Footer>
              <Button
                onClick={() => {
                  finishActionItem({ state: "Dismissed", step: "VerifyBusiness" });
                }}
                isLoading={isFinishActionItemPending}
                variant="ghost"
              >
                Skip for now
              </Button>
              <ButtonLink to={bankApplication.url!} variant="primary">
                Start verification
              </ButtonLink>
            </VerifyBusinessView.Footer>
          </VerifyBusinessView>
        </OnboardingSideBySide.LeftPadding>
      </OnboardingSideBySide.Left>

      <OnboardingSideBySide.Right>
        <div className="mx-auto mt-24 max-w-md @xs:px-8 @sm:px-16 @xl:mt-12 @xl:max-w-xl @3xl:mt-36">
          <VerifyBusinessHero>
            <VerifyBusinessHero.HeroImage />
            <VerifyBusinessHero.HeroQuote />
          </VerifyBusinessHero>
        </div>
      </OnboardingSideBySide.Right>
    </OnboardingSideBySide>
  );
};

export default VerifyBusinessPage;
