import React, { FC, useCallback, useEffect } from "react";
import VirtualButton from "ui/inputs/VirtualButton";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

import useShowChildrenEffects from "./useShowChildrenEffects";
import useSidePanelEventEffects from "./useSidePanelEventEffects";

import { useSidePanelContext } from ".";

const SidePanelPanelBlock: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  const {
    // State
    isDragging,
    setIsDragging,
    transitionEnabled,
    setTransitionEnabled,
    showChildren,
    setShowChildren,
    width,
    setWidth,

    // Configs
    positions,
    side,

    // Helpers
    isOpen,

    // Refs & ids
    panelId,
    panelRef,
    resizerRef,
  } = useSidePanelContext();

  useShowChildrenEffects();
  useSidePanelEventEffects();

  const handleResizerMouseDown = useCallback(() => {
    setIsDragging(true);
  }, [setIsDragging]);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, [setIsDragging]);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isDragging) {
        requestAnimationFrame(() => {
          // Calculate width based on mouse position.
          const newWidth = variants(side, {
            left: e.clientX,
            right: window.innerWidth - e.clientX,
          });
          if (newWidth < positions.min) {
            setWidth(positions.min);
          } else if (newWidth > positions.widest) {
            setWidth(positions.widest);
          } else {
            setWidth(newWidth);
          }
        });
      }
    },
    [isDragging, setWidth, side, positions.min, positions.widest]
  );

  const handleResizerKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Escape") {
        e.preventDefault();
        setIsDragging(false);
        (e.target as HTMLDivElement).blur();
      }

      const DELTA_SM = 12;
      const DELTA_MD = 64;

      // Makes the panel wider.
      if (
        (side === "right" && e.key === "ArrowLeft") ||
        (side === "left" && e.key === "ArrowRight")
      ) {
        e.preventDefault();

        if (e.metaKey) {
          if (width < positions.initial) {
            setWidth(positions.initial);
          } else {
            setWidth(positions.widest);
          }
          return;
        }

        if (width < positions.min) {
          setWidth(positions.min);
        } else if (e.shiftKey) {
          if (width + DELTA_MD < positions.widest) {
            setWidth(width + DELTA_MD);
          } else {
            setWidth(positions.widest);
          }
        } else {
          if (width + DELTA_SM < positions.widest) {
            setWidth(width + DELTA_SM);
          } else {
            setWidth(positions.widest);
          }
        }
      }

      // Makes the panel narrower.
      if (
        (side === "right" && e.key === "ArrowRight") ||
        (side === "left" && e.key === "ArrowLeft")
      ) {
        e.preventDefault();

        if (e.metaKey) {
          if (width > positions.widest) {
            setWidth(positions.widest);
          } else if (width > positions.initial) {
            setWidth(positions.initial);
          } else {
            setWidth(0);
          }
          return;
        }

        if (width - DELTA_SM < positions.min) {
          setWidth(0);
        } else if (e.shiftKey) {
          setWidth(width - DELTA_MD);
        } else {
          setWidth(width - DELTA_SM);
        }
      }
    },
    [width, setWidth, side, positions.widest, positions.initial, positions.min, setIsDragging]
  );

  const handleResizerDoubleClick = useCallback(() => {
    const halfWayBetweenWidestAndInitial =
      positions.widest - (positions.widest - positions.initial) / 2;
    if (width < halfWayBetweenWidestAndInitial) {
      setWidth(positions.widest);
    } else {
      setWidth(positions.initial);
    }
  }, [width, setWidth, positions.widest, positions.initial]);

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  // Ensures the width is within the min and max bounds during window resize.
  useEffect(() => {
    if (width > positions.widest) {
      setWidth(positions.widest);
    } else if (width < positions.min) {
      setWidth(0);
    }
  }, [width, positions.widest, positions.min, setWidth]);

  const resizer = (
    <VirtualButton
      onMouseDown={handleResizerMouseDown}
      onKeyDown={handleResizerKeyDown}
      onDoubleClick={handleResizerDoubleClick}
      ref={resizerRef}
      className={cn(
        "group absolute bottom-0 top-0 z-10 w-2 cursor-col-resize focus:outline-0 focus-visible:outline-0",
        !showChildren && "invisible",
        variants(side, {
          left: "-right-1",
          right: "-left-1",
        })
      )}
    >
      {/* Resizer focus line: the actual resizer is pretty wide so we artificially make it look narrower. */}
      <div className="duration-50 mx-auto h-full w-0.5 bg-focus opacity-0 transition-opacity group-hover:opacity-100 group-focus:opacity-100 group-focus-visible:opacity-100" />
    </VirtualButton>
  );

  return (
    <div
      className={cn(
        "duration-50 overflow-hidden bg-white",
        showChildren && "relative h-full",
        transitionEnabled && "transition-[width]", // We only want to enable this briefly or else the transition interferes with resizing by dragging.
        variants(side, {
          left: ["border-r border-r-grey-200"],
          right: ["border-l border-l-grey-200"],
        }),
        className
      )}
      onTransitionEnd={() => {
        setTransitionEnabled(false);
        if (!isOpen) {
          setShowChildren(false);
        }
      }}
      style={{ width: isOpen ? `${width}px` : 0 }}
      ref={panelRef}
      id={panelId}
    >
      {side === "right" && resizer}

      {showChildren && (
        <div className={cn("h-full w-full overflow-scroll", isDragging && "overflow-x-hidden")}>
          {children}
        </div>
      )}

      {side === "left" && resizer}
    </div>
  );
};

export default SidePanelPanelBlock;
