import { FC, ReactNode } from "react";
import ScrollWithInsetShadow from "ui/data-display/ScrollWithInsetShadow";

type Props = {
  children: ReactNode;
};

const DebugPre: FC<Props> = ({ children }) => {
  return (
    <ScrollWithInsetShadow className="max-h-64 overflow-y-auto">
      <pre className="font-mono select-all whitespace-pre-wrap break-words text-sm">{children}</pre>
    </ScrollWithInsetShadow>
  );
};

export default DebugPre;
