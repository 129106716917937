import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import useActionItemApi from "../api/useActionItemApi";
import { actionItemsQueryHooks, useRefreshAllActionItemsQueries } from "../queries/useActionItems";
import { FinishedGetStartedActionItem } from "../queries/useGetStartedActionItems";
import ActionItemRep, {
  ActionItemMilestone,
  ActionItemState,
  MilestoneToStepMap,
} from "../reps/ActionItemRep";

type Variables<TMilestone extends ActionItemMilestone> = {
  step: MilestoneToStepMap[TMilestone];
  state: ActionItemState;
};

const useFinishActionItemMutation = <TMilestone extends ActionItemMilestone>(
  milestone: TMilestone,
  additionalOptions?: MutationAdditionalOptions<
    ActionItemRep.Complete | null,
    Variables<TMilestone>
  >
) => {
  const actionItemApi = useActionItemApi();
  const businessGuid = useBusinessGuid();
  const actionItems = actionItemsQueryHooks.useData({
    params: { milestone },
  });
  const refreshAllActionItemsQueries = useRefreshAllActionItemsQueries();

  return useMutationWithDefaults(
    {
      mutationFn: async ({ step, state }: Variables<TMilestone>) => {
        const existingItem = actionItems.find(
          (item): item is FinishedGetStartedActionItem => item.name === step
        );

        if (existingItem) {
          return actionItemApi.update(businessGuid, existingItem.guid, { state });
        } else {
          return actionItemApi.set({
            milestoneName: milestone,
            name: step,
            ownerGuid: businessGuid,
            state: state,
          });
        }
      },
      onSuccess: async () => {
        await refreshAllActionItemsQueries();
      },
    },
    additionalOptions ?? {}
  );
};

export { useFinishActionItemMutation };
