import { useRefreshAllBusinessesQueries } from "modules/businesses/queries/businessesQueryHooks";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useMfa from "modules/mfa/useMfa";
import BusinessDetailsRep from "reps/BusinessDetailsRep";
import { notify } from "ui/feedback/Toast";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import useBusinessDetailsApi from "../api/useBusinessDetailsApi";

const useUpdateBusinessDetailsMutation = (
  additionalOptions?: MutationAdditionalOptions<BusinessDetailsRep, BusinessDetailsRep.Updater>
) => {
  const refreshAllBusinessesQueries = useRefreshAllBusinessesQueries();
  const { mfa } = useMfa();
  const businessDetailsApi = useBusinessDetailsApi();
  const businessGuid = useBusinessGuid();
  const { segmentTrack } = useSegment();

  return useMutationWithDefaults(
    {
      mutationFn: async (variables: BusinessDetailsRep.Updater) => {
        await mfa();
        const result = await businessDetailsApi.update(businessGuid, variables);
        return result;
      },
      onSuccess: async () => {
        await refreshAllBusinessesQueries();
        notify("success", "Business details updated");
        segmentTrack(SEGMENT_EVENTS.BUSINESS_DETAILS_UPDATED);
      },
      onError: async () => {
        notify("error", "Something went wrong! Please try again.");
      },
    },
    additionalOptions ?? {}
  );
};

export default useUpdateBusinessDetailsMutation;
