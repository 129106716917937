import { containerPaddingClasses } from "modules/onboarding/components/shared/onboarding-common-components";
import { FC } from "react";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

const OnboardingSideBySide: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <div className={cn("relative flex flex-1", className)}>{children}</div>;
};

const Left: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return (
    <div className={cn("flex-1 @container", containerPaddingClasses, className)}>{children}</div>
  );
};

const LeftPadding: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <div className={cn("@5xl:pl-16 @7xl:pl-24", className)}>{children}</div>;
};

const Right: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return (
    <div className={cn("hidden flex-1 bg-grey-50 @container tablet:block", className)}>
      {children}
    </div>
  );
};

export default Object.assign(OnboardingSideBySide, {
  Left,
  LeftPadding,
  Right,
});
