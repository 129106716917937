import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import useUnitCoRecurringPaymentsQueryOptions from "modules/unit-co-recurring-payments/queries/useUnitCoRecurringPaymentsQueryOptions";

type Params = {
  payeeGuid: string;
};

const usePayeeRecurringPaymentsQueryOptions = ({ payeeGuid }: Params, enabled = true) => {
  return useUnitCoRecurringPaymentsQueryOptions({ recipientGuid: payeeGuid }, enabled);
};

export const usePayeeRecurringPaymentsQuery = ({ payeeGuid }: Params, enabled = true) => {
  return useQuery(usePayeeRecurringPaymentsQueryOptions({ payeeGuid }, enabled));
};

const usePayeeRecurringPayments = ({ payeeGuid }: Params, enabled = true) => {
  const { data } = useSuspenseQuery(usePayeeRecurringPaymentsQueryOptions({ payeeGuid }, enabled));
  return data.recurringPayments ?? [];
};

export default usePayeeRecurringPayments;
