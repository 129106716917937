import { CaretRight } from "@phosphor-icons/react";
import { DEFAULT_MONTHS_SINCE } from "config/constants/filters";
import dayjs from "dayjs";
import DashboardPage from "layouts/DashboardPage";
import CapitalAccountTransactionsTable from "modules/capital-account-transactions/components/CapitalAccountTransactionsTable";
import CapitalAccountAutoPayBankAccountHighlightItem from "modules/capital-accounts/components/CapitalAccountAutoPayBankAccountHighlightItem";
import ChargeCardCapitalAccountBarChart from "modules/capital-accounts/components/ChargeCardCapitalAccountBarChart";
import { ChargeCardCapitalAccountBarChartLegendByCapitalAccountGuid } from "modules/capital-accounts/components/ChargeCardCapitalAccountBarChartLegend";
import { ChargeCardCapitalAccountMetadataListByCapitalAccountGuid } from "modules/capital-accounts/components/ChargeCardCapitalAccountMetadataList";
import NextAutoPaymentHighlightItem from "modules/capital-accounts/components/NextAutoPaymentHighlightItem";
import EditCapitalAccountAutoPayAccountModal from "modules/capital-accounts/dialogs/EditCapitalAccountAutoPayAccountModal";
import { useNextCapitalAutoPayAmount } from "modules/capital-accounts/queries/capitalRepaymentAmountsQueryHooks";
import { CapitalAccountSummaryWithChargeCard } from "modules/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import { FC, useState } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import Divider from "ui/data-display/Divider";
import MoneyAmountFraction from "ui/data-display/money/MoneyAmountFraction";
import VirtualButton from "ui/inputs/VirtualButton";
import useSearchParamDayjs from "utils/search-params/useSearchParamDayjs";
import useSearchParamValue from "utils/search-params/useSearchParamValue";

import CapitalAccountAssociatedCardsSection from "../../components/CapitalAccountAssociatedCardsSection";
import ChargeCardRepayments from "../../components/ChargeCardRepayments";
import CapitalAccountBanners from "../../sections/CapitalAccountBanners";
import CapitalAccountHeader from "../../sections/CapitalAccountHeader";

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const ChargeCardView: FC<Props> = ({ capitalAccountSummaryWithChargeCard }) => {
  const [
    isEditCapitalAccountAutoPayAccountModalOpen,
    setIsEditCapitalAccountAutoPayAccountModalOpen,
  ] = useState(false);

  const nextAutoPayAmount = useNextCapitalAutoPayAmount({
    capitalAccountGuid: capitalAccountSummaryWithChargeCard.guid,
    enabled: capitalAccountSummaryWithChargeCard.state === CapitalAccountRep.State.Active,
  });

  const [since, setSince] = useSearchParamDayjs(
    "since",
    dayjs().subtract(DEFAULT_MONTHS_SINCE, "months")
  );
  const [until, setUntil] = useSearchParamDayjs("until", dayjs());
  const [search, setSearch] = useSearchParamValue("search", "");
  const isCapitalAccountTerminated =
    capitalAccountSummaryWithChargeCard.state === CapitalAccountRep.State.Terminated;

  return (
    <>
      {isEditCapitalAccountAutoPayAccountModalOpen && (
        <EditCapitalAccountAutoPayAccountModal
          capitalAccountSummary={capitalAccountSummaryWithChargeCard}
          onClose={() => setIsEditCapitalAccountAutoPayAccountModalOpen(false)}
        />
      )}

      {!isCapitalAccountTerminated && (
        <CapitalAccountBanners capitalAccountSummary={capitalAccountSummaryWithChargeCard} />
      )}

      <CapitalAccountHeader capitalAccountSummary={capitalAccountSummaryWithChargeCard} />

      <DashboardPage.Section className="grid grid-cols-1 gap-14 @4xl:grid-cols-2">
        <div className="flex-1">
          <div className="mb-1 flex justify-between">
            <DashboardPage.Section.HeaderHeading className="font-medium">
              {isCapitalAccountTerminated ? "Repaid" : "Available"}
            </DashboardPage.Section.HeaderHeading>
          </div>

          <MoneyAmountFraction
            className="mb-6 mt-2"
            variant="headline"
            numeratorInCents={capitalAccountSummaryWithChargeCard.available}
            denominatorInCents={capitalAccountSummaryWithChargeCard.details.limit}
          />

          <ChargeCardCapitalAccountBarChart
            capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
            height={32}
          />

          <ChargeCardCapitalAccountBarChartLegendByCapitalAccountGuid
            capitalAccountGuid={capitalAccountSummaryWithChargeCard.guid}
          />

          <VirtualButton
            className="mt-6 flex cursor-pointer rounded-lg border border-grey-200 @container"
            onClick={() => setIsEditCapitalAccountAutoPayAccountModalOpen(true)}
          >
            <div className="flex w-full flex-col @md:flex-row">
              <NextAutoPaymentHighlightItem
                className="px-6 py-4"
                nextAutoPayAmount={nextAutoPayAmount}
              />

              <Divider orientation="vertical" className="my-6 hidden @md:block" />
              <Divider orientation="horizontal" className="my-0 block @md:hidden" />

              <div className="flex flex-1 items-center">
                <CapitalAccountAutoPayBankAccountHighlightItem
                  capitalAccount={capitalAccountSummaryWithChargeCard}
                  className="max-w-80 overflow-auto whitespace-nowrap px-6 py-4"
                />

                <CaretRight
                  size={24}
                  className="ml-auto mr-6 h-4 self-center text-grey-500"
                  weight="light"
                />
              </div>
            </div>
          </VirtualButton>
        </div>

        <div className="flex-1">
          <DashboardPage.Section.HeaderHeading className="mb-1 font-medium">
            Details
          </DashboardPage.Section.HeaderHeading>

          <ChargeCardCapitalAccountMetadataListByCapitalAccountGuid
            capitalAccountGuid={capitalAccountSummaryWithChargeCard.guid}
            capitalAccountState={capitalAccountSummaryWithChargeCard.state}
          />
        </div>
      </DashboardPage.Section>

      <CapitalAccountAssociatedCardsSection
        capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
      />

      <DashboardPage.Section>
        <ChargeCardRepayments
          capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
        />
      </DashboardPage.Section>

      <DashboardPage.Section>
        <DashboardPage.Section.Header>
          <DashboardPage.Section.HeaderHeading>
            Overdue repayments
          </DashboardPage.Section.HeaderHeading>
        </DashboardPage.Section.Header>

        <CapitalAccountTransactionsTable
          capitalAccountGuid={capitalAccountSummaryWithChargeCard.guid}
          capitalAccountType={capitalAccountSummaryWithChargeCard.type}
          sinceControl={[since, setSince]}
          untilControl={[until, setUntil]}
          searchControl={[search, setSearch]}
        />
      </DashboardPage.Section>
    </>
  );
};

export default ChargeCardView;
