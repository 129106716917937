import { ApiBuilder, useBackendV1Api } from "modules/highbeam-api/base";

import ActionItemRep from "../reps/ActionItemRep";

export type ActionItemApi = {
  set: (creation: ActionItemRep.Creation) => Promise<ActionItemRep.Complete>;
  getByMilestoneName: (
    ownerGuid: string,
    milestoneName: string
  ) => Promise<ActionItemRep.Complete[]>;
  update: (
    ownerGuid: string,
    actionItemGuid: string,
    update: ActionItemRep.Update
  ) => Promise<ActionItemRep.Complete | null>;
};

const builder: ApiBuilder<ActionItemApi> = (api) => ({
  set: async (creation) => {
    const url = `/action-items`;
    return (await api.put<ActionItemRep.Complete>(url, creation))!;
  },
  getByMilestoneName: async (ownerGuid, milestoneName) => {
    const queryParams = new URLSearchParams({ ownerGuid, milestoneName });
    const url = `/action-items?${queryParams.toString()}`;
    return (await api.get<ActionItemRep.Complete[]>(url))!;
  },
  update: async (ownerGuid, actionItemGuid, update) => {
    const queryParams = new URLSearchParams({ ownerGuid });
    const url = `/action-items/${actionItemGuid}?${queryParams.toString()}`;
    return await api.patch<ActionItemRep.Complete>(url, update);
  },
});

const useActionItemApi = () => useBackendV1Api(builder);

export default useActionItemApi;
