import { TextChatElementRep } from "modules/chat/reps/ChatElementRep";
import React from "react";
import cn from "utils/tailwind/cn";

type Props = {
  element: TextChatElementRep;
};

const TextChatElement: React.FC<Props> = ({ element }) => {
  return (
    <span className={cn({ "font-600": element.bold, italic: element.italic })}>
      {element.content}
    </span>
  );
};

export default TextChatElement;
