import { ChatTeardropDots } from "@phosphor-icons/react";
import { useChatWidget } from "components/ChatWidget";
import MultiStep from "layouts/MultiStep";
import CapitalAccountIcon from "modules/capital-accounts/components/CapitalAccountIcon";
import getCardOrLineCopy from "modules/capital-accounts/utils/getCardOrLineCopy";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Text from "ui/typography/Text";

import { useReviewLineOfferContext } from "../../context/ReviewLineOfferProvider";

import HowItWorksBulletPoints from "./HowItWorksBulletPoints";
import styles from "./HowItWorksView.module.scss";

const HOW_IT_WORKS_FORM_ID = "how-it-works-form";

type HowItWorksViewHeadlineProps = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const HowItWorksViewHeadline: FC<HowItWorksViewHeadlineProps> = ({ capitalAccountSummary }) => {
  return (
    <div className={styles.headlineContainer}>
      <div className={styles.headlineItem}>
        <CapitalAccountIcon capitalAccount={capitalAccountSummary} />
        <Text size={16} color={colors.grey[900]} weight="medium">
          {capitalAccountSummary.name}
        </Text>
      </div>

      <div className={styles.headlineItem}>
        <Text size={14} color={colors.grey[600]}>
          Limit:
        </Text>
        <MoneyAmount
          weight="medium"
          size={14}
          cents={capitalAccountSummary.details.limit}
          withCents={capitalAccountSummary.details.limit % 100 !== 0}
        />
      </div>
    </div>
  );
};

type HowItWorksContactSupportProps = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const HowItWorksContactSupport: FC<HowItWorksContactSupportProps> = ({ capitalAccountSummary }) => {
  const chat = useChatWidget();

  return (
    <div className={styles.supportContainer}>
      <Text size={14} color={colors.grey[600]}>
        Have questions?
      </Text>
      <div
        className={styles.support}
        onClick={() =>
          chat.message(`Hi, I have a question about my ${capitalAccountSummary.name} offer.`)
        }
      >
        <ChatTeardropDots size={14} color={colors.purple[500]} />
        <Text size={12} color={colors.purple[500]}>
          Contact support
        </Text>
      </div>
    </div>
  );
};

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const HowItWorksView: FC<Props> = ({ capitalAccountSummary }) => {
  const navigate = useNavigate();
  const { nextPathname } = useReviewLineOfferContext();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (nextPathname) {
      navigate(nextPathname);
    }
  };

  return (
    <MultiStep.Form id={HOW_IT_WORKS_FORM_ID} onSubmit={handleSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>
            How this {getCardOrLineCopy(capitalAccountSummary)} works
          </MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>
      </MultiStep.Section>

      <MultiStep.Section>
        <Divider />

        <HowItWorksViewHeadline capitalAccountSummary={capitalAccountSummary} />

        <Divider />

        <HowItWorksBulletPoints capitalAccountSummary={capitalAccountSummary} />

        <Divider />

        <HowItWorksContactSupport capitalAccountSummary={capitalAccountSummary} />
      </MultiStep.Section>

      <MultiStep.Controls>
        <MultiStep.Controls.NextButton form={HOW_IT_WORKS_FORM_ID}>
          Next
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

export default HowItWorksView;
