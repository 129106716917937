import { ArrowRight } from "@phosphor-icons/react";
import BankAccountBar from "modules/bank-accounts/components/BankAccountBar";
import ShopifyPayoutsStatusIcon from "modules/connected-stores/components/ShopifyPayoutsStatusIcon";
import { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import BankAccountRep from "reps/BankAccountRep";
import AmountCell from "ui/table/cells/AmountCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";

const createColumns = (): Column<BankAccountRep.Complete>[] => [
  {
    title: "Account",
    cellRender: (account) => (
      <BankAccountBar
        bankAccount={account}
        endAdornment={account.isPrimary && <ShopifyPayoutsStatusIcon />}
      />
    ),
  },
  {
    title: "Available",
    align: TableColumnAlignment.RIGHT,
    cellRender: (account) => (
      <AmountCell className="text-grey-800" cents={account.availableBalance} />
    ),
  },
  {
    title: "",
    align: TableColumnAlignment.RIGHT,
    width: 160,
    cellRender: () => (
      <div>
        <ArrowRight size={16} />
      </div>
    ),
  },
];

type Props = {
  bankAccounts: BankAccountRep.Complete[];
};

const BankAccountsTable: FC<Props> = ({ bankAccounts }) => {
  const navigate = useNavigate();
  const columns = useMemo(() => createColumns(), []);

  return (
    <Table
      columns={columns}
      rowKey={(account) => account.guid}
      data={bankAccounts}
      onRowClick={(account) => navigate(`/accounts/${account.guid}`)}
    />
  );
};

export default BankAccountsTable;
