import { ArrowRight, ArrowSquareOut } from "@phosphor-icons/react";
import { FC, ReactNode } from "react";
import { To, useNavigate } from "react-router-dom";

import { useCommandPaletteContext } from "../context";

import Cmdk from "./cmdk";

type Props = {
  to: To;
  children: ReactNode;
  // Pass in a unique value to prevent a highlighting bug. https://github.com/pacocoursey/cmdk/issues/345#issuecomment-2671249332
  value?: string;
};

const NavigationCommandItem: FC<Props> = ({ to, children, value }) => {
  const navigate = useNavigate();
  const { cleanup } = useCommandPaletteContext();
  const isExternal = typeof to === "string" && to.startsWith("http");

  return (
    <Cmdk.Item
      value={value}
      onSelect={() => {
        if (isExternal) {
          window.open(to, "_blank");
        } else {
          navigate(to);
        }
        cleanup();
      }}
      className="flex cursor-pointer items-center gap-x-4 border-b border-grey-100 p-3 last:border-b-0 hover:bg-grey-50 data-[selected=true]:bg-grey-50"
    >
      {children}
      {isExternal ? (
        <ArrowSquareOut size={20} weight="thin" className="ml-auto" />
      ) : (
        <ArrowRight size={20} weight="thin" className="ml-auto" />
      )}
    </Cmdk.Item>
  );
};

export default NavigationCommandItem;
