import { useMutation } from "@tanstack/react-query";
import { useRefreshAllBankAccountQueries } from "modules/bank-accounts/queries/useBankAccounts";
import useRefreshCapitalAccountTransactions from "modules/capital-account-transactions/queries/useRefreshCapitalAccountTransactions";
import { useRefreshAllCapitalAccountsQueries } from "modules/capital-accounts/queries/useCapitalAccounts";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useMfa from "modules/mfa/useMfa";
import { useState } from "react";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";
import { v4 as uuidv4 } from "uuid";

import { LINE_OF_CREDIT_INTEREST_FEE_QUERY_KEY } from "../queries/useLineOfCreditInterestFee";

type Data = {
  lineOfCreditGuid: string;
  amountInCents: number;
  transferFromBankAccountGuid: string;
};

const useCreateLineOfCreditRepaymentMutation = () => {
  const { mfa } = useMfa();
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();
  const refreshCapitalAccountsQueries = useRefreshAllCapitalAccountsQueries();
  const refreshAllBankAccountsQueries = useRefreshAllBankAccountQueries();
  const refreshLineOfCreditInterestFeeQuery = useRefreshQuery([
    LINE_OF_CREDIT_INTEREST_FEE_QUERY_KEY,
  ]);
  const refreshCapitalAccountTransactions = useRefreshCapitalAccountTransactions();

  // NB(alex): We should move this to `react-hook-form` once we get around to it. Context: https://highbeamco.slack.com/archives/C02M3GGQPHC/p1690914198052609?thread_ts=1690912209.739389&cid=C02M3GGQPHC
  const [idempotencyKey, setIdempotencyKey] = useState(uuidv4());

  return useMutation({
    mutationFn: async (data: Data) => {
      await mfa();

      return highbeamApi.lineOfCreditTransactions.createRepayment(
        businessGuid,
        data.lineOfCreditGuid,
        data.amountInCents,
        idempotencyKey,
        data.transferFromBankAccountGuid
      );
    },
    onError: () => {
      notify("warning", "There was an issue making the payment. Please try again.");
    },
    onSuccess: async () => {
      await Promise.all([
        refreshCapitalAccountsQueries(),
        refreshAllBankAccountsQueries(),
        refreshLineOfCreditInterestFeeQuery(),
        refreshCapitalAccountTransactions(),
      ]);
      notify("success", "Repayment successful");
      setIdempotencyKey(uuidv4());
    },
  });
};

export default useCreateLineOfCreditRepaymentMutation;
