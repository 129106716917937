import { FC } from "react";
import { AiTaskUpdateRep } from "reps/ai-tasks/AiTaskRep";
import Divider from "ui/data-display/Divider";
import { notify } from "ui/feedback/Toast";
import { Paragraph } from "ui/typography";

import useUpdateAiTaskMutation from "../../mutations/useUpdateAiTaskMutation";
import { useRefreshAiTasks } from "../../queries/useAiTasks";
import AiTaskPillComponent from "../AiTaskPillComponent";

import BaseAiTaskModal, { BaseAiTaskModalProps } from "./BaseAiTaskModal";
import { ModalIcon } from "./ModalIcon";

type Props = Omit<BaseAiTaskModalProps, "variant">;

const TurnOffTaskModal: FC<Props> = ({
  isOpen,
  onClose,
  title,
  description,
  onConfirm,
  onCancel,
  className,
  children,
  taskDescription,
  task,
}) => {
  const refreshTasks = useRefreshAiTasks();

  const updateTaskMutation = useUpdateAiTaskMutation(task!.id, {
    onSuccess: () => {
      refreshTasks();
      notify("success", "Task turned off successfully");
    },
  });

  const config = {
    title: title || "Turn off task",
    confirmButtonText: "Turn off",
    confirmButtonVariant: "secondary" as const,
    confirmButtonClassName:
      "border border-red-100 bg-red-50 text-red-500 shadow-xs data-[disabled=false]:hover:bg-red-100 data-[disabled=false]:hover:shadow-sm data-[disabled=false]:active:shadow-inset-red",
    cancelButtonText: "Cancel",
  };

  const handleConfirm = () => {
    const updatePayload: AiTaskUpdateRep = {
      status: "Inactive",
      description: `${task!.type} turned off`,
    };

    updateTaskMutation.mutate(updatePayload);
  };

  return (
    <BaseAiTaskModal
      isOpen={isOpen}
      onClose={onClose}
      title={title || config.title}
      config={config}
      onConfirm={onConfirm}
      onCancel={onCancel}
      className={className}
      onConfirmClick={handleConfirm}
      task={task}
    >
      <div className="mb-6">
        <div className="mb-4 flex items-center gap-3">
          <ModalIcon taskType={task!.type} />
          {taskDescription && (
            <Paragraph className="text-base px-2 font-[500]">{taskDescription}</Paragraph>
          )}
          <AiTaskPillComponent status={task!.status} className="ml-auto" />
        </div>
        <Divider />
        {description && (
          <Paragraph className="mt-4 text-grey-600">
            If you turn off this task, it will stop any activities immediately. You can turn the
            task back on at any time you&apos;d like.
          </Paragraph>
        )}
      </div>
      {children}
    </BaseAiTaskModal>
  );
};

export default TurnOffTaskModal;
