import { DependencyList, useCallback, useEffect } from "react";

/**
 * Listens for keyboard events.
 * The {@param handler} will be called when an event is found.
 * {@param deps} is a React dependency list, just like you'd pass to {@see useCallback} or {@see useEffect}.
 */
const useDocumentKeyboardEvent = (
  handler: (event: KeyboardEvent) => void,
  deps: DependencyList
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleEvent = useCallback(handler, deps);

  useEffect(() => {
    document.addEventListener("keydown", handleEvent);
    return () => document.removeEventListener("keydown", handleEvent);
  }, [handleEvent]);
};

export default useDocumentKeyboardEvent;
