import highbeamLogo from "assets/highbeam-logo-purple-bg.svg";
import env from "env";
import OnboardingLayout from "layouts/OnboardingLayout";
import { FC, useCallback } from "react";
import uncappedLogo from "ui/icons/logos/assets/uncapped.png";
import Button from "ui/inputs/Button";
import { Link, Paragraph } from "ui/typography";
import UnorderedList from "ui/typography/UnorderedList";
import useSearchParamValue from "utils/search-params/useSearchParamValue";

// NB(jashan):
//  The lender name will be generalized in the future as we expand the developer platform
const LENDER_NAME = "Uncapped";

const AuthPermissions: FC = () => {
  return (
    <div className="w-full rounded-lg border border-grey-200 bg-white p-6">
      <UnorderedList>
        <UnorderedList.Item className="text-md text-grey-500">
          Read your Highbeam business and banking information
        </UnorderedList.Item>
        <UnorderedList.Item className="text-md text-grey-500">
          Initiate recurring electronic repayments
        </UnorderedList.Item>
      </UnorderedList>
    </div>
  );
};

const LenderOAuthPermissionsPage: FC = () => {
  const [redirectState] = useSearchParamValue("state");

  const onAuthSelected = useCallback(
    (accepted: boolean) => {
      const redirectParams = new URLSearchParams();
      redirectParams.set("state", redirectState);

      if (!accepted) {
        redirectParams.set("error", "access_denied");
      }

      const redirectUrl = `https://${env.AUTH0_CUSTOM_DOMAIN}/continue?${redirectParams.toString()}`;
      window.location.replace(redirectUrl);
    },
    [redirectState]
  );

  return (
    <OnboardingLayout>
      <div className="mt-8 flex items-center">
        <div className="relative h-12 w-24">
          <div className="absolute left-0 z-20 flex h-12 w-[3.35rem] items-start rounded-lg bg-grey-50">
            {/*NB(jashan): Generalize logo rendering*/}
            <img src={uncappedLogo} alt={`${LENDER_NAME} logo`} className="size-12 rounded-lg" />
          </div>
          <img
            src={highbeamLogo}
            alt="Highbeam logo"
            className="absolute left-12 z-10 size-12 rounded-lg"
          />
        </div>
      </div>
      <div className="mx-auto flex max-w-screen-mobile-phone flex-col items-start gap-3 self-stretch p-6">
        <Paragraph className="mt-4 text-md text-grey-500">
          {`You are giving ${LENDER_NAME} permission to:`}
        </Paragraph>
        <AuthPermissions />
        {/*TODO(jashan): Add href to links for terms and conditions*/}
        <Paragraph className="my-4 text-center text-sm text-grey-500">
          By clicking Accept, you agree to the{" "}
          <Link href="">Highbeam Data Sharing Terms and Conditions</Link> and{" "}
          <Link href="">Funded Through Highbeam Payment Authorization Terms</Link>.
        </Paragraph>
      </div>
      <Button variant="primary" className="mb-2" onClick={() => onAuthSelected(true)}>
        Accept
      </Button>
      <Button variant="ghost" onClick={() => onAuthSelected(false)}>
        Decline
      </Button>
    </OnboardingLayout>
  );
};

export default LenderOAuthPermissionsPage;
