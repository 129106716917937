import { BulletedListChatElementRep } from "modules/chat/reps/ChatElementRep";
import React from "react";

import ParagraphChatElement from "./ParagraphChatElement";

type Props = {
  element: BulletedListChatElementRep;
};

const BulletedListChatElement: React.FC<Props> = ({ element }) => {
  return (
    <ul className="list-disc pl-7">
      {element.items.map((item, i) => (
        <li key={i} className="mb-2 last:mb-0">
          <ParagraphChatElement element={item} />
        </li>
      ))}
    </ul>
  );
};

export default BulletedListChatElement;
