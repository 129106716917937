import isHighCreditUtilization from "modules/line-of-credit/utils/isHighCreditUtilization";
import { FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import BarChart, { BarChartBarColor } from "ui/data-visualization/BarChart";
import variants from "utils/ts/variants";

import capitalRepaymentAmountsQueryHooks from "../queries/capitalRepaymentAmountsQueryHooks";
import { CapitalAccountSummaryWithChargeCard } from "../utils/isCapitalAccountSummaryWithChargeCard";

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
  height: 32 | 10;
};

const ChargeCardCapitalAccountBarChart: FC<Props> = ({
  capitalAccountSummaryWithChargeCard,
  height,
}) => {
  const { limit } = capitalAccountSummaryWithChargeCard.details;

  const capitalRepaymentAmounts = capitalRepaymentAmountsQueryHooks.useData({
    capitalAccountGuid: capitalAccountSummaryWithChargeCard.guid,
    enabled: capitalAccountSummaryWithChargeCard.state === CapitalAccountRep.State.Active,
    select: (data) => data.amounts,
  });

  const currentStatementAmountComplete = capitalRepaymentAmounts.find(
    (amount) => amount.type === "CurrentStatement"
  );
  const currentStatementAmount = currentStatementAmountComplete?.amount ?? 0;
  const lastStatementAmountComplete = capitalRepaymentAmounts.find(
    (amount) => amount.type === "LastStatement"
  );
  const lastStatementAmount = lastStatementAmountComplete?.amount ?? 0;
  const overdueAmountComplete = capitalRepaymentAmounts.find((amount) => amount.type === "Overdue");
  const overdueAmount = overdueAmountComplete?.amount ?? 0;

  const currentStatementPercentage = (currentStatementAmount / limit) * 100;
  const lastStatementPercentage = (lastStatementAmount / limit) * 100;
  const overduePercentage = (overdueAmount / limit) * 100;
  const availableAmountPercentage =
    100 - currentStatementPercentage - lastStatementPercentage - overduePercentage;

  return (
    <BarChart
      height={height}
      barHeight={height === 32 ? 16 : undefined}
      backgroundVariant="transparent"
      border={height === 32 ? "grey-light" : undefined}
    >
      {availableAmountPercentage > 0 && (
        <BarChart.Bar
          widthPercentage={availableAmountPercentage}
          minWidthPercentageWhenGreaterThanZero={1}
          color={variants(capitalAccountSummaryWithChargeCard.state, {
            Active: isHighCreditUtilization(capitalAccountSummaryWithChargeCard)
              ? "yellow"
              : "green",
            Offered: "green-disabled",
            OfferAccepted: "green-disabled",
            Terminated: "grey-solid",
          } satisfies Record<CapitalAccountRep.State, BarChartBarColor>)}
        />
      )}
      {overduePercentage > 0 && (
        <BarChart.Bar
          widthPercentage={overduePercentage}
          minWidthPercentageWhenGreaterThanZero={1}
          color="red-striped"
        />
      )}
      {lastStatementPercentage > 0 && (
        <BarChart.Bar
          widthPercentage={lastStatementPercentage}
          minWidthPercentageWhenGreaterThanZero={1}
          color="orange-striped"
        />
      )}
      {currentStatementPercentage > 0 && (
        <BarChart.Bar
          widthPercentage={currentStatementPercentage}
          minWidthPercentageWhenGreaterThanZero={1}
          color="grey-striped"
        />
      )}
    </BarChart>
  );
};

export default ChargeCardCapitalAccountBarChart;
