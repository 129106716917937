import useBankAccountInterestYieldYtd from "modules/bank-accounts/queries/useBankAccountInterestYieldYtd";
import MoneyAmount from "ui/data-display/money/MoneyAmount";

const AccountsOverviewInterestYtd = () => {
  const interestYtdCents = useBankAccountInterestYieldYtd();

  return (
    <MoneyAmount
      color="green"
      cents={interestYtdCents}
      size={18}
      centsTextSize={14}
      weight="medium"
    />
  );
};

export default AccountsOverviewInterestYtd;
