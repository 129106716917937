import { ArrowFatLineDown } from "@phosphor-icons/react";
import { FEATURE_FLAGS } from "flags";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import MultiStep from "layouts/MultiStep";
import CapitalAccountBreadcrumbItem from "modules/capital-accounts/components/CapitalAccountBreadcrumbItem";
import capitalAccountSummaryQueryHooks from "modules/capital-accounts/queries/capitalAccountSummaryQueryHooks";
import isAbleToDrawDown from "modules/capital-accounts/utils/isAbleToDrawDown";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import useLineOfCreditAgreement from "modules/line-of-credit-agreement/queries/useLineOfCreditAgreement";
import { FC } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import useIsAllowedToDrawDownCapital from "utils/permissions/useIsAllowedToDrawDownCapital";

import DrawdownAmountView from "./components/DrawdownAmountView";
import DrawdownConfirmationView from "./components/DrawdownConfirmationView";
import DrawdownInvoiceView from "./components/DrawdownInvoiceView";
import DrawdownSteps from "./components/DrawdownSteps";
import DrawdownProvider from "./context/DrawdownProvider";

type DrawdownPageContentProps = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const DrawdownPageContent: FC<DrawdownPageContentProps> = ({ capitalAccountSummary }) => {
  const isDrawdownApprovalRequired = capitalAccountSummary.controls.drawdownRequiresApproval;
  const isAllowedToDrawDownCapital = useIsAllowedToDrawDownCapital();

  if (!isAbleToDrawDown(capitalAccountSummary) || !isAllowedToDrawDownCapital) {
    return <Navigate to={`/capital/${capitalAccountSummary.guid}`} />;
  }

  return (
    <>
      <DashboardPage.Header>
        <DashboardPage.Header.BackCaret to={`/capital/${capitalAccountSummary.guid}`} />
        <DashboardPage.Header.IconTile icon={<ArrowFatLineDown />} />
        <DashboardPage.Header.Title>Draw down</DashboardPage.Header.Title>
      </DashboardPage.Header>

      <DashboardPage.Section>
        <DrawdownProvider capitalAccountSummary={capitalAccountSummary}>
          <MultiStep steps={<DrawdownSteps />}>
            <Routes>
              <Route
                index
                element={<DrawdownAmountView capitalAccountSummary={capitalAccountSummary} />}
              />
              {isDrawdownApprovalRequired && (
                <Route
                  path="/invoice"
                  element={<DrawdownInvoiceView capitalAccountSummary={capitalAccountSummary} />}
                />
              )}
              <Route
                path="/confirm"
                element={<DrawdownConfirmationView capitalAccountSummary={capitalAccountSummary} />}
              />
            </Routes>
          </MultiStep>
        </DrawdownProvider>
      </DashboardPage.Section>
    </>
  );
};

const DrawdownPage = () => {
  const { capitalAccountGuid } = useParams();
  const { data: capitalAccountSummary } = capitalAccountSummaryQueryHooks.useQuery({
    capitalAccountGuid: capitalAccountGuid ?? null,
  });

  const isUnacceptedAgreementFlagEnabled = useFeatureFlag(
    FEATURE_FLAGS["capital-unaccepted-agreement-flow"]
  );
  const lineOfCreditAgreement = useLineOfCreditAgreement(capitalAccountGuid ?? null);
  const isSignedLineAgreement = Boolean(lineOfCreditAgreement?.agreementSignedAt);

  // Redirect to capital page if no capitalAccountGuid
  if (capitalAccountSummary === null) {
    return <Navigate to="/capital" />;
  }

  if (isUnacceptedAgreementFlagEnabled && !isSignedLineAgreement) {
    return (
      <Navigate
        to={`/capital/${capitalAccountGuid}/review-updated-line-agreement`}
        state={{ from: `/capital/${capitalAccountGuid}/draw-down` }}
      />
    );
  }

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/capital">Capital</Breadcrumbs.Item>
          <CapitalAccountBreadcrumbItem
            capitalAccountSummary={capitalAccountSummary}
            isCurrentItem={false}
          />
          <Breadcrumbs.CurrentItem>Draw down</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        {capitalAccountSummary ? (
          <DrawdownPageContent capitalAccountSummary={capitalAccountSummary} />
        ) : (
          <DashboardPage.DotsLoader />
        )}
      </DashboardPage>
    </>
  );
};

export default DrawdownPage;
