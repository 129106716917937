import { ChatTeardropText } from "@phosphor-icons/react";
import { FC } from "react";
import HighbeamMarkdown from "ui/data-display/HighbeamMarkdown";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

type Props = PropsWithChildrenAndClassName;

const ChatSuggestionBubble: FC<Props> = ({ children, className }) => {
  return (
    <div
      className={cn(
        "flex w-fit items-center gap-x-2.5 rounded-full border border-dashed border-grey-200 px-4 py-2 text-sm text-grey-500",
        className
      )}
    >
      <ChatTeardropText size={20} className="-scale-x-100" weight="light" />
      {typeof children === "string" ? <HighbeamMarkdown>{children}</HighbeamMarkdown> : children}
    </div>
  );
};

export default ChatSuggestionBubble;
