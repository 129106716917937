import { Card } from "@highbeam/unit-node-sdk";

const getCardSupportNumber = (card: Card) => {
  switch (card.type) {
    case "individualVirtualDebitCard":
    case "businessVirtualDebitCard":
    case "individualDebitCard":
    case "businessDebitCard":
      return "+1 (833) 852-1519";
    case "businessVirtualCreditCard":
    case "businessCreditCard":
      return "+1 (833) 852-1518";
  }
};

export default getCardSupportNumber;
