import useAccountingAccount from "modules/accounting-accounts/queries/useAccountingAccount";
import { FALLBACK_NAME as ACCOUNTING_CLASS_CODE_FALLBACK_NAME } from "modules/accounting-class-codes/components/AccountingClassCodeBar";
import { useAccountingClassCodes } from "modules/accounting-class-codes/queries/accountingClassCodesQueryHooks";
import { usePayeeClassCode } from "modules/accounting-class-codes/queries/payeeClassCodeQueryHooks";
import { useApSettings } from "modules/ap-settings/queries/apSettingsQueryHooks";
import { FC } from "react";
import PayeeRep from "reps/PayeeRep";
import CopyIconWithTooltip from "ui/overlay/CopyIconWithTooltip";
import Text from "ui/typography/Text";

import styles from "./PayeeDetailsInfo.module.scss";

type RowData = {
  name: string;
  copyValue?: string;
  data?: React.ReactNode;
};

type Props = {
  payee: PayeeRep.Complete;
};

type DisplayTableProps = {
  data: RowData[];
};

const NoValueText: FC = () => (
  <Text size={14} weight="medium" className={styles["table-row__value"]}>
    -
  </Text>
);

type DefaultChartOfAccountDisplayProps = {
  defaultChartOfAccountId: string;
};

const DefaultChartOfAccountDisplay: FC<DefaultChartOfAccountDisplayProps> = ({
  defaultChartOfAccountId,
}) => {
  const accountingAccount = useAccountingAccount(defaultChartOfAccountId);

  return (
    <Text size={14} weight="medium" className={styles["table-row__value"]}>
      {accountingAccount.name}
    </Text>
  );
};

type PayeeClassCodeDisplayProps = {
  payeeGuid: string;
};

const PayeeClassCodeDisplay: FC<PayeeClassCodeDisplayProps> = ({ payeeGuid }) => {
  const accountingClassCodes = useAccountingClassCodes();
  const payeeClassCode = usePayeeClassCode(payeeGuid);

  if (!payeeClassCode) {
    return <NoValueText />;
  }

  return (
    <Text size={14} weight="medium" className={styles["table-row__value"]}>
      {accountingClassCodes.find(
        (accountingClassCode) => accountingClassCode.id === payeeClassCode.classCodeId
      )?.name ?? ACCOUNTING_CLASS_CODE_FALLBACK_NAME}
    </Text>
  );
};

const getTableText = (text?: string, isNumeric = false) => {
  if (!text) {
    return <NoValueText />;
  }

  return (
    <Text size={14} weight="medium" numeric={isNumeric} className={styles["table-row__value"]}>
      {text}
    </Text>
  );
};

const DisplayTable: FC<DisplayTableProps> = ({ data }) => (
  <table className={styles.table}>
    <tbody>
      {data.map((row: RowData) => (
        <tr className={styles["table-row"]} key={row.name}>
          <td>
            <Text size={14} className={styles["table-row__name"]}>
              {row.name}
            </Text>
          </td>
          <td>{row.data}</td>
          <td>
            {row.copyValue && (
              <CopyIconWithTooltip textToCopy={row.copyValue}></CopyIconWithTooltip>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export const PayeeDetailsTable: FC<Props> = ({ payee }) => {
  const { defaultChartOfAccountId } = payee;
  const apSettings = useApSettings();
  const accountingClassCodes = useAccountingClassCodes();

  const payeeDetails: RowData[] = [
    { name: "Name", data: getTableText(payee.name) },
    { name: "Email", data: getTableText(payee.emailAddress ?? undefined) },
    { name: "Phone number", data: getTableText(payee.phone ?? undefined) },
  ];

  if (accountingClassCodes.length > 0) {
    payeeDetails.push({
      name: "Accounting class",
      data: <PayeeClassCodeDisplay payeeGuid={payee.guid} />,
    });
  }

  if (apSettings) {
    payeeDetails.push({
      name: "Accounting category",
      data: defaultChartOfAccountId ? (
        <DefaultChartOfAccountDisplay defaultChartOfAccountId={defaultChartOfAccountId} />
      ) : (
        <NoValueText />
      ),
    });
  }

  return <DisplayTable data={payeeDetails} />;
};
