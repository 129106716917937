import { RecoilState } from "recoil";
import { EmptyObject } from "type-fest";
import { useTransition } from "utils/transitions";

import useDialog, { DialogState } from "./useDialog";

/**
 * DEPRECATED: Please do not use recoil for managing modals.
 * Wraps `useDialog` and `useTransition` to preserve a smooth animation for modals when closing.
 */
const useModal = <TOpenState extends Record<string, unknown> = EmptyObject>(
  modalRecoilState: RecoilState<DialogState<TOpenState>>
) => {
  const { isOpen, open, close } = useDialog(modalRecoilState);

  const { show, setShow, handleClose } = useTransition(isOpen, {
    initiateClose() {
      setShow(false);
    },
    onClose: close,
  });

  return {
    isOpen: show,
    open: open,
    close: handleClose,
  };
};

export default useModal;
