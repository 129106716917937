import CapitalAccountPage from "pages/capital/CapitalAccountPage";
import CapitalDocumentsPage from "pages/capital/CapitalDocumentsPage";
import CapitalOverviewPage from "pages/capital/CapitalOverviewPage";
import CapitalRepayPage from "pages/capital/CapitalRepayPage";
import CreditApplicationPage from "pages/capital/CreditApplicationPage";
import DrawdownPage from "pages/capital/DrawdownPage";
import ReviewLineOfferPage from "pages/capital/ReviewLineOfferPage";
import ReviewUpdatedLineAgreementPage from "pages/capital/ReviewUpdatedLineAgreementPage";
import { Route, Routes } from "react-router-dom";

const CapitalRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<CapitalOverviewPage />} />
      <Route path="/documents" element={<CapitalDocumentsPage />} />
      <Route path="/application/*" element={<CreditApplicationPage />} />
      <Route path="/:capitalAccountGuid" element={<CapitalAccountPage />}>
        <Route path="pending-drawdowns/:pendingDrawdownGuid" element={<CapitalAccountPage />} />
      </Route>
      <Route path="/:capitalAccountGuid/repay/*" element={<CapitalRepayPage />} />
      <Route path="/:capitalAccountGuid/draw-down/*" element={<DrawdownPage />} />
      <Route path="/:capitalAccountGuid/review-line-offer/*" element={<ReviewLineOfferPage />} />
      <Route
        path="/:capitalAccountGuid/review-updated-line-agreement"
        element={<ReviewUpdatedLineAgreementPage />}
      />
    </Routes>
  );
};

export default CapitalRoutes;
