import { ArrowFatLineUp, ArrowRight } from "@phosphor-icons/react";
import { FC, useMemo } from "react";
import { HighbeamAccountTypeName } from "reps/BankAccountRep";
import AccountBar from "ui/data-display/AccountBar";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import { Span } from "ui/typography";
import { ArrayOfUnion } from "utils/ts/ArrayOfUnion";
import variants from "utils/ts/variants";

import { yieldPercentByAccountType } from "../constants/yield";

import HighbeamAccountIcon from "./HighbeamAccountIcon";

const highbeamBankAccountTypes: ArrayOfUnion<HighbeamAccountTypeName> = [
  "DepositAccount",
  "HighYield",
];

const createColumns = (): Column<HighbeamAccountTypeName>[] => [
  {
    title: "Account",
    cellRender: (accountType) => {
      const accountName = variants(accountType, {
        DepositAccount: "Primary",
        HighYield: "High yield",
      });

      return (
        <AccountBar
          icon={<HighbeamAccountIcon highbeamTypeName={accountType} />}
          accountName={accountName}
        />
      );
    },
  },
  {
    title: "",
    key: "apy",
    align: TableColumnAlignment.RIGHT,
    cellRender: (accountType) => {
      const apy = yieldPercentByAccountType[accountType];
      return (
        <Span className="flex items-center gap-x-2 text-xs font-medium text-purple-500">
          <ArrowFatLineUp size={16} />
          <Span className="text-inherit">Up to {apy} APY</Span>
        </Span>
      );
    },
  },
  {
    title: "",
    key: "verifyBusiness",
    align: TableColumnAlignment.RIGHT,
    width: 160,
    cellRender: () => {
      return (
        <div className="flex items-center gap-x-1 text-xs font-medium text-grey-800">
          Verify business
          <ArrowRight size={16} />
        </div>
      );
    },
  },
];

type Props = {
  onRowClick: () => void;
};

const BankAccountsUpsellTable: FC<Props> = ({ onRowClick }) => {
  const columns = useMemo(() => createColumns(), []);

  return (
    <Table
      columns={columns}
      rowKey={(name) => name}
      data={highbeamBankAccountTypes}
      onRowClick={onRowClick}
    />
  );
};

export default BankAccountsUpsellTable;
