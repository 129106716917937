import { ArrowRight } from "@phosphor-icons/react";
import CapitalAccountBar from "modules/capital-accounts/components/CapitalAccountBar";
import capitalAccountSummaryQueryHooks from "modules/capital-accounts/queries/capitalAccountSummaryQueryHooks";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import CapitalAccountRep from "reps/CapitalAccountRep";
import colors from "styles/colors";
import MoneyAmountFraction from "ui/data-display/money/MoneyAmountFraction";
import AmountCell from "ui/table/cells/AmountCell";
import Table, { TableColumnAlignment } from "ui/table/Table";

const CapitalAccountBalanceCell: FC<{ capitalAccountGuid: string }> = ({ capitalAccountGuid }) => {
  const capitalAccountSummary = capitalAccountSummaryQueryHooks.useDataRequired({
    capitalAccountGuid,
  });

  return (
    <AmountCell
      className="font-medium text-grey-800"
      cents={capitalAccountSummary.details.limit - capitalAccountSummary.available}
    />
  );
};

const CapitalAccountUsageCell: FC<{ capitalAccountGuid: string }> = ({ capitalAccountGuid }) => {
  const capitalAccountSummary = capitalAccountSummaryQueryHooks.useDataRequired({
    capitalAccountGuid,
  });

  return (
    <MoneyAmountFraction
      numeratorColor={colors.grey[800]}
      numeratorInCents={Math.max(0, capitalAccountSummary.available)}
      denominatorInCents={capitalAccountSummary.details.limit}
    />
  );
};

type Props = {
  capitalAccounts: CapitalAccountRep.Complete[];
};

const CapitalAccountsTable: FC<Props> = ({ capitalAccounts }) => {
  const navigate = useNavigate();

  // TODO: Handle empty state https://highbeamco.slack.com/archives/C03R625F068/p1715788676966769

  return (
    <Table
      data={capitalAccounts}
      columns={[
        {
          title: "Account",
          cellRender: (capitalAccount) => <CapitalAccountBar capitalAccount={capitalAccount} />,
        },
        {
          title: "Balance",
          align: TableColumnAlignment.RIGHT,
          cellRender: (capitalAccount) => {
            return <CapitalAccountBalanceCell capitalAccountGuid={capitalAccount.guid} />;
          },
        },
        {
          title: "Available",
          align: TableColumnAlignment.RIGHT,
          cellRender: (capitalAccount) => (
            <CapitalAccountUsageCell capitalAccountGuid={capitalAccount.guid} />
          ),
        },
        {
          title: "",
          align: TableColumnAlignment.RIGHT,
          width: 160,
          cellRender: () => (
            <div className="flex items-center justify-end gap-x-1.5">
              <ArrowRight size={16} />
            </div>
          ),
        },
      ]}
      rowKey={(capitalAccount) => capitalAccount.guid}
      onRowClick={(capitalAccount) => navigate(`/capital/${capitalAccount.guid}`)}
    />
  );
};

export default CapitalAccountsTable;
