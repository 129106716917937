import { FC, ReactNode } from "react";
import AccountingAccountRep from "reps/AccountingAccountRep";
import ItemDepthIndicator from "ui/data-display/ItemDepthIndicator";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

import makeAccountingAccountDisplayName from "../../utils/makeAccountingAccountDisplayName";

type NominalCodeProps = {
  nominalCode: string;
};

const NominalCode: FC<NominalCodeProps> = ({ nominalCode }) => (
  <span className="font-monospace text-xs text-grey-400">{nominalCode} —</span>
);

type Props = PropsWithChildrenAndClassName & {
  accountingAccount: AccountingAccountRep.Complete;
  depth?: number;
  children?:
    | ReactNode
    | ((props: { accountingAccount: AccountingAccountRep.Complete; depth?: number }) => ReactNode);
};

const AccountingAccountBar: FC<Props> = ({ accountingAccount, depth, className, children }) => {
  const { nominalCode } = accountingAccount;

  return (
    <div className={cn("flex items-center gap-[1ex] whitespace-nowrap text-sm", className)}>
      {children ? (
        <>{typeof children === "function" ? children({ accountingAccount, depth }) : children}</>
      ) : (
        <>
          {typeof depth === "number" && depth > 0 && <ItemDepthIndicator depth={depth} />}
          {nominalCode && <NominalCode nominalCode={nominalCode} />}
          <span>{makeAccountingAccountDisplayName(accountingAccount)}</span>
        </>
      )}
    </div>
  );
};

export default Object.assign(AccountingAccountBar, {
  NominalCode,
});
