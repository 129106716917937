import { useChatWidget } from "components/ChatWidget";
import DataSourcesModal from "modules/chat/components/DataSourcesModal/DataSourcesModal";
import { DataSources, useDataSources } from "modules/chat/queries/useDataSources";
import { ActionChatElementRep } from "modules/chat/reps/ChatElementRep";
import React from "react";
import Button from "ui/inputs/Button";
import useModalV2 from "utils/dialog/useModalV2";
import variants from "utils/ts/variants";

type Props = {
  element: ActionChatElementRep;
};

const ActionChatElement: React.FC<Props> = ({ element }) => {
  const actionButton = variants(element.action, {
    ContactSupport: <ContactSupportAction buttonText={element.text} />,
    ViewChatDataConnections: <ViewChatDataConnectionsAction buttonText={element.text} />,
  });
  return actionButton;
};

type ActionButtonProps = {
  buttonText: string;
};

const ContactSupportAction: React.FC<ActionButtonProps> = ({ buttonText }) => {
  const chat = useChatWidget();

  return (
    <Button variant="ghost" paddingVariant="bare" className="text-purple-500" onClick={chat.show}>
      {buttonText}
    </Button>
  );
};

const ViewChatDataConnectionsAction: React.FC<ActionButtonProps> = ({ buttonText }) => {
  const dataSources = useDataSources();
  const dataSourcesModal = useModalV2<{ dataSources: DataSources }>();
  return (
    <>
      {dataSourcesModal.isOpen && (
        <DataSourcesModal {...dataSourcesModal.params} onClose={dataSourcesModal.close} />
      )}
      <Button
        variant="ghost"
        paddingVariant="bare"
        className="text-purple-500"
        onClick={() => {
          dataSourcesModal.open({ dataSources });
        }}
      >
        {buttonText}
      </Button>
    </>
  );
};

export default ActionChatElement;
