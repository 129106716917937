type LoadingPoint = {
  timestamp: number;
  percentage: number;
};

/**
 * Generates a list of timestamps for each percentage loaded for a given duration.
 */
const generateLoadingPoints = (duration: number): LoadingPoint[] => {
  const step = 100; // Update every 100ms
  const totalSteps = Math.ceil(duration / step);

  return Array.from({ length: totalSteps + 1 }, (_, i) => ({
    timestamp: i * step,
    percentage: Math.round((i / totalSteps) * 99),
  }));
};

export default generateLoadingPoints;
