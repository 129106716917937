import { RefObject, useEffect } from "react";
import {
  focusNextElement,
  focusPreviousElement,
  getPreviousFocusableElement,
} from "utils/browser/focus-element";

type Params = {
  drawerContainerRef: RefObject<HTMLDivElement>;
  textAreaRef: RefObject<HTMLTextAreaElement>;
};

const useChatPageKeyboardEvents = ({ drawerContainerRef, textAreaRef }: Params) => {
  useEffect(() => {
    const drawerContainerElement = drawerContainerRef.current;
    if (!drawerContainerElement) return;

    const handleTextAreaKeyDown = (e: KeyboardEvent) => {
      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        e.preventDefault();

        if (e.key === "ArrowUp") {
          const previousFocusableElement = getPreviousFocusableElement();
          if (
            !previousFocusableElement ||
            !drawerContainerElement.contains(previousFocusableElement)
          ) {
            return;
          }
          previousFocusableElement.focus();
        } else if (e.key === "ArrowDown") {
          focusNextElement();
        }
      }
    };

    drawerContainerElement.addEventListener("keydown", handleTextAreaKeyDown);

    return () => {
      return drawerContainerElement.removeEventListener("keydown", handleTextAreaKeyDown);
    };
  }, [drawerContainerRef]);

  useEffect(() => {
    const textAreaElement = textAreaRef.current;
    if (!textAreaElement) return;

    const handleTextAreaKeyDown = (e: KeyboardEvent) => {
      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        e.preventDefault();

        if (e.key === "ArrowUp") {
          focusPreviousElement();
        }
      }
    };

    textAreaElement.addEventListener("keydown", handleTextAreaKeyDown);

    return () => {
      return textAreaElement.removeEventListener("keydown", handleTextAreaKeyDown);
    };
  }, [textAreaRef]);
};

export default useChatPageKeyboardEvents;
