import { ComponentProps, ComponentPropsWithoutRef, ElementRef, FC, forwardRef } from "react";
import useTopLayerPortalContainer from "ui/hooks/useTopLayerPortalContainer";
import createComponentContext from "utils/react-helpers/createComponentContext";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";
import { DialogProps, Drawer as DrawerPrimitive } from "vaul";

export type DrawerDirection = Exclude<DialogProps["direction"], undefined>;

type DrawerContextValue = {
  direction: DrawerDirection;
};

const [DrawerProvider, useDrawerContext] = createComponentContext<DrawerContextValue>("Drawer");

const Drawer = ({
  shouldScaleBackground = true,
  direction = "right",
  ...props
}: ComponentProps<typeof DrawerPrimitive.Root>) => {
  return (
    <DrawerProvider value={{ direction }}>
      <DrawerPrimitive.Root
        shouldScaleBackground={shouldScaleBackground}
        direction={direction}
        {...props}
      />
    </DrawerProvider>
  );
};

const DrawerTrigger = DrawerPrimitive.Trigger;

const DrawerPortal = DrawerPrimitive.Portal;

const DrawerClose = DrawerPrimitive.Close;

const DrawerOverlay = forwardRef<
  ElementRef<typeof DrawerPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Overlay
    ref={ref}
    className={cn("fixed inset-0 z-modal-backdrop bg-black/50", className)}
    {...props}
  />
));

const DrawerContent = forwardRef<
  ElementRef<typeof DrawerPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>
>(({ className, children, ...props }, ref) => {
  const portalContainer = useTopLayerPortalContainer();
  const { direction } = useDrawerContext();

  return (
    <DrawerPortal container={portalContainer}>
      <DrawerOverlay />
      <DrawerPrimitive.Content
        ref={ref}
        className={cn(
          "fixed z-modal flex h-auto flex-col border border-grey-50 bg-white",
          "-m-px", // Offsets the border, which for some reason adds an extra black border in the ::after element.
          variants(direction, {
            top: "inset-x-0 top-0",
            bottom: "inset-x-0 bottom-0",
            left: "inset-y-0 left-0",
            right: "inset-y-0 right-0",
          }),
          className
        )}
        {...props}
      >
        {children}
      </DrawerPrimitive.Content>
    </DrawerPortal>
  );
});

const DrawerThumb: FC<PropsWithChildrenAndClassName> = ({ className, children }) => {
  return (
    <div className={cn("mx-auto mt-4 h-1.5 w-24 rounded-full bg-grey-100", className)}>
      {children}
    </div>
  );
};

const DrawerHeader: FC<ComponentPropsWithoutRef<"div">> = ({ className, ...props }) => (
  <div className={cn("sm:text-left grid gap-1.5 p-4 text-center", className)} {...props} />
);

const DrawerFooter: FC<ComponentPropsWithoutRef<"div">> = ({ className, ...props }) => (
  <div className={cn("mt-auto flex flex-col gap-2 p-4", className)} {...props} />
);

const DrawerTitle = forwardRef<
  ElementRef<typeof DrawerPrimitive.Title>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Title
    ref={ref}
    className={cn("font-semibold text-lg leading-none tracking-tight", className)}
    {...props}
  />
));

const DrawerDescription = forwardRef<
  ElementRef<typeof DrawerPrimitive.Description>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Description
    ref={ref}
    className={cn("text-muted-foreground text-sm", className)}
    {...props}
  />
));

export { useDrawerContext as useDrawer };

export default Object.assign(Drawer, {
  Close: DrawerClose,
  Content: DrawerContent,
  Description: DrawerDescription,
  Footer: DrawerFooter,
  Header: DrawerHeader,
  Title: DrawerTitle,
  Trigger: DrawerTrigger,
  Thumb: DrawerThumb,
});
