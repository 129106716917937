import { useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useQueryOptions from "utils/react-query/useQueryOptions";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import useApEmailAliasApi from "../api/useApEmailAliasApi";

export const makeQueryKey = (businessGuid: string) => ["ap-email-aliases", { businessGuid }];

const useApEmailAliasesQueryOptions = () => {
  const apEmailAliasApi = useApEmailAliasApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: makeQueryKey(businessGuid),
    queryFn: () => {
      return apEmailAliasApi.search(businessGuid);
    },
  });
};

const useApEmailAliases = () => {
  const { data } = useSuspenseQuery(useApEmailAliasesQueryOptions());

  return data;
};

export const useRefreshApEmailAliasesQuery = (businessGuid: string) =>
  useRefreshQuery(makeQueryKey(businessGuid));

export default useApEmailAliases;
