import { Dayjs } from "dayjs";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useQueryOptions from "utils/react-query/useQueryOptions";

import useCounterpartyReportApi from "../api/useCounterpartyReportApi";

export const COUNTERPARTY_REPORT_QUERY_KEY = "counterparty-report";

type Params = {
  monthRangeStart: Dayjs;
  monthRangeEndInclusive: Dayjs;
};

const useCounterpartyReportQueryOptions = ({ monthRangeStart, monthRangeEndInclusive }: Params) => {
  const categoryReportApi = useCounterpartyReportApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [
      COUNTERPARTY_REPORT_QUERY_KEY,
      businessGuid,
      monthRangeStart.format("YYYY-MM"),
      monthRangeEndInclusive.format("YYYY-MM"),
    ],
    queryFn: async () => {
      return categoryReportApi.search(businessGuid, monthRangeStart, monthRangeEndInclusive);
    },
  });
};

export { useCounterpartyReportQueryOptions };
