/**
 * Adapted from https://stackoverflow.com/a/18197341.
 */

export const downloadString = (
  filename: string,
  text: string,
  contentType: string = "text/plain"
) => {
  downloadBlob(filename, `data:${contentType};charset=utf-8,${encodeURIComponent(text)}`);
};

export const downloadBlob = (filename: string, href: string) => {
  const element = document.createElement("a");
  element.setAttribute("href", href);
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

/* eslint-disable no-await-in-loop */
/**
 * Download a file with the Fetch API, retrying on 404 errors.
 *
 * This is used for cases where we expect a file to become available, but possibly with a delay.
 * The main use-case for this is when users upload files they take some time to arrive at their
 * destination while we run backend validations on them, so if a user uploads a file and then
 * immediately tries to download it, it may take some time to appear.
 */
export const downloadWith404Retry = async (
  url: string,
  pollMs: number = 500,
  timeoutMs: number = 10000
): Promise<Blob> => {
  const startTime = Date.now();
  while (Date.now() - startTime < timeoutMs) {
    const res = await fetch(url, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
    });

    if (res.status === 404) {
      await new Promise((resolve) => {
        setTimeout(resolve, pollMs);
      });
      continue;
    } else if (!res.ok) {
      throw new Error(`Download failed. Status: ${res.status}; URL: ${url}`);
    }
    return await res.blob();
  }

  throw new Error(`Resource was not available after ${timeoutMs}ms. URL: ${url}`);
};
/* eslint-enable */

/**
 * Download a file from a base64-encoded string.
 *
 * This function is used for cases where a file is stored on the front-end as a base64 string.
 */
export const downloadBase64File = (base64String: string, fileName: string) => {
  const link = document.createElement("a");
  link.href = base64String;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
