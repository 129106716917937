import Regex from "utils/regex";

namespace Validator {
  export const accountNumber = (value: string) => new RegExp("^\\d{1,17}$").test(value);

  export const emailAddress = (value: string) =>
    new RegExp(`^${Regex.emailAddress.source}$`).test(value);

  export const humanName = (value: string) => 1 <= value.length && value.length <= 60;

  export const percent = (value: string) =>
    new RegExp("^(?:\\d|[1-9]\\d|100)(?:\\.\\d{1,2})?$").test(value);

  export const positive = (value: string) => new RegExp("^\\d*(?:\\.\\d*)?$").test(value);

  export const phoneNumber = (value: string) =>
    new RegExp(`^${Regex.phoneNumber.source}$`).test(value);

  export const routingNumber = (value: string) => new RegExp("^\\d{9}$").test(value);

  export const zipCode = (value: string) => new RegExp("^((\\d{5})|(\\d{9}))$").test(value);
}

export default Validator;
