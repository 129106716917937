import { CalendarBlank } from "@phosphor-icons/react";
import { captureMessage } from "@sentry/react";
import { CSSProperties, FC, ReactElement, ReactNode } from "react";
import { Span } from "ui/typography";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

import { BalanceLineChartSerieDatum } from "./BalanceLineChartSerieDatum";
import { formatXAxisValue, formatYAxisValue } from "./chart-axis-formatter-utils";
import ChartTooltip from "./ChartTooltip";

type RenderItemProps = {
  accountSerie: BalanceLineChartSerieDatum[];
  index: number;
  value: string;
};

type Props = {
  titleLocalDate: string;
  accountSeries: BalanceLineChartSerieDatum[][];
  className?: string;
  style?: CSSProperties;
  renderItem: (props: RenderItemProps) => ReactNode;
  renderFooter?: () => ReactNode;
};

const BalanceLineChartTooltip: FC<Props> = ({
  titleLocalDate,
  accountSeries,
  className,
  style,
  renderItem,
  renderFooter,
}) => {
  const title = formatXAxisValue({ value: titleLocalDate, type: "YYYY-MM-DD", compact: false });

  return (
    <ChartTooltip className={cn("min-w-64", className)} style={style}>
      <ChartTooltip.Header>
        <CalendarBlank />
        {title}
      </ChartTooltip.Header>
      <ChartTooltip.Body>
        <div className="flex h-8 items-center justify-between text-xs text-grey-600">
          <Span className="text-inherit">Account</Span>
          <Span className="text-inherit">Balance</Span>
        </div>

        <div className="flex flex-col gap-y-3">
          {accountSeries.map((accountSerie, index) => {
            const accountSerieDataValue = accountSerie.find(([date]) => date === titleLocalDate);

            if (!accountSerieDataValue) {
              // NB(alex): Shouldn't happen but this component isn't very mature yet so I might be missing something.
              captureMessage("Invalid accountSerieDataValue", {
                extra: { accountSerieDataValue },
              });
              return null;
            }

            const value = formatYAxisValue({
              value: accountSerieDataValue[1],
              type: "money",
              compact: false,
            });

            return renderItem({ accountSerie, index, value });
          })}
        </div>
      </ChartTooltip.Body>

      {renderFooter?.()}
    </ChartTooltip>
  );
};

type BalanceLineChartTooltipItemProps = {
  children: [ReactElement, ReactElement];
  className?: string;
};

const BalanceLineChartTooltipItem: FC<BalanceLineChartTooltipItemProps> = ({
  children,
  className,
}) => {
  return <div className={cn("flex items-center justify-between", className)}>{children}</div>;
};

const BalanceLineChartTooltipFooter: FC<PropsWithChildrenAndClassName> = ({
  children,
  className,
}) => {
  return (
    <div
      className={cn(
        "mt-4 flex items-center justify-between border-t border-grey-100 pt-4",
        className
      )}
    >
      {children}
    </div>
  );
};

export default Object.assign(BalanceLineChartTooltip, {
  Item: BalanceLineChartTooltipItem,
  Footer: BalanceLineChartTooltipFooter,
});
