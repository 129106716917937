import { AssistantMessageRep } from "./AssistantMessageRep";
import { UserMessageRep } from "./UserMessageRep";

type SuggestedFollowUp = {
  /**
   * How to render the suggested follow-up button.
   * This is often the same as [content], but it may differ in some circumstances.
   */
  display: string;
  /**
   * The message content that will be sent if this suggested follow-up is used.
   */
  content: string;
  /**
   * Whether to highlight/emphasize the button more than usual.
   */
  emphasis: boolean;
  /**
   * Most suggested follow-ups open in threads, but prompt flows don't.
   */
  useThread: boolean;
};

type Feedback = {
  value: "Positive" | "Negative";
  reason: string | null;
};

/**
 * What data sources may have been used to formulate the response.
 *
 * The current approach is broad, not providing detailed sources like
 * specific accounts that queries pulled relevant data from.
 *
 * These closely correspond to prompt enrichments.
 */
type Sources = {
  bills: boolean;
  ecommerceIndustryInformation: boolean;
  financialTransactions: boolean;
  highbeamPayees: boolean;
  shopifyOrders: boolean;
};

export const anySource = (sources: Sources | null): boolean => {
  if (!sources) return false;
  return (
    sources.bills ||
    sources.ecommerceIndustryInformation ||
    sources.financialTransactions ||
    sources.highbeamPayees ||
    sources.shopifyOrders
  );
};

/**
 * An AI Chat exchange lives within a {@link ChannelRep}.
 */
type ExchangeRep = {
  id: string;
  /**
   * Exchange {@link createdAt} values are unique within a {@link ChannelRep}.
   */
  createdAt: string;
  businessGuid: string;
  channelId: string;
  /**
   * If this exchange is part of a thread, the parent ID is the ID of that {@link ExchangeRep}.
   */
  parentId: string | null;
  /**
   * The user message forms the first half of the exchange.
   * This may be null for assistant-initiated messages (such as the intro message).
   */
  userMessage: UserMessageRep | null;
  /**
   * The assistant message forms the second half of the exchange.
   * This may be transiently null while {@link isLoading} is true,
   * or permanently null if {@link process} is false.
   */
  assistantMessage: AssistantMessageRep | null;
  /**
   * Whether to process and respond to the message.
   * If true, the assistant will respond.
   * If false, the message will stand alone.
   */
  process: boolean;
  /**
   * Whether the assistant is currently processing the message.
   */
  isLoading: boolean;
  /**
   * A list of loading messages to show to the user.
   * The list is only populated if {@link process} is true.
   * It's populated incrementally throughout processing.
   * The frontend is expected to only display the last loading message at any moment.
   */
  loadingMessages: string[];
  /**
   * Whether replying in thread should be allowed for this message.
   */
  allowThreads: boolean;
  /**
   * How many replies there are for this message.
   */
  replyCount: number;
  /**
   * A list of suggested follow-up questions for the user to ask.
   * This is only populated after processing is complete,
   * and will be populated a few seconds after {@link assistantMessage} is populated.
   */
  suggestedFollowUps: SuggestedFollowUp[] | null;
  /**
   * Feedback from the user.
   * Semantically this refers to {@link assistantMessage}.
   */
  feedback: Feedback | null;
  /**
   * What data sources may have been used to formulate the response.
   */
  sources: Sources | null;
};

type ExchangeCreatorRep = {
  type: "UserPlaintext";
  channelId: string;
  parentId: string | null;
  userGuid: string;
  content: string;
};

type ExchangeUpdateRep = {
  feedback?: Feedback | null;
};

export default ExchangeRep;
export type { SuggestedFollowUp, Feedback, Sources, ExchangeCreatorRep, ExchangeUpdateRep };
