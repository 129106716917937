import { CardLevelLimits, CardLimits } from "@highbeam/unit-node-sdk";
import { ROOT_CARDS_QUERY_NAME } from "modules/cards/queries/useCards";
import { useUnitApiOrThrow } from "modules/unit-co-customer-token/queries/useUnitApi";
import { Merge, SetRequired } from "type-fest";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

// Exemplar: makeQueryHooks
// Exemplar: Type guards - Unit's `CardLimits` type isn't very helpful so we use type-guards to ensure that the data is correct.
// Exemplar: Modifying the data for frontend use, instead of using what the backend gives us.

//
// Type guards
//

type CardLimitsAttributes = CardLimits["attributes"];

type CardLimitsWithDailyPurchaseLimit = {
  type: "limits";
  attributes: Merge<
    CardLimitsAttributes,
    {
      limits: SetRequired<CardLevelLimits, "dailyPurchase">;
    }
  >;
};

const checkIsCardLimitsWithDailyPurchaseLimit = (
  cardLimits: CardLimits
): cardLimits is CardLimitsWithDailyPurchaseLimit => {
  return cardLimits.attributes.limits?.dailyPurchase !== undefined;
};

type CardLimitsWithMonthlyPurchaseLimit = {
  type: "limits";
  attributes: Merge<
    CardLimitsAttributes,
    {
      limits: SetRequired<CardLevelLimits, "monthlyPurchase">;
    }
  >;
};

const checkIsCardLimitsWithMonthlyPurchaseLimit = (
  cardLimits: CardLimits
): cardLimits is CardLimitsWithMonthlyPurchaseLimit => {
  return cardLimits.attributes.limits?.monthlyPurchase !== undefined;
};

//
// Query hooks
//

type SpendLimitPurchaseTotals = {
  dailyTotalPurchases: number;
  monthlyTotalPurchases: number;
};

export type DailyCardSpendLimit = {
  period: "daily";
  limitInCents: number;
  spentInCents: number;
  purchaseTotals: SpendLimitPurchaseTotals;
};

export type MonthlyCardSpendLimit = {
  period: "monthly";
  limitInCents: number;
  spentInCents: number;
  purchaseTotals: SpendLimitPurchaseTotals;
};

export type CardSpendLimit = DailyCardSpendLimit | MonthlyCardSpendLimit;

type Params = {
  cardId: string;
};

const cardSpendLimitQueryHooks = makeQueryHooks({
  rootName: ROOT_CARDS_QUERY_NAME,
  name: "cardSpendLimit",
  useQueryVariables: ({ cardId }: Params) => {
    return {
      cardId,
    };
  },
  useQueryFnMaker: ({ cardId }) => {
    const unitApi = useUnitApiOrThrow();

    return async (): Promise<CardSpendLimit | null> => {
      const response = await unitApi.cards.limits(cardId);

      const spendLimitPurchaseTotals: SpendLimitPurchaseTotals = {
        dailyTotalPurchases: response.data.attributes.dailyTotals?.purchases ?? 0,
        monthlyTotalPurchases: response.data.attributes.monthlyTotals?.purchases ?? 0,
      };

      if (checkIsCardLimitsWithDailyPurchaseLimit(response.data)) {
        return {
          period: "daily",
          limitInCents: response.data.attributes.limits.dailyPurchase,
          spentInCents: spendLimitPurchaseTotals.dailyTotalPurchases,
          purchaseTotals: spendLimitPurchaseTotals,
        };
      }

      if (checkIsCardLimitsWithMonthlyPurchaseLimit(response.data)) {
        return {
          period: "monthly",
          limitInCents: response.data.attributes.limits.monthlyPurchase,
          spentInCents: spendLimitPurchaseTotals.monthlyTotalPurchases,
          purchaseTotals: spendLimitPurchaseTotals,
        };
      }

      return null;
    };
  },
});

export default cardSpendLimitQueryHooks;
