import { ShieldStar, WarningCircle } from "@phosphor-icons/react";
import { useState } from "react";
import PlaidAccountRep from "reps/PlaidAccountRep";
import { useIsSuperusering } from "state/auth/isSuperusering";
import Button from "ui/inputs/Button";
import ItemWithTooltip from "ui/overlay/ItemWithTooltip";
import { Paragraph } from "ui/typography";
import Text from "ui/typography/Text";
import { getPlaidBankAccountDisplayName } from "utils/account";

import DeletePlaidAccountModal from "./DeletePlaidAccountModal";
import styles from "./PlaidConnectionCardContents.module.scss";

type Props = {
  account: PlaidAccountRep.Complete;
};

const InactivePlaidAccountRow = ({ account }: Props) => {
  const isSuperusering = useIsSuperusering();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return (
    <>
      {isDeleteModalOpen && (
        <DeletePlaidAccountModal
          plaidAccountId={account.plaidAccountId}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      )}

      <ItemWithTooltip
        tooltip={
          <Paragraph>
            This account was removed from your Plaid connection. Please reconnect below, or reach
            out to Highbeam support to fully disconnect it.
          </Paragraph>
        }
        asChild
      >
        <div className={styles.plaidAccountRow}>
          <WarningCircle className="fill-yellow-600" size={20} />
          <Text size={14} weight="medium" className={styles.inactive}>
            {getPlaidBankAccountDisplayName(account)}
          </Text>
          {isSuperusering && (
            <Button variant="danger" onClick={() => setIsDeleteModalOpen(true)} size="xs">
              <ShieldStar size={12} /> Delete
            </Button>
          )}
        </div>
      </ItemWithTooltip>
    </>
  );
};

export default InactivePlaidAccountRow;
