import { Copy } from "@phosphor-icons/react";
import { FC } from "react";
import ItemWithTooltip from "ui/overlay/ItemWithTooltip";
import { Paragraph } from "ui/typography";
import useCopyToClipboard from "utils/browser/useCopyToClipboard";

type Props = {
  textToCopy?: string;
};

const CopyIconWithTooltip: FC<Props> = ({ textToCopy }) => {
  const { copied, copyToClipboard } = useCopyToClipboard();

  return (
    <ItemWithTooltip
      tooltip={<Paragraph>{!copied ? "Click to copy" : "Copied"}</Paragraph>}
      asChild
    >
      <span
        onClick={(e) => {
          e.preventDefault();
          if (textToCopy) {
            copyToClipboard(textToCopy);
          }
        }}
        onPointerDown={(e) => {
          // Prevents the tooltip from closing when the icon is clicked.
          e.stopPropagation();
        }}
        className="inline-flex cursor-pointer"
        tabIndex={0}
        role="button"
        onKeyDown={(e) => {
          // NB(alex): Had to wrap with a `span` because svgs don't support `onKeyDown`.
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            e.currentTarget.click();
          }
        }}
      >
        <Copy size={16} />
      </span>
    </ItemWithTooltip>
  );
};

export default CopyIconWithTooltip;
