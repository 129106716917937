import { ApiBuilder, useBackendV1Api } from "modules/highbeam-api/base";
import BankApplicationRep from "reps/BankApplicationRep";

type BankApplicationApi = {
  createBankApplication: (businessGuid: string) => Promise<BankApplicationRep.Complete>;
  getByBusiness: (businessGuid: string) => Promise<BankApplicationRep.Complete[]>;
};

const builder: ApiBuilder<BankApplicationApi> = (api) => ({
  createBankApplication: async (businessGuid) => {
    const url = `/businesses/${businessGuid}/bank-applications`;
    return (await api.post<BankApplicationRep.Complete>(url))!;
  },
  getByBusiness: async (businessGuid) => {
    const url = `/businesses/${businessGuid}/bank-applications`;
    return (await api.get<BankApplicationRep.Complete[]>(url))!;
  },
});

const useBankApplicationApi = () => useBackendV1Api(builder);

export default useBankApplicationApi;
