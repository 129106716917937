import dayjs from "dayjs";
import { DateChatElementRep } from "modules/chat/reps/ChatElementRep";
import React from "react";
import cn from "utils/tailwind/cn";

import { useParagraphChatElementContext } from "./ParagraphChatElement";

type Props = {
  element: DateChatElementRep;
};

const DateChatElement: React.FC<Props> = ({ element }) => {
  const { isInParagraph } = useParagraphChatElementContext();

  return (
    <span className={cn({ "font-600": isInParagraph })}>
      {dayjs(element.value).tz("America/New_York").format("MMM D, YYYY")}
    </span>
  );
};

export default DateChatElement;
