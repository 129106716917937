import { FC } from "react";

type Props = {
  className?: string;
};

const AccountsOverviewBalanceChartEmptySvg: FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      preserveAspectRatio="xMidYMid meet" // This is custom but everything else was copied from figma.
      viewBox="0 0 788 288"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        d="M26 172L1 164.5V259.5H787V92L743 78H709.5L661.5 118.5H646L612.5 115L555.5 139.5L518 120.5L490.5 144.5L460 127.5L435 120.5L399 126H367.5L328.5 139.5L314 160.5H283L244 182L206 179.5L162.5 160.5L127.5 156.5L87 167L26 172Z"
        fill="url(#paint0_linear_2603_9181)"
      />
      <line
        x1="0.5"
        y1="-2.18557e-08"
        x2="0.500011"
        y2="260"
        stroke="#D9D9D9"
        strokeDasharray="4 4"
      />
      <line y1="259.5" x2="787" y2="259.5" stroke="#D9D9D9" strokeDasharray="4 4" />
      <rect x="5" y="276" width="50.5" height="12" rx="6" fill="#FAFAFA" />
      <rect x="71.5" y="276" width="50.5" height="12" rx="6" fill="#FAFAFA" />
      <rect x="138" y="276" width="50.5" height="12" rx="6" fill="#FAFAFA" />
      <rect x="204.5" y="276" width="50.5" height="12" rx="6" fill="#FAFAFA" />
      <rect x="271" y="276" width="50.5" height="12" rx="6" fill="#FAFAFA" />
      <rect x="337.5" y="276" width="50.5" height="12" rx="6" fill="#FAFAFA" />
      <rect x="404" y="276" width="50.5" height="12" rx="6" fill="#FAFAFA" />
      <rect x="470.5" y="276" width="50.5" height="12" rx="6" fill="#FAFAFA" />
      <rect x="537" y="276" width="50.5" height="12" rx="6" fill="#FAFAFA" />
      <rect x="603.5" y="276" width="50.5" height="12" rx="6" fill="#FAFAFA" />
      <rect x="670" y="276" width="50.5" height="12" rx="6" fill="#FAFAFA" />
      <rect x="736.5" y="276" width="50.5" height="12" rx="6" fill="#FAFAFA" />
      <path
        d="M1 164.5L26 172L87 167L127.5 156.5L162.5 160.5L206 179.5L244 182L283 160.5H314L328.5 139.5L367.5 126H399L435 120.5L460 127.5L490.5 144.5L518 120.5L555.5 139.5L612.5 115L646 118.5H661.5L709.5 78H743L787 92"
        stroke="#E4E3E7"
        strokeWidth="1.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2603_9181"
          x1="415"
          y1="260"
          x2="414.5"
          y2="70"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEFEFF" />
          <stop offset="1" stopColor="#FAFAFA" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AccountsOverviewBalanceChartEmptySvg;
