import TransactionInfoFlexpane from "components/Transactions/TransactionInfoFlexpane";
import { FEATURE_FLAGS } from "flags";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import { useIsBusinessActive } from "modules/business/queries/useBusiness";
import CardAuthorizations from "modules/card-authorizations-table/CardAuthorizations";
import { cardAuthorizationsQueryHooks } from "modules/card-authorizations/queries/useCardAuthorizations";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import EnrichedTransactionsSection from "modules/insights/components/EnrichedTransactionsSection";
import VerifyBusinessBannerRenderer from "modules/onboarding/components/verify-business/VerifyBusinessBannerRenderer";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import SectionV2 from "ui/data-display/SectionV2";
import SectionLoading from "ui/feedback/SectionLoading";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import { DISABLE_SCROLL_TO_TOP_STATE } from "ui/navigation/ScrollToTopOnNavigate";
import useIsAllowedToNavigateToCardTransactionsPage from "utils/permissions/navigation/useIsAllowedToNavigateToCardTransactionsPage";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import variants from "utils/ts/variants";

import CardsTransactionsPageTabs, {
  useCardsTransactionsPageTabs,
} from "./CardsTransactionsPageTabs";
import Transactions from "./Transactions";

const HighbeamsCardsTransactionsContent = () => {
  const hasAuthorizations = cardAuthorizationsQueryHooks.useData({
    params: { page: 1 },
    select: (data) => data.meta.pagination.total > 0,
  });
  const { unitCoDepositAccountId, transactionId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const redirectTo = `/cards/transactions?${searchParams}`;

  return (
    <>
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => {
          // NB(alex): Necessary for resetting the error boundary after navigation takes place.
          resetErrorBoundary();

          if (error instanceof RequiredButNotFoundError) {
            return <Navigate to={redirectTo} state={DISABLE_SCROLL_TO_TOP_STATE} />;
          }

          // Trigger a different error boundary if it isn't a RequiredButNotFoundError
          throw error;
        }}
      >
        <TransactionInfoFlexpane
          params={
            unitCoDepositAccountId && transactionId
              ? {
                  unitCoDepositAccountId: unitCoDepositAccountId,
                  transactionId: transactionId,
                }
              : undefined
          }
          onClose={() => navigate(redirectTo, { state: DISABLE_SCROLL_TO_TOP_STATE })}
          // TODO(zafin): add refresh transactions logic
        />
      </ErrorBoundary>

      {hasAuthorizations && (
        <DashboardPage.Section>
          <DashboardPage.Section.Body>
            <CardAuthorizations />
          </DashboardPage.Section.Body>
        </DashboardPage.Section>
      )}

      <DashboardPage.Section>
        <Transactions />
      </DashboardPage.Section>
    </>
  );
};

const CardTransactionsPageContent = () => {
  const isBusinessActive = useIsBusinessActive();
  const { tabs, activeTab, setActiveTab } = useCardsTransactionsPageTabs(
    isBusinessActive ? "highbeam-cards" : "connected-cards"
  );
  const enrichedTransactionsEnabled = useFeatureFlag(FEATURE_FLAGS["enriched-transactions"]);

  return (
    <>
      <DashboardPage.Header>
        <DashboardPage.Header.Title>Card transactions</DashboardPage.Header.Title>
      </DashboardPage.Header>

      {/* NB(alex): We'll have to push up another PR to delete the `isSuperusering` once we're ready to roll this out. */}
      {enrichedTransactionsEnabled ? (
        <>
          <CardsTransactionsPageTabs
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            className="mt-12"
          />
          {variants(activeTab, {
            "highbeam-cards": (
              <Suspense fallback={<SectionLoading className="mt-6" />}>
                <SectionV2 variant="dashboard-page">
                  {isBusinessActive ? (
                    <HighbeamsCardsTransactionsContent />
                  ) : (
                    <VerifyBusinessBannerRenderer className="mt-2" />
                  )}
                </SectionV2>
              </Suspense>
            ),
            "connected-cards": (
              <Suspense fallback={<SectionLoading />}>
                <EnrichedTransactionsSection accountType="Credit" includeHighbeam={false} />
              </Suspense>
            ),
          })}
        </>
      ) : (
        <HighbeamsCardsTransactionsContent />
      )}
    </>
  );
};

const CardTransactionsPage = () => {
  const isAllowedToNavigateToCardTransactionsPage = useIsAllowedToNavigateToCardTransactionsPage();

  if (!isAllowedToNavigateToCardTransactionsPage) {
    return <Navigate to="/cards" />;
  }

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/cards">Cards</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Transactions</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <CardTransactionsPageContent />
      </DashboardPage>
    </>
  );
};

export default CardTransactionsPage;
