import { CaretDown, CaretUp, FileText } from "@phosphor-icons/react";
import { useQueryClient } from "@tanstack/react-query";
import useUpdateChatChannelExchangeUserFeedbackMutation from "modules/chat/mutations/useUpdateChatChannelExchangeUserFeedbackMutation";
import { chatChannelExchangesQueryHooks } from "modules/chat/queries/useChatChannelExchanges";
import ExchangeRep, { anySource, Feedback, Sources } from "modules/chat/reps/ExchangeRep";
import { FC, ReactNode, Suspense, useCallback, useState } from "react";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import { Span } from "ui/typography";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";

import SourcesCard from "../SourcesCard";
import UserFeedbackButtons from "../UserFeedbackButtons";
import UserFeedbackCard from "../UserFeedbackCard";

type Props = {
  exchange: ExchangeRep;
  otherActions?: ReactNode;
};

type ExpandedFooterCard = "sources" | "userFeedback" | null;

const sourcesAllFalse = (sources: Sources) => {
  return (
    !sources.bills &&
    !sources.ecommerceIndustryInformation &&
    !sources.financialTransactions &&
    !sources.highbeamPayees &&
    !sources.shopifyOrders
  );
};

const AiChatExchangeFooter: FC<Props> = ({ exchange, otherActions }) => {
  const { segmentTrack } = useSegment();
  // Footer element visibility state
  const [expandedFooterCard, setExpandedFooterCard] = useState<ExpandedFooterCard>(null);
  // Feedback mutation and handler
  const queryClient = useQueryClient();
  const chatChannelExchangesQueryKey = chatChannelExchangesQueryHooks.useQueryKey({
    channelId: exchange.channelId,
    latestExclusive: null,
  });

  // Keep a component-level copy of the feedback (separate from the server state)
  // to drive the UserFeedbackButtons and UserFeedbackCard. This allows us to toggle
  // the UserFeedbackCard open to allow the user to fill in the reason for negative feedback,
  // prior to dispatching the update feedback mutation.
  const [localFeedback, setLocalFeedback] = useState(exchange.feedback);

  const { mutate: updateChatChannelExchangeUserFeedback, isPending: isUpdatingUserFeedback } =
    useUpdateChatChannelExchangeUserFeedbackMutation(exchange.channelId, {
      onMutate: ({ exchangeId, update }) => {
        const previousExchanges = queryClient.getQueryData<ExchangeRep[]>(
          chatChannelExchangesQueryKey
        );
        const optimisticExchanges = previousExchanges?.map((exchange) => {
          if (exchange.id === exchangeId) {
            return { ...exchange, feedback: update.feedback };
          }
          return exchange;
        });
        queryClient.setQueryData(chatChannelExchangesQueryKey, optimisticExchanges);
        return { previousExchanges };
      },
      onError: (_error, variables, context) => {
        const previousExchanges = (context as { previousExchanges: ExchangeRep[] })
          .previousExchanges;
        // Roll back optimistic update if there's an error (both in the query cache and the local state).
        queryClient.setQueryData(chatChannelExchangesQueryKey, previousExchanges);
        previousExchanges?.forEach((exchange) => {
          if (exchange.id === variables.exchangeId) {
            setLocalFeedback(exchange.feedback);
          }
        });
        notify("error", "Something went wrong! Please try again.");
      },
    });
  const handleUpdateUserFeedback = useCallback(
    async (exchangeId: string, feedback: Feedback | null) => {
      setLocalFeedback(feedback);
      setExpandedFooterCard(feedback ? "userFeedback" : null);
      // We only run the update mutation right away if the feedback is not negative.
      // Otherwise, we just open the card to allow the user to fill in the reason.
      if (!feedback || feedback.value === "Positive" || feedback.reason !== null) {
        updateChatChannelExchangeUserFeedback({ exchangeId, update: { feedback } });
      }
    },
    [updateChatChannelExchangeUserFeedback]
  );

  // Handle sources button click
  const handleSourcesClick = useCallback(() => {
    if (expandedFooterCard === "sources") {
      setExpandedFooterCard(null);
    } else {
      segmentTrack(SEGMENT_EVENTS.AI_CHAT_SOURCES_BUTTON_CLICKED);
      setExpandedFooterCard("sources");
    }
  }, [expandedFooterCard, setExpandedFooterCard, segmentTrack]);

  return (
    <div className="flex w-full flex-col gap-y-4">
      <div className="flex w-full items-center justify-start gap-x-2">
        <UserFeedbackButtons
          value={localFeedback}
          onChange={(feedback) => handleUpdateUserFeedback(exchange.id, feedback)}
          isLoading={isUpdatingUserFeedback}
        />

        {anySource(exchange.sources) && (
          <div className="flex items-center gap-x-2">
            <Button size="xs" variant="ghost" onClick={handleSourcesClick}>
              <FileText size={16} weight="light" />
              <Span className="text-xs text-grey-600">Sources</Span>
              {expandedFooterCard === "sources" ? (
                <CaretUp size={16} weight="light" />
              ) : (
                <CaretDown size={16} weight="light" />
              )}
            </Button>
          </div>
        )}

        {otherActions}
      </div>

      {expandedFooterCard === "userFeedback" && (
        <UserFeedbackCard
          value={localFeedback}
          onChange={(feedback) => handleUpdateUserFeedback(exchange.id, feedback)}
        />
      )}
      {expandedFooterCard === "sources" &&
        exchange.sources !== null &&
        !sourcesAllFalse(exchange.sources) && (
          <Suspense fallback={null}>
            <SourcesCard sources={exchange.sources} onClose={() => setExpandedFooterCard(null)} />
          </Suspense>
        )}
    </div>
  );
};

export default AiChatExchangeFooter;
