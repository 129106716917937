import TransactionFlexpaneDispute from "components/common/transaction-flexpane/TransactionFlexpaneDispute";
import { useBankAccountByUnitAccountId } from "modules/bank-accounts/queries/useBankAccount";
import getCheckClearingDaysForBankAccount from "modules/checks/utils/getCheckClearingDaysForBankAccount";
import useUnitCoCheckDeposit from "modules/unit-co-check-deposits/queries/useUnitCheckDeposit";
import { FC } from "react";

type Props = {
  checkDepositId: string;
  onClose: () => void;
};

const CheckDepositFlexpaneTransactionDispute: FC<Props> = ({ checkDepositId }) => {
  const checkDeposit = useUnitCoCheckDeposit({ checkDepositId });
  const bankAccount = useBankAccountByUnitAccountId(checkDeposit.relationships.account.data.id);
  const checkClearingDays = getCheckClearingDaysForBankAccount(bankAccount);

  return (
    <TransactionFlexpaneDispute
      title="When will my check deposit be available?"
      description={`Check deposits take ${checkClearingDays} business days to process. If your deposit is still pending, please allow the full processing time to complete.`}
      showContactSupport={false}
    />
  );
};

export default CheckDepositFlexpaneTransactionDispute;
