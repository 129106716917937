import useSuggestedQuestionApi from "modules/chat/api/useSuggestedQuestionApi";
import { SuggestedQuestionRep } from "modules/chat/reps/SuggestedQuestionRep";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHasPermission from "utils/permissions/useHasPermission";
import makeQueryHooksV2, {
  ExtractParamsWithOptionalDefaults,
} from "utils/react-query/makeQueryHooksV2";

type Params = {
  businessGuid: string;
  useCase: string;
  limit: number;
};

const suggestedQuestionsQueryHooks = makeQueryHooksV2({
  useDefaultParams: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  makeQueryKey: (params: Params) => {
    return ["suggestedQuestions", params] as const;
  },
  useQueryFnParams: () => {
    const suggestedQuestionApi = useSuggestedQuestionApi();
    return { suggestedQuestionApi };
  },
  makeQueryFn: ({ suggestedQuestionApi }, { businessGuid, limit }) => {
    return () => suggestedQuestionApi.listByBusiness(businessGuid, limit);
  },
});

type ParamsWithOptionalDefaults = ExtractParamsWithOptionalDefaults<
  typeof suggestedQuestionsQueryHooks
>;

const useRandomSuggestedQuestions = (
  params: ParamsWithOptionalDefaults
): SuggestedQuestionRep[] => {
  return suggestedQuestionsQueryHooks.useDataOrThrow({ params });
};

const useHasSuggestedQuestionsPermission = () => {
  return useHasPermission("chat");
};

export {
  suggestedQuestionsQueryHooks,
  useRandomSuggestedQuestions,
  useHasSuggestedQuestionsPermission,
};
