import { useQueryClient } from "@tanstack/react-query";
import useExchangeApi from "modules/chat/api/useExchangeApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

const useUpdateChatConfigMutation = (
  channelId: string,
  additionalOptions: MutationAdditionalOptions<void, void>
) => {
  const queryClient = useQueryClient();
  const exchangeApi = useExchangeApi();

  return useMutationWithDefaults(
    {
      mutationFn: async () => {
        return await exchangeApi.deleteByChannel(channelId);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ["chatChannelExchanges", channelId],
        });
      },
    },
    additionalOptions
  );
};

export default useUpdateChatConfigMutation;
