import { PlusCircle } from "@phosphor-icons/react";
import dayjs from "dayjs";
import {
  FinancialAccountSyncStatusRep,
  ShopifySyncStatusRep,
} from "modules/insights/reps/IntelligenceStatusRep";
import { NormalizedAccountAugmented } from "modules/insights/utils/augment-normalized-account";
import { SETTINGS_PLAID_LINK_MODAL_KEY } from "modules/plaid/components/PlaidConnectionsList";
import { FC } from "react";
import AccountBar from "ui/data-display/AccountBar";
import { ShopifyLogo } from "ui/icons/logos/logos-square";
import ButtonLink from "ui/inputs/ButtonLink";
import { roundEpsilon } from "utils/math";

import NormalizedAccountAugmentedBar from "../NormalizedAccountRepBar/NormalizedAccountAugmentedBar";

import CleaningTransactions from "./CleaningTransactions";

type Props = {
  financialAccounts?: FinancialAccountSyncStatusRep[];
  shopifyStatus?: ShopifySyncStatusRep | null;
  normalizedAccountsAugmented: NormalizedAccountAugmented[];
  className?: string;
};

const IntelligenceStatusCleaningTransactions: FC<Props> = ({
  financialAccounts,
  shopifyStatus,
  normalizedAccountsAugmented,
  className,
}) => {
  return (
    <CleaningTransactions className={className}>
      <CleaningTransactions.Header>
        <CleaningTransactions.Heading />
        <div className="flex items-center">
          <ButtonLink
            to={`/settings/banks-and-cards?${SETTINGS_PLAID_LINK_MODAL_KEY}=true`}
            className="-mr-3"
          >
            <PlusCircle />{" "}
            <span>
              Add<span className="hidden @xs:inline"> connection</span>
            </span>
          </ButtonLink>
        </div>
      </CleaningTransactions.Header>

      {financialAccounts &&
        normalizedAccountsAugmented.map((na) => {
          const matchingFinancialAccount = financialAccounts.find(
            (fa) => fa.normalizedAccountId === na.id
          );

          if (!matchingFinancialAccount) return null;

          const { totalTransactions, totalEnrichedTransactions, normalizedAccountCreatedAt } =
            matchingFinancialAccount;

          const wasCreatedWithinLastHour = dayjs(normalizedAccountCreatedAt).isAfter(
            dayjs().subtract(1, "hour")
          );

          return (
            <CleaningTransactions.Row key={na.id}>
              <NormalizedAccountAugmentedBar normalizedAccountAugmented={na} />

              <CleaningTransactions.Completion
                completionPercentage={
                  totalTransactions === 0
                    ? wasCreatedWithinLastHour && na.repType !== "highbeam-account" // Highbeam accounts usually have 0 total transactions to start so we mark them as cleaned.
                      ? 0
                      : 100
                    : roundEpsilon((totalEnrichedTransactions / totalTransactions) * 100, 0)
                }
              />
            </CleaningTransactions.Row>
          );
        })}

      {shopifyStatus && (
        <CleaningTransactions.Row>
          <AccountBar icon={<ShopifyLogo />} accountName="Java Junction" />
          <CleaningTransactions.Completion
            completionPercentage={shopifyStatus.ordersSynced ? 100 : 0}
            syncingMessage="Syncing order data"
            completedMessage="Order data synced"
          />
        </CleaningTransactions.Row>
      )}
    </CleaningTransactions>
  );
};

export default IntelligenceStatusCleaningTransactions;
