import { ArrowSquareOut } from "@phosphor-icons/react";
import { useUnitCoCheckImageQuery } from "modules/unit-co-check-deposits/queries/useUnitCoCheckImage";
import { Dispatch, FC, SetStateAction } from "react";
import Shimmer from "ui/feedback/ShimmerV2";
import Button from "ui/inputs/Button";
import SegmentControls from "ui/navigation/SegmentControls";
import Flexpane from "ui/overlay/Flexpane";
import Text from "ui/typography/Text";
import openDataUrlImageInNewWindow from "utils/browser/openDataUrlImageInNewWindow";
import { TabConfig } from "utils/tabs/useTabState";

import styles from "./CheckDepositImagesFlexpaneSection.module.scss";

export const checkDepositImagesTabs = {
  "front-image": { label: "Front" },
  "back-image": { label: "Back" },
} satisfies TabConfig;

type CheckDepositImagesTab = keyof typeof checkDepositImagesTabs;

type Props = {
  checkDepositId: string;
  activeTab: CheckDepositImagesTab;
  setActiveTab: Dispatch<SetStateAction<CheckDepositImagesTab>>;
};

const CheckDepositImagesFlexpaneSection: FC<Props> = ({
  checkDepositId,
  activeTab,
  setActiveTab,
}) => {
  const { data: frontImage } = useUnitCoCheckImageQuery({
    side: "front",
    checkDepositId: checkDepositId,
  });

  const { data: backImage } = useUnitCoCheckImageQuery({
    side: "back",
    checkDepositId: checkDepositId,
  });

  return (
    <Flexpane.Section className={styles.container}>
      <div className={styles.headerContainer}>
        {activeTab === "front-image" ? (
          <Text size={14} weight={"bold"} underline className={styles.labelText}>
            {frontImage ? (
              <>
                <Button
                  className={styles.labelButton}
                  variant="ghost"
                  onClick={() => {
                    openDataUrlImageInNewWindow(frontImage);
                  }}
                >
                  Front of check
                </Button>
                <ArrowSquareOut size={18} weight="regular" className={styles.labelIcon} />
              </>
            ) : (
              <Shimmer className="w-32" />
            )}
          </Text>
        ) : (
          <Text size={14} weight={"bold"} underline className={styles.labelText}>
            {backImage ? (
              <Button
                className={styles.labelButton}
                variant="ghost"
                onClick={() => {
                  openDataUrlImageInNewWindow(backImage);
                }}
              >
                Back of check
              </Button>
            ) : (
              <Shimmer />
            )}
            <ArrowSquareOut size={18} weight="regular" className={styles.labelIcon} />
          </Text>
        )}
        <SegmentControls
          size="sm"
          tabs={checkDepositImagesTabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>

      {activeTab === "front-image" ? (
        <img src={frontImage} alt="Check front" />
      ) : (
        <img src={backImage} alt="Check back " />
      )}
    </Flexpane.Section>
  );
};

export default CheckDepositImagesFlexpaneSection;
