export type QueryParamEntry = [string, string];
export type QueryParamEntries = QueryParamEntry[];

/**
 * Converts an object to a record of string keys and values.
 * Handles booleans and string arrays.
 */
const convertObjectToQueryParams = <
  T extends Record<string, string | string[] | undefined | null | boolean | number>,
>(
  obj: T
): QueryParamEntries => {
  const initial: QueryParamEntries = [];
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value === undefined || value === null) {
      return acc;
    } else if (typeof value === "boolean") {
      acc.push([key, value ? "true" : "false"]);
    } else if (Array.isArray(value)) {
      value.forEach((v) => {
        acc.push([key, v]);
      });
    } else if (typeof value === "number") {
      acc.push([key, value.toString()]);
    } else {
      acc.push([key, value]);
    }
    return acc;
  }, initial);
};

/**
 * Converts an object to a URLSearchParams object.
 * Handles booleans and string arrays.
 */
const createURLSearchParamsFromObject = <
  T extends Record<string, string | string[] | undefined | null | boolean | number>,
>(
  obj: T
): URLSearchParams => {
  return new URLSearchParams(convertObjectToQueryParams(obj));
};

export { convertObjectToQueryParams, createURLSearchParamsFromObject };
