import { useSuspenseQuery } from "@tanstack/react-query";
import businessMemberQueryHooks, {
  useCurrentBusinessMember,
} from "modules/business-members/queries/businessMemberQueryHooks";
import useBusiness from "modules/business/queries/useBusiness";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import useMfaWithRedirect from "modules/mfa/MfaModal/utils/useMfaWithRedirect";
import useCreateUnitAuthorizedUserMutation from "modules/onboarding/mutations/useCreateUnitAuthorizedUserMutation";
import OnboardingPersonalInfoPage from "pages/onboarding/OnboardingPersonalInfoPage";
import { FC, PropsWithChildren, useMemo } from "react";
import { useRecoilValue } from "recoil";
import auth0ClientState from "state/auth/auth0Client";
import { getJwtFromAccessToken, isLastMfaValid } from "utils/auth";
import useMountEffect from "utils/customHooks/useMountEffect";

// NB(alex): This replaces the old "get started" onboarding page. We now authorize the user in the
// background without having the user take any explicit actions. They will be fully redirected to
// the Auth0 MFA page if they just logged in.

const useShouldPromptCreateAuthorizedUnitUser = () => {
  const { unitCoCustomerId, status } = useBusiness();
  const userGuid = useUserGuid();
  const { data: businessMember } = businessMemberQueryHooks.useQuery({
    userGuid,
  });

  return useMemo(() => {
    return businessMember && !businessMember.isOnboarded && unitCoCustomerId && status === "Active";
  }, [businessMember, status, unitCoCustomerId]);
};

const useCreateAuthorizedUnitUserEffect = () => {
  const { mutate: createAuthorizedUnitUser } = useCreateUnitAuthorizedUserMutation();
  const auth0Client = useRecoilValue(auth0ClientState);

  const businessGuid = useBusinessGuid();
  const userGuid = useUserGuid();
  // NB(alex): Hack to ensure a fresh jwt. We need to fix `useJwtQueryOptions`, it's very prone to misleading cache times because it doesn't use `cacheMode: "off"`.
  const { data: isJwtMfaComplete } = useSuspenseQuery({
    queryKey: ["fresh-jwt", businessGuid, userGuid],
    queryFn: async () => {
      const isAuthenticated = await auth0Client.isAuthenticated();

      if (isAuthenticated) {
        const jwtString = await auth0Client.getTokenSilently({ cacheMode: "off" });
        const jwt = getJwtFromAccessToken(jwtString);
        return isLastMfaValid(jwt);
      }

      return false;
    },
  });

  const mfaWithRedirect = useMfaWithRedirect();
  const shouldPromptCreateAuthorizedUnitUser = useShouldPromptCreateAuthorizedUnitUser();

  useMountEffect(() => {
    if (shouldPromptCreateAuthorizedUnitUser) {
      if (isJwtMfaComplete) {
        createAuthorizedUnitUser();
      } else {
        mfaWithRedirect();
      }
    }
  });
};

const AuthorizeUnitInBackground: FC = () => {
  useCreateAuthorizedUnitUserEffect();
  return null;
};

const AuthorizedUnitUserCheck: FC<PropsWithChildren> = ({ children }) => {
  const shouldPromptCreateAuthorizedUnitUser = useShouldPromptCreateAuthorizedUnitUser();
  const businessMember = useCurrentBusinessMember();

  if (shouldPromptCreateAuthorizedUnitUser) {
    if (
      businessMember &&
      (!businessMember.dateOfBirth || !businessMember.firstName || !businessMember.lastName)
    ) {
      return <OnboardingPersonalInfoPage />;
    } else {
      return <AuthorizeUnitInBackground />;
    }
  }

  return <>{children}</>;
};

export { useShouldPromptCreateAuthorizedUnitUser };

export default AuthorizedUnitUserCheck;
