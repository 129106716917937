import CapitalAccountRep from "reps/CapitalAccountRep";
import { SetFieldType, Simplify } from "type-fest";

import capitalAccountsQueryHooks from "../queries/useCapitalAccounts";

import { checkIsCapitalAccountWithChargeCard } from "./useCapitalAccountsWithChargeCard";

export type ActiveCapitalAccountWithChargeCard = Simplify<
  SetFieldType<CapitalAccountRep.ChargeCardOnlyComplete, "state", CapitalAccountRep.State.Active>
>;

export const checkIsActiveCapitalAccountWithChargeCard = (
  capitalAccount: CapitalAccountRep.Complete
): capitalAccount is ActiveCapitalAccountWithChargeCard => {
  return (
    checkIsCapitalAccountWithChargeCard(capitalAccount) &&
    capitalAccount.state === CapitalAccountRep.State.Active
  );
};

const useActiveCapitalAccountsWithChargeCard = () => {
  return capitalAccountsQueryHooks.useData({
    params: {},
    select: (capitalAccounts) => capitalAccounts.filter(checkIsActiveCapitalAccountWithChargeCard),
  });
};

export default useActiveCapitalAccountsWithChargeCard;
