import { FC } from "react";
import DotsLoader from "ui/feedback/DotsLoader";
import cn from "utils/tailwind/cn";

type Props = {
  className?: string;
};

const AccountsOverviewBalanceLoading: FC<Props> = ({ className }) => (
  <div className={cn("flex h-80 items-center justify-center", className)}>
    <DotsLoader dots={5} />
  </div>
);

export default AccountsOverviewBalanceLoading;
