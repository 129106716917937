import ChatWidget from "components/ChatWidget";
import PlaidConnectionExistsModal from "components/PlaidConnectionExistsModal";
import AuthenticatedRootLayout from "layouts/AuthenticatedRootLayout";
import AuthenticationRequiredLayout from "layouts/AuthenticationRequiredLayout";
import AuthorizedUnitUserCheck from "layouts/AuthorizedUnitUserCheck";
import AddToHomeScreenInstructionsSheet from "modules/add-to-home-screen/AddToHomeScreenInstructionsSheet";
import AddToHomeScreenPage from "modules/add-to-home-screen/AddToHomeScreenPage";
import { SIGN_OUT_ROUTE } from "modules/auth/constants";
import BusinessDisambiguatorRedirector from "modules/business-disambiguator/BusinessDisambiguatorRedirector";
import ConfirmationModal from "modules/confirmation-modal/ConfirmationModal";
import checkInsightsAppEnabled from "modules/insights-app/checkInsightsAppEnabled";
import LoadingPage from "modules/loading/pages/LoadingPage";
import MfaModal from "modules/mfa/MfaModal";
import BusinessSwitcher from "modules/superuser/BusinessSwitcher";
import useCanSuperuser from "modules/superuser/hooks/useCanSuperuser";
import SuperuserRedirect from "modules/superuser/SuperuserRedirect";
import CreditComparisonPage from "pages/capital/CreditComparisonPage";
import { CREDIT_COMPARISON_PATH } from "pages/capital/paths";
import CardLimitModal from "pages/CardLimitModal";
import InvitationPage from "pages/InvitationPage";
import LenderOAuthPermissionsPage from "pages/LenderOAuthPermissionsPage";
import PayoutInstructionModal from "pages/PayoutInstructionModal";
import ReferralPage from "pages/ReferralPage";
import LoadingShopify from "pages/settings/SettingsPage/Integrations/ConnectShopify/LoadingShopify";
import ShopifyRouter from "pages/settings/SettingsPage/Integrations/ConnectShopify/ShopifyRouter";
import SignInPage from "pages/SignInPage";
import SignOutPage from "pages/SignOutPage";
import SignUpPage from "pages/SignUpPage";
import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { useRecoilValue } from "recoil";
import AuthenticatedRouter from "routes/AuthenticatedRouter";
import isPlaidExistingAccountModalOpenState from "state/plaid/isExistingAccountModalOpen";
import { useIsTablet } from "utils/device/useMediaQuery";

const RootRouter = () => {
  const isPlaidExistingAccountModalOpen = useRecoilValue(isPlaidExistingAccountModalOpenState);
  const isTablet = useIsTablet();
  const canSuperuser = useCanSuperuser();

  const isInsightsApp = checkInsightsAppEnabled();

  return (
    <Suspense fallback={<LoadingPage location={RootRouter.name} />}>
      <Routes>
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path={SIGN_OUT_ROUTE} element={<SignOutPage />} />
        <Route path="/invitation/:invitationSlug" element={<InvitationPage />} />
        <Route path="/referral/:referralSlug" element={<ReferralPage />} />
        <Route path={`${CREDIT_COMPARISON_PATH}/*`} element={<CreditComparisonPage />} />
        {/* /loading-shopify is used for redirect from Shopify OAuth */}
        <Route path="/loading-shopify/*" element={<LoadingShopify />} />
        {isInsightsApp ? <Route path="/shopify/*" element={<ShopifyRouter />} /> : null}
        <Route path="/loading" element={<LoadingPage location={RootRouter.name} />} />
        <Route path="/mobile" element={<AddToHomeScreenPage />} />
        <Route path="/superuser" element={<SuperuserRedirect indexRoute="/" />} />
        <Route path="/auth-capital" element={<LenderOAuthPermissionsPage />} />

        <Route
          path="/*"
          element={
            <AuthenticationRequiredLayout>
              <BusinessDisambiguatorRedirector
                fallback={<LoadingPage location={BusinessDisambiguatorRedirector.name} />}
              >
                <AuthorizedUnitUserCheck>
                  <AuthenticatedRootLayout
                    fallback={<LoadingPage location={AuthenticatedRootLayout.name} />}
                  >
                    <AuthenticatedRouter />
                    <MfaModal />
                    <CardLimitModal />
                    <PayoutInstructionModal />
                    <ConfirmationModal />
                    {isTablet && <AddToHomeScreenInstructionsSheet />}
                    {isPlaidExistingAccountModalOpen && <PlaidConnectionExistsModal />}
                    <ChatWidget />
                  </AuthenticatedRootLayout>

                  {/* NB(alex): Will delete `SuperuserTools` soon. Basically ⌘k for superuser and ⌘^k for command palette. */}
                  {canSuperuser && <BusinessSwitcher />}
                </AuthorizedUnitUserCheck>
              </BusinessDisambiguatorRedirector>
            </AuthenticationRequiredLayout>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default RootRouter;
