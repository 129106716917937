import { CreditCard } from "@phosphor-icons/react";
import { FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import colors from "styles/colors";
import { UncappedLogo, DwightLogo, EmberLogo } from "ui/icons/logos/logos-square";
import cn from "utils/tailwind/cn";

import CashAccessLineIcon from "./assets/cash-access-line-icon.svg?react";

// NB(alex): This component needs to be audited / is still a WIP.

type Props = {
  capitalAccount: CapitalAccountRep.Complete | CapitalAccountSummaryRep.Complete;
  size?: number;
  className?: string;
};

const CapitalAccountIcon: FC<Props> = ({ capitalAccount, size = 24, className }) => {
  switch (capitalAccount.lender) {
    case CapitalAccountRep.CapitalLender.Dwight:
      return <DwightLogo className={cn("rounded", className)} size={size} />;
    case CapitalAccountRep.CapitalLender.Ember:
      return <EmberLogo className={cn("rounded", className)} size={size} />;
    case CapitalAccountRep.CapitalLender.Uncapped:
      return <UncappedLogo className={cn("rounded", className)} size={size} />;
  }
  switch (capitalAccount.type) {
    case CapitalAccountRep.Type.ChargeCardOnly:
      return (
        <CreditCard weight="light" color={colors.purple[500]} size={size} className={className} />
      );
    case CapitalAccountRep.Type.CashAccessOnly:
      // NB(alex): It would be nice to get a `CashAccessLineIcon` that doesn't have this extra padding.
      return <CashAccessLineIcon className={className} width={size} height={size} />;
  }
};

export default CapitalAccountIcon;
