import { PlusCircle } from "@phosphor-icons/react";
import { ComponentProps, FC } from "react";
import EmptyState from "ui/data-display/EmptyState";
import {
  AmexLogo,
  BankOfAmericaLogo,
  CapitalOneLogo,
  ChaseLogo,
  MercuryLogo,
  WellsFargoLogo,
} from "ui/icons/logos/logos-square";
import PlatformAvatar from "ui/icons/PlatformAvatar";
import PlatformAvatarGroup from "ui/icons/PlatformAvatarGroup";
import variants from "utils/ts/variants";

// To be wrapped by a `Button` or `ButtonLink`.
const PlaidAccountsTableEmptyButtonContent = () => {
  return (
    <>
      <PlusCircle size={24} weight="light" /> Add connection
    </>
  );
};

type PlaidAccountsTableEmptyAvatarGroupProps = {
  variant: "banks" | "cards";
  className?: string;
};

const PlaidAccountsTableEmptyAvatarGroup: FC<PlaidAccountsTableEmptyAvatarGroupProps> = ({
  variant,
  className,
}) => {
  return variants(variant, {
    banks: (
      <>
        <PlatformAvatarGroup
          variant="rounded-square"
          overlapSide="right-over-left"
          className={className}
        >
          <PlatformAvatar variant="rounded-square">
            {/* <PlatformAvatar.Img src={wellsFargo} alt="Wells Fargo" /> */}
            <WellsFargoLogo />
          </PlatformAvatar>
          <PlatformAvatar variant="rounded-square">
            {/* <PlatformAvatar.Img src={mercury} alt="Mercury" /> */}
            <MercuryLogo />
          </PlatformAvatar>
          <PlatformAvatar variant="rounded-square">
            {/* <PlatformAvatar.Img src={bankOfAmerica} alt="Bank of America" /> */}
            <BankOfAmericaLogo />
          </PlatformAvatar>
          <PlatformAvatar variant="rounded-square">
            {/* <PlatformAvatar.Img src={chase} alt="Chase" /> */}
            <ChaseLogo />
          </PlatformAvatar>
        </PlatformAvatarGroup>
      </>
    ),
    cards: (
      <>
        <PlatformAvatarGroup
          variant="rounded-square"
          overlapSide="right-over-left"
          className={className}
        >
          <PlatformAvatar variant="rounded-square">
            {/* <PlatformAvatar.Img src={chase} alt="Chase" /> */}
            <ChaseLogo />
          </PlatformAvatar>
          <PlatformAvatar variant="rounded-square">
            {/* <PlatformAvatar.Img src={bankOfAmerica} alt="Bank of America" /> */}
            <BankOfAmericaLogo />
          </PlatformAvatar>
          <PlatformAvatar variant="rounded-square">
            {/* <PlatformAvatar.Img src={amex} alt="American Express" /> */}
            <AmexLogo />
          </PlatformAvatar>
          <PlatformAvatar variant="rounded-square">
            {/* <PlatformAvatar.Img src={capitalOne} alt="Capital One" /> */}
            <CapitalOneLogo />
          </PlatformAvatar>
        </PlatformAvatarGroup>
      </>
    ),
  });
};

type Props = ComponentProps<typeof EmptyState>;

const PlaidAccountsTableEmpty: FC<Props> = ({ ...emptyStateProps }) => {
  return <EmptyState variant="card" {...emptyStateProps} />;
};

export default Object.assign(PlaidAccountsTableEmpty, {
  AvatarGroup: PlaidAccountsTableEmptyAvatarGroup,
  ButtonContent: PlaidAccountsTableEmptyButtonContent,
});
