import { LinkBreak } from "@phosphor-icons/react";
import BankAccountBar from "modules/bank-accounts/components/BankAccountBar";
import { NormalizedAccountAugmented } from "modules/insights/utils/augment-normalized-account";
import PlaidInstitutionLogo from "modules/plaid/components/PlaidInstitutionLogo";
import { FC } from "react";
import AccountBar, { AccountBarIconSize } from "ui/data-display/AccountBar";
import { getPlaidAccountDisplayNameWithoutMask } from "utils/account";
import cn from "utils/tailwind/cn";

type Props = {
  normalizedAccountAugmented: NormalizedAccountAugmented;
  className?: string;
  size?: AccountBarIconSize;
};

const NormalizedAccountAugmentedBar: FC<Props> = ({
  normalizedAccountAugmented,
  className,
  size = 18,
}) => {
  switch (normalizedAccountAugmented.repType) {
    case "highbeam-account":
      return (
        <BankAccountBar
          className={className}
          size={size}
          bankAccount={normalizedAccountAugmented.rep}
        />
      );
    case "plaid-account":
      return (
        <AccountBar
          size={size}
          className={cn(
            normalizedAccountAugmented.status === "disconnected" && "text-yellow-700",
            className
          )}
          accountName={getPlaidAccountDisplayNameWithoutMask(normalizedAccountAugmented.rep)}
          accountNumber={normalizedAccountAugmented.rep.accountMask ?? undefined}
          icon={
            normalizedAccountAugmented.status === "disconnected" ? (
              <LinkBreak size={size} className="text-yellow-600" />
            ) : (
              <PlaidInstitutionLogo institutionId={normalizedAccountAugmented.rep.institutionId} />
            )
          }
        />
      );
  }
};

export default NormalizedAccountAugmentedBar;
