import questionFlowerImg from "assets/question-flower.svg";
import { FC, ReactNode } from "react";
import AccentCard from "ui/data-display/AccentCard";
import Button from "ui/inputs/Button";
import { Heading4, Paragraph } from "ui/typography";

type Props = {
  title?: ReactNode;
  description?: ReactNode;
  showContactSupport?: boolean;
  onContactSupport?: () => void;
};

const TransactionFlexpaneDispute: FC<Props> = ({
  title = "Don’t recognize this transaction?",
  description = "If you don’t recognize this transaction or believe it is fraudulent, please contact support.",
  onContactSupport = () => {},
  showContactSupport = true,
}) => {
  return (
    <>
      <AccentCard className="flex flex-col gap-y-5">
        <img src={questionFlowerImg} alt="?" className="size-10" />

        <div className="flex flex-col gap-y-1">
          <Heading4 className="text-sm text-grey-900">{title}</Heading4>
          <Paragraph className="text-sm text-grey-600">{description}</Paragraph>
        </div>
        {showContactSupport && (
          <Button variant="tertiary" onClick={() => onContactSupport()} className="w-fit">
            Contact support
          </Button>
        )}
      </AccentCard>
    </>
  );
};

export default TransactionFlexpaneDispute;
