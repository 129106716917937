import { z } from "zod";

const SHOPIFY_STORE_URL_REGEX = new RegExp(
  "^(?:https://)?(?:admin\\.shopify\\.com\\/store\\/([\\w-]+)|([\\w-]+)\\.myshopify\\.com)/?$"
);

const extractSanitizedShopifyStoreNameFromUrl = (storeUrl: string) => {
  const match = SHOPIFY_STORE_URL_REGEX.exec(storeUrl);
  if (!match) return undefined;
  return match[1] ?? match[2];
};

const shopifyStoreUrlSchema = z
  .string()
  .regex(SHOPIFY_STORE_URL_REGEX, { message: "Please enter a valid Shopify store URL." });

const shopifyStoreNameFromUrlSchema = shopifyStoreUrlSchema.transform((arg, ctx) => {
  const sanitizedStoreName = extractSanitizedShopifyStoreNameFromUrl(arg);
  if (!sanitizedStoreName) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Please enter a valid Shopify store URL.",
    });
    return z.NEVER;
  }
  return sanitizedStoreName;
});

export {
  SHOPIFY_STORE_URL_REGEX,
  shopifyStoreUrlSchema,
  extractSanitizedShopifyStoreNameFromUrl,
  shopifyStoreNameFromUrlSchema,
};
