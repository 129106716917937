import useUserGuid from "modules/jwt/queries/useUserGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

const leadQueryHooks = makeQueryHooks({
  name: "lead",
  useQueryVariables: () => {
    const userGuid = useUserGuid();
    return { userGuid };
  },
  useQueryFnMaker: (variables) => {
    const highbeamApi = useHighbeamApi();
    return () => {
      return highbeamApi.lead.getByUser(variables.userGuid);
    };
  },
});

export default leadQueryHooks;

// Hooks

export const useSignupSource = () => {
  return leadQueryHooks.useData({
    select: (lead) => lead?.source,
  });
};

export const useLeadUserReportedRevenueInDollars = () => {
  return leadQueryHooks.useData({
    select: (lead) => {
      return lead?.other?.user_reported_revenue ? Number(lead.other.user_reported_revenue) : null;
    },
  });
};
