import { DotsThreeCircle } from "@phosphor-icons/react";
import { FC } from "react";
import { Paragraph } from "ui/typography";

import BaseAiTaskModal, { BaseAiTaskModalProps } from "./BaseAiTaskModal";

type Props = Omit<BaseAiTaskModalProps, "variant">;

const SetupInProgressModal: FC<Props> = ({
  isOpen,
  onClose,
  title,
  onConfirm,
  onCancel,
  className,
  children,
  task,
}) => {
  const config = {
    title: title || "Setup in progress",
    confirmButtonText: "",
    confirmButtonVariant: "primary" as const,
    confirmButtonClassName: "",
    cancelButtonText: "Close",
  };

  const getTaskSpecificMessage = () => {
    switch (task!.type) {
      case "CleanAndCategorizeTransactions":
        return {
          mainMessage:
            "Our team will review your transaction categorization preferences and set up the AI to clean and organize your data. Your current categorization will remain unchanged in the meantime.",
          timeEstimate: "Setup is typically completed within 1 business day.",
        };
      case "MaximizeYield":
        return {
          mainMessage:
            "The Highbeam team will review your task and make sure the AI sets it up correctly. We'll email you when the task is ready.",
          timeEstimate: "Setup typically takes 1 business day or less.",
        };
      case "CashFlowForecast":
        return {
          mainMessage:
            "The Highbeam team will review your task and make sure the AI sets it up correctly. We'll email you when the task is ready.",
          timeEstimate: "Setup typically takes 1 business day or less.",
        };
      case "AuditingAndAlerts":
        return {
          mainMessage:
            "The Highbeam team will review your task and make sure the AI sets it up correctly. We'll email you when the task is ready.",
          timeEstimate: "Setup typically takes 1 business day or less.",
        };
      case "Generic":
      default:
        return {
          mainMessage:
            "Our team will review your task requirements and set up the AI accordingly. The task will remain on in the meantime.",
          timeEstimate: "Reviews typically last 1 business day or less.",
        };
    }
  };

  const { mainMessage, timeEstimate } = getTaskSpecificMessage();

  return (
    <BaseAiTaskModal
      isOpen={isOpen}
      onClose={onClose}
      title={title || config.title}
      config={config}
      onConfirm={onConfirm}
      onCancel={onCancel}
      className={className}
      onConfirmClick={() => {}}
      task={task}
    >
      <div className="mb-6">
        <div className="mb-4 flex items-center gap-3">
          <DotsThreeCircle size={24} />
          <Paragraph className="text-base px-2 font-[500]">Setup in progress</Paragraph>
        </div>
        <Paragraph className="text-s mt-4 flex font-[400]">{mainMessage}</Paragraph>
        <Paragraph className="text-s mt-4 flex font-[400]">{timeEstimate}</Paragraph>
      </div>
      {children}
    </BaseAiTaskModal>
  );
};

export default SetupInProgressModal;
