import { FC, ReactNode } from "react";
import cn from "utils/tailwind/cn";

type Props = {
  children: ReactNode;
  className?: string;
};

const DebugContainer: FC<Props> = ({ children, className }) => {
  return (
    <div className={cn("mx-6 my-2 flex flex-col gap-2 rounded-md bg-purple-100 p-4", className)}>
      {children}
    </div>
  );
};

export default DebugContainer;
