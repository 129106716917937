import { ArrowFatLinesDown, ArrowFatLinesUp, DotsThree, Trash } from "@phosphor-icons/react";
import ApyTooltipContent from "components/Accounts/InterestTier/ApyTooltipContent";
import YieldInfoTooltipContent from "components/Accounts/InterestTier/YieldEarnedTooltipContent";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import HighYieldTeaser from "modules/bank-accounts/components/HighYieldTeaser";
import getBankAccountDisplayName from "modules/bank-accounts/utils/getBankAccountDisplayName";
import isValidHighYieldAccountToClose from "modules/bank-accounts/utils/isValidHighYieldAccountToClose";
import { Suspense } from "react";
import { Navigate } from "react-router-dom";
import BankAccountRep from "reps/BankAccountRep";
import CashDisplay from "ui/data-display/money/CashDisplay";
import SummaryArray from "ui/data-display/SummaryArray";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import { HighbeamHighYieldLogo } from "ui/icons/logos/logos-square";
import Button from "ui/inputs/Button";
import ButtonLink from "ui/inputs/ButtonLink";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import IconWithTooltip from "ui/overlay/IconWithTooltip";
import Menu from "ui/overlay/Menu";
import Text from "ui/typography/Text";
import useIsAllowedToCloseBankAccount from "utils/permissions/useIsAllowedToCloseBankAccount";

import AccountDetailsIncomingPaymentsSection from "../AccountDetailsPage/AccountDetailsIncomingPaymentsSection";
import CloseBankAccountModal from "../CloseBankAccountModal";
import { useCloseBankAccountModal } from "../CloseBankAccountModal/state/closeBankAccountModalState";

import HighYieldInterestYtd from "./HighYieldInterestYtd";
import HighYieldTransactions from "./HighYieldTransactions";

type Props = {
  account: BankAccountRep.Complete;
};

const HighYieldPage: React.FC<Props> = ({ account }) => {
  const isAllowedToCloseBankAccount = useIsAllowedToCloseBankAccount();
  const { open: openCloseBankAccountModal } = useCloseBankAccountModal();

  return account.highbeamType.name === "HighYield" ? (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/accounts">Accounts</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>High yield</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header
          actions={
            <>
              <div className="flex w-full">
                <ButtonLink
                  to="/transfer-money"
                  state={{ toAccountGuid: account.guid }}
                  variant="secondary"
                  className="w-full rounded-r-none"
                >
                  <ArrowFatLinesDown size={14} />
                  Deposit
                </ButtonLink>
                <ButtonLink
                  to="/transfer-money"
                  state={{ fromAccountGuid: account.guid }}
                  variant="secondary"
                  disabled={account.availableBalance === 0}
                  className="w-full rounded-l-none border-l-0"
                >
                  <ArrowFatLinesUp size={14} />
                  Withdraw
                </ButtonLink>
              </div>
              {isAllowedToCloseBankAccount && isValidHighYieldAccountToClose(account) && (
                <Menu
                  button={
                    <Button variant="tertiary" paddingVariant="square">
                      <DotsThree size={16} />
                    </Button>
                  }
                >
                  <Menu.Item
                    variant="danger"
                    icon={<Trash size={16} />}
                    onClick={() => {
                      openCloseBankAccountModal({
                        bankAccount: account,
                      });
                    }}
                  >
                    <Text size={14}>Close account</Text>
                  </Menu.Item>
                </Menu>
              )}
            </>
          }
        >
          <DashboardPage.Header.BackCaret to="/accounts" />
          <DashboardPage.Header.IconTile
            icon={<HighbeamHighYieldLogo size={20} className="-mb-1 -mr-1" />}
          />
          <DashboardPage.Header.Title>
            {getBankAccountDisplayName(account, { showMaskedAccountNumber: false })}
          </DashboardPage.Header.Title>
        </DashboardPage.Header>

        {account.availableBalance === 0 && (
          <DashboardPage.Section>
            <HighYieldTeaser account={account} />
          </DashboardPage.Section>
        )}

        <DashboardPage.Section>
          <SummaryArray>
            <SummaryArray.Item>
              <SummaryArray.Heading>Account balance</SummaryArray.Heading>
              <CashDisplay cents={account.availableBalance} />
            </SummaryArray.Item>

            <SummaryArray.Item>
              <SummaryArray.Heading>
                Current APY
                <IconWithTooltip color="dark" tooltip={<ApyTooltipContent account={account} />} />
              </SummaryArray.Heading>
              <SummaryArray.Value>
                {(account.depositProduct.interestBps / 100).toFixed(2)}%
              </SummaryArray.Value>
            </SummaryArray.Item>

            <SummaryArray.Item>
              <SummaryArray.Heading>
                Accrued interest (YTD)
                <IconWithTooltip color="dark" tooltip={<YieldInfoTooltipContent />} />
              </SummaryArray.Heading>
              <Suspense fallback={<AnimatedSpinner />}>
                <HighYieldInterestYtd accountGuid={account.guid} />
              </Suspense>
            </SummaryArray.Item>
          </SummaryArray>
        </DashboardPage.Section>

        <AccountDetailsIncomingPaymentsSection
          unitCoDepositAccountId={account.unitCoDepositAccountId}
        />

        <DashboardPage.Section>
          <DashboardPage.Section.Header>
            <DashboardPage.Section.HeaderHeading>Transactions</DashboardPage.Section.HeaderHeading>
          </DashboardPage.Section.Header>
          <DashboardPage.Section.Body>
            <HighYieldTransactions account={account} />
          </DashboardPage.Section.Body>
        </DashboardPage.Section>
      </DashboardPage>

      <CloseBankAccountModal />
    </>
  ) : (
    <Navigate to="/accounts" />
  );
};

export default HighYieldPage;
