import IntelligenceStatusCleaningTransactions from "modules/insights/components/CleaningTransactions/IntelligenceStatusCleaningTransactions";
import { useRefreshBalanceHistoryQueries } from "modules/insights/queries/useBalanceHistory";
import { intelligenceStatusQueryHooks } from "modules/insights/queries/useIntelligenceStatus";
import { useNormalizedAccountsAugmented } from "modules/insights/queries/useNormalizedAccountsAugmented";
import {
  finishedCleaningEveryFinancialAccount,
  finishedCleaningIntelligenceData,
  getIntelligenceStatusWithFinancialAccountsBeingCleaned,
} from "modules/insights/utils/data-sources-utils";
import { FC, useEffect, useState } from "react";

type Props = {
  className?: string;
};

const AccountsOverviewCleaningTransactionsRenderer: FC<Props> = ({ className }) => {
  const [pollingInterval, setPollingInterval] = useState<number | null>(2000);

  const normalizedAccountsAugmented = useNormalizedAccountsAugmented({
    normalizedAccountsQueryOptions: {
      refetchInterval: pollingInterval ?? false,
    },
    filters: {
      includeHighbeam: true,
    },
  });

  const refreshBalanceHistoryQueries = useRefreshBalanceHistoryQueries();

  const { data: intelligenceStatus, isLoading: isLoadingIntelligenceStatus } =
    intelligenceStatusQueryHooks.useQuery({
      params: {},
      select: (data) => {
        return getIntelligenceStatusWithFinancialAccountsBeingCleaned(data);
      },
      enabled: Boolean(pollingInterval),
      refetchInterval: pollingInterval ?? false,
    });

  // Disable polling once all are finished. We continue polling indefinitely otherwise, which we may want to fix at some point.
  useEffect(() => {
    const finishedCleaningFinancialAccounts =
      intelligenceStatus &&
      finishedCleaningEveryFinancialAccount(intelligenceStatus.financialAccounts);

    const finishedCleaningEverything =
      intelligenceStatus && finishedCleaningIntelligenceData(intelligenceStatus);

    if (finishedCleaningFinancialAccounts) {
      refreshBalanceHistoryQueries();
    }
    if (finishedCleaningEverything) {
      setPollingInterval(null);
    }
  }, [intelligenceStatus, isLoadingIntelligenceStatus, refreshBalanceHistoryQueries]);

  // NB(alex): We don't check `intelligenceStatus.shopifyStatus` because it can be loading for an indefinite period of time.
  if (!intelligenceStatus || intelligenceStatus.financialAccounts.length === 0) {
    return null;
  }

  return (
    <IntelligenceStatusCleaningTransactions
      financialAccounts={intelligenceStatus.financialAccounts}
      shopifyStatus={intelligenceStatus.shopifyStatus}
      normalizedAccountsAugmented={normalizedAccountsAugmented}
      className={className}
    />
  );
};

export default AccountsOverviewCleaningTransactionsRenderer;
