import { ApiBuilder, useBackendV1Api } from "modules/highbeam-api/base";
import CapitalRepaymentRep from "reps/CapitalRepaymentRep";

type CapitalRepaymentApi = {
  create: (
    businessGuid: string,
    capitalAccountGuid: string,
    creator: CapitalRepaymentRep.Creator
  ) => Promise<void>;
};

const builder: ApiBuilder<CapitalRepaymentApi> = (api) => ({
  create: async (businessGuid, capitalAccountGuid, creator) => {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
      capitalAccountGuid: capitalAccountGuid,
    });

    const url = `/capital-repayment/repayments?${qp}`;

    return (await api.post<void>(url, creator))!;
  },
});

const useCapitalRepaymentApi = () => useBackendV1Api(builder);

export default useCapitalRepaymentApi;
