import { useCurrentBusinessMember } from "modules/business-members/queries/businessMemberQueryHooks";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import AssignedBillStatsRep from "reps/AssignedBillStatsRep";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

import useAssignedBillStatsApi from "../api/useAssignedBillStatsApi";

import { BILL_SUMMARIES_ROOT_QUERY_KEY } from "./config";

const assignedBillStatsQueryHooks = makeQueryHooks({
  rootName: BILL_SUMMARIES_ROOT_QUERY_KEY,
  name: "assigned-bill-stats",
  useQueryVariables() {
    const businessGuid = useBusinessGuid();
    const currentBusinessMember = useCurrentBusinessMember();
    const businessMemberGuid = currentBusinessMember?.guid ?? null;
    return { businessGuid, businessMemberGuid };
  },
  useQueryFnMaker({ businessGuid, businessMemberGuid }) {
    const assignedBillStatsApi = useAssignedBillStatsApi();
    return async () => {
      // When superusering, we don't have a business member guid, so we can't call this backend endpoint.
      if (!businessMemberGuid) {
        const empty: AssignedBillStatsRep = {
          granted: { totalCount: 0 },
          requested: { totalCount: 0 },
        };
        return empty;
      }
      return await assignedBillStatsApi.getByBusinessMember(businessGuid, businessMemberGuid);
    };
  },
});

export default assignedBillStatsQueryHooks;
