import env from "env";
import { useFinishGetStartedActionItemMutation } from "modules/action-items/mutations/useFinishGetStartedActionItemMutation";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { ReactNode, useCallback, useRef, useState } from "react";
import { useRutterLink } from "react-rutter-link";
import RutterConnectionRep from "reps/RutterConnectionRep";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import { notify } from "ui/feedback/Toast";
import Modal from "ui/overlay/Modal";
import { Paragraph } from "ui/typography";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import {
  getRutterPlatformAccountTypeDisplayName,
  getRutterPlatformDisplayName,
} from "utils/rutter";

type ConnectCallbacks = {
  closeModal: () => void;
};

type RenderPropsArgs = {
  openModal: () => void;
};

type Props = {
  platform: RutterConnectionRep.RutterPlatform;
  children?: ReactNode | ((args: RenderPropsArgs) => ReactNode);
  onConnect: (connection: RutterConnectionRep.Complete, callbacks: ConnectCallbacks) => void;
};

const ConnectRutter: React.FC<Props> = ({ platform, children, onConnect }) => {
  const { segmentTrack } = useSegment();
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const [isVisible, setIsVisible] = useState(false);
  const { mutateAsync: finishGetStartedActionItem } = useFinishGetStartedActionItemMutation();
  const platformName = `${getRutterPlatformDisplayName(platform)} ${getRutterPlatformAccountTypeDisplayName(platform)}`;

  const rutterConfig = {
    publicKey: env.RUTTER_PUBLIC_KEY,
    onSuccess: async (publicToken: string) => {
      hideModal();
      const result = await highbeamApi.rutterConnection.create(businessGuid, publicToken);
      if (!result.isActive) {
        notify(
          "error",
          `There was an error connecting your ${platformName}. Please try again or contact support.`
        );
        hideModal();
        return;
      }
      notify("success", `${platformName} connected`);
      segmentTrack(SEGMENT_EVENTS.RUTTER_CONNECT_COMPLETED);
      await finishGetStartedActionItem({ step: "ConnectStore", state: "Complete" });
      onConnect(result, callbacks);
    },
    onExit: (event: string) => {
      if (event === "MERCHANT_CLOSED") {
        hideModal();
      }
    },
  };
  const { open: openRutter, exit: exitRutter } = useRutterLink(rutterConfig);

  const openModal = () => {
    if (!openRutter) {
      return;
    }
    // NOTE: Popup must be created before any async calls to not have browsers block it
    openRutter({ platform });
    setIsVisible(true);
  };
  const hideModal = useCallback(() => setIsVisible(false), []);
  const closeModal = () => {
    exitRutter();
    hideModal();
  };
  const callbacks = useRef<ConnectCallbacks>({ closeModal }).current;
  const renderPropsArgs = { openModal };

  return (
    <>
      {isVisible && (
        <Modal title={`Connect your ${platformName}`} onClose={closeModal}>
          <div className="flex flex-col items-center gap-y-6">
            <AnimatedSpinner size={56} className="text-grey-500" />
            <Paragraph className="text-md">
              Please grant Highbeam permission to connect to your {platformName} in the new window.
            </Paragraph>
          </div>
        </Modal>
      )}
      {typeof children === "function" && children(renderPropsArgs)}
      {typeof children !== "function" && children}
    </>
  );
};
export default ConnectRutter;
