import useChannelApi from "modules/chat/api/useChannelApi";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";

type Params = {
  businessGuid: string;
};

export const chatChannelsByBusiness = makeQueryHooksV2({
  useDefaultParams: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  makeQueryKey: (params: Params) => ["chat", "channels", "business", params],
  useQueryFnParams: () => {
    const channelApi = useChannelApi();
    return { channelApi };
  },
  makeQueryFn: ({ channelApi }, { businessGuid }) => {
    return () => {
      return channelApi.listByBusiness(businessGuid);
    };
  },
});
