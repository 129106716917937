import { Coins } from "@phosphor-icons/react";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import CapitalAccountTabs, {
  useCapitalAccountTabsSearchParamState,
} from "modules/capital-accounts/components/CapitalAccountTabs";
import RequestAdditionalCapitalButton from "modules/capital-accounts/components/RequestAdditionalCapitalButton";
import useNonTerminatedCapitalAccounts from "modules/capital-accounts/hooks/useNonTerminatedCapitalAccounts";
import useTerminatedCapitalAccounts from "modules/capital-accounts/hooks/useTerminatedCapitalAccounts";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

// TODO(alex): We will move this view within the `CapitalOverviewPage` when or before rolling out `CAPITAL_OVERVIEW_PAGE`.
import UpsellCapitalView from "../CapitalAccountPage/views/UpsellCapitalView";

import CapitalAccountsList from "./CapitalAccountsList";

const CapitalOverviewContent = () => {
  const [activeTab, setActiveTab] = useCapitalAccountTabsSearchParamState();
  const terminatedAccounts = useTerminatedCapitalAccounts();
  const nonTerminatedAccounts = useNonTerminatedCapitalAccounts();
  const accounts = activeTab === "Closed" ? terminatedAccounts : nonTerminatedAccounts;

  if (terminatedAccounts.length === 0 && nonTerminatedAccounts.length === 0) {
    return <UpsellCapitalView />;
  }

  return (
    <>
      <DashboardPage.Header actions={<RequestAdditionalCapitalButton />}>
        <DashboardPage.Header.IconTile icon={<Coins />} />
        <DashboardPage.Header.Title>Capital</DashboardPage.Header.Title>
      </DashboardPage.Header>

      <DashboardPage.Section>
        {terminatedAccounts.length > 0 && (
          <CapitalAccountTabs
            className="mb-8 mt-3 w-max"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
        <CapitalAccountsList accounts={accounts} />
      </DashboardPage.Section>
    </>
  );
};

const CapitalOverviewPage = () => {
  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>Capital</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <CapitalOverviewContent />
      </DashboardPage>
    </>
  );
};

export default CapitalOverviewPage;
