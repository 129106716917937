import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useQueryOptions from "utils/react-query/useQueryOptions";

import useCapitalDrawdownApprovalApi from "../api/useCapitalDrawdownApprovalApi";

type Params = {
  capitalAccountGuid: string;
};

export const useCapitalDrawdownApprovalsQueryOptions = ({ capitalAccountGuid }: Params) => {
  const capitalDrawdownApprovalApi = useCapitalDrawdownApprovalApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: ["capitalDrawdownApprovals", { businessGuid, capitalAccountGuid }],
    queryFn: () => {
      return capitalDrawdownApprovalApi.get(capitalAccountGuid, businessGuid);
    },
  });
};

export const useCapitalDrawdownApprovalsQuery = (params: Params) => {
  return useQuery(useCapitalDrawdownApprovalsQueryOptions(params));
};

const useCapitalDrawdownApprovals = (params: Params) => {
  const { data } = useSuspenseQuery(useCapitalDrawdownApprovalsQueryOptions(params));
  return data;
};

export default useCapitalDrawdownApprovals;
