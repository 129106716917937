import env from "../../env";

/**
 * Highbeam supports an "Insights App" for Shopify, that only includes insights features.
 * The purpose of this is to satisfy Shopify's requirement that integrations don't offer capital.
 *
 * At the time of writing, this is deployed to
 * - app.staging.insights.highbeam.co
 * - app.insights.highbeam.co
 *
 * This helper returns whether we're on one of those domains,
 * in which case the "Insights App" should be rendered instead of the normal app.
 */
const checkInsightsAppEnabled = (): boolean => {
  const host = window.location.host;
  if (env.USE_INSIGHTS_APP) return true; // For local testing, set VITE_USE_INSIGHTS_APP to true.
  return host.endsWith("insights.highbeam.co");
};

export default checkInsightsAppEnabled;
