import { ROOT_BUSINESSES_QUERY_KEY } from "modules/businesses/queries/businessesQueryHooks";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useQueryOptions from "utils/react-query/useQueryOptions";

import useBusinessDetailsApi from "../api/useBusinessDetailsApi";

const useBusinessDetailsQueryOptions = () => {
  const businessGuid = useBusinessGuid();
  const businessDetailsApi = useBusinessDetailsApi();

  return useQueryOptions({
    queryKey: [ROOT_BUSINESSES_QUERY_KEY, "businessDetails", { businessGuid }],
    queryFn: () => businessDetailsApi.get(businessGuid),
  });
};

export default useBusinessDetailsQueryOptions;
