import { ApiBuilder, useBackendV1Api } from "modules/highbeam-api/base";
import CapitalDrawdownApprovalRep from "reps/CapitalDrawdownApprovalRep";
import CapitalDrawdownApprovalUploadUrlRep from "reps/CapitalDrawdownApprovalUploadUrlRep";

type CapitalDrawdownApprovalApi = {
  get: (
    capitalAccountGuid: string,
    businessGuid: string
  ) => Promise<CapitalDrawdownApprovalRep.Complete[]>;
  create: (
    businessGuid: string,
    capitalAccountGuid: string,
    creator: CapitalDrawdownApprovalRep.Creator
  ) => Promise<CapitalDrawdownApprovalRep.Complete>;
  uploadUrl: (
    guid: string,
    businessGuid: string
  ) => Promise<CapitalDrawdownApprovalUploadUrlRep.Complete>;
};

const builder: ApiBuilder<CapitalDrawdownApprovalApi> = (api) => ({
  get: async (capitalAccountGuid, businessGuid) => {
    const qp = new URLSearchParams({ businessGuid, capitalAccountGuid });
    const path = `/capital-transactions/drawdown-approval?${qp}`;
    return (await api.get<CapitalDrawdownApprovalRep.Complete[]>(path))!;
  },
  create: async (businessGuid, capitalAccountGuid, creator) => {
    const qp = new URLSearchParams({ businessGuid, capitalAccountGuid });
    const url = `/capital-transactions/drawdown-approval?${qp}`;
    return (await api.post(url, creator))!;
  },
  uploadUrl: async (guid, businessGuid) => {
    const qp = new URLSearchParams({ businessGuid });
    const path = `/capital-transactions/drawdown-approval/${guid}/upload-url?${qp}`;
    return (await api.get<CapitalDrawdownApprovalUploadUrlRep.Complete>(path))!;
  },
});

const useCapitalDrawdownApprovalApi = () => useBackendV1Api(builder);

export default useCapitalDrawdownApprovalApi;
