import dayjs from "dayjs";
import { YearChatElementRep } from "modules/chat/reps/ChatElementRep";
import React from "react";

type Props = {
  element: YearChatElementRep;
};

const YearChatElement: React.FC<Props> = ({ element }) => {
  return <span>{dayjs(element.value, "YYYY").tz("America/New_York").format("YYYY")}</span>;
};

export default YearChatElement;
