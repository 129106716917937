import { FEATURE_FLAGS } from "flags";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import BankAccountFailedDebitsSection from "modules/bank-accounts/components/BankAccountFailedDebitsSection";
import { useIsBusinessActive } from "modules/business/queries/useBusiness";
import CardAuthorizations from "modules/card-authorizations-table/CardAuthorizations";
import { cardAuthorizationsQueryHooks } from "modules/card-authorizations/queries/useCardAuthorizations";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import IncomingPaymentsTable from "modules/incoming-payments/components/IncomingPaymentsTable";
import { useIncomingPaymentsQueries } from "modules/incoming-payments/queries/useIncomingPayments";
import EnrichedTransactionsSection from "modules/insights/components/EnrichedTransactionsSection";
import VerifyBusinessBannerRenderer from "modules/onboarding/components/verify-business/VerifyBusinessBannerRenderer";
import { Suspense, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import SectionV2 from "ui/data-display/SectionV2";
import SectionLoading from "ui/feedback/SectionLoading";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import Tabs, { Tab } from "ui/navigation/Tabs";
import { useIsTablet } from "utils/device/useMediaQuery";
import variants from "utils/ts/variants";

import AccountsTransactionsPageTabs, {
  useAccountsTransactionsPageTabs,
} from "./AccountsTransactionsPageTabs";
import Transactions from "./Transactions";

const HighbeamAccountsTransactions = () => {
  const isTablet = useIsTablet();
  const [activeTab, setActiveTab] = useState<string>("incoming");
  const [scrollTransactionsIntoView, setScrollTransactionsIntoView] = useState(false);
  const transactionScrollRef = useRef<HTMLDivElement>(null);
  const { hash } = useLocation();

  const tabs: Tab[] = [
    { id: "incoming", label: "Incoming" },
    { id: "transactions", label: "Transactions" },
  ];

  useEffect(() => {
    if (hash === "#transactions") {
      setActiveTab("transactions");
      setScrollTransactionsIntoView(true);
    }
  }, [hash]);

  const { data: incomingPayments = [] } = useIncomingPaymentsQueries();
  const hasIncoming = incomingPayments.length > 0;
  const { data: hasAuthorizations } = cardAuthorizationsQueryHooks.useQuery({
    params: { page: 1, accountType: "deposit" },
    select: (data) => data.meta.pagination.total > 0,
  });

  return (
    <>
      {isTablet && hasIncoming && (
        <Tabs
          tabsWrapperAdditionalClassName="px-4 w-auto tablet:px-8"
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )}

      <BankAccountFailedDebitsSection />

      {(!isTablet || activeTab === "incoming") && hasIncoming && (
        <DashboardPage.Section>
          <DashboardPage.Section.Header>
            <DashboardPage.Section.HeaderHeading>In transit</DashboardPage.Section.HeaderHeading>
          </DashboardPage.Section.Header>
          <DashboardPage.Section.Body>
            <IncomingPaymentsTable />
          </DashboardPage.Section.Body>
        </DashboardPage.Section>
      )}

      {(!isTablet || activeTab === "transactions" || !hasIncoming) && (
        <>
          {hasAuthorizations && (
            <DashboardPage.Section>
              <DashboardPage.Section.Body>
                <CardAuthorizations accountType="deposit" />
              </DashboardPage.Section.Body>
            </DashboardPage.Section>
          )}

          <DashboardPage.Section>
            <Transactions
              scrollRef={scrollTransactionsIntoView ? transactionScrollRef : undefined}
            />
          </DashboardPage.Section>
        </>
      )}
    </>
  );
};

const AccountsTransactionsPageContent = () => {
  const isBusinessActive = useIsBusinessActive();
  const { tabs, activeTab, setActiveTab } = useAccountsTransactionsPageTabs(
    isBusinessActive ? "highbeam-accounts" : "connected-accounts"
  );
  const enrichedTransactionsEnabled = useFeatureFlag(FEATURE_FLAGS["enriched-transactions"]);

  return (
    <>
      <DashboardPage.Header>
        <DashboardPage.Header.Title>Account transactions</DashboardPage.Header.Title>
      </DashboardPage.Header>

      {/* NB(alex): We'll have to push up another PR to delete the `isSuperusering` once we're ready to roll this out. */}
      {enrichedTransactionsEnabled ? (
        <>
          <AccountsTransactionsPageTabs
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            className="mt-12"
          />
          {variants(activeTab, {
            "highbeam-accounts": (
              <Suspense fallback={<SectionLoading className="mt-6" />}>
                <SectionV2 variant="dashboard-page">
                  {isBusinessActive ? (
                    <HighbeamAccountsTransactions />
                  ) : (
                    <VerifyBusinessBannerRenderer />
                  )}
                </SectionV2>
              </Suspense>
            ),
            "connected-accounts": (
              <Suspense fallback={<SectionLoading />}>
                <EnrichedTransactionsSection accountType="Deposit" includeHighbeam={false} />
              </Suspense>
            ),
          })}
        </>
      ) : (
        <HighbeamAccountsTransactions />
      )}
    </>
  );
};

const AccountsTransactionsPage = () => {
  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/accounts">Accounts</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Transactions</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <AccountsTransactionsPageContent />
      </DashboardPage>
    </>
  );
};

export default AccountsTransactionsPage;
