import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";
import OffsetPaginatedResultsRep from "reps/OffsetPaginatedResultsRep";
import { createURLSearchParamsFromObject } from "utils/search-params/create-url-search-params";

import { EnrichedTransactionRep } from "../reps/EnrichedTransactionRep";

type SearchEnrichedTransactionQueryParams = {
  businessGuid: string;
  page: number; // Long = 1
  pageSize: number; // Long = 50
  dateRangeStart: string; // LocalDate
  dateRangeEndInclusive: string; // LocalDate
  normalizedAccountIds?: string[];
  textQuery?: string;
};

type EnrichedTransactionApi = {
  search: (
    params: SearchEnrichedTransactionQueryParams
  ) => Promise<OffsetPaginatedResultsRep<EnrichedTransactionRep>>;
};

const builder: ApiBuilder<EnrichedTransactionApi> = (api) => ({
  search: async ({ businessGuid, ...params }) => {
    const qp = createURLSearchParamsFromObject(params);
    const url = `/insights/enriched-transactions/${businessGuid}?${qp}`;
    return (await api.get<OffsetPaginatedResultsRep<EnrichedTransactionRep>>(url))!;
  },
});

const useEnrichedTransactionApi = () => {
  return useBackendV2Api(builder);
};

export { type SearchEnrichedTransactionQueryParams };

export default useEnrichedTransactionApi;
