import InvoiceRep from "reps/InvoiceRep";
import TransactionRep from "reps/TransactionRep";
import { startOfBankingDay } from "utils/date";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class TransactionApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async list(
    businessGuid: string,
    extension: string,
    unitCoDepositAccountId?: string,
    query?: string,
    from?: Date,
    to?: Date,
    accountType: TransactionRep.AccountType = "Deposit"
  ): Promise<string> {
    const queryParams = new URLSearchParams({ businessGuid });
    if (unitCoDepositAccountId) queryParams.set("unitCoDepositAccountId", unitCoDepositAccountId);
    if (query) queryParams.set("query", query);
    if (from) queryParams.set("from", startOfBankingDay(from).format());
    if (to) queryParams.set("to", startOfBankingDay(to).add(1, "day").format());
    if (accountType) queryParams.set("accountType", accountType);
    const url = `/transactions/search?${queryParams.toString()}`;
    const headers = { Accept: `text/${extension}` };
    return (await this.api.get<string>(url, headers))!;
  }

  async setInvoice(businessGuid: string, invoiceGuid: string): Promise<InvoiceRep.Complete> {
    const url = `/businesses/${businessGuid}/invoices/${invoiceGuid}`;
    return (await this.api.put<InvoiceRep.Complete>(url))!;
  }

  async getInvoice(businessGuid: string, invoiceGuid: string): Promise<InvoiceRep.Complete> {
    const url = `/businesses/${businessGuid}/invoices/${invoiceGuid}`;
    return (await this.api.get<InvoiceRep.Complete>(url))!;
  }

  async getBySourceTransactionId(
    businessGuid: string,
    sourceTransactionIds: string[]
  ): Promise<TransactionRep.Complete[]> {
    const queryParams = new URLSearchParams();
    sourceTransactionIds.forEach((id) => {
      queryParams.append("sourceTransactionIds", id);
    });

    const url = `/businesses/${businessGuid}/transactions?${queryParams.toString()}`;
    return (await this.api.get<TransactionRep.Complete[]>(url))!;
  }

  async setTransactionCategory(
    businessGuid: string,
    transactionGuid: string,
    subcategoryGuid: string
  ): Promise<void> {
    const url = `/businesses/${businessGuid}/transactions/${transactionGuid}/categories/${subcategoryGuid}`;

    return (await this.api.put<void>(url))!;
  }
}
