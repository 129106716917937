import { CheckDepositStatus } from "@highbeam/unit-node-sdk";
import TransactionFlexpaneStatus from "components/common/transaction-flexpane/TransactionFlexpaneStatus";
import useUnitCoCheckDeposit from "modules/unit-co-check-deposits/queries/useUnitCheckDeposit";
import { FC } from "react";
import { ValueOf } from "type-fest";
import { PillColor } from "ui/data-display/Pill";

const simplifiedCheckDepositStatuses = {
  AwaitingBackImage: "Unknown",
  AwaitingCustomerConfirmation: "Unknown",
  AwaitingFrontImage: "Unknown",
  AwaitingImages: "Unknown",
  Canceled: "Canceled",
  Clearing: "Clearing",
  Pending: "Pending",
  PendingReview: "Pending",
  Rejected: "Failed",
  Returned: "Failed",
  Sent: "Sent",
} as const satisfies { [key in CheckDepositStatus]: string };

type SimplifiedCheckDepositStatuses = ValueOf<typeof simplifiedCheckDepositStatuses>;

const pillColorMappings: { [key in SimplifiedCheckDepositStatuses]: PillColor } = {
  Canceled: "orange-200",
  Clearing: "grey-200",
  Pending: "grey-100",
  Failed: "pink-100",
  Sent: "green-100", // TODO(alex): The previous `PaymentStatusPill` didn't handle this case so this value should be audited.
  Unknown: "grey-100", // TODO(alex): The previous `PaymentStatusPill` didn't handle this case so this value should be audited.
};

type Props = {
  checkDepositId: string;
};

const CheckDepositFlexpaneStatusPill: FC<Props> = ({ checkDepositId }) => {
  const checkDeposit = useUnitCoCheckDeposit({ checkDepositId });

  const statusLabel = simplifiedCheckDepositStatuses[checkDeposit.attributes.status];
  const pillColor = pillColorMappings[statusLabel];

  return (
    <TransactionFlexpaneStatus
      pillColor={pillColor}
      status={statusLabel}
      methodName="Check deposit"
    />
  );
};

export default CheckDepositFlexpaneStatusPill;
