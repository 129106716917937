import dayjs from "dayjs";
import { formatValue } from "react-currency-input-field";
import { formatNumberCompact } from "utils/numbers";

const DATE_MMM_D = "MMM D";
const DATE_MMM_D_YY = "MMM D, ’YY";
const DATE_MMM_D_YYYY = "MMM D, YYYY";

/**
 * X Axis
 */

type XAxisYearMonthDayParams = {
  value: string;
  type: "YYYY-MM-DD";
};

export type XAxisParams = XAxisYearMonthDayParams;

export type XAxisType = XAxisParams["type"];

type FormatXAxisValueParams = XAxisParams & {
  compact: boolean;
};

export const formatXAxisValue = ({ value, type, compact }: FormatXAxisValueParams): string => {
  switch (type) {
    case "YYYY-MM-DD":
      const valueAsDayjs = dayjs(value, "YYYY-MM-DD", true);
      if (valueAsDayjs.isValid()) {
        if (valueAsDayjs.isSame(dayjs(), "day")) {
          return "Today";
        }
        if (compact) {
          const isWithinLast12Months = valueAsDayjs.isAfter(dayjs().subtract(1, "year"), "day");
          return valueAsDayjs.format(isWithinLast12Months ? DATE_MMM_D : DATE_MMM_D_YY);
        } else {
          return valueAsDayjs.format(DATE_MMM_D_YYYY);
        }
      }
      throw new Error("Invalid date string in `formatXAxisValue`");
  }
};

type GetXAxisLabelCountByChartWidthParams = {
  chartWidth: number;
  xAxisLabelWidth: number;
  xAxisLabelGapWidth: number;
  xAxisHorizontalPadding: number;
};
/**
 * Determines how many axis labels to render based on chart width.
 */
export const getXAxisLabelCountByChartWidth = (params: GetXAxisLabelCountByChartWidthParams) => {
  const { chartWidth, xAxisLabelWidth, xAxisLabelGapWidth, xAxisHorizontalPadding } = params;

  const numberOfLabels =
    (chartWidth - xAxisHorizontalPadding * 2 - xAxisLabelGapWidth) /
    (xAxisLabelWidth + xAxisLabelGapWidth);

  return Math.ceil(numberOfLabels);
};

// TODO(alex): We should replace this function with `getXAxisLabelCountByChartWidth` above soon, but that will require refactoring `getXAxisIntervalsByChartWidth`.
export const getXAxisIntervalSpacingByChartWidth = (chartWidth: number) => {
  if (chartWidth > 512) return 8;
  if (chartWidth > 256) return 5;
  return 3;
};

type GetXAxisIntervalsByChartWidthParams = {
  index: number;
  value: string;
  dataLength: number;
  chartWidth: number;
};

// Custom label display interval to prevent the first and last label from getting cut off.
export const getXAxisIntervalsByChartWidth = ({
  index,
  dataLength,
  chartWidth,
}: GetXAxisIntervalsByChartWidthParams) => {
  const numberOfIntervals = getXAxisIntervalSpacingByChartWidth(chartWidth ?? 0);
  const autoInterval = Math.ceil(dataLength / numberOfIntervals);

  // Prevents first & last label from getting cut off.
  if (index === 0 || index === dataLength - 1) return false;

  return (index + Math.ceil(autoInterval / 2)) % autoInterval === 0;
};

/**
 * Y Axis
 */

export type YAxisNumberParams = {
  value: number | string;
  type: "number";
  compact?: boolean;
};

export type YAxisMoneyParams = {
  value: number | string;
  type: "money";
  compact?: boolean;
};

export type YAxisParams = YAxisNumberParams | YAxisMoneyParams;

export type YAxisType = YAxisParams["type"];

export const checkIsValidYAxisValue = (
  value: unknown,
  type: YAxisType
): value is YAxisParams["value"] => {
  switch (type) {
    case "money":
    case "number":
      return typeof value === "number" || typeof value === "string";
  }
};

type FormatYAxisValueParams = YAxisParams;

export const formatYAxisValue = (params: FormatYAxisValueParams): string => {
  switch (params.type) {
    case "money":
      const numberAsString = params.compact
        ? formatNumberCompact(Number(params.value))
        : formatValue({
            value: params.value.toString(),
            decimalScale: 2, // Assumes USD. We'd need to pass in a currency if we want to support other currencies.
          });
      return `$${numberAsString}`;
    case "number":
      return params.compact ? formatNumberCompact(Number(params.value)) : params.value.toString();
  }
};
