import { Account, DepositAccount } from "@highbeam/unit-node-sdk";
import { BankAccountApi } from "modules/bank-accounts/api/useBankAccountApi";
import BankAccountRep from "reps/BankAccountRep";
import PlaidAccountRep from "reps/PlaidAccountRep";
import PlaidConnectionRep from "reps/PlaidConnectionRep";
import {
  AmexLogo,
  BankOfAmericaLogo,
  CapitalOneLogo,
  ChaseLogo,
  ChimeLogo,
  CreditUnionOfColoradoLogo,
  FcuLogo,
  MercuryLogo,
  NovoLogo,
  ShopifyLogo,
  TdLogo,
  UsBankLogo,
  WellsFargoLogo,
  BrexLogo,
} from "ui/icons/logos/logos-square";
import notEmpty from "utils/notEmpty";
import {
  isDigitsOnly,
  removeTextFromBeginningAndTrim,
  removeTextFromEndAndTrim,
  toTitleCase,
} from "utils/string";
import { AccountTags } from "utils/types/transactionsTypes";

export function getPlaidBankAccountInstitutionLogo(institutionId: string | null) {
  switch (institutionId) {
    case "ins_10":
      return AmexLogo;
    case "ins_127989":
      return BankOfAmericaLogo;
    case "ins_127888":
      return BrexLogo;
    case "ins_128026":
      return CapitalOneLogo;
    case "ins_3":
    case "ins_56":
      return ChaseLogo;
    case "ins_35":
      return ChimeLogo;
    case "ins_127934":
      return CreditUnionOfColoradoLogo;
    case "ins_118051":
      return FcuLogo;
    case "ins_116794":
      return MercuryLogo;
    case "ins_116594":
      return NovoLogo;
    case "ins_132442":
      return ShopifyLogo;
    case "ins_14":
      return TdLogo;
    case "ins_127990":
      return UsBankLogo;
    case "ins_4":
      return WellsFargoLogo;
    default:
      return null;
  }
}

export const getPlaidAccountDisplayNameWithoutMask = (account: PlaidAccountRep.Complete) => {
  const institutionLogo = getPlaidBankAccountInstitutionLogo(account.institutionId);
  const institutionNameWithoutBank = removeTextFromEndAndTrim(account.institutionName, "Bank");
  const institutionNamePrefix =
    !institutionLogo && account.institutionName ? `${institutionNameWithoutBank} ` : "";

  const accountMask = account.accountMask || "";
  const originalAccountName = account.accountName;
  const withoutBank = removeTextFromBeginningAndTrim(
    originalAccountName,
    institutionNameWithoutBank
  );
  const withoutBankMask = removeTextFromEndAndTrim(withoutBank, accountMask);
  const withoutLongMask = removeOptionalDigitFromEnd(withoutBankMask);
  const withoutDash = removeTextFromEndAndTrim(withoutLongMask, "-");
  const withoutDots = removeTextFromEndAndTrim(withoutDash, "...");
  const titleCased = toTitleCase(withoutDots);

  return `${institutionNamePrefix}${titleCased}`;
};

const removeOptionalDigitFromEnd = (str: string): string => {
  const lastChar = str.charAt(str.length - 1);
  if (isDigitsOnly(lastChar)) {
    return str.slice(0, -1);
  }
  return str;
};

export function getPlaidBankAccountDisplayName(account: PlaidAccountRep.Complete) {
  const nameWithoutMask = getPlaidAccountDisplayNameWithoutMask(account);
  if (account.accountMask === null) {
    return nameWithoutMask;
  }
  return `${nameWithoutMask} •• ${account.accountMask}`;
}

export function getPartnerBankNameForAccount(
  account: BankAccountRep.Complete | undefined
): BankAccountRep.PartnerBank {
  if (account?.threadAccount) {
    return BankAccountRep.PartnerBank.THREAD;
  }
  return BankAccountRep.PartnerBank.BLUE_RIDGE;
}

export async function getHighbeamAccountsForUnitAccounts(
  bankAccountApi: BankAccountApi,
  unitAccounts: Account[]
) {
  return await Promise.all(
    unitAccounts?.map((unitAccount) => {
      const tags = (unitAccount as DepositAccount).attributes.tags as AccountTags;
      if (!tags?.bankAccountGuid) {
        return null;
      }

      return bankAccountApi.get(tags.bankAccountGuid!);
    }) ?? []
  ).then((accounts) => accounts.filter(notEmpty));
}

export function getAccountType(account: BankAccountRep.Complete): string {
  switch (account.highbeamType.name) {
    case "DepositAccount":
      return "Business checking";
    case "HighYield":
      return "High yield";
  }
}

export const getAccountPartnerBank = (account: BankAccountRep.Complete): string => {
  if (account.threadAccount) {
    return "Thread Bank";
  } else {
    return "Blue Ridge Bank";
  }
};

export const getPartnerBankAddress = (account: BankAccountRep.Complete): string => {
  if (account.threadAccount) {
    return "210 E Main St";
  } else {
    return "17 W Main St";
  }
};

export const getPartnerBankAddressCityState = (account: BankAccountRep.Complete): string => {
  if (account.threadAccount) {
    return "Rogersville, TN 37857";
  } else {
    return "Luray, VA 22835";
  }
};

export function sortPlaidAccounts(a: PlaidAccountRep.Complete, b: PlaidAccountRep.Complete) {
  // Active connections always go before inactive ones
  if (a.isConnectionActive !== b.isConnectionActive) {
    return a.isConnectionActive ? -1 : 1;
  }

  // Then sort by institutionId (not exposed to user)
  // https://highbeamco.slack.com/archives/C03SQ3K21QV/p1740536348109269?thread_ts=1740533265.832549&cid=C03SQ3K21QV
  if (a.institutionId.localeCompare(b.institutionId) !== 0) {
    return a.institutionId.localeCompare(b.institutionId);
  }

  // Deposit accounts always go before credit
  if (a.accountType === "DEPOSITORY" && b.accountType !== "DEPOSITORY") {
    return -1;
  }
  if (a.accountType !== "DEPOSITORY" && b.accountType === "DEPOSITORY") {
    return 1;
  }
  // Then sort by accountName
  const nameComparison = a.accountName.localeCompare(b.accountName);
  if (nameComparison !== 0) {
    return nameComparison;
  }
  // Then by accountMask
  if (a.accountMask && b.accountMask) {
    const maskComparison = a.accountMask.localeCompare(b.accountMask);
    if (maskComparison !== 0) {
      return maskComparison;
    }
  } else if (a.accountMask) {
    return -1; // account with accountMask comes first
  } else if (b.accountMask) {
    return 1; // account with accountMask comes first
  }
  // Finally sort by plaidAccountId
  return a.plaidAccountId.localeCompare(b.plaidAccountId);
}

export function sortPlaidConnections(
  a: PlaidConnectionRep.Complete,
  b: PlaidConnectionRep.Complete
) {
  return (
    Number(b.isActive) - Number(a.isActive) || a.institutionName.localeCompare(b.institutionName)
  );
}

export const maskAccountNumber = (accountNumber: string): string => {
  return `•• ${accountNumber.slice(-4)}`;
};

export const maskBankAccountAccountNumber = (
  bankAccount: BankAccountRep.Complete
): BankAccountRep.Complete => {
  return {
    ...bankAccount,
    accountNumber: maskAccountNumber(bankAccount.accountNumber),
  };
};
