import { FC } from "react";
import Listbox from "ui/inputs/Listbox";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";
import { RelativeTimeframe } from "utils/timeframe";
import variants from "utils/ts/variants";

type RelativeTimeframeSelectProps = {
  value: RelativeTimeframe;
  onChange: (newValue: RelativeTimeframe) => void;
};

const RelativeTimeframeSelect: FC<RelativeTimeframeSelectProps> = ({ value, onChange }) => (
  <Listbox
    variant="minimal"
    ghost
    value={value}
    onValueChange={(newValue) => onChange(newValue as RelativeTimeframe)}
  >
    <Listbox.Field>
      <Listbox.Label>Timeframe</Listbox.Label>
      <Listbox.Trigger className="h-auto py-2 text-xs font-medium hover:bg-grey-50 active:shadow-inset [&>span]:pr-1" />
    </Listbox.Field>
    <Listbox.Menu align="end">
      {Object.values(RelativeTimeframe).map((timeframe) => (
        <Listbox.Item key={timeframe} value={timeframe}>
          {variants(timeframe, {
            [RelativeTimeframe.LAST_7_DAYS]: "Last 7 days",
            [RelativeTimeframe.LAST_30_DAYS]: "Last 30 days",
            [RelativeTimeframe.THIS_QUARTER]: "This quarter",
            [RelativeTimeframe.YEAR_TO_DATE]: "Year-to-date",
            [RelativeTimeframe.LAST_12_MONTHS]: "Last 12 months",
            [RelativeTimeframe.LAST_2_YEARS]: "Last 2 years",
            [RelativeTimeframe.LAST_5_YEARS]: "Last 5 years",
          })}
        </Listbox.Item>
      ))}
    </Listbox.Menu>
  </Listbox>
);

type RelativeTimeframeSelectItemProps = {
  value: RelativeTimeframe;
};

const RelativeTimeframeSelectItem: FC<RelativeTimeframeSelectItemProps> = ({ value }) => {
  return (
    <Listbox.Item value={value}>
      {variants(value, {
        [RelativeTimeframe.LAST_7_DAYS]: "Last 7 days",
        [RelativeTimeframe.LAST_30_DAYS]: "Last 30 days",
        [RelativeTimeframe.THIS_QUARTER]: "This quarter",
        [RelativeTimeframe.YEAR_TO_DATE]: "Year-to-date",
        [RelativeTimeframe.LAST_12_MONTHS]: "Last 12 months",
        [RelativeTimeframe.LAST_2_YEARS]: "Last 2 years",
        [RelativeTimeframe.LAST_5_YEARS]: "Last 5 years",
      })}
    </Listbox.Item>
  );
};

type Props = PropsWithChildrenAndClassName;

const ChartTools: FC<Props> = ({ children, className }) => (
  <div className={cn("flex items-center justify-end", className)}>{children}</div>
);

export default Object.assign(ChartTools, {
  RelativeTimeframeSelect: RelativeTimeframeSelect,
  RelativeTimeframeSelectItem: RelativeTimeframeSelectItem,
});
