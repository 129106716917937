import { Coins } from "@phosphor-icons/react";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import MultiStep from "layouts/MultiStep";
import useNonTerminatedCapitalAccounts from "modules/capital-accounts/hooks/useNonTerminatedCapitalAccounts";
import useMostRecentCreditApplication from "modules/credit-application/queries/useMostRecentCreditApplication";
import { getIsInReviewCreditApplication } from "modules/credit-application/utils/credit-application-type-guards";
import ConnectBanksAndCardsView from "pages/capital/CreditApplicationPage/views/ConnectBankAndCardsView";
import { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DotsPageLoader from "ui/feedback/DotsLoader/DotsPageLoader";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import useIsAllowedToAcceptLineOfCredit from "utils/permissions/useIsAllowedToAcceptLineOfCredit";

import CreditApplicationSteps from "./components/CreditApplicationSteps";
import BusinessInfoView from "./views/BusinessInfoView";
import ConnectStoresView from "./views/ConnectStoresView";
import InventoryAndFulfillmentView from "./views/InventoryAndFulfillmentView";
import UploadFinancialDocumentsView from "./views/UploadFinancialDocumentsView";

const CreditApplicationPage = () => {
  // TODO(alex): CAPITAL_AUDIT -- preserving existing functionality, but we probably want to allow people to apply for credit even if they have a capital account now, right?
  const nonTerminatedCapitalAccounts = useNonTerminatedCapitalAccounts();
  const hasNonTerminatedCapitalAccounts = nonTerminatedCapitalAccounts.length > 0;
  const isAllowedToAcceptLineOfCredit = useIsAllowedToAcceptLineOfCredit();
  const [showLoading, setShowLoading] = useState(false);

  const mostRecentCreditApplication = useMostRecentCreditApplication();
  const isMostRecentCreditApplicationInReview =
    mostRecentCreditApplication && getIsInReviewCreditApplication(mostRecentCreditApplication);

  if (
    !isAllowedToAcceptLineOfCredit ||
    hasNonTerminatedCapitalAccounts ||
    isMostRecentCreditApplicationInReview
  ) {
    return <Navigate to={"/capital"} />;
  }

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/capital">Capital</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Credit application</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      {showLoading ? (
        <DotsPageLoader variant="page" />
      ) : (
        <DashboardPage>
          <DashboardPage.Header>
            <DashboardPage.Header.BackCaret to="/capital" />
            <DashboardPage.Header.IconTile icon={<Coins />} />
            <DashboardPage.Header.Title>Credit application</DashboardPage.Header.Title>
          </DashboardPage.Header>

          <DashboardPage.Section>
            <MultiStep steps={<CreditApplicationSteps />}>
              <Routes>
                <Route
                  index
                  element={<Navigate to="/capital/application/connect-banks-and-cards" replace />}
                />
                <Route
                  path="*"
                  element={<Navigate to="/capital/application/connect-banks-and-cards" replace />}
                />
                <Route path="/connect-stores" element={<ConnectStoresView />} />
                <Route path="/connect-banks-and-cards" element={<ConnectBanksAndCardsView />} />
                <Route
                  path="/upload-financial-documents"
                  element={<UploadFinancialDocumentsView />}
                />
                <Route path="/inventory" element={<InventoryAndFulfillmentView />} />
                <Route
                  path="/business-info"
                  element={<BusinessInfoView setShowLoading={setShowLoading} />}
                />
              </Routes>
            </MultiStep>
          </DashboardPage.Section>
        </DashboardPage>
      )}
    </>
  );
};

export default CreditApplicationPage;
