import { actionItemsQueryHooks } from "modules/action-items/queries/useActionItems";
import { useBankAccounts } from "modules/bank-accounts/queries/useBankAccounts";
import { useIsBusinessActive } from "modules/business/queries/useBusiness";
import { useHasAtLeastOneUnitTransaction } from "modules/unit-co-transactions/queries/useUnitCoTransactionsQueryOptions";
import CashDisplay from "ui/data-display/money/CashDisplay";
import SectionV2 from "ui/data-display/SectionV2";
import { Heading3, Paragraph } from "ui/typography";
import useIsAllowedToConnectBankAccounts from "utils/permissions/useIsAllowedToConnectBankAccounts";
import useIsAllowedToConnectStores from "utils/permissions/useIsAllowedToConnectStores";

import AccountsOverviewBalanceChartEmpty from "../AccountsOverviewBalanceChartEmpty";

import FundedAccount from "./FundedAccount";
import FundingAccount from "./FundingAccount";
import FundYourAccount from "./FundYourAccount";

const BalanceGraph = () => {
  const hasFinishedFundYourAccountActionItem = actionItemsQueryHooks.useData({
    params: { milestone: "FundYourAccount" },
    select: (data) =>
      data.some((item) => item.name === "FundYourAccount" && item.state === "Complete"),
  });
  const openBankAccounts = useBankAccounts({ filters: { status: "open" } });

  // Query Unit to see if the business has at least one transaction.
  // If they do, we can assume they've been funded, regardless of
  // whether they're current total balance is 0 or not.
  const hasAtLeastOneUnitCoTransaction = useHasAtLeastOneUnitTransaction();

  const accountsHaveBeenFunded = openBankAccounts.length > 0 && hasAtLeastOneUnitCoTransaction;
  const isAllowedToConnectBankAccounts = useIsAllowedToConnectBankAccounts();
  const showFundYourAccount =
    isAllowedToConnectBankAccounts &&
    !hasFinishedFundYourAccountActionItem &&
    !accountsHaveBeenFunded;

  const isAllowedToConnectStores = useIsAllowedToConnectStores();
  const showPayoutInstruction =
    isAllowedToConnectStores && !showFundYourAccount && !accountsHaveBeenFunded;

  return (
    <>
      {showFundYourAccount && <FundYourAccount />}
      {showPayoutInstruction && <FundingAccount />}
      {!showFundYourAccount && !showPayoutInstruction && <FundedAccount />}
    </>
  );
};

const AccountsOverviewBalanceSection = () => {
  const isBusinessActive = useIsBusinessActive();

  return (
    <SectionV2 variant="dashboard-page">
      <SectionV2.Body>
        {isBusinessActive ? (
          <BalanceGraph />
        ) : (
          // NB(alex): Will be deleted soon.
          <div className="flex flex-col tablet:flex-row tablet:gap-x-6">
            <div className="flex w-full max-w-72 flex-col gap-y-2 py-6">
              <Heading3 className="text-md font-bold text-grey-900">Total cash balance</Heading3>
              <CashDisplay cents={0} withCents />
              <Paragraph className="max-w-64 text-sm text-grey-600">
                Connect your bank accounts or verify your business to get started.
              </Paragraph>
            </div>

            <AccountsOverviewBalanceChartEmpty />
          </div>
        )}
      </SectionV2.Body>
    </SectionV2>
  );
};

export default AccountsOverviewBalanceSection;
