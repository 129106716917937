import { FEATURE_FLAGS } from "flags";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import { PLAID_ACCOUNT_PARAM_KEY } from "modules/insights/components/EnrichedTransactionsSection";
import PlaidAccountsTable from "modules/plaid/components/PlaidAccountsTable";
import PlaidAccountsTableEmpty from "modules/plaid/components/PlaidAccountsTableEmpty";
import { SETTINGS_PLAID_LINK_MODAL_KEY } from "modules/plaid/components/PlaidConnectionsList";
import RefreshPlaidBalancesButton from "modules/plaid/components/RefreshPlaidBalancesButton";
import { plaidAccountsQueryHooks } from "modules/plaid/queries/usePlaidAccounts";
import { ACCOUNTS_TAB_PARAM_KEY } from "pages/accounts/AccountsTransactionsPage/AccountsTransactionsPageTabs";
import { useNavigate } from "react-router-dom";
import SectionV2 from "ui/data-display/SectionV2";
import ButtonLink from "ui/inputs/ButtonLink";

const PlaidBankAccountsSection = () => {
  const { data: plaidBankAccounts, error: plaidBankAccountsError } =
    plaidAccountsQueryHooks.useSuspenseQuery({
      params: {},
      select: (accounts) => accounts.filter((account) => account.accountType === "DEPOSITORY"),
    });

  const navigate = useNavigate();

  const enrichedTransactionsEnabled = useFeatureFlag(FEATURE_FLAGS["enriched-transactions"]);

  return (
    <SectionV2 variant="dashboard-page">
      <SectionV2.Header
        subheadingActions={plaidBankAccounts.length > 0 && <RefreshPlaidBalancesButton />}
      >
        <SectionV2.Heading>Connected bank accounts</SectionV2.Heading>
        <SectionV2.Subheading>Balances are refreshed daily.</SectionV2.Subheading>
      </SectionV2.Header>

      <SectionV2.Body>
        {plaidBankAccounts.length > 0 ? (
          <PlaidAccountsTable
            plaidAccounts={plaidBankAccounts}
            type="bank"
            onRowClick={(account) => {
              if (enrichedTransactionsEnabled) {
                navigate(
                  `/accounts/transactions?${PLAID_ACCOUNT_PARAM_KEY}=${account.plaidAccountId}&${ACCOUNTS_TAB_PARAM_KEY}=connected-accounts`
                );
              } else {
                navigate(`/settings/banks-and-cards`);
              }
            }}
          />
        ) : plaidBankAccountsError instanceof Error ? (
          <SectionV2.ErrorFallback error={plaidBankAccountsError} />
        ) : (
          <PlaidAccountsTableEmpty
            body={<PlaidAccountsTableEmpty.AvatarGroup variant="banks" />}
            footer={
              // TODO(alex): HB-7034 Open the plaid modal here instead of navigating to the settings page.
              <ButtonLink to={`/settings/banks-and-cards?${SETTINGS_PLAID_LINK_MODAL_KEY}=true`}>
                <PlaidAccountsTableEmpty.ButtonContent />
              </ButtonLink>
            }
          />
        )}
      </SectionV2.Body>
    </SectionV2>
  );
};

export default PlaidBankAccountsSection;
