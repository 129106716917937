import { Info } from "@phosphor-icons/react";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import MultiStep from "layouts/MultiStep";
import useCreditApplicationDocumentsQueryOptions from "modules/credit-application/queries/useCreditApplicationDocumentsQueryOptions";
import useCreditApplicationQueryOptions from "modules/credit-application/queries/useCreditApplicationQueryOptions";
import { useState } from "react";
import CreditApplicationDocumentRep from "reps/CreditApplicationDocumentRep";
import CreditApplicationRep from "reps/CreditApplicationRep";
import Banner from "ui/data-display/Banner";
import CheckboxLabel from "ui/inputs/CheckboxLabel";
import CurrencyInput from "ui/inputs/CurrencyInput";
import Dropdown, { Option } from "ui/inputs/Dropdown";
import DropdownV2, { convertStringValueToDropdownOption } from "ui/inputs/DropdownV2";
import RadioWithLabel from "ui/inputs/RadioWithLabel";
import TextArea from "ui/inputs/TextArea";
import TextInput, { Filter } from "ui/inputs/TextInput";
import IconWithTooltip from "ui/overlay/IconWithTooltip";
import { Heading4, Paragraph, Span } from "ui/typography";
import sleep from "utils/async/sleep";
import { getCentsFromDollars } from "utils/money";
import getObjectKeys from "utils/ts/getObjectKeys";

import CreditApplicationBackButton from "../components/CreditApplicationBackButton";
import CreditApplicationDocumentExampleButton from "../components/CreditApplicationDocumentExampleButton";
import CreditApplicationLegalFootnote from "../components/CreditApplicationLegalFootnote";
import UploadDocumentsSection from "../components/UploadDocumentsSection";
import useSubmitCreditApplication from "../hooks/useSubmitCreditApplication";

import { getCountByDocumentType } from "./UploadFinancialDocumentsView";

const classes = {
  section: "flex flex-col gap-y-2 mb-4",
};

const BUSINESS_INFO_FORM_ID = "business-info-form";

type ReasonForRequest = "inventory" | "marketing" | "other";
// A mapping from labels to Duration values for repaymentTermsPreference
const repaymentTermMapping = {
  "30 days": dayjs.duration({ days: 30 }).asDays(),
  "60 days": dayjs.duration({ days: 60 }).asDays(),
  "90 days": dayjs.duration({ days: 90 }).asDays(),
  "120 days": dayjs.duration({ days: 120 }).asDays(),
  "Not sure": null,
} as const;

type RepaymentTermsPreference = keyof typeof repaymentTermMapping;

type Props = { setShowLoading: (val: boolean) => void };

const BusinessInfoView: React.FC<Props> = ({ setShowLoading }) => {
  const { mutateAsync: submitCreditApplication, isPending: isSubmitCreditApplicationLoading } =
    useSubmitCreditApplication();
  const { data: creditApplicationData } = useSuspenseQuery(useCreditApplicationQueryOptions());
  const { data: creditApplicationDocumentsData } = useQuery(
    useCreditApplicationDocumentsQueryOptions()
  );

  const [requestedAmountDollars, setRequestedAmountDollars] = useState<string>("");
  const [reasonForRequest, setReasonForRequest] = useState<ReasonForRequest>();
  const [otherReasonForRequest, setOtherReasonForRequest] = useState<string>("");
  const [additionalInformation, setAdditionalInformation] = useState<string>("");
  const [hasSecuredLenders, setHasSecuredLenders] = useState(false);
  const [securedLendersExplanation, setSecuredLendersExplanation] = useState("");
  const [subsidiaries, setSubsidiaries] = useState("");
  const [isSingleEntityBusiness, setIsSingleEntityBusiness] = useState(false);
  const [isRetailPresence, setIsRetailPresence] = useState(false);
  const [retailLocations, setRetailLocations] = useState("");
  const [industry, setIndustry] = useState<Option>();
  const [repaymentTermsPreference, setRepaymentTermsPreference] =
    useState<RepaymentTermsPreference | null>();
  const [otherIndustry, setOhterIndustry] = useState<string>();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    // Show the loading page
    setShowLoading(true);
    await sleep(2000);

    await submitCreditApplication({
      userProvidedDetails: {
        ...creditApplicationData?.userProvidedDetails,
        userNotes: additionalInformation,
        requestedAmount: getCentsFromDollars(requestedAmountDollars),
        reasonForRequest: Boolean(otherReasonForRequest) ? otherReasonForRequest : reasonForRequest,
        numberOfRetailLocations: Number(retailLocations),
        industry: industry?.value as CreditApplicationRep.CreditApplicationIndustry,
        otherIndustry,
        securedLenders: hasSecuredLenders,
        securedLendersExplanation,
        orgStructure: subsidiaries,
        singleEntityBusiness: isSingleEntityBusiness,
        ...(repaymentTermsPreference
          ? { repaymentTermsPreference: repaymentTermMapping[repaymentTermsPreference] }
          : {}),
      },
    });

    setShowLoading(false);
  };

  const orgStructureDocsCount = getCountByDocumentType(
    creditApplicationDocumentsData || [],
    CreditApplicationDocumentRep.DocumentType.OrgStructure
  );

  const paymentTermOptions = getObjectKeys(repaymentTermMapping).map(
    convertStringValueToDropdownOption
  );

  const isSubmitDisabled =
    !requestedAmountDollars ||
    !reasonForRequest ||
    !industry ||
    (industry.value === "Other" && !otherIndustry) ||
    (isSingleEntityBusiness ? false : !subsidiaries && orgStructureDocsCount === 0) ||
    (hasSecuredLenders && !securedLendersExplanation);

  return (
    <MultiStep.Form id={BUSINESS_INFO_FORM_ID} onSubmit={handleSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>Business information</MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>

        <div className={classes.section}>
          <Heading4 className="font-medium">What industry is your business in?</Heading4>
          <Dropdown
            placeholder="Select industry"
            value={industry ?? null}
            onChange={setIndustry}
            options={Object.entries(CreditApplicationRep.CreditApplicationIndustry).map(
              ([key, value]) => ({
                value: key,
                label: value,
              })
            )}
          />
          {industry?.value === "Other" && (
            <TextInput
              value={otherIndustry}
              placeholder="Please specify your industry"
              onChange={(val) => setOhterIndustry(val)}
            />
          )}
        </div>

        <MultiStep.Section.Spacer />

        <div className={classes.section}>
          <Heading4 className="font-medium">How much are you looking to borrow?</Heading4>

          <CurrencyInput
            prefixValue="$"
            value={requestedAmountDollars}
            onChange={setRequestedAmountDollars}
            allowDecimals={false}
          />
        </div>

        <MultiStep.Section.Spacer />

        <div className={classes.section}>
          <Heading4 className="font-medium">What do you need capital for?</Heading4>

          <div className="my-4 flex flex-col gap-y-4">
            <RadioWithLabel
              label="Inventory"
              checked={reasonForRequest === "inventory"}
              onChange={() => {
                setOtherReasonForRequest("");
                setReasonForRequest("inventory");
              }}
            />
            <RadioWithLabel
              label="Marketing"
              checked={reasonForRequest === "marketing"}
              onChange={() => {
                setOtherReasonForRequest("");
                setReasonForRequest("marketing");
              }}
            />
            <RadioWithLabel
              label="Other"
              checked={reasonForRequest === "other"}
              onChange={() => setReasonForRequest("other")}
            />
          </div>

          {reasonForRequest === "other" && (
            <TextInput
              value={otherReasonForRequest}
              placeholder="Please explain"
              onChange={(val) => {
                setOtherReasonForRequest(val);
              }}
            />
          )}
        </div>
      </MultiStep.Section>

      <div className={classes.section}>
        <Heading4 className="font-medium">What payment terms would you like?</Heading4>
        <DropdownV2
          placeholder="Select payment terms"
          value={
            repaymentTermsPreference
              ? convertStringValueToDropdownOption(repaymentTermsPreference)
              : null
          }
          onChange={(val) => setRepaymentTermsPreference(val ? val.value : null)}
          options={paymentTermOptions}
        />
      </div>

      <MultiStep.Section.Spacer />

      <div className={classes.section}>
        <Heading4 className="font-medium">Affiliated entities</Heading4>
        <div className="flex items-center justify-between pb-4">
          <Paragraph className="text-sm text-grey-600">
            Please list any parent or sibling companies, as well as all subsidiaries, and/or upload
            a company organizational chart that shows it.
          </Paragraph>
          <CreditApplicationDocumentExampleButton
            documentType={CreditApplicationDocumentRep.DocumentType.OrgStructure}
          />
        </div>

        <div className={classes.section}>
          <TextInput
            value={subsidiaries}
            placeholder="List companies here"
            onChange={setSubsidiaries}
          />

          <Span className="self-center text-md text-grey-500">or</Span>

          <UploadDocumentsSection type={CreditApplicationDocumentRep.DocumentType.OrgStructure} />
          <CheckboxLabel
            label="My company does not have any parent companies or subsidiaries"
            checked={isSingleEntityBusiness}
            textWeight="regular"
            onChange={() => setIsSingleEntityBusiness(!isSingleEntityBusiness)}
          />
        </div>
      </div>

      <MultiStep.Section.Spacer />

      <div className={classes.section}>
        <Heading4 className="font-medium">Do you have a retail presence?</Heading4>

        <div className="my-4 flex gap-x-8">
          <RadioWithLabel
            label="No"
            checked={!isRetailPresence}
            onChange={() => {
              setIsRetailPresence(!isRetailPresence);
              setRetailLocations("");
            }}
          />
          <RadioWithLabel
            label="Yes"
            checked={isRetailPresence}
            onChange={() => setIsRetailPresence(!isRetailPresence)}
          />
        </div>

        {isRetailPresence && (
          <TextInput
            value={retailLocations}
            placeholder="Number of store locations"
            inputFilter={Filter.DIGITS}
            onChange={(val) => {
              setRetailLocations(val);
              setIsRetailPresence(Boolean(val));
            }}
          />
        )}
      </div>

      <MultiStep.Section.Spacer />

      <div className={classes.section}>
        <Heading4 className="flex items-center gap-x-2 font-medium">
          Do you have any secured lenders or personal guarantees?
          <IconWithTooltip
            color="primary"
            tooltip="Secured lenders are lenders that hold collateral on business or personal assets."
          />
        </Heading4>

        <div className="my-4 flex gap-x-8">
          <RadioWithLabel
            label="No"
            checked={!hasSecuredLenders}
            onChange={() => {
              setHasSecuredLenders(!hasSecuredLenders);
            }}
          />
          <RadioWithLabel
            label="Yes"
            checked={hasSecuredLenders}
            onChange={() => {
              setHasSecuredLenders(!hasSecuredLenders);
            }}
          />
        </div>

        {hasSecuredLenders && (
          <TextArea
            value={securedLendersExplanation}
            placeholder="Please specify which of your lenders are secured by collateral or personal guarantees"
            onChange={(val) => {
              setSecuredLendersExplanation(val);
              setHasSecuredLenders(Boolean(val));
            }}
          >
            <TextArea.Input />
          </TextArea>
        )}
      </div>
      <MultiStep.Section.Spacer />

      <UploadDocumentsSection
        heading="(Optional) Additional information"
        subheading="Please add any additional information here that you think might be helpful."
        type={CreditApplicationDocumentRep.DocumentType.Other}
      >
        <MultiStep.Section.Spacer />

        <TextArea value={additionalInformation} onChange={setAdditionalInformation}>
          <TextArea.Label>(Optional) Is there anything else we should know?</TextArea.Label>
          <TextArea.Input />
        </TextArea>
      </UploadDocumentsSection>

      <Banner
        color="blue"
        icon={<Info />}
        paragraph="Submitting your capital application will not affect your credit score."
      />

      <MultiStep.Controls>
        <CreditApplicationBackButton />

        <MultiStep.Controls.NextButton
          form={BUSINESS_INFO_FORM_ID}
          disabled={isSubmitDisabled}
          isLoading={isSubmitCreditApplicationLoading}
        >
          Submit application
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>

      <CreditApplicationLegalFootnote />
    </MultiStep.Form>
  );
};

export default BusinessInfoView;
