import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";
import { createURLSearchParamsFromObject } from "utils/search-params/create-url-search-params";

import PayeeClassCodeRep, { PayeeClassCodeCreatorRep } from "../reps/PayeeClassCodeRep";

type PayeeClassCodeApi = {
  getByPayeeGuid: (payeeGuid: string) => Promise<PayeeClassCodeRep | null>;
  upsert: (body: PayeeClassCodeCreatorRep) => Promise<PayeeClassCodeRep>;
  delete: (payeeClassCodeId: string) => Promise<void>;
};

const builder: ApiBuilder<PayeeClassCodeApi> = (api) => ({
  getByPayeeGuid: async (payeeGuid) => {
    const queryParams = createURLSearchParamsFromObject({ payeeGuid });
    const url = `/accounting/payee-class-codes?${queryParams}`;
    return await api.get<PayeeClassCodeRep>(url);
  },
  upsert: async (body) => {
    const url = "/accounting/payee-class-codes";
    return (await api.post<PayeeClassCodeRep>(url, body))!;
  },
  delete: async (payeeClassCodeId) => {
    const url = `/accounting/payee-class-codes/${payeeClassCodeId}`;
    await api.delete(url);
  },
});

const usePayeeClassCodeApi = () => useBackendV2Api(builder);

export default usePayeeClassCodeApi;
