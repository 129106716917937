import * as echarts from "echarts";
import colors from "styles/colors";
import { hexWithOpacityPercent } from "utils/opacity-hex/opacity-to-hex";
import variants from "utils/ts/variants";

export const CHART_COLORS = [
  "purple-500" as const,
  "green-400" as const,
  "orange-500" as const,
  "red-500" as const,
  "yellow-500" as const,
  "pink-500" as const,
];

export type ChartColor = (typeof CHART_COLORS)[number] | "grey-800";

export const getChartColorByIndex = (index: number): ChartColor => {
  return CHART_COLORS[index % CHART_COLORS.length];
};

export const getChartBackgroundColorClass = (color: ChartColor): string => {
  return variants(color, {
    "purple-500": "bg-purple-500",
    "green-400": "bg-green-400",
    "orange-500": "bg-orange-500",
    "red-500": "bg-red-500",
    "yellow-500": "bg-yellow-500",
    "pink-500": "bg-pink-500",
    "grey-800": "bg-grey-800",
  });
};

export const getChartTextColorClass = (color: ChartColor): string => {
  return variants(color, {
    "purple-500": "text-purple-500",
    "green-400": "text-green-400",
    "orange-500": "text-orange-500",
    "red-500": "text-red-500",
    "yellow-500": "text-yellow-500",
    "pink-500": "text-pink-500",
    "grey-800": "text-grey-800",
  });
};

export const getChartColorHex = (color: ChartColor): string => {
  return variants(color, {
    "purple-500": colors.purple[500],
    "green-400": colors.green[400],
    "orange-500": colors.orange[500],
    "red-500": colors.red[500],
    "yellow-500": colors.yellow[500],
    "pink-500": colors.pink[500],
    "grey-800": colors.grey[800],
  });
};

type GradientColor = "purple-500" | "green-400";

export const getChartGradientByColor = (color: GradientColor): echarts.graphic.LinearGradient => {
  return variants(color, {
    "purple-500": new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0.05,
        color: colors.purple[500],
      },

      {
        offset: 0.5,
        color: colors.purple[300],
      },
      {
        offset: 1,
        color: hexWithOpacityPercent(colors.purple[300], 2),
      },
    ]),
    "green-400": new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0.05,
        color: colors.green[400],
      },

      {
        offset: 0.5,
        color: colors.green[300],
      },
      {
        offset: 1,
        color: hexWithOpacityPercent(colors.green[300], 2),
      },
    ]),
  });
};
