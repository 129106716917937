import { Dayjs } from "dayjs";
import { ApiBuilder, useBackendV1Api } from "modules/highbeam-api/base";
import CardRep from "reps/CardRep";
import { startOfBankingDay } from "utils/date";
import convertArrayToQueryString from "utils/search-params/convertArrayToQueryString";

type CardApi = {
  create: (creation: CardRep.Creator) => Promise<CardRep.Complete>;
  update: (
    businessGuid: string,
    cardId: string,
    debitCard: CardRep.Update
  ) => Promise<CardRep.Complete>;
  getAll: (params: CardRep.ListParams) => Promise<CardRep.UnitCoCards>;
  transactionsCsv: (
    businessGuid: string,
    cardId: string,
    query?: string,
    from?: Dayjs,
    to?: Dayjs
  ) => Promise<string>;
  allTransactionsCsv: (
    businessGuid: string,
    query?: string,
    from?: Dayjs,
    to?: Dayjs
  ) => Promise<string>;
  close: (cardId: string) => Promise<void>;
};

const builder: ApiBuilder<CardApi> = (api) => ({
  create: async (creation) => {
    const url = `/debit-cards`;
    return (await api.post<CardRep.Complete>(url, creation))!;
  },

  update: async (businessGuid, cardId, debitCard) => {
    const url = `/businesses/${businessGuid}/debit-cards/${cardId}`;
    return (await api.patch<CardRep.Complete>(url, debitCard))!;
  },

  getAll: async ({ status, ...params }) => {
    const qp = new URLSearchParams(params);
    const statusQp = status ? "&" + convertArrayToQueryString("status", status) : "";
    const url = `/cards?${qp}${statusQp}`;
    return (await api.get<CardRep.UnitCoCards>(url))!;
  },

  transactionsCsv: async (businessGuid, cardId, query, from, to) => {
    const queryParams = new URLSearchParams({ businessGuid });

    if (query && query.length > 0) queryParams.set("query", query);
    if (from) queryParams.set("from", startOfBankingDay(from).format());
    if (to) queryParams.set("to", startOfBankingDay(to).add(1, "day").format());

    const url = `/cards/${cardId}/transactions?${queryParams}`;
    return (await api.get<string>(url, { Accept: "text/csv" }))!;
  },

  allTransactionsCsv: async (businessGuid, query, from, to) => {
    const queryParams = new URLSearchParams({ businessGuid });

    if (query && query.length > 0) queryParams.set("query", query);
    if (from) queryParams.set("from", startOfBankingDay(from).format());
    if (to) queryParams.set("to", startOfBankingDay(to).add(1, "day").format());

    const url = `/cards/transactions?${queryParams}`;
    return (await api.get<string>(url, { Accept: "text/csv" }))!;
  },

  close: async (cardId) => {
    const url = `/cards/${cardId}/close`;
    return (await api.post<void>(url))!;
  },
});

const useCardApi = () => useBackendV1Api(builder);

export default useCardApi;
