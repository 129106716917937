import ConnectStores from "components/ConnectStores";
import { connectedStoresQueryHooks } from "modules/connected-stores/queries/useConnectedStores";
import { FC } from "react";
import ButtonLinkWithTooltip from "ui/inputs/ButtonLink/ButtonLinkWithTooltip";
import SkipButtonLink from "ui/navigation/SkipButtonLink";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";

import { CREDIT_COMPARISON_SIGNUP_SOURCE } from "../../constants";
import { useSignupSource } from "../../queries/leadQueryHooks";
import OnboardingCard from "../OnboardingCard";

type Props = {
  className?: string;
  allowSkip: boolean;
  to: string;
};

const OnboardingConnectStoresCard: FC<Props> = ({ className, allowSkip, to }) => {
  const { segmentTrack } = useSegment();

  const signupSource = useSignupSource();
  const isSignupSourceCreditComparison = signupSource === CREDIT_COMPARISON_SIGNUP_SOURCE;
  const { data: connectedStores = [] } = connectedStoresQueryHooks.useQuery({ params: {} });

  const hasConnectedShopifyOrAmazon =
    connectedStores.filter(
      (integration) => integration.type === "Shopify" || integration.type === "Amazon"
    ).length > 0;

  return (
    <OnboardingCard className={className}>
      <OnboardingCard.Header>
        <OnboardingCard.Heading1>Connect your stores</OnboardingCard.Heading1>
        <OnboardingCard.Subheading>
          Highbeam uses data from your stores to provide tailored banking services and real-time
          insights to manage cash flow.
        </OnboardingCard.Subheading>
      </OnboardingCard.Header>

      <OnboardingCard.Body>
        <ConnectStores redirectPathKey="onboarding" />
      </OnboardingCard.Body>

      <OnboardingCard.Footer>
        {allowSkip && (
          <SkipButtonLink
            to={to}
            disabled={hasConnectedShopifyOrAmazon}
            type="submit"
            onClick={() => {
              segmentTrack(SEGMENT_EVENTS.ONBOARDING_STORES_SKIPPED);
            }}
          >
            Skip this step
          </SkipButtonLink>
        )}

        <ButtonLinkWithTooltip
          to={to}
          variant="primary"
          disabled={!hasConnectedShopifyOrAmazon}
          type="submit"
          tooltip={
            !hasConnectedShopifyOrAmazon &&
            !isSignupSourceCreditComparison &&
            "Please connect at least 1 store to continue."
          }
        >
          {isSignupSourceCreditComparison ? "Compare offers" : "Next"}
        </ButtonLinkWithTooltip>
      </OnboardingCard.Footer>
    </OnboardingCard>
  );
};

export default OnboardingConnectStoresCard;
