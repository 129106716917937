import AccountingCategorySelect from "modules/accounting-accounts/components/AccountingCategorySelect";
import AccountingClassCodeSelect from "modules/accounting-class-codes/components/AccountingClassCodeSelect";
import DefaultPayeeClassCodeDescription from "modules/accounting-class-codes/components/DefaultPayeeClassCodeDescription";
import { useAccountingClassCodes } from "modules/accounting-class-codes/queries/accountingClassCodesQueryHooks";
import { useApSettings } from "modules/ap-settings/queries/apSettingsQueryHooks";
import PayeeDefaultChartOfAccountDescription from "modules/payees/components/PayeeDefaultChartOfAccountDescription";
import { useContext } from "react";
import Fieldset from "ui/inputs/Fieldset";
import TextInput from "ui/inputs/TextInput";

import { CreatePayeeFormContext } from "./CreatePayeeForm";
import style from "./CreatePayeePage.module.scss";

const PayeeInfoSection = () => {
  const form = useContext(CreatePayeeFormContext);
  const { defaultChartOfAccountId, classCodeId } = form.payee;
  const apSettings = useApSettings();
  const accountingClassCodes = useAccountingClassCodes();
  const hasAccountingClassCodes = accountingClassCodes.length > 0;

  return (
    <Fieldset width="narrow">
      <TextInput label="Name" className={style.span2} maxLength={60} {...form.payee.name} />
      <TextInput label="Email (optional)" className={style.span2} {...form.payee.emailAddress} />
      <TextInput
        label="Phone number (optional)"
        className={style.span2}
        {...form.payee.phoneNumber}
      />

      {hasAccountingClassCodes && (
        <div className="col-span-2 mt-2 flex flex-col gap-2">
          <AccountingClassCodeSelect
            clearable
            value={classCodeId.value}
            onValueChange={classCodeId.setValue}
            labelText="Accounting class (optional)"
          />
          <DefaultPayeeClassCodeDescription />
        </div>
      )}
      {apSettings && (
        <div className="col-span-2 mt-2 flex flex-col gap-2">
          <AccountingCategorySelect
            value={defaultChartOfAccountId.value}
            onValueChange={defaultChartOfAccountId.setValue}
            labelText="Accounting category (optional)"
          />
          <PayeeDefaultChartOfAccountDescription />
        </div>
      )}
    </Fieldset>
  );
};

export default PayeeInfoSection;
