import { LineChartChatElementRep } from "modules/chat/reps/ChatElementRep";
import { FC } from "react";
import { LineChart } from "ui/data-visualization/chart";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

type Props = {
  className?: string;
  element: LineChartChatElementRep;
};

const LineChartChatElement: FC<Props> = ({ className, element }) => {
  return (
    <LineChart
      className={cn("mb-4 mt-2", className)}
      xAxisData={element.xAxis}
      xAxisType="YYYY-MM-DD"
      yAxisType={variants(element.yAxisType, {
        Money: "money" as const,
        Number: "number" as const,
      })}
      series={element.series.map((serie) => ({
        name: serie.name,
        data: serie.values,
        showSymbol: false,
      }))}
    />
  );
};

export default LineChartChatElement;
