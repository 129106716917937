import { FC } from "react";
import { type LogoSquareProps, BankLogoGeneric } from "ui/icons/logos/logos-square";
import { getPlaidBankAccountInstitutionLogo } from "utils/account";

type Props = LogoSquareProps & {
  institutionId: string | null;
};

const PlaidInstitutionLogo: FC<Props> = ({ institutionId, ...props }) => {
  const LogoComponent = getPlaidBankAccountInstitutionLogo(institutionId) || BankLogoGeneric;
  return <LogoComponent {...props} />;
};

export default PlaidInstitutionLogo;
