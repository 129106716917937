import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useQueryOptions from "utils/react-query/useQueryOptions";

import useBusinessAddressApi from "../api/useBusinessAddressApi";

const useBusinessAddressQueryOptions = () => {
  const businessAddressApi = useBusinessAddressApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: ["businessAddress", { businessGuid }],
    queryFn: () => businessAddressApi.get(businessGuid),
  });
};

export default useBusinessAddressQueryOptions;
