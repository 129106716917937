import BankAccountsTable from "modules/bank-accounts/components/BankAccountsTable";
import BankAccountsUpsellTable from "modules/bank-accounts/components/BankAccountsUpsellTable";
import { useBankAccounts } from "modules/bank-accounts/queries/useBankAccounts";
import { useIsBusinessActive } from "modules/business/queries/useBusiness";
import VerifyYourBusinessModal, {
  useVerifyYourBusinessModal,
} from "modules/onboarding/dialogs/VerifyYourBusinessModal";
import SectionV2 from "ui/data-display/SectionV2";
import ButtonLinkWithTooltip from "ui/inputs/ButtonLink/ButtonLinkWithTooltip";
import useIsAllowedToCreateBankAccounts from "utils/permissions/useIsAllowedToCreateBankAccounts";

const ManageAccountsButton = () => {
  const isAllowedToCreateBankAccounts = useIsAllowedToCreateBankAccounts();

  return (
    <ButtonLinkWithTooltip
      to="/accounts/manage-accounts"
      tooltip={
        !isAllowedToCreateBankAccounts && "You don’t have permission to create bank accounts."
      }
      variant="tertiary"
      disabled={!isAllowedToCreateBankAccounts}
    >
      Manage accounts
    </ButtonLinkWithTooltip>
  );
};

const BankAccountsSection = () => {
  // NB(alex): Hack until we have empty states. Matches the query in `BankAccountsTable`.
  const openBankAccounts = useBankAccounts({ filters: { status: "open" } });
  const isBusinessActive = useIsBusinessActive();
  const verifyYourBusinessModal = useVerifyYourBusinessModal();

  if (isBusinessActive && openBankAccounts.length === 0) {
    return null;
  }

  return (
    <SectionV2 variant="dashboard-page">
      {verifyYourBusinessModal.isOpen && (
        <VerifyYourBusinessModal
          onClose={verifyYourBusinessModal.close}
          {...verifyYourBusinessModal.params}
        />
      )}

      <SectionV2.Header actions={isBusinessActive && <ManageAccountsButton />}>
        <SectionV2.Heading>Highbeam accounts</SectionV2.Heading>
      </SectionV2.Header>

      <SectionV2.Body>
        {isBusinessActive ? (
          <BankAccountsTable bankAccounts={openBankAccounts} />
        ) : (
          <BankAccountsUpsellTable
            onRowClick={() => {
              verifyYourBusinessModal.open({});
            }}
          />
        )}
      </SectionV2.Body>
    </SectionV2>
  );
};

export default BankAccountsSection;
