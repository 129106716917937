import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { SetFieldType } from "type-fest";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";
import { ArrayOfUnion } from "utils/ts/ArrayOfUnion";

import useActionItemApi from "../api/useActionItemApi";
import ActionItemRep, {
  ActionItemGetStartedSetupGuideStep,
  ActionItemState,
} from "../reps/ActionItemRep";

import { ROOT_ACTION_ITEMS_QUERY_KEY } from "./useActionItems";

export type GetStartedActionItemState = ActionItemState | "Incomplete";

export type IncompleteGetStartedActionItem = {
  order: number;
  state: "Incomplete";
  name: ActionItemGetStartedSetupGuideStep;
};

export type FinishedGetStartedActionItem = SetFieldType<
  ActionItemRep.Complete,
  "name",
  ActionItemGetStartedSetupGuideStep
> & {
  order: number;
};

export type GetStartedActionItem = FinishedGetStartedActionItem | IncompleteGetStartedActionItem;

export const isFinishedGetStartedActionItem = (
  item: ActionItemRep.Complete
): item is Omit<FinishedGetStartedActionItem, "order"> => {
  const options = [
    "ConnectStore",
    "CreateVirtualCard",
    "ConnectBank",
    "ReceiveStorePayouts",
  ] satisfies ArrayOfUnion<FinishedGetStartedActionItem["name"]>;

  // @ts-expect-error
  return options.includes(item.name);
};

const DEFAULT_GET_STARTED_ACTIONS: Pick<IncompleteGetStartedActionItem, "order" | "name">[] = [
  { order: 1, name: "ConnectStore" as const },
  { order: 2, name: "CreateVirtualCard" as const },
  { order: 3, name: "ConnectBank" as const },
  { order: 4, name: "ReceiveStorePayouts" as const },
];

type Params = {
  businessGuid: string;
};

const getStartedActionItemsQueryHooks = makeQueryHooksV2({
  useQueryFnParams: () => {
    const actionItemApi = useActionItemApi();
    return { actionItemApi };
  },
  useDefaultParams: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid: businessGuid };
  },
  makeQueryKey: (params: Params) => {
    return [ROOT_ACTION_ITEMS_QUERY_KEY, "getStartedActionItems", params];
  },
  makeQueryFn: ({ actionItemApi }, { businessGuid }) => {
    return async (): Promise<GetStartedActionItem[]> => {
      const actionItems = await actionItemApi.getByMilestoneName(businessGuid, "GetStarted");

      return DEFAULT_GET_STARTED_ACTIONS.map((defaultItem) => {
        const actionItem = actionItems.find((item) => item.name === defaultItem.name);
        if (actionItem && isFinishedGetStartedActionItem(actionItem)) {
          return {
            order: defaultItem.order,
            ...actionItem,
          };
        } else {
          return {
            ...defaultItem,
            state: "Incomplete" as const,
          };
        }
      });
    };
  },
});

export { getStartedActionItemsQueryHooks };
