import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import { useMvpFirstChatChannel } from "modules/chat/queries/useChatChannels";
import { useCurrentChatUserOrThrow } from "modules/chat/queries/useChatUsers";
import { FC } from "react";
import { Navigate } from "react-router-dom";
import { useIsSuperusering } from "state/auth/isSuperusering";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

import SuperuserChannelsPicker from "./SuperuserChannelsPicker";

const AiChatChannelsPage: FC = () => {
  const currentChatUser = useCurrentChatUserOrThrow();
  const chatChannel = useMvpFirstChatChannel({ chatUserId: currentChatUser.id });

  const isSuperusering = useIsSuperusering();

  if (!isSuperusering) {
    return <Navigate replace to={`/ai/${chatChannel.id}`} />;
  }

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>AI Analyst</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <SuperuserChannelsPicker />
    </>
  );
};

export default AiChatChannelsPage;
