import { MagnifyingGlass } from "@phosphor-icons/react";
import { useCashFlowContext } from "pages/CashFlowPage/providers/CashFlowProvider";
import { FC } from "react";
import colors from "styles/colors";
import TextInputV2 from "ui/inputs/TextInputV2";
import variants from "utils/ts/variants";

const CashFlowSearchInput: FC<{ className?: string }> = ({ className }) => {
  const { search, setSearch, activeTab } = useCashFlowContext();

  return (
    <TextInputV2
      className={className}
      placeholder={`Search by ${variants(activeTab, {
        "money-in": "source",
        "money-out": "vendor",
      })} or category`}
      value={search}
      onChange={setSearch}
      endAdornment={<MagnifyingGlass size={20} color={colors.grey[900]} weight="light" />}
    />
  );
};

export default CashFlowSearchInput;
