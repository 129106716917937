import { BalanceLineChartSerieDatum } from "./BalanceLineChartSerieDatum";

export const aggregateBalanceLineChartAccountSeriesTotals = (
  accountsSeries: BalanceLineChartSerieDatum[][]
): BalanceLineChartSerieDatum[] => {
  const aggregatedData: Record<string, number> = {};

  accountsSeries.forEach((account) => {
    account.forEach(([date, amount]) => {
      if (!aggregatedData[date]) {
        aggregatedData[date] = 0;
      }
      aggregatedData[date] += amount;
    });
  });

  return Object.entries(aggregatedData)
    .map(([date, total]) => [date, total] satisfies BalanceLineChartSerieDatum)
    .sort((a, b) => a[0].localeCompare(b[0]));
};
