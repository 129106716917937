import { Dispatch, RefObject, SetStateAction } from "react";
import createComponentContext from "utils/react-helpers/createComponentContext";

type CommandPaletteContextValue = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  highlighted: string;
  setHighlighted: Dispatch<SetStateAction<string>>;
  cleanup: () => void;
  scrollRef: RefObject<HTMLDivElement>;
};

const [CommandPaletteProvider, useCommandPaletteContext] =
  createComponentContext<CommandPaletteContextValue>("CommandPalette");

export { CommandPaletteProvider, useCommandPaletteContext };
