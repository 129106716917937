import { NormalizedAccountRep } from "modules/insights/reps/NormalizedAccountRep";
import BankAccountRep from "reps/BankAccountRep";
import PlaidAccountRep from "reps/PlaidAccountRep";
import variants from "utils/ts/variants";

import { checkIsHighbeamNormalizedAccountRep } from "./normalized-account-type-guards";

// NB(alex): Extracting these fields from the respective reps so so we don't have to type-guard as much.
type BaseNormalizedAccountAugmented = NormalizedAccountRep & {
  isPrimary: boolean;
  accountName: string;
  status: "active" | "closed" | "frozen" | "disconnected";
};

export type NormalizedHighbeamAccountAugmented = BaseNormalizedAccountAugmented & {
  repType: "highbeam-account";
  rep: BankAccountRep.Complete;
};

export type NormalizedPlaidAccountAugmented = BaseNormalizedAccountAugmented & {
  repType: "plaid-account";
  rep: PlaidAccountRep.Complete;
};

export type NormalizedAccountAugmented =
  | NormalizedHighbeamAccountAugmented
  | NormalizedPlaidAccountAugmented;

type AugmentNormalizedAccountParams = {
  normalizedAccount: NormalizedAccountRep;
  bankAccounts: BankAccountRep.Complete[];
  connectedPlaidBankAccounts: PlaidAccountRep.Complete[];
};

export const augmentNormalizedAccount = ({
  normalizedAccount,
  bankAccounts,
  connectedPlaidBankAccounts,
}: AugmentNormalizedAccountParams): NormalizedAccountAugmented | null => {
  const isHighbeamAccount = checkIsHighbeamNormalizedAccountRep(normalizedAccount);
  if (isHighbeamAccount) {
    const bankAccount = bankAccounts?.find(
      (bankAccount) => bankAccount.unitCoDepositAccountId === normalizedAccount.sourceAccountId
    );
    if (bankAccount) {
      return {
        ...normalizedAccount,
        repType: "highbeam-account" as const,
        rep: bankAccount,
        isPrimary: bankAccount.isPrimary,
        accountName: bankAccount.name,
        status: variants(bankAccount.status, {
          [BankAccountRep.Status.OPEN]: "active" as const,
          [BankAccountRep.Status.CLOSED]: "closed" as const,
          [BankAccountRep.Status.FROZEN]: "frozen" as const,
        }),
      };
    }
  } else {
    const plaidBankAccount = connectedPlaidBankAccounts.find(
      (plaidBankAccount) => plaidBankAccount.plaidAccountId === normalizedAccount.sourceAccountId
    );
    if (plaidBankAccount) {
      return {
        ...normalizedAccount,
        repType: "plaid-account" as const,
        rep: plaidBankAccount,
        isPrimary: false,
        accountName: plaidBankAccount.accountName,
        status: normalizedAccount.isActive ? "active" : "disconnected",
      };
    }
  }

  return null;
};
