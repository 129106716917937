import { FC, useState } from "react";
import { notify } from "ui/feedback/Toast";
import TextArea from "ui/inputs/TextArea";
import { Paragraph } from "ui/typography";

import useCreateAiTaskMutation from "../../mutations/useCreateAiTaskMutation";

import BaseAiTaskModal, { BaseAiTaskModalProps } from "./BaseAiTaskModal";

type Props = Omit<BaseAiTaskModalProps, "variant" | "task">;

const CreateTaskModal: FC<Props> = ({
  isOpen,
  onClose,
  title,
  onConfirm,
  onCancel,
  className,
  children,
}) => {
  const [createTaskDescription, setCreateTaskDescription] = useState("");

  const createTaskMutation = useCreateAiTaskMutation({
    onSuccess: () => {
      notify("success", "Task submitted for review");
    },
  });

  const config = {
    title: title || "Create task",
    confirmButtonText: "Submit for review",
    confirmButtonVariant: "primary" as const,
    confirmButtonClassName: "",
    cancelButtonText: "Cancel",
  };

  const handleConfirm = () => {
    if (createTaskDescription.trim()) {
      createTaskMutation.mutate({
        status: "SetupInProgress",
        type: "Generic",
        description: createTaskDescription.trim(),
      });
      setCreateTaskDescription(""); // Clear description after successful creation
    }
  };

  const handleClose = () => {
    setCreateTaskDescription(""); // Clear description when modal is closed
    if (onClose) {
      onClose();
    }
  };

  return (
    <BaseAiTaskModal
      isOpen={isOpen}
      onClose={handleClose}
      title={title || config.title}
      config={config}
      onConfirm={onConfirm}
      onCancel={onCancel}
      className={className}
      isConfirmDisabled={!createTaskDescription.trim()}
      onConfirmClick={handleConfirm}
      //task={task}
    >
      <div className="mb-2">
        <Paragraph className="mb-4 text-sm text-grey-600">
          Please describe the task you&apos;d like to create (Ex. "Send me a weekly report with my
          total marketing spend").
        </Paragraph>
        <div className="mt-4">
          <TextArea
            placeholder="Describe task here ..."
            value={createTaskDescription}
            onChange={(value) => setCreateTaskDescription(value)}
          >
            <TextArea.Input />
          </TextArea>
        </div>
        <Paragraph className="mt-4 text-sm text-grey-600">
          The Highbeam team will review your task and assign it to the correct AI agent. We&apos;ll
          email you when the task is ready.
        </Paragraph>
      </div>
      {children}
    </BaseAiTaskModal>
  );
};

export default CreateTaskModal;
