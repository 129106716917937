import { useMutation } from "@tanstack/react-query";
import useBankAccountApi from "modules/bank-accounts/api/useBankAccountApi";
import { useRefreshAllBankAccountQueries } from "modules/bank-accounts/queries/useBankAccounts";
import { notify } from "ui/feedback/Toast";

type Variables = {
  accountGuid: string;
};

const useSetPrimaryAccountNameMutation = () => {
  const bankAccountApi = useBankAccountApi();

  const refreshBankAccounts = useRefreshAllBankAccountQueries();

  return useMutation({
    mutationFn: async ({ accountGuid }: Variables) =>
      await bankAccountApi.update(accountGuid, { isPrimary: true }),
    onError: () => {
      notify("error", "Something went wrong! Please try again.");
    },
    onSuccess: async () => {
      refreshBankAccounts();
    },
  });
};

export default useSetPrimaryAccountNameMutation;
