import CapitalAccountAutoPayBankAccountHighlightItem from "modules/capital-accounts/components/CapitalAccountAutoPayBankAccountHighlightItem";
import CapitalAccountInterestDueHighlightItem from "modules/capital-accounts/components/CapitalAccountInterestDueHighlightItem";
import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import Divider from "ui/data-display/Divider";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAvailableHighlightItems: FC<Props> = ({ capitalAccountSummary }) => {
  const showInterestDue = capitalAccountSummary.details.netApr > 0;

  return (
    <div className="mt-6 rounded-lg border border-grey-200 @container">
      <div className="flex flex-col @sm:flex-row">
        <CapitalAccountAutoPayBankAccountHighlightItem
          capitalAccount={capitalAccountSummary}
          className="overflow-auto whitespace-nowrap px-6 py-4"
        />
        {showInterestDue && (
          <>
            <Divider orientation="vertical" className="my-6 hidden @sm:block" />
            <Divider orientation="horizontal" className="my-0 block @sm:hidden" />

            <CapitalAccountInterestDueHighlightItem
              capitalAccountSummary={capitalAccountSummary}
              className="whitespace-nowrap px-6 py-4"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default CapitalAvailableHighlightItems;
