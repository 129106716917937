import { CardStatus } from "@highbeam/unit-node-sdk";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import { useUnitApi } from "modules/unit-co-customer-token/queries/useUnitApi";
import { useIsSuperusering } from "state/auth/isSuperusering";
import useHasPermission from "utils/permissions/useHasPermission";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import useCardApi from "../api/useCardApi";

export const ROOT_CARDS_QUERY_NAME = "cards-root";

export const useRefreshAllCardsQueries = () => {
  return useRefreshQuery([ROOT_CARDS_QUERY_NAME]);
};

type CardsQueryStatus = "default" | "active" | "closed" | CardStatus[];

const getStatuses = (status: CardsQueryStatus): CardStatus[] => {
  if (Array.isArray(status)) {
    return status;
  }
  switch (status) {
    case "default":
      return ["Inactive", "Active", "Frozen", "SuspectedFraud"];
    case "active":
      return ["Active"];
    case "closed":
      return ["ClosedByCustomer"];
  }
};

type Params = {
  status: CardsQueryStatus;
  unitCoAccountId?: string;
  businessGuid: string;
  userGuid: string;
  hasCardReadOwnPermission: boolean;
  isSuperusering: boolean;
};

const cardsQueryHooks = makeQueryHooksV2({
  useQueryFnParams: () => {
    const unitApi = useUnitApi();
    const cardApi = useCardApi();
    return { unitApi, cardApi };
  },
  useDefaultParams: () => {
    const businessGuid = useBusinessGuid();
    const userGuid = useUserGuid();
    const hasCardReadOwnPermission = useHasPermission("card:readOwn");
    const isSuperusering = useIsSuperusering();

    return {
      businessGuid,
      userGuid,
      hasCardReadOwnPermission,
      isSuperusering,
    };
  },
  makeQueryKey: (params: Params) => {
    return [ROOT_CARDS_QUERY_NAME, "cards", params] as const;
  },
  makeQueryFn: (
    { unitApi, cardApi },
    { businessGuid, userGuid, hasCardReadOwnPermission, isSuperusering, unitCoAccountId, status }
  ) => {
    return async () => {
      // NB(alex): Unit token scoping bug workaround.
      // When an employee user has a token with scoped cards, but the list of scoped card ids is empty, Unit fails to restrict the list of cards.
      // But after fetching the list of cards, the scoped token _does_ work for fetching individual cards, thus throwing a 403.
      // This temporary workaround hits our cards through backend endpoint, and if the returned list of cards is empty, we return an empty array.
      // BUT we disable it for superuser because the endpoint isn't working for superuser.
      // We should fix this soon, but we want to ship employee user role, so this will work as a stop-gap for now.
      // Unit support request slack thread: https://highbeamco.slack.com/archives/C02JB5M1599/p1712866431406719
      if (!isSuperusering && hasCardReadOwnPermission) {
        const { unitResponse: cardsThroughBackend } = await cardApi.getAll({
          businessGuid: businessGuid,
          userGuid: userGuid,
          status: getStatuses(status),
          ...(unitCoAccountId && { unitCoAccountId: unitCoAccountId }),
        });

        if (cardsThroughBackend.length === 0) {
          return [];
        }
      }

      // TODO(alex) HB-5859
      if (!unitApi) {
        return [];
      }

      const { data: cards } = await unitApi.cards.list({
        include: "account",
        status: getStatuses(status),
        accountId: unitCoAccountId,
      });

      return cards;
    };
  },
});

export default cardsQueryHooks;
