import { ErrorBoundary } from "@sentry/react";
import Transactions from "components/Transactions";
import RecurringPaymentInfoFlexpane from "dialogs/RecurringPaymentInfoFlexpane";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import { useIsBusinessActive } from "modules/business/queries/useBusiness";
import usePayee from "modules/payees/queries/usePayee";
import usePayeeRecurringPayments from "modules/payees/queries/usePayeeRecurringPayments";
import PaymentsVerifyBusinessBannerRenderer from "modules/payments/components/PaymentsVerifyBusinessBannerRenderer";
import VendorCreditModalRoutes from "modules/vendor-credits/components/VendorCreditModalRoutes";
import useIsAllowedToReadVendorCredits from "modules/vendor-credits/hooks/useIsAllowedToReadVendorCredits";
import useVendorCreditsFeatureFlag from "modules/vendor-credits/hooks/useVendorCreditsFeatureFlag";
import RecurringPaymentsTable from "pages/payments/PaymentsOverviewPage/RecurringPayments/RecurringPaymentsTable";
import { FC, Suspense, useEffect, useMemo, lazy } from "react";
import { FallbackProps, ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { Navigate, useParams } from "react-router-dom";
import Shimmer from "ui/feedback/Shimmer";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import TabsV2 from "ui/navigation/TabsV2";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import useSearchParamValue from "utils/search-params/useSearchParamValue";
import { TabConfig, useSearchParamTabState } from "utils/tabs/useTabState";

import PayeeDetailsHeader from "./PayeeDetailsHeader";
import PayeeDetailsInfo from "./PayeeDetailsInfo";
import { usePayeeTransactions } from "./transaction-utils";

const PayeeVendorCreditsSection = lazy(
  () => import("modules/vendor-credits/components/PayeeVendorCreditsSection")
);

const PayeeDetailsBreadcrumbItem: FC = () => {
  const { payeeGuid } = useParams();
  const payee = usePayee(payeeGuid!, { required: true });
  return <Breadcrumbs.CurrentItem>{payee.name}</Breadcrumbs.CurrentItem>;
};

const ErrorFallback: FC<FallbackProps & { onRequiredButNotFoundError: () => void }> = ({
  error,
  resetErrorBoundary,
  onRequiredButNotFoundError,
}) => {
  useEffect(() => {
    resetErrorBoundary();
    if (error instanceof RequiredButNotFoundError) {
      onRequiredButNotFoundError();
    }
  }, [error, resetErrorBoundary, onRequiredButNotFoundError]);
  return null;
};

const RECURRING_PAYMENT_ID_SEARCH_PARAM = "recurringPaymentId";

const PayeeDetailsPaymentsSections: FC = () => {
  const { payeeGuid } = useParams();
  const payeeTransactions = usePayeeTransactions(payeeGuid!);
  const payeeRecurringPayments = usePayeeRecurringPayments({ payeeGuid: payeeGuid! });
  const [_, setRecurringPaymentId] = useSearchParamValue(RECURRING_PAYMENT_ID_SEARCH_PARAM);

  return (
    <>
      {payeeRecurringPayments.length > 0 && (
        <DashboardPage.Section>
          <DashboardPage.Section.Header>
            <DashboardPage.Section.HeaderHeading>
              Scheduled payments
            </DashboardPage.Section.HeaderHeading>
          </DashboardPage.Section.Header>

          <DashboardPage.Section.Body>
            <RecurringPaymentsTable
              isLoading={false}
              recurringPayments={payeeRecurringPayments}
              onRowClick={(recurringPayment) => {
                setRecurringPaymentId(recurringPayment.id);
              }}
            />
          </DashboardPage.Section.Body>
        </DashboardPage.Section>
      )}

      <DashboardPage.Section>
        <DashboardPage.Section.Header>
          <DashboardPage.Section.HeaderHeading>Transactions</DashboardPage.Section.HeaderHeading>
        </DashboardPage.Section.Header>

        <DashboardPage.Section.Body>
          <Transactions
            {...payeeTransactions}
            hideBalanceColumn
            // NB(alex): These unnecessary props are currently required but will be deleted soon.
            activeTab="completed"
            setActiveTab={() => {}}
            tabs={[{ id: "completed", label: "Completed" }]}
          />
        </DashboardPage.Section.Body>
      </DashboardPage.Section>
    </>
  );
};

const PayeeDetailsContent: FC = () => {
  const { payeeGuid } = useParams();
  const isVendorCreditsEnabled = useVendorCreditsFeatureFlag();
  const isAllowedToReadVendorCredits = useIsAllowedToReadVendorCredits();
  const enableVendorCreditsTab = isVendorCreditsEnabled && isAllowedToReadVendorCredits;
  const isBusinessActive = useIsBusinessActive();
  const [recurringPaymentId, setRecurringPaymentId] = useSearchParamValue(
    RECURRING_PAYMENT_ID_SEARCH_PARAM
  );

  const tabs: TabConfig = useMemo(() => {
    return {
      payments: { label: "Payments" },
      // TODO(lev): Unapplied vendor credits count badge.
      "vendor-credits": { label: "Vendor credits" },
    };
  }, []);

  const [activeTab, setActiveTab] = useSearchParamTabState("tab", tabs, "payments");

  return (
    <>
      <ReactErrorBoundary
        fallbackRender={(props) => (
          <ErrorFallback {...props} onRequiredButNotFoundError={() => setRecurringPaymentId("")} />
        )}
      >
        <RecurringPaymentInfoFlexpane
          recurringPaymentId={recurringPaymentId}
          onClose={() => setRecurringPaymentId("")}
        />
      </ReactErrorBoundary>

      <Suspense fallback={<Shimmer total={2} />}>
        <PayeeDetailsHeader />
      </Suspense>

      {isVendorCreditsEnabled && (
        <TabsV2 activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
      )}

      {(activeTab === "payments" || !enableVendorCreditsTab) && (
        <>
          <DashboardPage.Section>
            <Suspense
              fallback={<Shimmer total={2} />} // TODO(alex): `DashboardPage.Section` should have a default fallback
            >
              <PayeeDetailsInfo />
            </Suspense>
          </DashboardPage.Section>

          {isBusinessActive ? (
            <PayeeDetailsPaymentsSections />
          ) : (
            <DashboardPage.Section>
              <DashboardPage.Section.Header>
                <DashboardPage.Section.HeaderHeading>
                  Transactions
                </DashboardPage.Section.HeaderHeading>
              </DashboardPage.Section.Header>

              <DashboardPage.Section.Body>
                <PaymentsVerifyBusinessBannerRenderer />
              </DashboardPage.Section.Body>
            </DashboardPage.Section>
          )}
        </>
      )}

      {enableVendorCreditsTab && activeTab === "vendor-credits" && (
        <PayeeVendorCreditsSection payeeGuid={payeeGuid!} modalRoutePrefix="vendor-credits/" />
      )}
    </>
  );
};

const PayeeDetailsPage: FC = () => {
  return (
    <ErrorBoundary fallback={<Navigate to="/payments/payees" />}>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/payments">Payments</Breadcrumbs.Item>
          <Breadcrumbs.Item to="/payments/payees">Payees</Breadcrumbs.Item>
          <Suspense fallback={<Breadcrumbs.CurrentItemShimmer />}>
            <PayeeDetailsBreadcrumbItem />
          </Suspense>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <PayeeDetailsContent />
      </DashboardPage>

      <VendorCreditModalRoutes routePrefix="vendor-credits/" />
    </ErrorBoundary>
  );
};

export default PayeeDetailsPage;
