import { useRecoilValue } from "recoil";
import Jwt from "reps/Jwt";
import auth0ClientState from "state/auth/auth0Client";
import { getJwtFromAccessToken } from "utils/auth";
import { DataRequired, LoginRequiredParam } from "utils/react-query/require-data";
import useQueryOptions from "utils/react-query/useQueryOptions";

/**
 * The JWT will be refreshed 45 seconds before it expires. This was chosen because Auth0's JWT cache will return the
 * existing JWT if we try to refresh it prior to that.
 */
const JWT_REFRESH_THRESHOLD = 45_000;

const useJwtQueryOptions = <TRequired extends boolean = true>(
  params: LoginRequiredParam<TRequired> = { loginRequired: true as TRequired }
) => {
  const auth0Client = useRecoilValue(auth0ClientState);

  return useQueryOptions<DataRequired<Jwt, TRequired>>({
    queryKey: ["jwt"],
    queryFn: async () => {
      const isAuthenticated = await auth0Client.isAuthenticated();

      if (isAuthenticated) {
        const jwtString = await auth0Client.getTokenSilently();
        return getJwtFromAccessToken(jwtString);
      }

      // Redirect to login page if jwt is required.
      if (params?.loginRequired) {
        await auth0Client.loginWithRedirect();
      }

      return null as DataRequired<Jwt, TRequired>;
    },
    refetchOnWindowFocus: "always",
    refetchOnReconnect: "always",
    refetchInterval: ({ state: { data: jwt } }) =>
      Math.max(
        (jwt?.expiresAt ?? 0) * 1000 - Date.now() - JWT_REFRESH_THRESHOLD,
        JWT_REFRESH_THRESHOLD
      ),
  });
};

export default useJwtQueryOptions;
