import businessesQueryHooks from "modules/businesses/queries/businessesQueryHooks";
import { useRecoilValue } from "recoil";
import BusinessRep from "reps/BusinessRep";
import Text from "ui/typography/Text";
import { focusNextElement, focusPreviousElement } from "utils/browser/focus-element";
import useDocumentKeyboardEvent from "utils/browser/useDocumentKeyboardEvent";
import { useFuseSearch } from "utils/fuse";

import BusinessSwitcherResult from "./BusinessSwitcherResult";
import BusinessSwitcherResultCell from "./BusinessSwitcherResultCell";
import styles from "./BusinessSwitcherResults.module.scss";
import superuserInputState from "./state/superuserInputState";

const BusinessSwitcherResults = () => {
  const query = useRecoilValue(superuserInputState);

  const { data: businesses = [], isFetching, error } = businessesQueryHooks.useQuery({});
  const results = useSearch(businesses, query);

  useDocumentKeyboardEvent((e) => {
    if (e.key === "ArrowUp") {
      e.preventDefault();
      focusPreviousElement();
    }
    if (e.key === "ArrowDown") {
      e.preventDefault();
      focusNextElement();
    }
  }, []);

  if (isFetching) {
    return <Text size={14}>Loading...</Text>;
  }

  if (error) {
    return (
      <>
        <Text size={14}>Error.</Text>
        {error instanceof Error && <Text size={12}>{error.message}</Text>}
      </>
    );
  }

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <BusinessSwitcherResultCell header>Name</BusinessSwitcherResultCell>
          <BusinessSwitcherResultCell header>Guid</BusinessSwitcherResultCell>
          <BusinessSwitcherResultCell header>Unit.co id</BusinessSwitcherResultCell>
          <BusinessSwitcherResultCell header>Status</BusinessSwitcherResultCell>
        </tr>
      </thead>
      <tbody>
        {results.map((result) => (
          <BusinessSwitcherResult key={result.guid} business={result} />
        ))}
      </tbody>
    </table>
  );
};

export default BusinessSwitcherResults;

const useSearch = (businesses: BusinessRep.Complete[], query: string): BusinessRep.Complete[] => {
  const results = useFuseSearch(businesses, query, {
    shouldSort: true,
    threshold: query ? 0.3 : 1,
    keys: ["dba", "guid", "name", "unitCoCustomerId"],
  });
  if (!query) {
    return businesses.sort((a, b) => {
      const displayNameA = a.internalName;
      const displayNameB = b.internalName;
      if (!displayNameA) {
        if (!displayNameB) return a.guid.localeCompare(b.guid);
        return 1;
      }
      if (!displayNameB) return -1;
      return displayNameA.localeCompare(displayNameB);
    });
  }
  return results;
};
