import { Calculator as CalculatorIcon } from "@phosphor-icons/react";
import {
  DEPOSIT_ACCOUNT_YIELD,
  HIGH_YIELD_ACCOUNT_YIELD,
} from "modules/bank-accounts/constants/yield";
import { YieldChatCalculatorRep } from "modules/chat/reps/ChatCalculatorRep";
import React, { useMemo, useState } from "react";
import MoneyAmountV2 from "ui/data-display/money/MoneyAmountV2";
import CurrencyInputV2 from "ui/inputs/CurrencyInputV2";
import TextInputV2 from "ui/inputs/TextInputV2";
import { Heading4, Paragraph } from "ui/typography";
import { stripNonNumeric } from "utils/string";

import ParagraphChatElement from "../ParagraphChatElement";

import Calculator from ".";

type Props = {
  calculator: YieldChatCalculatorRep;
};

const YieldCalculator: React.FC<Props> = ({ calculator }) => {
  const [averageBalanceInOperating, setAverageBalanceInOperating] = useState<number | undefined>(
    calculator.operatingBalance ? Math.round(Number(calculator.operatingBalance)) : undefined
  );
  const [averageBalanceInHighYield, setAverageBalanceInHighYield] = useState<number | undefined>(
    calculator.highYieldBalance ? Math.round(Number(calculator.highYieldBalance)) : undefined
  );
  const [operatingApy, setOperatingApy] = useState<number | undefined>(DEPOSIT_ACCOUNT_YIELD);
  const [highYieldApy, setHighYieldApy] = useState<number | undefined>(HIGH_YIELD_ACCOUNT_YIELD);

  const annualYield = useMemo(() => {
    const annualYieldInOperating = ((averageBalanceInOperating ?? 0) * (operatingApy ?? 0)) / 100;
    const annualYieldInHighYield = ((averageBalanceInHighYield ?? 0) * (highYieldApy ?? 0)) / 100;
    return annualYieldInOperating + annualYieldInHighYield;
  }, [averageBalanceInHighYield, averageBalanceInOperating, highYieldApy, operatingApy]);

  return (
    <div className="flex flex-col gap-6">
      <Calculator.Frame>
        <Calculator.Title icon={<CalculatorIcon size={20} />} title="Yield" />
        <Calculator.Section>
          <Calculator.Row
            left={
              <Calculator.RowContent>
                <Paragraph className="font-medium">Average balance in operating</Paragraph>
              </Calculator.RowContent>
            }
            right={
              <Calculator.RowContent className="gap-4">
                <CurrencyInputV2
                  className="w-44"
                  prefixValue="$"
                  allowDecimals={false}
                  value={averageBalanceInOperating ? averageBalanceInOperating * 100 : undefined}
                  onChange={(value) => {
                    setAverageBalanceInOperating(value ? value / 100 : undefined);
                  }}
                />
                <Paragraph className="w-20 text-grey-600" />
              </Calculator.RowContent>
            }
          />
          <Calculator.Row
            left={
              <Calculator.RowContent>
                <Paragraph className="font-medium">Average balance in high yield</Paragraph>
              </Calculator.RowContent>
            }
            right={
              <Calculator.RowContent className="gap-4">
                <CurrencyInputV2
                  className="w-44"
                  prefixValue="$"
                  allowDecimals={false}
                  value={averageBalanceInHighYield ? averageBalanceInHighYield * 100 : undefined}
                  onChange={(value) => {
                    setAverageBalanceInHighYield(value ? value / 100 : undefined);
                  }}
                />
                <Paragraph className="w-20 text-grey-600" />
              </Calculator.RowContent>
            }
          />
          <Calculator.Row
            left={
              <Calculator.RowContent>
                <Paragraph className="font-medium">Operating APY</Paragraph>
              </Calculator.RowContent>
            }
            right={
              <Calculator.RowContent className="gap-4">
                <TextInputV2
                  className="w-44"
                  endAdornment="%"
                  value={operatingApy === undefined ? "" : String(operatingApy)}
                  onChange={(value) => {
                    const numericValue = stripNonNumeric(value);
                    setOperatingApy(numericValue.length > 0 ? Number(numericValue) : undefined);
                  }}
                />
                <Paragraph className="w-20 text-grey-600" />
              </Calculator.RowContent>
            }
          />
          <Calculator.Row
            left={
              <Calculator.RowContent>
                <Paragraph className="font-medium">High yield APY</Paragraph>
              </Calculator.RowContent>
            }
            right={
              <Calculator.RowContent className="gap-4">
                <TextInputV2
                  className="w-44"
                  endAdornment="%"
                  value={highYieldApy === undefined ? "" : String(highYieldApy)}
                  onChange={(value) => {
                    const numericValue = stripNonNumeric(value);
                    setHighYieldApy(numericValue.length > 0 ? Number(numericValue) : undefined);
                  }}
                />
                <Paragraph className="w-20 text-grey-600" />
              </Calculator.RowContent>
            }
          />
        </Calculator.Section>
        <Calculator.Section className="gap-1 pt-3">
          <Heading4 className="font-medium">Annual yield with Highbeam</Heading4>
          {annualYield ? (
            <Paragraph className="w-fit border-b-2 border-dotted border-grey-500 text-2xl font-medium text-green-500">
              <MoneyAmountV2
                className="text-2xl"
                showCurrencySymbol
                amount={annualYield.toFixed(2)}
                currencyCode="USD"
              />
            </Paragraph>
          ) : (
            <Paragraph className="w-fit border-b-2 border-dotted border-grey-500 text-xs font-medium text-grey-500">
              Please complete the form.
            </Paragraph>
          )}
        </Calculator.Section>
      </Calculator.Frame>
      {annualYield >= 1 && (
        <ParagraphChatElement
          element={{
            type: "Paragraph",
            content: [
              {
                type: "Text",
                bold: false,
                content: "Banking with Highbeam earns you ",
                italic: false,
              },
              { type: "Money", amount: annualYield, showCents: false },
              {
                type: "Text",
                bold: false,
                content: " in annual yield!",
                italic: false,
              },
            ],
          }}
        />
      )}
    </div>
  );
};

export default YieldCalculator;
