import amazonIcon from "assets/amazon-logo-without-label.svg";
import paypalIcon from "assets/paypal-icon.svg";
import shopifyIcon from "assets/shopify-icon.svg";
import stripeIcon from "assets/stripe-icon.svg";
import { FC } from "react";

import { ConnectedStoreType } from "../queries/useConnectedStores";

export const getConnectedStoreIcon = (connectedStoreType: ConnectedStoreType): string => {
  return {
    Shopify: shopifyIcon,
    "Shopify-rutter": shopifyIcon,
    PayPal: paypalIcon,
    Stripe: stripeIcon,
    Amazon: amazonIcon,
  }[connectedStoreType];
};

type Props = {
  connectedStoreType: ConnectedStoreType;
  className?: string;
};

const ConnectedStoreIcon: FC<Props> = ({ connectedStoreType, className }) => {
  return (
    <img
      src={getConnectedStoreIcon(connectedStoreType)}
      alt={connectedStoreType}
      className={className}
    />
  );
};

export default ConnectedStoreIcon;
