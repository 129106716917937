import CapitalAccountRep from "reps/CapitalAccountRep";
import variants from "utils/ts/variants";

type LenderDetails = {
  external: boolean;
  loginLink: string;
  name: string;
};

const getCapitalLenderDetails = (capitalAccount: CapitalAccountRep.Complete): LenderDetails => {
  return variants(capitalAccount.lender, {
    [CapitalAccountRep.CapitalLender.Dwight]: {
      external: true,
      loginLink: "https://app.dwightfunding.com/login",
      name: "Dwight",
    },
    [CapitalAccountRep.CapitalLender.Ember]: {
      external: true,
      loginLink: "https://www.embercompany.co/",
      name: "Ember",
    },
    [CapitalAccountRep.CapitalLender.Highbeam]: {
      external: false,
      loginLink: "https://auth.highbeam.co/u/login",
      name: "Highbeam",
    },
    [CapitalAccountRep.CapitalLender.Uncapped]: {
      external: true,
      loginLink: "https://portal.weareuncapped.com/",
      name: "Uncapped",
    },
    [CapitalAccountRep.CapitalLender.Sandbox]: {
      external: true,
      loginLink: "https://acmecapital.highbeam.co/login",
      name: "Acme Capital",
    },
  });
};

export default getCapitalLenderDetails;
