import { ApiBuilder, useBackendV1Api } from "modules/highbeam-api/base";
import { AuthMode } from "state/auth/highbeamApi";

import ReferralLinkRep from "../reps/ReferralLinkRep";

type ReferralLinkApi = {
  create: (creator: ReferralLinkRep.Creator) => Promise<ReferralLinkRep>;
  get: (referralSlugOrGuid: string) => Promise<ReferralLinkRep | null>;
  getByBusiness: (businessGuid: string) => Promise<ReferralLinkRep[] | null>;
  delete: (referralSlug: string) => Promise<ReferralLinkRep | null>;
};

const builder: ApiBuilder<ReferralLinkApi> = (api) => ({
  create: async (creator) => {
    const url = `/referral-links`;
    return (await api.post<ReferralLinkRep>(url, creator))!;
  },
  get: async (referralSlugOrGuid) => {
    const url = `/referral-links/${referralSlugOrGuid}`;
    return await api.get<ReferralLinkRep | null>(url);
  },
  getByBusiness: async (businessGuid) => {
    const qp = new URLSearchParams({ businessGuid });
    const url = `/referral-links?${qp}`;
    return await api.get<ReferralLinkRep[]>(url);
  },
  delete: async (referralSlug) => {
    const url = `/referral-links/${referralSlug}`;
    return await api.delete<ReferralLinkRep>(url);
  },
});

const useReferralLinkApi = (authMode: AuthMode = "maybeAuthenticated") => {
  return useBackendV1Api(builder, { authMode });
};

export { useReferralLinkApi };
