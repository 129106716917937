import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";

type ChatRefreshApi = {
  execute: (businessGuid: string) => Promise<void>;
};

const builder: ApiBuilder<ChatRefreshApi> = (api) => ({
  execute: async (businessGuid) => {
    const url = "/chat/refresh/execute";
    await api.post(url, { businessGuid });
  },
});

const useChatRefreshApi = () => useBackendV2Api(builder);

export default useChatRefreshApi;
