import { ArrowsClockwise, Check } from "@phosphor-icons/react";
import { FEATURE_FLAGS } from "flags";
import { ConnectedStore } from "modules/connected-stores/queries/useConnectedStores";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import { FC } from "react";
import Card from "ui/data-display/Card";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import Tooltip from "ui/overlay/Tooltip";
import { Heading4, Paragraph, Span } from "ui/typography";
import useIsAllowedToConnectStores from "utils/permissions/useIsAllowedToConnectStores";
import cn from "utils/tailwind/cn";

import ShopifyStoreUpdatePermissionsButton from "./ShopifyStoreUpdatePermissionsButton";

// NB(alex): This can probably be consolidated with `AddIntegration`.

type Props = {
  connectedStore: ConnectedStore;
  onDisconnect: (connectedStore: ConnectedStore) => void;
  className?: string;
};

const ConnectedStoreCard: FC<Props> = ({ connectedStore, onDisconnect, className }) => {
  const isAllowedToConnectStores = useIsAllowedToConnectStores();
  const canDisconnectStoreInSettings = useFeatureFlag(
    FEATURE_FLAGS["allow-store-disconnection-in-settings"]
  );
  const disabledDisconnect = !canDisconnectStoreInSettings || !isAllowedToConnectStores;

  const showShopifyUpdateConnectionButton =
    connectedStore.type === "Shopify" && !connectedStore.hasAllPermissions;
  const showDisconnectButton = !showShopifyUpdateConnectionButton;

  return (
    <Card
      className={cn(
        "flex min-h-40 w-full min-w-full flex-col items-start justify-between border border-grey-200 p-5 tablet:min-w-80",
        className
      )}
      shadow="xs"
    >
      <div>
        <div className="mb-3 flex items-center gap-x-2">
          <Heading4>{connectedStore.displayName}</Heading4>
        </div>

        <Paragraph className="mt-2 flex items-center gap-x-2 text-grey-600">
          {connectedStore.hasSyncedPayoutsAndBalances ? (
            <>
              <Check size={16} className="rounded-full bg-green-400 p-0.5" color="white" />
              <Span className="text-sm">Connected</Span>
            </>
          ) : (
            <Tooltip>
              <Tooltip.Trigger>
                <Span className="flex items-center gap-x-2">
                  <ArrowsClockwise size={16} className="bg-white" />
                  <Span className="text-sm">Syncing store data</Span>
                </Span>
              </Tooltip.Trigger>

              <Tooltip.Content>Sync can take from an average of 24h up to a week.</Tooltip.Content>
            </Tooltip>
          )}
        </Paragraph>
      </div>

      {showShopifyUpdateConnectionButton && (
        <ShopifyStoreUpdatePermissionsButton
          storeName={connectedStore.shopSubdomain}
          redirectPathKey="settings"
        />
      )}

      {showDisconnectButton && (
        <ButtonWithTooltip
          fullWidth
          variant={disabledDisconnect ? "danger" : "tertiary"}
          onClick={() => onDisconnect(connectedStore)}
          disabled={disabledDisconnect}
          tooltip={
            disabledDisconnect && (
              <>
                {!isAllowedToConnectStores ? (
                  <>You don’t have permission to disconnect stores.</>
                ) : (
                  <>
                    If you want to disconnect {connectedStore.displayName}, please reach out to
                    Highbeam support.
                  </>
                )}
              </>
            )
          }
        >
          Disconnect
        </ButtonWithTooltip>
      )}
    </Card>
  );
};

export default ConnectedStoreCard;
