import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import useNonTerminatedCapitalAccounts from "modules/capital-accounts/hooks/useNonTerminatedCapitalAccounts";
import ConnectedStoreCard from "modules/connected-stores/components/ConnectedStoreCard";
import ShopifyStoreUpdatePermissionsBanner, {
  useHasDismissedBanner,
} from "modules/connected-stores/components/ShopifyStoreUpdatePermissionsBanner";
import DeleteConnectedStoreModal, {
  DeleteConnectedStoreModalParams,
} from "modules/connected-stores/dialogs/DeleteConnectedStoreModal";
import {
  connectedStoresQueryHooks,
  ConnectedStoreType,
  ShopifyConnectedStore,
} from "modules/connected-stores/queries/useConnectedStores";
import getConnectedStoreIcon from "modules/connected-stores/utils/getConnectedStoreIcon";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import SectionLoading from "ui/feedback/SectionLoading";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import useModalV2 from "utils/dialog/useModalV2";
import useIsAllowedToNavigateToConnectedStoresPage from "utils/permissions/navigation/useIsAllowedToNavigateToConnectedStoresPage";
import { getRutterPlatformByConnectedStoreType } from "utils/rutter";
import { addAOrAn } from "utils/string";
import cn from "utils/tailwind/cn";

import AddIntegration from "../SettingsPage/AddIntegration";
import CannotDeleteStoreModal from "../SettingsPage/CannotDeleteStoreModal";
import ConnectRutter from "../SettingsPage/Integrations/ConnectRutter";
import ConnectShopify from "../SettingsPage/Integrations/ConnectShopify";

const connectedStoreTypes: ConnectedStoreType[] = ["Shopify", "Amazon", "Stripe", "PayPal"];

const SettingsStoresPageContent = () => {
  const [showCannotDeleteConfirmationModal, setShowCannotDeleteConfirmationModal] = useState(false);
  const {
    data: connectedStores = [],
    isLoading,
    refetch,
  } = connectedStoresQueryHooks.useQuery({ params: {} });
  const nonTerminatedCapitalAccounts = useNonTerminatedCapitalAccounts();
  const hasNonTerminatedCapitalAccounts = nonTerminatedCapitalAccounts.length > 0;

  const deleteConnectedStoreModal = useModalV2<DeleteConnectedStoreModalParams>();

  const hasDismissedBanner = useHasDismissedBanner();

  const shopifyStoreWithoutAllPermissions = connectedStores.find(
    (store): store is ShopifyConnectedStore => store.type === "Shopify" && !store.hasAllPermissions
  );

  // NB(alex): do not use this pattern. We should probably group by type and map that array instead.
  const connectedStoresByType = (type: ConnectedStoreType) =>
    connectedStores.filter((integration) => integration.type === type);

  return (
    <>
      <DashboardPage.Header>
        <DashboardPage.Header.BackCaret to="/settings" />
        <DashboardPage.Header.Title>Stores</DashboardPage.Header.Title>
      </DashboardPage.Header>

      {!hasDismissedBanner && shopifyStoreWithoutAllPermissions && (
        <ShopifyStoreUpdatePermissionsBanner
          redirectPathKey="settings"
          storeName={shopifyStoreWithoutAllPermissions.shopSubdomain}
        />
      )}

      <DashboardPage.Section>
        {isLoading && <SectionLoading />}

        {!isLoading &&
          connectedStoreTypes.map((type, index) => (
            <section key={type}>
              <img
                title={type}
                src={getConnectedStoreIcon({ connectedStoreType: type })}
                alt="Platform logo"
                className="mb-5 size-5"
              />
              <div
                className={cn(
                  "mb-8 flex flex-wrap gap-5 border-b border-grey-100 pb-8",
                  index === connectedStoreTypes.length - 1 && "border-b-0"
                )}
              >
                {connectedStoresByType(type).map((connectedStore) => (
                  <ConnectedStoreCard
                    className="max-w-72"
                    key={connectedStore.guid}
                    connectedStore={connectedStore}
                    onDisconnect={
                      hasNonTerminatedCapitalAccounts
                        ? () => {
                            setShowCannotDeleteConfirmationModal(true);
                          }
                        : () => {
                            deleteConnectedStoreModal.open({
                              connectedStoreGuid: connectedStore.guid,
                              connectedStoreType: connectedStore.type,
                            });
                          }
                    }
                  />
                ))}

                {type === "Shopify" ? (
                  <ConnectShopify redirectPathKey="settings">
                    {({ openModal }) => (
                      <AddIntegration
                        className="max-w-72"
                        title={`Add ${addAOrAn(type)} store`}
                        description={`Connect your ${type} store to get sales data and insights.`}
                        addText={`Connect ${type} store`}
                        onAdd={openModal}
                      />
                    )}
                  </ConnectShopify>
                ) : (
                  <ConnectRutter
                    platform={getRutterPlatformByConnectedStoreType(type)}
                    onConnect={(_, callbacks) => {
                      refetch();
                      callbacks.closeModal();
                    }}
                  >
                    {({ openModal }) => (
                      <AddIntegration
                        className="max-w-72"
                        title={`Add ${addAOrAn(type)} store`}
                        description={`Connect your ${type} store to get sales data and insights.`}
                        addText={`Connect ${type} store`}
                        onAdd={openModal}
                      />
                    )}
                  </ConnectRutter>
                )}
              </div>
            </section>
          ))}
      </DashboardPage.Section>

      {deleteConnectedStoreModal.isOpen && (
        <DeleteConnectedStoreModal
          onClose={deleteConnectedStoreModal.close}
          {...deleteConnectedStoreModal.params}
        />
      )}

      {showCannotDeleteConfirmationModal && (
        <CannotDeleteStoreModal onClose={() => setShowCannotDeleteConfirmationModal(false)} />
      )}
    </>
  );
};

const SettingsStoresPage = () => {
  const isAllowedToNavigateToConnectedStoresPage = useIsAllowedToNavigateToConnectedStoresPage();

  if (!isAllowedToNavigateToConnectedStoresPage) {
    return <Navigate to="/settings" />;
  }

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/settings">Settings</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Stores</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <SettingsStoresPageContent />
      </DashboardPage>
    </>
  );
};

export default SettingsStoresPage;
