import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";

import { HIGH_CREDIT_UTILIZATION_WARNING_THRESHOLD } from "../constants";

const isHighCreditUtilization = (capitalAccountSummary: CapitalAccountSummaryRep.Complete) => {
  const capitalUtilized = 0 - capitalAccountSummary.runningBalance;
  const capitalLimit = capitalAccountSummary.details.limit;
  return capitalUtilized / capitalLimit > HIGH_CREDIT_UTILIZATION_WARNING_THRESHOLD;
};

export default isHighCreditUtilization;
