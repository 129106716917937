import SignOutButton from "modules/auth/components/SignOutButton";
import UserAvatarMenu from "modules/auth/components/UserAvatarMenu";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import { OnboardingDotsLoader } from "modules/onboarding/components/shared/onboarding-common-components";
import { FC, ReactNode, Suspense } from "react";
import { Link } from "react-router-dom";
import Page, { PageHeaderProps } from "ui/data-display/Page";
import BarChart from "ui/data-visualization/BarChart";
import { HighbeamLogoWide } from "ui/icons/logos/logos-wide";
import cn from "utils/tailwind/cn";

const OnboardingLayoutHeader: FC<Omit<PageHeaderProps, "center">> = ({ className, ...props }) => {
  // TODO(alex) HB-5859: This check doesn't appear necessary by looking at ts types but actually those types are wrong, so we need to do this check or else the user avatar menu will crash.
  const businessGuid = useBusinessGuid({ loginRequired: false });
  const userGuid = useUserGuid({ loginRequired: false });

  return (
    <Page.Header
      className={cn("border-b border-b-grey-200 bg-white py-4 tablet:py-6", className)}
      left={
        <Link to="/">
          <HighbeamLogoWide className="tablet:px-8" />
        </Link>
      }
      right={
        <>
          {businessGuid && (
            <UserAvatarMenu
              // Aligns the dropdown user avatar with the user avatar button.
              dropdownClassName="mt-1 tablet:pt-[0.5625rem] tablet:mt-0 tablet:pt-0"
            />
          )}
          {!businessGuid && userGuid && <SignOutButton />}
        </>
      }
      {...props}
    />
  );
};

type Props = {
  progressBarPercentage?: number;
  children: ReactNode;
  header?: ReactNode;
  showBackgroundImage?: boolean;
  className?: string;
};

const OnboardingLayoutV2: FC<Props> = ({
  children,
  header = <OnboardingLayoutHeader />,
  progressBarPercentage,
  className,
}) => {
  return (
    <>
      <Page className={cn("items-stretch bg-white", className)}>
        {header}
        {progressBarPercentage !== undefined && (
          <BarChart className="w-full" height={3} roundedCornerVariant="rounded-none">
            <BarChart.Bar
              color="purple"
              widthPercentage={progressBarPercentage}
              className="rounded-l-none"
            />
          </BarChart>
        )}

        <Suspense fallback={<OnboardingDotsLoader />}>{children}</Suspense>
      </Page>
    </>
  );
};

export default Object.assign(OnboardingLayoutV2, {
  Header: OnboardingLayoutHeader,
});
