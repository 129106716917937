import {
  useOpenBankAccountOrThrow,
  usePrimaryBankAccountOrThrow,
} from "modules/bank-accounts/queries/useBankAccount";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";

type Params = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const useCapitalAccountRepaymentBankAccount = ({ capitalAccountSummary }: Params) => {
  const primaryBankAccount = usePrimaryBankAccountOrThrow();

  const repaymentBankAccountGuid =
    capitalAccountSummary.details.repayment.bankAccountGuid ?? primaryBankAccount.guid;

  return useOpenBankAccountOrThrow(repaymentBankAccountGuid);
};

export default useCapitalAccountRepaymentBankAccount;
