import useMfa from "modules/mfa/useMfa";
import { useCallback } from "react";
import useModalV2 from "utils/dialog/useModalV2";

import { CreateCardModalDefaultValues } from "../dialogs/CreateCardModal";

const useCreateCardModal = () => {
  const { open: _open, ...createCardModal } = useModalV2<{
    defaultValues?: CreateCardModalDefaultValues;
  }>();
  const { mfa } = useMfa();

  const open = useCallback(
    async (params?: Parameters<typeof _open>[0]) => {
      await mfa();
      _open(params ?? {});
    },
    [mfa, _open]
  );

  return { ...createCardModal, open };
};

export default useCreateCardModal;
