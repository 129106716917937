import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { FC } from "react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
import Banner from "ui/data-display/Banner";
import InfoIcon from "ui/icons/InfoIcon";

import { SupportedRedirectUrl } from "../utils/shopify-supported-redirect-url";

import ShopifyStoreUpdatePermissionsButton from "./ShopifyStoreUpdatePermissionsButton";

type AtomState = Record<string, boolean>;

const { persistAtom } = recoilPersist();
const shopifyStoreUpdatePermissionsBannerDismissedState = atom<AtomState>({
  key: "shopifyStoreUpdatePermissionsBannerDismissedState",
  default: {},
  effects: [persistAtom],
});

const useHasDismissedBanner = () => {
  const businessGuid = useBusinessGuid();
  const dismissedBanners = useRecoilValue(shopifyStoreUpdatePermissionsBannerDismissedState);
  return Boolean(dismissedBanners[businessGuid]);
};

const useDismissBanner = () => {
  const businessGuid = useBusinessGuid();
  const setHasDismissed = useSetRecoilState(shopifyStoreUpdatePermissionsBannerDismissedState);
  return () => setHasDismissed((prev) => ({ ...prev, [businessGuid]: true }));
};

type Props = {
  storeName: string;
  redirectPathKey: SupportedRedirectUrl;
};

const ShopifyStoreUpdatePermissionsBanner: FC<Props> = ({ storeName, redirectPathKey }) => {
  const dismiss = useDismissBanner();

  return (
    <Banner
      icon={<InfoIcon variant="info" />}
      color="purple"
      title="Update store permissions"
      paragraph="Update your Shopify connection so the AI Analyst can answer your questions using Shopify data."
      button={<Banner.DismissButton onClick={() => dismiss()} />}
      footer={
        <Banner.Footer>
          <ShopifyStoreUpdatePermissionsButton
            className="ml-9 w-fit"
            size="sm"
            storeName={storeName}
            redirectPathKey={redirectPathKey}
          />
        </Banner.Footer>
      }
    />
  );
};

export { useHasDismissedBanner };

export default ShopifyStoreUpdatePermissionsBanner;
