import { useRecoilState } from "recoil";
import isBusinessSwitcherOpenState from "state/superuser/isBusinessSwitcherOpen";
import useDocumentKeyboardEvent from "utils/browser/useDocumentKeyboardEvent";

import BusinessSwitcherDialog from "./BusinessSwitcherDialog";

const BusinessSwitcher = () => {
  const [isOpen, setIsOpen] = useRecoilState(isBusinessSwitcherOpenState);

  useDocumentKeyboardEvent((e) => {
    if (e.key === "k" && e.metaKey && e.shiftKey) {
      e.preventDefault();
      setIsOpen((isOpen) => !isOpen);
    }
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  return <BusinessSwitcherDialog isOpen={isOpen} onClose={handleClose} />;
};

export default BusinessSwitcher;
