import { captureException } from "@sentry/react";
import errorPageIcon from "assets/error-page-icon.svg";
import env from "env";
import React, { useEffect } from "react";
import Text from "ui/typography/Text";
import cn from "utils/tailwind/cn";

type Props = {
  error: Error;
  className?: string;
};

const ErrorText: React.FC<Props> = ({ error, className }) => {
  const errorStack = env.SHOW_DEBUG_MESSAGES ? error?.stack : undefined;

  useEffect(() => {
    captureException(error);
  }, [error]);

  return (
    <>
      <div className={cn("flex items-center gap-x-4", className)}>
        <img alt="" className="size-8 shrink-0" src={errorPageIcon} />
        <Text size={14}>
          We are sorry, there is a temporary issue with this view. Please come back in a few
          minutes, or reach out to support.
        </Text>
      </div>
      {errorStack && (
        <div className="py-2">
          <code className="whitespace-pre-line text-xs">[DEV] {errorStack}</code>
        </div>
      )}
    </>
  );
};

export default ErrorText;
