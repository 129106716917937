import { FC, ComponentPropsWithoutRef, useMemo } from "react";
import Combobox from "ui/inputs/Combobox";

import { useAccountingClassCodes } from "../queries/accountingClassCodesQueryHooks";
import AccountingClassCodeRep from "../reps/AccountingClassCodeRep";

import AccountingClassCodeBar, { FALLBACK_NAME } from "./AccountingClassCodeBar";

type AccountingClassCodeWithDepth = [AccountingClassCodeRep, number];

const makeParentChildMap = (accountingClassCodes: AccountingClassCodeRep[]) => {
  // The null key is for accounts with no parent.
  const parentChildMap = new Map<string | null, AccountingClassCodeRep[]>();

  accountingClassCodes.forEach((accountingClassCode) => {
    const { parentRutterId } = accountingClassCode;
    if (parentChildMap.has(parentRutterId)) {
      parentChildMap.get(parentRutterId)!.push(accountingClassCode);
    } else {
      parentChildMap.set(parentRutterId, [accountingClassCode]);
    }
  });

  return parentChildMap;
};

const sortAccountingClassCodesByName = (accountingClassCodes: AccountingClassCodeRep[]) =>
  accountingClassCodes.sort((a, b) => {
    const nameA = a.name;
    const nameB = b.name;

    if (nameA && nameB) return nameA.localeCompare(nameB);
    if (nameA) return -1;
    if (nameB) return 1;
    return 0;
  });

const makeHierarchicallySortedAccountingClassCodes = (
  accountingClassCodes: AccountingClassCodeRep[]
): AccountingClassCodeWithDepth[] => {
  const parentChildMap = makeParentChildMap(accountingClassCodes);

  const generate = (
    parentRutterId: string | null,
    depth: number
  ): AccountingClassCodeWithDepth[] => {
    const childAccountingClassCodes = parentChildMap.get(parentRutterId) || [];

    return sortAccountingClassCodesByName(childAccountingClassCodes).flatMap(
      (accountingClassCode) => [
        [accountingClassCode, depth],
        ...generate(accountingClassCode.rutterId, depth + 1),
      ]
    );
  };

  return generate(null, 0);
};

const AccountingClassCodeSelectItem = Combobox.Item;

type Props = ComponentPropsWithoutRef<typeof Combobox> & {
  labelText?: string;
  searchValue?: string;
  onSearchValueChange?: (value: string) => void;
};

const AccountingClassCodeSelect: FC<Props> = ({
  labelText = "Accounting class",
  value,
  onValueChange,
  searchValue,
  onSearchValueChange,
  ...props
}) => {
  const accountingClassCodes = useAccountingClassCodes();

  const accountingClassCodesLookup = useMemo(() => {
    return new Map(
      accountingClassCodes.map((accountingClassCode) => [
        accountingClassCode.id,
        accountingClassCode,
      ])
    );
  }, [accountingClassCodes]);

  const hierarchicallySortedAccountingClassCodes = useMemo(() => {
    return makeHierarchicallySortedAccountingClassCodes(accountingClassCodes);
  }, [accountingClassCodes]);

  const selectedAccountingClassCode = value ? accountingClassCodesLookup.get(value)! : null;

  return (
    <Combobox value={value} onValueChange={onValueChange} {...props}>
      <Combobox.Field>
        <Combobox.Label>{labelText}</Combobox.Label>
        <Combobox.Trigger>
          {value && selectedAccountingClassCode && (
            <AccountingClassCodeBar accountingClassCode={selectedAccountingClassCode} />
          )}
        </Combobox.Trigger>
      </Combobox.Field>
      <Combobox.Menu searchValue={searchValue} onSearchValueChange={onSearchValueChange}>
        <Combobox.MenuEmptyState>No class codes found.</Combobox.MenuEmptyState>

        {hierarchicallySortedAccountingClassCodes.map(([accountingClassCode, depth]) => (
          <AccountingClassCodeSelectItem
            key={accountingClassCode.id}
            value={accountingClassCode.id}
            searchableText={accountingClassCode.name ?? FALLBACK_NAME}
            disabled={accountingClassCode.status !== "active"}
          >
            <AccountingClassCodeBar accountingClassCode={accountingClassCode} depth={depth} />
          </AccountingClassCodeSelectItem>
        ))}
      </Combobox.Menu>
    </Combobox>
  );
};

export default AccountingClassCodeSelect;
