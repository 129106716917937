import { CalendarBlank, Lightning } from "@phosphor-icons/react";
import { ReactNode } from "react";
import { AiTaskRep, AiTaskType } from "reps/ai-tasks/AiTaskRep";
import { Paragraph } from "ui/typography";

type TableRowProps = {
  icon?: ReactNode;
  label?: string;
  description: string;
};

type ModalDescriptionTableProps = {
  task: AiTaskRep;
};

export const ModalDescriptionTable: React.FC<ModalDescriptionTableProps> = ({ task }) => {
  const getTableRows = (taskType: AiTaskType): TableRowProps[] => {
    switch (taskType) {
      case "CleanAndCategorizeTransactions":
        return [
          {
            icon: <CalendarBlank size={20} />,
            label: "Timing",
            description: "Recurring - Weekly",
          },
          {
            icon: <Lightning size={20} />,
            label: "Actions",
            description:
              "Automatically categorize and clean up transaction data for better reporting",
          },
          {
            description: "Generate weekly transaction summary reports",
          },
        ];
      case "MaximizeYield":
        return [
          {
            icon: <CalendarBlank size={20} />,
            label: "Timing",
            description: "Recurring - Every morning",
          },
          {
            icon: <Lightning size={20} />,
            label: "Actions",
            description: "Establish a target balance in your operating account",
          },
          {
            description: "Monitor and sweep funds between your operating and high yield accounts",
          },
        ];
      case "CashFlowForecast":
        return [
          {
            icon: <CalendarBlank size={20} />,
            label: "Timing",
            description: "Recurring - Daily",
          },
          {
            icon: <Lightning size={20} />,
            label: "Actions",
            description: "Update actuals and forecasts across all accounts",
          },
          {
            description: "Maintain and validate live Google Sheet",
          },
        ];
      case "AuditingAndAlerts":
        return [
          {
            icon: <CalendarBlank size={20} />,
            label: "Timing",
            description: "Recurring - Weekly",
          },
          {
            icon: <Lightning size={20} />,
            label: "Actions",
            description:
              "Create me a report with my net cash flow, any changes to my return rates, and flag any unusual transactions to double-check",
          },
          {
            description: "Send me an email with the report",
          },
        ];
      case "Generic":
      default:
        return [
          {
            icon: <CalendarBlank size={20} />,
            label: "Timing",
            description: "",
          },
          {
            icon: <Lightning size={20} />,
            label: "Actions",
            description: "",
          },
          {
            description: "",
          },
        ];
    }
  };

  const rows = getTableRows(task.type);

  return (
    <div className="mt-8">
      {rows.map((row, rowIndex) => (
        <div key={`table-row-${rowIndex}`} className={rowIndex > 0 ? "mt-4 flex" : "mb-12 flex"}>
          <div className="flex h-[20px] w-[148px] items-center">
            {row.icon}
            {row.label && (
              <Paragraph className="ml-3 text-[14px] font-[500] text-grey-700">
                {row.label}
              </Paragraph>
            )}
          </div>
          <div className="w-[332px] border-l-4 border-grey-100 pl-4">
            <Paragraph className="text-sm text-grey-700">{row.description}</Paragraph>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ModalDescriptionTable;
