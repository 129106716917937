import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

const chatExchangeFormSchema = z.object({
  content: z.string().nonempty(),
});

export type ChatExchangeFormInputs = z.input<typeof chatExchangeFormSchema>;
export type ChatExchangeFormOutputs = z.output<typeof chatExchangeFormSchema>;

type Params = {
  disabled?: boolean;
};

const useChatExchangeForm = (params?: Params) =>
  useForm<ChatExchangeFormInputs>({
    resolver: zodResolver(chatExchangeFormSchema),
    defaultValues: {
      content: "",
    } satisfies ChatExchangeFormInputs,
    ...params,
  });

export type UseChatExchangeFormReturn = ReturnType<typeof useChatExchangeForm>;

export default useChatExchangeForm;
