// This module provides facilities for creating API client modules for the Highbeam API
// which are independent of the HighbeamApi class and don't require the useHighbeamApi hook.
// This is so we can move towards a more modular API client architecture and code split
// different API modules across the app.

import HighbeamBaseApi from "api/HighbeamBaseApi";
import env from "env";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { AuthMode, getJwtState } from "state/auth/highbeamApi";

enum Backend {
  V1 = "V1",
  V2 = "V2",
}

const getRequestOrigin = (backend: Backend) => {
  switch (backend) {
    case Backend.V1:
      return env.HIGHBEAM_API_ORIGIN_V1;
    case Backend.V2:
      return env.HIGHBEAM_API_ORIGIN_V2;
  }
};

const useRequestOrigin = (backend: Backend) => useMemo(() => getRequestOrigin(backend), [backend]);

const useGetJwt = (authMode: AuthMode = "authenticated") => useRecoilValue(getJwtState(authMode));

export type ApiBuilder<TApi> = (api: HighbeamBaseApi) => TApi;

type UseBackendApiOptions = {
  authMode?: AuthMode;
};

const useBackendApi = <TApi>(
  backend: Backend,
  builder: ApiBuilder<TApi>,
  options: UseBackendApiOptions = {}
) => {
  const requestOrigin = useRequestOrigin(backend);
  const getJwt = useGetJwt(options.authMode);
  const baseApi = useMemo(
    () => new HighbeamBaseApi(requestOrigin, getJwt),
    [requestOrigin, getJwt]
  );

  return useMemo(() => builder(baseApi), [baseApi, builder]);
};

export const useBackendV1Api = <TApi>(builder: ApiBuilder<TApi>, options?: UseBackendApiOptions) =>
  useBackendApi(Backend.V1, builder, options);

export const useBackendV2Api = <TApi>(builder: ApiBuilder<TApi>, options?: UseBackendApiOptions) =>
  useBackendApi(Backend.V2, builder, options);
