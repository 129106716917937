import { useIsBusinessActive } from "modules/business/queries/useBusiness";
import { useReferralReceivedByBusiness } from "modules/user-referrals/queries/useReferral";
import useUser from "modules/user/queries/useUser";
import useIsAllowedToNavigateToCapitalRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToCapitalRoutes";

// NB(alex):
//  Co-locating these together intentionally.
//  We want newly onboarded users to be able to sign up for capital, but Shopify bans apps with capital offerings from their app store (anti-competitive bs, probably).
//  We need Shopify connections for AI so this is our way of threading the needle:
//  1. If a user has a referral link at all, we allow them to view the capital tab.
//  2. If a user has a `shopify.com` email address, we never let them view capital.
//  Slack thread with more context: https://highbeamco.slack.com/archives/C03R625F068/p1743694984273579?thread_ts=1743693903.438729&cid=C03R625F068

const useIsShopifyEmail = () => {
  const user = useUser();
  return user.emailAddress.includes("shopify.com");
};

const useIsCapitalApplicationReferral = () => {
  const referral = useReferralReceivedByBusiness();
  const isShopifyEmail = useIsShopifyEmail();
  return referral?.landingPage === "CapitalApplication" && !isShopifyEmail;
};

const useShouldShowCapitalRoutes = () => {
  const hasReferral = useReferralReceivedByBusiness();
  const isShopifyEmail = useIsShopifyEmail();
  const isAllowedToNavigateToCapitalRoutes = useIsAllowedToNavigateToCapitalRoutes();
  const isBusinessActive = useIsBusinessActive();
  return !isShopifyEmail && isAllowedToNavigateToCapitalRoutes && (hasReferral || isBusinessActive);
};

export { useIsShopifyEmail, useIsCapitalApplicationReferral, useShouldShowCapitalRoutes };
