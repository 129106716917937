import { Clock } from "@phosphor-icons/react";
import { ComponentProps, ComponentRef, FC, forwardRef } from "react";
import { SetOptional } from "type-fest";
import Pill from "ui/data-display/Pill";
import DotsLoader from "ui/feedback/DotsLoader";
import { Heading3, Heading4 } from "ui/typography";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

const containerPaddingClasses = "px-4 pb-32";

const OnboardingHeading = forwardRef<ComponentRef<typeof Heading3>, PropsWithChildrenAndClassName>(
  ({ children, className }, ref) => {
    return (
      <Heading3 className={cn("text-lg font-bold", className)} ref={ref}>
        {children}
      </Heading3>
    );
  }
);

const OnboardingEstimatedTimePill: FC<SetOptional<ComponentProps<typeof Pill>, "color">> = ({
  children,
  className,
  ...props
}) => {
  return (
    <Pill
      color="grey-100"
      iconLeft={({ sizeClassName }) => <Clock className={sizeClassName} />}
      className={cn("mb-1 align-middle", className)}
      {...props}
    >
      {children}
    </Pill>
  );
};

const OnboardingSubheading: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return (
    <Heading4 className={cn("mt-2 text-md font-regular text-grey-600", className)}>
      {children}
    </Heading4>
  );
};

const OnboardingFooter: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return (
    <div
      className={cn(
        "flex flex-col-reverse gap-x-4 gap-y-6 @md:flex-row @md:items-center @md:justify-end",
        className
      )}
    >
      {children}
    </div>
  );
};

const OnboardingDotsLoader: FC<SetOptional<ComponentProps<typeof DotsLoader>, "dots">> = ({
  className,
  ...props
}) => {
  return <DotsLoader dots={3} className={cn("mx-auto my-64", className)} {...props} />;
};

export {
  containerPaddingClasses,
  OnboardingHeading,
  OnboardingEstimatedTimePill,
  OnboardingSubheading,
  OnboardingFooter,
  OnboardingDotsLoader,
};
