import { useReceivedPaymentInfoFlexpaneContext } from "dialogs/ReceivedPaymentInfoFlexpane/context/ReceivedPaymentInfoFlexpaneProvider";
import { useBankAccountByUnitAccountId } from "modules/bank-accounts/queries/useBankAccount";
import { useNavigate } from "react-router-dom";
import Button from "ui/inputs/Button";
import Helper from "ui/inputs/Helper";
import Flexpane from "ui/overlay/Flexpane";

import styles from "./ReceivedPaymentInfoFlexpaneFundAccountSection.module.scss";

const ReceivedPaymentInfoFlexpaneFundAccountSection = () => {
  const navigate = useNavigate();
  const { receivedPayment } = useReceivedPaymentInfoFlexpaneContext();
  const bankAccount = useBankAccountByUnitAccountId(receivedPayment.relationships.account.data.id);
  const needsFunding =
    receivedPayment.attributes.returnReason === "InsufficientFunds" &&
    (bankAccount?.availableBalance || 0) < receivedPayment.attributes.amount;

  return (
    <Flexpane.Section>
      {needsFunding && (
        <>
          <Helper iconVariant="info">
            This debit failed because {bankAccount?.name} had an insufficient balance. Please fund
            the account and retry the debit. You have until 2PM ET today to retry
          </Helper>
          <div className={styles.buttonContainer}>
            <Button
              variant="tertiary"
              size="sm"
              onClick={() => {
                navigate("/transfer-money", { state: { toAccountGuid: bankAccount?.guid } });
              }}
            >
              Fund account
            </Button>
          </div>
        </>
      )}
    </Flexpane.Section>
  );
};

export default ReceivedPaymentInfoFlexpaneFundAccountSection;
