import { useMutation } from "@tanstack/react-query";
import { useRefreshAllCapitalAccountsQueries } from "modules/capital-accounts/queries/useCapitalAccounts";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import { LINE_OF_CREDIT_AGREEMENT_QUERY_KEY } from "modules/line-of-credit-agreement/queries/useLineOfCreditAgreementQueryOptions";
import useMfa from "modules/mfa/useMfa";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { bankingDay } from "utils/date";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

type Params = {
  capitalAccountGuid: string;
};

const useAcceptCreditOfferMutation = ({ capitalAccountGuid }: Params) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const userGuid = useUserGuid();
  const refreshCapitalAccountsQueries = useRefreshAllCapitalAccountsQueries();
  const refreshLineAgreement = useRefreshQuery([
    LINE_OF_CREDIT_AGREEMENT_QUERY_KEY,
    businessGuid,
    capitalAccountGuid,
  ]);

  const { mfa } = useMfa();

  return useMutation({
    mutationFn: async () => {
      await mfa();
      await highbeamApi.lineOfCredit.acceptLineOfCreditOffer(
        businessGuid,
        capitalAccountGuid,
        userGuid
      );
      await highbeamApi.lineOfCredit.updateLineOfCreditUserActionsMetadata(
        businessGuid,
        capitalAccountGuid,
        {
          agreementSignedAt: bankingDay().toISOString(),
        }
      );
      await refreshCapitalAccountsQueries();
    },
    onError: () => {
      notify("info", "Failed to accept the credit offer. Please try again.");
    },
    onSuccess: async () => {
      await refreshLineAgreement();
    },
  });
};

export default useAcceptCreditOfferMutation;
