import useTaskApi from "modules/chat/api/useTaskApi";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import makeQueryHooksV2, {
  ExtractParamsWithOptionalDefaults,
} from "utils/react-query/makeQueryHooksV2";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

type Params = {
  businessGuid: string;
};

const aiTasksQueryHooks = makeQueryHooksV2({
  useQueryFnParams: () => {
    const taskApi = useTaskApi();
    return { taskApi };
  },
  useDefaultParams: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  makeQueryKey: (params: Params) => {
    return ["aiTasks", params] as const;
  },
  makeQueryFn: ({ taskApi }, { businessGuid }) => {
    return () => taskApi.listByBusiness(businessGuid);
  },
});

type ParamsWithOptionalDefaults = ExtractParamsWithOptionalDefaults<typeof aiTasksQueryHooks>;

const useAiTasks = (params: ParamsWithOptionalDefaults = {}) => {
  return aiTasksQueryHooks.useData({ params });
};

const useRefreshAiTasks = (params: ParamsWithOptionalDefaults = {}) => {
  return useRefreshQuery(aiTasksQueryHooks.useQueryKey(params));
};

export { aiTasksQueryHooks, useAiTasks, useRefreshAiTasks };
