import { Lightning, DotsThreeCircle } from "@phosphor-icons/react";
import { FC } from "react";
import { AiTaskStatus } from "reps/ai-tasks/AiTaskRep";
import Pill, { type PillColor } from "ui/data-display/Pill";
import { IconRenderFunction } from "utils/react-helpers/IconRenderFunction";

type PillProps = {
  color: PillColor;
  text: string;
  icon?: IconRenderFunction;
};

type Props = {
  status: AiTaskStatus;
  className: string;
};

const AiTaskPillComponent: FC<Props> = ({ status, className }) => {
  const getPillProps = (): PillProps => {
    switch (status) {
      case "Active":
        return {
          color: "green-100" as const,
          text: "On",
          icon: ({ sizeClassName }: { sizeClassName: string }) => (
            <Lightning className={sizeClassName} />
          ),
        };
      case "Inactive":
        return {
          color: "grey-100" as const,
          text: "Off",
          icon: undefined,
        };
      case "PendingEdits":
        return {
          color: "grey-100" as const,
          text: "Pending edits",
          icon: ({ sizeClassName }: { sizeClassName: string }) => (
            <DotsThreeCircle className={sizeClassName} />
          ),
        };
      case "SetupInProgress":
        return {
          color: "grey-100" as const,
          text: "Setting up",
          icon: ({ sizeClassName }: { sizeClassName: string }) => (
            <DotsThreeCircle className={sizeClassName} />
          ),
        };
      default:
        return {
          color: "grey-100" as const,
          text: status,
          icon: ({ sizeClassName }: { sizeClassName: string }) => (
            <DotsThreeCircle className={sizeClassName} />
          ),
        };
    }
  };

  const pillProps = getPillProps();

  return (
    <Pill color={pillProps.color} className={className} iconLeft={pillProps.icon}>
      {pillProps.text}
    </Pill>
  );
};

export default AiTaskPillComponent;
