import { Account, Transaction } from "@highbeam/unit-node-sdk";
import { debounce } from "debounce";
import useBankAccountApi from "modules/bank-accounts/api/useBankAccountApi";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { useUnitApiOrThrow } from "modules/unit-co-customer-token/queries/useUnitApi";
import { useCallback, useEffect } from "react";
import BankAccountRep from "reps/BankAccountRep";
import { getHighbeamAccountsForUnitAccounts } from "utils/account";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { useHighbeamTransactions } from "utils/customHooks/useHighbeamTransactions";
import { startOfBankingDay } from "utils/date";
import { debounceMs } from "utils/debounce";
import { convertToHighbeamTransaction, getPaymentMetadataByGuid } from "utils/transactions";

export const usePayeeTransactions = (payeeGuid: string) => {
  const {
    transactions,
    setTransactions,
    isLoading,
    setIsLoading,
    searchQuery,
    setSearchQuery,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
  } = useHighbeamTransactions();

  const highbeamApi = useHighbeamApi();
  const bankAccountApi = useBankAccountApi();
  const unitApi = useUnitApiOrThrow();
  const businessGuid = useBusinessGuid();

  // TODO: Move transaction data to Recoil.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateTransactions = useCallback(
    debounce(
      (searchQuery: string, payeeGuid: string, fromDate: Date | null, toDate: Date | null) => {
        (async () => {
          const from = fromDate
            ? startOfBankingDay(fromDate, { keepLocalTime: true }).format()
            : undefined;
          const to = toDate
            ? startOfBankingDay(toDate, { keepLocalTime: true }).add(1, "day").format()
            : undefined;
          const { data, included: relatedResources } = await unitApi.transactions.list({
            include: "account",
            query: searchQuery || undefined,
            tags: { recipientGuid: payeeGuid },
            ...(from ? { since: from } : {}),
            ...(to ? { until: to } : {}),
          });

          const relatedAccounts = await getHighbeamAccountsForUnitAccounts(
            bankAccountApi,
            relatedResources as Account[]
          );
          const relatedAccountsByUnitId = new Map<string, BankAccountRep.Complete>(
            relatedAccounts.map((account) => [account.unitCoDepositAccountId, account])
          );
          const paymentMetadataByGuid = await getPaymentMetadataByGuid(
            businessGuid,
            data,
            highbeamApi
          );
          const transactions = data.map((transaction: Transaction) =>
            convertToHighbeamTransaction(
              transaction,
              relatedAccountsByUnitId,
              paymentMetadataByGuid[transaction.attributes.tags?.paymentMetadataGuid ?? ""]
            )
          );
          setTransactions(transactions);
          setIsLoading(false);
        })();
      },
      debounceMs
    ),
    []
  );

  const refreshTransactions = () => {
    setIsLoading(true);
    updateTransactions(searchQuery, payeeGuid, fromDate, toDate);
  };

  // TODO: Move transaction data to Recoil.
  useEffect(() => {
    refreshTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, payeeGuid, fromDate, toDate]);

  return {
    transactions,
    isLoading,
    searchQuery,
    setSearchQuery,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    refreshTransactions,
  };
};
