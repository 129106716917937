export type HighbeamAccountTypeName = "DepositAccount" | "HighYield";

namespace BankAccountRep {
  export enum Status {
    OPEN = "OPEN",
    CLOSED = "CLOSED",
    FROZEN = "FROZEN",
  }
  export enum PartnerBank {
    BLUE_RIDGE = "Blue Ridge Bank, NA ",
    THREAD = "Thread Bank, N.A ",
  }

  export interface Creation {
    readonly businessGuid: string;
    readonly name: string;
  }

  export interface HighbeamAccountType {
    name: HighbeamAccountTypeName;
    includeInTotalBalance: boolean;
    includeInSendMoney: boolean;
    showDetailsToUser: boolean;
    supportsDebitCards: boolean;
    supportsExternalTransfers: boolean;
  }

  export interface DepositProduct {
    readonly name: string;
    readonly interestBps: number;
    readonly checkClearingDays: number;
    readonly sameDayAch: boolean;
  }

  export interface Complete {
    readonly guid: string;
    readonly businessGuid: string;
    readonly name: string;
    readonly status: Status;
    readonly isPrimary: boolean;
    readonly availableBalance: number;
    readonly routingNumber: string;
    readonly accountNumber: string;
    readonly unitCoDepositAccountId: string;
    readonly type: string;
    readonly highbeamType: HighbeamAccountType;
    readonly depositProduct: DepositProduct;
    readonly threadAccount: boolean;
    readonly blueRidgeAccount: boolean;
  }

  export interface Update {
    readonly name?: string;
    readonly isPrimary?: boolean;
  }

  export interface MigrateToThreadUpdate {
    readonly accountNamePostfix?: string;
    readonly businessGuid: string;
    readonly copyAllAccounts?: boolean;
  }
}

export default BankAccountRep;
