import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHasPermission from "utils/permissions/useHasPermission";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import useIntelligenceStatusApi from "../api/useIntelligenceStatusApi";

type Params = {
  businessGuid: string;
};

const intelligenceStatusQueryHooks = makeQueryHooksV2({
  useQueryFnParams: () => {
    const intelligenceStatusApi = useIntelligenceStatusApi();
    return { intelligenceStatusApi };
  },
  useDefaultParams: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  makeQueryKey: (params: Params) => {
    return ["intelligenceStatus", params];
  },
  makeQueryFn: ({ intelligenceStatusApi }, { businessGuid }) => {
    return () => {
      return intelligenceStatusApi.get(businessGuid);
    };
  },
});

const useHasIntelligenceStatusPermission = () => {
  return useHasPermission("intelligenceStatus:read");
};

const useRefreshIntelligenceStatusQuery = () => {
  return useRefreshQuery(intelligenceStatusQueryHooks.useQueryKey({}));
};

export {
  intelligenceStatusQueryHooks,
  useHasIntelligenceStatusPermission,
  useRefreshIntelligenceStatusQuery,
};
