import { Check } from "@phosphor-icons/react";
import classNames from "classnames";
import { FC, useState } from "react";
import Pill from "ui/data-display/Pill";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import TextArea, { TextAreaProps } from "ui/inputs/TextArea";

const pillClasses = "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10";

type Props = Omit<TextAreaProps, "value" | "onChange"> & {
  value: string;
  onChange: (value: string) => void;
  isSaving: boolean;
  isLoading: boolean;
  saveNotes: (value: string) => Promise<void>;
};

const TransactionFlexpaneNotes: FC<Props> = ({
  isSaving,
  isLoading,
  onBlur: onBlurProp,
  saveNotes,
  ...textAreaProps
}) => {
  const [showSaved, setShowSaved] = useState(false);

  const onBlur: TextAreaProps["onBlur"] = async (e) => {
    await saveNotes(textAreaProps.value);

    // Optional onBlur prop, just in case we want to specific some kind of additional onBlur behavior
    onBlurProp && (await onBlurProp(e));

    // Triggers the success pill
    setShowSaved(true);
    setTimeout(() => setShowSaved(false), 1500);
  };

  return (
    <div className="relative">
      {isSaving && (
        <Pill
          color="purple-500"
          className={pillClasses}
          iconLeft={({ sizeClassName }) => <AnimatedSpinner className={sizeClassName} />}
        >
          Saving note
        </Pill>
      )}
      {isLoading && (
        <Pill
          color="purple-500"
          className={pillClasses}
          iconLeft={({ sizeClassName }) => <AnimatedSpinner className={sizeClassName} />}
        >
          Loading note
        </Pill>
      )}
      {showSaved && (
        <Pill
          color="green-500"
          className={pillClasses}
          iconLeft={({ sizeClassName }) => <Check className={sizeClassName} />}
        >
          Note saved
        </Pill>
      )}
      <TextArea
        placeholder="Add a note about this transaction"
        maxLength={10000}
        onBlur={onBlur}
        {...textAreaProps}
      >
        <TextArea.Label>Note</TextArea.Label>
        <TextArea.Input
          className={classNames((isLoading || isSaving || showSaved) && "bg-grey-50 opacity-40")}
        />
      </TextArea>
    </div>
  );
};

export default TransactionFlexpaneNotes;
