import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

const chatConfigSettingsFormSchema = z.object({
  details: z.string(),
});

export type ChatConfigSettingsFormInputs = z.infer<typeof chatConfigSettingsFormSchema>;

type ChatConfigSettingsFormParams = {
  defaultValues: ChatConfigSettingsFormInputs;
};

const useChatConfigSettingsForm = (params: ChatConfigSettingsFormParams) =>
  useForm<ChatConfigSettingsFormInputs>({
    ...params,
    resolver: zodResolver(chatConfigSettingsFormSchema),
  });

export default useChatConfigSettingsForm;
