import useChatUserApi from "modules/chat/api/useChatUserApi";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import makeQueryHooks from "utils/react-query/makeQueryHooks";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

export const chatUsersQueryHooks = makeQueryHooks({
  name: "chatUsers",
  useQueryVariables: () => {
    const businessGuid = useBusinessGuid();
    const userGuid = useUserGuid();
    return { businessGuid, userGuid };
  },
  useQueryFnMaker: ({ businessGuid, userGuid }) => {
    const chatUserApi = useChatUserApi();

    return () => {
      return chatUserApi.search(businessGuid, userGuid);
    };
  },
});

export const useChatUsers = () => {
  return chatUsersQueryHooks.useData({});
};

export const useCurrentChatUserQuery = () => {
  const userGuid = useUserGuid();

  // TODO(alex) HB-5859
  const businessGuid = useBusinessGuid();

  return chatUsersQueryHooks.useQuery({
    select: (chatUsers) => {
      return chatUsers?.find(
        (chatUser) =>
          (chatUser.type === "BusinessMember" || chatUser.type === "Highbeam") &&
          chatUser.userGuid === userGuid
      );
    },
    enabled: Boolean(businessGuid),
  });
};

export const useCurrentChatUser = () => {
  const chatUsers = useChatUsers();
  const userGuid = useUserGuid();
  return chatUsers?.find(
    (chatUser) =>
      (chatUser.type === "BusinessMember" || chatUser.type === "Highbeam") &&
      chatUser.userGuid === userGuid
  );
};

export const useCurrentChatUserOrThrow = () => {
  const currentChatUser = useCurrentChatUser();
  if (!currentChatUser) throw new RequiredButNotFoundError("Current chat user not found.");
  return currentChatUser;
};

export const useChatUserQuery = (chatUserId: string) => {
  return chatUsersQueryHooks.useQuery({
    select: (chatUsers) => chatUsers.find((user) => user.id === chatUserId),
  });
};
