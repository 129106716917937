import { useMutation } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useMfa from "modules/mfa/useMfa";
import { notify } from "ui/feedback/Toast";
import uploadFile from "utils/uploadFile";

import useCapitalDrawdownApprovalApi from "../api/useCapitalDrawdownApprovalApi";

type CreateDrawdownApprovalVariables = {
  capitalAccountGuid: string;
  amount: number;
  idempotencyKey: string;
  bankAccountGuid: string;
  note: string;
  invoice?: File;
};

const useCreateDrawdownApprovalRequestMutation = () => {
  const { mfa } = useMfa();
  const capitalDrawdownApprovalApi = useCapitalDrawdownApprovalApi();
  const businessGuid = useBusinessGuid();

  return useMutation({
    mutationFn: async ({
      capitalAccountGuid,
      amount,
      idempotencyKey,
      bankAccountGuid,
      note,
      invoice,
    }: CreateDrawdownApprovalVariables) => {
      await mfa();
      const createDrawdownApproval = await capitalDrawdownApprovalApi.create(
        businessGuid,
        capitalAccountGuid,
        {
          amount,
          idempotencyKey,
          bankAccountGuid,
          note,
        }
      );
      if (invoice) {
        const { guid } = createDrawdownApproval;
        const { attachmentUploadUrl } = await capitalDrawdownApprovalApi.uploadUrl(
          guid,
          businessGuid
        );
        const uploadResponse = await uploadFile(invoice, attachmentUploadUrl);
        if (uploadResponse) {
          return createDrawdownApproval;
        }
      } else {
        return createDrawdownApproval;
      }
      return null;
    },
    onError: () => {
      notify("warning", "There was an issue creating drawdown request. Please try again.");
    },
  });
};

export default useCreateDrawdownApprovalRequestMutation;
