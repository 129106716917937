import { AccountEndOfDay } from "@highbeam/unit-node-sdk";
import { startOfBankingDay } from "utils/date";
import { DEFAULT_RELATIVE_TIMEFRAME, timeframeToNumDays } from "utils/timeframe";

import formatDateForXAxis from "./formatDateForXAxis";

const getHistoryWithTodayForSingleAccount = (
  accountEndOfDay: AccountEndOfDay[],
  bankAccountTotalBalance: number,
  timeframe = DEFAULT_RELATIVE_TIMEFRAME
) => {
  const history = accountEndOfDay
    .map((point) => {
      const referenceDateInstance = startOfBankingDay(point.attributes.date);
      return {
        // The formatted date for the x-axis.
        date: formatDateForXAxis(referenceDateInstance),
        amount: point.attributes.available,
        // A dayjs instance of the date, which is used for padding the history.
        // We need a full date because the formatted date may strip out year information.
        referenceDateInstance,
      };
    })
    .reverse();

  if (history.length === 0) {
    history.push({
      date: formatDateForXAxis(startOfBankingDay()),
      amount: 0,
      referenceDateInstance: startOfBankingDay(),
    });
  }
  const numPaddedDays = Math.max(timeframeToNumDays(timeframe) - history.length - 1, 0);
  const paddedHistory = [
    ...[...Array(numPaddedDays)].map((_, i) => {
      const referenceDateInstance = history[0].referenceDateInstance.subtract(
        numPaddedDays - i,
        "d"
      );
      return {
        date: formatDateForXAxis(referenceDateInstance),
        amount: 0,
        referenceDateInstance,
      };
    }),
    ...history,
  ];
  const endReferenceDateInstance = history[history.length - 1].referenceDateInstance.add(1, "d");
  const historyWithToday = [
    ...paddedHistory,
    {
      date: formatDateForXAxis(endReferenceDateInstance),
      amount: bankAccountTotalBalance,
      referenceDateInstance: endReferenceDateInstance,
    },
  ];
  return historyWithToday;
};

export default getHistoryWithTodayForSingleAccount;
