import { DotsThreeVertical } from "@phosphor-icons/react";
import useMfa from "modules/mfa/useMfa";
import InvitationDetailsModal, {
  InvitationDetails,
} from "modules/user-invitations/dialogs/InvitationDetailsModal";
import EditUserRoleModal from "modules/users-table-data/dialogs/EditUserRoleModal";
import { EditUserRoleParams } from "modules/users-table-data/dialogs/EditUserRoleModal/EditUserRoleForm";
import EditUserSuccessfulModal, {
  EditUserSuccessfulParams,
} from "modules/users-table-data/dialogs/EditUserSuccessfulModal";
import RemoveBusinessMemberModal from "modules/users-table-data/dialogs/RemoveBusinessMemberModal";
import {
  BusinessMemberUsersTableDatum,
  useUsersTableDataQuery,
} from "modules/users-table-data/queries/useUsersTableData";
import { useState } from "react";
import Pill from "ui/data-display/Pill";
import Button from "ui/inputs/Button";
import Menu from "ui/overlay/Menu";
import Table, { TableColumnAlignment } from "ui/table/Table";
import Text from "ui/typography/Text";
import useModalV2 from "utils/dialog/useModalV2";
import useIsAllowedToCancelUserInvitations from "utils/permissions/useIsAllowedToCancelUserInvitations";
import useIsAllowedToChangeOrganizationOwner from "utils/permissions/useIsAllowedToChangeOrganizationOwner";
import useIsAllowedToEditUserRoles from "utils/permissions/useIsAllowedToEditUserRoles";
import useIsAllowedToRemoveUsers from "utils/permissions/useIsAllowedToRemoveUsers";

import { useChangeOrganizationOwnerModal } from "../ChangeOrganizationOwnerModal/state/changeOrganizationOwnerModalState";
import RemoveInvitationModal, { RemoveInvitationModalState } from "../RemoveInvitationModal";

import styles from "./UsersTable.module.scss";

const UsersTable = () => {
  const isAllowedToCancelUserInvitations = useIsAllowedToCancelUserInvitations();

  const { data: members, isPending } = useUsersTableDataQuery();

  const editUserRoleModal = useModalV2<EditUserRoleParams>();

  const editUserSuccessfulModal = useModalV2<EditUserSuccessfulParams>();

  const { open: openChangeOrganizationOwnerModal } = useChangeOrganizationOwnerModal();
  const isAllowedToUpdateUserRoles = useIsAllowedToEditUserRoles();
  const isAllowedToChangeOrganizationOwner = useIsAllowedToChangeOrganizationOwner();
  const isAllowedToRemoveUsers = useIsAllowedToRemoveUsers();

  const invitationDetailsModal = useModalV2<InvitationDetails>();

  const [removeInvitationModalState, setRemoveInvitationModalState] =
    useState<RemoveInvitationModalState | null>(null);

  const removeBusinessMemberModal = useModalV2<{ businessMember: BusinessMemberUsersTableDatum }>();

  const { mfa } = useMfa();

  return (
    <>
      {editUserRoleModal.isOpen && (
        <EditUserRoleModal
          {...editUserRoleModal.params}
          onClose={editUserRoleModal.close}
          onSuccess={({ userRole }) => {
            editUserSuccessfulModal.open({
              roleName: userRole.name,
              displayName: editUserRoleModal.params.fullName,
            });
          }}
        />
      )}

      {editUserSuccessfulModal.isOpen && (
        <EditUserSuccessfulModal
          {...editUserSuccessfulModal.params}
          onClose={editUserSuccessfulModal.close}
        />
      )}

      {removeInvitationModalState && (
        <RemoveInvitationModal
          onClose={() => setRemoveInvitationModalState(null)}
          {...removeInvitationModalState}
        />
      )}

      {removeBusinessMemberModal.isOpen && (
        <RemoveBusinessMemberModal
          businessMember={removeBusinessMemberModal.params.businessMember}
          onClose={removeBusinessMemberModal.close}
        />
      )}

      {invitationDetailsModal.isOpen && (
        <InvitationDetailsModal
          invitationDetails={invitationDetailsModal.params}
          onClose={invitationDetailsModal.close}
        />
      )}

      <Table
        columns={[
          {
            title: "User",
            cellRender: (member) => (
              <div className={styles.user}>
                <Text size={14} weight="medium" className={styles.name}>
                  {member.displayName}
                  {member.isCurrentUser && " (You)"}
                </Text>
                {member.isInvited && <Pill color="grey-100">Invited</Pill>}
                {!member.isInvited && !member.isOnboarded && (
                  <Pill color="grey-100">Onboarding</Pill>
                )}
              </div>
            ),
          },
          {
            title: "Role",
            cellRender: (member) => (
              <Text size={14} weight="medium">
                {member.userRoleName}
              </Text>
            ),
          },
          {
            title: "Email address",
            cellRender: (member) => (
              <Text size={14} weight="medium">
                {member.emailAddress}
              </Text>
            ),
          },
          {
            title: "Actions",
            align: TableColumnAlignment.RIGHT,
            cellClassName: styles.actions,
            cellRender: (member) => {
              const currentOwner = members?.find(
                (member) => "isOrganizationOwner" in member && member.isOrganizationOwner
              ) as BusinessMemberUsersTableDatum | undefined;

              // Unable to use `Boolean(...)` cast or else typescript error is thrown in implementation
              /* eslint-disable no-implicit-coercion */

              // Invited member items
              const showInvitationDetailsItem = !!member.isInvited;
              const showCancelInvitationItem =
                !!member.isInvited && isAllowedToCancelUserInvitations;

              // Business member items

              const memberIsAdmin =
                !member.isInvited &&
                member.memberships.some((membership) => membership.userRole.type === "Admin");
              const memberIsAdminAndCurrentUser = memberIsAdmin && member.isCurrentUser;

              const showChangeRoleItem =
                !member.isInvited &&
                member.isOnboarded &&
                !member.isOrganizationOwner &&
                isAllowedToUpdateUserRoles;
              const showTransferOwnershipItem =
                !member.isInvited &&
                member.isOnboarded &&
                isAllowedToChangeOrganizationOwner &&
                !member.isOrganizationOwner &&
                memberIsAdmin &&
                currentOwner;
              const showRemoveUserItem =
                !member.isInvited &&
                isAllowedToRemoveUsers &&
                !member.isOrganizationOwner &&
                !memberIsAdminAndCurrentUser;

              /* eslint-enable no-implicit-coercion */

              const hasItems =
                showInvitationDetailsItem ||
                showCancelInvitationItem ||
                showChangeRoleItem ||
                showTransferOwnershipItem ||
                showRemoveUserItem;

              if (!hasItems) {
                return null;
              }

              return (
                <Menu
                  prepend="Select action"
                  button={
                    <Button variant="tertiary" paddingVariant="square" size="xs">
                      <DotsThreeVertical size={16} />
                    </Button>
                  }
                >
                  {showInvitationDetailsItem && (
                    <Menu.Item
                      onClick={() => {
                        invitationDetailsModal.open({
                          initials: member.initials,
                          displayName: member.displayName,
                          slug: member.slug,
                        });
                      }}
                    >
                      Show invitation
                    </Menu.Item>
                  )}

                  {showCancelInvitationItem && (
                    <Menu.Item
                      variant="danger"
                      onClick={() => {
                        setRemoveInvitationModalState({
                          userInvitationGuid: member.guid,
                          displayName: member.displayName,
                        });
                      }}
                    >
                      Cancel invitation
                    </Menu.Item>
                  )}

                  {showChangeRoleItem && (
                    <Menu.Item
                      onClick={() => {
                        editUserRoleModal.open({
                          userGuid: member.userGuid,
                          initials: member.initials,
                          fullName: member.fullName || "",
                          userRoleName: member.userRoleName,
                          userRoleGuid: member.userRoleGuids[0],
                        });
                      }}
                    >
                      Change role
                    </Menu.Item>
                  )}

                  {showTransferOwnershipItem && (
                    <Menu.Item
                      onClick={() => {
                        openChangeOrganizationOwnerModal({
                          currentOwner: {
                            initials: currentOwner.initials,
                            userGuid: currentOwner.userGuid,
                            fullName: currentOwner.fullName || "",
                            userRoleName: currentOwner.userRoleName,
                          },
                          nextOwner: {
                            initials: member.initials,
                            userGuid: member.userGuid,
                            fullName: member.fullName || "",
                            userRoleName: member.userRoleName,
                          },
                        });
                      }}
                    >
                      Transfer ownership
                    </Menu.Item>
                  )}

                  {showRemoveUserItem && (
                    <Menu.Item
                      variant="danger"
                      onClick={async () => {
                        await mfa();
                        removeBusinessMemberModal.open({ businessMember: member });
                      }}
                    >
                      Remove user
                    </Menu.Item>
                  )}
                </Menu>
              );
            },
          },
        ]}
        isLoading={isPending}
        data={members}
        rowKey="guid"
      />
    </>
  );
};

export default UsersTable;
