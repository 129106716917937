import { useUnitApiOrThrow } from "modules/unit-co-customer-token/queries/useUnitApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

import { UNIT_CO_RECURRING_PAYMENTS_QUERY_KEY } from "./useUnitCoRecurringPaymentsQueryOptions";

const useUnitCoRecurringPaymentQueryOptions = (recurringPaymentId: string) => {
  const unitApi = useUnitApiOrThrow();

  return useQueryOptions({
    queryKey: [UNIT_CO_RECURRING_PAYMENTS_QUERY_KEY, { recurringPaymentId }],
    queryFn: async () => {
      const response = await unitApi.recurringPayments.get(recurringPaymentId);
      return response.data;
    },
  });
};

export default useUnitCoRecurringPaymentQueryOptions;
