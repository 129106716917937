import { FloppyDisk, NotePencil, X } from "@phosphor-icons/react";
import dayjs from "dayjs";
import React, { RefObject } from "react";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import TextArea from "ui/inputs/TextArea";
import VirtualButton from "ui/inputs/VirtualButton";
import { Paragraph } from "ui/typography";
import useMountEffect from "utils/customHooks/useMountEffect";
import { useResizeObserver } from "utils/customHooks/useResizeObserver";
import cn from "utils/tailwind/cn";

type Props = {
  createdAt: string;
  editable: boolean;
  edited: boolean;
  isPending: boolean;
  textAreaRef: RefObject<HTMLTextAreaElement>;
  value: string;
  handleStartEdit: () => void;
  handleEndEdit: () => void;
  handleEdit: (value: string) => void;
  handleSave: () => void;
};

const ChatKnowledgeBaseItem: React.FC<Props> = ({
  createdAt,
  editable,
  edited,
  isPending,
  textAreaRef,
  value,
  handleStartEdit,
  handleEndEdit,
  handleEdit,
  handleSave,
}) => {
  const resize = () => {
    textAreaRef.current!.style.height = "auto";
    textAreaRef.current!.style.height = `${textAreaRef.current!.scrollHeight}px`;
  };

  useMountEffect(resize);
  useResizeObserver(textAreaRef, resize);

  return (
    <div className="flex flex-row gap-2 rounded-lg bg-grey-100 p-2">
      <div className="flex flex-grow flex-col gap-1">
        <Paragraph className="p-1 text-xs text-grey-900">
          {dayjs(createdAt).format("MMM D, YYYY [at] h:mm A z")}
        </Paragraph>
        <TextArea
          disabled={!editable}
          value={value}
          onChange={(value) => {
            handleEdit(value);
            resize();
          }}
          className="flex-grow"
        >
          <TextArea.Input
            ref={textAreaRef}
            className={cn(
              "border-none p-1 font-monospace text-sm text-grey-600",
              edited && "bg-purple-50"
            )}
          />
        </TextArea>
      </div>
      <div className="ml-auto flex flex-col">
        {isPending && <AnimatedSpinner className="flex-none fill-grey-600" size={24} />}
        {editable && !isPending && (
          <VirtualButton onClick={handleEndEdit}>
            <X className="fill-grey-700 hover:fill-grey-600" size={24} />
          </VirtualButton>
        )}
        {!editable && !isPending && (
          <VirtualButton onClick={handleStartEdit}>
            <NotePencil className="fill-grey-700 hover:fill-grey-600" size={24} />
          </VirtualButton>
        )}
        {editable && !isPending && (
          <VirtualButton onClick={handleSave}>
            <FloppyDisk className="fill-green-700 hover:fill-green-600" size={24} />
          </VirtualButton>
        )}
      </div>
    </div>
  );
};

export default ChatKnowledgeBaseItem;
