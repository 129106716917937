import DebugContainer from "modules/chat/components/DebugContainer";
import DebugIndicator from "modules/chat/components/DebugIndicator";
import DebugPre from "modules/chat/components/DebugPre";
import DebugValue from "modules/chat/components/DebugValue";
import { chatExchangeDebugInfoQueryHooks } from "modules/chat/queries/useChatExchangeDebugInfo";
import { Dispatch, FC, PropsWithChildren, SetStateAction, useState } from "react";
import Button from "ui/inputs/Button";
import { Paragraph } from "ui/typography";
import createComponentContext from "utils/react-helpers/createComponentContext";

const [AiChatSuperuserDebugInfoProvider, useAiChatSuperuserDebugInfoContext] =
  createComponentContext<{
    exchangeId: string;
    showDebugInfo: boolean;
    setShowDebugInfo: Dispatch<SetStateAction<boolean>>;
  }>("AiChatSuperuserDebugInfo");

const AiChatSuperuserDebugInfoButton = () => {
  const { showDebugInfo, setShowDebugInfo } = useAiChatSuperuserDebugInfoContext();
  return (
    <Button onClick={() => setShowDebugInfo((prev) => !prev)} size="xs" className="text-purple-300">
      {showDebugInfo ? "[SU] Hide debug info" : "[SU] Show debug info"}
    </Button>
  );
};

const AiChatSuperuserDebugInfoContent = () => {
  const { exchangeId, showDebugInfo } = useAiChatSuperuserDebugInfoContext();

  const {
    data: messageProfile,
    isLoading,
    error,
  } = chatExchangeDebugInfoQueryHooks.useQuery({
    exchangeId,
    enabled: showDebugInfo,
  });

  return (
    <>
      {error instanceof Error && <div>Error: {error.message}</div>}
      {isLoading && <div>Loading...</div>}
      {showDebugInfo && messageProfile && (
        <div className="self-center">
          {messageProfile.profile.totalTime && (
            <DebugContainer>
              <div className="flex flex-row gap-2">
                <DebugIndicator.ByThreshold
                  value={messageProfile.profile.totalTime}
                  happy={20_000}
                  meh={40_000}
                />
                <Paragraph>
                  Total: <DebugValue value={messageProfile.profile.totalTime} decimals={0} />
                </Paragraph>
              </div>
            </DebugContainer>
          )}
          {messageProfile.profile.exception && (
            <DebugContainer className="bg-red-100">
              <DebugPre>{messageProfile.profile.exception}</DebugPre>
            </DebugContainer>
          )}
          {messageProfile.profile.promptEnrichmentTime &&
            messageProfile.profile.promptEnrichment && (
              <DebugContainer>
                {messageProfile.profile.promptEnrichmentTime && (
                  <div className="flex flex-row gap-2">
                    <DebugIndicator.ByThreshold
                      value={messageProfile.profile.promptEnrichmentTime}
                      happy={2500}
                      meh={5000}
                    />
                    <Paragraph>
                      Prompt enrichment:{" "}
                      <DebugValue
                        value={messageProfile.profile.promptEnrichmentTime}
                        decimals={1}
                      />
                    </Paragraph>
                  </div>
                )}
                {messageProfile.profile.promptEnrichment && (
                  <DebugPre>
                    {JSON.stringify(messageProfile.profile.promptEnrichment, null, 2)}
                  </DebugPre>
                )}
              </DebugContainer>
            )}
          {(messageProfile.profile.referenceQuestionsTime ||
            messageProfile.profile.referenceQuestionScores ||
            messageProfile.profile.referenceQuestions) && (
            <DebugContainer>
              {messageProfile.profile.referenceQuestionsTime && (
                <div className="flex flex-row gap-2">
                  <DebugIndicator.ByThreshold
                    value={messageProfile.profile.referenceQuestionsTime}
                    happy={2500}
                    meh={5000}
                  />
                  <Paragraph>
                    Reference questions:{" "}
                    <DebugValue
                      value={messageProfile.profile.referenceQuestionsTime}
                      decimals={1}
                    />
                  </Paragraph>
                </div>
              )}
              {messageProfile.profile.referenceQuestionScores && (
                <DebugPre>
                  {JSON.stringify(messageProfile.profile.referenceQuestionScores, null, 2)}
                </DebugPre>
              )}
              {messageProfile.profile.referenceQuestions && (
                <DebugPre>
                  {JSON.stringify(messageProfile.profile.referenceQuestions, null, 2)}
                </DebugPre>
              )}
            </DebugContainer>
          )}
          {messageProfile.profile.loadingMessageTime && (
            <DebugContainer>
              <div className="flex flex-row gap-2">
                <DebugIndicator.ByThreshold
                  value={messageProfile.profile.loadingMessageTime}
                  happy={1500}
                  meh={3000}
                />
                <Paragraph>
                  Loading message:{" "}
                  <DebugValue value={messageProfile.profile.loadingMessageTime} decimals={1} />
                </Paragraph>
              </div>
            </DebugContainer>
          )}
          {messageProfile.profile.answerContentTime && (
            <DebugContainer>
              <div className="flex flex-row gap-2">
                <DebugIndicator.ByThreshold
                  value={messageProfile.profile.answerContentTime}
                  happy={8000}
                  meh={16_000}
                />
                <Paragraph>
                  Answer content:{" "}
                  <DebugValue value={messageProfile.profile.answerContentTime} decimals={1} />
                </Paragraph>
              </div>
            </DebugContainer>
          )}
          {messageProfile.profile.suggestedFollowUpsTime && (
            <DebugContainer>
              <div className="flex flex-row gap-2">
                <DebugIndicator.ByThreshold
                  value={messageProfile.profile.suggestedFollowUpsTime}
                  happy={4000}
                  meh={8000}
                />
                <Paragraph>
                  Suggested follow-ups:{" "}
                  <DebugValue value={messageProfile.profile.suggestedFollowUpsTime} decimals={1} />
                </Paragraph>
              </div>
            </DebugContainer>
          )}
        </div>
      )}
    </>
  );
};

type Props = PropsWithChildren & {
  exchangeId: string;
};

const AiChatSuperuserDebugInfo: FC<Props> = ({ children, exchangeId }) => {
  const [showDebugInfo, setShowDebugInfo] = useState(false);

  return (
    <AiChatSuperuserDebugInfoProvider value={{ exchangeId, showDebugInfo, setShowDebugInfo }}>
      {children}
    </AiChatSuperuserDebugInfoProvider>
  );
};

const AiChatSuperuserDebugInfoIfVisible: FC<PropsWithChildren> = ({ children }) => {
  const { showDebugInfo } = useAiChatSuperuserDebugInfoContext();
  return showDebugInfo ? <>{children}</> : null;
};

export default Object.assign(AiChatSuperuserDebugInfo, {
  Button: AiChatSuperuserDebugInfoButton,
  Content: AiChatSuperuserDebugInfoContent,
  IfVisible: AiChatSuperuserDebugInfoIfVisible,
});
