import { CSSProperties, ReactElement, ReactNode, cloneElement } from "react";
import CountBadge from "ui/data-display/CountBadge";
import { TabConfigBadge } from "utils/tabs/useTabState";
import cn from "utils/tailwind/cn";

import TabsBadge from "../TabsBadge";

type Props = {
  children: ReactNode;
  className?: string;
  count?: number;
  badge?: TabConfigBadge;
  icon?: ReactElement<{ size?: number }>;
  isActive?: boolean;
  style?: CSSProperties;
};

const TabsTab = ({ children, className, count, badge, icon, isActive, style }: Props) => {
  return (
    <div
      className={cn(
        "flex items-center justify-center gap-x-2 self-center whitespace-nowrap p-4",
        "h-12", // Set height explicitly because the badges are slightly taller than the text.
        className
      )}
      style={style}
    >
      {icon && (
        <span className="flex h-4 items-center justify-center">
          {cloneElement(icon, { size: icon.props.size ?? 16 })}
        </span>
      )}

      <div
        className={cn(
          "text-sm leading-4",
          icon && "sr-only @sm:not-sr-only",
          isActive && "font-bold"
        )}
      >
        {children}
      </div>

      {count !== undefined && !badge && (
        <CountBadge count={count} backgroundColor={isActive ? "grey-700" : "grey-500"} />
      )}

      {Boolean(badge) && <TabsBadge badge={badge} isActive={isActive} />}
    </div>
  );
};

export default TabsTab;
