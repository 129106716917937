import { FC, ReactNode } from "react";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

import {
  ChartColor,
  getChartBackgroundColorClass,
  getChartTextColorClass,
} from "./chart-color-utils";

// NB(alex): Making a custom chat component because I couldn't figure out how to customize the label/value text styles with echarts.

type ChartLegendItemProps = {
  color: ChartColor;
  children: ReactNode;
};

const ChartLegendItem: FC<ChartLegendItemProps> = ({ color, children }) => {
  return (
    <div className="flex items-center gap-x-2 pr-4">
      <span className={cn("h-0.5 w-3", getChartBackgroundColorClass(color))} />
      <span className={getChartTextColorClass(color)}>{children}</span>
    </div>
  );
};

const ChartLegend: FC<PropsWithChildrenAndClassName> = ({ className, children }) => {
  return (
    <div className={cn("mb-4 flex w-full items-center justify-center text-xs", className)}>
      {children}
    </div>
  );
};

export default Object.assign(ChartLegend, {
  Item: ChartLegendItem,
});
