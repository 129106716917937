import { Warning } from "@phosphor-icons/react";
import DashboardPage from "layouts/DashboardPage";
import getCapitalAccountAvailablePercentage from "modules/capital-accounts/utils/getCapitalAccountAvailablePercentage";
import isHighCreditUtilization from "modules/line-of-credit/utils/isHighCreditUtilization";
import { FC } from "react";
import { Link } from "react-router-dom";
import CapitalAccountDetailsRep from "reps/CapitalAccountDetailsRep";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import Banner from "ui/data-display/Banner";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import MoneyAmountFraction from "ui/data-display/money/MoneyAmountFraction";
import BarChart from "ui/data-visualization/BarChart";
import RightArrowButton from "ui/navigation/RightArrowButton";
import Tooltip from "ui/overlay/Tooltip";
import { Heading4 } from "ui/typography";

import CapitalAvailableTooltipContent from "./CapitalAvailableTooltipContent";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
  showViewCardsButton?: boolean;
  showAvailableOutstandingBalance?: boolean;
};

const CapitalAvailableSection: FC<Props> = ({
  capitalAccountSummary,
  showViewCardsButton,
  showAvailableOutstandingBalance = false,
}) => {
  const barPercent = getCapitalAccountAvailablePercentage(capitalAccountSummary);
  const showHighCreditUtilizationWarning = isHighCreditUtilization(capitalAccountSummary);

  const isCapitalAccountTerminated =
    capitalAccountSummary.state === CapitalAccountRep.State.Terminated;

  return (
    <>
      <div className="mb-1 flex justify-between">
        <DashboardPage.Section.HeaderHeading className="font-medium">
          {isCapitalAccountTerminated ? "Repaid" : "Available"}
        </DashboardPage.Section.HeaderHeading>
        {showViewCardsButton && (
          <Link to="/cards?tab=Highbeam">
            <RightArrowButton>View your cards</RightArrowButton>
          </Link>
        )}
      </div>

      <MoneyAmountFraction
        className="mb-6 mt-2"
        variant="headline"
        numeratorInCents={capitalAccountSummary.available}
        denominatorInCents={capitalAccountSummary.details.limit}
      />

      <Tooltip>
        <Tooltip.Trigger asChild>
          <BarChart
            height={30}
            barHeight={12}
            backgroundVariant="grey-striped"
            border="grey-light"
            roundedCornerVariant="rounded-full"
          >
            <BarChart.Bar
              widthPercentage={barPercent}
              minWidthPercentageWhenZero={1}
              color={
                showHighCreditUtilizationWarning
                  ? "yellow"
                  : isCapitalAccountTerminated
                    ? "grey-solid"
                    : "green"
              }
            />
          </BarChart>
        </Tooltip.Trigger>

        <Tooltip.Content className="p-4">
          <CapitalAvailableTooltipContent capitalAccountSummary={capitalAccountSummary} />
        </Tooltip.Content>
      </Tooltip>

      {showAvailableOutstandingBalance && (
        <div className="flex gap-x-6 pt-4">
          <div className="flex gap-x-2">
            <BarChart
              height={16}
              backgroundVariant="transparent"
              className="w-4"
              roundedCornerVariant="rounded-none"
            >
              <BarChart.Bar
                color={
                  showHighCreditUtilizationWarning
                    ? "yellow"
                    : isCapitalAccountTerminated
                      ? "grey-solid"
                      : "green"
                }
                widthPercentage={100}
              />
            </BarChart>

            <div>
              <Heading4 className="text-xs font-regular">
                {isCapitalAccountTerminated ? "Repaid" : "Available"}
              </Heading4>
              <MoneyAmount size={12} weight="medium" cents={capitalAccountSummary.available} />
            </div>
          </div>
          <div className="flex gap-x-2">
            <BarChart
              height={16}
              backgroundVariant="transparent"
              className="w-4"
              roundedCornerVariant="rounded-none"
            >
              <BarChart.Bar color="grey-striped" widthPercentage={100} />
            </BarChart>

            <div>
              <Heading4 className="text-xs font-regular">Outstanding</Heading4>
              <MoneyAmount
                size={12}
                weight="medium"
                cents={capitalAccountSummary.details.limit - capitalAccountSummary.available}
              />
            </div>
          </div>
        </div>
      )}

      {capitalAccountSummary.lender === CapitalAccountRep.CapitalLender.Highbeam &&
        capitalAccountSummary.details.lineType !== CapitalAccountDetailsRep.LineType.Advance &&
        showHighCreditUtilizationWarning && (
          <div className="mt-9">
            <Banner
              color="yellow"
              icon={<Warning size={24} />}
              title="High capital usage"
              paragraph={
                "High capital usage is not recommended and can result in the reduction of your capital limit. Consider repaying to reduce your usage."
              }
            />
          </div>
        )}
    </>
  );
};

export default CapitalAvailableSection;
