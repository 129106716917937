import { PercentageChatElementRep } from "modules/chat/reps/ChatElementRep";
import React from "react";

type Props = {
  element: PercentageChatElementRep;
};

const PercentageChatElement: React.FC<Props> = ({ element }) => {
  return <span>{(element.value * 100).toFixed(element.decimalPlaces)}%</span>;
};

export default PercentageChatElement;
