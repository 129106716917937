import { FC, PropsWithChildren, ReactNode } from "react";
import ItemWithCopyTextTooltip from "ui/overlay/ItemWithCopyTextTooltip";
import { Span } from "ui/typography";

const BlockInfoKey: FC<PropsWithChildren> = ({ children }) => {
  return <Span className="text-grey-500">{children}</Span>;
};

type BlockInfoValueProps = {
  textToCopy?: string;
  children?: ReactNode;
};

const BlockInfoValue: FC<BlockInfoValueProps> = ({ children, textToCopy }) => {
  const text = <Span className="font-medium text-grey-900">{children}</Span>;

  if (textToCopy) {
    return <ItemWithCopyTextTooltip textToCopy={textToCopy}>{text}</ItemWithCopyTextTooltip>;
  } else {
    return text;
  }
};

const BlockInfo: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="ml-[11px] grid grid-cols-[auto_1fr] gap-4 border-l-2 border-purple-500 pl-5">
      {children}
    </div>
  );
};

export default Object.assign(BlockInfo, {
  Key: BlockInfoKey,
  Value: BlockInfoValue,
});
