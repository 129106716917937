import { ChatTeardropText, TrendUp } from "@phosphor-icons/react";
import { ComponentPropsWithoutRef, ComponentRef, forwardRef } from "react";
import HighbeamMarkdown from "ui/data-display/HighbeamMarkdown";
import VirtualButton from "ui/inputs/VirtualButton";
import EmbeddedDrawer from "ui/overlay/EmbeddedDrawer";
import { Heading4 } from "ui/typography";
import cn from "utils/tailwind/cn";

type ChatSuggestionsEmbeddedDrawerHeaderProps = {
  numSuggestions: number;
};

const ChatSuggestionsEmbeddedDrawerHeader = forwardRef<
  ComponentRef<typeof EmbeddedDrawer.Header>,
  ChatSuggestionsEmbeddedDrawerHeaderProps
>(({ numSuggestions }, forwardedRef) => {
  return (
    <EmbeddedDrawer.Header className="justify-between border-b-0 py-3" ref={forwardedRef}>
      <Heading4 className="flex items-center gap-x-2.5 text-sm font-regular text-grey-600">
        <TrendUp /> {numSuggestions} suggested prompts
      </Heading4>

      <EmbeddedDrawer.HeaderCaret />
    </EmbeddedDrawer.Header>
  );
});

const ChatSuggestionsEmbeddedDrawerBody = EmbeddedDrawer.Body;

type ChatSuggestionsEmbeddedDrawerSuggestionButtonProps = Pick<
  ComponentPropsWithoutRef<typeof VirtualButton>,
  "onClick" | "className"
> & {
  markdown: string;
};

const ChatSuggestionsEmbeddedDrawerSuggestionButton = forwardRef<
  ComponentRef<typeof VirtualButton>,
  ChatSuggestionsEmbeddedDrawerSuggestionButtonProps
>(({ className, markdown, ...props }, forwardedRef) => {
  return (
    <VirtualButton
      className={cn(
        "flex cursor-pointer items-center gap-x-2.5 px-4 py-2 text-grey-500 hover:bg-grey-50",
        className
      )}
      {...props}
      ref={forwardedRef}
    >
      <ChatTeardropText size={20} className="-scale-x-100" />
      <HighbeamMarkdown className="flex-1 text-sm text-grey-600">{markdown}</HighbeamMarkdown>
    </VirtualButton>
  );
});

type Props = ComponentPropsWithoutRef<typeof EmbeddedDrawer>;

const ChatSuggestionsEmbeddedDrawer = forwardRef<ComponentRef<typeof EmbeddedDrawer>, Props>(
  ({ children, ...embeddedDrawerProps }, forwardedRef) => {
    return (
      <EmbeddedDrawer {...embeddedDrawerProps} ref={forwardedRef}>
        {children}
      </EmbeddedDrawer>
    );
  }
);

export default Object.assign(ChatSuggestionsEmbeddedDrawer, {
  Header: ChatSuggestionsEmbeddedDrawerHeader,
  Body: ChatSuggestionsEmbeddedDrawerBody,
  SuggestionButton: ChatSuggestionsEmbeddedDrawerSuggestionButton,
});
