import { FC, ReactElement, ReactNode } from "react";
import { Paragraph } from "ui/typography";

import Tooltip, { TooltipContentPaddingVariant } from "./Tooltip";

type Props = {
  tooltip?: ReactNode;
  children: ReactElement;
  asChild?: boolean;
  contentPaddingVariant?: TooltipContentPaddingVariant;
  contentSideOffset?: number;
};

const ItemWithTooltip: FC<Props> = ({
  tooltip,
  children,
  asChild = true,
  contentPaddingVariant,
  contentSideOffset,
}) => {
  const tooltipEnabled = Boolean(tooltip);

  if (!tooltipEnabled) {
    return children;
  }

  return (
    <Tooltip>
      <Tooltip.Trigger asChild={asChild}>{children}</Tooltip.Trigger>

      <Tooltip.Content paddingVariant={contentPaddingVariant} sideOffset={contentSideOffset}>
        {typeof tooltip === "string" ? <Paragraph>{tooltip}</Paragraph> : tooltip}
      </Tooltip.Content>
    </Tooltip>
  );
};

export default ItemWithTooltip;
