import useUpdateKnowledgeBaseItemMutation from "modules/chat/mutations/useUpdateKnowledgeBaseItemMutation";
import { KnowledgeBaseItemRep } from "modules/chat/reps/KnowledgeBaseItemRep";
import React, { useCallback, useRef, useState } from "react";
import { notify } from "ui/feedback/Toast";

import ChatKnowledgeBaseItem from "./ChatKnowledgeBaseItem";

type Props = {
  item: KnowledgeBaseItemRep;
};

const ExistingChatKnowledgeBaseItem: React.FC<Props> = ({ item }) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  const [editable, setEditable] = useState(false);
  const [value, setValue] = useState(item.value);

  const { mutate, isPending } = useUpdateKnowledgeBaseItemMutation(item.id, {
    onSuccess: () => {
      setEditable(false);
    },
    onError: () => {
      notify("error", "Something went wrong! Please try again.");
    },
  });

  const handleStartEdit = () => {
    setEditable(true);
    setTimeout(() => {
      ref.current?.focus();
    });
  };

  const handleEndEdit = () => {
    setValue(item.value);
    setEditable(false);
  };

  const handleEdit = (value: string) => {
    setValue(value);
  };

  const handleSave = useCallback(() => {
    if (isPending) return;
    mutate({ value });
  }, [isPending, mutate, value]);

  return (
    <ChatKnowledgeBaseItem
      createdAt={item.createdAt}
      editable={editable}
      edited={value !== item.value}
      isPending={isPending}
      textAreaRef={ref}
      value={value}
      handleStartEdit={handleStartEdit}
      handleEndEdit={handleEndEdit}
      handleEdit={handleEdit}
      handleSave={handleSave}
    />
  );
};

export default ExistingChatKnowledgeBaseItem;
