import CheckDepositImagesFlexpaneSection, {
  checkDepositImagesTabs,
} from "modules/unit-co-check-deposits/components/CheckDepositImagesFlexpaneSection";
import { FC } from "react";
import Flexpane from "ui/overlay/Flexpane";
import useSearchParamOption from "utils/search-params/useSearchParamOption";
import getObjectKeys from "utils/ts/getObjectKeys";

import CheckDepositFlexpaneAmountSection from "./CheckDepositFlexpaneAmountSection";
import CheckDepositFlexpaneToFromSection from "./CheckDepositFlexpaneToFromSection";
import CheckDepositFlexpaneTransactionDispute from "./CheckDepositFlexpaneTransactionDispute";

type Props = {
  checkDepositId?: string;
  onClose: () => void;
};

const CheckDepositFlexpane: FC<Props> = ({ checkDepositId, onClose }) => {
  const [activeTab, setActiveTab] = useSearchParamOption(
    "side",
    getObjectKeys(checkDepositImagesTabs),
    "front-image"
  );

  return (
    <Flexpane isOpen={Boolean(checkDepositId)} onClose={onClose}>
      {checkDepositId && (
        <>
          <Flexpane.Header onClose={onClose}>Payment info</Flexpane.Header>
          <CheckDepositFlexpaneToFromSection checkDepositId={checkDepositId} />
          <CheckDepositImagesFlexpaneSection
            checkDepositId={checkDepositId}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <CheckDepositFlexpaneAmountSection checkDepositId={checkDepositId} />

          <Flexpane.Section>
            <CheckDepositFlexpaneTransactionDispute
              checkDepositId={checkDepositId}
              onClose={onClose}
            />
          </Flexpane.Section>
        </>
      )}
    </Flexpane>
  );
};

export default CheckDepositFlexpane;
