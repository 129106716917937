import { ErrorBoundary } from "@sentry/react";
import ErrorText from "modules/error/components/ErrorText";
import { cloneElement, FC, ReactElement, ReactNode, Suspense } from "react";
import SectionLoading from "ui/feedback/SectionLoading";
import { Heading2, Heading4 } from "ui/typography";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

import styles from "./Section.module.scss";

type SectionContainerProps = {
  className?: string;
  children: ReactNode;
  suspenseFallback?: React.ReactNode;
};

export const SectionContainer: FC<SectionContainerProps> = ({
  className,
  children,
  suspenseFallback = <SectionLoading />,
}) => {
  return (
    <Suspense fallback={suspenseFallback}>
      <div
        className={cn("w-full max-w-[var(--hb-content-max-width)]", styles.container, className)}
      >
        {children}
      </div>
    </Suspense>
  );
};

const SectionHeaderEnd: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <div className={cn("ml-auto", className)}>{children}</div>;
};

type SectionHeaderProps = {
  children?: ReactNode;
  className?: string;
  heading?: ReactNode;
  subheading?: ReactNode;
  actions?: ReactNode;
};

export const SectionHeader: FC<SectionHeaderProps> = ({
  className,
  children,
  heading,
  subheading,
  actions,
}) => {
  return (
    <>
      <div className={cn("mb-6 flex items-center gap-x-2", subheading && "mb-2", className)}>
        {typeof heading === "string" ? (
          <SectionHeaderHeading>{heading}</SectionHeaderHeading>
        ) : (
          heading
        )}

        {children}

        {actions && <SectionHeaderEnd>{actions}</SectionHeaderEnd>}
      </div>

      {typeof subheading === "string" ? (
        <SectionHeaderSubheading>{subheading}</SectionHeaderSubheading>
      ) : (
        subheading
      )}
    </>
  );
};

const DEFAULT_ICON_SIZE = 24;

type SectionHeaderHeadingProps = {
  children: ReactNode;
  className?: string;
  icon?: ReactElement<{ size?: number }>;
  iconSize?: number;
};

export const SectionHeaderHeading: FC<SectionHeaderHeadingProps> = ({
  className,
  icon,
  iconSize = DEFAULT_ICON_SIZE,
  children,
}) => {
  return (
    <Heading2 className={cn("flex items-center gap-x-2 leading-8", className)}>
      {icon && cloneElement(icon, { size: iconSize })}
      {children}
    </Heading2>
  );
};

export const SectionHeaderSubheading: FC<PropsWithChildrenAndClassName> = ({
  className,
  children,
}) => {
  return (
    <Heading4 className={cn("text-sm font-regular text-grey-600", className)}>{children}</Heading4>
  );
};

type SectionBodyProps = {
  children: ReactNode;
  className?: string;
  suspenseFallback?: React.ReactNode;
};

export const SectionBody: FC<SectionBodyProps> = ({
  className,
  suspenseFallback = <SectionLoading />,
  children,
}) => {
  return (
    <div className={cn("mt-6 first-of-type:mt-0", className)}>
      <ErrorBoundary fallback={({ error }) => <ErrorText error={error} />}>
        <Suspense fallback={suspenseFallback}>{children}</Suspense>
      </ErrorBoundary>
    </div>
  );
};

const Section = Object.assign(SectionContainer, {
  HeaderEnd: SectionHeaderEnd,
  Header: SectionHeader,
  HeaderHeading: SectionHeaderHeading,
  HeaderSubheading: SectionHeaderSubheading,
  Body: SectionBody,
});

export default Section;
