import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { HighbeamApiError } from "utils/ajax";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

type Params = {
  businessGuid: string;
};

const plaidConnectionsQueryHooks = makeQueryHooksV2({
  useQueryFnParams: () => {
    const highbeamApi = useHighbeamApi();
    return { highbeamApi };
  },
  useDefaultParams: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  makeQueryKey: (params: Params) => {
    return ["plaidConnections", params];
  },
  makeQueryFn: ({ highbeamApi }, { businessGuid }) => {
    return async () => {
      try {
        return await highbeamApi.plaid.getPlaidConnectionsByBusiness(businessGuid);
      } catch (error) {
        if (error instanceof HighbeamApiError) {
          // Handles user role error. https://highbeamco.slack.com/archives/C05A92512J0/p1738075202885299
          // NB(alex): Could be nice if the backend gave us an error type, because the 403 might not be caused by an insufficient user role.
          if (error.statusCode === 403) {
            return [];
          }
        }
        throw error;
      }
    };
  },
});

export default plaidConnectionsQueryHooks;

// Hooks

export const usePlaidConnections = () => {
  return plaidConnectionsQueryHooks.useData({
    params: {},
  });
};

export const useHasPlaidConnections = () => {
  return usePlaidConnections().length > 0;
};

export const useRefreshPlaidConnectionsQueries = () => {
  const baseKey = plaidConnectionsQueryHooks.useQueryKey({})[0];
  return useRefreshQuery([baseKey]);
};
