import { DataSources } from "modules/chat/queries/useDataSources";
import ConnectedStoreIcon from "modules/connected-stores/components/ConnectedStoreIcon";
import PlaidInstitutionLogo from "modules/plaid/components/PlaidInstitutionLogo";
import { FC } from "react";
import CountBadge from "ui/data-display/CountBadge";
import { HighbeamLogo } from "ui/icons/logos/logos-square";
import PlatformAvatar, { PlatformAvatarVariant } from "ui/icons/PlatformAvatar";
import PlatformAvatarGroup from "ui/icons/PlatformAvatarGroup";
import { getRutterIcon } from "utils/rutter";
import cn from "utils/tailwind/cn";

const platformAvatarClasses = "bg-grey-50";

type Props = Partial<DataSources> & {
  className?: string;
  truncateAt?: number;
  interactive?: boolean;
  includeHighbeam?: boolean;
};

const DataSourcesPlatformAvatarGroup: FC<Props> = ({
  connectedStores = [],
  plaidAccounts = [],
  accountingConnections = [],
  className,
  truncateAt = 3,
  interactive = false,
  includeHighbeam = true,
}) => {
  const uniqueConnectedStoreTypes = Array.from(
    new Set(connectedStores.map((connectedStore) => connectedStore.type))
  );
  const uniquePlaidInstitutionIds = Array.from(
    new Set(plaidAccounts.map((plaidAccount) => plaidAccount.institutionId))
  );
  const uniqueAccountingPlatformNames = Array.from(
    new Set(accountingConnections.map((accountingConnection) => accountingConnection.platformName))
  );

  const totalLength =
    uniqueConnectedStoreTypes.length +
    uniquePlaidInstitutionIds.length +
    uniqueAccountingPlatformNames.length;

  const variant: PlatformAvatarVariant = interactive
    ? "small-grey-circle-interactive"
    : "small-grey-circle";

  const showHighbeamLogo = includeHighbeam && totalLength < truncateAt;

  return (
    <PlatformAvatarGroup
      className={cn("group", className)}
      truncateAt={truncateAt}
      variant={variant}
    >
      {showHighbeamLogo && (
        <PlatformAvatar variant={variant} className={platformAvatarClasses}>
          <HighbeamLogo />
        </PlatformAvatar>
      )}
      {uniqueConnectedStoreTypes.map((connectedStoreType) => (
        <PlatformAvatar
          key={connectedStoreType}
          variant={variant}
          className={platformAvatarClasses}
        >
          <ConnectedStoreIcon connectedStoreType={connectedStoreType} className="size-4" />
        </PlatformAvatar>
      ))}
      {uniquePlaidInstitutionIds.map((institutionId) => (
        <PlatformAvatar key={institutionId} variant={variant} className={platformAvatarClasses}>
          <PlaidInstitutionLogo institutionId={institutionId} size={16} className="size-4" />
        </PlatformAvatar>
      ))}
      {uniqueAccountingPlatformNames.map((platformName) => (
        <PlatformAvatar key={platformName} variant={variant} className={platformAvatarClasses}>
          <img src={getRutterIcon(platformName)} alt={`${platformName} logo`} />
        </PlatformAvatar>
      ))}

      {/* If we want to include the Highbeam logo and we know we're going to be
      truncating, we haven't rendered the logo yet, so we render it here to ensure
      it's included in the overflow count. */}
      {includeHighbeam && !showHighbeamLogo && totalLength >= truncateAt && (
        <PlatformAvatar variant={variant} className={platformAvatarClasses}>
          <HighbeamLogo />
        </PlatformAvatar>
      )}

      {totalLength === 0 && !includeHighbeam && <CountBadge count={0} backgroundColor="grey-100" />}
    </PlatformAvatarGroup>
  );
};

export default DataSourcesPlatformAvatarGroup;
