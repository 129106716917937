import { FC } from "react";
import { HighbeamAccountTypeName } from "reps/BankAccountRep";
import { HighbeamHighYieldLogo, HighbeamLogo, LogoSquareProps } from "ui/icons/logos/logos-square";
import variants from "utils/ts/variants";

type Props = LogoSquareProps & {
  highbeamTypeName: HighbeamAccountTypeName;
};

const HighbeamAccountIcon: FC<Props> = ({ highbeamTypeName, ...platformIconProps }) => {
  const PlatformIcon = variants(highbeamTypeName, {
    DepositAccount: HighbeamLogo,
    HighYield: HighbeamHighYieldLogo,
  });

  return <PlatformIcon {...platformIconProps} />;
};

export default HighbeamAccountIcon;
