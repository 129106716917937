import { CaretDown, CheckCircle } from "@phosphor-icons/react";
import isGettingStartedSetupGuideOpenState from "modules/action-items/state/gettingStartedSetupGuide";
import { useEffect, useRef, useState } from "react";
import { useSetRecoilState } from "recoil";
import Pill from "ui/data-display/Pill";
import Button from "ui/inputs/Button";
import ButtonLink from "ui/inputs/ButtonLink";
import VirtualButton from "ui/inputs/VirtualButton";
import { Heading4, Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

import { useFinishGetStartedActionItemMutation } from "../mutations/useFinishGetStartedActionItemMutation";
import { GetStartedActionItem } from "../queries/useGetStartedActionItems";

type Props = {
  number: number;
  actionItem: GetStartedActionItem;
  active: boolean;
  setActive: (active: number | undefined) => void;
};

const ActionItemRow: React.FC<Props> = ({ number, actionItem, active, setActive }) => {
  const [scrollHeight, setScrollHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (active) {
      const content = contentRef.current;
      if (content) {
        content.style.height = `${scrollHeight}px`;
      }
    } else {
      const content = contentRef.current;
      if (content) {
        setScrollHeight(content.scrollHeight);
        content.style.height = "0";
      }
    }
  }, [active, scrollHeight]);

  const onToggleContainer = () => {
    if (active) {
      setActive(undefined);
    } else {
      setActive(actionItem.order);
    }
  };

  const { mutateAsync: finishGetStartedActionItem } = useFinishGetStartedActionItemMutation();

  const onSkipAction = () => {
    finishGetStartedActionItem({
      step: actionItem.name,
      state: "Dismissed",
    });
  };

  const isSkipped = actionItem.state === "Dismissed";
  const isCompleted = actionItem.state === "Complete";
  const isSkippable = !isSkipped && !isCompleted;
  const setShowGetStartedSetupGuide = useSetRecoilState(isGettingStartedSetupGuideOpenState);

  return (
    <VirtualButton
      className={cn(
        "flex cursor-pointer gap-x-3 border-t border-t-grey-100 p-4 hover:bg-grey-50",
        active && "bg-grey-50"
      )}
      onClick={onToggleContainer}
    >
      {isCompleted ? (
        <CheckCircle weight="fill" className="size-6 text-green-400" />
      ) : (
        <div
          className={cn(
            "flex size-6 items-center justify-center rounded-full bg-grey-100 text-xs text-grey-500",
            active && "bg-purple-500 text-white"
          )}
        >
          {number}
        </div>
      )}

      <div className="flex w-72 flex-col">
        <div className="flex justify-between">
          <Heading4 className="mt-0.5 font-medium">
            {variants(actionItem.name, {
              ConnectStore: "Connect your store",
              CreateVirtualCard: "Create your first virtual card",
              ConnectBank: "Connect your other banks and cards",
              ReceiveStorePayouts: "Connect your store payouts",
            })}
          </Heading4>
          {isSkipped && <Pill color="orange-100">Skipped</Pill>}
        </div>

        <div
          ref={contentRef}
          className="transition-height h-0 overflow-hidden duration-200 ease-in-out"
        >
          <Paragraph className="text-sm text-grey-600">
            {variants(actionItem.name, {
              ConnectStore:
                "When Highbeam is connected to your store, we are able to provide you with better insights.",
              CreateVirtualCard:
                "Create up to 5 virtual cards to help separate your business expenses.",
              ConnectBank: "Easily transfer funds between Highbeam and other bank accounts.",
              ReceiveStorePayouts: "Set up automatic payouts to fund your Highbeam account.",
            })}
          </Paragraph>

          <div className="mt-3 flex gap-x-3">
            {!isCompleted &&
              variants(actionItem.name, {
                ConnectStore: (
                  <ButtonLink
                    variant="tertiary"
                    size="sm"
                    to="/settings/stores"
                    onClick={() => setShowGetStartedSetupGuide(false)}
                  >
                    Connect stores
                  </ButtonLink>
                ),
                CreateVirtualCard: (
                  <ButtonLink
                    variant="tertiary"
                    size="sm"
                    to="/cards"
                    onClick={() => setShowGetStartedSetupGuide(false)}
                    state={{ openVirtualCard: true }}
                  >
                    Create virtual card
                  </ButtonLink>
                ),
                ConnectBank: (
                  <ButtonLink
                    variant="tertiary"
                    size="sm"
                    to="/settings/banks-and-cards"
                    onClick={() => setShowGetStartedSetupGuide(false)}
                    state={{ fromStep: "ConnectBank" }}
                  >
                    Connect your accounts
                  </ButtonLink>
                ),
                ReceiveStorePayouts: (
                  <ButtonLink
                    variant="tertiary"
                    size="sm"
                    to="/accounts"
                    onClick={() => setShowGetStartedSetupGuide(false)}
                    state={{ fromStep: "ReceiveStorePayouts" }}
                  >
                    Set up payouts
                  </ButtonLink>
                ),
              })}

            {isSkippable && (
              <Button size="sm" onClick={onSkipAction}>
                Skip
              </Button>
            )}
          </div>
        </div>
      </div>

      <CaretDown className={cn("mt-1 size-4 text-grey-400", active && "rotate-180")} />
    </VirtualButton>
  );
};

export default ActionItemRow;
