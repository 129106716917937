import CapitalAccountRep from "reps/CapitalAccountRep";
import { SetFieldType } from "type-fest";

import capitalAccountsQueryHooks from "../queries/useCapitalAccounts";

export type ActiveCapitalAccount = SetFieldType<
  CapitalAccountRep.Complete,
  "state",
  CapitalAccountRep.State.Active
>;

export const checkIsActiveCapitalAccount = (
  capitalAccount: CapitalAccountRep.Complete
): capitalAccount is ActiveCapitalAccount => {
  return capitalAccount.state === CapitalAccountRep.State.Active;
};

export const useHasActiveCapitalAccountQuery = () => {
  return capitalAccountsQueryHooks.useQuery({
    params: {},
    select: (capitalAccounts) => capitalAccounts.some(checkIsActiveCapitalAccount),
  });
};

export const useHasActiveCapitalAccount = () => {
  return capitalAccountsQueryHooks.useData({
    params: {},
    select: (capitalAccounts) => capitalAccounts.some(checkIsActiveCapitalAccount),
  });
};

const useActiveCapitalAccounts = () => {
  return capitalAccountsQueryHooks.useData({
    params: {},
    select: (capitalAccounts) => capitalAccounts.filter(checkIsActiveCapitalAccount),
  });
};

export default useActiveCapitalAccounts;
