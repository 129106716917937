import { PlusCircle as PlusCircleIcon } from "@phosphor-icons/react";
import noResultsEmptyImg from "assets/empty-state.svg";
import MergedCounterpartiesModal, {
  MergedCounterpartiesModalParams,
} from "dialogs/insights/MergedCounterpartiesModal";
import UpdateCounterpartyAliasModal, {
  UpdateCounterpartyAliasModalParams,
} from "dialogs/insights/UpdateCounterpartyAliasModal";
import { useNormalizedAccountsAugmented } from "modules/insights/queries/useNormalizedAccountsAugmented";
import { SETTINGS_PLAID_LINK_MODAL_KEY } from "modules/plaid/components/PlaidConnectionsList";
import useCashFlowTableData from "pages/CashFlowPage/data/useCashFlowTableData";
import { useCashFlowContext } from "pages/CashFlowPage/providers/CashFlowProvider";
import { FC } from "react";
import EmptyState from "ui/data-display/EmptyState";
import NoResultsEmptyState from "ui/table/EmptyState";
import Table from "ui/table/Table";
import useModalV2 from "utils/dialog/useModalV2";

import CashFlowCategoryCell from "../CashFlowCategoryCell";
import CashFlowCounterpartyCell from "../CashFlowCounterpartyCell";

const CashFlowTableView: FC = () => {
  const { activeTab, search, showUncategorizedOnly } = useCashFlowContext();
  const data = useCashFlowTableData();
  const hasData = data.length > 0;
  const isFiltering = search.length > 0 || showUncategorizedOnly;

  const updateCounterpartyAliasModal = useModalV2<UpdateCounterpartyAliasModalParams>();

  const mergedCounterpartiesModal = useModalV2<MergedCounterpartiesModalParams>();

  const normalizedAccountsAugmented = useNormalizedAccountsAugmented({
    filters: {
      includeHighbeam: true,
    },
  });
  const hasNormalizedAccounts = normalizedAccountsAugmented.length > 0;

  return (
    <>
      {updateCounterpartyAliasModal.isOpen && (
        <UpdateCounterpartyAliasModal
          onClose={updateCounterpartyAliasModal.close}
          params={updateCounterpartyAliasModal.params}
        />
      )}

      {mergedCounterpartiesModal.isOpen && (
        <MergedCounterpartiesModal
          onClose={mergedCounterpartiesModal.close}
          params={mergedCounterpartiesModal.params}
        />
      )}

      {hasData && (
        <Table
          className="mb-96" // HACK(alex): The menu gets cut off by the containing table. This value needs to be slightly taller than the menu's max-height. I think this is a limitation of our Headless UI menu.
          data={data}
          rowKey={(datum) => datum.key}
          columns={[
            {
              key: "counterparty",
              title: activeTab === "money-in" ? "Source" : "Vendor",
              cellRender: (datum) => (
                <CashFlowCounterpartyCell
                  datum={datum}
                  onClickCounterpartyName={() => {
                    updateCounterpartyAliasModal.open({
                      counterparty: datum.counterparty,
                      direction:
                        datum.category.groupDirectionValue === "In" ||
                        (datum.category.groupDirectionValue === "Bidirectional" &&
                          Number(datum.amount.amount) > 0)
                          ? "money-in"
                          : "money-out",
                      originalCounterparties: datum.originalCounterparties,
                    });
                  }}
                  onClickMergedCounterparties={() => {
                    mergedCounterpartiesModal.open({
                      counterparty: datum.counterparty,
                      originalCounterparties: datum.originalCounterparties,
                    });
                  }}
                />
              ),
            },
            {
              key: "category",
              title: "Category",
              cellRender: (datum) => <CashFlowCategoryCell datum={datum} />,
            },
          ]}
        />
      )}

      {!hasData && (
        <>
          {isFiltering ? (
            <NoResultsEmptyState
              image={noResultsEmptyImg}
              primaryText="No vendors match your filters"
              secondaryText="Try adjusting your filters to find what you're looking for."
            />
          ) : (
            <EmptyState
              variant="card"
              body={
                <>
                  <EmptyState.PrimaryText>No vendors found</EmptyState.PrimaryText>
                  {!hasNormalizedAccounts && (
                    <EmptyState.SecondaryText>
                      After you connect your accounts or get started with Highbeam banking, your
                      vendors will show here.
                    </EmptyState.SecondaryText>
                  )}
                </>
              }
              footer={
                !hasNormalizedAccounts && (
                  <EmptyState.LinkCTA
                    to={`/settings/banks-and-cards?${SETTINGS_PLAID_LINK_MODAL_KEY}=true`}
                  >
                    <PlusCircleIcon />
                    Connect account
                  </EmptyState.LinkCTA>
                )
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default CashFlowTableView;
