import { ApiBuilder, useBackendV1Api } from "modules/highbeam-api/base";
import BusinessMemberSummaryRep from "reps/BusinessMemberSummaryRep";

type BusinessMemberSummaryApi = {
  listByUser: (userGuid: string) => Promise<BusinessMemberSummaryRep.Complete[]>;
};

const builder: ApiBuilder<BusinessMemberSummaryApi> = (api) => ({
  listByUser: async (userGuid) => {
    const queryParams = new URLSearchParams({ userGuid });
    const url = `/business-member-summaries?${queryParams}`;
    return (await api.get<BusinessMemberSummaryRep.Complete[]>(url))!;
  },
});

const useBusinessMemberSummaryApi = () => useBackendV1Api(builder);

export default useBusinessMemberSummaryApi;
