import OnboardingLayout from "layouts/OnboardingLayout";
import { connectedStoresQueryHooks } from "modules/connected-stores/queries/useConnectedStores";
import OnboardingConnectStoresCard from "modules/onboarding/components/onboarding-connections/OnboardingConnectStoresCard";
import HighbeamInsightsPage from "pages/HighbeamInsightsPage";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

/**
 * Highbeam supports an "Insights App" for Shopify, that only includes insights features.
 * See {@link useInsightsApp} for more.
 */
const InsightsAppRouter: React.FC = () => {
  const connectedStores = connectedStoresQueryHooks.useData({ params: {} });

  const numberOfConnections = connectedStores.length;
  const hasConnections = numberOfConnections > 0;

  return (
    <Routes>
      {hasConnections && <Route path="/insights" element={<HighbeamInsightsPage />} />}
      <Route
        path="/onboarding/connect-stores"
        element={
          <OnboardingLayout>
            <OnboardingConnectStoresCard allowSkip={false} to="/insights" />
          </OnboardingLayout>
        }
      />
      <Route
        path="*"
        element={
          <Navigate replace to={hasConnections ? "/insights" : "/onboarding/connect-stores"} />
        }
      />
    </Routes>
  );
};

export default InsightsAppRouter;
