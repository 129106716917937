import { useSuspenseQuery } from "@tanstack/react-query";
import { useIsBusinessActive } from "modules/business/queries/useBusiness";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import useQueryOptions from "utils/react-query/useQueryOptions";

// Token expires every 24 hours, we reset the token every 23 hours
const UNIT_CO_CUSTOMER_TOKEN_EXPIRATION = 23 * 60 * 60 * 1000;

const useUnitCoCustomerTokenQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const isBankingActive = useIsBusinessActive(); // Will rename hook to match soon.

  return useQueryOptions({
    queryKey: ["unitCoCustomerToken", { businessGuid, isBankingActive }],
    queryFn: async () => {
      // NB(alex): Types for `useBusinessGuid` are wrong since we enabled multi-biz.
      if (!businessGuid || !isBankingActive) {
        return null;
      }
      return (await highbeamApi.unitCoCustomerToken.create(businessGuid)).token;
    },
    staleTime: UNIT_CO_CUSTOMER_TOKEN_EXPIRATION / 2, // NB(alex): Dividing by 2 to see if this helps with the token refresh issue. https://linear.app/highbeam/issue/HB-4626/invalid-or-expired-bearer-token-401-error
    refetchOnWindowFocus: true,
  });
};

const useUnitCoCustomerToken = () => {
  const { data } = useSuspenseQuery(useUnitCoCustomerTokenQueryOptions());
  return data;
};

const useUnitCoCustomerTokenOrThrow = () => {
  const token = useUnitCoCustomerToken();
  if (!token) throw new RequiredButNotFoundError();
  return token;
};

export { useUnitCoCustomerTokenOrThrow };

export default useUnitCoCustomerToken;
