import { Dayjs } from "dayjs";
import { ApiBuilder, useBackendV1Api } from "modules/highbeam-api/base";
import CapitalTransactionRep from "reps/CapitalTransactionRep";

type CapitalTransactionsApi = {
  get: (
    businessGuid: string,
    capitalAccountGuid: string,
    sinceInclusive: Dayjs,
    untilInclusive: Dayjs
  ) => Promise<CapitalTransactionRep.Complete[]>;
  getCsv: (
    businessGuid: string,
    capitalAccountGuid: string,
    sinceInclusive: Dayjs,
    untilInclusive: Dayjs
  ) => Promise<string>;
};

const builder: ApiBuilder<CapitalTransactionsApi> = (api) => ({
  get: async (businessGuid, capitalAccountGuid, sinceInclusive, untilInclusive) => {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
      capitalAccountGuid: capitalAccountGuid,
      sinceInclusive: sinceInclusive.format("YYYY-MM-DD"),
      untilInclusive: untilInclusive.format("YYYY-MM-DD"),
    });
    const url = `/capital-transactions?${qp}`;
    return (await api.get<CapitalTransactionRep.Complete[]>(url))!;
  },
  getCsv: async (businessGuid, capitalAccountGuid, sinceInclusive, untilInclusive) => {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
      capitalAccountGuid: capitalAccountGuid,
      sinceInclusive: sinceInclusive.format("YYYY-MM-DD"),
      untilInclusive: untilInclusive.format("YYYY-MM-DD"),
    });
    const url = `/capital-transactions?${qp}`;
    return (await api.get<string>(url, {
      Accept: "text/csv",
    }))!;
  },
});

const useCapitalTransactionsApi = () => useBackendV1Api(builder);

export default useCapitalTransactionsApi;
