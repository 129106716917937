import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

import useBillStatsApi from "../api/useBillStatsApi";

import { BILL_SUMMARIES_ROOT_QUERY_KEY } from "./config";

const billStatsQueryHooks = makeQueryHooks({
  rootName: BILL_SUMMARIES_ROOT_QUERY_KEY,
  name: "bill-stats",
  useQueryVariables() {
    const businessGuid = useBusinessGuid();

    return { businessGuid };
  },
  useQueryFnMaker({ businessGuid }) {
    const billStatsApi = useBillStatsApi();

    return () => billStatsApi.getByBusiness(businessGuid);
  },
});

export default billStatsQueryHooks;
