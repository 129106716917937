import BankAccountRep from "reps/BankAccountRep";
import { Money } from "reps/Money";
import PlaidAccountRep from "reps/PlaidAccountRep";
import { SetFieldType } from "type-fest";
import { isNotNull } from "utils/array";
import { roundEpsilon } from "utils/math";

import { BalanceHistoryRep } from "../reps/BalanceHistoryRep";

import { augmentNormalizedAccount, NormalizedAccountAugmented } from "./augment-normalized-account";
import { sortBalanceHistoryAccounts } from "./sort-balance-history-accounts";

export type HistoricalDailyBalanceAugmented = Money & {
  amountInCents: number;
};

export type AccountBalanceHistoryAugmented = {
  normalizedAccount: NormalizedAccountAugmented;
  historicalDailyBalances: HistoricalDailyBalanceAugmented[];
};

export type BalanceHistoryAugmented = SetFieldType<
  BalanceHistoryRep,
  "accounts",
  AccountBalanceHistoryAugmented[]
>;

type AugmentBalanceHistoryParams = {
  balanceHistory: BalanceHistoryRep;
  bankAccounts: BankAccountRep.Complete[];
  connectedPlaidBankAccounts: PlaidAccountRep.Complete[];
};

export const augmentBalanceHistory = ({
  balanceHistory,
  bankAccounts,
  connectedPlaidBankAccounts,
}: AugmentBalanceHistoryParams): BalanceHistoryAugmented => {
  return {
    ...balanceHistory,
    accounts: balanceHistory.accounts
      .map((account) => {
        const normalizedAccountAugmented = augmentNormalizedAccount({
          normalizedAccount: account.normalizedAccount,
          bankAccounts,
          connectedPlaidBankAccounts,
        });
        if (!normalizedAccountAugmented) {
          return null;
        }
        return {
          normalizedAccount: normalizedAccountAugmented,
          historicalDailyBalances: account.historicalDailyBalances.map((money, index) => {
            // Hack to use the current account balance for today's balance.
            // IMPORTANT: This only works if the last entry is today's balance, so if we allow date ranges that don't end with today, this will break.
            const isLastEntry = index === account.historicalDailyBalances.length - 1;
            const availableBalance = normalizedAccountAugmented.rep.availableBalance;
            if (isLastEntry && availableBalance !== null) {
              return {
                ...money,
                amountInCents: availableBalance,
              };
            }

            const amountInDollarsNumber = Number(money.amount.replace(/[$,]/g, ""));
            const amountInCents = roundEpsilon(amountInDollarsNumber * 100, 0);

            return {
              ...money,
              amountInCents,
            };
          }),
        };
      })
      .filter(isNotNull) // NB(alex): We guarantee a matching highbeam bank account or connected plaid bank account, and filter out balance histories that do not match. https://highbeamco.slack.com/archives/C03SQ3K21QV/p1740613896643099?thread_ts=1740613689.611729&cid=C03SQ3K21QV
      .sort(sortBalanceHistoryAccounts),
  };
};
