import { captureMessage } from "@sentry/react";
import { useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useQueryOptions from "utils/react-query/useQueryOptions";

import useBankApplicationApi from "../api/useBankApplicationApi";

const useBankApplicationQueryOptions = () => {
  const bankApplicationApi = useBankApplicationApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: ["bankApplication", { businessGuid }],
    queryFn: async () => {
      const bankApplications = await bankApplicationApi.getByBusiness(businessGuid);

      if (bankApplications.length === 0) {
        return null;
      } else if (bankApplications.length === 1) {
        return bankApplications[0];
      } else {
        captureMessage(`Multiple bank applications found for business ${businessGuid}.`);
        return bankApplications[0];
      }
    },
  });
};

export default useBankApplicationQueryOptions;

// Hooks

export const useBankApplication = () => {
  return useSuspenseQuery(useBankApplicationQueryOptions()).data;
};

export const useHasFilledOutBankApplication = () => {
  const bankApplication = useBankApplication();
  return bankApplication && bankApplication.status !== "FormCreated";
};
