import { FC, ReactNode, useRef } from "react";
import { AiTaskRep } from "reps/ai-tasks/AiTaskRep";
import Button from "ui/inputs/Button";
import ModalV4, { ModalRef } from "ui/overlay/ModalV4";

export type BaseAiTaskModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  className?: string;
  children?: ReactNode;
  // Task specific props
  taskDescription?: ReactNode;
  task?: AiTaskRep;
};

export type ModalConfig = {
  title: string;
  confirmButtonText: string;
  confirmButtonVariant: "primary" | "secondary";
  confirmButtonClassName: string;
  cancelButtonText: string;
};

type Props = BaseAiTaskModalProps & {
  config: ModalConfig;
  isConfirmDisabled?: boolean;
  onConfirmClick: () => void;
};

const BaseAiTaskModal: FC<Props> = ({
  isOpen,
  onClose,
  title,
  config,
  onConfirm,
  onCancel,
  className,
  children,
  isConfirmDisabled = false,
  onConfirmClick,
}) => {
  const modalRef = useRef<ModalRef>(null);

  // Don't render if not open
  if (!isOpen) return null;

  return (
    <ModalV4 onClose={onClose} modalRef={modalRef} className={className}>
      <ModalV4.Header>{title || config.title}</ModalV4.Header>
      <ModalV4.Body>{children}</ModalV4.Body>
      <ModalV4.Footer>
        {config.confirmButtonText && (
          <Button
            variant={config.confirmButtonVariant}
            className={config.confirmButtonClassName}
            disabled={isConfirmDisabled}
            onClick={() => {
              onConfirmClick();
              onConfirm?.();
              modalRef.current?.closeModalWithAnimation();
            }}
          >
            {config.confirmButtonText}
          </Button>
        )}
        {config.cancelButtonText && (
          <ModalV4.CloseButton
            onClick={() => {
              onCancel?.();
              modalRef.current?.closeModalWithAnimation();
            }}
          >
            {config.cancelButtonText}
          </ModalV4.CloseButton>
        )}
      </ModalV4.Footer>
    </ModalV4>
  );
};

export default BaseAiTaskModal;
