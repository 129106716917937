import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";
import { createURLSearchParamsFromObject } from "utils/search-params/create-url-search-params";

import { IntelligenceStatusRep } from "../reps/IntelligenceStatusRep";

type IntelligenceStatusApi = {
  get: (businessGuid: string) => Promise<IntelligenceStatusRep>;
};

const builder: ApiBuilder<IntelligenceStatusApi> = (api) => ({
  get: async (businessGuid: string) => {
    const qp = createURLSearchParamsFromObject({ businessGuid });
    const url = `/intelligence/status?${qp}`;
    return (await api.get<IntelligenceStatusRep>(url))!;
  },
});

const useIntelligenceStatusApi = () => useBackendV2Api(builder);

export default useIntelligenceStatusApi;
