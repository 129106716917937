import { HandWaving } from "@phosphor-icons/react";
import DashboardPage from "layouts/DashboardPage";
import { useCurrentBusinessMember } from "modules/business-members/queries/businessMemberQueryHooks";
import { useIsBusinessActive } from "modules/business/queries/useBusiness";
import { Heading4 } from "ui/typography";

import AccountsOverviewPageHeaderActions from "../components/AccountsOverviewPageHeaderActions";

const AccountsOverviewPageHeader = () => {
  const businessMember = useCurrentBusinessMember();
  const isBusinessActive = useIsBusinessActive();

  return (
    <DashboardPage.Header actions={isBusinessActive && <AccountsOverviewPageHeaderActions />}>
      <Heading4 className="mr-2 flex items-center gap-x-3 self-end whitespace-nowrap text-md font-medium">
        {businessMember?.firstName ? (
          <>Welcome, {businessMember.firstName}</>
        ) : (
          <>
            <HandWaving size={24} weight="light" /> Welcome to Highbeam!
          </>
        )}
      </Heading4>
    </DashboardPage.Header>
  );
};

export default AccountsOverviewPageHeader;
