import { ArrowRight } from "@phosphor-icons/react";
import { AiChatAnalystAvatar } from "modules/chat/components/AiChatUserAvatar";
import useCreateChatChannelExchangeMutation from "modules/chat/mutations/useCreateChatChannelExchangeMutation";
import {
  chatChannelsQueryHooks,
  getMvpFirstChatChannel,
} from "modules/chat/queries/useChatChannels";
import { useCurrentChatUserOrThrow } from "modules/chat/queries/useChatUsers";
import { SuggestedQuestionRep } from "modules/chat/reps/SuggestedQuestionRep";
import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ChatSuggestionBubble from "ui/chat/ChatSuggestionBubble";
import FeatureStatusBadge from "ui/data-display/FeatureStatusBadge";
import SectionV2 from "ui/data-display/SectionV2";
import VirtualButton from "ui/inputs/VirtualButton";
import { Heading4 } from "ui/typography";
import cn from "utils/tailwind/cn";

type Props = {
  suggestedQuestions: SuggestedQuestionRep[];
};

const AiAnalystNudgeSection: FC<Props> = ({ suggestedQuestions }) => {
  const navigate = useNavigate();
  const currentChatUser = useCurrentChatUserOrThrow();
  const { data: mvpChatChannel } = chatChannelsQueryHooks.useQuery({
    params: { chatUserId: currentChatUser.id },
    select: getMvpFirstChatChannel,
  });

  const [selectedButtonQuestionId, setSelectedButtonQuestionId] = useState<string | null>(null);

  const { mutateAsync: createChatChannelExchange, isPending } =
    useCreateChatChannelExchangeMutation({
      onSuccess: (data) => {
        navigate(`/ai/${data.channelId}`);
      },
      onSettled: () => {
        setSelectedButtonQuestionId(null);
      },
    });

  if (!mvpChatChannel) {
    return null;
  }

  return (
    <SectionV2 variant="dashboard-page">
      <SectionV2.Header className="mb-2">
        <Heading4 className="flex items-center bg-[linear-gradient(90deg,#178428_0%,#15A22C_100%)] bg-clip-text text-transparent">
          <AiChatAnalystAvatar className="mr-4" />
          Ask AI Analyst
          <FeatureStatusBadge featureStatus="Beta" className="ml-2.5" />
        </Heading4>
      </SectionV2.Header>

      <SectionV2.Body className="flex flex-wrap gap-3">
        {suggestedQuestions.map((question) => (
          <VirtualButton
            key={question.id}
            onClick={() => {
              createChatChannelExchange({
                type: "UserPlaintext",
                channelId: mvpChatChannel.id,
                parentId: null,
                content: question.plaintext,
              });
              setSelectedButtonQuestionId(question.id);
            }}
            disabled={isPending}
            className={cn(
              "rounded-full",
              selectedButtonQuestionId === question.id && "data-[disabled=true]:opacity-50"
            )}
          >
            <ChatSuggestionBubble>{question.markdown}</ChatSuggestionBubble>
          </VirtualButton>
        ))}
        <Link to={`/ai/${mvpChatChannel.id}`}>
          <ChatSuggestionBubble>
            See more <ArrowRight />
          </ChatSuggestionBubble>
        </Link>
      </SectionV2.Body>
    </SectionV2>
  );
};

export default AiAnalystNudgeSection;
