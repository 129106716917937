import { zodResolver } from "@hookform/resolvers/zod";
import { SupportedRedirectUrl } from "modules/connected-stores/utils/shopify-supported-redirect-url";
import { shopifyStoreNameFromUrlSchema } from "modules/connected-stores/utils/shopifyStoreUrlSchema";
import useConnectShopifyStore from "modules/connected-stores/utils/useConnectShopifyStore";
import checkInsightsAppEnabled from "modules/insights-app/checkInsightsAppEnabled";
import { FC, ReactNode, useState } from "react";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import Helper from "ui/inputs/Helper";
import TextInputV2 from "ui/inputs/TextInputV2";
import ModalV4 from "ui/overlay/ModalV4";
import { Paragraph } from "ui/typography";
import { z } from "zod";

const connectShopifyStoreFormSchema = z.object({
  storeName: shopifyStoreNameFromUrlSchema,
});

type ConnectShopifyStoreFormInputs = z.input<typeof connectShopifyStoreFormSchema>;
type ConnectShopifyStoreFormOutputs = z.output<typeof connectShopifyStoreFormSchema>;

const useConnectShopifyStoreForm = () => {
  return useForm({
    resolver: zodResolver(connectShopifyStoreFormSchema),
    defaultValues: {
      storeName: "",
    } satisfies ConnectShopifyStoreFormInputs,
  });
};

type RenderPropsArgs = {
  openModal: () => void;
};

type Props = {
  children: (args: RenderPropsArgs) => ReactNode;
  redirectPathKey: SupportedRedirectUrl;
};

const ConnectShopify: FC<Props> = ({ children, redirectPathKey }) => {
  const [isStoreNameModalVisible, setIsStoreNameModalVisible] = useState(false);
  const openShopifyStoreNameModal = () => setIsStoreNameModalVisible(true);
  const form = useConnectShopifyStoreForm();

  const { connectShopifyStore } = useConnectShopifyStore();

  const onSubmit = async ({ storeName }: ConnectShopifyStoreFormOutputs) => {
    await connectShopifyStore({ storeName, redirectPathKey });
  };

  return (
    <>
      {isStoreNameModalVisible && (
        <ShopifyStoreNameModal
          onClose={() => {
            setIsStoreNameModalVisible(false);
          }}
          onSubmit={onSubmit}
          form={form}
        />
      )}

      {children({ openModal: openShopifyStoreNameModal })}
    </>
  );
};

type ShopifyStoreNameModalProps = {
  onClose: () => void;
  onSubmit: (data: ConnectShopifyStoreFormOutputs) => void;
  form: UseFormReturn<ConnectShopifyStoreFormOutputs>;
};

const ShopifyStoreNameModal: FC<ShopifyStoreNameModalProps> = ({ form, onClose, onSubmit }) => {
  const isInsightsApp = checkInsightsAppEnabled();
  const isLoading = form.formState.isSubmitting;

  return (
    <ModalV4 onClose={onClose}>
      <ModalV4.Form
        // Skip validation for insights app.
        onSubmit={isInsightsApp ? () => onSubmit({ storeName: "" }) : form.handleSubmit(onSubmit)}
      >
        <ModalV4.Header>Connect your Shopify store</ModalV4.Header>
        <ModalV4.Body>
          <div className="flex flex-col gap-y-3">
            {!isInsightsApp && (
              <Paragraph className="text-sm">
                Please enter your shopify.com admin URL. It can be found in the browser address bar
                on the{" "}
                <a
                  href={"https://shopify.com/admin"}
                  target="_blank"
                  rel="noreferrer"
                  className="font-bold text-purple-500"
                >
                  Shopify admin page
                </a>
                .
              </Paragraph>
            )}

            {!isInsightsApp && (
              <Controller
                name="storeName"
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <TextInputV2
                      {...field}
                      autoFocus
                      label="Store URL"
                      showErrorOutline={Boolean(error)}
                      placeholder="admin.shopify.com/store/your-store"
                    />
                    {error && <Helper iconVariant="error">{error.message}</Helper>}
                  </div>
                )}
              />
            )}

            <Paragraph className="text-sm">
              You will be redirected to Shopify to install the Highbeam app.
            </Paragraph>
          </div>
        </ModalV4.Body>
        <ModalV4.Footer>
          <ModalV4.SubmitButton isLoading={isLoading}>
            {isLoading ? "Redirecting" : "Connect"}
          </ModalV4.SubmitButton>
        </ModalV4.Footer>
      </ModalV4.Form>
    </ModalV4>
  );
};

export default ConnectShopify;
