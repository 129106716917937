import { useQueryClient } from "@tanstack/react-query";
import useKnowledgeBaseItemApi from "modules/chat/api/useKnowledgeBaseItemApi";
import { KNOWLEDGE_BASE_ITEMS_QUERY_KEY } from "modules/chat/queries/useKnowledgeBaseItems";
import {
  KnowledgeBaseItemCreatorRep,
  KnowledgeBaseItemRep,
} from "modules/chat/reps/KnowledgeBaseItemRep";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

const useCreateKnowledgeBaseItemMutation = (
  additionalOptions: MutationAdditionalOptions<KnowledgeBaseItemRep, KnowledgeBaseItemCreatorRep>
) => {
  const queryClient = useQueryClient();
  const knowledgeBaseItemApi = useKnowledgeBaseItemApi();

  return useMutationWithDefaults(
    {
      mutationFn: async (creator) => {
        return await knowledgeBaseItemApi.create(creator);
      },
      onSuccess: async (knowledgeBaseItem) => {
        await queryClient.invalidateQueries({
          queryKey: [
            KNOWLEDGE_BASE_ITEMS_QUERY_KEY,
            { businessGuid: knowledgeBaseItem.businessGuid },
          ],
        });
      },
    },
    additionalOptions
  );
};

export default useCreateKnowledgeBaseItemMutation;
