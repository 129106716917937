import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import RutterConnectionRep from "reps/RutterConnectionRep";
import ShopifyConnectionRep from "reps/ShopifyConnectionRep";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";
import { getRutterPlatformDisplayName } from "utils/rutter";
import {
  isRutterCommercePlatformOrPaymentProcessorComplete,
  RutterCommercePlatformOrPaymentProcessorComplete,
  RutterCommercePlatformOrPaymentProcessorPlatformName,
} from "utils/rutter/typeguards";

import { rutterConnectionsQueryHooks } from "./useRutterConnections";
import { shopifyConnectionsQueryHooks } from "./useShopifyConnections";

export type ConnectedStoreType = "Amazon" | "PayPal" | "Shopify" | "Stripe" | "Shopify-rutter";

type BaseConnectedStore<T extends ConnectedStoreType> = {
  type: T;
  displayName: string;
  hasSyncedPayoutsAndBalances: boolean;
};

export type ShopifyConnectedStore = BaseConnectedStore<"Shopify"> & ShopifyConnectionRep.Complete;

export type RutterConnectedStore = BaseConnectedStore<
  "Amazon" | "PayPal" | "Stripe" | "Shopify-rutter"
> &
  RutterConnectionRep.Complete;

export type ConnectedStore = ShopifyConnectedStore | RutterConnectedStore;

// NB(alex): We should consider cleaning this up and replacing `useRutterConnectionsQueryOptions` and `useShopifyConnectionsQueryOptions` with this hook.

export const ROOT_CONNECTED_STORES_QUERY_KEY = "connectedStores--root";

export const getRutterConnectedStoreType = (
  platform: RutterCommercePlatformOrPaymentProcessorPlatformName
): RutterConnectedStore["type"] => {
  switch (platform) {
    case "AMAZON":
      return "Amazon";
    case "SHOPIFY":
      return "Shopify-rutter";
    case "STRIPE":
      return "Stripe";
    case "PAYPAL":
      return "PayPal";
  }
};

const convertShopifyConnectionToConnectedStore = (
  shopifyConnection: ShopifyConnectionRep.Complete
): ShopifyConnectedStore => {
  return {
    ...shopifyConnection,
    type: "Shopify",
    displayName: shopifyConnection.shopName,
    hasSyncedPayoutsAndBalances:
      shopifyConnection.hasSyncedPayouts && shopifyConnection.hasSyncedBalances,
  };
};

const convertRutterConnectionToConnectedStore = (
  rutterConnection: RutterCommercePlatformOrPaymentProcessorComplete
): RutterConnectedStore => {
  return {
    ...rutterConnection,
    type: getRutterConnectedStoreType(rutterConnection.platformName),
    displayName:
      rutterConnection.shopName || getRutterPlatformDisplayName(rutterConnection.platformName),
    hasSyncedPayoutsAndBalances:
      rutterConnection.hasSyncedPayouts && rutterConnection.hasSyncedBalances,
  };
};

type Params = {
  businessGuid: string;
};

const connectedStoresQueryHooks = makeQueryHooksV2({
  useDefaultParams: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  makeQueryKey: ({ businessGuid }: Params) => [
    ROOT_CONNECTED_STORES_QUERY_KEY,
    "connectedStores",
    { businessGuid },
  ],
  useQueryFnParams: () => {
    const fetchShopifyConnectionsQueryData = shopifyConnectionsQueryHooks.useFetchQuery();
    const fetchRutterConnectionsQueryData = rutterConnectionsQueryHooks.useFetchQuery();
    return { fetchShopifyConnectionsQueryData, fetchRutterConnectionsQueryData };
  },
  makeQueryFn: (
    { fetchShopifyConnectionsQueryData, fetchRutterConnectionsQueryData },
    { businessGuid }
  ) => {
    return async () => {
      const [shopifyConnections, rutterConnections] = await Promise.all([
        // NB(alex): We explicitly pass in the `businessGuid` to override the default value if needed.
        fetchShopifyConnectionsQueryData({ businessGuid }),
        fetchRutterConnectionsQueryData({ businessGuid }),
      ]);

      return [
        ...shopifyConnections
          .map(convertShopifyConnectionToConnectedStore)
          .sort((a, b) => (a.displayName > b.displayName ? 1 : -1)),
        ...rutterConnections
          .filter(isRutterCommercePlatformOrPaymentProcessorComplete)
          .map(convertRutterConnectionToConnectedStore)
          // NB(alex): We currently sort all rutter connections together under the assumption they will be re-split out where they are used. This isn't a great assumption, but is fine for now.
          .sort((a, b) => (a.displayName > b.displayName ? 1 : -1)),
      ];
    };
  },
});

export { connectedStoresQueryHooks };

export const useHasConnectedStores = () => {
  return connectedStoresQueryHooks.useData({
    params: {},
    select: (connectedStores) => connectedStores.length > 0,
  });
};
