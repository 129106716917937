import {
  containerPaddingClasses,
  OnboardingEstimatedTimePill,
  OnboardingFooter,
  OnboardingHeading,
} from "modules/onboarding/components/shared/onboarding-common-components";
import PlaidConnectionsList from "modules/plaid/components/PlaidConnectionsList";
import { usePlaidConnections } from "modules/plaid/queries/plaidConnectionsQueryHooks";
import ButtonLink from "ui/inputs/ButtonLink";
import { Heading4 } from "ui/typography";

import { onboardingRoutes } from "./config";

const ConnectAccountsConnectionsPage = () => {
  const plaidConnections = usePlaidConnections();

  return (
    <div className={containerPaddingClasses}>
      <div className="mx-auto mt-8 w-full max-w-3xl rounded-md @container tablet:mt-12 tablet:p-8 tablet:shadow-sm 2xl:mt-24">
        <div>
          <OnboardingHeading>
            Connect all bank accounts and cards &nbsp;&nbsp;
            <OnboardingEstimatedTimePill>3–5 minutes</OnboardingEstimatedTimePill>
          </OnboardingHeading>
          <Heading4 className="text-grey600 mt-4 text-sm font-regular">
            Connect all your accounts and cards to get the best results from the AI Analyst and the
            Highbeam product suite.
          </Heading4>
        </div>

        <div className="my-6">
          <PlaidConnectionsList />
        </div>

        <OnboardingFooter>
          <ButtonLink variant="ghost" to="/onboarding/verify-business">
            Skip for now
          </ButtonLink>
          <ButtonLink
            variant="primary"
            to={onboardingRoutes["/onboarding/cleaning-transactions-in-progress"]}
            disabled={plaidConnections.length === 0}
          >
            I’ve connected all my accounts
          </ButtonLink>
        </OnboardingFooter>
      </div>
    </div>
  );
};

export default ConnectAccountsConnectionsPage;
