import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";
import { createURLSearchParamsFromObject } from "utils/search-params/create-url-search-params";

import AccountingClassCodeRep from "../reps/AccountingClassCodeRep";

type AccountingClassCodeApi = {
  search: (businessGuid: string) => Promise<AccountingClassCodeRep[]>;
};

const builder: ApiBuilder<AccountingClassCodeApi> = (api) => ({
  search: async (businessGuid) => {
    const queryParams = createURLSearchParamsFromObject({ businessGuid: businessGuid });
    const url = `/accounting/class-codes?${queryParams}`;
    return (await api.get<AccountingClassCodeRep[]>(url))!;
  },
});

const useAccountingClassCodeApi = () => useBackendV2Api(builder);

export default useAccountingClassCodeApi;
