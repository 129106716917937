import { useBankAccounts } from "modules/bank-accounts/queries/useBankAccounts";
import { FC, ReactNode, createContext, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import { isNotNull } from "utils/array";
import { v4 as uuidv4 } from "uuid";

import useDrawdownAmountForm, {
  DrawdownAmountFormInputs,
  drawdownAmountFormSchema,
} from "../forms/useDrawdownAmountForm";
import useDrawdownInvoiceForm, {
  DrawdownInvoiceFormOutputs,
  drawdownInvoiceFormSchema,
} from "../forms/useDrawdownInvoiceForm";

type Step = {
  pathname: string;
  title: string;
};

type DrawdownContextState = {
  amountForm: ReturnType<typeof useDrawdownAmountForm>;
  invoiceForm: ReturnType<typeof useDrawdownInvoiceForm>;
  currentStepIndex: number;
  nextPathname: string | null;
  prevPathname: string | null;
  steps: Step[];
  idempotencyKey: string;
};

const DrawdownContext = createContext<DrawdownContextState>({} as DrawdownContextState);

type Props = {
  children: ReactNode;
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const DrawdownProvider: FC<Props> = ({ children, capitalAccountSummary }) => {
  const { pathname, state } = useLocation();

  const drawdownAmountFormInputs: DrawdownAmountFormInputs | null =
    drawdownAmountFormSchema.safeParse(state?.drawdownAmountFormInputs).success
      ? state?.drawdownAmountFormInputs
      : null;

  const drawdownInvoiceFormInputs: DrawdownInvoiceFormOutputs | null =
    drawdownInvoiceFormSchema.safeParse(state?.drawdownInvoiceFormInputs).success
      ? state?.drawdownInvoiceFormInputs
      : null;

  const transferOptions = useBankAccounts({
    filters: { paymentEnabled: "enabled-only" },
  });
  const isDrawdownApprovalRequired = capitalAccountSummary.controls.drawdownRequiresApproval;

  const amountForm = useDrawdownAmountForm({
    defaultValues: drawdownAmountFormInputs ?? {
      amount: "",
      drawdownFromCapitalAccount: capitalAccountSummary,
      drawdownToBankAccount: transferOptions[0],
    },
  });

  const invoiceForm = useDrawdownInvoiceForm({
    defaultValues: drawdownInvoiceFormInputs ?? {
      invoice: null,
      reasonForDrawdown: "",
    },
  });

  const [idempotencyKey] = useState(uuidv4);

  const steps: Step[] = [
    {
      pathname: `/capital/${capitalAccountSummary.guid}/draw-down`,
      title: "Amount",
    },
    isDrawdownApprovalRequired
      ? {
          pathname: `/capital/${capitalAccountSummary.guid}/draw-down/invoice`,
          title: "Invoice",
        }
      : null,
    {
      pathname: `/capital/${capitalAccountSummary.guid}/draw-down/confirm`,
      title: "Confirm details",
    },
  ].filter(isNotNull);

  const currentStepIndex = steps.findIndex((step) => step.pathname === pathname);

  const nextPathname =
    currentStepIndex >= 0 && currentStepIndex < steps.length - 1
      ? steps[currentStepIndex + 1].pathname
      : null;

  const prevPathname =
    currentStepIndex > 0 && currentStepIndex < steps.length
      ? steps[currentStepIndex - 1].pathname
      : null;

  return (
    <DrawdownContext.Provider
      value={{
        amountForm,
        invoiceForm,
        currentStepIndex,
        nextPathname,
        prevPathname,
        steps,
        idempotencyKey,
      }}
    >
      {children}
    </DrawdownContext.Provider>
  );
};

export default DrawdownProvider;

export const useDrawdownContext = () => {
  return useContext(DrawdownContext);
};

export const useDrawdownAmountFormContext = () => {
  return useContext(DrawdownContext).amountForm;
};

export const useDrawdownInvoiceFormContext = () => {
  return useContext(DrawdownContext).invoiceForm;
};
