import { ArrowsLeftRight } from "@phosphor-icons/react";
import { FEATURE_FLAGS } from "flags";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import { useFinishActionItemMutation } from "modules/action-items/mutations/useFinishActionItemMutation";
import { useBankAccountsByUnitAccountIdMap } from "modules/bank-accounts/queries/useBankAccounts";
import useBusiness from "modules/business/queries/useBusiness";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import useUnitCoCustomer from "modules/unit-co-customer/queries/useUnitCoCustomer";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import paymentAmountState from "state/payments/paymentAmount";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import Steps, { StepType } from "ui/navigation/Steps";
import { Processor } from "utils/transfers";
import { v4 as uuidv4 } from "uuid";

import useCreateTransferMutation from "./hooks/useCreateTransferMutation";
import ManualTransferFallbackModal from "./ManualTransferFallbackModal";
import { useOpenManualTransferFallbackModal } from "./ManualTransferFallbackModal/state/manualTransferFallbackModalState";
import TransferInfo from "./TransferInfo";
import { Transfer } from "./TransferInfo/utils";
import styles from "./TransferMoney.module.scss";
import TransferMoneyConfirmDetails from "./TransferMoneyConfirmDetails";
import TransferScheduled from "./TransferScheduled";
import {
  getInternalTransferPaymentRequest,
  getConnectedAccountTransferPaymentRequest,
  getBankAccountGuids,
  isAchDebit,
  isConnectedAccountTransfer,
} from "./utils";

enum Step {
  TRANSFER_INFO,
  CONFIRM_DETAILS,
  TRANSFER_SCHEDULED,
}

const steps: StepType[] = [
  {
    id: Step.TRANSFER_INFO,
    number: 1,
    title: "Transfer info",
  },
  {
    id: Step.CONFIRM_DETAILS,
    number: 2,
    title: "Confirm details",
  },
];

type LocationState = {
  fromAccountGuid?: string;
  toAccountGuid?: string;
};

const TransferMoneyContent = () => {
  const locationState = useLocation().state as LocationState | undefined;

  const business = useBusiness();
  const unitCoCustomer = useUnitCoCustomer();
  const openBankAccountsByUnitAccountIdMap = useBankAccountsByUnitAccountIdMap({
    filters: { status: "open" },
  });

  const [idempotencyKey, setIdempotencyKey] = useState(uuidv4());
  const [currentStep, setCurrentStep] = useState<Step>(Step.TRANSFER_INFO);
  const [transfer, setTransfer] = useState<Transfer>({
    amountInCents: 0,
    from: null,
    to: null,
    description: "Internal transfer",
  });
  const useUserDescription = useFeatureFlag(FEATURE_FLAGS["allow-transfer-description-ui"]);

  const { mutateAsync: finishActionItem } = useFinishActionItemMutation("FundYourAccount");
  const openManualTransferFallbackModal = useOpenManualTransferFallbackModal();
  const setPaymentAmountForMfaCheck = useSetRecoilState(paymentAmountState);

  const resetTransfer = () => {
    setIdempotencyKey(uuidv4());
    setCurrentStep(Step.TRANSFER_INFO);
    setTransfer({ amountInCents: 0, from: null, to: null, description: "Internal transfer" });
    setPaymentAmountForMfaCheck(0);
  };

  const onSuccess = () => {
    if (transfer.to?.processor === Processor.Highbeam) {
      finishActionItem({ step: "FundYourAccount", state: "Complete" });
    }
    setCurrentStep(Step.TRANSFER_SCHEDULED);
    setIdempotencyKey(uuidv4());
    setPaymentAmountForMfaCheck(0);
  };

  const onError = (transfer: Transfer) => {
    setIdempotencyKey(uuidv4());

    if (isAchDebit(transfer)) {
      openManualTransferFallbackModal();
    }
  };

  const { mutateAsync: transferMoney, isPending: isTransferLoading } = useCreateTransferMutation({
    onSuccess,
    onError,
  });

  const handleTransferMoney = async () => {
    const paymentRequest = isConnectedAccountTransfer(transfer)
      ? getConnectedAccountTransferPaymentRequest(
          business,
          transfer,
          idempotencyKey,
          useUserDescription
        )
      : getInternalTransferPaymentRequest(
          transfer,
          idempotencyKey,
          openBankAccountsByUnitAccountIdMap,
          business,
          unitCoCustomer && unitCoCustomer.type === "businessCustomer"
            ? unitCoCustomer.attributes.address
            : null
        );
    const bankAccountGuids = getBankAccountGuids(transfer);

    if (bankAccountGuids.length === 0) {
      throw new Error("Something went wrong while processing your transfer.");
    }

    await transferMoney({ unitPayload: paymentRequest, transfer });
  };

  return (
    <>
      <ManualTransferFallbackModal />

      {currentStep === Step.TRANSFER_SCHEDULED ? (
        <div className={styles.successContainer}>
          <TransferScheduled transferInfo={transfer} resetTransfer={resetTransfer} />
        </div>
      ) : (
        <main className={styles.content}>
          <Steps steps={steps} currentStep={currentStep} />

          <section className={styles.stepContent}>
            {currentStep === Step.TRANSFER_INFO && (
              <TransferInfo
                transferInfo={transfer}
                initialFromAccountGuid={locationState?.fromAccountGuid}
                initialToAccountGuid={locationState?.toAccountGuid}
                setTransferInfo={setTransfer}
                onNextPress={() => {
                  setCurrentStep(Step.CONFIRM_DETAILS);
                  setPaymentAmountForMfaCheck(transfer.amountInCents);
                }}
              />
            )}

            {currentStep === Step.CONFIRM_DETAILS && (
              <TransferMoneyConfirmDetails
                isLoading={isTransferLoading}
                onNextPress={handleTransferMoney}
                onPrevPress={() => setCurrentStep(Step.TRANSFER_INFO)}
                step={steps[steps.length - 1]}
                transfer={transfer}
              />
            )}
          </section>
        </main>
      )}
    </>
  );
};

const TransferMoneyPage = () => {
  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>Transfer money</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header>
          <DashboardPage.Header.IconTile icon={<ArrowsLeftRight />} />
          <DashboardPage.Header.Title>Transfer money</DashboardPage.Header.Title>
        </DashboardPage.Header>

        <DashboardPage.Section>
          <TransferMoneyContent />
        </DashboardPage.Section>
      </DashboardPage>
    </>
  );
};

export default TransferMoneyPage;
