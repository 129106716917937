import { ApiBuilder, useBackendV1Api } from "modules/highbeam-api/base";
import CapitalRepaymentAmountRep from "reps/CapitalRepaymentAmountRep";

type CapitalRepaymentAmountApi = {
  get: (
    businessGuid: string,
    capitalAccountGuid: string
  ) => Promise<CapitalRepaymentAmountRep.Complete>;
};

const builder: ApiBuilder<CapitalRepaymentAmountApi> = (api) => ({
  get: async (businessGuid, capitalAccountGuid) => {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
      capitalAccountGuid: capitalAccountGuid,
    });

    const url = `/capital-repayment/repayment-amounts?${qp}`;

    return (await api.get<CapitalRepaymentAmountRep.Complete>(url))!;
  },
});

const useCapitalRepaymentAmountApi = () => useBackendV1Api(builder);

export default useCapitalRepaymentAmountApi;
