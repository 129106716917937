import { FC } from "react";
import SegmentControls from "ui/navigation/SegmentControls";
import { TabConfig, useSearchParamTabState } from "utils/tabs/useTabState";

export const capitalAccountTabs: TabConfig = {
  Active: { label: "Active" },
  Closed: { label: "Closed" },
};

export const useCapitalAccountTabsSearchParamState = () => {
  return useSearchParamTabState("tab", capitalAccountTabs);
};

type Props = {
  activeTab: keyof typeof capitalAccountTabs;
  setActiveTab: (tab: keyof typeof capitalAccountTabs) => void;
  className?: string;
};

const CapitalAccountTabs: FC<Props> = ({ activeTab, setActiveTab, className }) => {
  return (
    <SegmentControls
      size="md"
      className={className}
      tabs={capitalAccountTabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
};

export default CapitalAccountTabs;
