import CapitalAccountRep from "reps/CapitalAccountRep";
import { SetFieldType, Simplify } from "type-fest";

import capitalAccountsQueryHooks from "../queries/useCapitalAccounts";

import { checkIsCapitalAccountWithChargeCard } from "./useCapitalAccountsWithChargeCard";
import { checkIsNonTerminatedCapitalAccount } from "./useNonTerminatedCapitalAccounts";

export type NonTerminatedCapitalAccountWithChargeCard = Simplify<
  SetFieldType<
    CapitalAccountRep.ChargeCardOnlyComplete,
    "state",
    Exclude<CapitalAccountRep.State, CapitalAccountRep.State.Terminated>
  >
>;

export const checkIsNonTerminatedCapitalAccountWithChargeCard = (
  capitalAccount: CapitalAccountRep.Complete
): capitalAccount is NonTerminatedCapitalAccountWithChargeCard => {
  return (
    checkIsNonTerminatedCapitalAccount(capitalAccount) &&
    checkIsCapitalAccountWithChargeCard(capitalAccount)
  );
};

const useNonTerminatedCapitalAccountsWithChargeCard = () => {
  return capitalAccountsQueryHooks.useData({
    params: {},
    select: (capitalAccounts) =>
      capitalAccounts.filter(checkIsNonTerminatedCapitalAccountWithChargeCard),
  });
};

export default useNonTerminatedCapitalAccountsWithChargeCard;
