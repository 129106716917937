import { Primitive } from "@radix-ui/react-primitive";
import { forwardRef, ReactNode } from "react";
import { useComposedRefs } from "utils/react-helpers/compose-refs";

import { SidePanelContextValue, useSidePanelContext } from "./context";

type SidePanelTriggerProps = Omit<
  React.ComponentPropsWithoutRef<typeof Primitive.button>,
  "children"
> & {
  children: ReactNode | ((props: SidePanelContextValue) => ReactNode);
};

const SidePanelTrigger = forwardRef<
  React.ElementRef<typeof Primitive.button>,
  SidePanelTriggerProps
>((props, forwardedRef) => {
  const { children, asChild = true, ...triggerProps } = props;
  const context = useSidePanelContext();
  const composedTriggerRef = useComposedRefs(forwardedRef, context.triggerRef);

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <Primitive.button
      type="button"
      aria-haspopup="dialog"
      aria-expanded={context.isOpen}
      aria-controls={context.panelId}
      data-state={context.isOpen ? "open" : "closed"}
      asChild={asChild}
      {...triggerProps}
      ref={composedTriggerRef}
    >
      {typeof children === "function" ? children(context) : children}
    </Primitive.button>
  );
});

export default SidePanelTrigger;
