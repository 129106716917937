import { Dispatch, SetStateAction, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export type StringArray = readonly string[];
export type OptionInArray<TStringArray extends StringArray> = TStringArray[number];

/**
 * Use search params to save state that must be a valid option in an array of options.
 * @param key The key to use in the url search params.
 * @param options The array of valid options.
 * @param defaultValue Initial value if key is not present in the search params. Also used as the fallback value if the value passed into search params is not a valid option.
 */
const useSearchParamOption = <TOptions extends StringArray>(
  key: string,
  options: TOptions,
  defaultValue: OptionInArray<TOptions>
): [OptionInArray<TOptions>, Dispatch<SetStateAction<OptionInArray<TOptions>>>] => {
  const [searchParams, setSearchParams] = useSearchParams({
    [key]: defaultValue,
  });

  const valueFromSearchParams = searchParams.get(key) || "";
  const isValidValue = options.includes(valueFromSearchParams);

  // We have to conditionally set the value to the default value if the initial value is not valid, otherwise requests may throw errors if they used the initial invalid default value.
  const value = isValidValue ? valueFromSearchParams : defaultValue;

  // If the value is not a valid option, set the value to be the default value
  useEffect(() => {
    if (!isValidValue && valueFromSearchParams !== "") {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        [key]: defaultValue,
      });
    }
  }, [valueFromSearchParams, isValidValue, defaultValue, key, searchParams, setSearchParams]);

  const setValue = (value: SetStateAction<OptionInArray<TOptions>>) => {
    // It's important for us to redefine this here to prevent overwriting params if multiple setters get called at once.
    const currentParams = new URLSearchParams(window.location.search);

    if (typeof value === "function") {
      return setSearchParams({
        ...Object.fromEntries(currentParams.entries()),
        [key]: value(currentParams.get(key) ?? defaultValue),
      });
    }

    return setSearchParams({
      ...Object.fromEntries(currentParams.entries()),
      [key]: value,
    });
  };

  return [value, setValue];
};

export default useSearchParamOption;
