import { useRefreshAllCardsQueries } from "modules/cards/queries/useCards";
import { useNavigate } from "react-router-dom";
import { notify } from "ui/feedback/Toast";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import useCardApi from "../api/useCardApi";

type Variables = {
  cardId: string;
};

const useArchiveCardMutation = (additionalOptions?: MutationAdditionalOptions<void, Variables>) => {
  const cardApi = useCardApi();
  const refreshListCardsQueries = useRefreshAllCardsQueries();
  const navigate = useNavigate();

  return useMutationWithDefaults(
    {
      mutationFn: async ({ cardId }: Variables) => {
        await cardApi.close(cardId);
      },
      onSuccess: async () => {
        await refreshListCardsQueries();
        navigate("/cards");
        notify("success", "Card archived");
      },
      onError: () => {
        notify("warning", "There was an issue closing the card. Please try again.");
      },
    },
    additionalOptions ?? {}
  );
};

export default useArchiveCardMutation;
