import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import BankAccountRep from "reps/BankAccountRep";
import { maskBankAccountAccountNumber } from "utils/account";
import useHasPermission from "utils/permissions/useHasPermission";
import useIsAllowedToViewAccountNumbers from "utils/permissions/useIsAllowedToViewAccountNumbers";
import makeQueryHooksV2, {
  ExtractParamsWithOptionalDefaults,
} from "utils/react-query/makeQueryHooksV2";
import { SimpleUseQueryOptions } from "utils/react-query/SimpleUseQueryOptions";
import useRefreshQuery, { UseRefreshQueryOptions } from "utils/react-query/useRefreshQuery";

import useBankAccountApi from "../api/useBankAccountApi";
import sortBankAccounts from "../utils/sortBankAccounts";

export const ROOT_BANK_ACCOUNTS_QUERY_KEY = "bankAccounts-root";

export const useRefreshAllBankAccountQueries = (options?: UseRefreshQueryOptions) => {
  return useRefreshQuery([ROOT_BANK_ACCOUNTS_QUERY_KEY], options);
};

type Params = {
  businessGuid: string;
  hasPermission: boolean;
  isAllowedToViewAccountNumbers: boolean;
};

export const bankAccountsQueryHooks = makeQueryHooksV2({
  useQueryFnParams: () => {
    const bankAccountApi = useBankAccountApi();
    return { bankAccountApi };
  },
  useDefaultParams: () => {
    const businessGuid = useBusinessGuid();
    const isAllowedToViewAccountNumbers = useIsAllowedToViewAccountNumbers();
    const isAllowedToReadBankAccounts = useHasPermission("bankAccount:read");

    return {
      businessGuid: businessGuid,
      isAllowedToViewAccountNumbers: isAllowedToViewAccountNumbers,
      hasPermission: isAllowedToReadBankAccounts,
    };
  },
  makeQueryKey: (params: Params) => {
    return [ROOT_BANK_ACCOUNTS_QUERY_KEY, "bankAccounts", params] as const;
  },
  makeQueryFn: (
    { bankAccountApi },
    { businessGuid, hasPermission, isAllowedToViewAccountNumbers }
  ) => {
    return async () => {
      if (!hasPermission) {
        return [];
      }

      // NB(alex): The typescript types for `useBusinessGuid` have been wrong since we rolled out multi-biz.
      if (!businessGuid) {
        return [];
      }

      const bankAccounts = await bankAccountApi.getByBusiness(businessGuid);

      // NB(alex): We should mask account numbers on the backend, but this simulates the behavior for now.
      const bankAccountsWithMaskedAccountNumbers = isAllowedToViewAccountNumbers
        ? bankAccounts
        : bankAccounts.map(maskBankAccountAccountNumber);

      return bankAccountsWithMaskedAccountNumbers.sort(sortBankAccounts);
    };
  },
});

export type Status = "all" | "closed" | "open";

const getStatuses = (status: Status): BankAccountRep.Status[] => {
  switch (status) {
    case "all":
      return [
        BankAccountRep.Status.OPEN,
        BankAccountRep.Status.CLOSED,
        BankAccountRep.Status.FROZEN,
      ];
    case "closed":
      return [BankAccountRep.Status.CLOSED];
    case "open":
      return [BankAccountRep.Status.OPEN, BankAccountRep.Status.FROZEN];
  }
};

type Filters = {
  status?: Status | BankAccountRep.Status[];
  supportsDebitCard?: "enabled-only" | "disabled-only";
  paymentEnabled?: "enabled-only" | "disabled-only";
  showDetailsToUser?: "enabled-only" | "disabled-only";
};

export const filterBankAccount = (bankAccount: BankAccountRep.Complete, filters: Filters) => {
  const { status = "all", supportsDebitCard, paymentEnabled, showDetailsToUser } = filters;

  const statuses = Array.isArray(status) ? status : getStatuses(status);
  if (!statuses.includes(bankAccount.status)) {
    return false;
  }
  if (supportsDebitCard) {
    if (supportsDebitCard === "enabled-only" && !bankAccount.highbeamType.supportsDebitCards) {
      return false;
    }
    if (supportsDebitCard === "disabled-only" && bankAccount.highbeamType.supportsDebitCards) {
      return false;
    }
  }
  if (paymentEnabled) {
    const isPaymentEnabled =
      (bankAccount.highbeamType.includeInSendMoney && bankAccount.threadAccount) ||
      bankAccount.isPrimary;
    if (paymentEnabled === "enabled-only" && !isPaymentEnabled) {
      return false;
    }
    if (paymentEnabled === "disabled-only" && isPaymentEnabled) {
      return false;
    }
  }
  if (showDetailsToUser) {
    if (showDetailsToUser === "enabled-only" && !bankAccount.highbeamType.showDetailsToUser) {
      return false;
    }
    if (showDetailsToUser === "disabled-only" && bankAccount.highbeamType.showDetailsToUser) {
      return false;
    }
  }
  return true;
};

// Syntax sugar.
export const makeFilterBankAccountsFilter = (filters: Filters) => {
  return (bankAccount: BankAccountRep.Complete) => filterBankAccount(bankAccount, filters);
};

export const filterBankAccounts = (bankAccounts: BankAccountRep.Complete[], filters: Filters) => {
  return bankAccounts.filter((bankAccount) => filterBankAccount(bankAccount, filters));
};

// Syntax sugar.
export const makeFilterBankAccountsSelect = (filters: Filters) => {
  return (bankAccounts: BankAccountRep.Complete[]) => {
    return filterBankAccounts(bankAccounts, filters);
  };
};

// Hooks

type ParamsWithOptionalDefaults = ExtractParamsWithOptionalDefaults<typeof bankAccountsQueryHooks>;

type HookParams = {
  params?: ParamsWithOptionalDefaults;
  filters?: Filters;
};

export const useBankAccounts = (hookParams: HookParams = {}) => {
  const { params = {}, filters = {} } = hookParams;
  return bankAccountsQueryHooks.useData({
    params,
    select: makeFilterBankAccountsSelect(filters),
  });
};

export const useBankAccountsQuery = (hookParams: HookParams & SimpleUseQueryOptions = {}) => {
  const { params = {}, filters = {}, ...options } = hookParams;
  return bankAccountsQueryHooks.useQuery({
    params,
    select: makeFilterBankAccountsSelect(filters),
    ...options,
  });
};

// Unit

export const createBankAccountsByUnitAccountIdMap = (bankAccounts: BankAccountRep.Complete[]) => {
  return new Map(bankAccounts.map((account) => [account.unitCoDepositAccountId, account]));
};

export const useBankAccountsByUnitAccountIdMap = (hookParams: HookParams = {}) => {
  const { params = {}, filters = {} } = hookParams;
  return bankAccountsQueryHooks.useData({
    params,
    select: (bankAccounts) => {
      return createBankAccountsByUnitAccountIdMap(filterBankAccounts(bankAccounts, filters));
    },
  });
};
