import { HighbeamAccountTypeName } from "reps/BankAccountRep";
import { isNotNull } from "utils/array";

import { BalanceHistoryAugmented } from "./augment-balance-history";

const getUniqueHighbeamAccountTypes = (
  balanceHistory: BalanceHistoryAugmented
): HighbeamAccountTypeName[] => {
  return [
    ...new Set(
      balanceHistory.accounts
        .map((account) => {
          return account.normalizedAccount.repType === "highbeam-account"
            ? account.normalizedAccount.rep.highbeamType.name
            : null;
        })
        .filter(isNotNull)
    ),
  ];
};

const getUniquePlaidInstitutionIds = (balanceHistory: BalanceHistoryAugmented) => {
  return [
    ...new Set(
      balanceHistory.accounts
        .map((account) => {
          return account.normalizedAccount.institutionId;
        })
        .filter(isNotNull)
    ),
  ];
};

type BalanceHistoryInstitution =
  | { type: "plaid"; institutionId: string }
  | { type: "highbeam"; highbeamAccountType: HighbeamAccountTypeName };

const getUniqueBalanceHistoryInstitution = (
  balanceHistory: BalanceHistoryAugmented
): BalanceHistoryInstitution[] => {
  const uniqueHighbeamAccountTypes = getUniqueHighbeamAccountTypes(balanceHistory);
  const uniquePlaidInstitutionIds = getUniquePlaidInstitutionIds(balanceHistory);

  return [
    ...uniqueHighbeamAccountTypes.map((highbeamAccountType) => ({
      type: "highbeam" as const,
      highbeamAccountType,
    })),
    ...uniquePlaidInstitutionIds.map((institutionId) => ({
      type: "plaid" as const,
      institutionId,
    })),
  ];
};

export {
  getUniquePlaidInstitutionIds,
  getUniqueHighbeamAccountTypes,
  getUniqueBalanceHistoryInstitution,
};
