import VerifyBusinessBannerRenderer from "modules/onboarding/components/verify-business/VerifyBusinessBannerRenderer";
import { FC } from "react";

type Props = {
  className?: string;
};

const PaymentsVerifyBusinessBannerRenderer: FC<Props> = ({ className }) => (
  <VerifyBusinessBannerRenderer
    className={className}
    defaultBlurb="Verify your business to start sending payments with Higheam."
  />
);

export default PaymentsVerifyBusinessBannerRenderer;
