import { FC } from "react";

type Props = {
  value: number;
  decimals: number;
};

const DebugValue: FC<Props> = ({ value, decimals }) => {
  return (
    <span className="font-monospace">
      <span className="select-all">{(value / 1000).toFixed(decimals)}</span>
      {"\u200B"}s
    </span>
  );
};

export default DebugValue;
