import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import PayeeRep from "reps/PayeeRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

export const PAYEES_QUERY_KEY = "payees";

export const useRefreshPayeesQueries = () => {
  return useRefreshQuery([PAYEES_QUERY_KEY]);
};

type Params = {
  status: PayeeRep.Status;
  businessGuid: string;
};

const payeesQueryHooks = makeQueryHooksV2({
  useQueryFnParams: () => {
    const highbeamApi = useHighbeamApi();
    return { highbeamApi };
  },
  useDefaultParams: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  makeQueryKey: (params: Params) => {
    return [PAYEES_QUERY_KEY, params];
  },
  makeQueryFn: ({ highbeamApi }, { businessGuid, status }) => {
    return () => highbeamApi.payee.getByBusiness(businessGuid, status);
  },
});

export { payeesQueryHooks };

export const useActivePayeesQuery = () => {
  return payeesQueryHooks.useQuery({ params: { status: "Active" } });
};

export const useActivePayees = () => {
  return payeesQueryHooks.useData({ params: { status: "Active" } });
};
