import { ArrowElbowDownRight as ArrowElbowDownRightIcon } from "@phosphor-icons/react";
import { FC } from "react";

type Props = {
  depth: number;
};

const ItemDepthIndicator: FC<Props> = ({ depth }) => (
  <span aria-hidden="true">
    {Array(depth - 1)
      .fill(0)
      .map((_, index) => (
        // The index key here is fine. This is a static array.
        <span key={index} className="ml-4"></span>
      ))}{" "}
    <ArrowElbowDownRightIcon className="h-4 w-4 text-grey-400" />
  </span>
);

export default ItemDepthIndicator;
