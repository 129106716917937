import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import useExchangeApi from "../api/useExchangeApi";
import { useRefreshChatChannelExchanges } from "../queries/useChatChannelExchanges";
import ExchangeRep from "../reps/ExchangeRep";

type Variables = {
  exchangeId: string;
};

const useDeleteExchangeMutation = (
  additionalOptions?: MutationAdditionalOptions<ExchangeRep, Variables>
) => {
  const exchangeApi = useExchangeApi();
  const refetchChatChannelExchanges = useRefreshChatChannelExchanges();

  return useMutationWithDefaults(
    {
      mutationFn: ({ exchangeId }: Variables) => exchangeApi.delete(exchangeId),
      onSuccess: async () => {
        await refetchChatChannelExchanges();
      },
    },
    additionalOptions ?? {}
  );
};

export default useDeleteExchangeMutation;
