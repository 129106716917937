import { Dispatch, RefObject, SetStateAction } from "react";
import createComponentContext from "utils/react-helpers/createComponentContext";

export type SidePanelSide = "left" | "right";

export type SidePanelPositions = {
  min: number;
  initial: number;
  widest: number;
};

export type SidePanelEvents = {
  onOpenStart?: () => void;
  onOpenEnd?: () => void;
  onCloseStart?: () => void;
  onCloseEnd?: () => void;
};

export type SidePanelContextValue = {
  // State
  isDragging: boolean;
  setIsDragging: Dispatch<SetStateAction<boolean>>;
  transitionEnabled: boolean;
  setTransitionEnabled: Dispatch<SetStateAction<boolean>>;
  showChildren: boolean;
  setShowChildren: Dispatch<SetStateAction<boolean>>;
  width: number;
  setWidth: Dispatch<SetStateAction<number>>;

  // Configs
  positions: SidePanelPositions;
  containerWidth: number;
  side: SidePanelSide;

  // Helpers
  isOpen: boolean;
  onOpenToggle: () => void;

  // Refs & ids
  panelId: string;
  panelRef: RefObject<HTMLDivElement>;
  resizerRef: RefObject<HTMLDivElement>;
  triggerRef: RefObject<HTMLButtonElement | null>;
} & SidePanelEvents;

const [SidePanelProvider, useSidePanelContext] =
  createComponentContext<SidePanelContextValue>("SidePanel");

export { SidePanelProvider, useSidePanelContext };
