import { ApiBuilder, useBackendV1Api } from "modules/highbeam-api/base";
import BusinessRep from "reps/BusinessRep";

type BusinessApi = {
  get: (businessGuid: string) => Promise<BusinessRep.Complete>;
  getByMemberUser: (userGuid: string) => Promise<BusinessRep.Complete[]>;
  getAll: () => Promise<BusinessRep.Complete[]>;
  create: (creator: BusinessRep.Creator) => Promise<BusinessRep.Complete>;
};

const builder: ApiBuilder<BusinessApi> = (api) => ({
  get: async (businessGuid) => {
    const url = `/businesses/${businessGuid}`;
    return (await api.get<BusinessRep.Complete>(url))!;
  },
  getByMemberUser: async (userGuid) => {
    const queryParams = new URLSearchParams({ userGuid });
    const url = `/businesses?${queryParams}`;
    return (await api.get<BusinessRep.Complete[]>(url))!;
  },
  getAll: async () => {
    const url = "/businesses";
    return (await api.get<BusinessRep.Complete[]>(url))!;
  },
  create: async (creator) => {
    const url = "/businesses";
    return (await api.post<BusinessRep.Complete>(url, creator))!;
  },
});

const useBusinessApi = () => useBackendV1Api(builder);

export default useBusinessApi;
