import { BookOpen, FileSearch, Megaphone, Money, Note } from "@phosphor-icons/react";
import { FC } from "react";
import { AiTaskType } from "reps/ai-tasks/AiTaskRep";
import cn from "utils/tailwind/cn";

type ModalIconProps = {
  taskType?: AiTaskType;
};

export type IconColor = "blue-light" | "green-100" | "yellow-100" | "pink-100";

export const getIconColorForTaskType = (taskType?: AiTaskType): IconColor => {
  switch (taskType) {
    case "CleanAndCategorizeTransactions":
      return "yellow-100";
    case "MaximizeYield":
      return "pink-100";
    case "CashFlowForecast":
      return "green-100";
    case "AuditingAndAlerts":
      return "blue-light";
    default:
      return "blue-light";
  }
};

export const getIconColorClass = (color: IconColor): string => {
  return {
    "blue-light": "border-blue bg-blue-light text-blue",
    "green-100": "border-green-600 bg-green-100 text-green-600",
    "pink-100": "border-pink-600 bg-pink-100 text-pink-600",
    "yellow-100": "border-yellow-700 bg-yellow-100 text-yellow-700",
  }[color];
};

export const ModalIcon: FC<ModalIconProps> = ({ taskType }) => {
  const iconMap: Record<AiTaskType, JSX.Element> = {
    CleanAndCategorizeTransactions: <BookOpen size={36} />,
    MaximizeYield: <Money size={36} />,
    CashFlowForecast: <FileSearch size={36} />,
    AuditingAndAlerts: <Megaphone size={36} className="-scale-x-100" />,
    Generic: <Note size={36} />, // Using Megaphone as default for Generic type
  };

  const iconColor = getIconColorForTaskType(taskType as AiTaskType);

  return (
    <div
      className={cn(
        "flex size-8 shrink-0 items-center justify-center rounded-md [&>svg]:size-5",
        getIconColorClass(iconColor)
      )}
    >
      {iconMap[taskType as AiTaskType]}
    </div>
  );
};
