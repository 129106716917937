import CapitalAccountRep from "reps/CapitalAccountRep";
import { SetFieldType } from "type-fest";

import capitalAccountsQueryHooks from "../queries/useCapitalAccounts";

export type NonTerminatedCapitalAccount = SetFieldType<
  CapitalAccountRep.Complete,
  "state",
  Exclude<CapitalAccountRep.State, CapitalAccountRep.State.Terminated>
>;

export const checkIsNonTerminatedCapitalAccount = (
  capitalAccount: CapitalAccountRep.Complete
): capitalAccount is NonTerminatedCapitalAccount => {
  return (
    capitalAccount.state === CapitalAccountRep.State.Active ||
    capitalAccount.state === CapitalAccountRep.State.Offered ||
    capitalAccount.state === CapitalAccountRep.State.OfferAccepted
  );
};

export const useNonTerminatedCapitalAccountsQuery = () => {
  return capitalAccountsQueryHooks.useQuery({
    params: {},
    select: (capitalAccounts) => capitalAccounts.filter(checkIsNonTerminatedCapitalAccount),
  });
};

const useNonTerminatedCapitalAccounts = () => {
  return capitalAccountsQueryHooks.useData({
    params: {},
    select: (capitalAccounts) => capitalAccounts.filter(checkIsNonTerminatedCapitalAccount),
  });
};

export default useNonTerminatedCapitalAccounts;
