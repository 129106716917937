import { ArrowSquareOut } from "@phosphor-icons/react";
import { FC, ReactElement, ReactNode, useState } from "react";
import { AiTaskRep, AiTaskUpdateRep } from "reps/ai-tasks/AiTaskRep";
import { useIsSuperusering } from "state/auth/isSuperusering";
import Card from "ui/data-display/Card";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import ButtonLink from "ui/inputs/ButtonLink";
import LoomVideoModal from "ui/overlay/video-modal/LoomVideoModal";
import { Paragraph, Heading3 } from "ui/typography";
import cn from "utils/tailwind/cn";

import useUpdateAiTaskMutation from "../../mutations/useUpdateAiTaskMutation";
import { useRefreshAiTasks } from "../../queries/useAiTasks";
import AiTaskButtonComponent from "../AiTaskButtonComponent";
import {
  EditPendingModal,
  EditTaskModal,
  LearnMoreModal,
  SetupInProgressModal,
  TurnOffTaskModal,
  TurnOnTaskModal,
} from "../AiTaskModalComponent";
import { getIconColorClass, getIconColorForTaskType } from "../AiTaskModalComponent/ModalIcon";
import AiTaskPillComponent from "../AiTaskPillComponent";

type Props = {
  icon: ReactElement<{ size?: number }>;
  title: ReactNode;
  description: ReactNode;
  task: AiTaskRep;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  onTurnOff?: () => void;
  onTurnOn?: () => void;
  isBusinessActive?: boolean;
  googleSheetUrl?: string;
};

const AiTaskCardComponent: FC<Props> = ({
  icon,
  title,
  description,
  task,
  primaryButtonText = "Edit task",
  secondaryButtonText = "[SU] Turn off",
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onTurnOff,
  onTurnOn,
  isBusinessActive,
  googleSheetUrl,
}) => {
  const [isPrimaryModalOpen, setIsPrimaryModalOpen] = useState(false);
  const [isSecondaryModalOpen, setIsSecondaryModalOpen] = useState(false);
  const [isEditPendingModalOpen, setIsEditPendingModalOpen] = useState(false);
  const [isSetupInProgressModalOpen, setIsSetupInProgressModalOpen] = useState(false);
  const [isLoomVideoModalOpen, setIsLoomVideoModalOpen] = useState(false);

  // NOTE: For Super User override ON button
  const isSuperusering = useIsSuperusering();
  const refreshTasks = useRefreshAiTasks();
  const updateTaskMutation = useUpdateAiTaskMutation(task.id, {
    onSuccess: () => {
      refreshTasks();
      notify("success", "Task turned on successfully");
    },
  });
  const handleSuperuserEnableTask = () => {
    const updatePayload: AiTaskUpdateRep = {
      status: "Active",
      description: `${task.type} turned on`,
    };

    updateTaskMutation.mutate(updatePayload);
  };

  const loomVideoOptions = {
    loomVideoId: "f4aaf68b407c476d9f8fb756507da738",
    title: "Cashflow sheet video",
    autoPlay: true,
    muted: false,
  };

  const handlePrimaryButtonClick = () => {
    if (onPrimaryButtonClick) {
      onPrimaryButtonClick();
    } else {
      setIsPrimaryModalOpen(true);
    }
  };

  const handleSecondaryButtonClick = () => {
    if (onSecondaryButtonClick) {
      onSecondaryButtonClick();
    } else {
      setIsSecondaryModalOpen(true);
    }
  };

  const handleTaskSubmittedForReview = () => {
    setIsPrimaryModalOpen(false);
    setIsEditPendingModalOpen(true);
  };

  const handleTaskSetupStarted = () => {
    setIsPrimaryModalOpen(false);
    setIsSetupInProgressModalOpen(true);
  };

  const getPrimaryModal = () => {
    if (task.status === "Inactive") {
      return (
        <TurnOnTaskModal
          isOpen={isPrimaryModalOpen}
          onClose={() => setIsPrimaryModalOpen(false)}
          title={String(title) || "Turn on task"}
          description={String(description) || "Are you sure you want to turn on this task?"}
          onConfirm={() => {
            onTurnOn?.();
            setIsPrimaryModalOpen(false);
          }}
          onCancel={() => setIsPrimaryModalOpen(false)}
          task={task}
          onTaskSetupStarted={handleTaskSetupStarted}
        />
      );
    } else if (task.status === "SetupInProgress") {
      return (
        <SetupInProgressModal
          isOpen={isPrimaryModalOpen}
          onClose={() => setIsPrimaryModalOpen(false)}
          title={String(title) || "Setup in progress"}
          description={
            String(description) || "This task is currently being set up. Please check back later."
          }
          task={task}
        />
      );
    } else {
      return (
        <EditTaskModal
          isOpen={isPrimaryModalOpen}
          onClose={() => setIsPrimaryModalOpen(false)}
          title={String(title) || "Edit task"}
          description={String(description)}
          onConfirm={() => setIsPrimaryModalOpen(false)}
          onCancel={() => setIsPrimaryModalOpen(false)}
          taskDescription={String(description)}
          task={task}
          onTaskSubmittedForReview={handleTaskSubmittedForReview}
        />
      );
    }
  };

  const getSecondaryModal = () => {
    if (task.status === "Inactive") {
      return (
        <LearnMoreModal
          isOpen={isSecondaryModalOpen}
          onClose={() => setIsSecondaryModalOpen(false)}
          title={String(title) || "Learn more"}
          description={String(description) || "This feature helps you automate your workflow."}
          onConfirm={() => setIsSecondaryModalOpen(false)}
          task={task}
          taskDescription={String(description)}
        />
      );
    } else {
      return (
        <TurnOffTaskModal
          isOpen={isSecondaryModalOpen}
          onClose={() => setIsSecondaryModalOpen(false)}
          title={String(title) || "Turn off task"}
          description={String(description) || "Are you sure you want to turn off this task?"}
          onConfirm={() => {
            onTurnOff?.();
            setIsSecondaryModalOpen(false);
          }}
          onCancel={() => setIsSecondaryModalOpen(false)}
          taskDescription={String(description)}
          task={task}
        />
      );
    }
  };
  return (
    <Card className={"flex min-h-32 flex-col border border-grey-100 p-8"} shadow="xs">
      <div className="flex w-full grow flex-col items-start gap-3">
        <div className="flex w-full items-center gap-3">
          <div
            className={cn(
              "flex size-8 items-center justify-center rounded-md [&>svg]:size-5",
              getIconColorClass(getIconColorForTaskType(task.type))
            )}
          >
            {icon}
          </div>
          <Heading3 className="text-sm font-medium text-grey-600">{title}</Heading3>
          <AiTaskPillComponent status={task.status} className="ml-auto" />
        </div>
        <Paragraph className="text-sm font-medium">{description}</Paragraph>
        {task.type === "CleanAndCategorizeTransactions" && (
          <ButtonLink to="/accounts/transactions" className="w-full">
            View transactions
          </ButtonLink>
        )}
        {task.type === "CashFlowForecast" && (
          <div className="w-full">
            {googleSheetUrl ? (
              <ButtonLink to={googleSheetUrl} className="w-full">
                View Google Sheet
                <ArrowSquareOut size={16} className="text-purple-500" />
              </ButtonLink>
            ) : (
              <Button className="w-full" onClick={() => setIsLoomVideoModalOpen(true)}>
                View demo
              </Button>
            )}
            {isLoomVideoModalOpen && (
              <LoomVideoModal
                {...loomVideoOptions}
                onClose={() => setIsLoomVideoModalOpen(false)}
              />
            )}
          </div>
        )}
      </div>
      <div className="mt-16 w-full">
        <AiTaskButtonComponent
          status={task.status}
          primaryButtonText={primaryButtonText}
          secondaryButtonText={secondaryButtonText}
          onPrimaryButtonClick={handlePrimaryButtonClick}
          onSecondaryButtonClick={handleSecondaryButtonClick}
          disablePrimaryButton={
            task.type === "MaximizeYield" && task.status === "Inactive" && !isBusinessActive
          }
          disabledTooltipText="You have to be a Highbeam banking customer to set up this task"
        />
        {isSuperusering && (
          <Button
            variant="secondary"
            className="mt-3 w-full bg-green-200 text-green-600"
            onClick={handleSuperuserEnableTask}
          >
            [SU] Turn on
          </Button>
        )}
      </div>

      {getPrimaryModal()}
      {getSecondaryModal()}

      <EditPendingModal
        isOpen={isEditPendingModalOpen}
        onClose={() => setIsEditPendingModalOpen(false)}
        title="Edit task"
        description=""
        task={task}
      />

      <SetupInProgressModal
        isOpen={isSetupInProgressModalOpen}
        onClose={() => setIsSetupInProgressModalOpen(false)}
        title="Setup in progress"
        description="Our team is setting up your task. The task will be active shortly."
        task={task}
      />
    </Card>
  );
};

export default AiTaskCardComponent;
