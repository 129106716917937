import { SuggestedQuestionRep } from "modules/chat/reps/SuggestedQuestionRep";
import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";

type SuggestedQuestionApi = {
  listByBusiness: (businessGuid: string, limit: number) => Promise<SuggestedQuestionRep[]>;
};

const builder: ApiBuilder<SuggestedQuestionApi> = (api) => ({
  listByBusiness: async (businessGuid, limit) => {
    const queryParams = new URLSearchParams({ businessGuid, limit: String(limit) });
    const url = `/chat/suggested-questions?${queryParams}`;
    return (await api.get<SuggestedQuestionRep[]>(url))!;
  },
});

const useSuggestedQuestionApi = () => useBackendV2Api(builder);

export default useSuggestedQuestionApi;
