import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";
import ApSettingsRep from "reps/ApSettingsRep";

type ApSettingsApi = {
  create: (settings: ApSettingsRep.Creator) => Promise<ApSettingsRep.Complete>;
  get: (businessGuid: string) => Promise<ApSettingsRep.Complete>;
  update: (apSettingsId: string, updater: ApSettingsRep.Updater) => Promise<ApSettingsRep.Complete>;
};

const builder: ApiBuilder<ApSettingsApi> = (api) => ({
  create: async (settings) => {
    const url = `/accounts-payable/settings`;
    return (await api.post<ApSettingsRep.Complete>(url, settings))!;
  },
  get: async (businessGuid) => {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/accounts-payable/settings?${queryParams}`;
    return (await api.get<ApSettingsRep.Complete>(url))!;
  },
  update: async (apSettingsId, updater) => {
    const url = `/accounts-payable/settings/${apSettingsId}`;
    return (await api.patch<ApSettingsRep.Complete>(url, updater))!;
  },
});

const useApSettingsApi = () => useBackendV2Api(builder);

export default useApSettingsApi;
