import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";
import ArchiveBusinessTaskRep from "reps/ArchiveBusinessTaskRep";

type ArchiveBusinessApi = {
  archive: (creator: ArchiveBusinessTaskRep.Creator) => Promise<void>;
};

const builder: ApiBuilder<ArchiveBusinessApi> = (api) => ({
  archive: async (creator) => {
    const url = "/business-management/archive-business";
    return (await api.post(url, creator))!;
  },
});

const useArchiveBusinessApi = () => useBackendV2Api(builder);

export default useArchiveBusinessApi;
