import { Span } from "ui/typography";
import { formatNumber } from "utils/numbers";
import cn from "utils/tailwind/cn";

const formatPaginatedTransactionsRange = (offset: number, count: number, totalCount: number) =>
  `Showing ${offset + 1}-${offset + count} of ${formatNumber(totalCount)} transactions`;

const formatTransactionsRange = (count: number, totalCount: number) =>
  `Showing ${count} of ${totalCount} transactions`;

type Props = {
  offset?: number;
  count: number;
  totalCount: number;
  className?: string;
};

const PaginationInfo: React.FC<Props> = ({ offset, count, totalCount, className }) => (
  <Span className={cn("block text-sm text-grey-800", className)}>
    {offset !== undefined
      ? formatPaginatedTransactionsRange(offset, count, totalCount)
      : formatTransactionsRange(count, totalCount)}
  </Span>
);

export default PaginationInfo;
