import { Card } from "@highbeam/unit-node-sdk";
import { Warning } from "@phosphor-icons/react";
import { useChatWidget } from "components/ChatWidget";
import BankAccountBar from "modules/bank-accounts/components/BankAccountBar";
import { useBankAccountByUnitAccountId } from "modules/bank-accounts/queries/useBankAccount";
import { useCardLimitsModal } from "pages/CardLimitModal/state/cardLimitModalState";
import Banner from "ui/data-display/Banner";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Button from "ui/inputs/Button";
import { Heading3, Heading4 } from "ui/typography";

import CardLimitBar from "./CardLimitBar";

type Props = {
  accountId: string;
  currentPurchase: number;
  totalLimit: number;
  cards: Card[];
};

const DepositAccountCardLimit: React.FC<Props> = ({
  accountId,
  currentPurchase,
  totalLimit,
  cards,
}) => {
  const chat = useChatWidget();
  const { open: openCardLimitsModal } = useCardLimitsModal();
  const bankAccount = useBankAccountByUnitAccountId(accountId);

  if (!bankAccount) {
    return null;
  }

  return (
    <Banner
      icon={<Warning size={24} />}
      color="yellow"
      title={
        <div className="flex gap-x-2">
          <Heading3>
            You’re approaching the <MoneyAmount cents={totalLimit} size={16} withCents={false} />{" "}
            daily debit limit for
          </Heading3>
          <BankAccountBar size={20} bankAccount={bankAccount} />
        </div>
      }
      body={
        <>
          <Heading4 className="my-2 font-medium">Associated debit cards:</Heading4>
          <ul className="list-inside list-disc text-sm font-regular">
            {cards.map((card) => (
              <li key={card.id} className="size-md">
                {card.attributes.tags.name}
              </li>
            ))}
          </ul>
          <CardLimitBar current={currentPurchase} total={totalLimit} />
        </>
      }
      footer={
        <div className="ml-12 flex gap-x-4 py-4">
          <Button
            variant="tertiary"
            onClick={() => {
              openCardLimitsModal({
                account: bankAccount,
                currentPurchase: currentPurchase,
              });
            }}
          >
            Learn more
          </Button>
          <Button
            variant="plain"
            onClick={() =>
              chat.message(
                "I'm approaching my debit card limit. Could I get more information on what I can do?"
              )
            }
          >
            Talk to us
          </Button>
        </div>
      }
    />
  );
};

export default DepositAccountCardLimit;
