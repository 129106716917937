import { FC, useState } from "react";
import { AiTaskUpdateRep } from "reps/ai-tasks/AiTaskRep";
import { notify } from "ui/feedback/Toast";
import TextArea from "ui/inputs/TextArea";
import TextInput from "ui/inputs/TextInput";
import { Paragraph } from "ui/typography";

import useUpdateAiTaskMutation from "../../mutations/useUpdateAiTaskMutation";
import { useRefreshAiTasks } from "../../queries/useAiTasks";
import AiTaskPillComponent from "../AiTaskPillComponent";

import BaseAiTaskModal, { BaseAiTaskModalProps } from "./BaseAiTaskModal";
import ModalDescriptionTable from "./ModalDescriptionTable";
import { ModalIcon } from "./ModalIcon";

type Props = Omit<BaseAiTaskModalProps, "variant"> & {
  onTaskSetupStarted?: () => void;
};

const TurnOnTaskModal: FC<Props> = ({
  isOpen,
  onClose,
  title,
  description,
  onConfirm,
  onCancel,
  className,
  children,
  task,
  onTaskSetupStarted,
}) => {
  const refreshTasks = useRefreshAiTasks();
  const [additionalText1, setAdditionalText1] = useState("");
  const [additionalText2, setAdditionalText2] = useState("");

  const updateTaskMutation = useUpdateAiTaskMutation(task!.id, {
    onSuccess: () => {
      refreshTasks();
      notify("success", "Task turned on successfully");
      if (onTaskSetupStarted) {
        onTaskSetupStarted();
      }
    },
  });

  const config = {
    title: title || "Turn on task",
    confirmButtonText: "Turn on task",
    confirmButtonVariant: "primary" as const,
    confirmButtonClassName: "",
    cancelButtonText: "Cancel",
  };

  const handleConfirm = () => {
    const descriptionText = `${task!.type} setup in progress${
      additionalText1.length > 0 ? `\n${additionalText1.trim()}` : ""
    }${additionalText2.length > 0 ? `\n${additionalText2.trim()}` : ""}`;

    const updatePayload: AiTaskUpdateRep = {
      status: "SetupInProgress",
      description: descriptionText,
    };

    updateTaskMutation.mutate(updatePayload);
  };

  // Render additional sections based on task type
  const renderAdditionalSections = () => {
    if (task!.type === "MaximizeYield") {
      return (
        <>
          <div className="mb-2 mt-6">
            <p className="mb-1 text-sm font-[500]">Bank accounts</p>
            <p className="mb-2 text-sm text-grey-700">
              Describe which accounts you would like to be included.
            </p>
            <TextArea
              value={additionalText1}
              onChange={(e) => setAdditionalText1(e)}
              placeholder='Ex. "Highbeam primary and Highbeam high yield."'
            >
              <TextArea.Input />
            </TextArea>
          </div>
          <div className="mb-2 mt-2">
            <p className="mb-1 text-sm font-[500]">Target balance</p>
            <p className="mb-2 text-sm text-grey-700">
              The minimum balance you would like to maintain and any other context.
            </p>
            <TextInput
              value={additionalText2}
              onChange={(e) => setAdditionalText2(e)}
              placeholder='Ex. "$30,000 in my Highbeam primary account."'
            />
          </div>
        </>
      );
    } else if (task!.type === "AuditingAndAlerts") {
      return (
        <div className="mb-2 mt-6">
          <p className="mb-1 text-sm font-[500]">Notes (optional)</p>
          <TextArea
            value={additionalText1}
            onChange={(e) => setAdditionalText1(e)}
            placeholder='Ex. "Please also show me any payments over $10k in the report."'
          >
            <TextArea.Input />
          </TextArea>
        </div>
      );
    }

    return null;
  };

  return (
    <BaseAiTaskModal
      isOpen={isOpen}
      onClose={onClose}
      title={title || config.title}
      config={config}
      onConfirm={onConfirm}
      onCancel={onCancel}
      className={className}
      //isConfirmDisabled={!taskId}
      onConfirmClick={handleConfirm}
      task={task}
    >
      <div className="mb-6">
        <div className="mb-4 flex items-center gap-3">
          <ModalIcon taskType={task!.type} />
          {description && (
            <Paragraph className="text-base px-2 font-[500]">{description}</Paragraph>
          )}
          <AiTaskPillComponent status={task!.status} className="ml-auto" />
        </div>
      </div>

      <ModalDescriptionTable task={task!} />

      {renderAdditionalSections()}

      {children}
    </BaseAiTaskModal>
  );
};

export default TurnOnTaskModal;
