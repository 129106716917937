import { bankAccountsQueryHooks } from "modules/bank-accounts/queries/useBankAccounts";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { plaidAccountsQueryHooks } from "modules/plaid/queries/usePlaidAccounts";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import useBalanceHistoryApi from "../api/useBalanceHistoryApi";
import { GetBalanceHistoryQueryParamsRep } from "../reps/BalanceHistoryRep";
import { augmentBalanceHistory } from "../utils/augment-balance-history";

const ROOT_BALANCE_HISTORY_QUERY_KEY = "balanceHistory";

type Params = GetBalanceHistoryQueryParamsRep & {
  businessGuid: string;
};

const balanceHistoryQueryHooks = makeQueryHooksV2({
  useQueryFnParams: () => {
    const balanceHistoryApi = useBalanceHistoryApi();
    const fetchBankAccounts = bankAccountsQueryHooks.useFetchQuery();
    const fetchPlaidBankAccounts = plaidAccountsQueryHooks.useFetchQuery();

    return {
      balanceHistoryApi,
      fetchBankAccounts,
      fetchPlaidBankAccounts,
    };
  },
  useDefaultParams: () => {
    const businessGuid = useBusinessGuid();
    return {
      businessGuid,
    };
  },
  makeQueryKey: ({ businessGuid, ...params }: Params) => {
    return [ROOT_BALANCE_HISTORY_QUERY_KEY, businessGuid, params];
  },
  makeQueryFn: (
    { balanceHistoryApi, fetchBankAccounts, fetchPlaidBankAccounts },
    { businessGuid, ...params }
  ) => {
    return async () => {
      const [balanceHistory, bankAccounts, plaidBankAccounts] = await Promise.all([
        balanceHistoryApi.get(businessGuid, params),
        fetchBankAccounts({ businessGuid }),
        fetchPlaidBankAccounts({ businessGuid }),
      ]);
      return augmentBalanceHistory({
        balanceHistory,
        bankAccounts,
        connectedPlaidBankAccounts: plaidBankAccounts,
      });
    };
  },
});

const useRefreshBalanceHistoryQueries = () => {
  return useRefreshQuery([ROOT_BALANCE_HISTORY_QUERY_KEY]);
};

export { balanceHistoryQueryHooks, useRefreshBalanceHistoryQueries };
