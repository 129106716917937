import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import BankAccountFailedDebitsSection from "modules/bank-accounts/components/BankAccountFailedDebitsSection";
import { useBankAccount } from "modules/bank-accounts/queries/useBankAccount";
import getBankAccountDisplayName from "modules/bank-accounts/utils/getBankAccountDisplayName";
import HighYieldPage from "pages/accounts/HighYieldPage";
import { Navigate, useParams } from "react-router-dom";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

import AccountAuthorizationsTable from "./AccountAuthorizationsTable";
import AccountDetailsAccountTransactionsSection from "./AccountDetailsAccountTransactionsSection";
import AccountDetailsIncomingPaymentsSection from "./AccountDetailsIncomingPaymentsSection";
import AccountDetailsPageHeader from "./AccountDetailsPageHeader";
import AccountInformation from "./AccountInformation";

const AccountDetailsPage = () => {
  const params = useParams();
  const accountGuid = params.accountGuid!;
  const bankAccount = useBankAccount(accountGuid);

  if (!bankAccount) {
    return <Navigate to="/accounts" />;
  }

  const accountPage =
    bankAccount.highbeamType.name === "HighYield" ? (
      <HighYieldPage account={bankAccount} />
    ) : (
      <>
        <DashboardHeader>
          <Breadcrumbs>
            <Breadcrumbs.Item to="/accounts">Accounts</Breadcrumbs.Item>
            <Breadcrumbs.CurrentItem>
              {getBankAccountDisplayName(bankAccount, { showMaskedAccountNumber: false })}
            </Breadcrumbs.CurrentItem>
          </Breadcrumbs>
        </DashboardHeader>

        <DashboardPage>
          <AccountDetailsPageHeader account={bankAccount} />
          <AccountInformation account={bankAccount} />
          <BankAccountFailedDebitsSection
            unitCoDepositAccountId={bankAccount.unitCoDepositAccountId}
          />
          <AccountDetailsIncomingPaymentsSection
            unitCoDepositAccountId={bankAccount.unitCoDepositAccountId}
          />
          <AccountAuthorizationsTable accountId={bankAccount.unitCoDepositAccountId} />
          <AccountDetailsAccountTransactionsSection account={bankAccount} />
        </DashboardPage>
      </>
    );

  return accountPage;
};

export default AccountDetailsPage;
