import { FC } from "react";
import AccentCard from "ui/data-display/AccentCard";
import BanksAndCards from "ui/icons/BanksAndCards";
import IconTile from "ui/icons/IconTile";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

import {
  OnboardingEstimatedTimePill,
  OnboardingFooter,
  OnboardingHeading,
} from "../shared/onboarding-common-components";

import ConnectYourAccountsYouWillGet from "./ConnectYourAccountsYouWillGet";

const ConnectYourAccountsView: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <div className={cn("flex flex-col @container", className)}>{children}</div>;
};

const BanksAndCardsIconTile: FC<{ className?: string }> = ({ className }) => {
  return (
    <IconTile
      icon={<BanksAndCards size={22} className="text-purple-500" />}
      className={cn("bg-purple-50", className)}
    />
  );
};

const Heading: FC<{ className?: string }> = ({ className }) => {
  return (
    <OnboardingHeading className={cn("mt-4", className)}>
      Connect your accounts to get started &nbsp;&nbsp;
      <OnboardingEstimatedTimePill>3–5 minutes</OnboardingEstimatedTimePill>
    </OnboardingHeading>
  );
};

const YouWillGetCard: FC<{ className?: string }> = ({ className }) => {
  return (
    <AccentCard className={cn("mt-7", className)}>
      <ConnectYourAccountsYouWillGet className="text-purple-800" />
    </AccentCard>
  );
};

const Footer: FC<PropsWithChildrenAndClassName> = ({ className, children }) => {
  return <OnboardingFooter className={cn("mt-9", className)}>{children}</OnboardingFooter>;
};

export default Object.assign(ConnectYourAccountsView, {
  BanksAndCardsIconTile: BanksAndCardsIconTile,
  Heading: Heading,
  YouWillGetCard: YouWillGetCard,
  Footer: Footer,
});
