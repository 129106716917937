import dayjs, { Dayjs } from "dayjs";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { API_LOCAL_DATE_FORMAT } from "utils/date";
import useSearchParamOption, {
  OptionInArray,
  StringArray,
} from "utils/search-params/useSearchParamOption";
import useSearchParamValue from "utils/search-params/useSearchParamValue";

import { DEFAULT_PAGE_PARAM_KEY } from "./config";

type UseSearchFilterParamsPatcherParams = {
  pageParamKey?: string;
};

const useSearchFilterParamsPatcher = (params: UseSearchFilterParamsPatcherParams = {}) => {
  const { pageParamKey = DEFAULT_PAGE_PARAM_KEY } = params;
  const [_, setSearchParams] = useSearchParams();

  return useCallback(
    (params: Record<string, string>) => {
      setSearchParams((currentParams) => {
        // Always remove the page when changing filters (effectively resetting it to 1).
        currentParams.delete(pageParamKey);
        Object.entries(params).forEach(([key, value]) => {
          currentParams.set(key, value);
        });
        return currentParams;
      });
    },
    [setSearchParams, pageParamKey]
  );
};

type UseStringValueFilterParams = {
  paramKey: string;
  defaultValue?: string;
  pageParamKey?: string;
};

const useStringValueFilter = (params: UseStringValueFilterParams) => {
  const { paramKey, defaultValue = "", pageParamKey = DEFAULT_PAGE_PARAM_KEY } = params;
  const patchSearchParams = useSearchFilterParamsPatcher({ pageParamKey });

  const [value] = useSearchParamValue(paramKey, defaultValue);

  const setValue = useCallback(
    (newValue: string) => {
      patchSearchParams({
        [paramKey]: newValue,
      });
    },
    [paramKey, patchSearchParams]
  );

  return [value, setValue] as const;
};

type UseOptionValueFilterParams<TOptions extends StringArray> = {
  paramKey: string;
  options: TOptions;
  defaultValue: OptionInArray<TOptions>;
  pageParamKey?: string;
};

const useOptionValueFilter = <TOptions extends StringArray>(
  params: UseOptionValueFilterParams<TOptions>
) => {
  const { paramKey, options, defaultValue, pageParamKey = DEFAULT_PAGE_PARAM_KEY } = params;
  const patchSearchParams = useSearchFilterParamsPatcher({ pageParamKey });

  const [value] = useSearchParamOption(paramKey, options, defaultValue);

  const setValue = useCallback(
    (newValue: OptionInArray<TOptions>) => {
      patchSearchParams({
        [paramKey]: newValue,
      });
    },
    [paramKey, patchSearchParams]
  );

  return [value, setValue] as const;
};

type UseDateValueFilterParams = {
  paramKey: string;
  defaultValue: Dayjs;
  pageParamKey?: string;
};

const useDateValueFilter = (params: UseDateValueFilterParams) => {
  const { paramKey, defaultValue, pageParamKey = DEFAULT_PAGE_PARAM_KEY } = params;
  const patchSearchParams = useSearchFilterParamsPatcher({ pageParamKey });

  const [rawValue] = useSearchParamValue(paramKey);
  const date = rawValue ? dayjs(rawValue, API_LOCAL_DATE_FORMAT, true) : defaultValue;

  const setDate = useCallback(
    (newValue: Dayjs) => {
      patchSearchParams({
        [paramKey]: newValue ? newValue.format(API_LOCAL_DATE_FORMAT) : "",
      });
    },
    [paramKey, patchSearchParams]
  );

  return [date, setDate] as const;
};

type UseDateValueFilterNullableParams = {
  paramKey: string;
  defaultValue?: Dayjs;
  pageParamKey?: string;
};

const useDateValueFilterNullable = (params: UseDateValueFilterNullableParams) => {
  const { paramKey, defaultValue = null, pageParamKey = DEFAULT_PAGE_PARAM_KEY } = params;
  const patchSearchParams = useSearchFilterParamsPatcher({ pageParamKey });

  const [rawValue] = useSearchParamValue(paramKey);
  const date = rawValue ? dayjs(rawValue, API_LOCAL_DATE_FORMAT, true) : (defaultValue ?? null);

  const setDate = useCallback(
    (newValue: Dayjs | null) => {
      patchSearchParams({
        [paramKey]: newValue ? newValue.format(API_LOCAL_DATE_FORMAT) : "",
      });
    },
    [paramKey, patchSearchParams]
  );

  return [date, setDate] as const;
};

export {
  useSearchFilterParamsPatcher,
  useStringValueFilter,
  useOptionValueFilter,
  useDateValueFilter,
  useDateValueFilterNullable,
};
