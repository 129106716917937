import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { FEATURE_FLAGS } from "flags";
import DashboardPage from "layouts/DashboardPage";
import useAccountingPlatformConnectionsQueryOptions from "modules/accounting-platforms/queries/useAccountingPlatformConnectionsQueryOptions";
import { useHasActiveCapitalAccount } from "modules/capital-accounts/hooks/useActiveCapitalAccounts";
import useCreditApplicationsQueryOptions from "modules/credit-application/queries/useCreditApplicationsQueryOptions";
import { getIsNewCreditApplication } from "modules/credit-application/utils/credit-application-type-guards";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import { FC } from "react";
import RutterConnectionRep from "reps/RutterConnectionRep";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import Text from "ui/typography/Text";
import getAccountingSoftwareName from "utils/rutter/getAccountingSoftwareName";

import ConnectRutter from "../SettingsPage/Integrations/ConnectRutter";

import AccountingInstructions from "./AccountingInstructions";
import AccountingPlatformConnectionCard from "./AccountingPlatformConnectionCard";
import AccountingSoftwareCard from "./AccountingSoftwareCard";

const containerClasses = "grid grid-cols-1 gap-6 tablet:grid-cols-3";

const AccountingSoftwareSection: FC = () => {
  const hasActiveCapitalAccount = useHasActiveCapitalAccount();
  const allowAccountingSoftwareConnectionFlag = useFeatureFlag(
    FEATURE_FLAGS["settings-allow-accounting-software-connection"]
  );

  const { data: nonNewCreditApplications } = useSuspenseQuery({
    ...useCreditApplicationsQueryOptions(),
    select: (creditApplications) => {
      return creditApplications.filter((creditApplication) => {
        return !getIsNewCreditApplication(creditApplication);
      });
    },
  });

  const showAccountingSoftwareConnectionSection =
    allowAccountingSoftwareConnectionFlag ||
    nonNewCreditApplications.length > 0 ||
    hasActiveCapitalAccount;

  return (
    <DashboardPage.Section>
      {showAccountingSoftwareConnectionSection && (
        <>
          <DashboardPage.Section.Header>
            <DashboardPage.Section.HeaderHeading>
              Share financials with Highbeam
            </DashboardPage.Section.HeaderHeading>
          </DashboardPage.Section.Header>

          <DashboardPage.Section.Body className={containerClasses}>
            <AccountingSoftwareRutterConnect />
          </DashboardPage.Section.Body>

          <Divider />
        </>
      )}

      <DashboardPage.Section.Header>
        <DashboardPage.Section.HeaderHeading>
          Share Highbeam transactions with accounting
        </DashboardPage.Section.HeaderHeading>
      </DashboardPage.Section.Header>

      <DashboardPage.Section.Body className={containerClasses}>
        <AccountingSoftwareInstructions />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};

export default AccountingSoftwareSection;

const AccountingSoftwareInstructions: FC = () => {
  const getCardDescription = (type: RutterConnectionRep.RutterAccountingPlatform) => (
    <Text size={14} color={colors.grey[600]}>
      {`Sync your Highbeam transactions to ${getAccountingSoftwareName(type)}.`}
    </Text>
  );

  return (
    <>
      {RutterConnectionRep.rutterAccountingPlatforms.map((type) => (
        <AccountingInstructions accountingSoftwareType={type} key={type}>
          {({ openModal }) => (
            <AccountingSoftwareCard
              type={type}
              description={getCardDescription(type)}
              addText="View instructions"
              onButtonClick={openModal}
            />
          )}
        </AccountingInstructions>
      ))}
    </>
  );
};

const AccountingSoftwareRutterConnect: FC = () => {
  const { refetch } = useQuery(useAccountingPlatformConnectionsQueryOptions());

  return (
    <>
      {RutterConnectionRep.rutterAccountingPlatforms.map((type) => (
        <ConnectRutter
          platform={type}
          onConnect={(_, callbacks) => {
            refetch();
            callbacks.closeModal();
          }}
          key={type}
        >
          {({ openModal }) => (
            <AccountingPlatformConnectionCard openModal={openModal} type={type} />
          )}
        </ConnectRutter>
      ))}
    </>
  );
};
