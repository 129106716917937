import { ArrowRight, Storefront, Calculator, Info } from "@phosphor-icons/react";
import { DataSources } from "modules/chat/queries/useDataSources";
import { ShopifyConnectedStore } from "modules/connected-stores/queries/useConnectedStores";
import useConnectShopifyStore from "modules/connected-stores/utils/useConnectShopifyStore";
import { FC } from "react";
import BanksAndCards from "ui/icons/BanksAndCards";
import { HighbeamLogo } from "ui/icons/logos/logos-square";
import Button from "ui/inputs/Button";
import ButtonLink from "ui/inputs/ButtonLink";
import ModalV4 from "ui/overlay/ModalV4";
import { Heading3, Paragraph } from "ui/typography";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

import DataSourcesPlatformAvatarGroup from "../DataSourcesPlatformAvatarGroup/DataSourcesPlatformAvatarGroup";

const DataSourceItem: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <div
    className={cn(
      "flex items-center justify-between gap-2 border-b border-b-grey-100 py-6 text-sm last-of-type:border-b-0",
      className
    )}
  >
    {children}
  </div>
);

const DataSourceItemMain: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <div className={cn("flex flex-col items-start gap-2", className)}>{children}</div>
);

const DataSourcesItemHeader: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <div className={cn("flex items-center gap-2", className)}>{children}</div>
);

const DataSourcesItemHeading: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <Heading3 className={cn("text-sm font-medium text-grey-800", className)}>{children}</Heading3>
);

const DataSourcesItemParagraph: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <Paragraph className={cn("ml-[1.8125rem] text-grey-600", className)}>{children}</Paragraph>
);

const DataSourceItemActions: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <div className={className}>{children}</div>
);

type Props = {
  dataSources: DataSources;
  onClose: () => void;
};

const DataSourcesModal: FC<Props> = ({ onClose, dataSources }) => {
  const { connectedStores } = dataSources;
  const shopifyStoreWithoutAllPermissions = connectedStores.find(
    (store): store is ShopifyConnectedStore => store.type === "Shopify" && !store.hasAllPermissions
  );
  const { connectShopifyStore, isLoading: isConnectingShopifyStore } = useConnectShopifyStore();

  return (
    <ModalV4 onClose={onClose}>
      <ModalV4.Header>Data sources</ModalV4.Header>

      <ModalV4.Body>
        <ModalV4.Paragraph>
          Connect data sources so AI Analyst can better answer questions about your business.
        </ModalV4.Paragraph>

        <div className="mt-2">
          <DataSourceItem>
            <DataSourceItemMain>
              <DataSourcesItemHeader>
                <HighbeamLogo size={20} />
                <DataSourcesItemHeading>Your Highbeam data</DataSourcesItemHeading>
              </DataSourcesItemHeader>
              <DataSourcesItemParagraph>
                Data from your Highbeam accounts, lines of credit, bill, and cards.
              </DataSourcesItemParagraph>
            </DataSourceItemMain>
          </DataSourceItem>

          <DataSourceItem>
            <DataSourceItemMain>
              <DataSourcesItemHeader>
                <BanksAndCards size={20} className="text-grey-900" />
                <DataSourcesItemHeading>Banks and cards</DataSourcesItemHeading>
                <DataSourcesPlatformAvatarGroup
                  plaidAccounts={dataSources.plaidAccounts}
                  truncateAt={6}
                />
              </DataSourcesItemHeader>
            </DataSourceItemMain>
            <DataSourceItemActions>
              <ButtonLink to="/settings/banks-and-cards" size="sm">
                Add connection <ArrowRight size={16} />
              </ButtonLink>
            </DataSourceItemActions>
          </DataSourceItem>

          <DataSourceItem>
            <DataSourceItemMain>
              <DataSourcesItemHeader>
                <Storefront
                  size={20}
                  className={cn("text-grey-900", {
                    "text-yellow-600": shopifyStoreWithoutAllPermissions,
                  })}
                />
                <DataSourcesItemHeading
                  className={cn({ "text-yellow-700": shopifyStoreWithoutAllPermissions })}
                >
                  Stores
                </DataSourcesItemHeading>
                {shopifyStoreWithoutAllPermissions && (
                  <Info size={16} className="text-yellow-600" />
                )}
                <DataSourcesPlatformAvatarGroup
                  connectedStores={dataSources.connectedStores}
                  truncateAt={6}
                  includeHighbeam={false}
                />
              </DataSourcesItemHeader>
              {shopifyStoreWithoutAllPermissions && (
                <DataSourcesItemParagraph>
                  Update your permissions to access order data.
                </DataSourcesItemParagraph>
              )}
            </DataSourceItemMain>
            <DataSourceItemActions>
              {shopifyStoreWithoutAllPermissions ? (
                <Button
                  variant="secondary"
                  size="sm"
                  isLoading={isConnectingShopifyStore}
                  onClick={() =>
                    connectShopifyStore({
                      storeName: shopifyStoreWithoutAllPermissions.shopSubdomain,
                      redirectPathKey: "settings",
                    })
                  }
                >
                  Update permissions <ArrowRight size={16} />
                </Button>
              ) : (
                <ButtonLink to="/settings/stores" size="sm">
                  Add connection <ArrowRight size={16} />
                </ButtonLink>
              )}
            </DataSourceItemActions>
          </DataSourceItem>

          <DataSourceItem>
            <DataSourceItemMain>
              <DataSourcesItemHeader>
                <Calculator size={20} className="text-grey-900" />
                <DataSourcesItemHeading>Accounting software</DataSourcesItemHeading>
                <DataSourcesPlatformAvatarGroup
                  accountingConnections={dataSources.accountingConnections}
                  truncateAt={6}
                  includeHighbeam={false}
                />
              </DataSourcesItemHeader>

              <DataSourcesItemParagraph>
                Connect accounting software to access bill data.
              </DataSourcesItemParagraph>
            </DataSourceItemMain>
            <DataSourceItemActions>
              <ButtonLink to="/settings/accounting-software" size="sm">
                Add connection <ArrowRight size={16} />
              </ButtonLink>
            </DataSourceItemActions>
          </DataSourceItem>
        </div>
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.CloseButton variant="ghost" onClick={onClose} />
      </ModalV4.Footer>
    </ModalV4>
  );
};

export default DataSourcesModal;
