import { Minus, Plus } from "@phosphor-icons/react";
import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import Fieldset from "ui/inputs/Fieldset";
import cn from "utils/tailwind/cn";
import { transitionDuration } from "utils/transitions";

type Props = {
  heading: ReactNode;
  disabled?: boolean;
  disabledTooltipContent?: ReactNode;
} & PropsWithChildren;

const CollapsibleFieldset: React.FC<Props> = ({
  heading,
  children,
  disabled,
  disabledTooltipContent,
}) => {
  const fieldsetWrapperRef = useRef<HTMLDivElement>(null);
  const hasChildren = Boolean(children);
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = useCallback(() => {
    if (!hasChildren || disabled) return;
    setIsOpen((currVal) => !currVal);
  }, [hasChildren, disabled]);

  /**
   * It's not possible to do vertical animations in CSS,
   * so we do some magic in the [useEffect] to dynamically set the height to a specific value.
   */
  useEffect(() => {
    const ref = fieldsetWrapperRef.current;
    if (!ref) return;
    if (isOpen) {
      ref.style.height = `${ref.scrollHeight}px`;
      setTimeout(() => (ref.style.height = "auto"), transitionDuration);
    } else {
      ref.style.height = `${ref.scrollHeight}px`;
      setTimeout(() => (ref.style.height = "0"), 0);
    }
  }, [isOpen]);

  const headingIcon = useMemo(
    () =>
      hasChildren ? (
        isOpen ? (
          <Minus size={16} className="ml-auto text-grey-500" />
        ) : (
          <Plus size={16} className="ml-auto text-grey-500" />
        )
      ) : null,
    [hasChildren, isOpen]
  );

  return (
    <div
      className={cn("flex max-w-[612px] flex-col overflow-hidden", { "overflow-visible": isOpen })}
    >
      <ButtonWithTooltip
        variant="tertiary"
        onClick={toggleIsOpen}
        className={cn("w-full justify-start gap-x-3 px-5", { "bg-grey-100": disabled })}
        disabled={disabled}
        tooltip={disabled && disabledTooltipContent}
      >
        {heading}
        {!disabled && headingIcon}
      </ButtonWithTooltip>
      <div className="transition-height h-0 duration-150 ease-in-out" ref={fieldsetWrapperRef}>
        <Fieldset className="border-t border-grey-200 p-5" width="narrow">
          {children}
        </Fieldset>
      </div>
    </div>
  );
};

export default CollapsibleFieldset;
