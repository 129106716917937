import { useMutation } from "@tanstack/react-query";
import { useCurrentBusinessMember } from "modules/business-members/queries/businessMemberQueryHooks";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

const useAcceptVgsiTermsMutation = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const currentBusinessMember = useCurrentBusinessMember();
  const businessMemberGuid: string | null = currentBusinessMember?.guid ?? null;

  return useMutation({
    mutationFn: async () => {
      return highbeamApi.internationalBankAccount.patchTermsAcceptedAt(
        businessGuid,
        businessMemberGuid
      );
    },
  });
};

export default useAcceptVgsiTermsMutation;
