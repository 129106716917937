import useTaskApi from "modules/chat/api/useTaskApi";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { AiTaskCreatorRep, AiTaskRep } from "reps/ai-tasks/AiTaskRep";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import { aiTasksQueryHooks } from "../queries/useAiTasks";

type Variables = Omit<AiTaskCreatorRep, "businessGuid">;

const useCreateAiTaskMutation = (
  additionalOptions: MutationAdditionalOptions<AiTaskRep, Variables> = {}
) => {
  const taskApi = useTaskApi();
  const businessGuid = useBusinessGuid();
  const refreshAiTasks = useRefreshQuery(aiTasksQueryHooks.useQueryKey({}));

  return useMutationWithDefaults(
    {
      mutationFn: (creator) => {
        return taskApi.create({ businessGuid, ...creator });
      },
      onSuccess: async () => {
        await refreshAiTasks();
      },
    },
    additionalOptions
  );
};

export default useCreateAiTaskMutation;
