import { AchReceivedPayment, CheckDeposit, Payment, UnitResponse } from "@highbeam/unit-node-sdk";
import { useQuery, useSuspenseQueries } from "@tanstack/react-query";
import {
  INCOMING_CHECK_DEPOSIT_STATUSES,
  unitCheckDepositsQueryHooks,
} from "modules/unit-co-check-deposits/queries/useUnitCheckDeposits";
import useUnitCoPaymentsQueryOptions, {
  INCOMING_PAYMENT_PARAMS,
} from "modules/unit-co-payments/queries/useUnitCoPaymentsQueryOptions";
import useUnitCoReceivedPaymentsQueryOptions from "modules/unit-co-received-payments/queries/useUnitCoReceivedPaymentsQueryOptions";

import getIncomingPaymentEstDate from "../utils/getIncomingPaymentEstDate";

export type IncomingPayment = Payment | CheckDeposit | AchReceivedPayment;

// NB(alex): I just copied this over, we should audit this function.
const sortIncomingPayments = (a: IncomingPayment, b: IncomingPayment) => {
  // Sort reverse chronologically. Nulls first. If both null, put later createdAt first

  const estA = getIncomingPaymentEstDate(a);
  const estB = getIncomingPaymentEstDate(b);

  if (!estA && !estB) {
    return b.attributes.createdAt.localeCompare(a.attributes.createdAt);
  }
  if (!estA) {
    return -1;
  }
  if (!estB) {
    return 1;
  }
  return estB.localeCompare(estA);
};

type Params = {
  accountId?: string;
};

const useIncomingPayments = (params: Params = {}) => {
  const { accountId } = params;

  return useSuspenseQueries({
    queries: [
      {
        ...unitCheckDepositsQueryHooks.useQueryOptions({
          accountId,
          status: INCOMING_CHECK_DEPOSIT_STATUSES,
        }),
        select: (data: UnitResponse<CheckDeposit[]>) => data.data,
      },
      useUnitCoPaymentsQueryOptions({
        accountId,
        ...INCOMING_PAYMENT_PARAMS,
      }),
      useUnitCoReceivedPaymentsQueryOptions({
        accountId: accountId,
        status: ["Advanced", "Pending", "PendingReview"],
      }),
    ],
    combine: ([
      { data: unitCheckDeposits },
      { data: unitPayments },
      { data: receivedPayments },
    ]) => {
      const incomingPayments: IncomingPayment[] = [
        ...unitCheckDeposits,
        ...unitPayments,
        ...receivedPayments,
      ];
      return incomingPayments.sort(sortIncomingPayments);
    },
  });
};

export default useIncomingPayments;

export const useIncomingPaymentsQueries = (params: Params = {}) => {
  const { accountId } = params;

  const { data: unitCheckDeposits, error: unitCheckDepositsError } =
    unitCheckDepositsQueryHooks.useQuery({
      params: {
        accountId,
        status: INCOMING_CHECK_DEPOSIT_STATUSES,
      },
      select: (data) => data.data,
    });

  // Only needed for Plaid transfers
  const { data: unitPayments, error: unitPaymentsError } = useQuery(
    useUnitCoPaymentsQueryOptions({
      accountId,
      ...INCOMING_PAYMENT_PARAMS,
    })
  );

  const { data: receivedPayments, error: receivedPaymentsError } = useQuery(
    useUnitCoReceivedPaymentsQueryOptions({
      accountId: accountId,
      status: ["Advanced", "Pending", "PendingReview"],
    })
  );

  if (unitCheckDepositsError || unitPaymentsError || receivedPaymentsError) {
    return {
      isError: true as const,
      error: unitCheckDepositsError || unitPaymentsError || receivedPaymentsError,
      isLoading: false as const,
      data: undefined,
    };
  }

  if (!unitCheckDeposits || !unitPayments || !receivedPayments) {
    return {
      isError: false as const,
      error: undefined,
      isLoading: true as const,
      data: undefined,
    };
  }

  const incomingPayments: IncomingPayment[] = [
    ...unitCheckDeposits,
    ...unitPayments,
    ...receivedPayments,
  ];

  return {
    isError: false as const,
    error: undefined,
    isLoading: false as const,
    data: incomingPayments.sort(sortIncomingPayments),
  };
};
