import { cloneElement, FC, ReactElement, ReactNode } from "react";
import Shimmer from "ui/feedback/ShimmerV2";
import { Span } from "ui/typography";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

import getAccountDisplayName from "./getAccountDisplayName";

export type AccountBarIconSize = 16 | 18 | 20 | 24;

const DEFAULT_SIZE = 18;

type AccountBarLoadingProps = {
  size?: AccountBarIconSize;
};

const AccountBarLoading: FC<AccountBarLoadingProps> = ({ size = DEFAULT_SIZE }) => {
  return (
    <div className={cn("inline-flex items-center gap-x-2")}>
      <Shimmer
        className={cn(
          "rounded",
          variants(size, {
            16: "size-4",
            18: "size-[1.125rem]",
            20: "size-5",
            24: "size-6",
          })
        )}
      />

      <Shimmer
        className={variants(size, {
          16: "w-28",
          18: "w-32",
          20: "w-36",
          24: "w-40",
        })}
      />
    </div>
  );
};

type Props = {
  size?: AccountBarIconSize;
  icon?: ReactElement<{ size: number }>;
  accountName: string;
  accountNumber?: string;
  status?: string;
  shortMethodName?: string;
  className?: string;
  textClassName?: string;
  endAdornment?: ReactNode;
  isLoading?: boolean;
};

const AccountBar: FC<Props> = ({
  size = DEFAULT_SIZE,
  icon,
  accountName,
  accountNumber,
  status,
  shortMethodName,
  className,
  textClassName,
  endAdornment,
  isLoading,
}) => {
  if (isLoading) {
    return <AccountBarLoading size={size} />;
  }

  return (
    <Span
      className={cn(
        "inline-flex items-center gap-x-2 font-medium text-grey-800",
        variants(size, {
          16: "text-xs leading-4",
          18: "text-sm leading-[1.125rem]",
          20: "text-md leading-5",
          24: "text-xl leading-6",
        }),
        className
      )}
    >
      {icon && cloneElement(icon, { size: icon.props.size ?? size })}

      <span className={cn("overflow-hidden text-ellipsis", textClassName)}>
        {getAccountDisplayName({ accountName, accountNumber, status })}

        {shortMethodName && (
          <Span className="ml-1 whitespace-nowrap text-grey-400">via {shortMethodName}</Span>
        )}
      </span>

      {endAdornment}
    </Span>
  );
};

export default Object.assign(AccountBar, {
  Loading: AccountBarLoading,
});
