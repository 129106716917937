import quickbooksIcon from "assets/rutter/quickbooks-logo.svg";
import shopifyIcon from "assets/shopify-icon.svg";
import useActiveAccountingPlatformConnection from "modules/accounting-platforms/queries/useActiveAccountingPlatformConnection";
import { useIsBusinessActive } from "modules/business/queries/useBusiness";
import { RecommendConnectingDataChatElementRep } from "modules/chat/reps/ChatElementRep";
import {
  connectedStoresQueryHooks,
  ShopifyConnectedStore,
} from "modules/connected-stores/queries/useConnectedStores";
import VerifyYourBusinessModal, {
  useVerifyYourBusinessModal,
} from "modules/onboarding/dialogs/VerifyYourBusinessModal";
import PlaidInstitutionLogo from "modules/plaid/components/PlaidInstitutionLogo";
import { plaidAccountsQueryHooks } from "modules/plaid/queries/usePlaidAccounts";
import { FC } from "react";
import BillPayIcon from "ui/icons/BillPayIcon";
import { HighbeamLogo } from "ui/icons/logos/logos-square";
import PlatformAvatar from "ui/icons/PlatformAvatar";
import PlatformAvatarGroup from "ui/icons/PlatformAvatarGroup";
import Button from "ui/inputs/Button";
import ButtonLink from "ui/inputs/ButtonLink";
import variants from "utils/ts/variants";

import ParagraphChatElement from "./ParagraphChatElement";

const useDataConnectivityState = () => {
  const hasAccountingConnection = useActiveAccountingPlatformConnection() !== null;
  const hasAnyPlaidAccounts = plaidAccountsQueryHooks.useData({ params: {} }).length > 0;
  const hasHighbeamBankAccounts = useIsBusinessActive();
  const { data: connectedStores = [] } = connectedStoresQueryHooks.useQuery({ params: {} });
  const shopifyStore = connectedStores.find(
    (store): store is ShopifyConnectedStore => store.type === "Shopify"
  );
  const hasShopifyConnection = shopifyStore !== undefined;
  const hasShopifyStoreLackingPermissions =
    shopifyStore !== undefined && !shopifyStore.hasAllPermissions;
  return {
    hasAccountingConnection,
    hasAnyPlaidAccounts,
    hasHighbeamBankAccounts,
    hasShopifyConnection,
    hasShopifyStoreLackingPermissions,
  };
};

const ConnectBillsNudge: FC = () => {
  const { hasAccountingConnection } = useDataConnectivityState();
  return (
    <>
      {!hasAccountingConnection && (
        <ButtonLink size="sm" variant="tertiary" to="/settings/accounting-software">
          <img src={quickbooksIcon} alt="Quickbooks" className="h-4" />
          Connect accounting software
        </ButtonLink>
      )}
      <ButtonLink size="sm" variant="ghost" to="/bills">
        <BillPayIcon />
        Try Highbeam Bill Pay
      </ButtonLink>
    </>
  );
};

const ConnectFinancialAccountsNudge: FC = () => {
  const hasAnyPlaidAccounts = plaidAccountsQueryHooks.useData({ params: {} }).length > 0;
  const hasHighbeamBankAccounts = useIsBusinessActive();
  const verifyYourBusinessModal = useVerifyYourBusinessModal();
  return (
    <>
      {verifyYourBusinessModal.isOpen && (
        <VerifyYourBusinessModal onClose={verifyYourBusinessModal.close} />
      )}
      {!hasAnyPlaidAccounts && (
        <ButtonLink size="sm" variant="tertiary" to="/settings/banks-and-cards">
          <PlatformAvatarGroup>
            <PlatformAvatar variant="small-grey-circle">
              <PlaidInstitutionLogo institutionId="ins_56" /> {/* Chase */}
            </PlatformAvatar>
            <PlatformAvatar variant="small-grey-circle">
              <PlaidInstitutionLogo institutionId="ins_127989" /> {/* Bank of America */}
            </PlatformAvatar>
          </PlatformAvatarGroup>
          Connect financial accounts
        </ButtonLink>
      )}
      {!hasHighbeamBankAccounts && (
        <Button
          size="sm"
          variant="ghost"
          onClick={() => {
            verifyYourBusinessModal.open({});
          }}
        >
          <HighbeamLogo />
          Try Highbeam banking
        </Button>
      )}
    </>
  );
};

const ConnectShopifyNudge: FC = () => {
  const { hasShopifyConnection, hasShopifyStoreLackingPermissions } = useDataConnectivityState();
  return (
    <>
      {!hasShopifyConnection && (
        <ButtonLink size="sm" variant="tertiary" to="/settings/stores">
          <img src={shopifyIcon} alt="Shopify" className="h-4" />
          Connect Shopify
        </ButtonLink>
      )}
      {hasShopifyConnection && hasShopifyStoreLackingPermissions && (
        // Older connections have insufficient permissions for order data
        <ButtonLink size="sm" variant="tertiary" to="/settings/stores">
          <img src={shopifyIcon} alt="Shopify" className="h-4" />
          Update Shopify permissions
        </ButtonLink>
      )}
    </>
  );
};

const ShopifyDataStillSyncing: FC = () => {
  return (
    <ParagraphChatElement
      element={{
        type: "Paragraph",
        content: [
          {
            type: "Text",
            content:
              "Note: You have connected Shopify data, but it is still syncing. Initial import can take up to 24 hours.",
            bold: false,
            italic: false,
          },
        ],
      }}
    />
  );
};

type Props = {
  element: RecommendConnectingDataChatElementRep;
};

const RecommendConnectingDataChatElement: FC<Props> = ({ element }) => {
  // Special case for state where initial Shopify sync is ongoing.
  // We don't make a recommendation here but instead add a caveat message.
  const { hasShopifyConnection, hasShopifyStoreLackingPermissions } = useDataConnectivityState();
  if (
    element.dataSource === "Shopify" &&
    hasShopifyConnection &&
    !hasShopifyStoreLackingPermissions
  ) {
    return <ShopifyDataStillSyncing />;
  }
  // Main case for providing a recommendation with CTAs.
  const recommendation = variants(element.dataSource, {
    Bills: <ConnectBillsNudge />,
    FinancialAccounts: <ConnectFinancialAccountsNudge />,
    Shopify: <ConnectShopifyNudge />,
  });
  return (
    <div>
      <ParagraphChatElement
        element={{
          type: "Paragraph",
          content: [
            {
              type: "Text",
              content: "This answer could be improved by connecting more data:",
              bold: false,
              italic: false,
            },
          ],
        }}
      />
      <div className="mt-4 flex flex-row items-center gap-4">{recommendation}</div>
    </div>
  );
};

export default RecommendConnectingDataChatElement;
