import { FC, ComponentProps } from "react";
import Markdown from "react-markdown";
import cn from "utils/tailwind/cn";

import styles from "./HighbeamMarkdown.module.css";

const HighbeamMarkdown: FC<ComponentProps<typeof Markdown>> = ({ className, ...props }) => {
  return <Markdown className={cn(styles.markdown, className)} {...props} />;
};

export default HighbeamMarkdown;
