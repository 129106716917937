import MultiStep from "layouts/MultiStep";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import Helper from "ui/inputs/Helper";
import TextArea from "ui/inputs/TextArea";

import { useDrawdownContext, useDrawdownInvoiceFormContext } from "../context/DrawdownProvider";

import DrawdownInvoiceUploadSection from "./DrawdownInvoiceUploadSection";

const DRAW_DOWN_INVOICE_FORM_ID = "draw-down-invoice-form";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const DrawdownInvoiceView: FC<Props> = ({ capitalAccountSummary }) => {
  const { state } = useLocation();
  const { nextPathname, prevPathname } = useDrawdownContext();

  const form = useDrawdownInvoiceFormContext();

  const navigate = useNavigate();

  const onSubmit = form.handleSubmit((_data) => {
    // NB(alex): Do not copy this pattern -- I think the correct pattern should be to save the validated data in a shared store, and to derive the inputs or keep them in a provider.
    if (nextPathname) {
      navigate(nextPathname, {
        state: {
          ...state,
          drawdownInvoiceFormInputs: form.getValues(),
        },
      });
    }
  });

  return (
    <MultiStep.Form id={DRAW_DOWN_INVOICE_FORM_ID} onSubmit={onSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>Upload invoice</MultiStep.Section.Header.Heading>
          <MultiStep.Section.Header.Subheading>
            Upload the invoice you plan to pay with this drawdown. Highbeam will review the invoice
            to verify that the drawdown is for inventory spend. Review can take{" "}
            <strong>up to 3 business days.</strong>
          </MultiStep.Section.Header.Subheading>
        </MultiStep.Section.Header>

        <MultiStep.Section>
          <DrawdownInvoiceUploadSection />
        </MultiStep.Section>

        <MultiStep.Section>
          <Controller
            control={form.control}
            name="reasonForDrawdown"
            render={({ field, fieldState: { error } }) => (
              <div>
                <TextArea placeholder="Reason for drawdown" {...field}>
                  <TextArea.Input />
                </TextArea>
                {error && <Helper iconVariant="error">{error.message}</Helper>}
              </div>
            )}
          />
        </MultiStep.Section>
      </MultiStep.Section>

      <MultiStep.Controls>
        <MultiStep.Controls.BackButtonLink
          to={prevPathname || `/capital/${capitalAccountSummary.guid}`}
          state={state}
        />

        <MultiStep.Controls.NextButton form={DRAW_DOWN_INVOICE_FORM_ID}>
          Next
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

export default DrawdownInvoiceView;
