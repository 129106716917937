import Fuse from "fuse.js";
import { useMemo } from "react";

const useMatchKeywords = (search: string, keywords: string[], threshold = 0.3) => {
  const fuse = useMemo(() => {
    return new Fuse([...keywords, keywords.join(" ")], {
      threshold: threshold,
      shouldSort: true,
      isCaseSensitive: false,
    });
  }, [keywords, threshold]);

  return useMemo(() => {
    return fuse.search(search).length > 0;
  }, [fuse, search]);
};

export { useMatchKeywords };
