import useMessageProfileApi from "modules/chat/api/useMessageProfileApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

type Params = {
  exchangeId: string;
};

export const chatExchangeDebugInfoQueryHooks = makeQueryHooks({
  name: "chatExchangeDebugInfo",
  useQueryVariables: ({ exchangeId }: Params) => {
    return { exchangeId: exchangeId };
  },
  useQueryFnMaker: ({ exchangeId }) => {
    const messageProfileApi = useMessageProfileApi();
    return () => messageProfileApi.getByMessage(exchangeId);
  },
});
