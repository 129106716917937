import { FEATURE_FLAGS } from "flags";
import useBusinessAddress from "modules/business-details/queries/useBusinessAddress";
import useNonTerminatedCapitalAccounts from "modules/capital-accounts/hooks/useNonTerminatedCapitalAccounts";
import useMostRecentCreditApplication from "modules/credit-application/queries/useMostRecentCreditApplication";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import CreditApplicationRep from "reps/CreditApplicationRep";

const useIsAllowedToChangeAddressToCalifornia = () => {
  const californiaEnabledForCapital = useFeatureFlag(
    FEATURE_FLAGS["capital-allow-ca-credit-applications"]
  );
  const { state } = useBusinessAddress();

  const mostRecentCreditApplication = useMostRecentCreditApplication();
  const hasInReviewCreditApplication =
    mostRecentCreditApplication &&
    CreditApplicationRep.inReviewCreditApplicationStates.includes(
      mostRecentCreditApplication.state as CreditApplicationRep.InReviewState
    );
  const nonTerminatedCapitalAccounts = useNonTerminatedCapitalAccounts();
  const hasNonTerminatedCapitalAccount = nonTerminatedCapitalAccounts.length > 0;
  const hasActiveCapitalProduct = hasInReviewCreditApplication && hasNonTerminatedCapitalAccount;

  // Always allow users to change their address if already in California.
  const businessAddressIsAlreadyInCalifornia = state === "CA";
  if (businessAddressIsAlreadyInCalifornia) {
    return true;
  }

  // Always allow users to change their address if FF is enabled.
  if (californiaEnabledForCapital) {
    return true;
  }

  // Allow users to change their address to California if they don't have any active capital products.
  return !hasActiveCapitalProduct;
};

export default useIsAllowedToChangeAddressToCalifornia;
