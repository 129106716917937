import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useHasPermission from "utils/permissions/useHasPermission";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";

import { ROOT_CONNECTED_STORES_QUERY_KEY } from "./useRefreshAllConnectedStoresQueries";

// NB(alex): We should move rutter stuff out of "connected-stores".

type Params = {
  enabled: boolean;
  businessGuid: string;
};

const rutterConnectionsQueryHooks = makeQueryHooksV2({
  useDefaultParams: () => {
    const businessGuid = useBusinessGuid();
    const hasPermission = useHasPermission("storeConnection:read");
    return {
      enabled: hasPermission,
      businessGuid,
    };
  },
  makeQueryKey: ({ businessGuid }: Params) => {
    return [ROOT_CONNECTED_STORES_QUERY_KEY, "rutterConnections", { businessGuid }] as const;
  },
  useQueryFnParams: () => {
    const highbeamApi = useHighbeamApi();
    return { highbeamApi };
  },
  makeQueryFn: ({ highbeamApi }, { businessGuid, enabled }) => {
    return () => {
      if (!enabled) return [];
      return highbeamApi.rutterConnection.getByBusiness(businessGuid);
    };
  },
});

export { rutterConnectionsQueryHooks };
