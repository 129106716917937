import { Card } from "@highbeam/unit-node-sdk";
import { captureException } from "@sentry/react";
import CardChip from "assets/card-chip.svg?react";
import CardholderInitialsAndFullName from "components/CardholderInitialsAndFullName";
import { FEATURE_FLAGS } from "flags";
import CardStatusPill from "modules/cards/components/CardStatusPill";
import { getCardName } from "modules/cards/utils";
import getCardTypeByCard from "modules/cards/utils/getCardTypeByCard";
import {
  checkIsPhysicalCard,
  checkIsPhysicalCreditCard,
  checkIsPhysicalDebitCard,
  checkIsVirtualChargeCard,
  checkIsVirtualDebitCard,
} from "modules/cards/utils/typeguards";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import isMfaInProgressState from "modules/mfa/isMfaInProgressState";
import useMfa, { MfaCanceledError } from "modules/mfa/useMfa";
import { forwardRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { highbeamCardSizeClasses } from "ui/feedback/CardShimmer";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import { Span } from "ui/typography";
import useIsAllowedToReadCardNumber from "utils/permissions/useIsAllowedToReadCardNumber";
import cn from "utils/tailwind/cn";

import styles from "./HighbeamCard.module.scss";
import HighbeamCardCvv from "./HighbeamCardCvv";
import HighbeamCardDetailsButton from "./HighbeamCardDetailsButton";
import HighbeamCardExpiry from "./HighbeamCardExpiry";
import HighbeamCardFraud from "./HighbeamCardFraud";
import HighbeamCardLocked from "./HighbeamCardLocked";
import HighbeamCardLogo from "./HighbeamCardLogo";
import HighbeamCardNumber from "./HighbeamCardNumber";
import HighbeamCardTypePills from "./HighbeamCardTypePills";
import VisaLogo from "./VisaLogo";

type Props = {
  card: Card;
  useHighbeamLogo?: boolean;
  showDetailsButton?: boolean;
  /**
   * Shows the "fraud" or "locked" status badges. Not the greatest prop (legacy).
   */
  showStatus?: boolean;
  showActivateCardButton?: boolean;
  clickable: boolean;
  className?: string;
};

const HighbeamCard = forwardRef<HTMLDivElement, Props>(
  (
    {
      card,
      useHighbeamLogo,
      showDetailsButton,
      showStatus = true,
      showActivateCardButton,
      clickable,
      className,
      ...divProps
    },
    ref
  ) => {
    const isAllowedToReadCardNumber = useIsAllowedToReadCardNumber(card);
    const fetchArchivedCardsDetails = useFeatureFlag(FEATURE_FLAGS["archived-card-details"]);

    const { mfa } = useMfa();
    const isMfaInProgress = useRecoilValue(isMfaInProgressState);

    const isLoading = isAllowedToReadCardNumber && isMfaInProgress;
    const isLocked = card.attributes.status === "Frozen";
    const isActive = card.attributes.status === "Active";
    const isFraud = card.attributes.status === "SuspectedFraud";
    const isArchived = card.attributes.status === "ClosedByCustomer";

    const shouldFetchSensitiveData =
      isActive || isLocked || (isArchived && Boolean(fetchArchivedCardsDetails));

    const [isRevealed, setIsRevealed] = useState(false);

    const handleHide = () => {
      setIsRevealed(false);
    };

    const handleReveal = async () => {
      try {
        // `isMfaComplete` triggers sensitive data fetching in `HighbeamCardSensitiveData`.
        await mfa();
        setIsRevealed(true);
      } catch (error) {
        if (!(error instanceof MfaCanceledError)) {
          notify("error", "Something went wrong! Please try again.");
          captureException(error);
        }
      }
    };

    const isPhysicalCard = checkIsPhysicalDebitCard(card) || checkIsPhysicalCreditCard(card);
    const textColor = isPhysicalCard ? "white" : "black";
    const showArchived = isArchived;

    return (
      <div
        ref={ref}
        className={cn(
          // NB(alex): `will-change-transform` fixes a very strange "subpixel rendering" issue. https://chatgpt.com/share/67493fd0-40f8-8003-889b-112ac81771a7
          clickable &&
            "cursor-pointer transition-transform duration-100 ease-in-out will-change-transform hover:-translate-y-1",
          className
        )}
        {...divProps}
      >
        <div
          className={cn(
            "relative flex select-none flex-col justify-between p-4 tablet:p-6",
            highbeamCardSizeClasses,
            checkIsPhysicalDebitCard(card) && styles.containerBorderPhysical,
            checkIsPhysicalCreditCard(card) && styles.containerBorderPhysicalCredit,
            (checkIsVirtualDebitCard(card) || checkIsVirtualChargeCard(card)) &&
              styles.containerBorderVirtual,
            checkIsVirtualChargeCard(card) && styles.containerChargeCard,
            checkIsVirtualDebitCard(card) && styles.containerDebitCard
          )}
        >
          <div className="flex items-center justify-between">
            {useHighbeamLogo ? (
              <HighbeamCardLogo isPhysicalCard={isPhysicalCard} />
            ) : (
              <Span
                className={cn(
                  "pr-2 text-sm font-bold",
                  isPhysicalCard ? "text-white" : "text-black"
                )}
              >
                {getCardName(card)}
              </Span>
            )}
            {showDetailsButton && isAllowedToReadCardNumber && (
              <HighbeamCardDetailsButton
                isPhysicalCard={isPhysicalCard}
                isRevealed={isRevealed}
                isLoading={isLoading}
                onHide={handleHide}
                onReveal={handleReveal}
              />
            )}
            {showStatus && isFraud && <HighbeamCardFraud />}
            {showStatus && !isFraud && isLocked && <HighbeamCardLocked />}
            {showArchived && <CardStatusPill status="ClosedByCustomer" />}
            {showActivateCardButton && (
              <Button className="rounded-xl bg-white" size="sm" variant="plain" tabIndex={-1}>
                Activate
              </Button>
            )}
          </div>

          <div className="flex flex-col gap-y-4">
            <div className="flex items-center justify-between">
              <HighbeamCardTypePills cardType={getCardTypeByCard(card)} />

              {checkIsPhysicalCard(card) && <CardChip />}
            </div>

            <HighbeamCardNumber
              textColor={textColor}
              isLoading={isLoading}
              isRevealed={isRevealed}
              isAvailable={shouldFetchSensitiveData}
              card={card}
            />

            <div className="flex gap-x-10">
              <HighbeamCardExpiry
                textColor={textColor}
                card={card}
                isAvailable={shouldFetchSensitiveData}
              />
              <HighbeamCardCvv
                textColor={textColor}
                isLoading={isLoading}
                isRevealed={isRevealed}
                isAvailable={shouldFetchSensitiveData}
                card={card}
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <CardholderInitialsAndFullName cardId={card.id} />
            <VisaLogo isPhysicalCard={isPhysicalCard} />
          </div>
        </div>
      </div>
    );
  }
);

export default HighbeamCard;
