import { IncomingPayment } from "../queries/useIncomingPayments";

const getIncomingPaymentEstDate = (payment: IncomingPayment): string | null => {
  switch (payment.type) {
    case "checkDeposit":
      return payment.attributes.settlementDate ?? null;
    case "achReceivedPayment":
      return payment.attributes.completionDate ?? null;
    case "achPayment":
      if (payment.attributes.status === "Clearing") {
        return payment.attributes.settlementDate ?? null;
      }
      return null;
    case "bookPayment":
    case "wirePayment":
      // NB(alex): I'm pretty sure wire & book payments always resolve same-day so aren't actually relevant for the `IncomingPayment` type.
      return null;
  }
};

export default getIncomingPaymentEstDate;
