import { useMutation } from "@tanstack/react-query";
import useBankAccountApi from "modules/bank-accounts/api/useBankAccountApi";
import { useRefreshAllBankAccountQueries } from "modules/bank-accounts/queries/useBankAccounts";
import useMfa from "modules/mfa/useMfa";
import { notify } from "ui/feedback/Toast";

import { useCloseBankAccountModal } from "../state/closeBankAccountModalState";

const useCloseBankAccountMutation = () => {
  const bankAccountApi = useBankAccountApi();
  const { mfa } = useMfa();
  const { close: closeBankAccountModal } = useCloseBankAccountModal();

  const refreshBankAccountsQuery = useRefreshAllBankAccountQueries();

  return useMutation({
    mutationFn: async (accountGuid: string) => {
      await mfa();
      return bankAccountApi.close(accountGuid);
    },
    onError: () => {
      notify("error", "Something went wrong! Please try again.");
    },
    onSuccess: async () => {
      notify("success", "Account closed");
      refreshBankAccountsQuery();
      closeBankAccountModal();
    },
  });
};

export default useCloseBankAccountMutation;
