import { FEATURE_FLAGS } from "flags";
import DashboardLayout from "layouts/Dashboard";
import useApEmailAliases from "modules/ap-email-aliases/queries/useApEmailAliases";
import useShouldShowEmployeeView from "modules/bills/hooks/useShouldShowEmployeeView";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import RemapRedirector from "modules/navigation/RemapRedirector";
import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import useIsAllowedToNavigateToBillPayRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToBillPayRoutes";
import useHasPermission from "utils/permissions/useHasPermission";

const BillsPage = lazy(() => import("pages/bills/BillsPage"));
const BillsSettingsPage = lazy(() => import("pages/bills/BillsSettingsPage"));
const BillsSyncErrorsPage = lazy(() => import("pages/bills/BillsSyncErrorsPage"));

const BillPayRoutesRenderer: FC = () => {
  const isAllowedToNavigateToBillPayRoutes = useIsAllowedToNavigateToBillPayRoutes();

  const billPayEnabled = useFeatureFlag(FEATURE_FLAGS["bill-pay-ui"]);

  const apEmailAliases = useApEmailAliases();
  const shouldShowBillsEmployeeView = useShouldShowEmployeeView();
  const isAllowedToNavigateToBillPaySettings = useHasPermission("accountsPayableSettings:read");
  const isAllowedToNavigateToBillsSyncErrors = useHasPermission("accounting:read");

  if (
    isAllowedToNavigateToBillPayRoutes &&
    (!shouldShowBillsEmployeeView || apEmailAliases.length > 0)
  ) {
    return (
      <Routes>
        <Route
          path="*"
          element={
            billPayEnabled ? (
              <DashboardLayout>
                <Routes>
                  {isAllowedToNavigateToBillPaySettings && (
                    <Route path="/settings" element={<BillsSettingsPage />} />
                  )}
                  {isAllowedToNavigateToBillsSyncErrors && (
                    <Route path="/sync-errors/*" element={<BillsSyncErrorsPage />} />
                  )}
                  <Route path="*" element={<BillsPage />} />
                </Routes>
              </DashboardLayout>
            ) : null
          }
        />
        {/* Legacy routes */}
        <Route
          path="/payments/bills/*"
          element={<RemapRedirector oldPath="/payments/bills" newPath="/bills" />}
        />
      </Routes>
    );
  }

  return null;
};

export default BillPayRoutesRenderer;
