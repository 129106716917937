import { ArrowRight } from "@phosphor-icons/react";
import { CSSProperties, FC, ForwardedRef, forwardRef, ReactNode, RefObject, useRef } from "react";
import { Controller } from "react-hook-form";
import useChatExchangeForm, {
  type ChatExchangeFormInputs,
  type ChatExchangeFormOutputs,
} from "ui/chat/ChatExchangeForm/useChatExchangeForm";
import Button from "ui/inputs/Button";
import TextArea from "ui/inputs/TextArea";
import { composeRefs, useComposedRefs } from "utils/react-helpers/compose-refs";
import createComponentContext from "utils/react-helpers/createComponentContext";
import cn from "utils/tailwind/cn";

type ChatExchangeFormContextValue = {
  form: ReturnType<typeof useChatExchangeForm>;
  formRef: RefObject<HTMLFormElement>;
};

const [ChatExchangeFormProvider, useChatExchangeFormContext] =
  createComponentContext<ChatExchangeFormContextValue>("ChatExchangeForm");

type Props = {
  onSubmit: (data: ChatExchangeFormOutputs) => Promise<void>;
  form: ReturnType<typeof useChatExchangeForm>;
  className?: string;
  children?: ReactNode;
};

const ChatExchangeForm = forwardRef<HTMLFormElement, Props>(
  ({ onSubmit, form, className, children }, forwardedRef) => {
    const internalFormRef = useRef<HTMLFormElement>(null);
    const formRef = useComposedRefs(internalFormRef, forwardedRef);

    return (
      <ChatExchangeFormProvider value={{ form, formRef: internalFormRef }}>
        <form
          ref={formRef}
          className={cn("relative w-full", className)}
          onSubmit={form.handleSubmit(onSubmit)}
        >
          {children}
        </form>
      </ChatExchangeFormProvider>
    );
  }
);

type ChatExchangeFormTextAreaProps = {
  textAreaRef?: ForwardedRef<HTMLTextAreaElement>;
  className?: string;
  inputClassName?: string;
  inputStyle?: CSSProperties;
};

const ChatExchangeFormTextArea: FC<ChatExchangeFormTextAreaProps> = ({
  className,
  inputClassName,
  inputStyle,
  textAreaRef,
}) => {
  const { form, formRef } = useChatExchangeFormContext();

  return (
    <Controller
      name="content"
      control={form.control}
      render={({ field: { ref, ...field } }) => {
        return (
          <TextArea
            {...field}
            className={cn("bg-white", className)}
            rows={3}
            placeholder="Ask any question about your business’s finances."
            autoFocus
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                formRef.current!.requestSubmit();
              }
            }}
          >
            <TextArea.Input
              ref={composeRefs(ref, textAreaRef)}
              className={cn("pr-11", inputClassName)}
              style={inputStyle}
            />
          </TextArea>
        );
      }}
    />
  );
};

type ChatExchangeFormSubmitButtonProps = {
  className?: string;
};

const ChatExchangeFormSubmitButton: FC<ChatExchangeFormSubmitButtonProps> = ({ className }) => {
  const { form } = useChatExchangeFormContext();
  return (
    <Button
      type="submit"
      className={cn("absolute right-3 top-3 h-8 w-8 p-0", className)}
      variant="primary"
      disabled={!form.formState.isValid}
      isLoading={form.formState.isSubmitting}
    >
      {!form.formState.isSubmitting && <ArrowRight size={14} />}
    </Button>
  );
};

export { useChatExchangeForm, type ChatExchangeFormInputs, type ChatExchangeFormOutputs };

export default Object.assign(ChatExchangeForm, {
  TextArea: ChatExchangeFormTextArea,
  SubmitButton: ChatExchangeFormSubmitButton,
});
