import useCreateBankApplicationMutation from "modules/bank-application/mutations/useCreateBankApplicationMutation";
import { useBankApplication } from "modules/bank-application/queries/useBankApplicationQueryOptions";
import { useIsBusinessActive } from "modules/business/queries/useBusiness";
import { FC } from "react";
import AccentCard from "ui/data-display/AccentCard";
import ButtonLink from "ui/inputs/ButtonLink";
import ModalV4 from "ui/overlay/ModalV4";
import useMountEffect from "utils/customHooks/useMountEffect";
import useModalV2 from "utils/dialog/useModalV2";

import VerifyYourBusinessWhatYoullNeed from "../components/verify-business/VerifyYourBusinessWhatYoullNeed";

export const useVerifyYourBusinessModal = () => {
  return useModalV2();
};

const VerifyYourBusinessModalContent: FC = () => {
  const bankApplication = useBankApplication();
  const { mutate: createBankApplication } = useCreateBankApplicationMutation();
  const isBusinessActive = useIsBusinessActive();

  // NB(alex): Should never be opened if the business is already active. If this error gets thrown, the checks in place are incorrect.
  //  This modal creates a banking application so we need to offensively crash the modal before a new banking application gets created.
  if (isBusinessActive) {
    throw new Error("Business is already active.");
  }

  useMountEffect(() => {
    if (!bankApplication) {
      createBankApplication();
    }
  });

  const bankApplicationUrl = bankApplication?.url;

  if (!bankApplicationUrl) {
    return <ModalV4.LoadingFallback />;
  }

  return (
    <div>
      <ModalV4.Header>Verify business</ModalV4.Header>
      <ModalV4.Body>
        <AccentCard withTopBorder className="border-t-purple-300">
          <VerifyYourBusinessWhatYoullNeed />
        </AccentCard>
        <ModalV4.Paragraph className="mt-6 text-sm">
          This information is necessary to ensure security and compliance with federal regulations.
          Your information is secure and will never be shared.
        </ModalV4.Paragraph>
      </ModalV4.Body>
      <ModalV4.Footer>
        <ButtonLink variant="primary" to={bankApplicationUrl}>
          Continue
        </ButtonLink>
      </ModalV4.Footer>
    </div>
  );
};

type Props = {
  onClose: () => void;
};

const VerifyYourBusinessModal: FC<Props> = ({ onClose }) => {
  return (
    <ModalV4 onClose={onClose} className="max-w-xl">
      <VerifyYourBusinessModalContent />
    </ModalV4>
  );
};

export default VerifyYourBusinessModal;
