import { Calculator } from "@phosphor-icons/react";
import classNames from "classnames";
import PublicLayout from "layouts/PublicLayout";
import { CREDIT_COMPARISON_PATH } from "pages/capital/paths";
import { Suspense } from "react";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import showConfirmResetModalState from "state/capital/creditCalculator/showConfirmResetModal";
import allStepsState from "state/compareCreditOffers/allSteps";
import CopyLink from "ui/data-display/CopyLink";
import SectionHeader from "ui/data-display/SectionHeader";
import DotsPageLoader from "ui/feedback/DotsLoader/DotsPageLoader";
import IconTile from "ui/icons/IconTile";
import StepsV2 from "ui/navigation/StepsV2";
import { Heading2 } from "ui/typography";
import Text from "ui/typography/Text";

import styles from "./CompareCreditOffersPage.module.scss";
import ConfirmResetModal from "./components/ConfirmResetModal";
import CreateYourOwnButton from "./components/CreateYourOwnButton";
import WhatsTheAprHeaderLogo from "./components/WhatsTheAprHeaderLogo";
import useIsMatchingSessionAndSnapshot from "./hooks/useIsMatchingSessionAndSnapshot";
import useResetComparisonSession from "./hooks/useResetComparisonSession";
import useSaveEmailParamToSession from "./hooks/useSaveEmailParamToSession";
import getStepIndexFromPathname from "./utils/getStepIndexFromPathname";
import CreditComparisonTableView from "./views/CreditComparisonTableView";
import CreditOfferDetailsForm from "./views/CreditOfferDetailsForm";
import RevenueProjectionForm from "./views/RevenueProjectionForm";
import SelectCreditOfferProvidersForm from "./views/SelectCreditOfferProvidersForm";

const CreditComparisonPage = () => {
  useSaveEmailParamToSession();
  const resetComparisonSession = useResetComparisonSession();

  const [showConfirmResetModal, setShowConfirmResetModal] = useRecoilState(
    showConfirmResetModalState
  );

  const allSteps = useRecoilValue(allStepsState);
  const { pathname } = useLocation();

  const currentStepIndex = getStepIndexFromPathname(allSteps, pathname);
  const isResultsStep = currentStepIndex === allSteps.length - 1;

  const isMatchingSessionAndSnapshot = useIsMatchingSessionAndSnapshot();

  return (
    <PublicLayout
      header={
        <PublicLayout.Header
          logo={<WhatsTheAprHeaderLogo />}
          actions={
            <PublicLayout.Header.HeaderActions
              authenticatedActions={
                <Link to={"/"}>
                  <Text size={14}>Go back to Highbeam</Text>
                </Link>
              }
            />
          }
        />
      }
    >
      <div className={styles.container}>
        {showConfirmResetModal && (
          <ConfirmResetModal
            isLoading={false}
            onClick={() => resetComparisonSession()}
            onClose={() => setShowConfirmResetModal(false)}
          />
        )}

        <div className={styles.header}>
          <SectionHeader
            actions={
              isResultsStep && (
                <>
                  {isMatchingSessionAndSnapshot ? (
                    <CopyLink link={window.location.href} text="Copy share link" />
                  ) : (
                    <CreateYourOwnButton />
                  )}
                </>
              )
            }
          >
            <IconTile icon={<Calculator />} />
            <Heading2>Credit comparison</Heading2>
          </SectionHeader>
        </div>
        <div className={styles.main}>
          {isResultsStep && !isMatchingSessionAndSnapshot ? null : (
            <div
              className={classNames(
                styles.stepsContainer,
                isResultsStep && styles.stepsContainerTableActive
              )}
            >
              {<StepsV2 steps={allSteps} currentStepIndex={currentStepIndex} />}
            </div>
          )}
          <Suspense fallback={<DotsPageLoader variant="section" />}>
            <Routes>
              <Route index element={<SelectCreditOfferProvidersForm />} />
              <Route path="revenue-projection" element={<RevenueProjectionForm />} />
              <Route path=":offer" element={<CreditOfferDetailsForm />} />
              <Route path="results/:snapshotSlug" element={<CreditComparisonTableView />} />
              <Route path="*" element={<Navigate to={CREDIT_COMPARISON_PATH} />} />
            </Routes>
          </Suspense>
        </div>
      </div>
    </PublicLayout>
  );
};

export default CreditComparisonPage;
