import { useState, useEffect, FC } from "react";
import cn from "utils/tailwind/cn";

type Props = {
  text: string;
  className?: string;
  speed?: number;
  delayAfterFinished?: number;
  onFinished?: () => void;
};

const TypingAnimation: FC<Props> = ({
  text,
  speed = 60,
  onFinished,
  delayAfterFinished = 3000,
  className,
}) => {
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);
  const [isFinishedTyping, setIsFinishedTyping] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line functional/no-let
    let timeout: NodeJS.Timeout | undefined;

    if (index < text.length) {
      timeout = setTimeout(() => {
        setDisplayedText((prev) => prev + text[index]);
        setIndex(index + 1);
      }, speed);
    } else if (index === text.length) {
      setIsFinishedTyping(true);
      setTimeout(() => {
        onFinished?.();
      }, delayAfterFinished);
    }

    return () => clearTimeout(timeout);
  }, [index, text, speed, onFinished, delayAfterFinished]);

  return (
    <span className={className}>
      {displayedText}
      <span className={cn(isFinishedTyping && "animate-blink")}>|</span>
    </span>
  );
};

export default TypingAnimation;
