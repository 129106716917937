import { FC } from "react";

type Props = {
  size?: number;
  className?: string;
};

const BanksAndCards: FC<Props> = ({ size = 24, className }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 22" fill="none" className={className}>
      <path
        d="M21.7149 11.7595H10.7897C10.5383 11.7595 10.3345 11.9633 10.3345 12.2147V19.4982C10.3345 19.7496 10.5383 19.9534 10.7897 19.9534H21.7149C21.9663 19.9534 22.1701 19.7496 22.1701 19.4982V12.2147C22.1701 11.9633 21.9663 11.7595 21.7149 11.7595Z"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5286 17.8683H20.3494"
        stroke="currentColor"
        strokeWidth="1.39492"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3345 14.0327H22.1701"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16032 2.09642C8.95141 1.96786 8.68791 1.96786 8.479 2.09642L1.82146 6.19337C1.57379 6.34578 1.45749 6.64416 1.53669 6.92397C1.61589 7.20379 1.87131 7.39695 2.16212 7.39695H3.56059V11.2181H2.67424C2.31525 11.2181 2.02424 11.5091 2.02424 11.8681C2.02424 12.2271 2.31525 12.5181 2.67424 12.5181H4.21059H7.2833H7.96856V11.2181H7.9333V7.39695H9.70601V9.70529H11.006V7.39695H12.7787V9.70529H14.0787V7.39695H15.4772C15.768 7.39695 16.0234 7.20379 16.1026 6.92397C16.1818 6.64416 16.0655 6.34578 15.8179 6.19337L9.16032 2.09642ZM7.96856 13.2666V14.5666H1.65C1.29101 14.5666 1 14.2756 1 13.9166C1 13.5576 1.29101 13.2666 1.65 13.2666H7.96856ZM4.86059 11.2181V7.39695H6.6333V11.2181H4.86059ZM4.4586 6.09695H7.2833H10.356H13.1807L8.81966 3.41322L4.4586 6.09695Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BanksAndCards;
