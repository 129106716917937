import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";
import AccountingAccountRep from "reps/AccountingAccountRep";

export type AccountingAccountApi = {
  get: (accountingAccountId: string) => Promise<AccountingAccountRep.Complete>;
  setAccount: (
    accountingAccountId: string,
    body: AccountingAccountRep.Updater
  ) => Promise<AccountingAccountRep.Complete>;
  search: (businessGuid: string) => Promise<AccountingAccountRep.Complete[]>;
};

const builder: ApiBuilder<AccountingAccountApi> = (api) => ({
  get: async (accountingAccountId) => {
    const url = `/accounting/accounts/${accountingAccountId}`;
    return (await api.get<AccountingAccountRep.Complete>(url))!;
  },
  setAccount: async (accountingAccountId, body) => {
    const url = `/accounting/accounts/${accountingAccountId}`;
    return (await api.put<AccountingAccountRep.Complete>(url, body))!;
  },
  search: async (businessGuid) => {
    const queryParams = new URLSearchParams({ businessGuid: businessGuid });
    const url = `/accounting/accounts?${queryParams}`;
    return (await api.get<AccountingAccountRep.Complete[]>(url))!;
  },
});

const useAccountingAccountApi = () => useBackendV2Api(builder);

export default useAccountingAccountApi;
