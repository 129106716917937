import {
  ComponentProps,
  ComponentPropsWithoutRef,
  ComponentRef,
  FC,
  forwardRef,
  ReactNode,
  useEffect,
  useState,
} from "react";
import ChatAvatar from "ui/chat/ChatAvatar";
import CircleProgressIndicator from "ui/feedback/CircleProgressIndicator";
import TypingAnimation from "ui/feedback/TypingAnimation";
import { Paragraph } from "ui/typography";
import stringToReactKey from "utils/react-helpers/stringToReactKey";
import cn from "utils/tailwind/cn";

import ReplyInThreadButton from "../ReplyInThreadButton";

import generateLoadingPoints from "./generateLoadingPoints";

// NB(alex): This is hard-coded to match the `ChatAvatar`'s size for proper horizontal spacing. Feel free to pass in the size via prop if it needs to be dynamic.
const NoAvatar: FC<{ className?: string }> = ({ className }) => {
  return <div className={cn("w-8 shrink-0", className)} />;
};

type ChatExchangeBlockProps = ComponentProps<"div"> & {
  avatar?: ReactNode;
};

const ChatExchangeBlock = forwardRef<ComponentRef<"div">, ChatExchangeBlockProps>(
  ({ className, avatar = <NoAvatar />, children, ...props }, ref) => {
    return (
      <div className={cn("flex gap-x-4", className)} ref={ref} {...props}>
        {avatar}

        <div className="w-full flex-1">
          <div
            // NB(alex): This vertically aligns the child element if it is shorter than the avatar (but only when one is passed in).
            className="flex h-full w-full flex-col items-start justify-center"
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
);

const ChatExchangeParagraph = forwardRef<
  ComponentRef<typeof Paragraph>,
  ComponentPropsWithoutRef<typeof Paragraph>
>(({ className, ...props }, ref) => {
  // NB(alex): This assumes the avatar is `h-8`.
  return <Paragraph className={cn("flex min-h-8 items-center", className)} ref={ref} {...props} />;
});

type ChatExchangeCircleProgressIndicatorProps = Omit<
  ComponentPropsWithoutRef<typeof CircleProgressIndicator>,
  "percent"
> & {
  duration?: number;
};

const ChatExchangeCircleProgressIndicator: FC<ChatExchangeCircleProgressIndicatorProps> = ({
  duration = 40_000,
  ...props
}) => {
  // Fake loading state. NB(alex): Had to extract this because it was triggering re-renders.
  const [loadingPercent, setLoadingPercent] = useState(0);
  useEffect(() => {
    const timeouts: NodeJS.Timeout[] = [];
    const loadingPoints = generateLoadingPoints(duration);

    loadingPoints.forEach(({ percentage, timestamp }) => {
      const timeout = setTimeout(() => {
        setLoadingPercent(percentage);
      }, timestamp);
      timeouts.push(timeout);
    });
    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, [duration]);

  return (
    <CircleProgressIndicator
      percent={loadingPercent}
      circleColor="grey-200"
      progressColor="grey-500"
      {...props}
    />
  );
};

type ChatExchangeBlockLoadingProps = ComponentProps<"div"> & {
  duration?: number;
  messages: string[];
};

const ChatExchangeBlockLoading = forwardRef<ComponentRef<"div">, ChatExchangeBlockLoadingProps>(
  ({ className, duration, messages, ...props }, ref) => {
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const currentMessage = messages[currentMessageIndex];

    return (
      <ChatExchangeBlock
        avatar={
          <ChatAvatar>
            <ChatAvatar.Fallback>
              <ChatExchangeCircleProgressIndicator duration={duration} />
            </ChatAvatar.Fallback>
          </ChatAvatar>
        }
        className={className}
        {...props}
        ref={ref}
      >
        <TypingAnimation
          className="text-sm text-grey-500"
          key={stringToReactKey(currentMessage)}
          text={currentMessage}
          onFinished={() => {
            setCurrentMessageIndex((prev) => (prev + 1 === messages.length ? prev : prev + 1));
          }}
        />

        <Paragraph className="text-2xs text-grey-500">Can take up to 1 minute</Paragraph>
      </ChatExchangeBlock>
    );
  }
);

const ChatExchangeReplyInThreadButton = forwardRef<
  ComponentRef<typeof ReplyInThreadButton>,
  ComponentPropsWithoutRef<typeof ReplyInThreadButton>
>(({ className, ...props }, ref) => {
  return (
    <ReplyInThreadButton
      className={cn("-mx-3 w-[calc(100%+1.5rem)] justify-start", className)}
      ref={ref}
      {...props}
    />
  );
});

type Props = {
  children: ReactNode;
  className?: string;
};

const ChatExchange: FC<Props> = ({ children, className }) => {
  return <div className={cn("flex flex-col gap-y-3 py-3 text-sm", className)}>{children}</div>;
};

export default Object.assign(ChatExchange, {
  NoAvatar: NoAvatar,
  Block: ChatExchangeBlock,
  BlockLoading: ChatExchangeBlockLoading,
  Paragraph: ChatExchangeParagraph,
  ReplyInThreadButton: ChatExchangeReplyInThreadButton,
});
