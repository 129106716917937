import { useMutation } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import useBankApplicationApi from "../api/useBankApplicationApi";
import useBankApplicationQueryOptions from "../queries/useBankApplicationQueryOptions";

const useCreateBankApplicationMutation = () => {
  const bankApplicationApi = useBankApplicationApi();
  const businessGuid = useBusinessGuid();
  const refreshBankApplication = useRefreshQuery(useBankApplicationQueryOptions().queryKey);

  return useMutation({
    mutationFn: () => {
      return bankApplicationApi.createBankApplication(businessGuid);
    },
    onSuccess: async () => {
      await refreshBankApplication();
    },
  });
};

export default useCreateBankApplicationMutation;
