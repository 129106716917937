import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";
import { AiTaskCreatorRep, AiTaskRep, AiTaskUpdateRep } from "reps/ai-tasks/AiTaskRep";

type AiTaskApi = {
  listByBusiness: (businessGuid: string) => Promise<AiTaskRep[]>;
  create: (creator: AiTaskCreatorRep) => Promise<AiTaskRep>;
  update: (taskId: string, update: AiTaskUpdateRep) => Promise<AiTaskRep>;
};

const builder: ApiBuilder<AiTaskApi> = (api) => ({
  listByBusiness: async (businessGuid) => {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/chat/ai-tasks?${queryParams}`;
    return (await api.get<AiTaskRep[]>(url))!;
  },
  create: async (creator) => {
    const url = `/chat/ai-tasks`;
    return (await api.post<AiTaskRep>(url, creator))!;
  },
  update: async (taskId, update) => {
    const url = `/chat/ai-tasks/${taskId}`;
    return (await api.patch<AiTaskRep>(url, update))!;
  },
});

const useTaskApi = () => useBackendV2Api(builder);

export default useTaskApi;
