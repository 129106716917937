import { Payment, Relationship, Transaction, UnitError } from "@highbeam/unit-node-sdk";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useUnitApiOrThrow } from "modules/unit-co-customer-token/queries/useUnitApi";

export const UNIT_CO_PAYMENTS_QUERY_KEY = "unit-co-payments";

const useAssociatedPaymentForTransactionInfoFlexpane = (transaction: Transaction) => {
  const unitApi = useUnitApiOrThrow();
  const paymentId = (transaction.relationships.payment as Relationship)?.data.id;

  const { data } = useSuspenseQuery({
    queryKey: [UNIT_CO_PAYMENTS_QUERY_KEY, { paymentId }],
    queryFn: async () => {
      if (paymentId) {
        const response = await unitApi.payments.get(paymentId).catch((err) => {
          // NB(alex): Handles edge case where payment is not found. Prevents the flexpane from crashing for a) Book payments between 2 different unit customers. b) Line of credit transactions. There may be other places, would be good to keep track in this comment if they come up!
          // Context: https://linear.app/highbeam/issue/HB-4265/fix-flexpane-book-payment-bug
          if (err instanceof UnitError) {
            if (err.errors[0].status === "404") {
              return { data: null };
            }
          }
          throw err;
        });

        return response.data;
      }
      if (transaction.type === "wireTransaction") {
        // NB(siva): I'm sorry for this.
        // Unit doesn't return the payment ID for wires, so we have to do a lookup to find the payment. This is a temporary solution until we can get the payment ID from Unit.
        // I limit it to pending payments to avoid fetching all payments, which is a lot of data.
        const { data: pendingPayments } = await unitApi.payments
          .list({
            status: ["Pending", "PendingReview", "Clearing"],
          })
          .catch((err) => {
            if (err instanceof UnitError) {
              if (err.errors[0].status === "404") {
                return { data: null };
              }
            }
            throw err;
          });
        if (!pendingPayments) return null;
        const paymentsByGuid = new Map<string, Payment>(
          pendingPayments.map((payment) => [payment.attributes.tags?.paymentGuid ?? "", payment])
        );
        const paymentGuid = transaction.attributes.tags?.paymentGuid ?? "";

        return paymentsByGuid.get(paymentGuid) ?? null;
      } else {
        return null;
      }
    },
  });

  return data;
};

export default useAssociatedPaymentForTransactionInfoFlexpane;
