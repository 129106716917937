import { ComponentProps, FC } from "react";
import VirtualButton from "ui/inputs/VirtualButton";
import LoomVideoModal from "ui/overlay/video-modal/LoomVideoModal";
import createComponentContext from "utils/react-helpers/createComponentContext";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import useSearchParamBoolean from "utils/search-params/useSearchParamBoolean";
import cn from "utils/tailwind/cn";

import cashflowSheetVideoThumbnail from "../../assets/cashflow-sheet-video-thumbnail.webp";
import OnboardingHero from "../shared/OnboardingHero";

type CashflowSheetViewContextValue = {
  isVideoModalOpen: boolean;
  onOpenVideoModal: () => void;
  onCloseVideoModal: () => void;
};

const [CashflowSheetViewProvider, useCashflowSheetViewContext] =
  createComponentContext<CashflowSheetViewContextValue>("CashflowSheetViewContext");

const WouldYouLikeACashflowSheetView: FC<PropsWithChildrenAndClassName> = ({
  className,
  children,
}) => {
  // NB(alex): This also allows users to swipe "back" on mobile to close the modal.
  const [isVideoModalOpen, setIsVideoModalOpen] = useSearchParamBoolean("video", false);

  return (
    <CashflowSheetViewProvider
      value={{
        isVideoModalOpen,
        onOpenVideoModal: () => setIsVideoModalOpen(true),
        onCloseVideoModal: () => setIsVideoModalOpen(false),
      }}
    >
      <OnboardingHero className={className}>{children}</OnboardingHero>

      {isVideoModalOpen && (
        <LoomVideoModal
          onClose={() => setIsVideoModalOpen(false)}
          loomVideoId="f4aaf68b407c476d9f8fb756507da738"
          title="Cashflow sheet video"
        />
      )}
    </CashflowSheetViewProvider>
  );
};

const VideoTrigger: FC<ComponentProps<typeof VirtualButton>> = ({
  children = "Watch video",
  ...props
}) => {
  const { onOpenVideoModal } = useCashflowSheetViewContext();
  return (
    <VirtualButton {...props} onClick={onOpenVideoModal}>
      {children}
    </VirtualButton>
  );
};

const HeroImage: FC<Omit<ComponentProps<typeof OnboardingHero.HeroImage>, "src" | "alt">> = ({
  ...props
}) => {
  return (
    <OnboardingHero.HeroImage
      src={cashflowSheetVideoThumbnail}
      alt="Cashflow sheet video"
      {...props}
    />
  );
};

const Heading: FC<PropsWithChildrenAndClassName> = ({
  children = "Would you like a cash flow sheet?",
  className,
}) => {
  return (
    <OnboardingHero.Heading
      // Hack - the image is fairly large and contains the drop shadow.
      className={cn("-mt-2", className)}
    >
      {children}
    </OnboardingHero.Heading>
  );
};

const Subheading: FC<PropsWithChildrenAndClassName> = ({
  children = "Highbeam Intelligence will create a live 13-week cash flow sheet with forecasts.\nWe’ll email you once the sheet is ready!",
  className,
}) => {
  return (
    <OnboardingHero.Subheading
      className={cn("whitespace-pre-wrap text-center leading-6", className)}
    >
      {children}
    </OnboardingHero.Subheading>
  );
};

const Footer: FC<ComponentProps<typeof OnboardingHero.Footer>> = (props) => {
  return <OnboardingHero.Footer {...props} />;
};

export default Object.assign(WouldYouLikeACashflowSheetView, {
  VideoTrigger: VideoTrigger,
  HeroImage: HeroImage,
  Heading: Heading,
  Subheading: Subheading,
  Footer: Footer,
});
