import { FC } from "react";
import DotsLoader from "ui/feedback/DotsLoader";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

type Props = PropsWithChildrenAndClassName & {
  isSwitchingPage?: boolean;
};

const PaginatedTableContainer: FC<Props> = ({ isSwitchingPage, children, className }) => (
  <div className={cn("relative flex flex-col gap-y-6", className)}>
    {children}
    {isSwitchingPage && (
      <div className="absolute inset-0 flex items-start justify-center bg-white/90 p-32">
        <DotsLoader dots={5} />
      </div>
    )}
  </div>
);

export default PaginatedTableContainer;
