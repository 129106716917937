import { X } from "@phosphor-icons/react";
import { ComponentProps, FC, useMemo, useRef } from "react";
import Button from "ui/inputs/Button";
import { composeRefs } from "utils/react-helpers/compose-refs";
import cn from "utils/tailwind/cn";

import ModalV4, { ModalRef } from "../ModalV4";

type Props = Omit<ComponentProps<typeof ModalV4>, "children"> & {
  loomVideoId: string;
  title: string;
  autoPlay?: boolean;
  muted?: boolean;
};

const LoomVideoModal: FC<Props> = ({
  className,
  loomVideoId,
  title,
  autoPlay = true,
  muted = false,
  modalRef,
  ...props
}) => {
  const qp = useMemo(() => {
    const qp = new URLSearchParams();
    if (autoPlay) qp.set("autoplay", "1");
    if (muted) qp.set("muted", "1");
    return qp.toString();
  }, [autoPlay, muted]);

  const internalModalRef = useRef<ModalRef>(null);

  return (
    <ModalV4
      {...props}
      className={cn(
        "max-h-[85vh] max-w-[90vw] bg-transparent shadow-none tablet:h-full tablet:w-full",
        className
      )}
      modalRef={composeRefs(internalModalRef, modalRef)}
    >
      <Button
        variant="plain"
        size="xs"
        className="absolute right-4 top-4"
        paddingVariant="square"
        onClick={() => internalModalRef.current?.closeModalWithAnimation()}
      >
        <X className="size-8 text-white" />
      </Button>

      <iframe
        src={`https://www.loom.com/embed/${loomVideoId}?${qp}`}
        title={title}
        className="h-full w-full"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
    </ModalV4>
  );
};

export default LoomVideoModal;
