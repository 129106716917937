import {
  KnowledgeBaseItemCreatorRep,
  KnowledgeBaseItemRep,
  KnowledgeBaseItemUpdateRep,
} from "modules/chat/reps/KnowledgeBaseItemRep";
import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";

type KnowledgeBaseItemApi = {
  listByBusiness: (businessGuid: string) => Promise<KnowledgeBaseItemRep[]>;
  create: (creator: KnowledgeBaseItemCreatorRep) => Promise<KnowledgeBaseItemRep>;
  update: (
    knowledgeBaseItemId: string,
    update: KnowledgeBaseItemUpdateRep
  ) => Promise<KnowledgeBaseItemRep>;
  delete: (knowledgeBaseItemId: string) => Promise<KnowledgeBaseItemRep>;
};

const builder: ApiBuilder<KnowledgeBaseItemApi> = (api) => ({
  listByBusiness: async (businessGuid) => {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/chat/knowledge-base-items?${queryParams}`;
    return (await api.get<KnowledgeBaseItemRep[]>(url))!;
  },
  create: async (creator) => {
    const url = `/chat/knowledge-base-items`;
    return (await api.post<KnowledgeBaseItemRep>(url, creator))!;
  },
  update: async (knowledgeBaseItemId, update) => {
    const url = `/chat/knowledge-base-items/${knowledgeBaseItemId}`;
    return (await api.patch<KnowledgeBaseItemRep>(url, update))!;
  },
  delete: async (knowledgeBaseItemId) => {
    const url = `/chat/knowledge-base-items/${knowledgeBaseItemId}`;
    return (await api.delete<KnowledgeBaseItemRep>(url))!;
  },
});

const useKnowledgeBaseItemApi = () => useBackendV2Api(builder);

export default useKnowledgeBaseItemApi;
