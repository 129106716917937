import { ArrowLineUpRight, ArrowsLeftRight, DotsThree } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import colors from "styles/colors";
import ChequeIcon from "ui/icons/ChequeIcon";
import Button from "ui/inputs/Button";
import ButtonLink from "ui/inputs/ButtonLink";
import ButtonLinkWithTooltip from "ui/inputs/ButtonLink/ButtonLinkWithTooltip";
import Menu from "ui/overlay/Menu";
import useMediaQuery from "utils/device/useMediaQuery";
import useIsAllowedToNavigateToDepositCheckPage from "utils/permissions/navigation/useIsAllowedToNavigateToDepositCheckPage";
import useIsAllowedToNavigateToTransferMoney from "utils/permissions/navigation/useIsAllowedToNavigateToTransferMoney";
import useIsAllowedToApprovePayments from "utils/permissions/useIsAllowedToApprovePayments";
import useIsAllowedToCreateDraftPayments from "utils/permissions/useIsAllowedToCreateDraftPayments";
import cn from "utils/tailwind/cn";

const AccountsOverviewPageHeaderActions = () => {
  const isAllowedToCreatePaymentDrafts = useIsAllowedToCreateDraftPayments();
  const isAllowedToApprovePayments = useIsAllowedToApprovePayments();
  const isAllowedToNavigateToDepositCheckPage = useIsAllowedToNavigateToDepositCheckPage();
  const isAllowedToNavigateToTransferMoneyPage = useIsAllowedToNavigateToTransferMoney();
  // NB(alex): We should standardize these values soon. `useIsMobile` was too wide :/
  const isMobileSmall = useMediaQuery("(max-width: 640px)");

  const showSendMoneyButton = isAllowedToCreatePaymentDrafts || isAllowedToApprovePayments;

  return (
    <div className="flex w-full items-center">
      {showSendMoneyButton && (
        <ButtonLink variant="secondary" to="/send-money" className="w-full rounded-r-none">
          <ArrowLineUpRight size={14} />
          {isAllowedToApprovePayments ? "Send money" : "Draft a payment"}
        </ButtonLink>
      )}

      {isAllowedToNavigateToTransferMoneyPage && (
        <ButtonLink
          variant="secondary"
          to="/transfer-money"
          className={cn(
            "w-full",
            showSendMoneyButton ? "rounded-none border-x-0" : "rounded-r-none border-r-0"
          )}
        >
          <ArrowsLeftRight size={14} />
          Transfer money
        </ButtonLink>
      )}

      {isMobileSmall ? (
        // Hide menu icon on mobile if user doesn't have permission. Feel free to modify if we don't like this.
        !isAllowedToNavigateToDepositCheckPage ? null : (
          <Menu
            button={
              <Button variant="secondary" className="h-[2.625rem] rounded-l-none">
                <DotsThree weight="bold" />
              </Button>
            }
          >
            <Link to="/deposit-check">
              {/* TODO(alex): <MenuItemLink /> Menu item button should not be nested under Link. */}
              <Menu.Item style={{ display: "flex", alignItems: "center" }} icon={<ChequeIcon />}>
                Deposit check
              </Menu.Item>
            </Link>
          </Menu>
        )
      ) : (
        <ButtonLinkWithTooltip
          to="/deposit-check"
          variant="secondary"
          className="rounded-l-none"
          fullWidth
          disabled={!isAllowedToNavigateToDepositCheckPage}
          tooltip={
            !isAllowedToNavigateToDepositCheckPage && "You don’t have permission to deposit checks."
          }
        >
          <ChequeIcon color={colors.purple[500]} />
          Deposit check
        </ButtonLinkWithTooltip>
      )}
    </div>
  );
};

export default AccountsOverviewPageHeaderActions;
