import { DotsThreeCircle } from "@phosphor-icons/react";
import { FC, ReactNode } from "react";
import Banner from "ui/data-display/Banner";
import IconTile from "ui/icons/IconTile";

type Props = {
  button: ReactNode;
  className?: string;
};

const VerificationInProgressBanner: FC<Props> = ({ button, className }) => {
  return (
    <Banner
      color="white"
      icon={<IconTile className="bg-purple-50" icon={<DotsThreeCircle weight="light" />} />}
      title="Business verification in progress"
      paragraph={
        <>
          We’ll notify you when your verification is complete in{" "}
          <strong className="font-medium">1&ndash;3 business days</strong>.
        </>
      }
      button={button}
      className={className}
    />
  );
};

export default VerificationInProgressBanner;
