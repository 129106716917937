import { Dayjs } from "dayjs";
import {
  Area,
  AreaChart as Chart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import colors from "styles/colors";
import DotsLoader from "ui/feedback/DotsLoader";

export type DetailedAreaChartDatum = {
  amount: number;
  // The formatted date for the x-axis.
  date: string;
  // A dayjs instance of the date, which is used for padding the history.
  // We need a full date because the formatted date may strip out year information.
  referenceDateInstance: Dayjs;
};

type Props = {
  data: DetailedAreaChartDatum[];
  ticks: string[];
  gradientFrom?: string;
  gradientTo?: string;
  strokeColor?: string;
  chartHeight?: number;
  setActiveTick?: (tick: DetailedAreaChartDatum | null) => void;
  isAnimationActive?: boolean;
  // This state represents when the chart is loading in new data
  // (for example, the x axis is changed to a new timeframe). In this scenario,
  // the chart is already rendered with some previous data, but we want to show
  // an overlay loading state to indicate that new data is coming in.
  isFetching?: boolean;
};

const DetailedAreaChart: React.FC<Props> = ({
  data,
  ticks,
  gradientFrom,
  gradientTo,
  strokeColor,
  chartHeight,
  setActiveTick,
  isAnimationActive,
  isFetching,
}) => (
  <div className="relative">
    <ResponsiveContainer width="100%" height={chartHeight}>
      <Chart
        data={data}
        onMouseMove={(val: any) => setActiveTick?.(data[val.activeTooltipIndex])}
        onMouseLeave={() => setActiveTick?.(null)}
        // Hack for fixing x-axis label getting cut off. Fix found here: https://github.com/recharts/recharts/issues/1618#issuecomment-1612155672
        {...{ overflow: "visible" }}
      >
        <defs>
          <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={gradientFrom || colors.purple[300]} stopOpacity={0.13} />
            <stop offset="95%" stopColor={gradientTo || colors.purple[500]} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="amount"
          strokeWidth="1.5px"
          stroke={strokeColor || colors.purple[500]}
          fillOpacity={1}
          fill="url(#colorValue)"
          isAnimationActive={isAnimationActive}
        />
        <CartesianGrid strokeDasharray="4" vertical horizontal={false} stroke={colors.grey[200]} />
        <XAxis
          axisLine={{ stroke: colors.grey[200] }}
          tickLine={false}
          dataKey={"date"}
          interval="preserveStartEnd"
          ticks={ticks}
          tick={{ fontSize: 12 }}
          strokeDasharray="4"
          tickMargin={12}
        />
        <Tooltip wrapperStyle={{ display: "none" }} />
      </Chart>
    </ResponsiveContainer>

    {isFetching && (
      <div className="absolute inset-0 flex items-center justify-center bg-white/90">
        <DotsLoader dots={5} />
      </div>
    )}
  </div>
);

export default DetailedAreaChart;
