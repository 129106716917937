// NB(lev): We share this as the root key for bill summaries related queries,
// such as the bill summaries list, an individual bill summary, assigned bill
// summaries, etc.
// There are some other queries in the bills module, such as bill stats, that
// also use this root key (even though they're not bill summaries related, technically).
// This is being done currently to facilitate invalidating bill summaries and related
// things like stats, all at once.
const BILL_SUMMARIES_ROOT_QUERY_KEY = "bill-summaries";

export { BILL_SUMMARIES_ROOT_QUERY_KEY };
