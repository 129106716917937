import { useSuspenseQueries } from "@tanstack/react-query";
import { bankAccountsQueryHooks } from "modules/bank-accounts/queries/useBankAccounts";
import { plaidAccountsQueryHooks } from "modules/plaid/queries/usePlaidAccounts";
import BankAccountRep from "reps/BankAccountRep";
import PlaidAccountRep from "reps/PlaidAccountRep";
import { isNotNull } from "utils/array";
import { SimpleUseQueryOptions } from "utils/react-query/SimpleUseQueryOptions";

import { AccountType } from "../reps/AccountType";
import { NormalizedAccountRep } from "../reps/NormalizedAccountRep";
import {
  augmentNormalizedAccount,
  NormalizedAccountAugmented,
} from "../utils/augment-normalized-account";

import { normalizedAccountsQueryHooks } from "./useNormalizedAccounts";

type CombineParams = {
  bankAccounts: BankAccountRep.Complete[];
  plaidBankAccounts: PlaidAccountRep.Complete[];
  normalizedAccounts: NormalizedAccountRep[];
};

const combine = ({ bankAccounts, plaidBankAccounts, normalizedAccounts }: CombineParams) => {
  return normalizedAccounts
    .map((normalizedAccount) => {
      return augmentNormalizedAccount({
        normalizedAccount,
        bankAccounts,
        connectedPlaidBankAccounts: plaidBankAccounts,
      });
    })
    .filter(isNotNull)
    .sort((a, b) => {
      if (a.institutionName === "Highbeam" && b.institutionName === "Highbeam") {
        if (a.subtype === "High Yield" && b.subtype !== "High Yield") return 1;
        if (a.subtype !== "High Yield" && b.subtype === "High Yield") return -1;
      }
      if (a.institutionName === "Highbeam" && b.institutionName !== "Highbeam") return -1;
      if (b.institutionName === "Highbeam" && a.institutionName !== "Highbeam") return 1;
      return a.accountName.localeCompare(b.accountName);
    });
};

type FilterNormalizedAccountsAugmentedParams = {
  accountType?: AccountType;
  includeHighbeam?: boolean;
  uniqueInstitutions?: boolean;
};

const filterNormalizedAccountsAugmented = (
  normalizedAccountsAugmented: NormalizedAccountAugmented[],
  filters: FilterNormalizedAccountsAugmentedParams
) => {
  const { accountType, includeHighbeam, uniqueInstitutions } = filters;
  // eslint-disable-next-line functional/no-let
  let result = normalizedAccountsAugmented;
  if (accountType) {
    result = normalizedAccountsAugmented.filter(
      (normalizedAccount) => normalizedAccount.type === accountType
    );
  }
  if (!includeHighbeam) {
    result = result.filter((normalizedAccount) => normalizedAccount.institutionName !== "Highbeam");
  }

  if (uniqueInstitutions) {
    result = result.filter(
      (normalizedAccount, index, self) =>
        index === self.findIndex((t) => t.institutionId === normalizedAccount.institutionId)
    );
  }
  return result;
};

type UseNormalizedAccountsAugmentedParams = {
  normalizedAccountsQueryOptions?: SimpleUseQueryOptions;
  filters?: FilterNormalizedAccountsAugmentedParams;
};

const useNormalizedAccountsAugmented = (params?: UseNormalizedAccountsAugmentedParams) => {
  const { normalizedAccountsQueryOptions, filters } = params ?? {};

  return useSuspenseQueries({
    queries: [
      bankAccountsQueryHooks.useQueryOptions({}),
      plaidAccountsQueryHooks.useQueryOptions({}),
      {
        ...normalizedAccountsQueryHooks.useQueryOptions({}),
        ...normalizedAccountsQueryOptions,
      },
    ],
    combine: ([
      { data: bankAccounts },
      { data: plaidBankAccounts },
      { data: normalizedAccounts },
    ]) => {
      const combinedData = combine({ bankAccounts, plaidBankAccounts, normalizedAccounts });
      if (filters) {
        return filterNormalizedAccountsAugmented(combinedData, filters);
      }
      return combinedData;
    },
  });
};

export { filterNormalizedAccountsAugmented, useNormalizedAccountsAugmented };
