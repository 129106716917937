import { useMutation } from "@tanstack/react-query";
import useBusinessOwnerApi from "modules/businesses/api/useBusinessOwnerApi";
import { useRefreshAllBusinessesQueries } from "modules/businesses/queries/businessesQueryHooks";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useMfa from "modules/mfa/useMfa";
import { notify } from "ui/feedback/Toast";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";

import { useChangeOrganizationOwnerModal } from "../state/changeOrganizationOwnerModalState";

type Variables = {
  userGuid: string;
};

const useChangeOwnerMutation = () => {
  const businessOwnerApi = useBusinessOwnerApi();
  const businessGuid = useBusinessGuid();
  const { close: closeChangeOrganizationOwnerModal } = useChangeOrganizationOwnerModal();
  const { mfa } = useMfa();
  const refreshAllBusinessesQueries = useRefreshAllBusinessesQueries();
  const { segmentTrack } = useSegment();

  return useMutation({
    mutationFn: async ({ userGuid }: Variables) => {
      await mfa();
      return businessOwnerApi.setBusinessOwner(businessGuid, userGuid);
    },
    onError: () => {
      notify("error", "Something went wrong. Please try again.");
    },
    onSuccess: async () => {
      await refreshAllBusinessesQueries();
      closeChangeOrganizationOwnerModal();
      notify("success", "Account ownership transferred");
      segmentTrack(SEGMENT_EVENTS.OWNERSHIP_TRANSFERRED);
    },
  });
};

export default useChangeOwnerMutation;
