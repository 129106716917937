import dayjs from "dayjs";
import { MonthChatElementRep } from "modules/chat/reps/ChatElementRep";
import React from "react";

type Props = {
  element: MonthChatElementRep;
};

const MonthChatElement: React.FC<Props> = ({ element }) => {
  return (
    <span>
      {dayjs(element.value.charAt(0).toUpperCase() + element.value.slice(1).toLowerCase(), "MMMM")
        .tz("America/New_York")
        .format("MMMM")}
    </span>
  );
};

export default MonthChatElement;
