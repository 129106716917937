import { useState } from "react";

type ModalState<TParams extends object> = { isOpen: true; params: TParams } | { isOpen: false };

/**
 * Used for managing modal state.
 * When open, `isOpen` is true and additional state is included.
 * When closed, `isOpen` is false and no additional state is included.
 */
const useModalV2 = <TParams extends object>(defaultParams?: TParams) => {
  const [modalState, setModalState] = useState<ModalState<TParams>>(
    defaultParams ? { params: defaultParams, isOpen: true } : { isOpen: false }
  );

  const open = (params: TParams) => {
    setModalState({ params, isOpen: true });
  };

  const close = () => {
    // Clear state.
    setModalState({ isOpen: false });
  };

  return { ...modalState, open, close };
};

export default useModalV2;
