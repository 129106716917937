import { CurrencyDollarSimple, PlusCircle, ShieldCheck } from "@phosphor-icons/react";
import { usePrimaryBankAccount } from "modules/bank-accounts/queries/useBankAccount";
import { useHasFilledOutBankApplication } from "modules/bank-application/queries/useBankApplicationQueryOptions";
import { useIsBusinessActive } from "modules/business/queries/useBusiness";
import VerifyYourBusinessModal, {
  useVerifyYourBusinessModal,
} from "modules/onboarding/dialogs/VerifyYourBusinessModal";
import { SETTINGS_PLAID_LINK_MODAL_KEY } from "modules/plaid/components/PlaidConnectionsList";
import { useHasPlaidConnections } from "modules/plaid/queries/plaidConnectionsQueryHooks";
import { useHasAtLeastOneUnitTransaction } from "modules/unit-co-transactions/queries/useUnitCoTransactionsQueryOptions";
import { useRef, useState } from "react";
import Button from "ui/inputs/Button";
import ButtonLink from "ui/inputs/ButtonLink";
import { useResizeObserver } from "utils/customHooks/useResizeObserver";

import AccountsOverviewBalanceChartEmptySvg from "./AccountsOverviewBalanceChartEmptySvg";

const VerifyBusinessButton = () => {
  const verifyYourBusinessModal = useVerifyYourBusinessModal();

  return (
    <>
      {verifyYourBusinessModal.isOpen && (
        <VerifyYourBusinessModal
          onClose={verifyYourBusinessModal.close}
          {...verifyYourBusinessModal.params}
        />
      )}
      <Button
        variant="ghost"
        onClick={() => {
          verifyYourBusinessModal.open({});
        }}
      >
        <ShieldCheck size={24} weight="light" /> Verify business
      </Button>
    </>
  );
};

// We center the actions relative to the chart, ignoring the height added by the x-axis labels.
const BOTTOM_OFFSET_RATIO = 56 / 288;

const AccountsOverviewBalanceChartEmpty = () => {
  const graphContainerRef = useRef<HTMLDivElement>(null);
  const actionsContainerRef = useRef<HTMLDivElement>(null);
  const [actionsTopOffset, setActionsTopOffset] = useState(0);
  const hasPlaidConnections = useHasPlaidConnections();
  const hasAtLeastOneUnitCoTransaction = useHasAtLeastOneUnitTransaction();
  const isBusinessActive = useIsBusinessActive();
  const hasFilledOutBankApplication = useHasFilledOutBankApplication();
  const primaryBankAccount = usePrimaryBankAccount();

  useResizeObserver(graphContainerRef, (element) => {
    const { height } = element.getBoundingClientRect();
    const boundingContainerHeight = height - height * BOTTOM_OFFSET_RATIO;
    const actionsContainerHeight = actionsContainerRef.current?.clientHeight ?? 0;
    setActionsTopOffset((boundingContainerHeight - actionsContainerHeight) / 2);
  });

  return (
    <div className="relative flex-1">
      <div
        className="absolute inset-x-0 mx-auto flex w-fit flex-col gap-y-4"
        ref={actionsContainerRef}
        style={{ top: actionsTopOffset }}
      >
        {!hasPlaidConnections && (
          <ButtonLink
            to={`/settings/banks-and-cards?${SETTINGS_PLAID_LINK_MODAL_KEY}=true`}
            variant="tertiary"
          >
            <PlusCircle size={24} weight="light" /> Connect accounts
          </ButtonLink>
        )}

        {hasPlaidConnections && isBusinessActive && !hasAtLeastOneUnitCoTransaction && (
          <ButtonLink
            variant="tertiary"
            to="/transfer-money"
            state={{ toAccountGuid: primaryBankAccount?.guid }}
          >
            <CurrencyDollarSimple size={24} weight="light" />
            Fund your accounts
          </ButtonLink>
        )}

        {!isBusinessActive && !hasFilledOutBankApplication && <VerifyBusinessButton />}
      </div>
      <div ref={graphContainerRef}>
        <AccountsOverviewBalanceChartEmptySvg />
      </div>
    </div>
  );
};

export default AccountsOverviewBalanceChartEmpty;
