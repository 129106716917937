import { PaymentListParams } from "@highbeam/unit-node-sdk";
import { useUnitApiIfEnabled } from "modules/unit-co-customer-token/queries/useUnitApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const INCOMING_PAYMENT_PARAMS: PaymentListParams = {
  limit: 50,
  offset: 0,
  direction: ["Debit"], // satisfies Direction[]
  status: ["Pending", "Clearing", "PendingReview"], // satisfies PaymentStatus[]
};

export const UNIT_PAYMENTS_QUERY_KEY = "unitPayments";

const useUnitCoPaymentsQueryOptions = (params?: PaymentListParams, enabled = true) => {
  const unitApi = useUnitApiIfEnabled({ enabled });

  return useQueryOptions({
    queryKey: [UNIT_PAYMENTS_QUERY_KEY, params, enabled],
    queryFn: async () => {
      if (!enabled) return [];
      const { data } = await unitApi!.payments.list(params);
      return data;
    },
  });
};

export default useUnitCoPaymentsQueryOptions;
