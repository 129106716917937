import { CalendarBlank, WarningCircle } from "@phosphor-icons/react";
import dayjs from "dayjs";
import MultiStep from "layouts/MultiStep";
import { useBankAccounts } from "modules/bank-accounts/queries/useBankAccounts";
import capitalRepaymentAmountsQueryHooks from "modules/capital-accounts/queries/capitalRepaymentAmountsQueryHooks";
import { CapitalAccountSummaryWithChargeCard } from "modules/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import chargeCardAccountQueryHooks from "modules/charge-cards/queries/chargeCardAccountQueryHooks";
import useChargeCardRepaymentInfo from "modules/charge-cards/queries/useChargeCardRepaymentInfo";
import RepaymentBankAccountDropdown, {
  RepaymentBankAccountDropdownControl,
} from "pages/capital/CapitalRepayPage/components/RepaymentBankAccountDropdown";
import { FC, useMemo, useRef } from "react";
import { Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Divider from "ui/data-display/Divider";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import MoneyAmountFraction from "ui/data-display/money/MoneyAmountFraction";
import Pill from "ui/data-display/Pill";
import BarChart from "ui/data-visualization/BarChart";
import CurrencyInput from "ui/inputs/CurrencyInputV2";
import Helper from "ui/inputs/Helper";
import RadioCardSelect from "ui/inputs/RadioCardSelect";
import RadioCardSimple from "ui/inputs/RadioCardSimple";
import { Span } from "ui/typography";
import cn from "utils/tailwind/cn";

import getRepaymentOptions from "./getRepaymentOptions";
import useRepayHighbeamCardsForm, {
  RepayHighbeamCardsFormInputs,
  repayHighbeamCardsFormSchema,
  RepaymentOption,
} from "./useRepayHighbeamCardsForm";

const REPAY_HIGHBEAM_CARDS_FORM_ID = "repay-highbeam-cards-form";

type RepayHighbeamCardsAmountFormProps = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
  defaultValues: RepayHighbeamCardsFormInputs | null;
};

const RepayHighbeamCardsAmountForm: FC<RepayHighbeamCardsAmountFormProps> = ({
  capitalAccountSummaryWithChargeCard,
  defaultValues,
}) => {
  const openBankAccounts = useBankAccounts({ filters: { status: "open" } });

  const defaultRepaymentBankAccount =
    openBankAccounts.find(
      (bankAccount) =>
        bankAccount.guid === capitalAccountSummaryWithChargeCard.details.repayment.bankAccountGuid
    ) ?? openBankAccounts[0];

  const chargeCardAccount = chargeCardAccountQueryHooks.useDataRequired({
    capitalAccountGuid: capitalAccountSummaryWithChargeCard.guid,
  });

  const chargeCardRepaymentAmounts = capitalRepaymentAmountsQueryHooks.useData({
    capitalAccountGuid: capitalAccountSummaryWithChargeCard.guid,
    select: (data) => data.amounts,
  });

  const overdueAmount =
    chargeCardRepaymentAmounts.find((amount) => amount.type === "Overdue")?.amount ?? 0;

  const lastStatementAmount =
    chargeCardRepaymentAmounts.find((amount) => amount.type === "LastStatement")?.amount ?? 0;

  const lastStatementPlusOverdueAmount = lastStatementAmount + overdueAmount;

  const currentStatementAmount =
    chargeCardRepaymentAmounts.find((amount) => amount.type === "CurrentStatement")?.amount ?? 0;

  const totalAmount = currentStatementAmount + lastStatementAmount + overdueAmount;

  const { previousStatementPeriodEnd } = useChargeCardRepaymentInfo(chargeCardAccount.guid, {
    required: true,
  });

  const repaymentOptions = useMemo((): RepaymentOption[] => {
    return getRepaymentOptions({
      currentStatementAmount,
      lastStatementAmount,
      overdueAmount,
    });
  }, [currentStatementAmount, lastStatementAmount, overdueAmount]);

  const form = useRepayHighbeamCardsForm({
    defaultValues: defaultValues ?? {
      amount: 0,
      currentAmountAvailable: capitalAccountSummaryWithChargeCard.available,
      repaymentOption: null,
      repaymentBankAccount: defaultRepaymentBankAccount,
    },
  });

  const navigate = useNavigate();

  const amountInputRef = useRef<HTMLInputElement>(null);

  const amountInputValue = form.watch("amount");
  const selectedRepaymentOption = form.watch("repaymentOption");
  const selectedRepaymentBankAccount = form.watch("repaymentBankAccount");

  const onSubmit = form.handleSubmit((_data) => {
    // NB(alex): Do not copy this pattern -- I think the correct pattern should be to save the validated data in a shared store, and to derive the inputs or keep them in a provider.
    navigate(`/capital/${capitalAccountSummaryWithChargeCard.guid}/repay/confirm`, {
      state: { repayHighbeamCardsAmountFormInputs: form.getValues() },
    });
  });

  const availableAfterRepayment = capitalAccountSummaryWithChargeCard.available + amountInputValue;

  const availableAfterRepaymentPercentage =
    (availableAfterRepayment / capitalAccountSummaryWithChargeCard.details.limit) * 100;

  const repaymentBankAccountAvailableBalance = selectedRepaymentBankAccount.availableBalance;
  const repaymentBankAccountBalanceAfterRepayment =
    repaymentBankAccountAvailableBalance - amountInputValue;
  const insufficientFunds = repaymentBankAccountBalanceAfterRepayment < 0;

  return (
    <MultiStep.Form id={REPAY_HIGHBEAM_CARDS_FORM_ID} onSubmit={onSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>
            How much do you want to repay?
          </MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>

        <Controller
          control={form.control}
          name="repaymentOption"
          render={({ field, fieldState: { error } }) => {
            return (
              <div>
                <RadioCardSelect
                  options={repaymentOptions}
                  value={field.value}
                  onChange={field.onChange}
                  rowGap={8}
                >
                  {({ option, isSelected, onSelect }) => {
                    switch (option) {
                      case "total-balance":
                        return (
                          <RadioCardSimple
                            key={option}
                            value={option}
                            checked={isSelected}
                            onChange={(value) => {
                              onSelect(value);
                              form.setValue("amount", totalAmount);
                            }}
                            label={<MoneyAmount cents={totalAmount} weight="medium" />}
                            helper="Total balance"
                          />
                        );
                      case "current-statement-balance":
                        return (
                          <RadioCardSimple
                            key={option}
                            value={option}
                            checked={isSelected}
                            onChange={(value) => {
                              onSelect(value);
                              form.setValue("amount", currentStatementAmount);
                            }}
                            label={<MoneyAmount cents={currentStatementAmount} weight="medium" />}
                            helper="Current statement balance"
                          />
                        );
                      case "last-statement-balance":
                        return (
                          <RadioCardSimple
                            key={option}
                            value={option}
                            checked={isSelected}
                            onChange={(value) => {
                              onSelect(value);
                              form.setValue("amount", lastStatementPlusOverdueAmount);
                            }}
                            label={
                              <div className="flex items-center gap-x-4">
                                <MoneyAmount
                                  cents={lastStatementPlusOverdueAmount}
                                  weight="medium"
                                />
                                <Pill
                                  color="yellow-100"
                                  iconLeft={({ sizeClassName }) => (
                                    <CalendarBlank className={sizeClassName} />
                                  )}
                                >
                                  Due {dayjs(previousStatementPeriodEnd).format("MMM D")}
                                </Pill>
                              </div>
                            }
                            helper={
                              overdueAmount
                                ? "Last statement + overdue balance"
                                : "Last statement balance"
                            }
                          />
                        );
                      case "overdue-balance":
                        return (
                          <RadioCardSimple
                            key={option}
                            value={option}
                            checked={isSelected}
                            onChange={(value) => {
                              onSelect(value);
                              form.setValue("amount", overdueAmount);
                            }}
                            label={
                              <div className="flex items-center gap-x-4">
                                <MoneyAmount cents={overdueAmount} weight="medium" />
                                <Pill
                                  color="red-200"
                                  iconLeft={({ sizeClassName }) => (
                                    <WarningCircle className={sizeClassName} />
                                  )}
                                >
                                  Overdue
                                </Pill>
                              </div>
                            }
                            helper="Overdue balance"
                          />
                        );
                      case "custom":
                        return (
                          <RadioCardSimple
                            key={option}
                            value={option}
                            checked={isSelected}
                            onChange={(value) => {
                              onSelect(value);

                              // Focus & select the amount input when the "custom" option is selected
                              // NB(alex): I wasn't able to figure out how to unset the field so I opted to select & highlight the field instead
                              amountInputRef.current?.focus();
                              setTimeout(() => amountInputRef.current?.select(), 0);
                            }}
                            label="Custom amount"
                          />
                        );
                    }
                  }}
                </RadioCardSelect>

                {error && <Helper iconVariant="error">{error.message}</Helper>}
              </div>
            );
          }}
        />

        <Controller
          control={form.control}
          name="amount"
          render={({ field }) => {
            return (
              <CurrencyInput
                onFocus={(e) => e.target.select()}
                inputRef={amountInputRef}
                prefixValue="$"
                label="Amount"
                className={cn(selectedRepaymentOption !== "custom" && "hidden", "mt-2")}
                {...field}
              />
            );
          }}
        />

        {insufficientFunds && (
          <Helper iconVariant="error">
            The amount you entered is greater than your account balance of{" "}
            <MoneyAmount cents={selectedRepaymentBankAccount.availableBalance} />.
          </Helper>
        )}

        {amountInputValue > Math.max(0, totalAmount) && (
          <Helper iconVariant="warning">
            The amount you entered is greater than the amount you owe.
          </Helper>
        )}
      </MultiStep.Section>

      <MultiStep.Section className="mt-8">
        <RepaymentBankAccountDropdown
          control={form.control as unknown as RepaymentBankAccountDropdownControl} // Intentional type-cast.
        />
      </MultiStep.Section>

      <Divider className="my-8" />

      <MultiStep.Section>
        <Span className="text-sm font-medium text-grey-900">Capital available after repayment</Span>

        <BarChart height={8} className="my-3">
          <BarChart.Bar color="green" widthPercentage={availableAfterRepaymentPercentage} />
        </BarChart>

        <MoneyAmountFraction
          numeratorInCents={availableAfterRepayment}
          denominatorInCents={capitalAccountSummaryWithChargeCard.details.limit}
        />
      </MultiStep.Section>

      <MultiStep.Controls>
        <MultiStep.Controls.NextButton
          form={REPAY_HIGHBEAM_CARDS_FORM_ID}
          disabled={insufficientFunds}
        >
          Next
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const RepayHighbeamCardsAmountView: FC<Props> = ({ capitalAccountSummaryWithChargeCard }) => {
  const { state } = useLocation();

  const repayHighbeamCardsAmountFormInputs: RepayHighbeamCardsFormInputs | null =
    repayHighbeamCardsFormSchema.safeParse(state?.repayHighbeamCardsAmountFormInputs).success
      ? state?.repayHighbeamCardsAmountFormInputs
      : null;

  return (
    <RepayHighbeamCardsAmountForm
      capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
      defaultValues={repayHighbeamCardsAmountFormInputs}
    />
  );
};

export default RepayHighbeamCardsAmountView;
