import { useBankAccountByUnitAccountIdOrThrow } from "modules/bank-accounts/queries/useBankAccount";
import { useBankAccounts } from "modules/bank-accounts/queries/useBankAccounts";
import CancelPaymentModal, {
  PaymentDirection,
  useCancelPaymentModal,
} from "modules/payments/dialogs/CancelPaymentModal";
import { UNIT_PAYMENTS_QUERY_KEY } from "modules/unit-co-payments/queries/useUnitCoPaymentsQueryOptions";
import { FC } from "react";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import Flexpane from "ui/overlay/Flexpane";
import { getPaymentCounterparty } from "utils/payments";
import useIsAllowedToCancelPayment from "utils/permissions/useIsAllowedToCancelPayment";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import { usePaymentInfoFlexpaneContext } from "../../context/PaymentInfoFlexpaneProvider";

type Props = {
  paymentDirection?: PaymentDirection;
};

const OriginatedAchTransactionFlexpaneHeader: FC<Props> = ({ paymentDirection = "Incoming" }) => {
  const { payment, onClose, onPaymentCanceled } = usePaymentInfoFlexpaneContext();

  const bankAccounts = useBankAccounts();
  const bankAccountsByUnitId = new Map(
    bankAccounts.map((account) => [account.unitCoDepositAccountId, account])
  );

  const isAllowedToCancelPayment = useIsAllowedToCancelPayment();
  const bankAccount = useBankAccountByUnitAccountIdOrThrow(payment.relationships.account.data.id);

  const canCancelPayment =
    payment.attributes.status === "Pending" || payment.attributes.status === "PendingReview";
  const refreshPayments = useRefreshQuery([UNIT_PAYMENTS_QUERY_KEY]);

  const paymentGuid = payment.attributes.tags?.paymentGuid;
  const counterparty = getPaymentCounterparty(payment, bankAccountsByUnitId);

  const cancelPaymentModal = useCancelPaymentModal();

  return (
    <>
      {cancelPaymentModal.isOpen && (
        <CancelPaymentModal
          onSuccess={() => {
            refreshPayments();
            onClose();
            onPaymentCanceled?.();
          }}
          onClose={cancelPaymentModal.close}
          params={cancelPaymentModal.params}
        />
      )}

      <Flexpane.Header
        onClose={onClose}
        right={
          canCancelPayment && paymentGuid ? (
            <ButtonWithTooltip
              variant="danger"
              onClick={() => {
                cancelPaymentModal.open({
                  bankAccount: bankAccount,
                  counterpartyName: counterparty.name,
                  paymentAmountInCents: payment.attributes.amount,
                  paymentDirection: paymentDirection,
                  paymentGuid: paymentGuid,
                });
              }}
              disabled={!isAllowedToCancelPayment}
              tooltip={
                !isAllowedToCancelPayment && "You don’t have permission to cancel transfers."
              }
            >
              Cancel transfer
            </ButtonWithTooltip>
          ) : null
        }
      >
        Payment info
      </Flexpane.Header>
    </>
  );
};

export default OriginatedAchTransactionFlexpaneHeader;
