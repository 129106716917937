import { PlusCircle } from "@phosphor-icons/react";
import DashboardPage from "layouts/DashboardPage";
import CapitalAssociatedCardsTableView from "modules/capital-accounts/components/CapitalAssociatedCardsTableView";
import { CapitalAccountSummaryWithChargeCard } from "modules/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import CreateCardModal from "modules/cards/dialogs/CreateCardModal";
import useCreateCardModal from "modules/cards/hooks/useCreateCardModal";
import cardsQueryHooks from "modules/cards/queries/useCards";
import chargeCardAccountQueryHooks from "modules/charge-cards/queries/chargeCardAccountQueryHooks";
import { FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import AccentCard from "ui/data-display/AccentCard";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import { Heading2, Heading4, Paragraph } from "ui/typography";
import useIsAllowedToCreateCards from "utils/permissions/useIsAllowedToCreateCards";

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
  className?: string;
};

const CapitalAccountAssociatedCardsSection: FC<Props> = ({
  capitalAccountSummaryWithChargeCard,
  className,
}) => {
  const chargeCardAccount = chargeCardAccountQueryHooks.useData({
    capitalAccountGuid: capitalAccountSummaryWithChargeCard.guid,
  });

  const isAllowedToCreateCards = useIsAllowedToCreateCards();

  const hasChargeCardAccount = chargeCardAccount !== null;
  const isCapitalAccountActive =
    capitalAccountSummaryWithChargeCard.state === CapitalAccountRep.State.Active;

  const { data: cards = [] } = cardsQueryHooks.useQuery({
    params: {
      status: "default",
      unitCoAccountId: chargeCardAccount?.unitCoCreditAccountId,
    },
    // NB(alex): Active capital accounts should always have a charge card account, but offer accepted state does not.
    enabled: hasChargeCardAccount,
  });

  const createCardModal = useCreateCardModal();

  const hasAssociatedCards = cards.length > 0;

  const createCardButton = (
    <ButtonWithTooltip
      variant="tertiary"
      onClick={() => {
        createCardModal.open({
          defaultValues: {
            capitalAccountGuid: capitalAccountSummaryWithChargeCard.guid,
          },
        });
      }}
      disabled={!hasChargeCardAccount || !isAllowedToCreateCards || !isCapitalAccountActive}
      tooltip={
        (!hasChargeCardAccount && "This account has not been activated yet.") ||
        (!isAllowedToCreateCards && "You don’t have permission to create cards.")
      }
    >
      <PlusCircle size={20} weight="light" /> Create card
    </ButtonWithTooltip>
  );

  return (
    <>
      {createCardModal.isOpen && (
        <CreateCardModal
          defaultValues={createCardModal.params.defaultValues}
          onClose={createCardModal.close}
        />
      )}

      <DashboardPage.Section className={className}>
        <DashboardPage.Section.Header actions={hasAssociatedCards && createCardButton}>
          <Heading2>Associated cards</Heading2>
        </DashboardPage.Section.Header>

        {hasAssociatedCards ? (
          <CapitalAssociatedCardsTableView cards={cards} />
        ) : (
          <AccentCard className="flex flex-col items-center p-16 text-center">
            <Heading4 className="mb-2">Create a new card</Heading4>
            <Paragraph className="mb-4 max-w-60 text-sm text-grey-600">
              Create a card and connect it with this capital account
            </Paragraph>
            {createCardButton}
          </AccentCard>
        )}
      </DashboardPage.Section>
    </>
  );
};

export default CapitalAccountAssociatedCardsSection;
