import BankAccountBalanceMoneyAmountByBankAccountGuid from "modules/bank-accounts/components/BankAccountBalanceMoneyAmountByBankAccountGuid";
import { BankAccountBarByGuid } from "modules/bank-accounts/components/BankAccountBar";
import { usePrimaryBankAccountOrThrow } from "modules/bank-accounts/queries/useBankAccount";
import { FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import HighlightItem from "ui/data-display/HighlightItem";
import { Span } from "ui/typography";

type Props = {
  capitalAccount: CapitalAccountRep.Complete;
  className?: string;
};

const CapitalAccountAutoPayBankAccountHighlightItem: FC<Props> = ({
  capitalAccount,
  className,
}) => {
  const primaryAccount = usePrimaryBankAccountOrThrow();

  return (
    <HighlightItem className={className}>
      <HighlightItem.Heading>Auto-pay account</HighlightItem.Heading>

      <HighlightItem.Body>
        <BankAccountBarByGuid
          bankAccountGuid={capitalAccount.details.repayment.bankAccountGuid ?? primaryAccount.guid}
          className="overflow-hidden overflow-ellipsis"
        />
      </HighlightItem.Body>

      <HighlightItem.Footer>
        <Span className="text-xs text-grey-600">
          Balance:{" "}
          <BankAccountBalanceMoneyAmountByBankAccountGuid
            bankAccountGuid={
              capitalAccount.details.repayment.bankAccountGuid ?? primaryAccount.guid
            }
            size={12}
            weight="regular"
            centsTextWeight="regular"
          />
        </Span>
      </HighlightItem.Footer>
    </HighlightItem>
  );
};

export default CapitalAccountAutoPayBankAccountHighlightItem;
