import { useChatWidget } from "components/ChatWidget";
import OnboardingLayout from "layouts/OnboardingLayout";
import useBusiness from "modules/business/queries/useBusiness";
import ArchivedCustomerCard from "modules/onboarding/components/ArchivedCustomerCard";
import OnboardingBankApplicationRejectedCard from "modules/onboarding/components/OnboardingBankApplicationRejectedCard";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useSegment from "utils/customHooks/useSegment";

import checkInsightsAppEnabled from "../../modules/insights-app/checkInsightsAppEnabled";

import AuthenticatedRouterErrorBoundary from "./AuthenticatedRouterErrorBoundary";
import InsightsAppRouter from "./InsightsAppRouter";
import OnboardedRouter from "./OnboardedRouter";

const AuthenticatedRouter = () => {
  const location = useLocation();
  const { segmentPage } = useSegment();
  const chat = useChatWidget();
  const business = useBusiness();
  const isInsightsApp = checkInsightsAppEnabled();

  useEffect(() => {
    chat.refresh();
    segmentPage();
  }, [location, segmentPage, chat]);

  if (business.status === "Rejected") {
    return (
      <AuthenticatedRouterErrorBoundary>
        <OnboardingLayout>
          <OnboardingBankApplicationRejectedCard />
        </OnboardingLayout>
      </AuthenticatedRouterErrorBoundary>
    );
  }

  if (business.status === "Archived") {
    return (
      <AuthenticatedRouterErrorBoundary>
        <OnboardingLayout>
          <ArchivedCustomerCard />
        </OnboardingLayout>
      </AuthenticatedRouterErrorBoundary>
    );
  }

  return (
    <AuthenticatedRouterErrorBoundary>
      {isInsightsApp ? <InsightsAppRouter /> : <OnboardedRouter />}
    </AuthenticatedRouterErrorBoundary>
  );
};

export default AuthenticatedRouter;
