import WereCleaningYourTransactionsView from "modules/onboarding/components/onboarding-connections/WereCleaningYourTransactionsView";
import { containerPaddingClasses } from "modules/onboarding/components/shared/onboarding-common-components";
import ButtonLink from "ui/inputs/ButtonLink";
import cn from "utils/tailwind/cn";

const CleaningTransactionsInProgressPage = () => {
  return (
    <WereCleaningYourTransactionsView className={cn(containerPaddingClasses, "mt-24")}>
      <WereCleaningYourTransactionsView.HeroImage />
      <WereCleaningYourTransactionsView.Heading />
      <WereCleaningYourTransactionsView.Subheading />
      <WereCleaningYourTransactionsView.Footer>
        <ButtonLink to="/onboarding/would-you-like-a-cash-flow-sheet" variant="primary">
          Continue onboarding
        </ButtonLink>
      </WereCleaningYourTransactionsView.Footer>
    </WereCleaningYourTransactionsView>
  );
};

export default CleaningTransactionsInProgressPage;
