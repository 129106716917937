import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import Emoji from "components/Emoji";
import isGettingStartedSetupGuideOpenState from "modules/action-items/state/gettingStartedSetupGuide";
import { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { useRecoilState } from "recoil";
import Button from "ui/inputs/Button";
import { Span } from "ui/typography";
import useDocumentKeyboardEvent from "utils/browser/useDocumentKeyboardEvent";
import useIsAllowedToConnectBankAccounts from "utils/permissions/useIsAllowedToConnectBankAccounts";
import useIsAllowedToConnectStores from "utils/permissions/useIsAllowedToConnectStores";
import useIsAllowedToCreateCards from "utils/permissions/useIsAllowedToCreateCards";

import {
  GetStartedActionItem,
  getStartedActionItemsQueryHooks,
} from "../queries/useGetStartedActionItems";

import GetStartedActionItemDrawer from "./GetStartedActionItemDrawer";

const getAllActionItemsFinished = (actionItems: GetStartedActionItem[]) => {
  return actionItems.every((item) => item.state !== "Incomplete");
};

const getAllActionItemsIncomplete = (actionItems: GetStartedActionItem[]) => {
  return actionItems.every((item) => item.state === "Incomplete");
};

const GetStartedSetupGuide = () => {
  const actionItems = getStartedActionItemsQueryHooks.useData({ params: {} });
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles: popperClassNames, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [73, 10],
        },
      },
    ],
  });

  const [showGetStarted, setShowGetStarted] = useRecoilState(isGettingStartedSetupGuideOpenState);

  // Start with get started guid open if all get started action items are incomplete.
  useEffect(() => {
    if (getAllActionItemsIncomplete(actionItems)) {
      setShowGetStarted(true);
    }
  }, [actionItems, setShowGetStarted]);

  useEffect(() => {
    const handleClickOutsidePopover = (event: MouseEvent) => {
      if (
        showGetStarted &&
        referenceElement &&
        popperElement &&
        !referenceElement.contains(event.target as HTMLElement) &&
        !popperElement.contains(event.target as HTMLElement)
      ) {
        setShowGetStarted(false);
      }
    };

    if (showGetStarted) {
      document.addEventListener("click", handleClickOutsidePopover);
    }

    return () => {
      document.removeEventListener("click", handleClickOutsidePopover);
    };
  }, [showGetStarted, referenceElement, popperElement, setShowGetStarted]);

  const incompleteItemsCount = actionItems.reduce(
    (acc, item) => acc + (item.state === "Complete" ? 0 : 1),
    0
  );

  useDocumentKeyboardEvent(
    (e) => {
      if (showGetStarted && e.key === "Escape") {
        setShowGetStarted(false);
      }
    },
    [showGetStarted]
  );

  const isAllowedToConnectStores = useIsAllowedToConnectStores();
  const isAllowedToCreateCards = useIsAllowedToCreateCards();
  const isAllowedToConnectBankAccounts = useIsAllowedToConnectBankAccounts();

  if (
    // NB(alex): Hacky way to hide the guide entirely if user is missing any of the permissions required in the guide (instead of selectively showing a different guide based on user permissions.)
    !isAllowedToConnectStores ||
    !isAllowedToCreateCards ||
    !isAllowedToConnectBankAccounts ||
    // NB(alex): We hide the guide entirely if all action items are finished. This render logic should occur outside of this component, but this is fine for now.
    getAllActionItemsFinished(actionItems)
  ) {
    return null;
  }

  return (
    <Popover>
      <PopoverButton
        ref={setReferenceElement}
        className="w-full"
        as={Button}
        variant="tertiary"
        onClick={() => setShowGetStarted(!showGetStarted)}
      >
        <Emoji className="mr-2" animation="wave">
          👋
        </Emoji>
        Get started
        {incompleteItemsCount > 0 && (
          <Span className="flex size-6 items-center justify-center rounded-full bg-purple-100 text-2xs font-bold">
            {incompleteItemsCount}
          </Span>
        )}
      </PopoverButton>
      {showGetStarted && (
        <PopoverPanel
          ref={setPopperElement}
          style={popperClassNames.popper}
          {...attributes.popper}
          static
        >
          <GetStartedActionItemDrawer
            title="Get the best out of Highbeam"
            actionItems={actionItems}
          />
        </PopoverPanel>
      )}
    </Popover>
  );
};

export default GetStartedSetupGuide;
