// NB(alex): Experimental pattern, please do not copy.
// Still need to figure out a way to extract sub-routes.

const onboardingRoutes = {
  "/onboarding/connect-accounts-get-started": "/onboarding/connect-accounts-get-started",
  "/onboarding/connect-accounts": "/onboarding/connect-accounts",
  "/onboarding/verify-business": "/onboarding/verify-business",
  "/onboarding/cleaning-transactions-in-progress": "/onboarding/cleaning-transactions-in-progress",
  "/onboarding/would-you-like-a-cash-flow-sheet": "/onboarding/would-you-like-a-cash-flow-sheet",
};

// NB(alex): Will move this to a shared directory if we want to use it in other places.
const removePrefixFromRoutes = (routes: Record<string, string>, prefix: string) => {
  return Object.fromEntries(
    Object.entries(routes).map(([key, value]) => [
      key.startsWith(prefix) ? key.slice(prefix.length) : key,
      value.startsWith(prefix) ? value.slice(prefix.length) : value,
    ])
  );
};

const onboardingRoutesWithoutPrefix = removePrefixFromRoutes(onboardingRoutes, "/onboarding");

export { onboardingRoutes, onboardingRoutesWithoutPrefix };
