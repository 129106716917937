import * as echarts from "echarts";
import { get } from "lodash-es";
import { FC, ReactElement, useEffect, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import colors from "styles/colors";
import DotsLoader from "ui/feedback/DotsLoader";
import Shimmer from "ui/feedback/ShimmerV2";
import { useResizeObserver } from "utils/customHooks/useResizeObserver";
import cn from "utils/tailwind/cn";

import { BalanceLineChartSerieDatum } from "./BalanceLineChartSerieDatum";
import {
  formatXAxisValue,
  getXAxisIntervalsByChartWidth,
  getXAxisLabelCountByChartWidth,
} from "./chart-axis-formatter-utils";
import { getChartColorHex, getChartGradientByColor } from "./chart-color-utils";

const X_AXIS_PADDING_TOP = 8;

export type BalanceLineChartColor = "purple-500" | "green-400";

type RenderTooltipProps = {
  titleLocalDate: string;
};

type Props = {
  data: BalanceLineChartSerieDatum[];
  className?: string;
  renderTooltip: (renderProps: RenderTooltipProps) => ReactElement;
  color: BalanceLineChartColor;
  isLoading?: boolean;

  // Hack for manually adjusting the placement of the graph if labels get cut off.
  gridLeft?: number;
  gridRight?: number;
};

const BalanceLineChart: FC<Props> = ({
  className,
  data,
  renderTooltip,
  color,
  isLoading,
  gridLeft = 0,
  gridRight = 4, // Right-most data point gets cut off without a little bit of space.
}) => {
  const chartContainerRef = useRef(null); // Reference to the chart container
  const chartInstance = useRef<echarts.ECharts | null>(null); // Store the ECharts instance

  useEffect(() => {
    if (!chartContainerRef.current) return;

    // Initialize the chart.
    chartInstance.current = echarts.init(chartContainerRef.current);

    // Configure the chart.
    chartInstance.current.setOption<echarts.EChartsOption>({
      tooltip: {
        trigger: "axis",
        axisPointer: {
          lineStyle: {
            color: colors.grey[400],
            width: 1,
          },
          type: "line",
        },
        transitionDuration: 0.15,

        // Same styles as `shadow-xs`.
        shadowColor: "rgba(0, 0, 0, 0.07)",
        shadowOffsetX: 0,
        shadowOffsetY: 1,
        shadowBlur: 2,

        padding: [12, 16, 16, 16],
        borderColor: colors.grey[200],
        textStyle: {
          fontSize: 14,
        },
        formatter: (params) => {
          const paramsAsArray = Array.isArray(params) ? params : [params];
          const titleLocalDate = paramsAsArray[0].name;

          // NB(alex): Needs to render a string. Total hack, but it works beautifully!
          return renderToString(renderTooltip({ titleLocalDate }));
        },

        position: function (_point, params, _el, _elRect, size) {
          const chart = chartInstance.current;
          if (!chart) return [0, 0];

          // Gets the x/y coordinates of the current data point (first item in the series).
          // NB(alex): I have no clue why this works but it seems to work perfectly.
          // This took a lot of chatgpt-ing to figure out. https://chatgpt.com/share/67aba7a2-1454-800e-b4bd-1b395a011b4b
          const xIndex = get(params, "[0].dataIndex", 0);
          const dataPointX = chart.convertToPixel({ seriesIndex: 0 }, [xIndex, 0])[0];
          const dataPointY = chart.convertToPixel({ seriesIndex: 0 }, [
            xIndex,
            get(params, "[0].data[1]", 0),
          ])[1];

          const [tooltipWidth] = size.contentSize;
          const chartWidth = size.viewSize[0];
          const chartHeight = size.viewSize[1];

          // NB(alex): We want the tooltip on the left side of the x-axis grid line, but want to flip it if it's at the left-most edge of the chart.
          // Don't flip it though if it's at the right-most edge of the chart.
          const isAtLeftEdgeOfChart = dataPointX < tooltipWidth + 64; // Trail & error to determine leeway for what looks best.
          const isAtRightEdgeOfChart = dataPointX > chartWidth - tooltipWidth + 40; // Trail & error to determine leeway for what looks best.
          const shouldFlipTooltip = isAtLeftEdgeOfChart && !isAtRightEdgeOfChart;

          const SPACE_X = 12;
          const tooltipPositionX = shouldFlipTooltip
            ? dataPointX + SPACE_X
            : dataPointX - tooltipWidth - SPACE_X;

          // If tooltip is in bottom half, place at mid-point, otherwise adjust down slightly.
          const tooltipPositionY =
            dataPointY > chartHeight / 2
              ? dataPointY - chartHeight / 2
              : dataPointY - chartHeight / 3;

          // Tooltip gets positioned next to the active point of the first line in the series.
          const coords = [tooltipPositionX, tooltipPositionY];

          return coords;
        },
      },
      grid: {
        // NB(alex): The general philosophy is to fill the entire containing element and to let
        // external elements determine the chart's size instead of doing it here.
        left: gridLeft,
        bottom: X_AXIS_PADDING_TOP,
        right: gridRight,
        top: 8, // Top label on y axis gets cut off.
        containLabel: true,
      },
      textStyle: {
        fontFamily: "Satoshi",
      },
      xAxis: {
        type: "category", // NB(alex): We currently only support "category", but we may want to expand functionality for the remaining types, "time" | "value" | "log".
        boundaryGap: false,
        axisTick: {
          alignWithLabel: true,
        },
        axisLine: {
          lineStyle: {
            type: "dashed",
            width: 1,
            color: colors.grey[200],
          },
        },
        axisLabel: {
          interval: (index, value) => {
            return getXAxisIntervalsByChartWidth({
              index,
              value,
              dataLength: data.length,
              chartWidth: chartInstance.current?.getWidth() ?? 0,
            });
          },
          // Gets replaced by shimmer if there are still labels while loading.
          color: isLoading ? "transparent" : colors.grey[600],
          formatter: (value) => {
            return formatXAxisValue({ value, type: "YYYY-MM-DD", compact: true });
          },
          padding: [X_AXIS_PADDING_TOP, 0, 0, 0],
          hideOverlap: true,
        },
      },
      yAxis: {
        type: "value",
        min: 0, // NB(alex): Needed to add this or else the y-axis would randomly go below zero, even when there weren't any negative values.
        axisLine: {
          show: true,
          lineStyle: {
            type: "dashed",
            dashOffset: 10,
            width: 1,
            color: colors.grey[200],
          },
        },
        axisLabel: { show: false },
        splitLine: { show: false },
      },
      series: [
        {
          color: getChartColorHex(color),
          type: "line",
          showSymbol: false,
          smooth: 0.15,
          lineStyle: {
            width: 1.5,
          },
          areaStyle: {
            opacity: 0.13,
            color: getChartGradientByColor(color),
          },
          data,
        },
      ],
    });

    // Cleanup on component unmount.
    return () => {
      if (chartInstance.current) {
        chartInstance.current.dispose();
      }
    };
  }, [data, renderTooltip, gridLeft, gridRight, color, isLoading]);

  const [chartWidth, setChartWidth] = useState(0);
  const xAxisLoadingContainerRef = useRef<HTMLDivElement>(null);

  // Resize the chart when the size changes.
  useResizeObserver(chartContainerRef, () => {
    requestAnimationFrame(() => {
      chartInstance.current?.resize();
      setChartWidth(chartInstance.current?.getWidth() ?? 0);
    });
  });

  const X_AXIS_PX = 16;

  return (
    <div
      // Ensure the container has a size.
      className={cn("relative flex h-80 w-full flex-col", className)}
    >
      <div ref={chartContainerRef} className="flex-1" />

      {isLoading && (
        <div
          className="absolute inset-x-0 top-0 z-10 flex items-center justify-center"
          style={{
            bottom: 28 + 64, // NB(alex): `64` is arbitrary and `28` is the x-axis height. I tried to dynamically compute this but it was buggy. Doing this out of laziness for now, but we can dynamically compute it if needed, but it is harder than expected to do correctly.
          }}
        >
          <DotsLoader dots={5} />
        </div>
      )}

      {isLoading && (
        <div
          ref={xAxisLoadingContainerRef}
          className={cn(
            "inset-x-4 bottom-0 flex items-center justify-between gap-2.5 px-4",
            data.length > 0 && "absolute" // x-axis labels become transparent when loading, but if they don't exist at all, we want the loading indicators to actually shift the chart upwards to replace the labels.
          )}
          style={{
            paddingTop: X_AXIS_PADDING_TOP - 2, // NB(alex): I thought this would match without the `-2` but it doesn't...
            paddingLeft: X_AXIS_PX,
            paddingRight: X_AXIS_PX,
          }}
        >
          {Array.from({
            length: getXAxisLabelCountByChartWidth({
              chartWidth: chartWidth,
              xAxisHorizontalPadding: 16,
              xAxisLabelGapWidth: 16,
              xAxisLabelWidth: 56,
            }),
          }).map((_, index) => (
            <Shimmer key={index} className="h-3.5 w-14" />
          ))}
        </div>
      )}
    </div>
  );
};

export default BalanceLineChart;
