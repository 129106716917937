const exampleRawText = `2024-04-03	$1,339	$1,339
2024-04-04	$2,851	$2,851
2024-04-05	$5,246	$5,246
2024-04-06	$8,261	$8,261
2024-04-07	$10,986	$10,986
2024-04-08	$12,578	$12,578
2024-04-09	$14,275	$14,275
2024-04-10	$14,783	$14,895
2024-04-11	$13,707	$15,599
2024-04-12	$14,220	$15,999
2024-04-13	$14,392	$15,879
2024-04-14	$14,921	$16,669
2024-04-15	$15,253	$17,153
2024-04-16	$15,638	$17,326
2024-04-17	$16,145	$18,081
2024-04-18	$16,565	$19,498
2024-04-19	$17,283	$20,228
2024-04-20	$17,733	$20,557
2024-04-21	$18,704	$21,055
2024-04-22	$18,407	$21,842
2024-04-23	$16,799	$21,801
2024-04-24	$16,875	$21,633
2024-04-25	$16,909	$21,065
2024-04-26	$16,339	$20,354
2024-04-27	$16,472	$20,372
2024-04-28	$16,880	$19,256
2024-04-29	$17,190	$18,356
2024-04-30	$17,793	$19,486
2024-05-01	$18,433	$18,725
2024-05-02	$19,439	$18,291
2024-05-03	$20,906	$18,791
2024-05-04	$21,551	$20,124
2024-05-05	$21,686	$20,828
2024-05-06	$21,873	$21,689
2024-05-07	$22,325	$22,380
2024-05-08	$22,631	$24,479
2024-05-09	$22,294	$25,023
2024-05-10	$21,665	$25,451
2024-05-11	$21,663	$27,050
2024-05-12	$21,517	$28,275
2024-05-13	$21,085	$28,469
2024-05-14	$23,096	$27,681
2024-05-15	$22,664	$26,822
2024-05-16	$22,651	$27,036
2024-05-17	$23,430	$28,169
2024-05-18	$24,351	$27,140
2024-05-19	$25,011	$27,319
2024-05-20	$27,248	$27,720
2024-05-21	$25,864	$29,334
2024-05-22	$25,569	$29,024
2024-05-23	$25,324	$29,327
2024-05-24	$26,427	$28,797
2024-05-25	$27,270	$29,256
2024-05-26	$28,175	$29,080
2024-05-27	$26,478	$30,101
2024-05-28	$26,830	$29,213
2024-05-29	$27,612	$29,310
2024-05-30	$29,554	$28,387
2024-05-31	$28,295	$28,098
2024-06-01	$27,423	$27,278
2024-06-02	$27,217	$27,540
2024-06-03	$27,773	$25,956
2024-06-04	$26,631	$24,547
2024-06-05	$25,615	$24,661
2024-06-06	$24,154	$25,285
2024-06-07	$23,873	$24,715
2024-06-08	$23,796	$24,088
2024-06-09	$23,865	$24,238
2024-06-10	$22,825	$25,116
2024-06-11	$24,279	$27,572
2024-06-12	$24,999	$28,236
2024-06-13	$24,443	$28,616
2024-06-14	$24,114	$29,064
2024-06-15	$23,882	$29,802
2024-06-16	$24,756	$29,133
2024-06-17	$24,501	$27,672
2024-06-18	$23,513	$26,215
2024-06-19	$23,601	$25,931
2024-06-20	$23,333	$25,687
2024-06-21	$23,519	$24,865
2024-06-22	$24,032	$24,087
2024-06-23	$22,335	$23,007
2024-06-24	$22,348	$23,545
2024-06-25	$21,881	$23,622
2024-06-26	$21,293	$23,593
2024-06-27	$21,099	$23,629
2024-06-28	$21,030	$23,600
2024-06-29	$21,171	$24,141
2024-06-30	$21,883	$26,596
2024-07-01	$22,520	$27,329
2024-07-02	$21,907	$28,202
2024-07-03	$21,276	$27,916
2024-07-04	$21,060	$27,057
2024-07-05	$20,790	$27,543
2024-07-06	$19,630	$26,281
2024-07-07	$18,669	$23,872
2024-07-08	$17,381	$22,607
2024-07-09	$17,918	$21,629
2024-07-10	$17,732	$21,566
2024-07-11	$18,492	$22,443
2024-07-12	$19,396	$21,688
2024-07-13	$21,279	$21,904
2024-07-14	$21,492	$23,166
2024-07-15	$21,215	$23,998
2024-07-16	$23,072	$24,324
2024-07-17	$24,176	$23,911
2024-07-18	$24,011	$23,110
2024-07-19	$23,202	$23,571
2024-07-20	$22,010	$22,466
2024-07-21	$21,583	$20,984
2024-07-22	$21,247	$20,211
2024-07-23	$18,682	$19,761
2024-07-24	$17,843	$20,267
2024-07-25	$17,472	$20,921
2024-07-26	$17,055	$21,162
2024-07-27	$16,607	$22,676
2024-07-28	$17,485	$23,489
2024-07-29	$18,231	$24,014
2024-07-30	$18,332	$23,358
2024-07-31	$17,654	$22,572
2024-08-01	$17,888	$24,424
2024-08-02	$18,496	$24,305
2024-08-03	$19,205	$24,469
2024-08-04	$18,935	$25,172
2024-08-05	$18,090	$24,569
2024-08-06	$18,973	$24,671
2024-08-07	$18,678	$24,809
2024-08-08	$21,121	$21,690
2024-08-09	$21,389	$21,098
2024-08-10	$21,121	$20,155
2024-08-11	$22,281	$17,412
2024-08-12	$23,222	$17,307
2024-08-13	$22,210	$17,813
2024-08-14	$22,444	$17,745
2024-08-15	$21,422	$17,812
2024-08-16	$21,493	$17,519
2024-08-17	$22,116	$17,067
2024-08-18	$21,768	$18,480
2024-08-19	$21,508	$19,173
2024-08-20	$21,225	$19,485
2024-08-21	$20,983	$18,856
2024-08-22	$18,796	$18,676
2024-08-23	$17,893	$18,363
2024-08-24	$17,336	$18,365
2024-08-25	$16,029	$17,486
2024-08-26	$15,467	$16,119
2024-08-27	$15,621	$19,859
2024-08-28	$16,625	$24,989
2024-08-29	$17,007	$30,339
2024-08-30	$19,182	$35,373
2024-08-31	$19,060	$39,557
2024-09-01	$20,614	$43,527
2024-09-02	$21,768	$50,820
2024-09-03	$22,791	$50,753
2024-09-04	$22,211	$46,592
2024-09-05	$22,283	$40,521
2024-09-06	$20,298	$34,869
2024-09-07	$20,482	$29,818
2024-09-08	$19,193	$25,705
2024-09-09	$18,040	$18,386
2024-09-10	$17,272	$12,606
2024-09-11	$16,484	$11,730
2024-09-12	$19,187	$11,834
2024-09-13	$20,626	$12,748
2024-09-14	$21,826	$13,358
2024-09-15	$22,330	$14,959
2024-09-16	$22,609	$14,915
2024-09-17	$22,942	$15,338
2024-09-18	$24,188	$14,855
2024-09-19	$21,876	$15,329
2024-09-20	$21,058	$14,745
2024-09-21	$19,881	$14,820
2024-09-22	$19,703	$12,783
2024-09-23	$19,972	$13,568
2024-09-24	$20,165	$14,021
2024-09-25	$19,534	$14,117
2024-09-26	$20,766	$14,279
2024-09-27	$21,183	$14,481
2024-09-28	$21,596	$13,763
2024-09-29	$21,773	$14,271
2024-09-30	$22,056	$13,922
2024-10-01	$21,689	$13,207
2024-10-02	$21,522	$13,007
2024-10-03	$19,743	$13,250
2024-10-04	$19,433	$13,840
2024-10-05	$19,028	$15,366
2024-10-06	$19,149	$15,557
2024-10-07	$18,480	$15,001
2024-10-08	$18,937	$15,676
2024-10-09	$19,477	$16,747
2024-10-10	$20,021	$16,076
2024-10-11	$19,510	$16,852
2024-10-12	$19,413	$16,983
2024-10-13	$18,984	$16,908
2024-10-14	$19,966	$17,791
2024-10-15	$19,675	$17,311
2024-10-16	$19,060	$16,768
2024-10-17	$20,232	$18,327
2024-10-18	$20,577	$17,204
2024-10-19	$21,156	$15,476
2024-10-20	$21,059	$15,453
2024-10-21	$19,785	$14,788
2024-10-22	$19,582	$15,145
2024-10-23	$19,444	$15,982
2024-10-24	$17,528	$14,434
2024-10-25	$17,341	$13,802
2024-10-26	$16,547	$14,272
2024-10-27	$16,292	$14,179
2024-10-28	$16,605	$14,825
2024-10-29	$16,001	$14,790
2024-10-30	$16,002	$13,893
2024-10-31	$15,497	$13,820
2024-11-01	$15,773	$14,390
2024-11-02	$16,424	$14,436
2024-11-03	$17,020	$13,710
2024-11-04	$16,661	$13,228
2024-11-05	$16,393	$13,968
2024-11-06	$16,012	$14,536
2024-11-07	$16,359	$15,470
2024-11-08	$15,593	$15,404
2024-11-09	$14,958	$14,780
2024-11-10	$14,476	$15,145
2024-11-11	$14,718	$14,823
2024-11-12	$14,716	$13,974
2024-11-13	$15,158	$13,459
2024-11-14	$16,331	$12,060
2024-11-15	$16,641	$11,760
2024-11-16	$19,145	$11,743
2024-11-17	$20,732	$11,581
2024-11-18	$22,476	$11,697
2024-11-19	$24,175	$11,843
2024-11-20	$25,937	$12,090
2024-11-21	$25,718	$12,173
2024-11-22	$26,557	$12,039
2024-11-23	$24,682	$14,014
2024-11-24	$24,075	$13,911
2024-11-25	$26,138	$14,621
2024-11-26	$27,326	$20,126
2024-11-27	$28,718	$24,662
2024-11-28	$30,990	$27,876
2024-11-29	$39,203	$35,382
2024-11-30	$44,744	$38,812
2024-12-01	$47,746	$44,121
2024-12-02	$50,018	$50,565
2024-12-03	$52,238	$50,583
2024-12-04	$49,826	$46,103
2024-12-05	$46,611	$43,803
2024-12-06	$38,041	$37,103
2024-12-07	$31,827	$32,537
2024-12-08	$27,768	$27,889
2024-12-09	$21,783	$22,033
2024-12-10	$17,108	$18,422
2024-12-11	$17,356	$18,759
2024-12-12	$18,362	$19,654
2024-12-13	$21,747	$20,265
2024-12-14	$31,462	$21,487
2024-12-15	$36,111	$23,492
2024-12-16	$36,191	$24,399
2024-12-17	$37,582	$23,856
2024-12-18	$36,891	$24,366
2024-12-19	$36,151	$24,309
2024-12-20	$32,243	$23,994
2024-12-21	$21,926	$23,056
2024-12-22	$16,078	$21,392
2024-12-23	$15,424	$20,015
2024-12-24	$13,225	$18,294
2024-12-25	$12,260	$16,302
2024-12-26	$11,489	$14,459
2024-12-27	$10,879	$13,313
2024-12-28	$10,851	$11,260
2024-12-29	$11,232	$9,849
2024-12-30	$11,397	$8,913
2024-12-31	$12,007	$9,152
2025-01-01	$12,104	$9,339
2025-01-02	$12,697	$9,173
2025-01-03	$13,166	$8,629
2025-01-04	$13,465	$11,520
2025-01-05	$13,694	$12,759
2025-01-06	$13,321	$13,438
2025-01-07	$13,706	$12,896
2025-01-08	$14,004	$15,795
2025-01-09	$13,980	$15,986
2025-01-10	$14,089	$16,154
2025-01-11	$14,133	$14,367
2025-01-12	$14,036	$14,285
2025-01-13	$14,148	$13,419
2025-01-14	$14,160	$13,265
2025-01-15	$14,513	$10,743
2025-01-16	$14,722	$11,680
2025-01-17	$14,969	$11,716
2025-01-18	$14,975	$11,739
2025-01-19	$15,868	$12,559
2025-01-20	$16,239	$12,735
2025-01-21	$15,837	$13,460
2025-01-22	$15,540	$13,282
2025-01-23	$14,624	$12,068
2025-01-24	$14,100	$11,407
2025-01-25	$14,145	$11,439
2025-01-26	$13,737	$9,286
2025-01-27	$13,526	$9,013
2025-01-28	$13,960	$8,665
2025-01-29	$14,118	$8,440
2025-01-30	$15,172	$9,088
2025-01-31	$15,593	$10,407
2025-02-01	$15,606	$9,969
2025-02-02	$15,770	$10,195
2025-02-03	$15,971	$9,822
2025-02-04	$15,937	$9,931
2025-02-05	$17,351	$10,451
2025-02-06	$17,896	$14,424
2025-02-07	$18,094	$16,417
2025-02-08	$19,560	$19,721
2025-02-09	$19,422	$20,236
2025-02-10	$19,832	$20,232
2025-02-11	$20,276	$19,749
2025-02-12	$19,045	$20,484
2025-02-13	$18,226	$16,595
2025-02-14	$17,846	$14,380
2025-02-15	$16,784	$12,629
2025-02-16	$16,935	$12,397
2025-02-17	$17,438	$13,796
2025-02-18	$17,347	$13,759
2025-02-19	$23,053	$13,684
2025-02-20	$25,413	$13,211
2025-02-21	$27,311	$13,026
2025-02-22	$28,251	$11,732
2025-02-23	$28,952	$13,087
2025-02-24	$29,249	$13,503
2025-02-25	$34,407	$13,830
2025-02-26	$31,555	$12,421
2025-02-27	$32,558	$12,538
2025-02-28	$34,298	$12,412
2025-03-01	$34,774	$12,234
2025-03-02	$35,310	$10,787
2025-03-03	$36,132	$9,596
2025-03-04	$32,011	$9,676
2025-03-05	$30,510	$10,561
2025-03-06	$28,294	$10,782
2025-03-07	$27,871	$11,435
2025-03-08	$27,463	$12,142
2025-03-09	$27,798	$12,120
2025-03-10	$27,239	$12,979
2025-03-11	$26,483	$13,321
2025-03-12	$26,451	$15,059
2025-03-13	$26,611	$16,954
2025-03-14	$25,319	$17,057
2025-03-15	$25,542	$17,794
2025-03-16	$25,541	$18,309
2025-03-17	$25,554	$18,428
2025-03-18	$25,927	$18,591
2025-03-19	$25,688	$17,280
2025-03-20	$26,823	$15,605
2025-03-21	$26,329	$16,000
2025-03-22	$26,543	$16,107
2025-03-23	$26,166	$16,713
2025-03-24	$26,011	$16,648
2025-03-25	$26,726	$16,763
2025-03-26	$27,581	$17,624
2025-03-27	$26,255	$17,768
2025-03-28	$26,644	$17,387
2025-03-29	$27,057	$17,393
2025-03-30	$27,562	$17,148
`;

export { exampleRawText };
