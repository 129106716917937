import { PlusCircle } from "@phosphor-icons/react";
import { captureException } from "@sentry/react";
import emptyExternalBankAccounts from "assets/empty-external-bank-accounts.svg";
import { useFinishGetStartedActionItemMutation } from "modules/action-items/mutations/useFinishGetStartedActionItemMutation";
import { useRefreshIntelligenceStatusQuery } from "modules/insights/queries/useIntelligenceStatus";
import PlaidConnectionCard from "modules/plaid/components/connection-cards/PlaidConnectionCard";
import PlaidConnectionCardContents from "modules/plaid/components/connection-cards/PlaidConnectionCardContents";
import PlaidNewConnectionCardContents from "modules/plaid/components/connection-cards/PlaidNewConnectionCardContents";
import PlaidPendingConnectionCardContents, {
  PlaidPendingConnection,
} from "modules/plaid/components/connection-cards/PlaidPendingConnectionCardContents";
import compareConnections from "modules/plaid/components/PlaidConnectionsList/compareConnections";
import PlaidLinkModal from "modules/plaid/components/PlaidLinkModal";
import {
  usePlaidConnections,
  useRefreshPlaidConnectionsQueries,
} from "modules/plaid/queries/plaidConnectionsQueryHooks";
import { useRefreshAllPlaidAccountsQueries } from "modules/plaid/queries/usePlaidAccounts";
import { FC, useCallback, useMemo, useState } from "react";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { useSetRecoilState } from "recoil";
import PlaidConnectionRep from "reps/PlaidConnectionRep";
import duplicatePlaidConnectionGuidState from "state/plaid/duplicateConnectionGuid";
import isPlaidExistingAccountModalOpenState from "state/plaid/isExistingAccountModalOpen";
import EmptyCardsList from "ui/data-display/EmptyCardsList";
import { notify } from "ui/feedback/Toast";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import { HighbeamApiError } from "utils/ajax";
import { HighbeamPlaidLinkOnSuccess } from "utils/customHooks/useHighbeamPlaidLink";
import useMountEffect from "utils/customHooks/useMountEffect";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import useIsAllowedToConnectBankAccounts from "utils/permissions/useIsAllowedToConnectBankAccounts";
import useSearchParamValue from "utils/search-params/useSearchParamValue";

type NoPlaidConnectionsDisplayProps = {
  openPlaidLinkModal: () => void;
};

const NoPlaidConnectionsDisplay: FC<NoPlaidConnectionsDisplayProps> = ({ openPlaidLinkModal }) => {
  const isAllowedToConnectBankAccounts = useIsAllowedToConnectBankAccounts();

  return (
    <>
      <EmptyCardsList>
        <img src={emptyExternalBankAccounts} alt="No connected bank accounts" />
        <ButtonWithTooltip
          disabled={!isAllowedToConnectBankAccounts}
          variant="secondary"
          onClick={openPlaidLinkModal}
          tooltip={
            !isAllowedToConnectBankAccounts && "You don’t have permission to connect bank accounts."
          }
        >
          <PlusCircle />
          Connect an account
        </ButtonWithTooltip>
      </EmptyCardsList>
    </>
  );
};

type PlaidLinkModalState = null | {
  existingConnectionGuid?: string;
};

const showConnectionSuccessToast = (
  metadata: PlaidLinkOnSuccessMetadata,
  existingConnectionBeingUpdated?: PlaidConnectionRep.Complete
) => {
  const name = metadata.institution?.name ?? "external";
  if (existingConnectionBeingUpdated) {
    notify("success", `Your ${name} account is connected 🎉.`);
  } else {
    notify("success", `Your ${name} account has been added 🎉.`);
  }
};

export const SETTINGS_PLAID_LINK_MODAL_KEY = "connect";

const PlaidConnectionsList: FC = () => {
  const plaidConnections = usePlaidConnections();
  const [plaidLinkModalState, setPlaidLinkModalState] = useState<PlaidLinkModalState>(null);

  // TODO(alex): HB-7034 Need to decouple connecting to Plaid from this component. This is a hack that opens the modal so users don't have to click a "connect" button a second time.
  const [connect] = useSearchParamValue(SETTINGS_PLAID_LINK_MODAL_KEY, "false");
  useMountEffect(() => {
    if (!connect || connect === "false") {
      return;
    } else {
      // NB(alex): Not sure why, but calling this pushes to history so you have to click the browser's "back" button twice to go back. Unsure why :/
      if (connect === "true") {
        setPlaidLinkModalState({});
      } else {
        setPlaidLinkModalState({ existingConnectionGuid: connect });
      }
    }
  });

  const [pendingConnection, setPendingConnection] = useState<PlaidPendingConnection | null>(null);
  const connectionsIncludingPending = useMemo(() => {
    // NB(alex): Fixes an issue where there's a brief window where the pending connection appears next to the newly refreshed connection.
    const pendingConnectionIsDuplicate = plaidConnections.some((connection) => {
      const institutionName = pendingConnection?.linkSuccessMetadata.institution?.name;
      return institutionName && institutionName === connection.institutionName;
    });

    if (!pendingConnection || pendingConnectionIsDuplicate) {
      return plaidConnections.sort(compareConnections);
    }
    // If a pending connection exists which is modifying an existing one, exclude that existing connection
    // in favor of the pending version.
    const unmodifiedConnections = plaidConnections.filter(
      (connection) => connection.guid !== pendingConnection.modifyingExistingConnection?.guid
    );
    return [...unmodifiedConnections, pendingConnection].sort(compareConnections);
  }, [plaidConnections, pendingConnection]);

  const refreshPlaidConnectionsQuery = useRefreshPlaidConnectionsQueries();
  const refreshAllPlaidAccountsQueries = useRefreshAllPlaidAccountsQueries();

  const setIsPlaidExistingAccountModalOpen = useSetRecoilState(
    isPlaidExistingAccountModalOpenState
  );
  const setDuplicatePlaidConnectionGuid = useSetRecoilState(duplicatePlaidConnectionGuidState);
  const { mutateAsync: finishGetStartedActionItem } = useFinishGetStartedActionItemMutation();
  const { segmentTrack } = useSegment();

  const refreshIntelligenceStatusQuery = useRefreshIntelligenceStatusQuery();

  const onLinkSuccess: HighbeamPlaidLinkOnSuccess = useCallback(
    (
      metadata: PlaidLinkOnSuccessMetadata,
      finalizationPromise: Promise<void>,
      existingConnectionBeingUpdated?: PlaidConnectionRep.Complete
    ) => {
      setPendingConnection({
        linkSuccessMetadata: metadata,
        modifyingExistingConnection: existingConnectionBeingUpdated,
      });
      finalizationPromise
        .then(async () => {
          showConnectionSuccessToast(metadata, existingConnectionBeingUpdated);
          await Promise.all([
            refreshPlaidConnectionsQuery(),
            refreshAllPlaidAccountsQueries(),
            finishGetStartedActionItem({ step: "ConnectBank", state: "Complete" }),
            refreshIntelligenceStatusQuery(),
          ]);
        })
        .catch((e: Error) => {
          // TODO move PlaidConnectionExistsModal from global router into this component,
          //   and use state to launch it here. -ayoon
          if (e instanceof HighbeamApiError && e.error === "PlaidConnectionAlreadyExists") {
            setDuplicatePlaidConnectionGuid(e.properties.connectionGuid);
            setIsPlaidExistingAccountModalOpen(true);
            segmentTrack(SEGMENT_EVENTS.PLAID_LINK_DUPLICATE, metadata);
            return;
          } else {
            captureException(e);
          }

          notify("error", "We were unable to connect the account.");
          segmentTrack(SEGMENT_EVENTS.PLAID_LINK_FAILED, metadata);
        })
        .finally(() => setPendingConnection(null));
    },
    [
      finishGetStartedActionItem,
      refreshAllPlaidAccountsQueries,
      refreshPlaidConnectionsQuery,
      refreshIntelligenceStatusQuery,
      segmentTrack,
      setDuplicatePlaidConnectionGuid,
      setIsPlaidExistingAccountModalOpen,
    ]
  );

  return (
    <>
      {plaidLinkModalState && (
        <PlaidLinkModal
          onClose={() => setPlaidLinkModalState(null)}
          existingConnectionGuid={plaidLinkModalState.existingConnectionGuid}
          onLinkSuccess={onLinkSuccess}
        />
      )}
      <div className="@container">
        {connectionsIncludingPending.length === 0 ? (
          <NoPlaidConnectionsDisplay openPlaidLinkModal={() => setPlaidLinkModalState({})} />
        ) : (
          <div className="grid gap-6 @xl:max-w-screen-mobile-phone @xl:grid-cols-2 @4xl:max-w-screen-large-desktop @4xl:grid-cols-3">
            {connectionsIncludingPending.map((connection) => {
              if ("guid" in connection) {
                return (
                  <PlaidConnectionCard key={connection.guid}>
                    <PlaidConnectionCardContents
                      connection={connection}
                      openPlaidLinkModal={() =>
                        setPlaidLinkModalState({ existingConnectionGuid: connection.guid })
                      }
                    />
                  </PlaidConnectionCard>
                );
              } else {
                return (
                  <PlaidConnectionCard key={"PENDING"}>
                    <PlaidPendingConnectionCardContents pendingConnection={connection} />
                  </PlaidConnectionCard>
                );
              }
            })}

            <PlaidConnectionCard className="bg-grey-50">
              <PlaidNewConnectionCardContents
                openPlaidLinkModal={() => setPlaidLinkModalState({})}
              />
            </PlaidConnectionCard>
          </div>
        )}
      </div>
    </>
  );
};

export default PlaidConnectionsList;
