import { Calculator as CalculatorIcon } from "@phosphor-icons/react";
import { CashConversionCycleChatCalculatorRep } from "modules/chat/reps/ChatCalculatorRep";
import ParagraphChatElement from "pages/ai-chat/ChatElement/ParagraphChatElement";
import React, { useMemo, useState } from "react";
import MoneyAmountV2 from "ui/data-display/money/MoneyAmountV2";
import CurrencyInputV2 from "ui/inputs/CurrencyInputV2";
import TextInputV2 from "ui/inputs/TextInputV2";
import { Heading4, Paragraph } from "ui/typography";
import { stripNonNumeric } from "utils/string";

import Calculator from ".";

type Props = {
  calculator: CashConversionCycleChatCalculatorRep;
};

const CashConversionCycleCalculator: React.FC<Props> = () => {
  const [annualSales, setAnnualSales] = useState<number>();
  const [grossProfit, setGrossProfit] = useState<number>();
  const [inventoryTurnsPerYear, setInventoryTurnsPerYear] = useState<number>();
  const [daysSalesOutstanding, setDaysSalesOutstanding] = useState<number>();
  const [daysPayableOutstanding, setDaysPayableOutstanding] = useState<number>();

  const cashConversionCycle = useMemo(() => {
    if (!inventoryTurnsPerYear) return;
    if (daysSalesOutstanding === undefined) return;
    if (daysPayableOutstanding === undefined) return;
    return (
      365 / inventoryTurnsPerYear + (daysSalesOutstanding ?? 0) - (daysPayableOutstanding ?? 0)
    );
  }, [daysPayableOutstanding, daysSalesOutstanding, inventoryTurnsPerYear]);

  const workingCapitalNeeded = useMemo(() => {
    if (annualSales === undefined) return;
    if (grossProfit === undefined) return;
    if (cashConversionCycle === undefined) return;
    return (annualSales / 365) * (1 - grossProfit / 100) * cashConversionCycle;
  }, [annualSales, cashConversionCycle, grossProfit]);

  return (
    <div className="flex flex-col gap-6">
      <Calculator.Frame>
        <Calculator.Title icon={<CalculatorIcon size={20} />} title="Cash Conversion Cycle (CCC)" />
        <Calculator.Section>
          <Calculator.Row
            left={
              <Calculator.RowContent>
                <Paragraph className="font-medium">Your annual sales</Paragraph>
              </Calculator.RowContent>
            }
            right={
              <Calculator.RowContent className="gap-4">
                <CurrencyInputV2
                  className="w-44"
                  prefixValue="$"
                  allowDecimals={false}
                  value={annualSales ? annualSales * 100 : undefined}
                  onChange={(value) => {
                    setAnnualSales(value ? value / 100 : undefined);
                  }}
                />
                <Paragraph className="w-20 text-grey-600" />
              </Calculator.RowContent>
            }
          />
          <Calculator.Row
            left={
              <Calculator.RowContent>
                <Paragraph className="font-medium">Landed product margin / Gross profit</Paragraph>
              </Calculator.RowContent>
            }
            right={
              <Calculator.RowContent className="gap-4">
                <TextInputV2
                  className="w-44"
                  endAdornment="%"
                  value={grossProfit === undefined ? "" : String(grossProfit)}
                  onChange={(value) => {
                    const numericValue = stripNonNumeric(value);
                    setGrossProfit(numericValue.length > 0 ? Number(numericValue) : undefined);
                  }}
                />
                <Paragraph className="w-20 text-grey-600" />
              </Calculator.RowContent>
            }
          />
          <Calculator.Row
            left={
              <Calculator.RowContent>
                <Paragraph className="font-medium">Inventory turns per year</Paragraph>
              </Calculator.RowContent>
            }
            right={
              <Calculator.RowContent className="gap-4">
                <TextInputV2
                  className="w-44"
                  value={inventoryTurnsPerYear === undefined ? "" : String(inventoryTurnsPerYear)}
                  onChange={(value) => {
                    const numericValue = stripNonNumeric(value);
                    setInventoryTurnsPerYear(
                      numericValue.length > 0 ? Number(numericValue) : undefined
                    );
                  }}
                />
                <Paragraph className="w-20 text-grey-600">turns</Paragraph>
              </Calculator.RowContent>
            }
          />
          <Calculator.Row
            left={
              <Calculator.RowContent>
                <Paragraph className="font-medium">Days sales outstanding</Paragraph>
              </Calculator.RowContent>
            }
            right={
              <Calculator.RowContent className="gap-4">
                <TextInputV2
                  className="w-44"
                  value={daysSalesOutstanding === undefined ? "" : String(daysSalesOutstanding)}
                  onChange={(value) => {
                    const numericValue = stripNonNumeric(value);
                    setDaysSalesOutstanding(
                      numericValue.length > 0 ? Number(numericValue) : undefined
                    );
                  }}
                />
                <Paragraph className="w-20 text-grey-600">days</Paragraph>
              </Calculator.RowContent>
            }
          />
          <Calculator.Row
            left={
              <Calculator.RowContent>
                <Paragraph className="font-medium">Days payable outstanding</Paragraph>
              </Calculator.RowContent>
            }
            right={
              <Calculator.RowContent className="gap-4">
                <TextInputV2
                  className="w-44"
                  value={daysPayableOutstanding === undefined ? "" : String(daysPayableOutstanding)}
                  onChange={(value) => {
                    const numericValue = stripNonNumeric(value);
                    setDaysPayableOutstanding(
                      numericValue.length > 0 ? Number(numericValue) : undefined
                    );
                  }}
                />
                <Paragraph className="w-20 text-grey-600">days</Paragraph>
              </Calculator.RowContent>
            }
          />
        </Calculator.Section>
        <div className="flex flex-row">
          <Calculator.Section className="gap-1 pt-3">
            <Heading4 className="font-medium">Your cash conversion cycle</Heading4>
            {cashConversionCycle ? (
              <Paragraph className="w-fit border-b-2 border-dotted border-grey-500 text-2xl font-medium text-grey-500">
                {Math.round(cashConversionCycle)} days
              </Paragraph>
            ) : (
              <Paragraph className="w-fit border-b-2 border-dotted border-grey-500 text-xs font-medium text-grey-500">
                Please complete the form.
              </Paragraph>
            )}
          </Calculator.Section>
          <div className="mx-11 border-l-2 border-grey-100" />
          <Calculator.Section className="gap-1 pt-3">
            <Heading4 className="font-medium">Working capital needed</Heading4>
            {workingCapitalNeeded ? (
              <Paragraph className="w-fit border-b-2 border-dotted border-grey-500 text-2xl font-medium text-grey-500">
                <MoneyAmountV2
                  className="text-2xl"
                  showCurrencySymbol
                  amount={String(Math.ceil(workingCapitalNeeded))}
                  currencyCode="USD"
                  showCents={false}
                />
              </Paragraph>
            ) : (
              <Paragraph className="w-fit border-b-2 border-dotted border-grey-500 text-xs font-medium text-grey-500">
                Please complete the form.
              </Paragraph>
            )}
          </Calculator.Section>
        </div>
      </Calculator.Frame>
      <ParagraphChatElement
        element={{
          type: "Paragraph",
          content: [
            ...(cashConversionCycle
              ? [
                  {
                    type: "Text" as const,
                    bold: false,
                    content: "You have a CCC of ",
                    italic: false,
                  },
                  {
                    type: "Text" as const,
                    bold: true,
                    content: String(Math.round(cashConversionCycle)),
                    italic: false,
                  },
                  {
                    type: "Text" as const,
                    bold: false,
                    content: " days",
                    italic: false,
                  },
                  ...(workingCapitalNeeded
                    ? [
                        {
                          type: "Text" as const,
                          bold: false,
                          content: ", which means you require ",
                          italic: false,
                        },
                        {
                          type: "Money" as const,
                          amount: Math.ceil(workingCapitalNeeded),
                          showCents: false,
                        },
                        {
                          type: "Text" as const,
                          bold: false,
                          content: " in working capital",
                          italic: false,
                        },
                      ]
                    : []),
                  {
                    type: "Text" as const,
                    bold: false,
                    content: ". ",
                    italic: false,
                  },
                ]
              : []),
            {
              type: "Text",
              bold: false,
              content:
                "Adjust your CCC inputs to see how much you can improve your working capital position!",
              italic: false,
            },
          ],
        }}
      />
    </div>
  );
};

export default CashConversionCycleCalculator;
