import { ChatUserRep } from "modules/chat/reps/ChatUserRep";
import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";

type ChatUserApi = {
  search: (businessGuid: string, userGuid: string) => Promise<ChatUserRep[]>;
};

const builder: ApiBuilder<ChatUserApi> = (api) => ({
  search: async (businessGuid, userGuid) => {
    const queryParams = new URLSearchParams({ businessGuid, userGuid });
    const url = `/chat/users?${queryParams}`;
    return (await api.get<ChatUserRep[]>(url))!;
  },
});

const useChatUserApi = () => useBackendV2Api(builder);

export default useChatUserApi;
