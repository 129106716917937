import { useQuery, keepPreviousData } from "@tanstack/react-query";
import ApySummary from "components/Accounts/InterestTier/ApySummary";
import DashboardPage from "layouts/DashboardPage";
import useBankAccountBalanceHistoryQueryOptions from "modules/bank-accounts/queries/useBankAccountBalanceHistoryQueryOptions";
import useBankAccountInterestYieldYtd from "modules/bank-accounts/queries/useBankAccountInterestYieldYtd";
import { useEffect, useState } from "react";
import BankAccountRep from "reps/BankAccountRep";
import colors from "styles/colors";
import CashDisplay from "ui/data-display/money/CashDisplay";
import SectionHeader from "ui/data-display/SectionHeader";
import ChartTools from "ui/data-visualization/ChartTools";
import DetailedAreaChart, { DetailedAreaChartDatum } from "ui/data-visualization/DetailedAreaChart";
import Tabs, { Tab } from "ui/navigation/Tabs";
import { Heading2 } from "ui/typography";
import Text from "ui/typography/Text";
import { useIsMobile } from "utils/device/useMediaQuery";
import useIsAllowedToViewAccountNumbers from "utils/permissions/useIsAllowedToViewAccountNumbers";
import {
  RelativeTimeframe,
  DEFAULT_RELATIVE_TIMEFRAME,
  timeframeToNumDaysBetweenTicks,
} from "utils/timeframe";

import styles from "./AccountInformation.module.scss";
import DomesticAccountInformation from "./DomesticAccountInformation";
import InternationalAccountInformation from "./InternationalAccountInformation";

type Props = {
  account: BankAccountRep.Complete;
};

const TABS: Tab[] = [
  {
    id: "domestic",
    label: "Domestic",
  },
  {
    id: "international",
    label: "International",
  },
];

const AccountInformation: React.FC<Props> = ({ account }) => {
  const [activeTick, setActiveTick] = useState<DetailedAreaChartDatum | null>(null);
  const [isAnimationActive, setIsAnimationActive] = useState(true);
  const isMobile = useIsMobile();
  const isAllowedToViewAccountNumbers = useIsAllowedToViewAccountNumbers();
  const [selectedTab, setSelectedTab] = useState<string>(TABS[0].id);
  const [timeframe, setTimeframe] = useState<RelativeTimeframe>(DEFAULT_RELATIVE_TIMEFRAME);
  const numDaysBetweenTicks = timeframeToNumDaysBetweenTicks(timeframe);
  const { data: bankAccountBalanceHistory, isFetching: isFetchingBankAccountBalanceHistory } =
    useQuery({
      ...useBankAccountBalanceHistoryQueryOptions(account.unitCoDepositAccountId, timeframe),
      placeholderData: keepPreviousData,
    });

  const interest = useBankAccountInterestYieldYtd(account.guid);

  useEffect(() => {
    const animationTimer = setTimeout(() => {
      setIsAnimationActive(false);
    }, 1500);
    return () => clearTimeout(animationTimer);
  }, []);

  const earningInterest = interest > 0 || account.depositProduct.interestBps > 0;
  const shouldShowInterestSection = earningInterest;

  const balanceChartHeight = shouldShowInterestSection || isMobile ? 180 : 350;

  return (
    <DashboardPage.Section>
      <div className={styles.container}>
        {bankAccountBalanceHistory && (
          <div className={styles.column}>
            <SectionHeader>
              <Heading2>Account balance</Heading2>
            </SectionHeader>
            <div>
              <CashDisplay
                color={activeTick ? colors.purple[500] : colors.black}
                cents={
                  activeTick
                    ? activeTick.amount
                    : bankAccountBalanceHistory[bankAccountBalanceHistory.length - 1]?.amount || 0
                }
              />
              <Text size={12}>as of {activeTick ? activeTick.date : "today"}</Text>
            </div>
            <ChartTools>
              <ChartTools.RelativeTimeframeSelect value={timeframe} onChange={setTimeframe} />
            </ChartTools>
            <DetailedAreaChart
              data={bankAccountBalanceHistory}
              ticks={bankAccountBalanceHistory
                .map((datum) => datum.date)
                .filter((_, i) => {
                  const len = bankAccountBalanceHistory.length;
                  return i === 0 || i === len - 1 || (len - i - 1) % numDaysBetweenTicks === 0;
                })}
              isAnimationActive={isAnimationActive}
              setActiveTick={setActiveTick}
              chartHeight={balanceChartHeight}
              isFetching={isFetchingBankAccountBalanceHistory}
            />
            {shouldShowInterestSection && <ApySummary account={account} />}
          </div>
        )}
        <div className={styles.column}>
          <SectionHeader>
            <Heading2>Account information</Heading2>
          </SectionHeader>

          <Tabs
            tabs={TABS}
            activeTab={selectedTab}
            setActiveTab={setSelectedTab}
            variant="rounded"
          />
          {selectedTab === "domestic" && (
            <DomesticAccountInformation
              account={account}
              isAllowedToViewAccountNumbers={isAllowedToViewAccountNumbers}
            />
          )}
          {selectedTab === "international" && (
            <InternationalAccountInformation
              isPrimary={account.isPrimary}
              isAllowedToViewAccountNumbers={isAllowedToViewAccountNumbers}
            />
          )}
        </div>
      </div>
    </DashboardPage.Section>
  );
};

export default AccountInformation;
