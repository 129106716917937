import { ApiBuilder, useBackendV1Api } from "modules/highbeam-api/base";
import BusinessAddressRep from "reps/BusinessAddressRep";

type BusinessAddressApi = {
  get: (businessGuid: string) => Promise<BusinessAddressRep | null>;
  update: (
    businessGuid: string,
    updater: BusinessAddressRep.Updater
  ) => Promise<BusinessAddressRep>;
};

const builder: ApiBuilder<BusinessAddressApi> = (api) => ({
  get: async (businessGuid) => {
    const url = `/businesses/${businessGuid}/address`;
    return await api.get<BusinessAddressRep>(url);
  },
  update: async (businessGuid, updater) => {
    const url = `/businesses/${businessGuid}/address`;
    return (await api.put<BusinessAddressRep>(url, updater))!;
  },
});

const useBusinessAddressApi = () => useBackendV1Api(builder);

export default useBusinessAddressApi;
