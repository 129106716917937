import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";
import ApEmailAliasRep from "reps/ApEmailAliasRep";

type ApEmailAliasApi = {
  search: (businessGuid: string) => Promise<ApEmailAliasRep.Complete[]>;
  create: (body: ApEmailAliasRep.Creator) => Promise<ApEmailAliasRep.Complete>;
};

const builder: ApiBuilder<ApEmailAliasApi> = (api) => ({
  search: async (businessGuid) => {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/accounts-payable/email-aliases?${queryParams}`;
    return (await api.get<ApEmailAliasRep.Complete[]>(url))!;
  },
  create: async (body) => {
    const url = `/accounts-payable/email-aliases`;
    return (await api.post<ApEmailAliasRep.Complete>(url, body))!;
  },
});

const useApEmailAliasApi = () => useBackendV2Api(builder);

export default useApEmailAliasApi;
