import moneyInOut from "assets/money-in-out.svg";
import BankAccountBar from "modules/bank-accounts/components/BankAccountBar";
import useCancelPaymentMutation from "modules/payments/mutations/useCancelPaymentMutation";
import { FC } from "react";
import BankAccountRep from "reps/BankAccountRep";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Pill from "ui/data-display/Pill";
import ModalV4 from "ui/overlay/ModalV4";
import useModalContext from "ui/overlay/ModalV4/useModalContext";
import Text from "ui/typography/Text";
import useModalV2 from "utils/dialog/useModalV2";

export type PaymentDirection = "Incoming" | "Outgoing";

export type CancelPaymentParams = {
  bankAccount: BankAccountRep.Complete;
  counterpartyName: string;
  paymentAmountInCents: number;
  paymentDirection: PaymentDirection;
  paymentGuid: string;
};

export const useCancelPaymentModal = () => {
  return useModalV2<CancelPaymentParams>();
};

const classes = {
  transactionCancelCard:
    "w-full h-16 border-black border-b border-b-grey-100 flex items-center gap-x-2 pb-4 mb-6",
  transactionCancelCardSummary: "flex gap-x-4 items-center",
  disclaimer: "flex flex-wrap gap-x-1",
};

type CancelSummaryMessageProps = Pick<
  CancelPaymentParams,
  "bankAccount" | "counterpartyName" | "paymentDirection" | "paymentAmountInCents"
>;

const CancelSummaryMessage: FC<CancelSummaryMessageProps> = ({
  bankAccount,
  counterpartyName,
  paymentDirection,
  paymentAmountInCents,
}) => {
  if (paymentDirection === "Outgoing") {
    return (
      <>
        <div className={classes.transactionCancelCard}>
          <div className={classes.transactionCancelCardSummary}>
            <img src={moneyInOut} alt="money icon" />
            <Text size={16} color={colors.grey[600]}>
              <MoneyAmount weight={"medium"} cents={paymentAmountInCents} /> transfer to{" "}
              <Text as="span" size={16} weight="medium" color={colors.grey[600]}>
                {counterpartyName}
              </Text>
            </Text>
          </div>
          <Pill color="grey-100" bordered>
            Pending
          </Pill>
        </div>
        <Text color={colors.grey[600]} size={16} weight="regular" className={classes.disclaimer}>
          After canceling, the funds will be returned to
          <BankAccountBar size={20} bankAccount={bankAccount} />
        </Text>
      </>
    );
  } else {
    return (
      <>
        <div className={classes.transactionCancelCard}>
          <div className={classes.transactionCancelCardSummary}>
            <img src={moneyInOut} alt="money icon" />
            <Text size={16} color={colors.grey[600]}>
              <MoneyAmount weight={"medium"} cents={paymentAmountInCents} /> transfer from{" "}
              <Text as="span" size={16} weight="medium" color={colors.grey[600]}>
                {counterpartyName}
              </Text>
            </Text>
          </div>
          <Pill color="grey-100" bordered>
            Pending
          </Pill>
        </div>
        <Text color={colors.grey[600]} size={16} weight="regular" className={classes.disclaimer}>
          After canceling, the funds will no longer be deposited into
          <BankAccountBar size={20} bankAccount={bankAccount} />
        </Text>
      </>
    );
  }
};

type CancelPaymentModalFormProps = CancelPaymentParams & {
  onSuccess: () => void;
};

const CancelPaymentModalForm: FC<CancelPaymentModalFormProps> = ({
  bankAccount,
  counterpartyName,
  paymentDirection,
  paymentAmountInCents,
  paymentGuid,
  onSuccess,
}) => {
  const { closeModalWithAnimation } = useModalContext();
  const { mutateAsync: cancelPayment, isPending } = useCancelPaymentMutation({
    onSuccess: () => {
      onSuccess();
      closeModalWithAnimation();
    },
  });

  return (
    <ModalV4.Form
      onSubmit={async () => {
        await cancelPayment({ paymentGuid: paymentGuid });
        onSuccess();
      }}
    >
      <ModalV4.Header>Cancel transfer?</ModalV4.Header>

      <ModalV4.Body>
        <CancelSummaryMessage
          bankAccount={bankAccount}
          counterpartyName={counterpartyName}
          paymentDirection={paymentDirection}
          paymentAmountInCents={paymentAmountInCents}
        />
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.SubmitButton variant="danger" isLoading={isPending}>
          Cancel transfer
        </ModalV4.SubmitButton>
        <ModalV4.CloseButton onClick={closeModalWithAnimation}>Close</ModalV4.CloseButton>
      </ModalV4.Footer>
    </ModalV4.Form>
  );
};

type Props = {
  onSuccess: () => void;
  onClose: () => void;
  params: CancelPaymentParams;
};

const CancelPaymentModal: FC<Props> = ({ onSuccess, params, onClose }) => {
  return (
    <ModalV4 onClose={onClose}>
      <CancelPaymentModalForm onSuccess={onSuccess} {...params} />
    </ModalV4>
  );
};

export default CancelPaymentModal;
