import { ComponentProps, FC, ReactNode } from "react";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Shimmer from "ui/feedback/ShimmerV2";

import { useBankAccountQuery } from "../queries/useBankAccount";

type Props = Omit<ComponentProps<typeof MoneyAmount>, "cents"> & {
  bankAccountGuid: string;
  notFoundFallback?: ReactNode;
};

const BankAccountBalanceMoneyAmountByBankAccountGuid: FC<Props> = ({
  bankAccountGuid,
  notFoundFallback = null,
  ...props
}) => {
  const { data: bankAccount, isLoading } = useBankAccountQuery(bankAccountGuid);

  if (isLoading) {
    return <Shimmer />;
  }

  if (!bankAccount) {
    return <>{notFoundFallback}</>;
  }

  return <MoneyAmount cents={bankAccount.availableBalance} {...props} />;
};

export default BankAccountBalanceMoneyAmountByBankAccountGuid;
