import { useRefreshAllBusinessMembersQueries } from "modules/business-members/queries/businessMembersQueryHooks";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useMfa from "modules/mfa/useMfa";
import CardRep from "reps/CardRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import useCardApi from "../api/useCardApi";
import { useRefreshAllCardsQueries } from "../queries/useCards";

type BaseVariables = Omit<
  CardRep.Creator,
  "type" | "businessGuid" | "accountGuid" | "associatedAccountId"
>;

type DebitCardVariables = BaseVariables & {
  type: "PHYSICAL" | "VIRTUAL";
  bankAccountGuid: string;
  unitCoAccountId: string;
};

type CreditCardVariables = BaseVariables & {
  type: "PHYSICAL_CREDIT" | "VIRTUAL_CREDIT";
  creditAccountGuid: string;
};

type Variables = DebitCardVariables | CreditCardVariables;

const useCreateCardMutation = (
  additionalOptions: MutationAdditionalOptions<CardRep.Complete, Variables> = {}
) => {
  const highbeamApi = useHighbeamApi();
  const cardApi = useCardApi();
  const { segmentTrack } = useSegment();
  const businessGuid = useBusinessGuid();
  const refreshCardsQueries = useRefreshAllCardsQueries();
  const { mfa } = useMfa();
  const refreshAllBusinessMembersQueries = useRefreshAllBusinessMembersQueries();

  return useMutationWithDefaults(
    {
      mutationFn: async (variables: Variables) => {
        await mfa();

        // NB(alex): Using a switch statement because ts was failing to work with `if` statements 🤔
        switch (variables.type) {
          case "PHYSICAL":
          case "VIRTUAL":
            const { bankAccountGuid, unitCoAccountId, ...debitCardVariables } = variables;

            return cardApi.create({
              ...debitCardVariables,
              businessGuid,
              accountGuid: bankAccountGuid,
              associatedAccountId: unitCoAccountId,
            });
          case "PHYSICAL_CREDIT":
          case "VIRTUAL_CREDIT":
            const { creditAccountGuid, ...creditCardVariables } = variables;

            // NB(alex): This lookup should be done on the backend, but I figured we can do this on the frontend for now. I initially had this in app logic, but I realized that's the wrong place for this lookup and it should be done on the backend. Decided to do it on the frontend though to ship it faster.
            const chargeCardAccount = await highbeamApi.chargeCardAccount.get(
              businessGuid,
              creditAccountGuid
            );

            if (!chargeCardAccount) {
              throw new Error("Charge card account not found");
            }

            return cardApi.create({
              ...creditCardVariables,
              accountGuid: chargeCardAccount.guid,
              associatedAccountId: chargeCardAccount.unitCoCreditAccountId,
              businessGuid,
            });
        }
      },
      onSuccess: async (data, variables) => {
        segmentTrack(SEGMENT_EVENTS.CARD_CREATED, {
          type: variables.type,
          cardId: data.cardId,
        });
        await Promise.all([
          refreshAllBusinessMembersQueries(), // Refreshes dateOfBirth
          refreshCardsQueries(),
        ]);
      },
    },
    additionalOptions
  );
};

export default useCreateCardMutation;
