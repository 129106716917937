import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";

import InsightsCategoryRep from "../reps/InsightsCategoryRep";

type InsightsCategoryApi = {
  search: () => Promise<InsightsCategoryRep.Complete[]>;
};

const builder: ApiBuilder<InsightsCategoryApi> = (api) => ({
  search: async () => {
    const url = `/insights/insights-categories`;
    return (await api.get<InsightsCategoryRep.Complete[]>(url))!;
  },
});

const useInsightsCategoryApi = () => useBackendV2Api(builder);

export default useInsightsCategoryApi;
