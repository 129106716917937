import useNonTerminatedCapitalAccounts from "modules/capital-accounts/hooks/useNonTerminatedCapitalAccounts";
import CapitalAccountsTable from "modules/capital/components/CapitalAccountsTable";
import SectionV2 from "ui/data-display/SectionV2";

const CapitalAccountsSection = () => {
  // NB(alex): CapitalAccountsTable matches the query in `CapitalAccountsTable`. We need this hack until we have empty states for the table.
  const nonTerminatedCapitalAccounts = useNonTerminatedCapitalAccounts();

  if (nonTerminatedCapitalAccounts.length === 0) {
    return null;
  }

  return (
    <SectionV2 variant="dashboard-page">
      <SectionV2.Header>
        <SectionV2.Heading>Capital</SectionV2.Heading>
      </SectionV2.Header>

      <SectionV2.Body>
        <CapitalAccountsTable capitalAccounts={nonTerminatedCapitalAccounts} />
      </SectionV2.Body>
    </SectionV2>
  );
};

export default CapitalAccountsSection;
