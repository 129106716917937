import { useFinishGetStartedActionItemMutation } from "modules/action-items/mutations/useFinishGetStartedActionItemMutation";
import { getStartedActionItemsQueryHooks } from "modules/action-items/queries/useGetStartedActionItems";
import useCreateCardMutation from "modules/cards/mutations/useCreateCardMutation";
import { CardCreditOrDebit, CardVirtualOrPhysical } from "modules/cards/types";
import { getCardTypeLabelByCardType } from "modules/cards/utils/get-card-type-label";
import getCardTypeByAttributes from "modules/cards/utils/getCardTypeByAttributes";
import { checkIsCreditCardType, checkIsPhysicalCardType } from "modules/cards/utils/typeguards";
import { FC, useCallback, useState } from "react";
import ModalV4 from "ui/overlay/ModalV4";

import CreateCardModalCardCreatedSuccessfully, {
  CreateCardModalCardCreatedSuccessfullyHeader,
} from "./CreateCardModalCardCreatedSuccessfully";
import CreateCardModalCardDetailsForm from "./CreateCardModalCardDetailsForm";
import CreateCardModalSelectCapitalAccountForm from "./CreateCardModalSelectCapitalAccountForm";
import CreateCardModalSelectCardTypeForm from "./CreateCardModalSelectCardTypeForm";
import CreateCardModalShippingAddressForm from "./CreateCardModalShippingAddressForm";
import useCreateCardModalStore, { State } from "./useCreateCardModalStore";

export type CreateCardModalDefaultValues = {
  cardName?: string;
  cardVirtualOrPhysical?: CardVirtualOrPhysical;
  cardCreditOrDebit?: CardCreditOrDebit;
  capitalAccountGuid?: string;
};

type CreateCardModalContentProps = {
  store: ReturnType<typeof useCreateCardModalStore>;
  defaultValues?: CreateCardModalDefaultValues;
};

const CreateCardModalContent: FC<CreateCardModalContentProps> = ({ defaultValues, store }) => {
  const { state, methods } = store;
  const [createdCardId, setCreatedCardId] = useState<string | null>(null);

  const defaultCardVirtualOrPhysical = defaultValues?.cardVirtualOrPhysical ?? "virtual";
  const defaultCardCreditOrDebit = defaultValues?.cardCreditOrDebit ?? "credit";
  const defaultCardName = defaultValues?.cardName;
  const defaultCapitalAccountGuid = defaultValues?.capitalAccountGuid;

  const selectedVirtualOrPhysical =
    state.formData["select-card-type"]?.virtualOrPhysical ?? defaultCardVirtualOrPhysical;
  const selectedCreditOrDebit =
    state.formData["select-card-type"]?.creditOrDebitOption.type ?? defaultCardCreditOrDebit;

  const selectedCardType = getCardTypeByAttributes({
    creditOrDebit: selectedCreditOrDebit,
    virtualOrPhysical: selectedVirtualOrPhysical,
  });

  const selectedCardTypeLabel = getCardTypeLabelByCardType({
    cardType: selectedCardType,
    capitalize: false,
  });

  const createCardMutation = useCreateCardMutation({
    onSuccess: (data) => {
      setCreatedCardId(data.cardId);
    },
  });

  const isSubmitLoading = createCardMutation.isPending;

  const createCard = async (formData: State["formData"]) => {
    if (!formData["select-card-type"]) {
      throw new Error("Missing card type");
    }

    const { creditOrDebitOption, virtualOrPhysical } = formData["select-card-type"];

    const cardType = getCardTypeByAttributes({
      creditOrDebit: creditOrDebitOption.type,
      virtualOrPhysical: virtualOrPhysical,
    });

    if (!formData["card-details"]) {
      throw new Error("Missing card details");
    }

    const isPhysicalCardType = checkIsPhysicalCardType(cardType);

    if (isPhysicalCardType && !formData["shipping-address"]) {
      throw new Error("Missing shipping address");
    }

    const { spendLimit } = formData["card-details"];
    const { associatedBankAccount } = formData["card-details"];

    const isCreditCardType = checkIsCreditCardType(cardType);

    if (isCreditCardType && !formData["select-capital-account"]) {
      throw new Error("Credit card requires a capital account");
    }

    const accountVariables = isCreditCardType
      ? {
          type: cardType,
          creditAccountGuid: formData["select-capital-account"]!.capitalAccount.guid, // NB(alex): Not sure why the `!` is necessary, we check for its existence in the "if" statement above.
        }
      : {
          type: cardType,
          bankAccountGuid: associatedBankAccount.guid,
          unitCoAccountId: associatedBankAccount.unitCoDepositAccountId,
        };

    const dateOfBirth =
      formData["card-details"].cardholder.dateOfBirth ??
      formData["card-details"].cardholderDateOfBirth?.format("YYYY-MM-DD");

    if (!dateOfBirth) {
      throw new Error("Missing date of birth");
    }

    await createCardMutation.mutateAsync({
      ...accountVariables,
      name: formData["card-details"].cardName,

      identifiableInfo: {
        dateOfBirth: dateOfBirth,
        memberGuid: formData["card-details"].cardholder.guid,
      },
      shippingAddress: formData["shipping-address"]
        ? {
            street: formData["shipping-address"].address.street1,
            street2: formData["shipping-address"].address.street2,
            city: formData["shipping-address"].address.city,
            state: formData["shipping-address"].address.state,
            postalCode: formData["shipping-address"].address.postalCode,
            country: formData["shipping-address"].address.country,
          }
        : undefined,

      dailyPurchaseLimit:
        spendLimit.enabled && spendLimit.period === "daily" ? spendLimit.amount : null,
      monthlyPurchaseLimit:
        spendLimit.enabled && spendLimit.period === "monthly" ? spendLimit.amount : null,
    });
  };

  return (
    <>
      <ModalV4.Header>
        {state.activeStep === "select-card-type" ? (
          "Create card"
        ) : state.activeStep === "card-created-successfully" && createdCardId ? (
          <CreateCardModalCardCreatedSuccessfullyHeader cardId={createdCardId} />
        ) : (
          `Create ${selectedCardTypeLabel}`
        )}
      </ModalV4.Header>

      {state.activeStep === "select-card-type" && (
        <CreateCardModalSelectCardTypeForm
          submittedDataDefaultValues={state.formData["select-card-type"]}
          defaultCardVirtualOrPhysical={defaultCardVirtualOrPhysical}
          defaultCardCreditOrDebit={defaultCardCreditOrDebit}
          defaultCapitalAccountGuid={defaultCapitalAccountGuid}
          onValid={(data) => {
            methods.setFormValues("select-card-type", data);

            if (data.creditOrDebitOption.type === "credit") {
              methods.setActiveStep("select-capital-account");
            } else {
              methods.setActiveStep("card-details");
            }
          }}
        />
      )}

      {state.activeStep === "select-capital-account" &&
        state.formData["select-card-type"]?.creditOrDebitOption.type === "credit" && (
          <CreateCardModalSelectCapitalAccountForm
            submittedDataDefaultValues={state.formData["select-capital-account"]}
            activeCapitalAccountWithChargeCard={
              state.formData["select-card-type"].creditOrDebitOption.capitalAccount
            }
            onBack={() => methods.setActiveStep("select-card-type")}
            onValid={(data) => {
              methods.setFormValues("select-capital-account", data);
              methods.setActiveStep("card-details");
            }}
          />
        )}

      {state.activeStep === "card-details" && (
        <CreateCardModalCardDetailsForm
          submittedDataDefaultValues={state.formData["card-details"]}
          defaultCardName={defaultCardName}
          creditOrDebit={selectedCreditOrDebit}
          virtualOrPhysical={selectedVirtualOrPhysical}
          selectedCapitalAccount={state.formData["select-capital-account"]?.capitalAccount}
          onBack={() => {
            if (selectedCreditOrDebit === "credit") {
              methods.setActiveStep("select-capital-account");
            } else {
              methods.setActiveStep("select-card-type");
            }
          }}
          onValid={async (data) => {
            methods.setFormValues("card-details", data);

            if (selectedVirtualOrPhysical === "physical") {
              methods.setActiveStep("shipping-address");
            } else {
              await createCard({
                ...state.formData,
                "card-details": data,
              });
              methods.setActiveStep("card-created-successfully");
            }
          }}
          isSubmitLoading={isSubmitLoading}
          nextButtonText={selectedVirtualOrPhysical === "physical" ? "Next" : "Create card"}
        />
      )}

      {state.activeStep === "shipping-address" && (
        <CreateCardModalShippingAddressForm
          isSubmitLoading={isSubmitLoading}
          onValid={async (data) => {
            methods.setFormValues("shipping-address", data);
            await createCard({
              ...state.formData,
              "shipping-address": data,
            });
            methods.setActiveStep("card-created-successfully");
          }}
          onBack={() => {
            methods.setActiveStep("card-details");
          }}
        />
      )}

      {state.activeStep === "card-created-successfully" && createdCardId && (
        <CreateCardModalCardCreatedSuccessfully cardId={createdCardId} />
      )}
    </>
  );
};

type Props = {
  onClose: () => void;
  defaultValues?: CreateCardModalDefaultValues;
};

const CreateCardModal: FC<Props> = ({ onClose: onCloseProp, ...props }) => {
  const store = useCreateCardModalStore();
  const activeStep = store.state.activeStep;

  const { mutateAsync: finishGetStartedActionItem } = useFinishGetStartedActionItemMutation();

  const hasFinishedCreateVirtualCardActionItem = getStartedActionItemsQueryHooks.useData({
    params: {},
    select: (data) => {
      return data.some((item) => {
        return (
          item.name === "CreateVirtualCard" &&
          (item.state === "Complete" || item.state === "Dismissed")
        );
      });
    },
  });

  // Hack to defer marking the action item as complete until after the modal is closed.
  const onClose = useCallback(() => {
    if (!hasFinishedCreateVirtualCardActionItem && activeStep === "card-created-successfully") {
      finishGetStartedActionItem({ step: "CreateVirtualCard", state: "Complete" });
    }
    onCloseProp();
  }, [onCloseProp, hasFinishedCreateVirtualCardActionItem, activeStep, finishGetStartedActionItem]);

  return (
    <ModalV4
      onClose={onClose}
      // These two views have dropdowns that may get cut off, but if a user has lots of capital accounts, they might get cut off if this is enabled.
      dropdownOverflowHack={activeStep === "card-details" || activeStep === "shipping-address"}
    >
      <CreateCardModalContent {...props} store={store} />
    </ModalV4>
  );
};

export default CreateCardModal;
