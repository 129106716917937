import TasksPage from "pages/tasks/TasksPage";
import { Route, Routes } from "react-router-dom";

const TasksRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<TasksPage />} />
    </Routes>
  );
};

export default TasksRoutes;
