import { useMutation } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useMfa from "modules/mfa/useMfa";
import BusinessAddressRep from "reps/BusinessAddressRep";
import { notify } from "ui/feedback/Toast";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import useBusinessAddressApi from "../api/useBusinessAddressApi";
import useBusinessAddressQueryOptions from "../queries/useBusinessAddressQueryOptions";

const useUpdateBusinessAddressMutation = () => {
  const refreshBusinessAddressQuery = useRefreshQuery(useBusinessAddressQueryOptions().queryKey);
  const businessAddressApi = useBusinessAddressApi();
  const { mfa } = useMfa();
  const businessGuid = useBusinessGuid();
  const { segmentTrack } = useSegment();

  return useMutation({
    mutationFn: async (variables: BusinessAddressRep.Updater) => {
      await mfa();
      return businessAddressApi.update(businessGuid, variables);
    },
    onSuccess: async () => {
      await refreshBusinessAddressQuery();
      notify("success", "Business address updated");
      segmentTrack(SEGMENT_EVENTS.BUSINESS_ADDRESS_UPDATED);
    },
    onError: async () => {
      notify("error", "Something went wrong! Please try again.");
    },
  });
};

export default useUpdateBusinessAddressMutation;
