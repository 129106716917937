import { DEMO_STORE_BALANCES } from "modules/demo-account/constants";
import useIsDemoAccount from "modules/demo-account/queries/useIsDemoAccount";
import { useMemo } from "react";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import { Span } from "ui/typography";
import { useIsMobile } from "utils/device/useMediaQuery";
import { getDollarsFromCents } from "utils/money";
import { getRutterIcon, getRutterPlatformByConnectedStoreType } from "utils/rutter";
import { formatAmount } from "utils/string";

import { ConnectedStoreBalance } from "../queries/useConnectedStoreBalances";

type Props = {
  connectedStoreBalances: ConnectedStoreBalance[];
};

const ConnectedStoresTable: React.FC<Props> = ({ connectedStoreBalances }) => {
  const isMobile = useIsMobile();
  const isDemoAccount = useIsDemoAccount();
  const columns = useMemo(() => createColumns(isMobile, isDemoAccount), [isMobile, isDemoAccount]);

  return (
    <Table
      columns={columns}
      data={connectedStoreBalances}
      rowKey={(it, index) => `${index}-${it.shopName}`}
    />
  );
};

export default ConnectedStoresTable;

function createColumns(isMobile: boolean, isDemoAccount: boolean) {
  const storeColumn: Column<ConnectedStoreBalance> = {
    title: "Store",
    cellRender: (connectedStoreBalance) => (
      <div className="flex gap-x-1.5 text-grey-800">
        <img
          className="size-5"
          src={getRutterIcon(
            getRutterPlatformByConnectedStoreType(connectedStoreBalance.platformName)
          )}
          alt={connectedStoreBalance.platformName}
        />
        <Span className="text-sm font-medium text-grey-800">{connectedStoreBalance.shopName}</Span>
      </div>
    ),
  };

  const balanceColumn: Column<ConnectedStoreBalance> = {
    title: "Balance",
    align: TableColumnAlignment.RIGHT,
    cellRender: (connectedStoreBalance) => {
      const isNegativeStripeBalance =
        connectedStoreBalance.platformName === "Stripe" && connectedStoreBalance.balance < 0;
      const text =
        connectedStoreBalance.hasSynced && !isNegativeStripeBalance
          ? formatAmount(
              getDollarsFromCents(
                isDemoAccount
                  ? connectedStoreBalance.balance === 0
                    ? DEMO_STORE_BALANCES[connectedStoreBalance.platformName]
                    : connectedStoreBalance.balance
                  : connectedStoreBalance.balance
              )
            )
          : "Available soon";
      return <Span className="block text-sm font-medium text-grey-800">{text}</Span>;
    },
  };

  return isMobile ? [storeColumn, balanceColumn] : [storeColumn, balanceColumn];
}
