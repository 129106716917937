import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";

type Params = {
  businessGuid: string | null;
  callbackUrl: string;
  storeName: string;
};

const shopifyConnectionRedirectUrlQueryHooks = makeQueryHooksV2({
  useDefaultParams: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  makeQueryKey: (params: Params) => ["shopifyConnectionRedirectUrl", params],
  useQueryFnParams: () => {
    const highbeamApi = useHighbeamApi();
    return { highbeamApi };
  },
  makeQueryFn: ({ highbeamApi }, { businessGuid, callbackUrl, storeName }) => {
    return () => {
      return highbeamApi.shopifyConnection.createRedirectUrl(businessGuid, callbackUrl, storeName);
    };
  },
});

export { shopifyConnectionRedirectUrlQueryHooks };
