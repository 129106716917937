import { CheckCircle, Sparkle } from "@phosphor-icons/react";
import { FC, ReactNode } from "react";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import { Heading4, Span } from "ui/typography";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

const CleaningTransactions: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return (
    <div className={cn("rounded-lg border border-grey-100 px-5 py-1 @container", className)}>
      {children}
    </div>
  );
};

const Header: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <div className={cn("flex items-center justify-between", className)}>{children}</div>;
};

type HeadingProps = PropsWithChildrenAndClassName & {
  icon?: ReactNode;
};

const Heading: FC<HeadingProps> = ({
  children = "Cleaning transactions",
  className,
  icon = <Sparkle size={16} className="shrink-0" />,
}) => {
  return (
    <Heading4
      className={cn("flex items-center gap-3 py-4 text-sm font-medium text-grey-600", className)}
    >
      {icon}
      {children}
    </Heading4>
  );
};

const Row: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return (
    <div
      className={cn(
        "flex flex-col justify-between border-b border-b-grey-50 py-4 last-of-type:border-b-0 @sm:flex-row @sm:items-center",
        className
      )}
    >
      {children}
    </div>
  );
};

type CompletionProps = {
  completionPercentage: number;
  syncingMessage?: string;
  completedMessage?: string;
};

const Completion: FC<CompletionProps> = ({
  completionPercentage,
  syncingMessage = "Syncing transactions",
  completedMessage = "Transactions cleaned",
}) => {
  return (
    <div className="mt-4 flex items-center gap-x-3 @sm:mt-0">
      {completionPercentage === 0 ? (
        <Span className="text-sm text-grey-600">{syncingMessage}</Span>
      ) : completionPercentage >= 100 ? (
        <Span className="text-sm text-green-600">{completedMessage}</Span>
      ) : (
        <div className="h-1 w-64 rounded-full bg-grey-100">
          <div
            className={cn("h-full rounded-full bg-grey-600", "transition-[width] duration-150")}
            style={{ width: `${completionPercentage}%` }}
          />
        </div>
      )}
      <div className="flex h-5 w-10 items-center">
        {completionPercentage === 0 ? (
          <AnimatedSpinner />
        ) : completionPercentage >= 100 ? (
          <CheckCircle size={16} className="text-green-500" />
        ) : (
          <Span className="text-sm text-grey-600">{completionPercentage}%</Span>
        )}
      </div>
    </div>
  );
};

export default Object.assign(CleaningTransactions, {
  Header: Header,
  Heading: Heading,
  Row: Row,
  Completion: Completion,
});
