import { useMutation } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useMfa from "modules/mfa/useMfa";
import { notify } from "ui/feedback/Toast";

import useCapitalAccountApi from "../api/useCapitalAccountApi";
import capitalAccountSummaryQueryHooks from "../queries/capitalAccountSummaryQueryHooks";

type Variables = {
  repaymentBankAccountGuid: string;
};

const useEditCapitalAccountAutoPayAccountMutation = (capitalAccountGuid: string) => {
  const capitalAccountApi = useCapitalAccountApi();
  const businessGuid = useBusinessGuid();
  const { mfa } = useMfa();

  const refreshCapitalAccountSummary = capitalAccountSummaryQueryHooks.useRefreshQuery({
    capitalAccountGuid,
  });

  return useMutation({
    mutationFn: async ({ repaymentBankAccountGuid }: Variables) => {
      await mfa();
      await capitalAccountApi.updateRepaymentAccount(businessGuid, capitalAccountGuid, {
        bankAccountGuid: repaymentBankAccountGuid,
      });
    },
    onSuccess: async () => {
      await refreshCapitalAccountSummary();
      notify("success", "Auto-pay account changed");
    },
    onError: () => {
      notify("error", "Error saving auto-pay account");
    },
  });
};

export default useEditCapitalAccountAutoPayAccountMutation;
