import useMfa, { MfaCanceledError } from "modules/mfa/useMfa";
import PaymentRep from "reps/payments-v2/PaymentRep";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

type Variables = {
  paymentGuid: string;
};

const useCancelPaymentMutation = (
  additionalOptions: MutationAdditionalOptions<PaymentRep.Complete, Variables> = {}
) => {
  const highbeamApi = useHighbeamApi();
  const { mfa } = useMfa();

  return useMutationWithDefaults(
    {
      mutationFn: async ({ paymentGuid }) => {
        await mfa();
        return highbeamApi.paymentAction.cancel(paymentGuid);
      },
      onError: (err) => {
        if (err instanceof MfaCanceledError) return;
        notify("error", "There was an error canceling the payment. Please try again.");
      },
      onSuccess: () => {
        notify("success", "Payment canceled");
      },
    },
    additionalOptions
  );
};

export default useCancelPaymentMutation;
