import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";
import cn from "utils/tailwind/cn";

const NarrowTable = forwardRef<ElementRef<"table">, ComponentPropsWithoutRef<"table">>(
  ({ className, ...props }, ref) => {
    return <table ref={ref} className={cn("w-full text-sm", className)} {...props} />;
  }
);

const NarrowTableHead = forwardRef<ElementRef<"thead">, ComponentPropsWithoutRef<"thead">>(
  ({ className, ...props }, ref) => {
    return <thead ref={ref} className={cn("[&_tr]:border-b", className)} {...props} />;
  }
);

const NarrowTableBody = forwardRef<ElementRef<"tbody">, ComponentPropsWithoutRef<"tbody">>(
  ({ className, ...props }, ref) => {
    return <tbody ref={ref} className={cn("[&_tr:last-child]:border-0", className)} {...props} />;
  }
);

const NarrowTableFoot = forwardRef<ElementRef<"tfoot">, ComponentPropsWithoutRef<"tfoot">>(
  ({ className, ...props }, ref) => {
    return (
      <tfoot
        ref={ref}
        className={cn("border-t font-medium [&>tr]:last:border-b-0", className)}
        {...props}
      />
    );
  }
);

const NarrowTableRow = forwardRef<ElementRef<"tr">, ComponentPropsWithoutRef<"tr">>(
  ({ className, ...props }, ref) => {
    return (
      <tr
        ref={ref}
        className={cn(
          "border-b border-b-grey-50 transition-colors data-[state=selected]:bg-grey-50",
          className
        )}
        {...props}
      />
    );
  }
);

const NarrowTableHeaderCell = forwardRef<ElementRef<"th">, ComponentPropsWithoutRef<"th">>(
  ({ className, ...props }, ref) => {
    return (
      <th
        ref={ref}
        className={cn("font-normal px-4 py-1.5 text-left text-xs text-grey-600", className)}
        {...props}
      />
    );
  }
);

const NarrowTableCell = forwardRef<ElementRef<"td">, ComponentPropsWithoutRef<"td">>(
  ({ className, ...props }, ref) => {
    return <td ref={ref} className={cn("p-4 text-sm text-grey-800", className)} {...props} />;
  }
);

export default Object.assign(NarrowTable, {
  Head: NarrowTableHead,
  Body: NarrowTableBody,
  Foot: NarrowTableFoot,
  HeadCell: NarrowTableHeaderCell,
  Row: NarrowTableRow,
  Cell: NarrowTableCell,
});
