import questionFlowerImg from "assets/question-flower.svg";
import useCard from "modules/cards/queries/useCard";
import { FC, useState } from "react";
import AccentCard from "ui/data-display/AccentCard";
import Button from "ui/inputs/Button";
import { Heading4, Paragraph, Span } from "ui/typography";

import CardDisputeInstructionModal from "./CardDisputeInstructionModal";

type Props = {
  cardId: string;
  amount: number;
  date: string;
  vendor: string;
};

const CardTransactionFlexpaneDispute: FC<Props> = ({ cardId, amount, date, vendor }) => {
  const card = useCard({ cardId, required: true });
  const [cardDisputeInstructionModalOpen, setCardDisputeInstructionModalOpen] =
    useState<boolean>(false);

  return (
    <>
      <AccentCard className="flex flex-col gap-y-5">
        <img src={questionFlowerImg} alt="?" className="size-10" />

        <div className="flex flex-col gap-y-1">
          <Heading4 className="text-sm text-grey-900">Don’t recognize this purchase?</Heading4>
          <Paragraph className="text-sm text-grey-600">
            <Span>
              If you don’t recognize this transaction or believe it is fraudulent, please follow the
              instructions below to dispute the charge with Visa support.
            </Span>
          </Paragraph>
        </div>

        <Button
          variant="tertiary"
          onClick={() => {
            setCardDisputeInstructionModalOpen(true);
          }}
          className="w-fit"
        >
          View dispute instructions
        </Button>
      </AccentCard>
      {cardDisputeInstructionModalOpen && (
        <CardDisputeInstructionModal
          cardId={card.id}
          amount={amount}
          date={date}
          vendor={vendor}
          onClose={() => {
            setCardDisputeInstructionModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default CardTransactionFlexpaneDispute;
