import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";
import { createURLSearchParamsFromObject } from "utils/search-params/create-url-search-params";

import { BalanceHistoryRep, GetBalanceHistoryQueryParamsRep } from "../reps/BalanceHistoryRep";

type BalanceHistoryApi = {
  get: (
    businessGuid: string,
    queryParams: GetBalanceHistoryQueryParamsRep
  ) => Promise<BalanceHistoryRep>;
};

const builder: ApiBuilder<BalanceHistoryApi> = (api) => ({
  get: async (businessGuid, queryParams) => {
    const qp = createURLSearchParamsFromObject(queryParams);
    const url = `/insights/balance-history/${businessGuid}?${qp}`;
    return (await api.get<BalanceHistoryRep>(url))!;
  },
});

const useBalanceHistoryApi = () => useBackendV2Api(builder);

export default useBalanceHistoryApi;
