import { useIsBusinessActive } from "modules/business/queries/useBusiness";
import AccountDetailsPage from "pages/accounts/AccountDetailsPage";
import AccountsOverviewPage from "pages/accounts/AccountsOverviewPage";
import AccountsStatementsPage from "pages/accounts/AccountsStatementsPage";
import AccountsTransactionsPage from "pages/accounts/AccountsTransactionsPage";
import ManageAccountsPage from "pages/accounts/ManageAccountsPage";
import PaymentSwitcherPage from "pages/accounts/PaymentSwitcherPage";
import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import useIsAllowedToNavigateToAccountsRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToAccountsRoutes";

type Props = {
  indexRoute: string;
};

const AccountsRoutes: FC<Props> = ({ indexRoute }) => {
  const isAllowedToNavigateToAccountsRoutes = useIsAllowedToNavigateToAccountsRoutes();
  const isBusinessActive = useIsBusinessActive();

  if (!isAllowedToNavigateToAccountsRoutes) {
    return <Navigate to={indexRoute} />;
  }

  return (
    <Routes>
      <Route path="/" element={<AccountsOverviewPage />} />
      <Route path="/transactions" element={<AccountsTransactionsPage />} />
      {isBusinessActive && (
        <>
          <Route path="/payment-switcher" element={<PaymentSwitcherPage />} />
          <Route path="/statements" element={<AccountsStatementsPage />} />
          <Route path="/documents" element={<AccountsStatementsPage />} />
          <Route path="/manage-accounts" element={<ManageAccountsPage />} />
        </>
      )}
      <Route path="/high-yield" element={<Navigate replace to={indexRoute} />} />
      <Route path="/:accountGuid" element={<AccountDetailsPage />} />
    </Routes>
  );
};

export default AccountsRoutes;
