import { MessageProfileRep } from "modules/chat/reps/MessageProfileRep";
import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";

type MessageProfileApi = {
  getByMessage: (messageId: string) => Promise<MessageProfileRep>;
};

const builder: ApiBuilder<MessageProfileApi> = (api) => ({
  getByMessage: async (messageId) => {
    const url = `/chat/messages/${messageId}/profile`;
    return (await api.get<MessageProfileRep>(url))!;
  },
});

const useMessageProfileApi = () => useBackendV2Api(builder);

export default useMessageProfileApi;
