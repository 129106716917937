import dayjs from "dayjs";
import { YearMonthChatElementRep } from "modules/chat/reps/ChatElementRep";
import React from "react";

type Props = {
  element: YearMonthChatElementRep;
};

const YearMonthChatElement: React.FC<Props> = ({ element }) => {
  return <span>{dayjs(element.value, "YYYY-MM").tz("America/New_York").format("MMMM YYYY")}</span>;
};

export default YearMonthChatElement;
