import { ArrowSquareIn } from "@phosphor-icons/react";
import Emoji from "components/Emoji";
import isGettingStartedSetupGuideOpenState from "modules/action-items/state/gettingStartedSetupGuide";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { Heading3 } from "ui/typography";

import { GetStartedActionItem } from "../queries/useGetStartedActionItems";

import GetStartedActionItemDrawerRow from "./GetStartedActionItemDrawerRow";

type Props = {
  title: string;
  actionItems: GetStartedActionItem[];
};

const GetStartedActionItemDrawer: React.FC<Props> = ({ title, actionItems }) => {
  const [activeAction, setActiveAction] = useState<number | undefined>();
  const setShowGetStarted = useSetRecoilState(isGettingStartedSetupGuideOpenState);

  useEffect(() => {
    const firstIncompleteAction = actionItems.find((aItem) => aItem.state === "Incomplete");
    setActiveAction(firstIncompleteAction?.order);
  }, [actionItems]);

  const sortedActionItems = [...actionItems].sort((a, b) => Number(a.order) - Number(b.order));

  return (
    <div className="flex w-96 flex-col rounded-lg border border-grey-200 bg-white shadow-md drop-shadow-sm">
      <div className="flex items-center justify-between p-4">
        <Heading3>
          <Emoji className="mr-2" animation="wave">
            👋
          </Emoji>
          {title}
        </Heading3>

        <button
          title="Preview"
          type="button"
          className="size-6 rotate-180"
          onClick={() => setShowGetStarted((prev) => !prev)}
        >
          <ArrowSquareIn className="size-6" weight="light" />
        </button>
      </div>
      {sortedActionItems.map((actionItem, i) => (
        <GetStartedActionItemDrawerRow
          key={actionItem.name}
          number={i + 1}
          active={activeAction === actionItem.order}
          setActive={setActiveAction}
          actionItem={actionItem}
        />
      ))}
    </div>
  );
};

export default GetStartedActionItemDrawer;
