import { ArrowRight, Bank, Book, Invoice, ListBullets, User } from "@phosphor-icons/react";
import closeIcon from "assets/close-icon.svg";
import HighbeamAccountIcon from "modules/bank-accounts/components/HighbeamAccountIcon";
import { useIsBusinessActive } from "modules/business/queries/useBusiness";
import { Sources } from "modules/chat/reps/ExchangeRep";
import { useHasPlaidConnections } from "modules/plaid/queries/plaidConnectionsQueryHooks";
import { FC, ReactElement } from "react";
import colors from "styles/colors";
import { Paragraph } from "ui/typography";
import Text from "ui/typography/Text";

const iconSize = 20;

type SourceRowProps = {
  icon: ReactElement;
  label: string;
  link?: string;
};

const SourceRow: FC<SourceRowProps> = ({ icon, label, link }) => {
  return (
    <div className="group flex items-center justify-start gap-x-4 py-4 last:pb-0">
      {icon}
      {link ? (
        <a href={link} className="flex-1">
          <div className="flex items-center justify-between">
            {label}
            <ArrowRight size={iconSize} weight="light" />
          </div>
        </a>
      ) : (
        label
      )}
    </div>
  );
};

type Props = {
  sources: Sources;
  onClose: () => void;
};

const SourcesCard: FC<Props> = ({ sources, onClose }) => {
  const hasHighbeamBankAccounts = useIsBusinessActive();
  const hasPlaidAccounts = useHasPlaidConnections();
  const showHighbeamBankAccounts = hasHighbeamBankAccounts && sources.financialTransactions;
  const showPlaidAccounts = hasPlaidAccounts && sources.financialTransactions;

  return (
    <div className="w-full rounded-lg border border-grey-200 p-6">
      <div className="flex items-center justify-between border-b border-b-grey-200 pb-4">
        <Text size={14} weight="medium">
          Data sources
        </Text>
        <button className="flex size-5" onClick={onClose} type="button">
          <img src={closeIcon} alt="Close" title="Close" />
        </button>
      </div>
      <Text size={14} color={colors.grey[800]} className="pb-2 pt-4">
        To answer this question we consulted the following data sources:
      </Text>
      <div className="flex flex-col divide-y divide-grey-100">
        {showHighbeamBankAccounts && (
          <SourceRow
            icon={<HighbeamAccountIcon size={iconSize} highbeamTypeName="DepositAccount" />}
            label="Highbeam transactions"
            link="/accounts"
          />
        )}
        {showPlaidAccounts && (
          <SourceRow
            icon={<Bank size={iconSize} weight="light" />}
            label="Connected account transactions"
            link="/settings/banks-and-cards"
          />
        )}
        {sources.bills && (
          <SourceRow icon={<Invoice size={iconSize} weight="light" />} label="Bills" />
        )}
        {sources.ecommerceIndustryInformation && (
          <SourceRow icon={<Book size={iconSize} weight="light" />} label="Industry information" />
        )}
        {sources.highbeamPayees && (
          <SourceRow icon={<User size={iconSize} weight="light" />} label="Payees" />
        )}
        {sources.shopifyOrders && (
          <SourceRow icon={<ListBullets size={iconSize} weight="light" />} label="Shopify orders" />
        )}
      </div>
      <div className="mt-8">
        <Paragraph className="text-xs text-grey-600">
          AI can make mistakes. Check important info.
        </Paragraph>
      </div>
    </div>
  );
};

export default SourcesCard;
