const csvToTsv = (csv: string): string => {
  return csv
    .split("\n")
    .map((line) => {
      // Use a regex to match values inside quotes or plain values
      const values = line.match(/("([^"]*)")|([^,]+)/g) || [];
      return values
        .map((value) => {
          // Remove surrounding quotes if present
          if (value.startsWith('"') && value.endsWith('"')) {
            value = value.slice(1, -1).replace(/""/g, '"'); // Handle escaped quotes
          }
          return value;
        })
        .join("\t");
    })
    .join("\n");
};

export default csvToTsv;
