import dayjs from "dayjs";
import { useCounterpartyReportQueryOptions } from "modules/insights/queries/useCounterpartyReport";

import { CashFlowDurationOption } from "../providers/CashFlowProvider";

const getCounterpartyReportQueryParamsBySelectedDuration = (
  duration: CashFlowDurationOption
): Parameters<typeof useCounterpartyReportQueryOptions>[0] => {
  switch (duration) {
    case "last-3-months":
      return {
        monthRangeStart: dayjs().subtract(3, "months"),
        monthRangeEndInclusive: dayjs(),
      };
    case "last-6-months":
      return {
        monthRangeStart: dayjs().subtract(6, "months"),
        monthRangeEndInclusive: dayjs(),
      };
    case "last-12-months":
      return {
        monthRangeStart: dayjs().subtract(12, "months"),
        monthRangeEndInclusive: dayjs(),
      };
    case "year-to-date":
      return {
        monthRangeStart: dayjs().startOf("year"),
        monthRangeEndInclusive: dayjs(),
      };
  }
};

export default getCounterpartyReportQueryParamsBySelectedDuration;
