import { ShieldCheck } from "@phosphor-icons/react";
import { FC } from "react";
import AccentCard from "ui/data-display/AccentCard";
import IconTile from "ui/icons/IconTile";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

import {
  OnboardingEstimatedTimePill,
  OnboardingFooter,
  OnboardingHeading,
  OnboardingSubheading,
} from "../shared/onboarding-common-components";

import VerifyYourBusinessWhatYoullNeed from "./VerifyYourBusinessWhatYoullNeed";

const VerifyBusinessView: FC<PropsWithChildrenAndClassName> = ({ className, children }) => {
  return <div className={cn("flex flex-col gap-y-4", className)}>{children}</div>;
};

const ShieldCheckIconTile: FC<{ className?: string }> = ({ className }) => {
  return <IconTile icon={<ShieldCheck />} className={cn("bg-purple-50", className)} />;
};

const Heading: FC<{ className?: string }> = ({ className }) => {
  return (
    <OnboardingHeading className={cn("text-lg font-bold", className)}>
      (Optional) – Verify your business &nbsp;&nbsp;
      <OnboardingEstimatedTimePill>5 minutes</OnboardingEstimatedTimePill>
    </OnboardingHeading>
  );
};

const Subheading: FC<{ className?: string }> = ({ className }) => {
  return (
    <OnboardingSubheading className={className}>
      Verify your business to access Highbeam banking and start earning more yield on your cash.
    </OnboardingSubheading>
  );
};

const WhatYoullNeedCard: FC<{ className?: string }> = ({ className }) => {
  return (
    <AccentCard className={cn("mt-4", className)}>
      <VerifyYourBusinessWhatYoullNeed />
    </AccentCard>
  );
};

const Footer: FC<PropsWithChildrenAndClassName> = ({ className, children }) => {
  return <OnboardingFooter className={cn("mt-9", className)}>{children}</OnboardingFooter>;
};

export default Object.assign(VerifyBusinessView, {
  ShieldCheckIconTile: ShieldCheckIconTile,
  Heading: Heading,
  Subheading: Subheading,
  WhatYoullNeedCard: WhatYoullNeedCard,
  Footer: Footer,
});
