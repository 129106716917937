import { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";

type Props = {
  oldPath: string;
  newPath: string;
};

const RemapRedirector: FC<Props> = ({ oldPath, newPath }) => {
  const { pathname, search } = useLocation();
  return <Navigate to={`${pathname.replace(oldPath, newPath)}${search}`} replace />;
};

export default RemapRedirector;
