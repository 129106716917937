import { useIsBusinessActive } from "modules/business/queries/useBusiness";
import ArchivedCardsPage from "pages/cards/ArchivedCardsPage";
import CardDetailsPage from "pages/cards/CardDetailsPage";
import CardsPage from "pages/cards/CardsPage";
import CardTransactionsPage from "pages/cards/CardTransactionsPage";
import { Navigate, Route, Routes } from "react-router-dom";
import useIsAllowedToNavigateToCardTransactionsPage from "utils/permissions/navigation/useIsAllowedToNavigateToCardTransactionsPage";

const CardsRoutes = () => {
  const isAllowedToNavigateToCardTransactionsPage = useIsAllowedToNavigateToCardTransactionsPage();
  const isBusinessActive = useIsBusinessActive();

  return (
    <Routes>
      {isBusinessActive ? (
        <>
          <Route path="/" element={<CardsPage />} />
          <Route path="/archived" element={<ArchivedCardsPage />} />
        </>
      ) : (
        <Route path="/" element={<Navigate to="/cards/transactions" />} />
      )}

      {isAllowedToNavigateToCardTransactionsPage && (
        <Route path="/transactions" element={<CardTransactionsPage />}>
          <Route path=":unitCoDepositAccountId/:transactionId" element={<CardTransactionsPage />} />

          {/* NB(alex): There may be a better way to do this. Basically, for these statuses, we use the card authorization flexpane, otherwise we use the card transaction flexpane... */}
          <Route path="pending/:authorizationId" element={<CardTransactionsPage />} />
          <Route path="canceled/:authorizationId" element={<CardTransactionsPage />} />
          <Route path="declined/:authorizationId" element={<CardTransactionsPage />} />
        </Route>
      )}

      <Route path="/:cardId" element={<CardDetailsPage />}>
        <Route
          path="completed/:unitCoDepositAccountId/:transactionId"
          element={<CardDetailsPage />}
        />

        {/* NB(alex): There may be a better way to do this. Basically, for these statuses, we use the card authorization flexpane, otherwise we use the card transaction flexpane... */}
        <Route path="pending/:authorizationId" element={<CardDetailsPage />} />
        <Route path="canceled/:authorizationId" element={<CardDetailsPage />} />
        <Route path="declined/:authorizationId" element={<CardDetailsPage />} />
      </Route>
    </Routes>
  );
};

export default CardsRoutes;
