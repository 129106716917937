import { ComponentProps, FC } from "react";
import { SetRequired } from "type-fest";
import { Heading3, Heading4 } from "ui/typography";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

const OnboardingHero: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <div className={cn("flex flex-col items-center", className)}>{children}</div>;
};

const HeroImage: FC<SetRequired<ComponentProps<"img">, "src" | "alt">> = ({
  className,
  src,
  alt,
  ...props
}) => {
  return <img src={src} alt={alt} className={cn("w-full max-w-[29.5rem]", className)} {...props} />;
};

const Heading: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <Heading3 className={cn("text-center text-md font-bold", className)}>{children}</Heading3>;
};

const Subheading: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return (
    <Heading4 className={cn("mt-3 text-center text-sm font-regular text-grey-600", className)}>
      {children}
    </Heading4>
  );
};

const Footer: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <div className={cn("mt-6 flex flex-col items-center gap-y-4", className)}>{children}</div>;
};

export default Object.assign(OnboardingHero, {
  HeroImage: HeroImage,
  Heading: Heading,
  Subheading: Subheading,
  Footer: Footer,
});
