import {
  ArrowLineUpRight,
  ArrowsLeftRight,
  Bank,
  Cards,
  Coins,
  FileSearch,
  Gear,
  Link,
} from "@phosphor-icons/react";
import { withErrorBoundary } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import env from "env";
import BankAccountBar from "modules/bank-accounts/components/BankAccountBar";
import useShouldShowEmployeeView from "modules/bills/hooks/useShouldShowEmployeeView";
import { useBusinessQueryOptions, useIsBusinessActive } from "modules/business/queries/useBusiness";
import CapitalAccountBar from "modules/capital-accounts/components/CapitalAccountBar";
import CardAvatarBar from "modules/cards/components/CardAvatarBar";
import { getCardTypeLabelByUnitCoCard } from "modules/cards/utils/get-card-type-label";
import useIsAllowedToNavigateToChatRoutes from "modules/chat/hook/useIsAllowedToNavigateToChatRoutes";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useJwt from "modules/jwt/queries/useJwt";
import useCanSuperuser from "modules/superuser/hooks/useCanSuperuser";
import {
  Dispatch,
  FC,
  SetStateAction,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useIsSuperusering } from "state/auth/isSuperusering";
import Pill from "ui/data-display/Pill";
import ScrollWithInsetShadow from "ui/data-display/ScrollWithInsetShadow";
import UserAvatar from "ui/data-display/UserAvatar";
import { notify } from "ui/feedback/Toast";
import BillPayIcon from "ui/icons/BillPayIcon";
import ChequeIcon from "ui/icons/ChequeIcon";
import PaymentIcon from "ui/icons/PaymentIcon";
import { Span } from "ui/typography";
import { copyToClipboard } from "utils/browser/useCopyToClipboard";
import useDocumentKeyboardEvent from "utils/browser/useDocumentKeyboardEvent";
import useIsAllowedToNavigateToAccountsRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToAccountsRoutes";
import useIsAllowedToNavigateToBillPayRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToBillPayRoutes";
import useIsAllowedToNavigateToBusinessDetailsSettingsPage from "utils/permissions/navigation/useIsAllowedToNavigateToBusinessDetailsSettingsPage";
import useIsAllowedToNavigateToCapitalRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToCapitalRoutes";
import useIsAllowedToNavigateToCardsRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToCardsRoutes";
import useIsAllowedToNavigateToConnectedAccountingSoftwarePage from "utils/permissions/navigation/useIsAllowedToNavigateToConnectedAccountingSoftwarePage";
import useIsAllowedToNavigateToConnectedStoresPage from "utils/permissions/navigation/useIsAllowedToNavigateToConnectedStoresPage";
import useIsAllowedToNavigateToDepositCheckPage from "utils/permissions/navigation/useIsAllowedToNavigateToDepositCheckPage";
import useIsAllowedToNavigateToPaymentsRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToPaymentsRoutes";
import useIsAllowedToNavigateToSendMoney from "utils/permissions/navigation/useIsAllowedToNavigateToSendMoney";
import useIsAllowedToNavigateToTransferMoney from "utils/permissions/navigation/useIsAllowedToNavigateToTransferMoney";
import useIsAllowedToViewBankConnections from "utils/permissions/navigation/useIsAllowedToViewBankConnections";
import getInitials from "utils/string/getInitials";

import {
  useCommandPaletteBankAccounts,
  useCommandPaletteCapitalAccounts,
  useCommandPaletteCards,
  useCommandPalettePayees,
  useCommandPaletteSuperuserBusinesses,
} from "./command-palette-data-sources";
import { useMatchKeywords } from "./command-palette-utils";
import AskAiCommandItem from "./components/AskAiCommandItem";
import Cmdk from "./components/cmdk";
import NavigationCommandItem from "./components/NavigationCommandItem";
import SuperuserCommandItem, { ExitSuperuserCommandItem } from "./components/SuperuserCommandItem";
import { CommandPaletteProvider, useCommandPaletteContext } from "./context";
import Dialog from "./dialog";

const DEV = env.SHOW_DEBUG_MESSAGES;

const CommandPaletteListContent = () => {
  const { search, cleanup, scrollRef, setHighlighted } = useCommandPaletteContext();
  const bankAccounts = useCommandPaletteBankAccounts(search);
  const cards = useCommandPaletteCards(search);
  const payees = useCommandPalettePayees(search);
  const capitalAccounts = useCommandPaletteCapitalAccounts(search);
  const { businesses, isLoading: isLoadingSuperuserBusinesses } =
    useCommandPaletteSuperuserBusinesses(search);

  const canSuperuser = useCanSuperuser();
  const isSuperusering = useIsSuperusering();
  const isBankingActive = useIsBusinessActive(); // Will rename hook to match soon.
  const businessGuid = useBusinessGuid();
  const { data: business } = useQuery(useBusinessQueryOptions());
  const jwt = useJwt();

  const isAllowedToNavigateToSendMoney = useIsAllowedToNavigateToSendMoney();
  const isAllowedToNavigateToTransferMoney = useIsAllowedToNavigateToTransferMoney();
  const isAllowedToNavigateToDepositCheckPage = useIsAllowedToNavigateToDepositCheckPage();
  const isAllowedToNavigateToAccountsRoutes = useIsAllowedToNavigateToAccountsRoutes();
  const isAllowedToNavigateToCardsRoutes = useIsAllowedToNavigateToCardsRoutes();
  const isAllowedToNavigateToPaymentsRoutes = useIsAllowedToNavigateToPaymentsRoutes();
  const isAllowedToNavigateToBillPayRoutes = useIsAllowedToNavigateToBillPayRoutes();
  const isAllowedToNavigateToChatRoutes = useIsAllowedToNavigateToChatRoutes();
  const isAllowedToNavigateToCapitalRoutes = useIsAllowedToNavigateToCapitalRoutes();
  const isAllowedToNavigateToBusinessDetailsSettingsPage =
    useIsAllowedToNavigateToBusinessDetailsSettingsPage();
  const isAllowedToNavigateToConnectedStoresPage = useIsAllowedToNavigateToConnectedStoresPage();
  const isAllowedToViewBankConnections = useIsAllowedToViewBankConnections();
  const isAllowedToNavigateToConnectedAccountingSoftwarePage =
    useIsAllowedToNavigateToConnectedAccountingSoftwarePage();
  const shouldShowBillsEmployeeView = useShouldShowEmployeeView();

  const exitSuperuserMatch = useMatchKeywords(search, ["exit", "superuser"]);
  const copyBusinessGuidMatch = useMatchKeywords(search, ["copy", "business", "guid"]);
  const copyJwtMatch = useMatchKeywords(search, ["copy", "jwt"]);
  const openInSuperblocksMatch = useMatchKeywords(search, ["open", "superblocks"]);
  const openInUnitMatch = useMatchKeywords(search, ["open", "unit"]);
  const sendMoneyMatch = useMatchKeywords(search, ["send", "money"]);
  const transferMoneyMatch = useMatchKeywords(search, ["transfer", "money"]);
  const depositCheckMatch = useMatchKeywords(search, ["deposit", "check"]);
  const accountsTransactionsMatch = useMatchKeywords(search, ["accounts", "transactions"], 0.5);
  const accountsDocumentsMatch = useMatchKeywords(search, ["accounts", "documents"], 0.5);
  const cardsTransactionsMatch = useMatchKeywords(search, ["cards", "transactions"], 0.5);
  const payeesMatch = useMatchKeywords(search, ["payees"]);
  const aiMatch = useMatchKeywords(search, ["ai", "analyst", "chat"], 0.5);
  const capitalDocumentsMatch = useMatchKeywords(search, ["capital", "documents"], 0.5);
  const capitalCompareOffersMatch = useMatchKeywords(
    search,
    ["capital", "compare", "offers", "whats", "the", "apr"],
    0.5
  );
  const settingsBusinessInformationMatch = useMatchKeywords(
    search,
    ["settings", "business", "information"],
    0.5
  );
  const settingsSecurityMatch = useMatchKeywords(search, ["settings", "security"], 0.5);
  const settingsUsersMatch = useMatchKeywords(search, ["settings", "users"], 0.5);
  const settingsStoresMatch = useMatchKeywords(search, ["settings", "stores"], 0.5);
  const settingsBanksAndCardsMatch = useMatchKeywords(search, ["settings", "banks", "cards"], 0.5);
  const settingsAccountingSoftwareMatch = useMatchKeywords(
    search,
    ["settings", "accounting", "software"],
    0.5
  );

  useEffect(() => {
    if (businesses.length > 0) {
      const hasMatch = businesses.some((business) => business.guid === search);
      if (hasMatch) {
        setHighlighted(search);
      }
    }
  }, [businesses, search, setHighlighted]);

  useDocumentKeyboardEvent((e) => {
    if (e.metaKey && e.key === "c") {
      e.preventDefault();
      const selectedItem = scrollRef.current?.querySelector("[data-selected=true]");
      if (selectedItem) {
        if (e.shiftKey) {
          const value = selectedItem.getAttribute("data-shift-copy-value");
          if (value) {
            copyToClipboard(value);
            notify("success", `Copied ${value}`);
          }
        } else {
          const value = selectedItem.getAttribute("data-copy-value");
          if (value) {
            copyToClipboard(value);
            notify("success", `Copied ${value}`);
          }
        }
      }
    }
  }, []);

  return (
    <>
      {isSuperusering && (!search || exitSuperuserMatch) && <ExitSuperuserCommandItem />}

      {(canSuperuser || DEV) && (!search || copyBusinessGuidMatch) && (
        <Cmdk.Item
          onSelect={() => {
            copyToClipboard(businessGuid);
            notify("success", `Copied ${businessGuid}`);
            cleanup();
          }}
        >
          <Span>Copy business guid</Span>
          <Cmdk.CaretRight />
          <strong className="font-medium">{businessGuid}</strong>
          <Link size={16} weight="light" className="ml-auto" />
        </Cmdk.Item>
      )}

      {(canSuperuser || DEV) && (!search || copyJwtMatch) && (
        <Cmdk.Item
          onSelect={() => {
            copyToClipboard(jwt.rawToken);
            notify("success", `Copied JWT`);
            cleanup();
          }}
        >
          <Link size={16} weight="light" className="shrink-0" />
          <Span className="whitespace-nowrap">Copy jwt</Span>
        </Cmdk.Item>
      )}

      {isSuperusering && business && (!search || openInSuperblocksMatch) && (
        <NavigationCommandItem
          to={`${env.SUPERBLOCKS_BUSINESS_LOOKUP_APP_LINK}?businessGuid=${business.guid}`}
        >
          <Span>Open superblocks</Span>
          <Cmdk.CaretRight />
          <strong className="font-medium">{business.name}</strong>
        </NavigationCommandItem>
      )}

      {isSuperusering && business && (!search || openInUnitMatch) && (
        <NavigationCommandItem to={`${env.UNIT_APP_LINK}/customers/${business.unitCoCustomerId}`}>
          <Span>Open Unit</Span>
          <Cmdk.CaretRight />
          <strong className="font-medium">{business.name}</strong>
        </NavigationCommandItem>
      )}

      <Cmdk.Group>
        {/* Move money */}

        {isBankingActive && isAllowedToNavigateToSendMoney && (!search || sendMoneyMatch) && (
          <NavigationCommandItem to="/send-money">
            <ArrowLineUpRight size={20} weight="light" /> Send money
          </NavigationCommandItem>
        )}

        {isBankingActive &&
          isAllowedToNavigateToTransferMoney &&
          (!search || transferMoneyMatch) && (
            <NavigationCommandItem to="/transfer-money">
              <ArrowsLeftRight size={20} weight="light" /> Transfer money
            </NavigationCommandItem>
          )}

        {isBankingActive &&
          isAllowedToNavigateToDepositCheckPage &&
          (!search || depositCheckMatch) && (
            <NavigationCommandItem to="/deposit-check">
              <ChequeIcon size={20} /> Deposit check
            </NavigationCommandItem>
          )}

        {/* Accounts */}

        {isAllowedToNavigateToAccountsRoutes &&
          ("accounts".includes(search) || "home".includes(search)) && (
            <NavigationCommandItem to="/accounts">
              <Bank size={20} weight="light" /> Accounts
            </NavigationCommandItem>
          )}

        {isAllowedToNavigateToAccountsRoutes && search && accountsTransactionsMatch && (
          <NavigationCommandItem to="/accounts/transactions">
            <Span className="text-grey-600">Accounts</Span>
            <Cmdk.CaretRight />
            <Span className="font-medium text-grey-900">Transactions</Span>
          </NavigationCommandItem>
        )}

        {isAllowedToNavigateToAccountsRoutes && search && accountsDocumentsMatch && (
          <NavigationCommandItem to="/accounts/documents">
            <Span className="text-grey-600">Accounts</Span>
            <Cmdk.CaretRight />
            <Span className="font-medium text-grey-900">Documents</Span>
          </NavigationCommandItem>
        )}

        {isAllowedToNavigateToAccountsRoutes &&
          search &&
          bankAccounts.map((bankAccount, index) => {
            return (
              <NavigationCommandItem
                key={bankAccount.guid + index}
                to={`/accounts/${bankAccount.guid}`}
                value={bankAccount.guid}
              >
                <Span className="text-grey-600">Accounts</Span>
                <Cmdk.CaretRight />
                <BankAccountBar bankAccount={bankAccount} />
              </NavigationCommandItem>
            );
          })}

        {/* Cards */}

        {isBankingActive && isAllowedToNavigateToCardsRoutes && "cards".includes(search) && (
          <NavigationCommandItem to="/cards">
            <Cards size={20} weight="light" /> Cards
          </NavigationCommandItem>
        )}

        {isBankingActive &&
          isAllowedToNavigateToCardsRoutes &&
          search &&
          cardsTransactionsMatch && (
            <NavigationCommandItem to="/cards/transactions">
              <Span className="text-grey-600">Cards</Span>
              <Cmdk.CaretRight />
              <Span className="font-medium text-grey-900">Transactions</Span>
            </NavigationCommandItem>
          )}

        {isAllowedToNavigateToCardsRoutes &&
          search &&
          cards.map((card) => {
            return (
              <NavigationCommandItem key={card.id} to={`/cards/${card.id}`}>
                <Span className="text-grey-600">Cards</Span>
                <Cmdk.CaretRight />
                <CardAvatarBar
                  card={card}
                  showStatus="if-not-active"
                  cardAvatarSize="xs"
                  showTypeLabel={false}
                />
                <Pill color="grey-100">
                  {getCardTypeLabelByUnitCoCard({ card, capitalize: true })}
                </Pill>
              </NavigationCommandItem>
            );
          })}

        {/* Payments */}

        {isBankingActive &&
          isAllowedToNavigateToPaymentsRoutes &&
          ("payments".includes(search) || "payees".includes(search)) && (
            <NavigationCommandItem to="/payments">
              <PaymentIcon size={20} /> Payments
            </NavigationCommandItem>
          )}

        {isBankingActive && isAllowedToNavigateToPaymentsRoutes && search && payeesMatch && (
          <NavigationCommandItem to="/payments/payees">
            <Span className="text-grey-600">Payments</Span>
            <Cmdk.CaretRight />
            <Span className="font-medium text-grey-900">Payees</Span>
          </NavigationCommandItem>
        )}

        {isAllowedToNavigateToCardsRoutes &&
          search &&
          payees.map((payee) => {
            return (
              <NavigationCommandItem key={payee.guid} to={`/payments/payees/${payee.guid}`}>
                <Span className="text-grey-600">Payments</Span>
                <Cmdk.CaretRight />
                <Span className="text-grey-600">Payees</Span>
                <Cmdk.CaretRight />
                <UserAvatar initials={getInitials(payee.name)} color="purple-light" size={32} />
                <Span>{payee.name}</Span>
              </NavigationCommandItem>
            );
          })}

        {/* Bill pay */}

        {isBankingActive && isAllowedToNavigateToBillPayRoutes && "bills".includes(search) && (
          <NavigationCommandItem to="/bills">
            <BillPayIcon size={20} /> {shouldShowBillsEmployeeView ? "Bill approvals" : "Bills"}
          </NavigationCommandItem>
        )}

        {/* AI */}

        {isAllowedToNavigateToChatRoutes && (!search || aiMatch) && (
          <NavigationCommandItem to="/ai">
            <FileSearch size={20} weight="light" /> AI Analyst
          </NavigationCommandItem>
        )}

        {/* Capital */}

        {isAllowedToNavigateToCapitalRoutes && "capital".includes(search) && (
          <NavigationCommandItem to="/capital">
            <Coins size={20} weight="light" /> Capital
          </NavigationCommandItem>
        )}

        {isBankingActive &&
          isAllowedToNavigateToCapitalRoutes &&
          search &&
          capitalDocumentsMatch && (
            <NavigationCommandItem to="/capital/documents">
              <Span className="text-grey-600">Capital</Span>
              <Cmdk.CaretRight />
              <Span className="font-medium text-grey-900">Documents</Span>
            </NavigationCommandItem>
          )}

        {isAllowedToNavigateToCapitalRoutes && search && capitalCompareOffersMatch && (
          <NavigationCommandItem to="/whatstheapr">
            <Span className="text-grey-600">Capital</Span>
            <Cmdk.CaretRight />
            <Span className="font-medium text-grey-900">Compare other offers</Span>
          </NavigationCommandItem>
        )}

        {isAllowedToNavigateToCapitalRoutes &&
          search &&
          capitalAccounts.map((capitalAccount) => {
            return (
              <NavigationCommandItem
                key={capitalAccount.guid}
                to={`/capital/${capitalAccount.guid}`}
                value={capitalAccount.guid}
              >
                <Span className="text-grey-600">Capital</Span>
                <Cmdk.CaretRight />
                <CapitalAccountBar capitalAccount={capitalAccount} />
              </NavigationCommandItem>
            );
          })}

        {/* Settings */}

        {businessGuid && "settings".includes(search) && (
          <NavigationCommandItem to="/settings">
            <Gear size={20} weight="light" /> Settings
          </NavigationCommandItem>
        )}

        {isBankingActive &&
          isAllowedToNavigateToBusinessDetailsSettingsPage &&
          search &&
          settingsBusinessInformationMatch && (
            <NavigationCommandItem to="/settings/business">
              <Span className="text-grey-600">Settings</Span>
              <Cmdk.CaretRight />
              <Span className="font-medium text-grey-900">Business information</Span>
            </NavigationCommandItem>
          )}

        {businessGuid && search && settingsSecurityMatch && (
          <NavigationCommandItem to="/settings/security">
            <Span className="text-grey-600">Settings</Span>
            <Cmdk.CaretRight />
            <Span className="font-medium text-grey-900">Security</Span>
          </NavigationCommandItem>
        )}

        {businessGuid && search && settingsUsersMatch && (
          <NavigationCommandItem to="/settings/users">
            <Span className="text-grey-600">Settings</Span>
            <Cmdk.CaretRight />
            <Span className="font-medium text-grey-900">Users</Span>
          </NavigationCommandItem>
        )}

        {isAllowedToNavigateToConnectedStoresPage && search && settingsStoresMatch && (
          <NavigationCommandItem to="/settings/stores">
            <Span className="text-grey-600">Settings</Span>
            <Cmdk.CaretRight />
            <Span className="font-medium text-grey-900">Stores</Span>
          </NavigationCommandItem>
        )}

        {isAllowedToViewBankConnections && search && settingsBanksAndCardsMatch && (
          <NavigationCommandItem to="/settings/banks-and-cards">
            <Span className="text-grey-600">Settings</Span>
            <Cmdk.CaretRight />
            <Span className="font-medium text-grey-900">Banks and cards</Span>
          </NavigationCommandItem>
        )}

        {isAllowedToNavigateToConnectedAccountingSoftwarePage &&
          search &&
          settingsAccountingSoftwareMatch && (
            <NavigationCommandItem to="/settings/accounting-software">
              <Span className="text-grey-600">Settings</Span>
              <Cmdk.CaretRight />
              <Span className="font-medium text-grey-900">Accounting software</Span>
            </NavigationCommandItem>
          )}
      </Cmdk.Group>

      {businesses.length > 0 && (
        <Cmdk.Group>
          {businesses.map((business) => {
            return <SuperuserCommandItem key={business.guid} business={business} />;
          })}
        </Cmdk.Group>
      )}

      {isAllowedToNavigateToChatRoutes && !isLoadingSuperuserBusinesses && <AskAiCommandItem />}
    </>
  );
};

type CommandPaletteMainProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const CommandPaletteMain: FC<CommandPaletteMainProps> = ({ open, setOpen }) => {
  const [search, setSearch] = useState("");
  const [highlighted, setHighlighted] = useState("");

  // Scroll to top of list when search gets updated.
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Timeout is needed if filtered list is short and then the search gets cleared.
    setTimeout(() => {
      if (!scrollRef.current) return;
      scrollRef.current.scrollTo({ top: 0, behavior: "auto" });
    }, 0);
  }, [search]);

  const cleanup = useCallback(() => {
    setOpen(false);
    setSearch("");
    setHighlighted("");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CommandPaletteProvider
      value={{
        open,
        setOpen,
        search,
        setSearch,
        scrollRef,
        cleanup,
        highlighted,
        setHighlighted,
      }}
    >
      <Cmdk loop shouldFilter={false} value={highlighted} onValueChange={setHighlighted}>
        <Cmdk.Input
          placeholder="Search for pages and commands..."
          value={search}
          onValueChange={setSearch}
        />
        <ScrollWithInsetShadow className="max-h-96" ref={scrollRef}>
          <Cmdk.List>
            <CommandPaletteListContent />
          </Cmdk.List>
        </ScrollWithInsetShadow>
      </Cmdk>
    </CommandPaletteProvider>
  );
};

const CommandPalette = () => {
  const [open, setOpen] = useState(false);

  useDocumentKeyboardEvent((event) => {
    if (event.key === "k" && (event.metaKey || event.ctrlKey)) {
      event.preventDefault();
      setOpen((open) => !open);
    }
  }, []);

  return (
    <Suspense fallback={null}>
      <Dialog modal={false} open={open} onOpenChange={setOpen}>
        <Dialog.Content aria-describedby="command-palette-description">
          <Dialog.Title className="sr-only">Command palette</Dialog.Title>
          <Dialog.Description className="sr-only">Command palette</Dialog.Description>

          <CommandPaletteMain open={open} setOpen={setOpen} />
        </Dialog.Content>
      </Dialog>
    </Suspense>
  );
};

export default withErrorBoundary(CommandPalette, { fallback: <></> });
