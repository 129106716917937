import { ListChecks } from "@phosphor-icons/react";
import { FEATURE_FLAGS } from "flags";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import { useState } from "react";
import SwitchWithLabel from "ui/inputs/SwitchWithLabel";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

import CashFlowDurationDropdown from "./components/CashFlowDurationDropdown";
import CashFlowSearchInput from "./components/CashFlowSearchInput";
import CashFlowSyncSheetButton from "./components/CashFlowSyncSheetButton";
import CashFlowSyncSheetModal from "./components/CashFlowSyncSheetModal";
import CashFlowTableView from "./components/CashFlowTableView";
import CashFlowTabs from "./components/CashFlowTabs";
import CashFlowProvider, { useCashFlowContext } from "./providers/CashFlowProvider";

const CashFlowDurationSection = () => {
  return (
    <DashboardPage.Section>
      <CashFlowDurationDropdown />
    </DashboardPage.Section>
  );
};

const CashFlowPageContent = () => {
  const [isSyncSheetModalOpen, setIsSyncSheetModalOpen] = useState(false);
  const { showUncategorizedOnly, setShowUncategorizedOnly } = useCashFlowContext();
  const syncSheetEnabled = useFeatureFlag(FEATURE_FLAGS["vendor-rules-sync-google-sheet"]);

  return (
    <>
      {isSyncSheetModalOpen && (
        <CashFlowSyncSheetModal onClose={() => setIsSyncSheetModalOpen(false)} />
      )}

      <DashboardPage.Header
        actions={
          syncSheetEnabled && (
            <CashFlowSyncSheetButton onClick={() => setIsSyncSheetModalOpen(true)} />
          )
        }
      >
        <DashboardPage.Header.IconTile icon={<ListChecks />} />
        <DashboardPage.Header.Title>Vendor rules</DashboardPage.Header.Title>
      </DashboardPage.Header>

      <CashFlowDurationSection />

      <CashFlowTabs />

      <DashboardPage.Section>
        <div className="flex items-center gap-x-4">
          <CashFlowSearchInput className="flex-1" />
          <div>
            <SwitchWithLabel
              label="Uncategorized only"
              value={showUncategorizedOnly}
              onChange={setShowUncategorizedOnly}
            />
          </div>
        </div>
      </DashboardPage.Section>

      <DashboardPage.Section>
        <CashFlowTableView />
      </DashboardPage.Section>
    </>
  );
};

const CashFlowPage = () => {
  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>Vendor rules</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <CashFlowProvider>
          <CashFlowPageContent />
        </CashFlowProvider>
      </DashboardPage>
    </>
  );
};

export default CashFlowPage;
