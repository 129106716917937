import { InsightsSheetRep } from "../reps/InsightsSheetRep";
import InsightsSheetSyncTaskRep from "../reps/InsightsSheetSyncTaskRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class InsightsSheetApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async sync(businessGuid: string): Promise<void> {
    const url = `/insights/tasks/insights-sheet-sync/create`;
    const body: InsightsSheetSyncTaskRep.Complete = {
      businessGuids: { type: "Explicit", guids: [businessGuid] },
      sheetIds: { type: "All" },
    };
    return (await this.api.post(url, body))!;
  }

  async getPrimarySheet(businessGuid: string): Promise<InsightsSheetRep> {
    const url = `/insights/insights-sheets/primary?businessGuid=${businessGuid}`;
    return (await this.api.get(url))!;
  }
}
