import { TransactionListParams } from "@highbeam/unit-node-sdk";
import { useSuspenseQuery } from "@tanstack/react-query";
import useBusinessUnitCoCustomerId from "modules/business/queries/useBusinessUnitCoCustomerId";
import { useUnitApi } from "modules/unit-co-customer-token/queries/useUnitApi";
import { Simplify } from "type-fest";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const UNIT_CO_TRANSACTIONS_QUERY_KEY = "unit-co-transactions";

type Params = Simplify<
  TransactionListParams & {
    customerId?: never; // Always use the customerId from the business state
  }
>;

const useUnitCoTransactionsQueryOptions = (params: Params) => {
  const unitApi = useUnitApi();
  const customerId = useBusinessUnitCoCustomerId();

  return useQueryOptions({
    queryKey: [UNIT_CO_TRANSACTIONS_QUERY_KEY, customerId, params],
    queryFn: async () => {
      // NB(alex): Hack to prevent the page from crashing. We should re-visit a cleaner approach / please do not copy this.
      if (!unitApi || !customerId) {
        return {
          transactions: [],
          pagination: {
            total: 0,
            pagination: {
              limit: 0,
              offset: 0,
            },
          },
        };
      }

      const response = await unitApi.transactions.list({
        sort: "-createdAt",
        ...params,
        customerId: customerId,
      });

      return {
        transactions: response.data,
        pagination: response.meta.pagination,
      };
    },
  });
};

const useHasAtLeastOneUnitTransaction = () => {
  const { data } = useSuspenseQuery({
    ...useUnitCoTransactionsQueryOptions({
      limit: 1,
    }),
    select: (data) => data.transactions.length > 0,
  });
  return data;
};

export { useHasAtLeastOneUnitTransaction };

export default useUnitCoTransactionsQueryOptions;
