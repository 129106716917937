import { KnowledgeBaseItemRep } from "modules/chat/reps/KnowledgeBaseItemRep";
import React from "react";

import ChatKnowledgeBaseCreate from "./ChatKnowledgeBaseCreate";
import ExistingChatKnowledgeBaseItem from "./ExistingChatKnowledgeBaseItem";

type Props = {
  businessGuid: string;
  items: KnowledgeBaseItemRep[];
};

const ChatKnowledgeBaseTable: React.FC<Props> = ({ businessGuid, items }) => {
  return (
    <div className="flex flex-col gap-2">
      <ChatKnowledgeBaseCreate businessGuid={businessGuid} />
      {items.map((item) => {
        return <ExistingChatKnowledgeBaseItem key={item.id} item={item} />;
      })}
    </div>
  );
};

export default ChatKnowledgeBaseTable;
