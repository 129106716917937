import HighbeamAccountIcon from "modules/bank-accounts/components/HighbeamAccountIcon";
import getBankAccountDisplayName from "modules/bank-accounts/utils/getBankAccountDisplayName";
import PlaidInstitutionLogo from "modules/plaid/components/PlaidInstitutionLogo";
import BankAccountRep from "reps/BankAccountRep";
import PlaidAccountRep from "reps/PlaidAccountRep";
import colors from "styles/colors";
import Text from "ui/typography/Text";
import { getPlaidBankAccountDisplayName } from "utils/account";

const classes = {
  container: "flex items-center gap-x-1.5 mt-0.5 text-grey-900",
  icon: "size-[1.125rem]",
};

type Props = {
  bankAccount?: BankAccountRep.Complete;
  plaidAccount?: PlaidAccountRep.Complete;
};

/**
 * DEPRECATED: Use `<AccountBar>` instead
 */
const AccountLabel: React.FC<Props> = ({ bankAccount, plaidAccount }) => {
  if (plaidAccount) {
    return (
      <div className={classes.container}>
        <PlaidInstitutionLogo institutionId={plaidAccount.institutionId} />
        <Text size={14} weight="medium">
          {getPlaidBankAccountDisplayName(plaidAccount)}
        </Text>
      </div>
    );
  }
  if (bankAccount) {
    return (
      <div className={classes.container}>
        <HighbeamAccountIcon highbeamTypeName={bankAccount.highbeamType.name} />
        <Text
          size={14}
          weight="medium"
          color={
            bankAccount.status !== BankAccountRep.Status.OPEN ? colors.grey[600] : colors.grey[900]
          }
        >
          {getBankAccountDisplayName(bankAccount)}
        </Text>
      </div>
    );
  }
  return null;
};

export default AccountLabel;
