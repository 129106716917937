import isHighCreditUtilization from "modules/line-of-credit/utils/isHighCreditUtilization";
import { ComponentProps, FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Text, { TextProps } from "ui/typography/Text";

const Label = (props: TextProps) => <Text size={14} weight="regular" {...props} />;

const Value = ({ cents, ...moneyAmountProps }: ComponentProps<typeof MoneyAmount>) => (
  <MoneyAmount
    direction={cents < 0 ? "negative" : "none"}
    cents={cents}
    weight="medium"
    {...moneyAmountProps}
  />
);

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAvailableTooltipContent: FC<Props> = ({ capitalAccountSummary }) => {
  const { runningBalance, cardBalance, cardPending, state } = capitalAccountSummary;
  const hasHighbeamCard = state === CapitalAccountRep.State.Active;
  const isCapitalAccountTerminated = state === CapitalAccountRep.State.Terminated;
  const capitalLimit = capitalAccountSummary.details.limit;
  const lineOfCreditUsed = 0 - runningBalance;
  const highbeamCardBalance = 0 - (cardBalance + cardPending);

  const lineOfCreditUsedAsNegative = 0 - lineOfCreditUsed;
  const highbeamCardBalanceAsNegative = 0 - highbeamCardBalance;
  const capitalAvailable =
    capitalLimit + lineOfCreditUsedAsNegative + highbeamCardBalanceAsNegative;

  const rowClasses = "flex items-center justify-between gap-x-14";

  return (
    <div className="flex flex-col gap-y-4">
      <div className={rowClasses}>
        <Label color={colors.grey[900]}>
          {hasHighbeamCard ? "Highbeam card limit" : "Capital limit"}
        </Label>
        <Value cents={capitalLimit} />
      </div>
      <div className={rowClasses}>
        <Label color={colors.grey[600]}>
          {hasHighbeamCard ? "Last statement balance" : "Outstanding"}
        </Label>
        <Value cents={lineOfCreditUsedAsNegative} />
      </div>
      {hasHighbeamCard && (
        <div className={rowClasses}>
          <Label color={colors.grey[600]}>Highbeam Card balance</Label>
          <Value cents={highbeamCardBalanceAsNegative} />
        </div>
      )}

      <Divider className="my-0" />

      <div className={rowClasses}>
        <Label color={colors.grey[900]}>
          Capital {isCapitalAccountTerminated ? "repaid" : "available"}
        </Label>
        <Value
          cents={capitalAvailable}
          color={
            isHighCreditUtilization(capitalAccountSummary)
              ? colors.yellow[700]
              : isCapitalAccountTerminated
                ? colors.grey[900]
                : colors.green[500]
          }
        />
      </div>
    </div>
  );
};

export default CapitalAvailableTooltipContent;
