import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";
import BillStatsRep from "reps/BillStatsRep";

type BillStatsApi = {
  getByBusiness: (businessGuid: string) => Promise<BillStatsRep>;
};

const builder: ApiBuilder<BillStatsApi> = (api) => ({
  getByBusiness: async (businessGuid) => {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
    });
    const url = `/accounts-payable/bill-stats?${qp}`;
    return (await api.get<BillStatsRep>(url))!;
  },
});

const useBillStatsApi = () => useBackendV2Api(builder);

export default useBillStatsApi;
