import { ComponentProps, FC } from "react";
import CardRep from "reps/CardRep";
import Shimmer from "ui/feedback/ShimmerV2";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

import physicalCreditIcon from "./assets/physical-credit.svg";
import physicalDebitIcon from "./assets/physical-debit.svg";
import virtualCreditIcon from "./assets/virtual-credit.svg";
import virtualDebitIcon from "./assets/virtual-debit.svg";

export type CardAvatarSize = "md" | "sm" | "xs";

const DEFAULT_CARD_AVATAR_SIZE = "md";

const getSizeClasses = (size: CardAvatarSize) => {
  return variants(size, {
    md: "h-8 w-12",
    sm: "h-6 w-8",
    xs: "h-3 w-6",
  });
};

type CardAvatarLoadingProps = {
  size?: CardAvatarSize;
  className?: string;
};

const CardAvatarLoading: FC<CardAvatarLoadingProps> = ({
  size = DEFAULT_CARD_AVATAR_SIZE,
  className,
}) => {
  return <Shimmer className={cn("rounded", getSizeClasses(size), className)} />;
};

type Props = Omit<ComponentProps<"img">, "src" | "alt"> & {
  cardType: CardRep.CardType;
  size?: CardAvatarSize;
  isLoading?: boolean;
};

const CardAvatar: FC<Props> = ({
  cardType,
  size = DEFAULT_CARD_AVATAR_SIZE,
  className,
  isLoading,
  ...imgProps
}) => {
  if (isLoading) {
    return <CardAvatarLoading size={size} className={className} />;
  }

  return (
    <img
      src={variants(cardType, {
        VIRTUAL: virtualDebitIcon,
        VIRTUAL_CREDIT: virtualCreditIcon,
        PHYSICAL: physicalDebitIcon,
        PHYSICAL_CREDIT: physicalCreditIcon,
      })}
      alt={cardType}
      {...imgProps}
      className={cn(getSizeClasses(size), className)}
    />
  );
};

export default Object.assign(CardAvatar, {
  Loading: CardAvatarLoading,
});
