import { NumberedListChatElementRep } from "modules/chat/reps/ChatElementRep";
import React from "react";

import ParagraphChatElement from "./ParagraphChatElement";

type Props = {
  element: NumberedListChatElementRep;
};

const NumberedListChatElement: React.FC<Props> = ({ element }) => {
  return (
    <ol className="list-decimal pl-7">
      {element.items.map((item, i) => (
        <li key={i}>
          <ParagraphChatElement element={item} />
        </li>
      ))}
    </ol>
  );
};

export default NumberedListChatElement;
