import { useQueryClient } from "@tanstack/react-query";
import useExchangeApi from "modules/chat/api/useExchangeApi";
import { chatChannelExchangesQueryHooks } from "modules/chat/queries/useChatChannelExchanges";
import { useRefreshChatThreadExchanges } from "modules/chat/queries/useChatThreadExchanges";
import ExchangeRep, { ExchangeUpdateRep } from "modules/chat/reps/ExchangeRep";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

const useUpdateChatChannelExchangeUserFeedbackMutation = (
  channelId: string,
  additionalOptions?: MutationAdditionalOptions<
    ExchangeRep,
    { exchangeId: string; update: ExchangeUpdateRep }
  >
) => {
  const exchangeApi = useExchangeApi();
  const queryClient = useQueryClient();
  const refreshChatThreadExchanges = useRefreshChatThreadExchanges();

  return useMutationWithDefaults(
    {
      mutationFn: ({ exchangeId, update }) => {
        return exchangeApi.update(exchangeId, update);
      },
      onSuccess: async () => {
        await Promise.all([
          // NB(alex): Should be able to figure out a simpler way to do this in `makeQueryHooksV2`.
          queryClient.invalidateQueries({
            queryKey: chatChannelExchangesQueryHooks.makeQueryKey({
              channelId: channelId,
              latestExclusive: null,
            }),
          }),
          refreshChatThreadExchanges(),
        ]);
      },
    },
    additionalOptions ?? {}
  );
};

export default useUpdateChatChannelExchangeUserFeedbackMutation;
