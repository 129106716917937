import classNames from "classnames";
import { FC, ReactNode, Suspense } from "react";
import DotsPageLoader from "ui/feedback/DotsLoader/DotsPageLoader";

import styles from "./MultiStep.module.scss";
import MultiStepControls from "./MultiStepControls";
import MultiStepForm from "./MultiStepForm";
import MultiStepSection from "./MultiStepSection";

type Props = {
  stepsContainerClassName?: string;
  formContainerClassName?: string;
  steps: ReactNode;
  children: ReactNode;
  suspenseFallback?: ReactNode;
};

const MultiStep: FC<Props> = ({
  stepsContainerClassName,
  formContainerClassName,
  steps,
  children,
  suspenseFallback = <DotsPageLoader variant="section" />,
}) => (
  <div className={styles.container}>
    <div className={classNames(styles.stepsContainer, stepsContainerClassName)}>{steps}</div>

    <Suspense fallback={suspenseFallback}>
      <div className={classNames(styles.formContainer, formContainerClassName)}>{children}</div>
    </Suspense>
  </div>
);

export default Object.assign(MultiStep, {
  Form: MultiStepForm,
  Section: MultiStepSection,
  Controls: MultiStepControls,
});
