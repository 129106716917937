import useIsAllowedToNavigateToChatAdminRoutes from "modules/chat/hook/useIsAllowedToNavigateToChatAdminRoutes";
import ChatAdminPage from "pages/accounts/ChatAdminPage";
import ChatKnowledgeBasePage from "pages/accounts/ChatKnowledgeBasePage";
import AiChatChannelPage from "pages/ai-chat/AiChatChannelPage";
import AiChatChannelsPage from "pages/ai-chat/AiChatChannelsPage";
import SuperuserBenchmarkingChartPage from "pages/ai-chat/SuperuserBenchmarkingChartPage";
import CashFlowPage from "pages/CashFlowPage";
import { Route, Routes } from "react-router-dom";

const AiRoutes = () => {
  const isAllowedToNavigateToChatAdminRoutes = useIsAllowedToNavigateToChatAdminRoutes();

  return (
    <Routes>
      <Route path="/" element={<AiChatChannelsPage />} />
      <Route path="/vendor-rules" element={<CashFlowPage />} />
      <Route path="/:channelId" element={<AiChatChannelPage />} />

      {isAllowedToNavigateToChatAdminRoutes && (
        <>
          <Route path="/admin" element={<ChatAdminPage />} />
          <Route path="/knowledge-base" element={<ChatKnowledgeBasePage />} />
          <Route path="/benchmark-chart" element={<SuperuserBenchmarkingChartPage />} />
        </>
      )}
    </Routes>
  );
};

export default AiRoutes;
