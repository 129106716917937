import { FC } from "react";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

export type CircleProgressIndicatorCircleColor = "grey-200";
export type CircleProgressIndicatorProgressColor = "grey-400" | "grey-500" | "green-500";

type Props = {
  percent: number;
  circleColor?: CircleProgressIndicatorCircleColor;
  circleWidth?: number;
  progressColor?: CircleProgressIndicatorProgressColor;
  progressWidth?: number;
  size?: number;
};

const CircleProgressIndicator: FC<Props> = ({
  percent,
  circleColor = "grey-200",
  circleWidth = 2,
  progressColor = "grey-500",
  progressWidth = circleWidth,
  size = 16,
}) => {
  const radius = (size - Math.max(circleWidth, progressWidth)) / 2;
  const circumference = Math.PI * radius * 2;
  const strokeDashoffset = Math.round(circumference * ((100 - percent) / 100));

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      style={{ transform: "rotate(-90deg)" }}
    >
      <circle
        r={radius}
        cx={size / 2}
        cy={size / 2}
        strokeWidth={circleWidth}
        className={cn(
          "fill-transparent",
          variants(circleColor, {
            "grey-200": "stroke-grey-200",
          })
        )}
      />
      <circle
        r={radius}
        cx={size / 2}
        cy={size / 2}
        className={cn(
          "fill-transparent",
          variants(progressColor, {
            "grey-400": "stroke-grey-400",
            "grey-500": "stroke-grey-500",
            "green-500": "stroke-green-500",
          })
        )}
        strokeWidth={progressWidth}
        strokeLinecap="butt"
        strokeDashoffset={strokeDashoffset}
        fill="transparent"
        strokeDasharray={circumference}
      />
    </svg>
  );
};

export default CircleProgressIndicator;
