import { useQueryClient } from "@tanstack/react-query";
import useTaskApi from "modules/chat/api/useTaskApi";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { AiTaskRep, AiTaskUpdateRep } from "reps/ai-tasks/AiTaskRep";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

const useUpdateAiTaskMutation = (
  taskId: string,
  additionalOptions: MutationAdditionalOptions<AiTaskRep, AiTaskUpdateRep>
) => {
  const queryClient = useQueryClient();
  const taskApi = useTaskApi();
  const businessGuid = useBusinessGuid();

  return useMutationWithDefaults(
    {
      mutationFn: async (update) => {
        return await taskApi.update(taskId, update);
      },
      onSuccess: async (aiTask) => {
        await queryClient.setQueryData(["ai-tasks", { businessGuid }], aiTask);
      },
    },
    additionalOptions
  );
};

export default useUpdateAiTaskMutation;
