import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";

import useEnrichedTransactionApi, {
  SearchEnrichedTransactionQueryParams,
} from "../api/useEnrichedTransactionApi";

const DEFAULT_ENRICHED_TRANSACTIONS_PAGE_SIZE = 50;

const enrichedTransactionsQueryHooks = makeQueryHooksV2({
  useQueryFnParams: () => {
    const enrichedTransactionsApi = useEnrichedTransactionApi();
    return { enrichedTransactionsApi };
  },
  useDefaultParams: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid, pageSize: DEFAULT_ENRICHED_TRANSACTIONS_PAGE_SIZE };
  },
  makeQueryKey: (params: SearchEnrichedTransactionQueryParams) => {
    return ["enrichedTransactions", params] as const;
  },
  makeQueryFn: ({ enrichedTransactionsApi }, params) => {
    return () => enrichedTransactionsApi.search(params);
  },
});

export { DEFAULT_ENRICHED_TRANSACTIONS_PAGE_SIZE, enrichedTransactionsQueryHooks };
