import lightning from "assets/lightning.svg";
import { useFinishGetStartedActionItemMutation } from "modules/action-items/mutations/useFinishGetStartedActionItemMutation";
import { usePrimaryBankAccountOrThrow } from "modules/bank-accounts/queries/useBankAccount";
import { ConnectedStoreType } from "modules/connected-stores/queries/useConnectedStores";
import { useRecoilValue } from "recoil";
import Tabs, { Tab } from "ui/navigation/Tabs";
import ModalV3 from "ui/overlay/ModalV3";

import styles from "./PayoutInstructionModal.module.scss";
import PayoutInstructions from "./PayoutInstructions";
import payoutInstructionsModalState, {
  usePayoutInstructionsModal,
} from "./state/payoutInstructionsModalState";

const tabs: Tab<ConnectedStoreType>[] = [
  { id: "Shopify", label: "Shopify" },
  { id: "Amazon", label: "Amazon" },
  { id: "PayPal", label: "PayPal" },
  { id: "Stripe", label: "Stripe" },
];

type Props = {
  activeTab: ConnectedStoreType;
};

const PayoutInstructionModal: React.FC<Props> = ({ activeTab }) => {
  const { open: openPayoutInstructionsModal, close: onClose } = usePayoutInstructionsModal();

  const primaryAccount = usePrimaryBankAccountOrThrow();
  const { mutateAsync: finishGetStartedActionItem } = useFinishGetStartedActionItemMutation();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        finishGetStartedActionItem({ step: "ReceiveStorePayouts", state: "Complete" });
        onClose();
      }}
    >
      <ModalV3.Header onClose={onClose} icon={<img alt="Highbeam account" src={lightning} />}>
        How to set up your payouts to Highbeam
      </ModalV3.Header>

      <ModalV3.Body>
        <Tabs
          variant="rounded"
          noBorder
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={(tab) => openPayoutInstructionsModal({ activeTab: tab })}
        />
        <div className={styles.container}>
          <PayoutInstructions accountToDisplay={primaryAccount} activeTab={activeTab} />
        </div>
      </ModalV3.Body>

      <ModalV3.Footer onClose={onClose}>
        <ModalV3.Footer.SubmitButton>Got it!</ModalV3.Footer.SubmitButton>
      </ModalV3.Footer>
    </form>
  );
};

const PayoutInstructionModalWrapper = () => {
  const { isOpen, close: onClose } = usePayoutInstructionsModal();

  // NB(alex): not super happy with this pattern, will likely iterate towards something better
  const state = useRecoilValue(payoutInstructionsModalState);

  return (
    <ModalV3 isOpen={isOpen} onClose={onClose}>
      {state.isOpen && <PayoutInstructionModal activeTab={state.activeTab} />}
    </ModalV3>
  );
};

export default PayoutInstructionModalWrapper;
