import { LinkBreak, ArrowRight } from "@phosphor-icons/react";
import AccountLabel from "components/Accounts/AccountLabel";
import { FC, useMemo } from "react";
import PlaidAccountRep from "reps/PlaidAccountRep";
import colors from "styles/colors";
import MoneyAmountFraction from "ui/data-display/money/MoneyAmountFraction";
import Shimmer from "ui/feedback/ShimmerV2";
import AmountCell from "ui/table/cells/AmountCell";
import NotFoundCell from "ui/table/cells/NotFoundCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import { Span } from "ui/typography";
import { getDollarsFromCents } from "utils/money";
import { formatAmount } from "utils/string";
import cn from "utils/tailwind/cn";

import { usePlaidBalancesRefreshing } from "../mutations/useRefreshPlaidBalancesMutation";

const accountColumn: Column<PlaidAccountRep.Complete> = {
  title: "Account",
  cellRender: (account) => <AccountLabel plaidAccount={account} />,
};

const getCardBalanceColumn = (
  plaidBalancesRefreshing: boolean
): Column<PlaidAccountRep.Complete> => {
  return {
    title: "Balance",
    align: TableColumnAlignment.RIGHT,
    cellRender: (account) => (
      <div className="flex items-center justify-end gap-x-1">
        {plaidBalancesRefreshing ? (
          <Shimmer className="h-4 w-24" />
        ) : (
          <>
            {account.currentBalance === null ? (
              <NotFoundCell />
            ) : (
              <AmountCell className="font-medium text-grey-800" cents={account.currentBalance} />
            )}
          </>
        )}
      </div>
    ),
  };
};

const getCardAvailableColumn = (
  plaidBalancesRefreshing: boolean
): Column<PlaidAccountRep.Complete> => {
  return {
    title: "Available",
    align: TableColumnAlignment.RIGHT,
    cellRender: (account) => {
      return (
        <div className="flex items-center justify-end gap-x-1">
          {plaidBalancesRefreshing ? (
            <Shimmer className="h-4 w-24" />
          ) : account.availableBalance !== null && account.creditLimit !== null ? (
            <MoneyAmountFraction
              numeratorColor={colors.grey[800]}
              numeratorInCents={account.availableBalance}
              denominatorInCents={account.creditLimit}
            />
          ) : (
            <Span className="block text-sm font-medium text-grey-800">
              {account.availableBalance !== null
                ? formatAmount(getDollarsFromCents(account.availableBalance))
                : "-"}
            </Span>
          )}
        </div>
      );
    },
  };
};

const getBankAvailableColumn = (
  plaidBalancesRefreshing: boolean
): Column<PlaidAccountRep.Complete> => {
  return {
    title: "Available",
    align: TableColumnAlignment.RIGHT,
    cellRender: (account) => {
      return (
        <div className="flex items-center justify-end gap-x-1">
          {plaidBalancesRefreshing ? (
            <Shimmer className="h-4 w-24" />
          ) : (
            <Span className="block text-sm font-medium text-grey-800">
              {account.availableBalance !== null
                ? formatAmount(getDollarsFromCents(account.availableBalance))
                : "-"}
            </Span>
          )}
        </div>
      );
    },
  };
};

const connectionStatusColumn: Column<PlaidAccountRep.Complete> = {
  title: "",
  align: TableColumnAlignment.RIGHT,
  width: 160,
  cellRender: (account) => {
    const isConnected = account.isActive && account.isConnectionActive;
    return (
      <div
        className={cn(
          "flex items-center justify-end gap-x-1.5 text-sm",
          !isConnected && "text-yellow-600"
        )}
      >
        {isConnected ? (
          <ArrowRight size={16} />
        ) : (
          <>
            <LinkBreak size={16} />
            Disconnected
            <ArrowRight size={16} />
          </>
        )}
      </div>
    );
  },
};

type Props = {
  plaidAccounts: PlaidAccountRep.Complete[];
  type: "bank" | "card";
  onRowClick: (account: PlaidAccountRep.Complete) => void;
};

const PlaidAccountsTable: FC<Props> = ({ plaidAccounts, type, onRowClick }) => {
  const plaidBalancesRefreshing = usePlaidBalancesRefreshing();

  const columns = useMemo(() => {
    return type === "bank"
      ? [accountColumn, getBankAvailableColumn(plaidBalancesRefreshing), connectionStatusColumn]
      : [
          accountColumn,
          getCardBalanceColumn(plaidBalancesRefreshing),
          getCardAvailableColumn(plaidBalancesRefreshing),
          connectionStatusColumn,
        ];
  }, [type, plaidBalancesRefreshing]);

  return (
    <Table
      rowKey={(account) => account.plaidAccountId}
      columns={columns}
      data={plaidAccounts}
      onRowClick={onRowClick}
    />
  );
};

export default PlaidAccountsTable;
