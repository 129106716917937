import { ReceivedPaymentListParams } from "@highbeam/unit-node-sdk";
import { useUnitApiOrThrow } from "modules/unit-co-customer-token/queries/useUnitApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const INCOMING_PAYMENT_PARAMS: ReceivedPaymentListParams = {
  limit: 50,
  offset: 0,
  status: ["Pending", "Advanced", "Completed", "Returned", "PendingReview", "MarkedForReturn"], // TODO: Missing: MarkedForReturn
};

export const UNIT_RECEIVED_PAYMENTS_QUERY_KEY = "unitReceivedPayments";

const useUnitCoReceivedPaymentsQueryOptions = (params?: ReceivedPaymentListParams) => {
  const unitApi = useUnitApiOrThrow();

  return useQueryOptions({
    queryKey: [UNIT_RECEIVED_PAYMENTS_QUERY_KEY, params],
    queryFn: async () => {
      const { data } = await unitApi.receivedPayments.list(params);
      return data;
    },
  });
};

export default useUnitCoReceivedPaymentsQueryOptions;
