import { CurrencyDollar, LinkBreak } from "@phosphor-icons/react";
import TotalYieldInfoTooltipContent from "components/Accounts/InterestTier/TotalYieldEarnedTooltipContent";
import dayjs from "dayjs";
import HighbeamAccountIcon from "modules/bank-accounts/components/HighbeamAccountIcon";
import { useBankAccounts } from "modules/bank-accounts/queries/useBankAccounts";
import { useIsBusinessActive } from "modules/business/queries/useBusiness";
import BalanceHistoryTotalBalanceListbox from "modules/insights/components/BalanceHistoryTotalBalanceListbox";
import NormalizedAccountAugmentedBar from "modules/insights/components/NormalizedAccountRepBar/NormalizedAccountAugmentedBar";
import { balanceHistoryQueryHooks } from "modules/insights/queries/useBalanceHistory";
import { BalanceHistoryAugmented } from "modules/insights/utils/augment-balance-history";
import { checkIsHighbeamNormalizedAccountRep } from "modules/insights/utils/normalized-account-type-guards";
import { getUniqueBalanceHistoryInstitution } from "modules/insights/utils/unique-institution-utils";
import PlaidInstitutionLogo from "modules/plaid/components/PlaidInstitutionLogo";
import { useHasPlaidConnections } from "modules/plaid/queries/plaidConnectionsQueryHooks";
import { FC, useEffect, useMemo, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
import CashDisplay from "ui/data-display/money/CashDisplay";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import { aggregateBalanceLineChartAccountSeriesTotals } from "ui/data-visualization/chart/aggregate-balance-line-chart-account-series-totals";
import BalanceLineChart from "ui/data-visualization/chart/BalanceLineChart";
import { BalanceLineChartSerieDatum } from "ui/data-visualization/chart/BalanceLineChartSerieDatum";
import BalanceLineChartTooltip from "ui/data-visualization/chart/BalanceLineChartTooltip";
import ChartTools from "ui/data-visualization/ChartTools";
import PlatformAvatar from "ui/icons/PlatformAvatar";
import PlatformAvatarGroup from "ui/icons/PlatformAvatarGroup";
import ButtonLink from "ui/inputs/ButtonLink";
import IconWithTooltip from "ui/overlay/IconWithTooltip";
import { Heading4, Paragraph, Span } from "ui/typography";
import { pluralize } from "utils/string";
import cn from "utils/tailwind/cn";
import { DEFAULT_RELATIVE_TIMEFRAME, RelativeTimeframe, timeframeToNumDays } from "utils/timeframe";
import { ArrayOfUnion } from "utils/ts/ArrayOfUnion";
import variants from "utils/ts/variants";

import AccountsOverviewBalanceChartEmpty from "./AccountsOverviewBalanceChartEmpty";
import AccountsOverviewInterestYtd from "./AccountsOverviewInterestYtd";

// NB(alex): We don't do this yet, but we can merge institutions together if there are too many results. https://highbeamco.slack.com/archives/C065RNMTA7Q/p1740536597228809?thread_ts=1740532345.037029&cid=C065RNMTA7Q

const convertBalanceHistoryToBalanceLineChartAccountSeries = (
  balanceHistory: BalanceHistoryAugmented
): BalanceLineChartSerieDatum[][] => {
  const startInclusive = dayjs(balanceHistory.historicalRangeStartInclusive);

  return balanceHistory.accounts.map((account) => {
    return account.historicalDailyBalances.map((balance, index) => {
      return [dayjs(startInclusive).add(index, "day").format("YYYY-MM-DD"), balance.amountInCents];
    });
  });
};

const floorAccountSeriesValues = (
  accountSeries: BalanceLineChartSerieDatum[]
): BalanceLineChartSerieDatum[] => {
  return accountSeries.map(([date, amountInCents]) => {
    return [date, Math.floor(amountInCents / 100) * 100];
  });
};

const floorAccountsSeriesValues = (
  accountsSeries: BalanceLineChartSerieDatum[][]
): BalanceLineChartSerieDatum[][] => {
  return accountsSeries.map((account) => {
    return floorAccountSeriesValues(account);
  });
};

export type FilterType = "highbeam" | "total";

const getFilterTitle = (value: FilterType) => {
  return variants(value, {
    highbeam: "Highbeam balance",
    total: "Total cash balance",
  });
};

const BALANCE_VIEW_OPTIONS: ArrayOfUnion<FilterType> = ["highbeam", "total"];

const { persistAtom } = recoilPersist();

const balanceViewState = atom<FilterType>({
  key: "accounts/balanceViewState",
  default: "highbeam",
  effects: [persistAtom],
});

const useBalanceViewState = (hasOpenBankAccounts: boolean) => {
  const [balanceView, setBalanceView] = useRecoilState(balanceViewState);

  useEffect(() => {
    if (!hasOpenBankAccounts) {
      setBalanceView("total");
    }
  }, [hasOpenBankAccounts, setBalanceView]);

  return [balanceView, setBalanceView] as const;
};

const timeframeState = atom<RelativeTimeframe>({
  key: "accounts/timeframeState",
  default: DEFAULT_RELATIVE_TIMEFRAME,
  effects: [persistAtom],
});

const useTimeframeState = () => useRecoilState(timeframeState);

type Props = {
  className?: string;
};

const AccountsOverviewBalance: FC<Props> = ({ className }) => {
  const openBankAccounts = useBankAccounts({ filters: { status: "open" } });
  const hasOpenBankAccounts = openBankAccounts.length > 0;

  const [balanceView, setBalanceView] = useBalanceViewState(hasOpenBankAccounts);
  const [timeframe, setTimeframe] = useTimeframeState();
  const [now] = useState(() => dayjs());

  const historicalRangeStartInclusive = useMemo(() => {
    return now.subtract(timeframeToNumDays(timeframe) - 1, "days").format("YYYY-MM-DD");
  }, [timeframe, now]);

  const balanceHistory = balanceHistoryQueryHooks.useData({
    params: {
      historicalRangeEndInclusive: now.format("YYYY-MM-DD"),
      historicalRangeStartInclusive: historicalRangeStartInclusive,
      excludedAccountTypes: ["Credit", "Loan"],
    },
    select: (data) => {
      return {
        ...data,
        accounts: data.accounts.filter((account) => {
          if (balanceView === "highbeam") {
            return checkIsHighbeamNormalizedAccountRep(account.normalizedAccount);
          }
          return true;
        }),
      };
    },
  });

  const accountSeries = convertBalanceHistoryToBalanceLineChartAccountSeries(balanceHistory);
  const aggregatedData = aggregateBalanceLineChartAccountSeriesTotals(accountSeries);

  const accountSeriesWithFlooredValues = floorAccountsSeriesValues(accountSeries);
  const aggregatedDataWithFlooredValues = floorAccountSeriesValues(aggregatedData);

  const totalBalance =
    aggregatedData.length > 0 ? aggregatedData[aggregatedData.length - 1]?.[1] : 0;

  const balanceHistoryInstitutions = useMemo(() => {
    if (!balanceHistory) return [];

    const uniqueBalanceHistoryInstitutions = getUniqueBalanceHistoryInstitution(balanceHistory);

    // If there are more than 4 institutions, remove high yield.
    if (uniqueBalanceHistoryInstitutions.length >= 4) {
      return uniqueBalanceHistoryInstitutions.filter((institution) => {
        return institution.type !== "highbeam" || institution.highbeamAccountType !== "HighYield";
      });
    }
    return uniqueBalanceHistoryInstitutions;
  }, [balanceHistory]);

  const isBusinessActive = useIsBusinessActive(); // Will update name to `isBankingActive` soon.
  const hasPlaidConnections = useHasPlaidConnections();
  const hasConnections = isBusinessActive || hasPlaidConnections;
  const showEmptyState = aggregatedData.length === 0;

  const numDisconnectedAccounts = useMemo(() => {
    return (
      balanceHistory?.accounts.filter(
        (account) => account.normalizedAccount.status === "disconnected"
      ).length ?? 0
    );
  }, [balanceHistory]);

  return (
    <div className={cn("flex flex-col gap-x-8 gap-y-6 tablet:flex-row", className)}>
      <div className="min-w-64">
        <BalanceHistoryTotalBalanceListbox
          value={balanceView}
          onChange={setBalanceView}
          renderTrigger={({ value }) => {
            return (
              <div className="flex items-center whitespace-nowrap">
                {getFilterTitle(value)}{" "}
                <PlatformAvatarGroup
                  truncateAt={4}
                  variant="small-grey-circle"
                  overlapSide="left-over-right"
                  className="flex-1 shrink-0 px-3 leading-none"
                >
                  {balanceHistoryInstitutions.map((institution) => {
                    switch (institution.type) {
                      case "highbeam":
                        return (
                          <PlatformAvatar
                            variant="small-grey-circle"
                            key={institution.highbeamAccountType}
                            className="shrink-0"
                          >
                            <HighbeamAccountIcon
                              highbeamTypeName={institution.highbeamAccountType}
                            />
                          </PlatformAvatar>
                        );
                      case "plaid":
                        return (
                          <PlatformAvatar
                            variant="small-grey-circle"
                            key={institution.institutionId}
                            className="shrink-0"
                          >
                            <PlaidInstitutionLogo institutionId={institution.institutionId} />
                          </PlatformAvatar>
                        );
                    }
                  })}
                </PlatformAvatarGroup>
              </div>
            );
          }}
        >
          {BALANCE_VIEW_OPTIONS.map((value) => (
            <BalanceHistoryTotalBalanceListbox.Item key={value} value={value}>
              {getFilterTitle(value)}
            </BalanceHistoryTotalBalanceListbox.Item>
          ))}
        </BalanceHistoryTotalBalanceListbox>

        <div className="mb-6 mt-0.5">
          <CashDisplay cents={totalBalance} withCents />

          <div className="my-2.5">
            {hasConnections ? (
              <div className="flex items-center gap-x-1.5">
                <Span className="text-sm text-grey-600">as of today</Span>
                {balanceView === "total" && (
                  <IconWithTooltip
                    icon="info"
                    className="text-xs text-grey-600"
                    tooltip="Balances for connected accounts are refreshed daily."
                  />
                )}
              </div>
            ) : (
              <Paragraph className="max-w-64 text-sm text-grey-600">
                Connect your bank accounts or verify your business to get started.
              </Paragraph>
            )}
          </div>

          {isBusinessActive && (
            <div className="mt-6 max-w-44 border-t border-t-grey-200 pt-6">
              <div className="flex items-center gap-x-1.5">
                <Span className="text-sm font-medium">Highbeam yield (YTD)</Span>
                <IconWithTooltip tooltip={<TotalYieldInfoTooltipContent />} />
              </div>

              <AccountsOverviewInterestYtd />
            </div>
          )}
        </div>
      </div>

      <div className="flex-1">
        <ChartTools
          className={cn("mb-3", {
            // Ensures consistent spacing above the chart.
            invisible:
              (balanceView === "highbeam" && !isBusinessActive) ||
              (balanceView === "total" && !hasPlaidConnections),
          })}
        >
          {numDisconnectedAccounts > 0 && (
            <ButtonLink className="text-yellow-700" to="/settings/banks-and-cards">
              <LinkBreak size={16} className="text-yellow-600" />
              {pluralize(numDisconnectedAccounts, "account")} disconnected
            </ButtonLink>
          )}
          <ChartTools.RelativeTimeframeSelect value={timeframe} onChange={setTimeframe} />
        </ChartTools>

        {showEmptyState ? (
          <AccountsOverviewBalanceChartEmpty />
        ) : (
          <BalanceLineChart
            color={variants(balanceView, {
              highbeam: "purple-500" as const,
              total: "green-400" as const,
            })}
            data={aggregatedData}
            renderTooltip={({ titleLocalDate }) => {
              return (
                <BalanceLineChartTooltip
                  titleLocalDate={titleLocalDate}
                  accountSeries={accountSeriesWithFlooredValues}
                  renderItem={({ index, value }) => {
                    if (!balanceHistory) {
                      return null;
                    }

                    const normalizedAccount = balanceHistory.accounts[index].normalizedAccount;

                    const valueInCents = Number(value.replace(/[$,]/g, ""));
                    if (normalizedAccount.status === "closed" && valueInCents === 0) {
                      return null;
                    }

                    return (
                      <BalanceLineChartTooltip.Item key={normalizedAccount.rawAccountId}>
                        <div className="flex items-center gap-x-2 pr-12">
                          <NormalizedAccountAugmentedBar
                            size={16}
                            normalizedAccountAugmented={normalizedAccount}
                          />
                        </div>
                        <MoneyAmount
                          as="span"
                          className={cn(
                            "text-xs font-medium text-grey-800",
                            normalizedAccount.status === "disconnected" && "text-grey-500"
                          )}
                          cents={valueInCents}
                          centsTextSize={8}
                          withCents={false}
                        />
                      </BalanceLineChartTooltip.Item>
                    );
                  }}
                  renderFooter={() => {
                    const totalCashBalanceInCents =
                      aggregatedDataWithFlooredValues.find(
                        ([date]) => date === titleLocalDate
                      )?.[1] ?? 0;

                    return (
                      <BalanceLineChartTooltip.Footer>
                        <div className="flex items-center gap-x-2.5">
                          <CurrencyDollar className="text-grey-600" size={16} />
                          <Heading4 className="text-xs font-medium text-grey-800">
                            {getFilterTitle(balanceView)}
                          </Heading4>
                        </div>

                        <MoneyAmount
                          as="span"
                          className="text-xs font-medium text-grey-800"
                          cents={totalCashBalanceInCents}
                          centsTextSize={8}
                          withCents={false}
                        />
                      </BalanceLineChartTooltip.Footer>
                    );
                  }}
                />
              );
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AccountsOverviewBalance;
