import { useBankAccountByUnitAccountIdOrThrow } from "modules/bank-accounts/queries/useBankAccount";
import CancelPaymentModal, {
  useCancelPaymentModal,
} from "modules/payments/dialogs/CancelPaymentModal";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import FlexpaneHeader from "ui/overlay/Flexpane/FlexpaneHeader";
import useIsAllowedToCancelPayment from "utils/permissions/useIsAllowedToCancelPayment";
import { HighbeamOriginatedAchTransaction } from "utils/types/transactionsTypes";

import { useTransactionInfoFlexpaneContext } from "../context/TransactionInfoFlexpaneProvider";

type Props = {
  transaction: HighbeamOriginatedAchTransaction;
};

const OriginatedAchTransactionFlexpaneHeader: React.FC<Props> = ({ transaction }) => {
  const { refreshTransactions, onClose } = useTransactionInfoFlexpaneContext();

  const isAllowedToCancelPayment = useIsAllowedToCancelPayment();
  const bankAccount = useBankAccountByUnitAccountIdOrThrow(transaction.accountId);

  const canCancelPayment =
    transaction.paymentStatus === "Pending" || transaction.paymentStatus === "PendingReview";

  const cancelPaymentModal = useCancelPaymentModal();

  return (
    <>
      {cancelPaymentModal.isOpen && (
        <CancelPaymentModal
          onSuccess={() => {
            refreshTransactions && refreshTransactions();
            onClose();
          }}
          onClose={cancelPaymentModal.close}
          params={cancelPaymentModal.params}
        />
      )}

      <FlexpaneHeader
        onClose={onClose}
        right={
          canCancelPayment && transaction.paymentGuid !== undefined ? (
            <ButtonWithTooltip
              variant="danger"
              onClick={() => {
                cancelPaymentModal.open({
                  bankAccount: bankAccount,
                  counterpartyName: transaction.counterpartyName,
                  paymentAmountInCents: transaction.amountInCents,
                  paymentDirection: "Outgoing",
                  paymentGuid: transaction.paymentGuid!,
                });
              }}
              disabled={!isAllowedToCancelPayment}
              tooltip={
                !isAllowedToCancelPayment && "You don’t have permission to cancel transfers."
              }
            >
              Cancel transfer
            </ButtonWithTooltip>
          ) : null
        }
      >
        Transaction info
      </FlexpaneHeader>
    </>
  );
};

export default OriginatedAchTransactionFlexpaneHeader;
