import { LockLaminated } from "@phosphor-icons/react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useChatWidget } from "components/ChatWidget";
import HighbeamCard from "components/HighbeamCard";
import { FEATURE_FLAGS } from "flags";
import CardApproachingLimitBanners from "modules/cards/components/CardApproachingLimitBanners";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import useUnitCoAccountLimitQueryOptions from "modules/unit-co-account-limits/queries/useUnitCoAccountLimitQueryOptions";
import Banner from "ui/data-display/Banner";
import Button from "ui/inputs/Button";

import CardDetailsMetadata from "../CardDetailsMetadata";
import { useCardDetailsPageContext } from "../providers/CardDetailsPageProvider";

import styles from "./CardDetailsInfo.module.scss";

const CardDetailsInfo = () => {
  const { card } = useCardDetailsPageContext();
  const canSeeArchivedCardDetails = Boolean(useFeatureFlag(FEATURE_FLAGS["archived-card-details"]));

  const chat = useChatWidget();
  const { data: accountLimits } = useSuspenseQuery(
    useUnitCoAccountLimitQueryOptions(card.relationships.account.data.id || "")
  );

  return (
    <div className={styles.detailsContainer}>
      <div className={styles.title}>
        <h2>Card info</h2>
      </div>
      {accountLimits && (
        <div className={styles.bannerContainer}>
          <CardApproachingLimitBanners cards={[card]} />
        </div>
      )}
      {card.attributes.status === "SuspectedFraud" && (
        <div className={styles.bannerContainer}>
          <Banner
            icon={<LockLaminated size={20} />}
            color="red"
            title="Card is locked for unusual activity"
            paragraph={
              <>
                Your card has been flagged and locked due to potential unusual activity. Please
                check your email for notices from Highbeam (notifications@highbeam.co) to review
                these transactions and reactivate your card. If you have any questions or would like
                Highbeam to reactivate your card, please contact support.
              </>
            }
            button={
              <Button variant="tertiary" onClick={() => chat.show()}>
                Contact support
              </Button>
            }
          />
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.cardContainer}>
          <HighbeamCard
            card={card}
            showDetailsButton={
              card.attributes.status !== "Inactive" &&
              (card.attributes.status !== "ClosedByCustomer" || canSeeArchivedCardDetails)
            }
            clickable={false}
            useHighbeamLogo
            showStatus={false}
          />
        </div>

        <CardDetailsMetadata card={card} />
      </div>
    </div>
  );
};

export default CardDetailsInfo;
