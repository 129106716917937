import benchmarksPreviewImg from "assets/benchmarks-preview.svg";
import scenariosPreviewImg from "assets/scenarios-preview.svg";
import { ComingSoonChatElementRep } from "modules/chat/reps/ChatElementRep";
import { Heading2, Paragraph } from "ui/typography";
import variants from "utils/ts/variants";

import ParagraphChatElement from "./ParagraphChatElement";

type Props = {
  element: ComingSoonChatElementRep;
};

const ComingSoonChatElement: React.FC<Props> = ({ element }) => {
  const featureDisplayName = variants(element.feature, {
    Benchmarking: "Benchmarks",
    Forecasting: "Scenarios",
  });
  const featurePreviewImg = variants(element.feature, {
    Benchmarking: benchmarksPreviewImg,
    Forecasting: scenariosPreviewImg,
  });
  const featureCopyPart =
    " " +
    variants(element.feature, {
      Benchmarking: "benchmarking is available!",
      Forecasting: "scenarios are available!",
    });
  return (
    <div>
      <div className="mb-6 flex flex-row justify-between gap-4 rounded-lg border-[1px] border-purple-100 bg-purple-50 p-6">
        <div>
          <Heading2 className="mb-4">{`${featureDisplayName} are coming soon`}</Heading2>

          <Paragraph className="text-grey-600">
            We’ve added you to the early access waiting list, and we’ll email you when
            {featureCopyPart}
          </Paragraph>
        </div>

        <div className="rounded-xl border border-purple-100 bg-white shadow-xl shadow-purple-100">
          <img src={featurePreviewImg} alt={`${featureDisplayName} preview`} className="m-2" />
        </div>
      </div>
      {/* Hard-coded pseudo followup message */}
      <ParagraphChatElement
        element={{
          type: "Paragraph",
          content: [
            {
              type: "Text",
              content: "How else can I be of assistance?",
              bold: false,
              italic: false,
            },
          ],
        }}
      />
    </div>
  );
};

export default ComingSoonChatElement;
