import { SuggestedFollowUp } from "modules/chat/reps/ExchangeRep";
import { FC, ReactNode } from "react";
import ChatSuggestionBubble from "ui/chat/ChatSuggestionBubble";
import DotsLoader from "ui/feedback/DotsLoader";
import VirtualButton from "ui/inputs/VirtualButton";
import cn from "utils/tailwind/cn";

type Props = {
  children: ReactNode;
};

const AiChatExchangeSuggestedFollowUps: FC<Props> = ({ children }) => {
  return <div className="relative flex w-full flex-row flex-wrap gap-4">{children}</div>;
};

type ChatBubbleButtonProps = {
  suggestedFollowUp: SuggestedFollowUp;
  onClick: () => void;
  isLoading?: boolean;
};

const ChatBubbleButton: FC<ChatBubbleButtonProps> = ({ suggestedFollowUp, onClick, isLoading }) => {
  return (
    <VirtualButton
      key={suggestedFollowUp.display}
      onClick={onClick}
      className={cn("rounded-full", isLoading && "pointer-events-none opacity-10")}
    >
      <ChatSuggestionBubble
        className={cn(
          "hover:bg-grey-50 hover:text-grey-600",
          suggestedFollowUp.emphasis &&
            "border-grey-200 text-purple-500 hover:border-purple-300 hover:bg-purple-50 hover:text-purple-600"
        )}
      >
        {suggestedFollowUp.display}
      </ChatSuggestionBubble>
    </VirtualButton>
  );
};

const Loading = () => {
  return (
    <div className="absolute inset-0 flex items-start justify-center p-32">
      <DotsLoader dots={5} />
    </div>
  );
};

export default Object.assign(AiChatExchangeSuggestedFollowUps, {
  Button: ChatBubbleButton,
  Loading,
});
