import {
  ArrowFatLinesUp,
  ArrowSquareOut,
  DotsThreeCircle,
  DotsThreeVertical,
} from "@phosphor-icons/react";
import DashboardPage from "layouts/DashboardPage";
import CapitalAccountIcon from "modules/capital-accounts/components/CapitalAccountIcon";
import LineOfCreditDrawdownButtonLink from "modules/capital-accounts/components/LineOfCreditDrawdownButtonLink";
import LineOfCreditRepayButtonLink from "modules/capital-accounts/components/LineOfCreditRepayButtonLink";
import ChargeCardAdditionalMetadataModal from "modules/capital-accounts/dialogs/ChargeCardAdditionalMetadataModal";
import { checkIsActiveCapitalAccountWithChargeCard } from "modules/capital-accounts/hooks/useActiveCapitalAccountsWithChargeCard";
import getCapitalAccountTitle from "modules/capital-accounts/utils/getCapitalAccountTitle";
import getCapitalLenderDetails from "modules/capital-accounts/utils/getCapitalLenderDetails";
import { CapitalAccountSummaryWithChargeCard } from "modules/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import { FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import Pill from "ui/data-display/Pill";
import Button from "ui/inputs/Button";
import ButtonLinkWithTooltip from "ui/inputs/ButtonLink/ButtonLinkWithTooltip";
import VirtualButton from "ui/inputs/VirtualButton";
import Menu from "ui/overlay/Menu";
import useModalV2 from "utils/dialog/useModalV2";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAccountHeader: FC<Props> = ({ capitalAccountSummary }) => {
  const moreDetailsModal = useModalV2<{
    capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
  }>();

  const lenderDetails = getCapitalLenderDetails(capitalAccountSummary);

  return (
    <>
      {moreDetailsModal.isOpen && (
        <ChargeCardAdditionalMetadataModal
          onClose={moreDetailsModal.close}
          capitalAccountSummaryWithChargeCard={
            moreDetailsModal.params.capitalAccountSummaryWithChargeCard
          }
        />
      )}

      <DashboardPage.Header
        actions={
          (capitalAccountSummary.state === CapitalAccountRep.State.Active ||
            capitalAccountSummary.state === CapitalAccountRep.State.OfferAccepted) && (
            <div className="flex w-full gap-x-2">
              {capitalAccountSummary.type === CapitalAccountRep.Type.CashAccessOnly && (
                <div className="flex w-full">
                  {lenderDetails.external && (
                    <a href={lenderDetails.loginLink} target="_blank" rel="noreferrer">
                      <VirtualButton
                        variant="ghost"
                        className="mr-4 cursor-pointer text-purple-500"
                      >
                        View in {lenderDetails.name} <ArrowSquareOut size={14} />
                      </VirtualButton>
                    </a>
                  )}
                  <LineOfCreditRepayButtonLink
                    capitalAccountSummary={capitalAccountSummary}
                    className="rounded-r-none border-r-0"
                  />
                  <LineOfCreditDrawdownButtonLink
                    className="rounded-l-none"
                    capitalAccountSummary={capitalAccountSummary}
                  />
                </div>
              )}

              {capitalAccountSummary.type === CapitalAccountRep.Type.ChargeCardOnly && (
                <ButtonLinkWithTooltip
                  variant="secondary"
                  to={`/capital/${capitalAccountSummary.guid}/repay`}
                  disabled={capitalAccountSummary.state !== CapitalAccountRep.State.Active}
                  tooltip={
                    capitalAccountSummary.state !== CapitalAccountRep.State.Active &&
                    `Please connect payouts to activate your ${capitalAccountSummary.name}.`
                  }
                >
                  <ArrowFatLinesUp size={20} weight="light" />
                  Repay
                </ButtonLinkWithTooltip>
              )}

              {checkIsActiveCapitalAccountWithChargeCard(capitalAccountSummary) && (
                <Menu
                  button={
                    <Button variant="tertiary" className="h-[2.625rem] px-3">
                      <DotsThreeVertical size={16} />
                    </Button>
                  }
                >
                  <Menu.Item
                    icon={<DotsThreeCircle size={16} />}
                    onClick={() => {
                      moreDetailsModal.open({
                        capitalAccountSummaryWithChargeCard: capitalAccountSummary,
                      });
                    }}
                  >
                    More details
                  </Menu.Item>
                </Menu>
              )}
            </div>
          )
        }
      >
        <DashboardPage.Header.BackCaret to="/capital" />
        <DashboardPage.Header.IconTile
          icon={
            <CapitalAccountIcon
              capitalAccount={capitalAccountSummary}
              size={
                // NB(justin): It might be better here to make a lender-specific IconTile.
                capitalAccountSummary.lender === CapitalAccountRep.CapitalLender.Highbeam ? 24 : 44
              }
              className={capitalAccountSummary.lender === "Highbeam" ? "" : "rounded-lg"}
            />
          }
        />
        <DashboardPage.Header.Title>
          {getCapitalAccountTitle(capitalAccountSummary)}
        </DashboardPage.Header.Title>
        {capitalAccountSummary.state === CapitalAccountRep.State.Terminated && (
          <Pill color="red-200">Closed</Pill>
        )}
      </DashboardPage.Header>
    </>
  );
};

export default CapitalAccountHeader;
