import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import useBusiness from "modules/business/queries/useBusiness";
import { useUnitApiOrThrow } from "modules/unit-co-customer-token/queries/useUnitApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const useUnitCoCustomerQueryOptions = () => {
  const unitApi = useUnitApiOrThrow();
  const { unitCoCustomerId } = useBusiness();

  return useQueryOptions({
    queryKey: ["unitCoCustomer", { unitCoCustomerId }],
    queryFn: async () => {
      if (!unitCoCustomerId) return null;
      const { data } = await unitApi.customers.get(unitCoCustomerId);
      return data;
    },
  });
};

export const useUnitCoCustomerQuery = () => {
  return useQuery(useUnitCoCustomerQueryOptions());
};

const useUnitCoCustomer = () => {
  return useSuspenseQuery(useUnitCoCustomerQueryOptions()).data;
};

export default useUnitCoCustomer;
