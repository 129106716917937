import { Coins, DownloadSimple } from "@phosphor-icons/react";
import emptyImg from "assets/empty-state.svg";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import CapitalAccountIcon from "modules/capital-accounts/components/CapitalAccountIcon";
import capitalAccountSummaryQueryHooks from "modules/capital-accounts/queries/capitalAccountSummaryQueryHooks";
import useDownloadDocumentMutation from "modules/documents/mutations/useDownloadDocumentMutation";
import useDocuments from "modules/documents/queries/useDocuments";
import { FC } from "react";
import DocumentRepBackendV1 from "reps/DocumentRepBackendV1";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import DateCell from "ui/table/cells/DateCell";
import NotFoundCell from "ui/table/cells/NotFoundCell";
import EmptyState from "ui/table/EmptyState";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import Text from "ui/typography/Text";
import useIsAllowedToDownloadCreditOfferAgreement from "utils/permissions/useIsAllowedToDownloadCreditOfferAgreement";

import { permissionsTooltipCopy } from "../CapitalAccountPage/constants";

import styles from "./CapitalDocumentsPage.module.scss";

const columns: Column<DocumentRepBackendV1.Complete>[] = [
  {
    title: "Created",
    cellRender: ({ generatedAt }) => <DateCell date={generatedAt} />,
  },
  {
    title: "Type",
    cellRender: ({ name }) => <Text size={14}>{name}</Text>,
  },
  {
    title: "Capital account",
    cellRender: (datum) => {
      if (!datum.lineOfCreditGuid) {
        return <NotFoundCell />;
      }
      return <CapitalAccountCell lineOfCreditGuid={datum.lineOfCreditGuid} />;
    },
  },
  {
    title: "Action",
    align: TableColumnAlignment.RIGHT,
    cellRender: (datum) => <DownloadButton datum={datum} />,
  },
];

const CapitalDocumentsPage = () => {
  const documents = useDocuments();

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/capital">Capital</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Documents</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header>
          <DashboardPage.Header.IconTile icon={<Coins />} />
          <DashboardPage.Header.Title>Documents</DashboardPage.Header.Title>
        </DashboardPage.Header>

        <DashboardPage.Section>
          {documents.length > 0 ? (
            <Table
              columns={columns}
              isLoadingRows={3}
              data={documents}
              rowKey={(documents) => documents.guid}
            />
          ) : (
            <EmptyState
              image={emptyImg}
              primaryText="No documents available."
              secondaryText="Once you have documents, they will be shown here."
            />
          )}
        </DashboardPage.Section>
      </DashboardPage>
    </>
  );
};

export default CapitalDocumentsPage;

type DownloadButtonProps = {
  datum: DocumentRepBackendV1.Complete;
};

const DownloadButton: FC<DownloadButtonProps> = ({ datum }) => {
  const isAllowedToDownloadCreditOfferAgreement = useIsAllowedToDownloadCreditOfferAgreement();
  const disabled = !isAllowedToDownloadCreditOfferAgreement;
  const { mutateAsync: downloadDocument, isPending } = useDownloadDocumentMutation();

  const onDownloadDocument = async () => {
    await downloadDocument({ documentGuid: datum.guid, documentName: datum.name });
  };

  if (!datum.lineOfCreditGuid) {
    return null;
  }

  return (
    <div className={styles.downloadButton}>
      <ButtonWithTooltip
        variant="tertiary"
        onClick={onDownloadDocument}
        size="sm"
        disabled={disabled}
        isLoading={isPending}
        tooltip={disabled && permissionsTooltipCopy.notAllowedToDownloadCreditAgreement}
      >
        <DownloadSimple />
        Download
      </ButtonWithTooltip>
    </div>
  );
};

type CapitalAccountCellProps = {
  lineOfCreditGuid: string;
};

const CapitalAccountCell: FC<CapitalAccountCellProps> = ({ lineOfCreditGuid }) => {
  const capitalAccountSummary = capitalAccountSummaryQueryHooks.useDataRequired({
    capitalAccountGuid: lineOfCreditGuid,
  });

  return (
    <div className={styles.capitalAccountName}>
      <CapitalAccountIcon capitalAccount={capitalAccountSummary} />
      <Text size={14} weight="medium">
        {capitalAccountSummary.name}
      </Text>
    </div>
  );
};
