// NOTE: Modify allowed redirect URLs in Shopify settings if you change `supportedOrigins`.
const supportedRedirectUrls = {
  capital: "/capital/application/connect-stores",
  settings: "/settings/stores",
  onboarding: "/onboarding/connect-stores",
} satisfies Record<string, string>;

type SupportedRedirectUrl = keyof typeof supportedRedirectUrls;

const checkIsSupportedRedirectUrl = (url: string): url is SupportedRedirectUrl => {
  return url in supportedRedirectUrls;
};

export { supportedRedirectUrls, type SupportedRedirectUrl, checkIsSupportedRedirectUrl };
