// NB(alex): `makeQueryHooks` is still experimental.

import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import useCapitalAccountApi from "../api/useCapitalAccountApi";

export const ROOT_CAPITAL_ACCOUNTS_QUERY_KEY = "capitalAccounts-root";

export const useRefreshAllCapitalAccountsQueries = () => {
  return useRefreshQuery([ROOT_CAPITAL_ACCOUNTS_QUERY_KEY]);
};

type Params = {
  businessGuid: string;
};

const capitalAccountsQueryHooks = makeQueryHooksV2({
  useQueryFnParams: () => {
    const capitalAccountApi = useCapitalAccountApi();
    return { capitalAccountApi };
  },
  useDefaultParams: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  makeQueryKey: (params: Params) => {
    return [ROOT_CAPITAL_ACCOUNTS_QUERY_KEY, "capitalAccounts", params];
  },
  makeQueryFn: ({ capitalAccountApi }, { businessGuid }) => {
    return () => {
      // TODO(alex) HB-5859
      if (!businessGuid) return [];
      return capitalAccountApi.getAll(businessGuid);
    };
  },
});

export default capitalAccountsQueryHooks;
