import { useSetRecoilState } from "recoil";
import { notify } from "ui/feedback/Toast";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import {
  GetStartedActionItem,
  getStartedActionItemsQueryHooks,
} from "../queries/useGetStartedActionItems";
import ActionItemRep, {
  ActionItemGetStartedSetupGuideStep,
  ActionItemState,
} from "../reps/ActionItemRep";
import isGettingStartedSetupGuideOpenState from "../state/gettingStartedSetupGuide";

import { useFinishActionItemMutation } from "./useFinishActionItemMutation";

const allActionItemsComplete = (actionItems: GetStartedActionItem[]) => {
  return actionItems.every((item) => item.state === "Complete");
};

type Variables = {
  step: ActionItemGetStartedSetupGuideStep;
  state: ActionItemState;
};

const useFinishGetStartedActionItemMutation = (
  additionalOptions?: MutationAdditionalOptions<ActionItemRep.Complete | null, Variables>
) => {
  const { refetch: refetchGetStartedActionItems } = getStartedActionItemsQueryHooks.useQuery({
    params: {},
  });

  const setShowGetStarted = useSetRecoilState(isGettingStartedSetupGuideOpenState);

  const { mutateAsync: finishActionItem } = useFinishActionItemMutation("GetStarted");

  return useMutationWithDefaults(
    {
      mutationFn: async ({ step, state }: Variables) => {
        return finishActionItem({ step, state });
      },
      onSuccess: async () => {
        // NB(alex): Slightly inefficient because we end up refetching twice via `useRefreshAllActionItemsQueries`, but oh well.
        const { data: finalData } = await refetchGetStartedActionItems();
        if (finalData && allActionItemsComplete(finalData)) {
          notify("success", "Congrats on setting up your business on Highbeam!");
        }
        setShowGetStarted(true);
      },
    },
    additionalOptions ?? {}
  );
};

export { useFinishGetStartedActionItemMutation };
