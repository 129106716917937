import { ComponentPropsWithoutRef, ComponentRef, forwardRef } from "react";
import { SetRequired } from "type-fest";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

export type PlatformAvatarVariant =
  | "circle"
  | "rounded-square"
  | "small-grey-circle"
  // NB(lev): The interactive variant is used for the data sources avatar group in the AI chat.
  // It's similar to the small-grey-circle variant, but has a lighter background and a hover state.
  | "small-grey-circle-interactive";

type PlatformAvatarImgProps = SetRequired<ComponentPropsWithoutRef<"img">, "src" | "alt">;

const PlatformAvatarImg = forwardRef<ComponentRef<"img">, PlatformAvatarImgProps>(
  ({ alt, src, className, ...props }, ref) => {
    return (
      <img
        width="100%"
        height="100%"
        alt={alt}
        src={src}
        className={cn("shrink-0", className)}
        {...props}
        ref={ref}
      />
    );
  }
);

type Props = SetRequired<ComponentPropsWithoutRef<"div">, "children"> & {
  variant?: PlatformAvatarVariant;
};

const PlatformAvatar = forwardRef<ComponentRef<"div">, Props>(
  ({ variant = "circle", className, children }, ref) => {
    return (
      <div
        className={cn(
          "flex items-center justify-center rounded-full bg-white",
          variants(variant, {
            circle: "size-8 rounded-full border border-grey-100",
            "rounded-square": "size-10 rounded-lg border border-grey-300 shadow-xs",
            "small-grey-circle": "size-6 bg-grey-100",
            "small-grey-circle-interactive": "size-6 bg-grey-50 group-hover:bg-grey-100",
          }),
          className
        )}
        ref={ref}
      >
        <div
          className={cn(
            "flex w-auto items-center justify-center",
            variants(variant, {
              circle: "h-3.5",
              "rounded-square": "h-4",
              "small-grey-circle": "h-4",
              "small-grey-circle-interactive": "h-4",
            })
          )}
        >
          {children}
        </div>
      </div>
    );
  }
);

export default Object.assign(PlatformAvatar, {
  Img: PlatformAvatarImg,
});
