import useDeleteExchangeMutation from "modules/chat/mutations/useDeleteExchangeMutation";
import { FC } from "react";
import { useSidePanelContext } from "ui/data-display/SidePanel";
import Button from "ui/inputs/Button";

const AiChatChannelPageDeleteExchangeButton: FC<{ exchangeId: string }> = ({ exchangeId }) => {
  const { setWidth } = useSidePanelContext();
  const { mutate, isPending } = useDeleteExchangeMutation();
  return (
    <Button
      isLoading={isPending}
      size="xs"
      onClick={() => {
        setWidth(0);
        mutate({ exchangeId });
      }}
      variant="ghost"
      className="text-red-500/50"
    >
      [SU] Delete exchange
    </Button>
  );
};

export default AiChatChannelPageDeleteExchangeButton;
