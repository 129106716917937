import { FC } from "react";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

export type CountBadgeBackgroundColor =
  | "grey-100"
  | "grey-200"
  | "grey-500"
  | "grey-700"
  | "purple-100";

type Props = {
  count: number;
  backgroundColor: CountBadgeBackgroundColor;
  className?: string;
};

const CountBadge: FC<Props> = ({ className, count, backgroundColor }) => {
  // NB(lev): Count badges may be nested inside of elements that only permit
  // phrasing content, so we need to use a span instead of a div.
  return (
    <span
      className={cn(
        "flex h-5 w-min items-center justify-center rounded-xl px-2",
        "text-xs font-medium tabular-nums", // Text styles
        variants(backgroundColor, {
          "grey-100": "bg-grey-100 text-grey-500",
          "grey-200": "bg-grey-200 text-grey-500",
          "grey-500": "bg-grey-500 text-white",
          "grey-700": "bg-grey-700 text-white",
          "purple-100": "bg-purple-100 text-purple-500",
        }),
        className
      )}
    >
      {count}
    </span>
  );
};

export default CountBadge;
