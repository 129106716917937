import { useQueryClient } from "@tanstack/react-query";
import useExchangeApi from "modules/chat/api/useExchangeApi";
import { chatChannelExchangesQueryHooks } from "modules/chat/queries/useChatChannelExchanges";
import { useRefreshChatThreadExchanges } from "modules/chat/queries/useChatThreadExchanges";
import ExchangeRep, { ExchangeCreatorRep } from "modules/chat/reps/ExchangeRep";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

type Variables = Omit<ExchangeCreatorRep, "userGuid">;

const useCreateChatChannelExchangeMutation = (
  additionalOptions?: MutationAdditionalOptions<ExchangeRep, Variables>
) => {
  const exchangeApi = useExchangeApi();
  const userGuid = useUserGuid();
  const queryClient = useQueryClient();
  const refreshChatThreadExchanges = useRefreshChatThreadExchanges();

  return useMutationWithDefaults(
    {
      mutationFn: (variables: Variables) => {
        return exchangeApi.create({ userGuid, ...variables });
      },
      onSuccess: async (_data, variables) => {
        await Promise.all([
          // NB(alex): Should be able to figure out a simpler way to do this in `makeQueryHooksV2`.
          queryClient.invalidateQueries({
            queryKey: chatChannelExchangesQueryHooks.makeQueryKey({
              channelId: variables.channelId,
              latestExclusive: null,
            }),
          }),
          refreshChatThreadExchanges(),
        ]);
      },
    },
    additionalOptions ?? {}
  );
};

export default useCreateChatChannelExchangeMutation;
