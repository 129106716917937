import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { FEATURE_FLAGS } from "flags";
import useInternationalAccountQuery from "modules/bank-accounts/queries/useInternationalAccountQuery";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";

const useShouldReviewVgsiTerms = () => {
  const isVgsiFlagEnabled = useFeatureFlag(FEATURE_FLAGS["vgsi-new-terms"]);
  const { data: internationalBankAccount } = useQuery(useInternationalAccountQuery());

  if (!isVgsiFlagEnabled) {
    return false;
  }
  if (internationalBankAccount?.termsAcceptedAt) {
    const acceptedDate = dayjs(internationalBankAccount.termsAcceptedAt);
    const today = dayjs();
    if (acceptedDate.isBefore(today)) {
      return false;
    }
  }

  return true;
};

export default useShouldReviewVgsiTerms;
