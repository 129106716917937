import { ComponentPropsWithoutRef, ComponentRef, forwardRef } from "react";
import cn from "utils/tailwind/cn";

// NB(alex): Will do `OrderedList` once it comes up again.

const UnorderedList = forwardRef<ComponentRef<"ul">, ComponentPropsWithoutRef<"ul">>(
  ({ children, className, ...props }, ref) => {
    return (
      <ul ref={ref} className={cn("list-inside list-disc space-y-2", className)} {...props}>
        {children}
      </ul>
    );
  }
);

const Item = forwardRef<ComponentRef<"li">, ComponentPropsWithoutRef<"li">>(
  ({ children, className, ...props }, ref) => {
    return (
      <li ref={ref} className={cn("ml-2", className)} {...props}>
        {children}
      </li>
    );
  }
);

const Content = forwardRef<ComponentRef<"span">, ComponentPropsWithoutRef<"span">>(
  ({ children, className, ...props }, ref) => {
    return (
      <span ref={ref} className={cn("inline-flex items-center gap-x-2", className)} {...props}>
        {children}
      </span>
    );
  }
);

export default Object.assign(UnorderedList, {
  Item: Item,
  Content: Content,
});
