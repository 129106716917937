import { FC } from "react";
import Divider from "ui/data-display/Divider";
import { Paragraph } from "ui/typography";

import AiTaskPillComponent from "../AiTaskPillComponent";

import BaseAiTaskModal, { BaseAiTaskModalProps } from "./BaseAiTaskModal";
import ModalDescriptionTable from "./ModalDescriptionTable";
import { ModalIcon } from "./ModalIcon";

type Props = Omit<BaseAiTaskModalProps, "variant">;

const LearnMoreModal: FC<Props> = ({
  isOpen,
  onClose,
  title,
  onConfirm,
  onCancel,
  taskDescription,
  task,
  className,
  children,
}) => {
  const config = {
    title: title || "Learn more",
    confirmButtonText: "Done",
    confirmButtonVariant: "primary" as const,
    confirmButtonClassName: "",
    cancelButtonText: "",
  };

  return (
    <BaseAiTaskModal
      isOpen={isOpen}
      onClose={onClose}
      title="Learn more"
      config={config}
      onConfirm={onConfirm}
      onCancel={onCancel}
      className={className}
      onConfirmClick={() => {}}
      task={task}
    >
      <div className="mb-6">
        <div className="mb-4 flex items-center gap-3">
          <ModalIcon taskType={task!.type} />
          {taskDescription && (
            <Paragraph className="text-base px-2 font-[500]">{taskDescription}</Paragraph>
          )}
          <AiTaskPillComponent status={task!.status} className="ml-auto" />
        </div>
        <Divider />
        <ModalDescriptionTable task={task!} />
      </div>
      {children}
    </BaseAiTaskModal>
  );
};

export default LearnMoreModal;
