import BusinessRep from "reps/BusinessRep";

export const NEW_BUSINESS_PLACEHOLDER_NAME = "New business";

type Params = {
  displayName: string | null;
  status: BusinessRep.Status;
};

const getBusinessSafeDisplayName = ({ displayName, status }: Params): string => {
  const parts = [displayName || NEW_BUSINESS_PLACEHOLDER_NAME];
  if (status === "PendingReview") {
    parts.push(displayName ? "(in review)" : "(verification in progress)");
  }
  return parts.join(" ");
};

export default getBusinessSafeDisplayName;
