import { CaretRight, SignOut } from "@phosphor-icons/react";
import env from "env";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useJwt from "modules/jwt/queries/useJwt";
import { FC, useCallback } from "react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
import BusinessRep from "reps/BusinessRep";
import businessGuidState from "state/auth/businessGuid";
import isSuperuseringState from "state/auth/isSuperusering";
import Pill from "ui/data-display/Pill";
import { Span } from "ui/typography";

import { useCommandPaletteContext } from "../context";

import Cmdk from "./cmdk";

const localStorageKey = import.meta.env.DEV
  ? `mostRecentMemberBusinessGuid-${env.AUTH0_CUSTOM_DOMAIN}` // Prevents overriding the value when switching environments.
  : "mostRecentMemberBusinessGuid";

const { persistAtom } = recoilPersist();
const mostRecentMemberBusinessGuidState = atom<string | null>({
  key: localStorageKey,
  default: null,
  effects: [persistAtom],
});

type Props = {
  business: BusinessRep.Complete;
};

const SuperuserCommandItem: FC<Props> = ({ business }) => {
  const { cleanup } = useCommandPaletteContext();

  const setIsSuperusering = useSetRecoilState(isSuperuseringState);
  const setBusinessGuid = useSetRecoilState(businessGuidState);
  const setMostRecentMemberBusinessGuid = useSetRecoilState(mostRecentMemberBusinessGuidState);

  const { businesses } = useJwt();
  const businessGuid = useBusinessGuid();

  const onSelect = useCallback(() => {
    setIsSuperusering(Boolean(business.guid));
    setBusinessGuid(business.guid);

    // Preserves the previous business guid to go back to when exiting superuser.
    if (businesses[businessGuid]) {
      setMostRecentMemberBusinessGuid(businessGuid);
    }

    // No need to refresh if already in current business.
    if (businessGuid === business.guid) {
      cleanup();
      return;
    }

    // When on the business disambiguator, we redirect to the home page.
    if (window.location.pathname.includes("/businesses")) {
      window.location.assign("/");
    } else {
      window.location.reload();
    }
  }, [
    business,
    businessGuid,
    businesses,
    setBusinessGuid,
    setIsSuperusering,
    setMostRecentMemberBusinessGuid,
    cleanup,
  ]);

  return (
    <Cmdk.Item
      onSelect={onSelect}
      data-copy-value={business.guid}
      data-shift-copy-value={business.unitCoCustomerId}
      value={business.guid}
    >
      <Span className="text-grey-600">Superuser</Span>
      <CaretRight size={16} weight="light" className="text-grey-400" />
      <Span className="flex flex-col gap-y-1">
        <Span className="flex items-center gap-x-2 font-medium text-grey-900">
          {business.name}

          <Pill color="grey-100">{business.status}</Pill>
        </Span>
        <Span className="flex items-center gap-x-2 py-1">
          <Pill color="grey-100">
            <>
              {business.guid} <strong className="ml-1 text-2xs">⌘C</strong>
            </>
          </Pill>
          {business.unitCoCustomerId && (
            <Pill color="grey-100">
              <>
                Unit ID: {business.unitCoCustomerId} <strong className="ml-1 text-2xs">⌘⇧C</strong>
              </>
            </Pill>
          )}
        </Span>
      </Span>
    </Cmdk.Item>
  );
};

const ExitSuperuserCommandItem: FC = () => {
  const { cleanup } = useCommandPaletteContext();

  const { businesses } = useJwt();

  const setIsSuperusering = useSetRecoilState(isSuperuseringState);
  const businessGuid = useBusinessGuid();
  const setBusinessGuid = useSetRecoilState(businessGuidState);
  const mostRecentMemberBusinessGuid = useRecoilValue(mostRecentMemberBusinessGuidState);

  const onSelect = useCallback(() => {
    setIsSuperusering(false);

    // No need to restore the business guid if already superused into that business.
    if (mostRecentMemberBusinessGuid === businessGuid) {
      cleanup();
      return;
    }

    // Restore previous business guid. If we fall back to "", we end up at the business disambiguator.
    const memberBusinesses = Object.keys(businesses);
    setBusinessGuid(
      mostRecentMemberBusinessGuid && memberBusinesses.includes(mostRecentMemberBusinessGuid)
        ? mostRecentMemberBusinessGuid
        : (memberBusinesses[0] ?? "")
    );

    cleanup();
    window.location.reload();
  }, [
    mostRecentMemberBusinessGuid,
    businessGuid,
    setBusinessGuid,
    setIsSuperusering,
    cleanup,
    businesses,
  ]);

  return (
    <Cmdk.Item onSelect={onSelect}>
      <Span className="text-grey-600">Superuser</Span>
      <CaretRight size={16} weight="light" className="text-grey-400" />
      <Span className="font-medium text-grey-900">Exit superuser</Span>
      <SignOut size={20} className="ml-auto text-grey-400" />
    </Cmdk.Item>
  );
};

export { ExitSuperuserCommandItem };

export default SuperuserCommandItem;
