import { FEATURE_FLAGS } from "flags";
import { useRecoilValue } from "recoil";
import featureFlagsState from "state/featureFlags";
import { ValueOf } from "type-fest";

const useFeatureFlag = (flagKey: ValueOf<typeof FEATURE_FLAGS>) => {
  const featureFlags = useRecoilValue(featureFlagsState);
  return featureFlags[flagKey];
};

export default useFeatureFlag;
