import { FC } from "react";
import ButtonLink from "ui/inputs/ButtonLink";
import ItemWithCopyTextTooltip from "ui/overlay/ItemWithCopyTextTooltip";
import { Span } from "ui/typography";

import SuperuserChannelRep from "./SuperuserChannelRep";

type Props = {
  channel: SuperuserChannelRep;
};

const SuperuserChannelChoice: FC<Props> = ({ channel }) => {
  return (
    <div className="flex flex-row gap-2">
      <ButtonLink to={`/ai/${channel.id}`} key={channel.id} className="whitespace-pre-wrap text-sm">
        {channel.name}
      </ButtonLink>
      <ItemWithCopyTextTooltip textToCopy={channel.id}>
        <Span className="select-none text-sm">{channel.id}</Span>
      </ItemWithCopyTextTooltip>
    </div>
  );
};

export default SuperuserChannelChoice;
