import RutterConnectionRep from "reps/RutterConnectionRep";

export const isRutterCommercePlatform = (
  platform: string
): platform is RutterConnectionRep.RutterCommercePlatform =>
  platform === "SHOPIFY" || platform === "AMAZON";

export const isRutterAccountingPlatform = (
  platform: string
): platform is RutterConnectionRep.RutterAccountingPlatform => {
  return RutterConnectionRep.rutterAccountingPlatforms.includes(
    platform as RutterConnectionRep.RutterAccountingPlatform
  );
};

export const isRutterPaymentProcessor = (
  platform: string
): platform is RutterConnectionRep.RutterPaymentProcessor =>
  platform === "STRIPE" || platform === "PAYPAL";

export type RutterCommercePlatformOrPaymentProcessorComplete =
  | RutterConnectionRep.RutterCommercePlatformComplete
  | RutterConnectionRep.RutterPaymentProcessorComplete;

export type RutterCommercePlatformOrPaymentProcessorPlatformName =
  RutterCommercePlatformOrPaymentProcessorComplete["platformName"];

export const isRutterCommercePlatformOrPaymentProcessorPlatformName = (
  platform: RutterConnectionRep.RutterPlatform
): platform is RutterCommercePlatformOrPaymentProcessorPlatformName => {
  return isRutterCommercePlatform(platform) || isRutterPaymentProcessor(platform);
};

export const isRutterCommercePlatformOrPaymentProcessorComplete = (
  connection: RutterConnectionRep.Complete
): connection is RutterCommercePlatformOrPaymentProcessorComplete => {
  return isRutterCommercePlatformOrPaymentProcessorPlatformName(connection.platformName);
};
