import { AccountEndOfDay } from "@highbeam/unit-node-sdk";
import { DetailedAreaChartDatum } from "ui/data-visualization/DetailedAreaChart";
import { startOfBankingDay } from "utils/date";
import { DEFAULT_RELATIVE_TIMEFRAME, timeframeToNumDays } from "utils/timeframe";

import formatDateForXAxis from "./formatDateForXAxis";

const mergeHistories = (histories: AccountEndOfDay[][]): DetailedAreaChartDatum[] => {
  const dayBalanceMap: Map<string, number> = new Map();

  histories.forEach((history) => {
    history.forEach((accountEndOfDay) => {
      const { date, available } = accountEndOfDay.attributes;
      const existingDayBalance = dayBalanceMap.get(date);
      if (existingDayBalance) {
        dayBalanceMap.set(date, existingDayBalance + available);
      } else {
        dayBalanceMap.set(date, available);
      }
    });
  });

  return (
    Array.from(dayBalanceMap.entries())
      .map(([date, amount]) => ({
        date: formatDateForXAxis(date),
        amount,
        referenceDateInstance: startOfBankingDay(date),
      }))
      // Sort chronologically.
      .sort((a, b) => a.referenceDateInstance.diff(b.referenceDateInstance))
  );
};

const getHistoryWithTodayForMultipleAccounts = (
  histories: AccountEndOfDay[][],
  bankAccountsTotalBalance: number,
  timeframe = DEFAULT_RELATIVE_TIMEFRAME
) => {
  const history = mergeHistories(histories);
  if (history.length === 0) {
    history.push({
      date: formatDateForXAxis(startOfBankingDay()),
      amount: 0,
      referenceDateInstance: startOfBankingDay(),
    });
  }
  const numPaddedDays = Math.max(timeframeToNumDays(timeframe) - history.length - 1, 0);
  const paddedHistory = [
    ...[...Array(numPaddedDays)].map((_, i) => {
      const referenceDateInstance = history[0].referenceDateInstance.subtract(
        numPaddedDays - i,
        "d"
      );
      return {
        date: formatDateForXAxis(referenceDateInstance),
        amount: 0,
        referenceDateInstance,
      };
    }),
    ...history,
  ];
  const endReferenceDateInstance = history[history.length - 1].referenceDateInstance.add(1, "d");
  const historyWithToday = [
    ...paddedHistory,
    {
      date: formatDateForXAxis(endReferenceDateInstance),
      amount: bankAccountsTotalBalance,
      referenceDateInstance: endReferenceDateInstance,
    },
  ];
  return historyWithToday;
};

export default getHistoryWithTodayForMultipleAccounts;
