import CapitalAccountCard, {
  EmptyCapitalAccountCard,
} from "modules/capital-accounts/components/CapitalAccountCard";
import { NonTerminatedCapitalAccount } from "modules/capital-accounts/hooks/useNonTerminatedCapitalAccounts";
import { TerminatedCapitalAccount } from "modules/capital-accounts/hooks/useTerminatedCapitalAccounts";
import { Link } from "react-router-dom";

type Props = {
  accounts: TerminatedCapitalAccount[] | NonTerminatedCapitalAccount[];
};

const CapitalAccountsList = ({ accounts }: Props) => {
  return (
    <div className="grid gap-4 @3xl:grid-cols-2 @5xl:grid-cols-3">
      {accounts.map((capitalAccount) => (
        <Link key={capitalAccount.guid} to={`/capital/${capitalAccount.guid}`}>
          <CapitalAccountCard capitalAccountGuid={capitalAccount.guid} />
        </Link>
      ))}

      <EmptyCapitalAccountCard />
    </div>
  );
};

export default CapitalAccountsList;
