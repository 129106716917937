import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";

import useNormalizedAccountApi, {
  SearchNormalizedAccountQueryParams,
} from "../api/useNormalizedAccountApi";

const ROOT_NORMALIZED_ACCOUNTS_QUERY_KEY = "normalizedAccounts--root";

const normalizedAccountsQueryHooks = makeQueryHooksV2({
  useQueryFnParams: () => {
    const normalizedAccountApi = useNormalizedAccountApi();
    return { normalizedAccountApi };
  },
  useDefaultParams: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  makeQueryKey: (params: SearchNormalizedAccountQueryParams) => {
    return [ROOT_NORMALIZED_ACCOUNTS_QUERY_KEY, "normalizedAccounts", params];
  },
  makeQueryFn: ({ normalizedAccountApi }, params) => {
    return () => normalizedAccountApi.search(params);
  },
});

export { ROOT_NORMALIZED_ACCOUNTS_QUERY_KEY, normalizedAccountsQueryHooks };
