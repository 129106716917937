import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import makeQueryHooksV2, {
  ExtractParamsWithOptionalDefaults,
} from "utils/react-query/makeQueryHooksV2";

import useAccountingClassCodeApi from "../api/useAccountingClassCodeApi";

const ROOT_QUERY_KEY = "accounting-class-codes";

type Params = {
  businessGuid: string;
};

const accountingClassCodesQueryHooks = makeQueryHooksV2({
  useDefaultParams: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  makeQueryKey: (params: Params) => {
    return [ROOT_QUERY_KEY, params] as const;
  },
  useQueryFnParams: () => {
    const accountingClassCodeApi = useAccountingClassCodeApi();
    return { accountingClassCodeApi };
  },
  makeQueryFn: ({ accountingClassCodeApi }, { businessGuid }) => {
    return () => accountingClassCodeApi.search(businessGuid);
  },
});

type ParamsWithOptionalDefaults = ExtractParamsWithOptionalDefaults<
  typeof accountingClassCodesQueryHooks
>;

type HookParams = {
  params?: ParamsWithOptionalDefaults;
};

const useAccountingClassCodes = (hookParams: HookParams = {}) => {
  const { params = {} } = hookParams;
  return accountingClassCodesQueryHooks.useData({ params });
};

export default accountingClassCodesQueryHooks;
export { useAccountingClassCodes };
