import { CaretRight as CaretRightIcon, CaretLeft as CaretLeftIcon } from "@phosphor-icons/react";
import { FC } from "react";
import { Link, LinkProps } from "react-router-dom";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

type PageLinkProps = LinkProps & {
  isActive?: boolean;
};

const PageLink: FC<PageLinkProps> = ({ children, className, isActive, ...props }) => (
  <Link
    aria-current={isActive ? "page" : undefined}
    className={cn(
      "flex size-8 items-center justify-center rounded-md p-2 text-sm text-grey-500",
      !isActive && "hover:bg-grey-100",
      isActive && "cursor-default bg-grey-900 text-white",
      className
    )}
    {...props}
  >
    {children}
  </Link>
);

type PreviousPageLinkProps = Omit<LinkProps, "children">;

const PreviousPageLink: FC<PreviousPageLinkProps> = (props) => (
  <PageLink aria-label="Previous page" {...props}>
    <CaretLeftIcon className="size-4" />
  </PageLink>
);

type NextPageLinkProps = Omit<LinkProps, "children">;

const NextPageLink: FC<NextPageLinkProps> = (props) => (
  <PageLink aria-label="Next page" {...props}>
    <CaretRightIcon className="size-4" />
  </PageLink>
);

const Ellipsis: FC = () => (
  <span aria-hidden className="flex size-4 items-center justify-center text-sm text-grey-500">
    ..
  </span>
);

type Props = PropsWithChildrenAndClassName;

const Pagination: FC<Props> = ({ children, className }) => (
  <nav
    role="navigation"
    aria-label="Pagination"
    className={cn("ml-auto flex items-center gap-3", className)}
  >
    {children}
  </nav>
);

export default Object.assign(Pagination, {
  PageLink: PageLink,
  NextPageLink: NextPageLink,
  PreviousPageLink: PreviousPageLink,
  Ellipsis: Ellipsis,
});
