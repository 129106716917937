import { Parser } from "@json2csv/plainjs";
import { SpanChatElementRep, TableChatElementRep } from "modules/chat/reps/ChatElementRep";
import { useCallback } from "react";
import { downloadString } from "utils/download";

const getValueForSpanChatElement = (element: SpanChatElementRep): string | number => {
  switch (element.type) {
    case "Action":
      return element.text;
    case "Date":
      return element.value;
    case "Link":
      return element.text;
    case "Money":
      return element.amount;
    case "Month":
      return element.value;
    case "Number":
      return element.value;
    case "Percentage":
      return element.value;
    case "Text":
      return element.content;
    case "Timestamp":
      return element.value;
    case "Year":
      return element.value;
    case "YearMonth":
      return element.value;
  }
};

const convertTableChatElementToCsv = (element: TableChatElementRep) => {
  const headers = element.columns.map((column) => column.header);
  const parser = new Parser({
    fields: headers,
    transforms: [
      (spanElements: SpanChatElementRep[]) => {
        const headerToValue = headers.map((header, index) => {
          const value = getValueForSpanChatElement(spanElements[index]);
          return [header, value];
        });
        return Object.fromEntries(headerToValue);
      },
    ],
  });
  return parser.parse(element.values);
};

const downloadTableChatElementAsCsv = (element: TableChatElementRep, csvName: string) => {
  const csv = convertTableChatElementToCsv(element);
  downloadString(csvName, csv, "text/csv");
};

const useDownloadTableChatElementAsCsv = () => {
  return useCallback(downloadTableChatElementAsCsv, []);
};

export { convertTableChatElementToCsv };

export default useDownloadTableChatElementAsCsv;
