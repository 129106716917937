import { captureException, ErrorBoundary } from "@sentry/react";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import DevTools from "components/DevTools";
import { CommandPalette } from "modules/command-palette";
import ErrorPage from "modules/error/pages/ErrorPage";
import LoadingPage from "modules/loading/pages/LoadingPage";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import RootRouter from "routes/RootRouter";
import { ToastContainer } from "ui/feedback/Toast";
import ScrollToTopOnNavigate from "ui/navigation/ScrollToTopOnNavigate";
import { TooltipProvider } from "ui/overlay/Tooltip";
import { ApiError } from "utils/ajax";
import { EventBusProvider } from "utils/event-bus";

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      // ApiErrors are captured in HighbeamBaseApi.ts
      if (!(error instanceof ApiError)) {
        captureException(error);
      }
    },
  }),
});

const App = () => (
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <EventBusProvider>
          <TooltipProvider>
            <ErrorBoundary fallback={({ error }) => <ErrorPage error={error} />}>
              <Suspense fallback={<LoadingPage location={App.name} />}>
                <ScrollToTopOnNavigate />
                <RootRouter />
                <ToastContainer />
                <CommandPalette />
              </Suspense>
            </ErrorBoundary>
          </TooltipProvider>
        </EventBusProvider>
      </BrowserRouter>

      <DevTools />
    </QueryClientProvider>
  </RecoilRoot>
);

export default App;
