import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";
import useRefreshQuery from "utils/react-query/useRefreshQuery";
import variants from "utils/ts/variants";

import useActionItemApi from "../api/useActionItemApi";
import { ActionItemMilestone } from "../reps/ActionItemRep";

const ROOT_ACTION_ITEMS_QUERY_KEY = "actionItems";

const useRefreshAllActionItemsQueries = () => {
  return useRefreshQuery([ROOT_ACTION_ITEMS_QUERY_KEY]);
};

type Params = {
  milestone: ActionItemMilestone;
  // NB(alex): hack to deal with the ambiguious guid based on the milestone.
  ownerGuid?: string;
  userGuid: string;
  businessGuid: string;
};

const actionItemsQueryHooks = makeQueryHooksV2({
  useQueryFnParams: () => {
    const actionItemApi = useActionItemApi();
    return { actionItemApi };
  },
  useDefaultParams: () => {
    const userGuid = useUserGuid();
    const businessGuid = useBusinessGuid();
    return {
      userGuid: userGuid,
      businessGuid: businessGuid,
    };
  },
  makeQueryKey: (params: Params) => {
    // NB(alex): For now we just pass everything into the query key but technically we don't have to include `userGuid` and `businessGuid`.
    return [ROOT_ACTION_ITEMS_QUERY_KEY, "actionItems", params];
  },
  makeQueryFn: ({ actionItemApi }, params) => {
    // Use the specified `ownerGuid` if passed in, otherwise determine the value by milestone.
    const ownerGuid =
      params.ownerGuid ??
      variants(params.milestone, {
        Signup: params.userGuid,
        GetStarted: params.businessGuid,
        FundYourAccount: params.businessGuid,
        Onboarding: params.businessGuid,
      });
    return () => {
      return actionItemApi.getByMilestoneName(ownerGuid, params.milestone);
    };
  },
});

export { ROOT_ACTION_ITEMS_QUERY_KEY, actionItemsQueryHooks, useRefreshAllActionItemsQueries };
