import { ApiBuilder, useBackendV1Api } from "modules/highbeam-api/base";
import BusinessMemberRep from "reps/BusinessMemberRep";

type BusinessMemberApi = {
  getByBusiness: (businessGuid: string) => Promise<BusinessMemberRep.Complete[]>;
  getByMember: (
    businessGuid: string,
    userGuid: string
  ) => Promise<BusinessMemberRep.Complete | null>;
  update: (
    businessGuid: string,
    memberGuid: string,
    rep: BusinessMemberRep.Updater
  ) => Promise<BusinessMemberRep.Complete>;
  delete: (businessGuid: string, memberGuid: string) => Promise<BusinessMemberRep.Complete>;
};

const builder: ApiBuilder<BusinessMemberApi> = (api) => ({
  getByBusiness: async (businessGuid) => {
    const url = `/businesses/${businessGuid}/members`;
    return (await api.get<BusinessMemberRep.Complete[]>(url))!;
  },
  getByMember: async (businessGuid, userGuid) => {
    const qp = new URLSearchParams({ userGuid });
    const url = `/businesses/${businessGuid}/members?${qp}`;
    return api.get<BusinessMemberRep.Complete>(url);
  },
  update: async (businessGuid, memberGuid, rep) => {
    const url = `/businesses/${businessGuid}/members/${memberGuid}`;
    return (await api.patch<BusinessMemberRep.Complete>(url, rep))!;
  },
  delete: async (businessGuid, memberGuid) => {
    const url = `/businesses/${businessGuid}/members/${memberGuid}`;
    return (await api.delete<BusinessMemberRep.Complete>(url))!;
  },
});

const useBusinessMemberApi = () => useBackendV1Api(builder);

export default useBusinessMemberApi;
