import useChannelApi from "modules/chat/api/useChannelApi";
import { ChannelRep } from "modules/chat/reps/ChannelRep";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import makeQueryHooksV2, {
  ExtractParamsWithOptionalDefaults,
} from "utils/react-query/makeQueryHooksV2";

type Params = {
  businessGuid: string;
  chatUserId: string;
};

const chatChannelsQueryHooks = makeQueryHooksV2({
  useDefaultParams: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  makeQueryKey: (params: Params) => {
    return ["chatChannels", params] as const;
  },
  useQueryFnParams: () => {
    const channelApi = useChannelApi();
    return { channelApi };
  },
  makeQueryFn: ({ channelApi }, { businessGuid, chatUserId }) => {
    return () => {
      return channelApi.search(businessGuid, chatUserId);
    };
  },
});

type ParamsWithOptionalDefaults = ExtractParamsWithOptionalDefaults<typeof chatChannelsQueryHooks>;

const useChatChannels = (params: ParamsWithOptionalDefaults) => {
  return chatChannelsQueryHooks.useData({ params });
};

const getMvpFirstChatChannel = (chatChannels: ChannelRep[]) => {
  return chatChannels[0];
};

const useMvpFirstChatChannel = (params: ParamsWithOptionalDefaults) => {
  const chatChannels = useChatChannels(params);
  return getMvpFirstChatChannel(chatChannels);
};

export { chatChannelsQueryHooks, getMvpFirstChatChannel, useChatChannels, useMvpFirstChatChannel };
