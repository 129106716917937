import { FC } from "react";
import Banner from "ui/data-display/Banner";
import InfoIcon from "ui/icons/InfoIcon";
import ButtonLink from "ui/inputs/ButtonLink";

import { useHasInactivePlaidBankAccounts } from "../queries/usePlaidAccounts";

export const useShouldShowUpdatePlaidConnectionBanner = () => {
  return useHasInactivePlaidBankAccounts();
};

type Props = {
  className?: string;
};

const UpdatePlaidConnectionBanner: FC<Props> = ({ className }) => {
  return (
    <Banner
      className={className}
      color="white"
      icon={<InfoIcon size={24} variant="info" />}
      title="Some accounts need to be reconnected"
      paragraph={
        "You need to reconnect one or more of your connected accounts in order to use them with Highbeam again."
      }
      button={
        <ButtonLink variant="tertiary" size="sm" to="/settings/banks-and-cards">
          Go to settings
        </ButtonLink>
      }
    />
  );
};

export default UpdatePlaidConnectionBanner;
