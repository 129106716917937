import { ReactNode } from "react";
import Listbox from "ui/inputs/Listbox";

type Props<T extends string> = {
  value: T;
  onChange: (newValue: T) => void;
  renderTrigger: (props: { value: T }) => ReactNode;
  children: ReactNode;
};

const BalanceHistoryTotalBalanceListbox = <T extends string>({
  value,
  onChange,
  children,
  renderTrigger,
}: Props<T>) => {
  return (
    <Listbox
      variant="minimal"
      ghost
      value={value}
      onValueChange={(newValue) => onChange(newValue as T)}
    >
      <Listbox.Field>
        <Listbox.Label className="text-md font-bold">Balance history</Listbox.Label>
        <Listbox.Trigger className="-mx-4 flex w-auto px-4 text-md font-bold">
          {renderTrigger({ value })}
        </Listbox.Trigger>
      </Listbox.Field>
      <Listbox.Menu>{children}</Listbox.Menu>
    </Listbox>
  );
};

type BalanceHistoryTotalBalanceListboxItemProps<T extends string> = {
  children: ReactNode;
  value: T;
};

const BalanceHistoryTotalBalanceListboxItem = <T extends string>({
  children,
  value,
}: BalanceHistoryTotalBalanceListboxItemProps<T>) => {
  return <Listbox.Item value={value}>{children}</Listbox.Item>;
};

export default Object.assign(BalanceHistoryTotalBalanceListbox, {
  Item: BalanceHistoryTotalBalanceListboxItem,
});
