import {
  CaretLeft,
  CaretRight,
  Copy,
  DownloadSimple,
  MinusCircle,
  PlusCircle,
} from "@phosphor-icons/react";
import dayjs from "dayjs";
import { snakeCase } from "lodash-es";
import useDownloadTableChatElementAsCsv, {
  convertTableChatElementToCsv,
} from "modules/chat/hook/useDownloadTableChatElementAsCsv";
import { ParagraphChatElementRep, TableChatElementRep } from "modules/chat/reps/ChatElementRep";
import React, { useMemo, useRef, useState } from "react";
import EmptyState from "ui/data-display/EmptyState";
import ScrollWithInsetShadow from "ui/data-display/ScrollWithInsetShadow";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import NarrowTable from "ui/table/NarrowTable";
import { Column } from "ui/table/Table";
import { Heading4 } from "ui/typography";
import { copyToClipboard } from "utils/browser/useCopyToClipboard";
import useMountEffect from "utils/customHooks/useMountEffect";
import csvToTsv from "utils/string/csv-to-tsv";
import cn from "utils/tailwind/cn";

import ParagraphChatElement from "./ParagraphChatElement";

import ChatElement from ".";

/**
 * Page sizes: This component uses "singlePageSize" as a ceiling for how many results to show on a single page.
 *             If the number of results exceeds this number, "pageSize" will be used to delineate multiple pages.
 *             This provides the advantage of allowing slightly longer tables when they all fit on one page,
 *             which is desirable for ensuring a full year fits on a single page,
 *             and also desirable for avoiding tables where the 2nd page only has a single result.
 */
type Props = {
  element: TableChatElementRep;
  singlePageSize?: number;
  pageSize?: number;
};

const TableChatElement: React.FC<Props> = ({ element, singlePageSize = 13, pageSize = 10 }) => {
  const columns = useMemo(() => {
    return element.columns.map((column, i) => {
      return {
        title: column.header,
        cellRender: (row) => {
          const value = row[i];
          return <ParagraphChatElement element={value} />;
        },
      } satisfies Column<ParagraphChatElementRep[]>;
    });
  }, [element.columns]);

  const data = element.values;
  const fitsOnOnePage = data.length <= singlePageSize;
  const [page, setPage] = useState(0);

  const pageData = useMemo(() => {
    if (fitsOnOnePage) return data;
    return data.slice(page * pageSize, (page + 1) * pageSize);
  }, [data, fitsOnOnePage, page, pageSize]);

  const downloadTableChatElementAsCsv = useDownloadTableChatElementAsCsv();

  const [_expanded, setExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState(0);
  const showExpandedButton = containerHeight > 384; // max-h-96
  const expanded = _expanded && showExpandedButton;

  useMountEffect(() => {
    setContainerHeight(containerRef.current?.scrollHeight ?? 0);
  });

  return (
    <div
      className={cn(
        "relative w-full overflow-hidden rounded-2xl border border-grey-200",
        !expanded && "max-h-96"
      )}
      ref={containerRef}
    >
      <div className="flex items-center justify-between px-5 pb-4 pt-4">
        <div>
          <Heading4 className="font-medium">{element.name}</Heading4>
        </div>

        <div className="flex items-center gap-x-2">
          <ButtonWithTooltip
            paddingVariant="square"
            variant="ghost"
            size="sm"
            onClick={() => {
              const csv = convertTableChatElementToCsv(element);
              copyToClipboard(csvToTsv(csv));
              notify("success", "Copied table");
            }}
            tooltip="Copy as table"
          >
            <Copy size={16} weight="light" />
          </ButtonWithTooltip>

          <ButtonWithTooltip
            paddingVariant="square"
            variant="ghost"
            size="sm"
            onClick={() =>
              downloadTableChatElementAsCsv(
                element,
                `highbeam_ai-${dayjs().format("YYYY_MM_DD")}-${snakeCase(element.name)}`
              )
            }
            tooltip="Save as CSV"
          >
            <DownloadSimple size={16} weight="light" />
          </ButtonWithTooltip>
        </div>
      </div>

      <ScrollWithInsetShadow orientation="horizontal" className="border-t border-grey-100">
        <NarrowTable>
          <NarrowTable.Head>
            <NarrowTable.Row>
              {columns.map((column, i) => (
                <NarrowTable.HeadCell key={i}>{column.title}</NarrowTable.HeadCell>
              ))}
            </NarrowTable.Row>
          </NarrowTable.Head>

          <NarrowTable.Body>
            {pageData.length > 0 ? (
              <>
                {pageData.map((row, i) => (
                  <NarrowTable.Row key={i}>
                    {row.map((cell, j) => (
                      <NarrowTable.Cell key={j}>{<ChatElement element={cell} />}</NarrowTable.Cell>
                    ))}
                  </NarrowTable.Row>
                ))}
              </>
            ) : (
              <div className="px-12 py-10">
                <EmptyState body={<EmptyState.PrimaryText>No results</EmptyState.PrimaryText>} />
              </div>
            )}
          </NarrowTable.Body>
        </NarrowTable>
      </ScrollWithInsetShadow>

      {fitsOnOnePage && !showExpandedButton && (
        // Hack in some extra padding if there aren't very many results.
        <div className="h-1" />
      )}

      {fitsOnOnePage ? (
        <div className="h-1" />
      ) : (
        <div className="flex items-center justify-end gap-2 px-6 pb-4 pt-4">
          <div className="mr-2 text-sm font-medium text-grey-500">
            {page * pageSize + 1} - {Math.min(page * pageSize + pageSize, data.length)} of{" "}
            {data.length}
          </div>

          <Button
            variant="ghost"
            size="sm"
            paddingVariant="bare"
            onClick={() => setPage(page - 1)}
            disabled={page === 0}
            className="data-[disabled=true]:opacity-20"
          >
            <CaretLeft size={16} />
          </Button>

          <Button
            variant="ghost"
            size="sm"
            paddingVariant="bare"
            onClick={() => setPage(page + 1)}
            disabled={page >= Math.ceil(data.length / pageSize) - 1}
            className="data-[disabled=true]:opacity-20"
          >
            <CaretRight size={16} />
          </Button>
        </div>
      )}

      {showExpandedButton && (
        <div
          className={cn(
            "pointer-events-none flex justify-center",
            expanded
              ? "pb-8"
              : "absolute inset-x-0 bottom-0 h-48 bg-gradient-to-b from-transparent to-white",
            fitsOnOnePage && "pt-4"
          )}
        >
          <Button
            variant="tertiary"
            size="sm"
            onClick={() => setExpanded((prev) => !prev)}
            className={cn(
              "pointer-events-auto mx-0 w-fit rounded-full",
              !expanded && "absolute bottom-8"
            )}
          >
            {expanded ? (
              <>
                <MinusCircle size={16} /> See less
              </>
            ) : (
              <>
                <PlusCircle size={16} /> See more
              </>
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

export default TableChatElement;
