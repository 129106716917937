import dayjs, { Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import duration from "dayjs/plugin/duration";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(quarterOfYear);

type BankingDayOpts = {
  format?: dayjs.OptionType;
  keepLocalTime?: boolean;
};

export const bankingDay = (date?: dayjs.ConfigType, opts: BankingDayOpts = {}): Dayjs =>
  dayjs(date, opts.format).tz("America/New_York", opts.keepLocalTime);

export const startOfBankingDay = (date?: dayjs.ConfigType, opts: BankingDayOpts = {}): Dayjs =>
  bankingDay(date, opts).startOf("day");

export const startOfYear = (date?: dayjs.ConfigType, opts: BankingDayOpts = {}): Dayjs =>
  bankingDay(date, opts).startOf("year");

export const startOfQuarter = (date?: dayjs.ConfigType, opts: BankingDayOpts = {}): Dayjs =>
  bankingDay(date, opts).startOf("quarter");

export const dateOnly = (date?: dayjs.ConfigType): Dayjs => dayjs(date).tz(undefined, true);

export const formatDate = (date: dayjs.ConfigType, template = "MMM D, YYYY") =>
  dayjs(date).format(template);

export const API_LOCAL_DATE_FORMAT = "YYYY-MM-DD";
export const API_LOCAL_MONTH_FORMAT = "YYYY-MM";

export type FormatBankingDateOptions = {
  showYear?: boolean | "if-not-current-year";
};

export const formatBankingDate = (date: dayjs.ConfigType, opts?: FormatBankingDateOptions) => {
  const { showYear = true } = opts ?? {};
  if (showYear === "if-not-current-year") {
    if (dayjs().isSame(dayjs(date), "year")) {
      return dayjs(date).tz("America/New_York").format("MMM D");
    }
    return dayjs(date).tz("America/New_York").format("MMM D, YYYY");
  }
  return showYear
    ? dayjs(date).tz("America/New_York").format("MMM D, YYYY")
    : dayjs(date).tz("America/New_York").format("MMM D");
};

export const formatEventTime = (date: dayjs.ConfigType) =>
  dayjs(date).format("MMM D, YYYY [at] h:mm A z");

export const getDurationFromPeriod = (period: string): plugin.Duration => {
  return dayjs.duration(period);
};

export const getMsFromDays = (days: number): number => {
  return dayjs.duration(days, "days").asMilliseconds();
};

export const getDaysFromMs = (ms: number): number => {
  return dayjs.duration(ms, "milliseconds").asDays();
};

export const getUsersLocalTimezoneAbbreviation = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = { timeZoneName: "short" };
  const dateString = new Intl.DateTimeFormat("en-US", options).format(date);
  const match = dateString.match(/\b[A-Z]{3,5}\b/);
  return match ? match[0] : "";
};

export const timeAgo = (date: dayjs.ConfigType) => {
  const target = dayjs(date);
  const now = dayjs();

  if (now.diff(target, "week") >= 1) {
    // Don't display the year if the target date is in the current year.
    return target.year() === now.year()
      ? formatDate(target, "MMM D")
      : formatDate(target, "MMM D, YYYY");
  }

  return target.fromNow();
};
