import { NumberChatElementRep } from "modules/chat/reps/ChatElementRep";
import React from "react";
import { formatNumber } from "utils/numbers";

type Props = {
  element: NumberChatElementRep;
};

const NumberChatElement: React.FC<Props> = ({ element }) => {
  return (
    <span>
      {formatNumber(element.value, {
        minimumFractionDigits: element.decimalPlaces,
        maximumFractionDigits: element.decimalPlaces,
      })}
    </span>
  );
};

export default NumberChatElement;
