import { Check, PlusCircle } from "@phosphor-icons/react";
import { SIGN_OUT } from "modules/auth/constants";
import getBusinessSafeDisplayName from "modules/business/utils/getBusinessSafeDisplayName";
import useShouldShowAddNewBusiness from "modules/businesses/hooks/useShouldShowAddNewBusiness";
import businessSummariesByMemberUserQueryHooks, {
  filterActiveAndPendingBusinessSummaries,
} from "modules/businesses/queries/businessSummariesByMemberUserQueryHooks";
import checkInsightsAppEnabled from "modules/insights-app/checkInsightsAppEnabled";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import useCanSuperuser from "modules/superuser/hooks/useCanSuperuser";
import { useRef, ComponentPropsWithoutRef, FC } from "react";
import { useSetRecoilState } from "recoil";
import BusinessMemberSummaryRep from "reps/BusinessMemberSummaryRep";
import businessGuidState from "state/auth/businessGuid";
import isBusinessSwitcherOpenState from "state/superuser/isBusinessSwitcherOpen";
import { Span } from "ui/typography";
import useOnClickOutside from "use-onclickoutside";
import useDocumentKeyboardEvent from "utils/browser/useDocumentKeyboardEvent";
import cn from "utils/tailwind/cn";

import { useSignOut } from "../SignOutButton";

import UserAvatarMenuDropdownHeader from "./UserAvatarMenuDropdownHeader";

type MenuButtonProps = ComponentPropsWithoutRef<"button"> & {
  borderTop?: boolean;
};

const MenuButton: FC<MenuButtonProps> = ({ children, className, borderTop, ...props }) => {
  return (
    <button
      className={cn(
        "flex items-center justify-between gap-x-2 p-3 text-left text-sm font-medium hover:bg-grey-50",
        borderTop && "border-t border-t-grey-100",
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};

type BusinessButtonProps = {
  businessSummary: BusinessMemberSummaryRep.Complete;
  className?: string;
};

const BusinessButton: FC<BusinessButtonProps> = ({ businessSummary, className }) => {
  const businessGuid = useBusinessGuid();
  const isSelected = businessSummary.businessGuid === businessGuid;
  const setBusinessGuid = useSetRecoilState(businessGuidState);

  const onClick = () => {
    setBusinessGuid(businessSummary.businessGuid);
    window.location.href = "/"; // Intended to force a refresh.
  };

  const needsToFinishOnboarding =
    !businessSummary.userIsOnboarded && businessSummary.businessStatus === "Active";

  return (
    <MenuButton onClick={onClick} className={cn(isSelected && "text-purple-500", className)}>
      {getBusinessSafeDisplayName({
        displayName: businessSummary.businessDisplayName,
        status: businessSummary.businessStatus,
      })}

      {needsToFinishOnboarding && (
        <Span className={cn("whitespace-nowrap", isSelected && "text-purple-500")}>
          Finish onboarding
        </Span>
      )}
      {!needsToFinishOnboarding && isSelected && <Check size={16} />}
    </MenuButton>
  );
};

const BusinessSwitcherButton = () => {
  const setIsBusinessSwitcherOpen = useSetRecoilState(isBusinessSwitcherOpenState);

  return (
    <MenuButton onClick={() => setIsBusinessSwitcherOpen(true)}>
      [SUPERUSER] Business switcher
    </MenuButton>
  );
};

const SignOutButton = () => {
  const signOut = useSignOut();

  return (
    <MenuButton borderTop onClick={signOut} className="text-red-600">
      {SIGN_OUT}
    </MenuButton>
  );
};

type Props = {
  onCloseUserMenu: () => void;
  onClickAddNewBusiness: () => void;
  className?: string;
};

const UserAvatarMenuDropdown: FC<Props> = ({
  onCloseUserMenu,
  onClickAddNewBusiness,
  className,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, onCloseUserMenu);
  useDocumentKeyboardEvent((e) => {
    if (e.key === "Escape") {
      onCloseUserMenu();
    }
  }, []);

  const canSuperuser = useCanSuperuser();

  const userGuid = useUserGuid();
  const { data: businessSummaries = [] } = businessSummariesByMemberUserQueryHooks.useQuery({
    userGuid,
    select: filterActiveAndPendingBusinessSummaries,
  });

  const addBusinessButtonIsEnabled = useShouldShowAddNewBusiness(businessSummaries);
  const isInsightsApp = checkInsightsAppEnabled();

  return (
    <div
      className={cn(
        "absolute top-0 z-10 flex flex-col border-grey-200 bg-white shadow-sm",
        "left-0 right-0 rounded-b-md",
        "tablet:-right-4 tablet:-top-[15px] tablet:left-auto tablet:w-96 tablet:rounded-md",
        "overflow-hidden", // Hides overflow from rounded borders.
        className
      )}
      ref={ref}
    >
      <UserAvatarMenuDropdownHeader onClickAvatar={onCloseUserMenu} />

      {!isInsightsApp && (
        <>
          <div className="bg-grey-50 px-3 py-1.5">
            <Span className="text-xs font-medium text-grey-500">Business</Span>
          </div>
          {businessSummaries.map((businessSummary) => (
            <BusinessButton key={businessSummary.businessGuid} businessSummary={businessSummary} />
          ))}
        </>
      )}
      {canSuperuser && <BusinessSwitcherButton />}
      {addBusinessButtonIsEnabled && (
        <MenuButton borderTop onClick={onClickAddNewBusiness}>
          Add new business
          <PlusCircle size={16} />
        </MenuButton>
      )}
      <SignOutButton />
    </div>
  );
};

export default UserAvatarMenuDropdown;
