import { ErrorBoundary } from "@sentry/react";
import ErrorText from "modules/error/components/ErrorText";
import {
  ComponentPropsWithoutRef,
  ComponentRef,
  FC,
  forwardRef,
  ReactElement,
  ReactNode,
  Suspense,
} from "react";
import Shimmer from "ui/feedback/ShimmerV2";
import { Heading2, Heading4 } from "ui/typography";
import createComponentContext from "utils/react-helpers/createComponentContext";
import cn from "utils/tailwind/cn";

// NB(alex): Planning to add more variants soon, but still WIP.
export type SectionVariant = "dashboard-page";

type SectionV2ContextValue = {
  variant: SectionVariant;
};

const [SectionV2Provider, _useSectionV2Context] =
  createComponentContext<SectionV2ContextValue>("SectionV2");

type Props = ComponentPropsWithoutRef<"div"> & {
  variant: "dashboard-page";
};

const SectionV2 = forwardRef<ComponentRef<"div">, Props>(
  ({ children, className, variant, ...props }, ref) => {
    return (
      <SectionV2Provider value={{ variant }}>
        <ErrorBoundary fallback={({ error }) => <ErrorText error={error} />}>
          <div
            ref={ref}
            className={cn("max-w-[var(--hb-content-max-width)] py-8", className)}
            {...props}
          >
            {children}
          </div>
        </ErrorBoundary>
      </SectionV2Provider>
    );
  }
);

type SectionHeaderProps = Omit<ComponentPropsWithoutRef<"div">, "children"> & {
  // The first element is the heading element, if a second element is specified, it is the subheading element.
  children?: ReactElement | [ReactElement, ReactElement];
  actions?: ReactNode;
  // Used for aligning actions with the subheading.
  subheadingActions?: ReactNode;
};

const SectionHeader = forwardRef<ComponentRef<"div">, SectionHeaderProps>(
  ({ children, className, actions, subheadingActions, ...props }, ref) => {
    const hasSubheading = Array.isArray(children) && children.length === 2;
    return (
      <div className={cn("mb-6 flex flex-col", className)} {...props} ref={ref}>
        <div className={cn("flex items-center gap-x-2", className)}>
          {hasSubheading ? children[0] : children}
          {actions && <div className="ml-auto">{actions}</div>}
        </div>
        {hasSubheading && (
          <div className="flex items-center gap-x-2">
            {children[1]}
            {subheadingActions && <div className="ml-auto">{subheadingActions}</div>}
          </div>
        )}
      </div>
    );
  }
);

const SectionHeading = forwardRef<
  ComponentRef<typeof Heading2>,
  ComponentPropsWithoutRef<typeof Heading2>
>(({ children, className, ...props }, ref) => {
  return (
    <Heading2 className={cn("flex items-center gap-x-2 text-lg", className)} {...props} ref={ref}>
      {children}
    </Heading2>
  );
});

const SectionSubheading = forwardRef<
  ComponentRef<typeof Heading4>,
  ComponentPropsWithoutRef<typeof Heading4>
>(({ children, className, ...props }, ref) => {
  return (
    <Heading4 className={cn("text-sm font-regular text-grey-600", className)} {...props} ref={ref}>
      {children}
    </Heading4>
  );
});

type SectionBodyLoadingProps = {
  className?: string;
};

const SectionBodyLoading = forwardRef<ComponentRef<"div">, SectionBodyLoadingProps>(
  ({ className }, ref) => {
    return (
      <div className={cn("flex flex-col", className)} ref={ref}>
        <Shimmer />
      </div>
    );
  }
);

const SectionErrorFallback: FC<ComponentPropsWithoutRef<typeof ErrorText>> = (props) => {
  return <ErrorText {...props} />;
};

type SectionBodyProps = ComponentPropsWithoutRef<"div"> & {
  suspenseFallback?: ReactNode;
};

const SectionBody = forwardRef<ComponentRef<"div">, SectionBodyProps>(
  ({ children, className, suspenseFallback, ...props }, ref) => {
    return (
      <div className={cn("mt-6 first-of-type:mt-0", className)} {...props} ref={ref}>
        <ErrorBoundary fallback={({ error }) => <SectionErrorFallback error={error} />}>
          <Suspense fallback={suspenseFallback}>{children}</Suspense>
        </ErrorBoundary>
      </div>
    );
  }
);

export default Object.assign(SectionV2, {
  Header: SectionHeader,
  Heading: SectionHeading,
  Subheading: SectionSubheading,
  Body: SectionBody,
  BodyLoading: SectionBodyLoading,
  ErrorFallback: SectionErrorFallback,
});
