import { useQueryClient } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import usePayeeClassCodeApi from "../api/usePayeeClassCodeApi";
import payeeClassCodeQueryHooks from "../queries/payeeClassCodeQueryHooks";
import PayeeClassCodeRep, { PayeeClassCodeCreatorRep } from "../reps/PayeeClassCodeRep";

type Variables = Omit<PayeeClassCodeCreatorRep, "payeeGuid" | "businessGuid">;

const useUpsertPayeeClassCodeMutation = (
  payeeGuid: string,
  additionalOptions: MutationAdditionalOptions<PayeeClassCodeRep, Variables> = {}
) => {
  const payeeClassCodeApi = usePayeeClassCodeApi();
  const businessGuid = useBusinessGuid();
  const payeeClassCodeQueryKey = payeeClassCodeQueryHooks.useQueryKey({
    payeeGuid,
  });
  const queryClient = useQueryClient();

  return useMutationWithDefaults(
    {
      mutationFn: ({ classCodeId }) =>
        payeeClassCodeApi.upsert({
          payeeGuid,
          businessGuid,
          classCodeId,
        }),

      onSuccess: async (data) =>
        queryClient.setQueriesData<PayeeClassCodeRep>({ queryKey: payeeClassCodeQueryKey }, data),
    },
    additionalOptions
  );
};

export default useUpsertPayeeClassCodeMutation;
