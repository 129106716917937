export type InterestTier = {
  range: string;
  interestBps: number;
};

export const CHECKING_INTEREST_TIERS = [
  {
    range: "$0–$100K",
    interestBps: 0,
  },
  {
    range: "$100K–$250K",
    interestBps: 75,
  },
  {
    range: "$250K+",
    interestBps: 202,
  },
] as const satisfies readonly InterestTier[];

export const HIGH_YIELD_INTEREST_TIERS = [
  {
    range: "$0–$1M",
    interestBps: 300,
  },
  {
    range: "$1M–$5M",
    interestBps: 352,
  },
  {
    range: "$5M+",
    interestBps: 404,
  },
] as const satisfies readonly InterestTier[];
