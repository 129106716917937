import { ShieldCheck } from "@phosphor-icons/react";
import { yieldPercentByAccountType } from "modules/bank-accounts/constants/yield";
import { FC, ReactNode } from "react";
import Banner from "ui/data-display/Banner";
import IconTile from "ui/icons/IconTile";
import cn from "utils/tailwind/cn";

import { OnboardingEstimatedTimePill } from "../shared/onboarding-common-components";

type Props = {
  button: ReactNode;
  paragraph?: ReactNode;
  className?: string;
};

const VerifyBusinessBanner: FC<Props> = ({ button, paragraph, className }) => {
  return (
    <Banner
      color="white"
      icon={<IconTile className="bg-purple-50" icon={<ShieldCheck weight="light" />} />}
      title={
        <>
          Verify your business &nbsp;&nbsp;
          <OnboardingEstimatedTimePill size="xs" className="hidden mobile-phone:inline-flex">
            5 minutes
          </OnboardingEstimatedTimePill>
        </>
      }
      paragraph={
        paragraph ?? (
          <>
            Access up to{" "}
            <strong className="font-medium">{yieldPercentByAccountType["HighYield"]} yield</strong>{" "}
            in bank accounts and make bill payments.
          </>
        )
      }
      button={<div className="hidden tablet:block">{button}</div>}
      footer={
        <Banner.Footer
          className={cn(
            "block items-center pt-0 tablet:hidden",
            "ml-1 pl-16" // Hack to align the button with the text. We need to re-work our banner component...
          )}
        >
          {button}
          <OnboardingEstimatedTimePill size="xs" className="inline-flex mobile-phone:hidden">
            5 minutes
          </OnboardingEstimatedTimePill>
        </Banner.Footer>
      }
      className={className}
    />
  );
};

export default VerifyBusinessBanner;
