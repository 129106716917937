import React from "react";

import ChatConfigSettings from "./ChatConfigSettings";
import ChatConfigStatus from "./ChatConfigStatus";

const ChatAdmin: React.FC = () => {
  return (
    <>
      <ChatConfigStatus />
      <ChatConfigSettings />
    </>
  );
};

export default ChatAdmin;
