import { CategoryChatElementRep } from "modules/chat/reps/ChatElementRep";
import React from "react";

type Props = {
  element: CategoryChatElementRep;
};

const CategoryChatElement: React.FC<Props> = ({ element }) => {
  return <span className="font-600">{element.humanReadable}</span>;
};

export default CategoryChatElement;
