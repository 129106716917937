import { useEffect } from "react";

import { useSidePanelContext } from "./context";

/**
 * Manages the `showChildren` state.
 */
const useShowChildrenEffects = () => {
  const { isOpen, showChildren, setShowChildren, transitionEnabled } = useSidePanelContext();

  // Always show children when open.
  useEffect(() => {
    if (isOpen && !showChildren) {
      setShowChildren(true);
    }
  }, [isOpen, setShowChildren, showChildren]);

  // Unmount children if not open and not in the middle of a transition.
  useEffect(() => {
    if (!isOpen && !transitionEnabled && showChildren) {
      setShowChildren(false);
    }
  }, [isOpen, transitionEnabled, showChildren, setShowChildren]);
};

export default useShowChildrenEffects;
