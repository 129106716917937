import useExchangeApi from "modules/chat/api/useExchangeApi";
import { useMemo } from "react";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

const QUERY_KEY = "chatChannelExchanges";

type Params = {
  channelId: string;
  latestExclusive: string | null;
};

export const chatChannelExchangesQueryHooks = makeQueryHooksV2({
  useQueryFnParams: () => {
    const exchangeApi = useExchangeApi();
    return { exchangeApi };
  },
  makeQueryKey: (params: Params) => {
    return [QUERY_KEY, params.channelId, params.latestExclusive] as const;
  },
  makeQueryFn: ({ exchangeApi }, { channelId, latestExclusive }) => {
    return () => exchangeApi.searchChannel(channelId, latestExclusive);
  },
});

export const useChatChannelExchanges = (params: Params) => {
  const exchanges = chatChannelExchangesQueryHooks.useData({ params, refetchInterval: 1000 });
  return useMemo(() => exchanges.toReversed(), [exchanges]);
};

export const useRefreshChatChannelExchanges = () => {
  return useRefreshQuery([QUERY_KEY]);
};
