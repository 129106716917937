import { CheckCircle } from "@phosphor-icons/react";
import { Feedback } from "modules/chat/reps/ExchangeRep";
import { ComponentRef, forwardRef } from "react";
import Button from "ui/inputs/Button";

type Props = {
  className?: string;
  value: Feedback | null;
  onChange: (feedback: Feedback | null) => void;
};

const UserFeedbackCard = forwardRef<ComponentRef<"div">, Props>(
  ({ className, onChange, value, ...props }, forwardedRef) => {
    const selectedFeedback = value?.value ?? null;
    const showNegativeFeedbackReasons = selectedFeedback === "Negative" && !value?.reason;
    const feedbackSubmitted =
      value && (value.value === "Positive" || (value.value === "Negative" && value.reason));

    return (
      <div className={className} ref={forwardedRef} {...props}>
        {showNegativeFeedbackReasons && (
          <div className="rounded-md border border-grey-200 bg-white p-4">
            <span className="text-xs font-medium text-grey-600">What went wrong?</span>
            <div className="mt-4 flex flex-wrap gap-2">
              <Button
                variant="ghost"
                size="sm"
                className="h-8 rounded-full border border-grey-100 font-regular text-grey-600"
                onClick={() => onChange({ value: "Negative", reason: "Incorrect information" })}
              >
                Incorrect information
              </Button>
              <Button
                variant="ghost"
                size="sm"
                className="h-8 rounded-full border border-grey-100 font-regular text-grey-600"
                onClick={() => onChange({ value: "Negative", reason: "Not helpful" })}
              >
                Not helpful
              </Button>
              <Button
                variant="ghost"
                size="sm"
                className="h-8 rounded-full border border-grey-100 font-regular text-grey-600"
                onClick={() => onChange({ value: "Negative", reason: "Other" })}
              >
                Other
              </Button>
            </div>
          </div>
        )}
        {feedbackSubmitted && (
          <div className="rounded-md border border-grey-200 bg-white p-3">
            <div className="flex items-center">
              <CheckCircle size={16} color="green" className="mr-2" />
              <span className="text-xs font-medium text-grey-600">Thanks for the feedback!</span>
            </div>
            <div className="mt-2 text-sm text-grey-600">
              Your input will help Highbeam Intelligence work better for you.
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default UserFeedbackCard;
