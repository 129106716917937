import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

import useApSettingsApi from "../api/useApSettingsApi";

const apSettingsQueryHooks = makeQueryHooks({
  name: "ap-settings",
  useQueryVariables() {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  useQueryFnMaker({ businessGuid }) {
    const apSettingsApi = useApSettingsApi();
    return () => apSettingsApi.get(businessGuid);
  },
});

export default apSettingsQueryHooks;

//
// Hooks
//

export const useApSettings = () => apSettingsQueryHooks.useData({});
