import { FC } from "react";
import ButtonLink, { ButtonLinkProps } from "ui/inputs/ButtonLink";

type Props = ButtonLinkProps & {
  isSuperuserOnly?: boolean;
  isDevOnly?: boolean;
};

const SkipButtonLink: FC<Props> = ({
  children = "Skip",
  isSuperuserOnly,
  isDevOnly,
  ...buttonV2Props
}) => {
  return (
    <ButtonLink variant="ghost" {...buttonV2Props}>
      {isSuperuserOnly && "[SU] "}
      {!isSuperuserOnly && isDevOnly && "[DEV] "}
      {children}
    </ButtonLink>
  );
};

export default SkipButtonLink;
