import { IconProps, ThumbsUp } from "@phosphor-icons/react";
import { ComponentRef, forwardRef } from "react";
import colors from "styles/colors";
import cn from "utils/tailwind/cn";

export type ThumbIconDirection = "up" | "down";

type Props = Omit<IconProps, "weight"> & {
  direction: ThumbIconDirection;
  selected?: boolean;
};

const ThumbIcon = forwardRef<ComponentRef<"svg">, Props>(
  ({ size, direction, className, selected = false, color = colors.grey[900], ...props }, ref) => {
    if (selected) {
      // NB(alex): Wasn't able to figure out how to fill in the `ThumbsUp` icon completely so opted to copy the SVG over.
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 16 16"
          fill="none"
          className={cn(direction === "down" && "-scale-y-100", className)}
          ref={ref}
          {...props}
        >
          <path
            d="M5 6L7.5 1C8.03043 1 8.53914 1.21071 8.91421 1.58579C9.28929 1.96086 9.5 2.46957 9.5 3V4.5H13.3687C13.5105 4.49965 13.6508 4.52965 13.78 4.58798C13.9092 4.64631 14.0245 4.73162 14.118 4.83818C14.2116 4.94475 14.2812 5.0701 14.3223 5.20581C14.3634 5.34152 14.375 5.48445 14.3562 5.625L13.6062 11.625C13.5759 11.8659 13.459 12.0875 13.2773 12.2485C13.0956 12.4095 12.8615 12.4989 12.6187 12.5H5"
            fill={color}
          />
          <path
            d="M1.42857 6H4V12.5H1.42857C1.31491 12.5 1.2059 12.4473 1.12553 12.3536C1.04515 12.2598 1 12.1326 1 12V6.5C1 6.36739 1.04515 6.24021 1.12553 6.14645C1.2059 6.05268 1.31491 6 1.42857 6Z"
            fill={color}
          />
        </svg>
      );
    } else {
      return (
        <ThumbsUp
          size={size}
          color={color}
          className={cn(direction === "down" && "-scale-y-100", className)}
          ref={ref}
          {...props}
        />
      );
    }
  }
);

export default ThumbIcon;
