import { PlusCircle } from "@phosphor-icons/react";
import { FC } from "react";
import { AiTaskStatus } from "reps/ai-tasks/AiTaskRep";
import { useIsSuperusering } from "state/auth/isSuperusering";
import Button from "ui/inputs/Button";
import Tooltip from "ui/overlay/Tooltip";
import { Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";

export type IconColor = "blue-light" | "green-100" | "yellow-100" | "pink-100";

export enum AiTaskButtonVariant {
  Standard = "Standard",
  CreateTask = "CreateTask",
}

type Props = {
  variant?: AiTaskButtonVariant;
  status?: AiTaskStatus;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick: () => void;
  className?: string;
  disablePrimaryButton?: boolean;
  disabledTooltipText?: string;
};

const AiTaskButtonComponent: FC<Props> = ({
  variant = AiTaskButtonVariant.Standard,
  status = "Active",
  primaryButtonText = "Edit task",
  secondaryButtonText = "[SU] Turn off",
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  className,
  disablePrimaryButton = false,
  disabledTooltipText = "You have to be a Highbeam banking customer to set up this task",
}) => {
  const getButtonText = () => {
    if (status === "Inactive") {
      return {
        primary: "Review and activate",
        secondary: "Learn more",
      };
    }

    return {
      primary: primaryButtonText,
      secondary: secondaryButtonText,
    };
  };

  const buttonText = getButtonText();

  const isSuperusering = useIsSuperusering();

  const getSecondaryButtonClassName = () => {
    if (status === "Inactive") {
      return "button-size-sm shadow-xs"; // No color or border for Inactive status
    }

    return "button-size-sm border border-red-100 bg-red-50 text-red-500 shadow-xs data-[disabled=false]:hover:bg-red-100 data-[disabled=false]:hover:shadow-sm data-[disabled=false]:active:shadow-inset-red";
  };

  const getSecondaryButtonVariant = () => {
    if (status === "Inactive") {
      return "ghost";
    }

    return "secondary";
  };

  const renderPrimaryButton = () => {
    if (!buttonText.primary) return null;

    const button = (
      <Button
        variant="tertiary"
        className="button-size-sm"
        onClick={onPrimaryButtonClick}
        disabled={disablePrimaryButton}
      >
        {buttonText.primary}
      </Button>
    );

    if (disablePrimaryButton) {
      return (
        <Tooltip>
          <Tooltip.Trigger asChild>{button}</Tooltip.Trigger>
          <Tooltip.Content paddingVariant="compact">
            <Paragraph className="text-sm">{disabledTooltipText}</Paragraph>
          </Tooltip.Content>
        </Tooltip>
      );
    }

    return button;
  };

  if (variant === AiTaskButtonVariant.CreateTask) {
    return (
      <Button
        variant="tertiary"
        className={cn("flex h-[44px] w-[139px] items-center gap-2", className)}
        onClick={onPrimaryButtonClick}
      >
        <PlusCircle size={20} />
        <span>Create task</span>
      </Button>
    );
  }

  return (
    <div className={cn("flex w-full flex-col gap-3", className)}>
      {renderPrimaryButton()}

      {buttonText.secondary &&
        (buttonText.secondary.includes("Turn off") ? isSuperusering : true) && (
          <Button
            variant={getSecondaryButtonVariant()}
            className={getSecondaryButtonClassName()}
            onClick={onSecondaryButtonClick}
          >
            {buttonText.secondary}
          </Button>
        )}
    </div>
  );
};

export default AiTaskButtonComponent;
