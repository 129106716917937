// The Embedded Drawer component is a simple panel that is absolutely positioned along
// the bottom of its parent container (or first non-static positioned ancestor). The
// component is composed of a visible header/bar and a body, which is expanded/collapsed
// by clicking the header.
// The Embedded Drawer is *not* a modal-based component, like a conventional drawer or
// our dialog modals or Flexpane. When the drawer is open, it does *not* trap focus or
// otherwise prevent interaction with the rest of the page.

import { CaretDown as CaretDownIcon } from "@phosphor-icons/react";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useId,
  useCallback,
  useRef,
  useEffect,
  ComponentPropsWithoutRef,
  CSSProperties,
  forwardRef,
  ComponentRef,
} from "react";
import VirtualButton from "ui/inputs/VirtualButton";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";
import { transitionDuration } from "utils/transitions";

type EmbeddedDrawerContextValue = {
  baseId: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const EmbeddedDrawerContext = createContext<EmbeddedDrawerContextValue>({
  baseId: "",
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
});

const useEmbeddedDrawerContext = () => {
  const context = useContext(EmbeddedDrawerContext);
  if (!context) {
    throw new Error("useEmbeddedDrawerContext must be used within an EmbeddedDrawer");
  }
  return context;
};

const makeHeaderId = (baseId: string) => `${baseId}-header`;
const makeBodyId = (baseId: string) => `${baseId}-body`;

const EmbeddedDrawerHeaderCaret: FC<ComponentPropsWithoutRef<typeof CaretDownIcon>> = ({
  className,
  ...props
}) => {
  const { isOpen } = useEmbeddedDrawerContext();

  return <CaretDownIcon className={cn("size-4", !isOpen && "rotate-180", className)} {...props} />;
};

type EmbeddedDrawerHeaderProps = PropsWithChildrenAndClassName;

const EmbeddedDrawerHeader = forwardRef<
  ComponentRef<typeof VirtualButton>,
  EmbeddedDrawerHeaderProps
>(({ children, className }, forwardedRef) => {
  const { baseId, isOpen, onOpen, onClose } = useEmbeddedDrawerContext();
  const headerId = makeHeaderId(baseId);
  const bodyId = makeBodyId(baseId);

  const handleClick = useCallback(() => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  }, [isOpen, onClose, onOpen]);

  return (
    <VirtualButton
      id={headerId}
      aria-controls={bodyId}
      className={cn(
        "sticky top-0 z-[12] flex items-center justify-between rounded-t bg-white p-4 text-md hover:bg-grey-50 active:shadow-inset-y",
        isOpen && "border-b border-b-grey-100",
        className
      )}
      onClick={handleClick}
      ref={forwardedRef}
    >
      {children}
    </VirtualButton>
  );
});

type EmbeddedDrawerBodyProps = PropsWithChildrenAndClassName;

const EmbeddedDrawerBody: FC<EmbeddedDrawerBodyProps> = ({ children, className }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { baseId, isOpen } = useEmbeddedDrawerContext();
  const headerId = makeHeaderId(baseId);
  const bodyId = makeBodyId(baseId);

  useEffect(() => {
    const container = containerRef.current!;
    container.style.overflow = "hidden";

    if (isOpen) {
      container.style.display = "block";
      container.style.maxHeight = `${container.scrollHeight}px`;

      const timer = setTimeout(() => {
        container.style.overflow = null!;
        container.style.maxHeight = null!;
      }, transitionDuration);

      return () => clearTimeout(timer);
    } else {
      container.style.maxHeight = `${container.scrollHeight}px`;

      // eslint-disable-next-line functional/no-let
      let timer: ReturnType<typeof setTimeout> | null = null;

      setTimeout(() => {
        container.style.maxHeight = "0px";

        timer = setTimeout(() => {
          container.style.display = "none";
        }, transitionDuration);
      }, 0);

      return () => timer && clearTimeout(timer);
    }
  }, [isOpen]);

  return (
    <div
      id={bodyId}
      aria-labelledby={headerId}
      aria-expanded={isOpen}
      className={cn(
        // The `hidden` class here is only used to prevent a flicker on the component's initial mount. After that, we use inline display styles to manage its visibility.
        "relative z-[11] hidden bg-white transition-[max-height] duration-150 ease-in-out",
        className
      )}
      ref={containerRef}
    >
      {children}
    </div>
  );
};

type Props = PropsWithChildren<{
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  className?: string;
  style?: CSSProperties;
}>;

const EmbeddedDrawer = forwardRef<ComponentRef<"div">, Props>(
  ({ isOpen, onOpen, onClose, className, children, style }, forwardedRef) => {
    const baseId = useId();

    return (
      <div
        className={cn(
          "absolute inset-x-0 bottom-0 z-10 max-h-full overflow-auto rounded-t border-t border-t-grey-100 text-grey-900 shadow-[0_-1px_3px_0_rgba(0,0,0,0.1),_0_-1px_2px_0_rgba(0,0,0,0.06)]",
          className
        )}
        style={style}
        ref={forwardedRef}
      >
        <EmbeddedDrawerContext.Provider value={{ baseId, isOpen, onOpen, onClose }}>
          {children}
        </EmbeddedDrawerContext.Provider>
      </div>
    );
  }
);

export default Object.assign(EmbeddedDrawer, {
  Header: EmbeddedDrawerHeader,
  HeaderCaret: EmbeddedDrawerHeaderCaret,
  Body: EmbeddedDrawerBody,
});
