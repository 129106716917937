import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

export const ROOT_PLAID_ACCOUNTS_QUERY_NAME = "plaidAccounts-root";

export const useRefreshAllPlaidAccountsQueries = () => {
  return useRefreshQuery([ROOT_PLAID_ACCOUNTS_QUERY_NAME]);
};

type Params = {
  businessGuid: string;
};

export const plaidAccountsQueryHooks = makeQueryHooksV2({
  useQueryFnParams: () => {
    const highbeamApi = useHighbeamApi();
    return { highbeamApi };
  },
  useDefaultParams: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  makeQueryKey: (params: Params) => {
    return [ROOT_PLAID_ACCOUNTS_QUERY_NAME, "plaidAccounts", params];
  },
  makeQueryFn: ({ highbeamApi }, { businessGuid }) => {
    return () => {
      return highbeamApi.plaid.getPlaidBankAccountsByBusiness(businessGuid);
    };
  },
});

export const useHasInactivePlaidBankAccounts = (): boolean => {
  return plaidAccountsQueryHooks.useData({
    params: {},
    select: (plaidAccounts) => {
      const inactivePlaidBankAccounts = plaidAccounts.filter((account) => {
        return (
          account.accountType === "DEPOSITORY" && (!account.isActive || !account.isConnectionActive)
        );
      });
      return inactivePlaidBankAccounts.length > 0;
    },
  });
};
