import useChatConfigApi from "modules/chat/api/useChatConfigApi";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHasPermission from "utils/permissions/useHasPermission";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";

export const CHAT_CONFIGS_QUERY_KEY = "chat-configs";

type Params = {
  businessGuid: string;
};

const chatConfigQueryHooks = makeQueryHooksV2({
  useDefaultParams: () => {
    const businessGuid = useBusinessGuid();
    const enabled = useHasPermission("chat");
    return { businessGuid, enabled };
  },
  makeQueryKey: (params: Params) => {
    return [CHAT_CONFIGS_QUERY_KEY, params] as const;
  },
  useQueryFnParams: () => {
    const chatConfigApi = useChatConfigApi();
    return { chatConfigApi };
  },
  makeQueryFn: ({ chatConfigApi }, { businessGuid }) => {
    return () => {
      return chatConfigApi.getByBusiness(businessGuid);
    };
  },
});

const useChatConfig = () => {
  return chatConfigQueryHooks.useData({ params: {} });
};

export { chatConfigQueryHooks, useChatConfig };
