import useCreateAiTaskMutation from "modules/ai-tasks/mutations/useCreateAiTaskMutation";
import { aiTasksQueryHooks } from "modules/ai-tasks/queries/useAiTasks";
import WouldYouLikeACashflowSheetView from "modules/onboarding/components/onboarding-connections/WouldYouLikeACashflowSheetView";
import { containerPaddingClasses } from "modules/onboarding/components/shared/onboarding-common-components";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import ButtonLink from "ui/inputs/ButtonLink";
import cn from "utils/tailwind/cn";

const WouldYouLikeACashFlowSheetPage = () => {
  const cashFlowForecastTask = aiTasksQueryHooks.useData({
    params: {},
    select: (data) => data.find((task) => task.type === "CashFlowForecast"),
  });

  const { mutate: createAiTask, isPending } = useCreateAiTaskMutation({
    onSuccess: () => {
      notify("success", "Cash flow forecast creation queued");
    },
  });

  return (
    <WouldYouLikeACashflowSheetView
      className={cn(containerPaddingClasses, "py-8 tablet:mt-12 2xl:mt-16")}
    >
      <WouldYouLikeACashflowSheetView.VideoTrigger>
        <WouldYouLikeACashflowSheetView.HeroImage />
      </WouldYouLikeACashflowSheetView.VideoTrigger>
      <WouldYouLikeACashflowSheetView.Heading />
      <WouldYouLikeACashflowSheetView.Subheading />

      <WouldYouLikeACashflowSheetView.Footer>
        {cashFlowForecastTask?.status === "Active" ? (
          <Button
            isLoading={isPending}
            variant="primary"
            onClick={() => {
              createAiTask({
                status: "SetupInProgress",
                type: "CashFlowForecast",
                description: "Create a cash flow forecast",
              });
            }}
          >
            Create a sheet for me
          </Button>
        ) : (
          <ButtonLink
            to="/onboarding/verify-business"
            variant="primary"
            onClick={() => {
              notify("success", "Cash flow forecast creation in progress");
            }}
          >
            Create a sheet for me
          </ButtonLink>
        )}

        <ButtonLink to="/onboarding/verify-business" variant="ghost">
          No thanks
        </ButtonLink>
      </WouldYouLikeACashflowSheetView.Footer>
    </WouldYouLikeACashflowSheetView>
  );
};

export default WouldYouLikeACashFlowSheetPage;
