import { ComponentProps, FC } from "react";
import { Paragraph, Span } from "ui/typography";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

import kondiHero from "../../assets/kondi-hero.webp";

const VerifyBusinessHero: FC<PropsWithChildrenAndClassName> = ({ className, children }) => {
  return <div className={cn("flex max-w-md flex-col gap-y-8", className)}>{children}</div>;
};

const HeroImage: FC<Omit<ComponentProps<"img">, "src" | "alt">> = (props) => {
  return <img src={kondiHero} alt="Kondi" {...props} />;
};

const HeroQuote: FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={cn("flex flex-col gap-y-2", className)}>
      <Span className="block h-8 text-[2.75rem] text-purple-400" aria-hidden="true">
        “
      </Span>
      <div className="text-md font-medium leading-7 text-grey-900">
        <Paragraph>
          Before Highbeam, the cash flow drove the strategy instead of the business. Highbeam put us
          back in control.
        </Paragraph>
      </div>
      <div className="mt-8 text-sm">
        <span className="block leading-8">
          <strong>Lauren Goldberg</strong>
        </span>
        <span className="block leading-8">CEO, Suzie Kondi</span>
      </div>
    </div>
  );
};

export default Object.assign(VerifyBusinessHero, {
  HeroImage: HeroImage,
  HeroQuote: HeroQuote,
});
