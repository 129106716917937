import DashboardPage from "layouts/DashboardPage";
import FailedDebitsTable from "modules/failed-debits/components/FailedDebitsTable";
import { useFailedDebitPaymentsQuery } from "modules/failed-debits/queries/useFailedDebitPayments";
import { FC } from "react";

type Props = {
  unitCoDepositAccountId?: string;
};

const BankAccountFailedDebitsSection: FC<Props> = ({ unitCoDepositAccountId }) => {
  const { data: failedDebits = [] } = useFailedDebitPaymentsQuery({
    accountId: unitCoDepositAccountId,
  });
  if (failedDebits.length === 0) {
    return null;
  }

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header className="flex-col items-start">
        <DashboardPage.Section.HeaderHeading>Failed debits</DashboardPage.Section.HeaderHeading>
        <DashboardPage.Section.HeaderSubheading>
          These debits failed due to insufficient funds. You can fund the accounts and retry the
          debits until 2PM ET today.
        </DashboardPage.Section.HeaderSubheading>
      </DashboardPage.Section.Header>

      <FailedDebitsTable unitCoDepositAccountId={unitCoDepositAccountId} />
    </DashboardPage.Section>
  );
};

export default BankAccountFailedDebitsSection;
