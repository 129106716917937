import MultiStep from "layouts/MultiStep";
import isHighCreditUtilization from "modules/line-of-credit/utils/isHighCreditUtilization";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import colors from "styles/colors";
import MoneyAmountFraction from "ui/data-display/money/MoneyAmountFraction";
import BarChart from "ui/data-visualization/BarChart";
import CurrencyInputV3 from "ui/inputs/CurrencyInputV3";
import Helper from "ui/inputs/Helper";
import Text from "ui/typography/Text";
import { getCentsFromDollars } from "utils/money";

import { useDrawdownAmountFormContext, useDrawdownContext } from "../context/DrawdownProvider";

import DrawdownFromCapitalAccountDropdown from "./DrawdownFromCapitalAccountDropdown";
import DrawdownToBankAccountDropdown from "./DrawdownToBankAccountDropdown";

const DRAW_DOWN_AMOUNT_FORM_ID = "draw-down-amount-form";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const DrawdownAmountView: FC<Props> = ({ capitalAccountSummary }) => {
  const capitalAccountAvailableBalance = capitalAccountSummary.available;
  const limit = capitalAccountSummary.details.limit;

  const { state } = useLocation();
  const { nextPathname } = useDrawdownContext();

  const form = useDrawdownAmountFormContext();
  const amountInputValue = form.watch("amount");
  const selectedAmountInCents = amountInputValue === "" ? 0 : getCentsFromDollars(amountInputValue);

  const navigate = useNavigate();

  const onSubmit = form.handleSubmit((_data) => {
    // NB(alex): Do not copy this pattern -- I think the correct pattern should be to save the validated data in a shared store, and to derive the inputs or keep them in a provider.
    if (nextPathname) {
      navigate(nextPathname, {
        state: {
          ...state,
          drawdownAmountFormInputs: form.getValues(),
        },
      });
    }
  });

  const availableAfterDrawdown = capitalAccountAvailableBalance - selectedAmountInCents;
  const availableAfterDrawdownPercentage = (availableAfterDrawdown / limit) * 100;
  const showHighUtilizationWarning =
    selectedAmountInCents > 0 && isHighCreditUtilization(capitalAccountSummary);

  return (
    <MultiStep.Form id={DRAW_DOWN_AMOUNT_FORM_ID} onSubmit={onSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>
            How much do you want to draw down?
          </MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>

        <MultiStep.Section className="mt-6">
          <DrawdownFromCapitalAccountDropdown />
        </MultiStep.Section>

        <Controller
          control={form.control}
          name="amount"
          render={({ field, fieldState: { error } }) => {
            return (
              <div>
                <CurrencyInputV3
                  startAdornment="$"
                  label="Amount"
                  showErrorOutline={Boolean(error)}
                  autoFocus
                  {...field}
                  value={field.value}
                />
                {showHighUtilizationWarning && (
                  <Helper iconVariant="warning">
                    Continuous high credit utilization can result in a decrease of your line of
                    credit limit.
                  </Helper>
                )}
                {error && <Helper iconVariant="error">{error.message}</Helper>}
              </div>
            );
          }}
        />
      </MultiStep.Section>

      <MultiStep.Section className="mt-6">
        <DrawdownToBankAccountDropdown />
      </MultiStep.Section>

      <MultiStep.Section className="py-6">
        <Text color={colors.grey[900]} size={14} weight="medium">
          Available after drawdown
        </Text>

        <BarChart height={8} className="mt-4">
          <BarChart.Bar
            color={availableAfterDrawdownPercentage > 0 ? "green" : "yellow"}
            widthPercentage={availableAfterDrawdownPercentage}
          />
        </BarChart>

        <MoneyAmountFraction
          numeratorInCents={availableAfterDrawdown}
          denominatorInCents={limit}
          className="mt-3"
        />
      </MultiStep.Section>

      <MultiStep.Controls>
        <MultiStep.Controls.NextButton form={DRAW_DOWN_AMOUNT_FORM_ID}>
          Next
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

export default DrawdownAmountView;
