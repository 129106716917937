import { FC } from "react";
import { Paragraph } from "ui/typography";

const DefaultPayeeClassCodeDescription: FC = () => (
  <Paragraph className="text-sm text-grey-600">
    Choose a class from your accounting software. All new bills for this payee will be coded with
    this class by default, but you can edit them later.
  </Paragraph>
);

export default DefaultPayeeClassCodeDescription;
