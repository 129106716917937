import { useRefreshAllBankAccountQueries } from "modules/bank-accounts/queries/useBankAccounts";
import useCapitalRepaymentApi from "modules/capital-accounts/api/useCapitalRepaymentApi";
import { useRefreshAllCapitalAccountsQueries } from "modules/capital-accounts/queries/useCapitalAccounts";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useMfa from "modules/mfa/useMfa";
import { useState } from "react";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";
import { v4 as uuidv4 } from "uuid";

type Variables = {
  capitalAccountGuid: string;
  amountInCents: number;
  bankAccountGuid: string;
};

const useRepayChargeCardMutation = (
  additionalOptions: MutationAdditionalOptions<void, Variables>
) => {
  const capitalRepaymentApi = useCapitalRepaymentApi();
  const businessGuid = useBusinessGuid();
  const refreshAllCapitalAccountsQueries = useRefreshAllCapitalAccountsQueries();
  const refreshAllBankAccountQueries = useRefreshAllBankAccountQueries();
  const { mfa } = useMfa();

  // NB(alex): We should move this to `react-hook-form` once we get around to it. Context: https://highbeamco.slack.com/archives/C02M3GGQPHC/p1690914198052609?thread_ts=1690912209.739389&cid=C02M3GGQPHC
  const [idempotencyKey, setIdempotencyKey] = useState(uuidv4());

  return useMutationWithDefaults(
    {
      mutationFn: async ({ capitalAccountGuid, amountInCents, bankAccountGuid }: Variables) => {
        await mfa();
        return capitalRepaymentApi.create(businessGuid, capitalAccountGuid, {
          amount: amountInCents,
          bankAccountGuid: bankAccountGuid,
          idempotencyKey: idempotencyKey,
        });
      },
      onSuccess: async () => {
        await Promise.all([refreshAllCapitalAccountsQueries(), refreshAllBankAccountQueries()]);
        setIdempotencyKey(uuidv4());
      },
    },
    additionalOptions ?? {}
  );
};

export default useRepayChargeCardMutation;
