import { FC } from "react";
import { Paragraph } from "ui/typography";
import { formatNumber } from "utils/numbers";
import cn from "utils/tailwind/cn";

type RenderProps = {
  start: number;
  end: number;
  totalCount: number;
};

type Props = {
  page: number;
  pageSize: number;
  totalCount: number;
  className?: string;
  children?: (props: RenderProps) => React.ReactNode;
};

const PaginationSliceIndicator: FC<Props> = ({
  page,
  pageSize,
  totalCount,
  className,
  children,
}) => {
  const start = (page - 1) * pageSize + 1;
  const end = Math.min(start + pageSize - 1, totalCount);

  return (
    <div className={cn("flex items-center gap-2", className)}>
      <Paragraph className="text-sm font-medium text-grey-600">
        {children ? (
          children({ start, end, totalCount })
        ) : totalCount > 0 ? (
          <>
            Showing {start}&ndash;{end} of {formatNumber(totalCount)}
          </>
        ) : (
          <>Showing 0</>
        )}
      </Paragraph>
    </div>
  );
};

export default PaginationSliceIndicator;
