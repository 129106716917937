import { LinkChatElementRep } from "modules/chat/reps/ChatElementRep";
import React from "react";
import { Link } from "react-router-dom";

type Props = {
  element: LinkChatElementRep;
};

const LinkChatElement: React.FC<Props> = ({ element }) => {
  return (
    <Link to={element.target} className="text-purple-500 hover:underline">
      {element.text}
    </Link>
  );
};

export default LinkChatElement;
