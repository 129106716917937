import { Unit, UnitConfig } from "@highbeam/unit-node-sdk";
import { useSuspenseQuery } from "@tanstack/react-query";
import env from "env";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import useQueryOptions from "utils/react-query/useQueryOptions";

import useUnitCoCustomerToken from "./useUnitCoCustomerToken";

const useUnitApiQueryOptions = (token: string | null, basePath: string, config?: UnitConfig) => {
  return useQueryOptions({
    queryKey: ["unit-api", token, basePath, config],
    queryFn: () => {
      if (!token) {
        return null;
      }
      return new Unit(token, basePath, config);
    },
    staleTime: Infinity,
  });
};

export const DEFAULT_UNIT_CONFIG: UnitConfig = {
  sdkUserAgent: false,
};

const useUnitApi = (sensitiveToken?: string) => {
  const token = useUnitCoCustomerToken();
  const { data } = useSuspenseQuery(
    useUnitApiQueryOptions(sensitiveToken ?? token, env.UNIT_API_ORIGIN, DEFAULT_UNIT_CONFIG)
  );
  return data;
};

const useUnitApiOrThrow = (sensitiveToken?: string) => {
  const unitApi = useUnitApi(sensitiveToken);
  if (!unitApi) throw new RequiredButNotFoundError();
  return unitApi;
};

type UseUnitApiIfEnabledOptions = {
  sensitiveToken?: string;
  enabled?: boolean;
};

const useUnitApiIfEnabled = (options: UseUnitApiIfEnabledOptions = {}) => {
  const { sensitiveToken, enabled = true } = options;
  const unitApi = useUnitApi(sensitiveToken);
  if (enabled && !unitApi) throw new RequiredButNotFoundError();
  return unitApi;
};

export { useUnitApi, useUnitApiOrThrow, useUnitApiIfEnabled };
