import { FC, ReactNode } from "react";
import ItemDepthIndicator from "ui/data-display/ItemDepthIndicator";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

import AccountingClassCodeRep from "../reps/AccountingClassCodeRep";

const FALLBACK_NAME = "Unknown class code";

type Props = PropsWithChildrenAndClassName & {
  accountingClassCode: AccountingClassCodeRep;
  depth?: number;
  children?:
    | ReactNode
    | ((props: { accountingClassCode: AccountingClassCodeRep; depth?: number }) => ReactNode);
};

const AccountingClassCodeBar: FC<Props> = ({ accountingClassCode, depth, className, children }) => {
  const { name } = accountingClassCode;

  return (
    <div className={cn("flex items-center gap-[1ex] whitespace-nowrap text-sm", className)}>
      {children ? (
        <>{typeof children === "function" ? children({ accountingClassCode, depth }) : children}</>
      ) : (
        <>
          {typeof depth === "number" && depth > 0 && <ItemDepthIndicator depth={depth} />}
          <span>{name ?? FALLBACK_NAME}</span>
        </>
      )}
    </div>
  );
};

export default AccountingClassCodeBar;
export { FALLBACK_NAME };
