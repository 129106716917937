import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import usePayeeClassCodeApi from "../api/usePayeeClassCodeApi";
import payeeClassCodeQueryHooks from "../queries/payeeClassCodeQueryHooks";

type Variables = {
  payeeClassCodeId: string;
};

const useDeletePayeeClassCodeMutation = (
  payeeGuid: string,
  additionalOptions: MutationAdditionalOptions<void, Variables> = {}
) => {
  const payeeClassCodeApi = usePayeeClassCodeApi();
  const payeeClassCodeQueryKey = payeeClassCodeQueryHooks.useQueryKey({
    payeeGuid,
  });
  const refreshPayeeClassCodeQuery = useRefreshQuery(payeeClassCodeQueryKey);

  return useMutationWithDefaults(
    {
      mutationFn: ({ payeeClassCodeId }) => payeeClassCodeApi.delete(payeeClassCodeId),
      onSuccess: async () => await refreshPayeeClassCodeQuery(),
    },
    additionalOptions
  );
};

export default useDeletePayeeClassCodeMutation;
