import BankAccountRep from "reps/BankAccountRep";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import { SimpleUseQueryOptions } from "utils/react-query/SimpleUseQueryOptions";

import getPrimaryBankAccount from "../utils/getPrimaryBankAccount";

import {
  bankAccountsQueryHooks,
  createBankAccountsByUnitAccountIdMap,
  filterBankAccounts,
} from "./useBankAccounts";

const findBankAccount = (bankAccounts: BankAccountRep.Complete[], bankAccountGuid?: string) => {
  if (!bankAccountGuid) return null;
  return bankAccounts.find((bankAccount) => bankAccount.guid === bankAccountGuid) ?? null;
};

const findBankAccountByUnitAccountId = (
  bankAccounts: BankAccountRep.Complete[],
  unitAccountId: string
) => {
  const bankAccountsByUnitAccountId = createBankAccountsByUnitAccountIdMap(bankAccounts);
  return bankAccountsByUnitAccountId.get(unitAccountId) ?? null;
};

const bankAccountSelect = (bankAccountGuid?: string) => {
  return (bankAccounts: BankAccountRep.Complete[]) => {
    return findBankAccount(bankAccounts, bankAccountGuid);
  };
};

export const useBankAccount = (bankAccountGuid?: string) => {
  return bankAccountsQueryHooks.useData({
    params: {},
    select: bankAccountSelect(bankAccountGuid),
  });
};

export const useBankAccountOrThrow = (bankAccountGuid: string) => {
  const bankAccount = useBankAccount(bankAccountGuid);
  if (bankAccount === null) throw new RequiredButNotFoundError();
  return bankAccount;
};

export const useBankAccountQuery = (bankAccountGuid?: string) => {
  return bankAccountsQueryHooks.useQuery({
    params: {},
    select: bankAccountSelect(bankAccountGuid),
  });
};

export const usePrimaryBankAccount = () => {
  return bankAccountsQueryHooks.useData({
    params: {},
    select: (bankAccounts) => getPrimaryBankAccount(bankAccounts),
  });
};

export const usePrimaryBankAccountOrThrow = () => {
  const bankAccount = usePrimaryBankAccount();
  if (bankAccount === null) throw new RequiredButNotFoundError();
  return bankAccount;
};

export const useOpenBankAccount = (bankAccountGuid: string) => {
  return bankAccountsQueryHooks.useData({
    params: {},
    select: (bankAccounts) => {
      const openBankAccounts = filterBankAccounts(bankAccounts, {
        status: "open",
      });
      return findBankAccount(openBankAccounts, bankAccountGuid);
    },
  });
};

export const useOpenBankAccountOrThrow = (bankAccountGuid: string) => {
  const bankAccount = useOpenBankAccount(bankAccountGuid);
  if (bankAccount === null) throw new RequiredButNotFoundError();
  return bankAccount;
};

// Unit

export const useBankAccountByUnitAccountId = (unitAccountId: string) => {
  return bankAccountsQueryHooks.useData({
    params: {},
    select: (bankAccounts) => findBankAccountByUnitAccountId(bankAccounts, unitAccountId),
  });
};

export const useBankAccountByUnitAccountIdOrThrow = (unitAccountId: string) => {
  const bankAccount = useBankAccountByUnitAccountId(unitAccountId);
  if (bankAccount === null) throw new RequiredButNotFoundError();
  return bankAccount;
};

export const useBankAccountByUnitAccountIdQuery = (
  unitAccountId: string,
  options: SimpleUseQueryOptions = {}
) => {
  return bankAccountsQueryHooks.useQuery({
    params: {},
    select: (bankAccounts) => findBankAccountByUnitAccountId(bankAccounts, unitAccountId),
    ...options,
  });
};
