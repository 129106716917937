import { BookOpen, FileSearch, Lightning, Megaphone, Money } from "@phosphor-icons/react";
import { FEATURE_FLAGS } from "flags";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import AiTaskButtonComponent, {
  AiTaskButtonVariant,
} from "modules/ai-tasks/components/AiTaskButtonComponent";
import AiTaskCardComponent from "modules/ai-tasks/components/AiTaskCardComponent";
import { CreateTaskModal } from "modules/ai-tasks/components/AiTaskModalComponent";
import { aiTasksQueryHooks } from "modules/ai-tasks/queries/useAiTasks";
import { insightsSheetQueryHooks } from "modules/ai-tasks/queries/useInsightsSheet";
import { useIsBusinessActive } from "modules/business/queries/useBusiness";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { FC, useState } from "react";
import { AiTaskRep } from "reps/ai-tasks/AiTaskRep";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

const TasksPageContent: FC = () => {
  const businessGuid = useBusinessGuid();
  const isBusinessActive = useIsBusinessActive();

  const tasks = aiTasksQueryHooks.useData({ params: { businessGuid } });
  const insightsSheet = insightsSheetQueryHooks.useData({ params: { businessGuid } });

  const aiTasksAuditingViewEnabled = useFeatureFlag(FEATURE_FLAGS["ai-tasks-auditing"]);
  const aiTasksTreasuryViewEnabled = useFeatureFlag(FEATURE_FLAGS["ai-tasks-treasury"]);

  const bookkeepingTask = tasks.find(
    (task: AiTaskRep) => task.type === "CleanAndCategorizeTransactions"
  );

  const treasuryTask = tasks.find((task: AiTaskRep) => task.type === "MaximizeYield");
  const analystTask = tasks.find((task: AiTaskRep) => task.type === "CashFlowForecast");
  const auditingTask = tasks.find((task: AiTaskRep) => task.type === "AuditingAndAlerts");

  return (
    <DashboardPage.Section className="grid grid-cols-1 gap-6 tablet:grid-cols-3">
      <AiTaskCardComponent
        icon={<BookOpen size={28} />}
        title={"Bookkeeper"}
        description={
          "Clean and categorize my transactions daily across all my Highbeam and connected accounts."
        }
        task={bookkeepingTask!}
      />
      <AiTaskCardComponent
        icon={<FileSearch size={28} />}
        title={"Analyst"}
        description={"Create and maintain a live 13-week cash flow forecast for me."}
        task={analystTask!}
        googleSheetUrl={insightsSheet?.googleSheetUrl}
      />
      {aiTasksTreasuryViewEnabled && (
        <AiTaskCardComponent
          icon={<Money size={28} />}
          title={"Treasury"}
          description={"Maximize daily yield across all my bank accounts."}
          task={treasuryTask!}
          isBusinessActive={isBusinessActive}
        />
      )}
      {aiTasksAuditingViewEnabled && (
        <AiTaskCardComponent
          icon={<Megaphone size={28} className="-scale-x-100" />}
          title={"Auditing & Alerts"}
          description={
            "Weekly executive report email with cash flow, return rate, and any transactions to double-check."
          }
          task={auditingTask!}
        />
      )}
    </DashboardPage.Section>
  );
};

const TasksPage = () => {
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState(false);

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>Tasks</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header
          actions={
            <AiTaskButtonComponent
              variant={AiTaskButtonVariant.CreateTask}
              onPrimaryButtonClick={() => setIsCreateTaskModalOpen(true)}
              onSecondaryButtonClick={() => {}}
            />
          }
        >
          <DashboardPage.Header.IconTile icon={<Lightning />} />
          <DashboardPage.Header.Title>Tasks</DashboardPage.Header.Title>
        </DashboardPage.Header>

        <TasksPageContent />
      </DashboardPage>

      <CreateTaskModal
        isOpen={isCreateTaskModalOpen}
        onClose={() => setIsCreateTaskModalOpen(false)}
        title="Create task"
        onConfirm={() => {
          setIsCreateTaskModalOpen(false);
        }}
        onCancel={() => {
          setIsCreateTaskModalOpen(false);
        }}
      />
    </>
  );
};

export default TasksPage;
