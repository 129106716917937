const opacityPercentToHex = (opacityPercent: number): string => {
  // Convert percentage to a value between 0-255
  const alpha = Math.round((Math.max(0, Math.min(100, opacityPercent)) / 100) * 255);

  // Convert to hex and ensure two characters
  return alpha.toString(16).padStart(2, "0").toUpperCase();
};

const hexWithOpacityPercent = (hex: string, opacityPercent: number): string => {
  const opacityHex = opacityPercentToHex(opacityPercent);
  return `${hex}${opacityHex}`;
};

export { opacityPercentToHex, hexWithOpacityPercent };
