import SubcategoryRep from "modules/transaction-subcategories/reps/SubcategoryRep";

import TransactionSubcategoryDropdownLabel from "../components/TransactionSubcategoryDropdownLabel";

// NB(alex): This hack is necessary until our `Dropdown` is composable.

const getTransactionSubcategoryOption = (subcategory: SubcategoryRep.Complete) => {
  return {
    value: subcategory.guid,
    subcategory: subcategory,
    label: <TransactionSubcategoryDropdownLabel subcategory={subcategory} />,
  };
};

export default getTransactionSubcategoryOption;
