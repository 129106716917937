import { ApiBuilder, useBackendV1Api } from "modules/highbeam-api/base";
import CapitalTransactionLimitsRep from "reps/CapitalTransactionLimitsRep";

type CapitalTransactionLimitsApi = {
  get: (
    businessGuid: string,
    capitalAccountGuid: string
  ) => Promise<CapitalTransactionLimitsRep.Complete>;
};

const builder: ApiBuilder<CapitalTransactionLimitsApi> = (api) => ({
  get: async (businessGuid, capitalAccountGuid) => {
    const qp = new URLSearchParams({ businessGuid, capitalAccountGuid });
    const url = `/capital-transaction-limits?${qp}`;
    return (await api.get<CapitalTransactionLimitsRep.Complete>(url))!;
  },
});

const useCapitalTransactionLimitsApi = () => useBackendV1Api(builder);

export default useCapitalTransactionLimitsApi;
