import { AccountBalanceHistoryAugmented } from "./augment-balance-history";
import {
  checkIsHighbeamHighYieldNormalizedAccountAugmented,
  checkIsHighbeamNormalizedAccountAugmented,
} from "./normalized-account-type-guards";

export const sortBalanceHistoryAccounts = (
  a: AccountBalanceHistoryAugmented,
  b: AccountBalanceHistoryAugmented
) => {
  const isAHighbeamAccount = checkIsHighbeamNormalizedAccountAugmented(a.normalizedAccount);
  const isBHighbeamAccount = checkIsHighbeamNormalizedAccountAugmented(b.normalizedAccount);

  // Highbeam accounts always come first.
  if (isAHighbeamAccount && !isBHighbeamAccount) return -1;
  if (!isAHighbeamAccount && isBHighbeamAccount) return 1;

  if (isAHighbeamAccount && isBHighbeamAccount) {
    // Primary account at the top.
    if (a.normalizedAccount.isPrimary && !b.normalizedAccount.isPrimary) return -1;
    if (!a.normalizedAccount.isPrimary && b.normalizedAccount.isPrimary) return 1;

    // High yield after regular accounts.
    const isAHighYieldAccount = checkIsHighbeamHighYieldNormalizedAccountAugmented(
      a.normalizedAccount
    );
    const isBHighYieldAccount = checkIsHighbeamHighYieldNormalizedAccountAugmented(
      b.normalizedAccount
    );
    if (isAHighYieldAccount && !isBHighYieldAccount) return 1;
    if (!isAHighYieldAccount && isBHighYieldAccount) return -1;

    // Sort alphabetically by account name otherwise.
    return a.normalizedAccount.accountName.localeCompare(b.normalizedAccount.accountName);
  }

  if (!isAHighbeamAccount && !isBHighbeamAccount) {
    if (a.normalizedAccount.institutionId && b.normalizedAccount.institutionId) {
      if (a.normalizedAccount.institutionId !== b.normalizedAccount.institutionId) {
        return a.normalizedAccount.institutionId.localeCompare(b.normalizedAccount.institutionId);
      } else {
        return a.normalizedAccount.accountName.localeCompare(b.normalizedAccount.accountName);
      }
    }
  }

  return 0;
};
