import { FC, ReactNode } from "react";
import { SetRequired } from "type-fest";

import TextCell from "./TextCell";

type CounterpartyCellLogoProps = SetRequired<JSX.IntrinsicElements["img"], "src">;

const CounterpartyCellLogo: FC<CounterpartyCellLogoProps> = ({ src, ...imgProps }) => {
  return <img src={src} alt="Logo" className="size-3.5" {...imgProps} />;
};

type Props = {
  children: ReactNode;
  overflowEllipsis?: boolean;
  className?: string;
};

const CounterpartyCell: FC<Props> = ({ children, overflowEllipsis, className }) => {
  return (
    <TextCell overflowEllipsis={overflowEllipsis} darken className={className}>
      {children}
    </TextCell>
  );
};

export default Object.assign(CounterpartyCell, {
  Logo: CounterpartyCellLogo,
});
