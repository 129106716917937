import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
  useSuspenseQuery,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import { Simplify } from "type-fest";

import RequiredButNotFoundError from "./RequiredButNotFoundError";
import useRefreshQuery, { UseRefreshQueryOptions } from "./useRefreshQuery";

// NB(alex): Experimental but cautiously optimistic about this! It may be brittle / may not handle all cases yet so please use with caution.

/**
 * Generates a set of query hooks in a standardized format and preserves type-saftey and type inference of types.
 */
const makeQueryHooks = <TParams, TVariables, TQueryData, TError>(config: {
  /**
   * Shared root query key which allows us to invalidate many queries at once by invalidating the root.
   */
  rootName?: string;
  /**
   * Name of the resource.
   */
  name: string;
  /**
   * Function used for converting `params` into `queryVariables` which get passed into `useQueryFnMaker`.
   */
  useQueryVariables: (params: TParams) => TVariables;
  /**
   * Shared query function that gets injected into `.useQuery` and `.useSuspenseQuery`.
   */
  useQueryFnMaker: (variables: TVariables) => QueryFunction<TQueryData>;
}) => {
  const { rootName, name, useQueryVariables, useQueryFnMaker } = config;

  const baseQueryKey = rootName ? [rootName, name] : [name];

  const useQueryKey = (params: TParams) => {
    const variables = useQueryVariables(params);
    return [...baseQueryKey, variables];
  };

  const useQueryFn = (params: TParams) => {
    const variables = useQueryVariables(params);
    return useQueryFnMaker(variables);
  };

  type BaseSelectFunction = (data: TQueryData) => any;
  type DefaultSelectFunction = (data: TQueryData) => TQueryData;
  type SharedQueryParams<TSelect extends BaseSelectFunction> = TParams & { select?: TSelect };
  type OmitQueryOptionKeys = "queryKey" | "queryFn" | "select";

  type UseSuspenseQueryParams<TSelect extends BaseSelectFunction> = Simplify<
    SharedQueryParams<TSelect> &
      Omit<
        UseSuspenseQueryOptions<
          TQueryData,
          TError,
          ReturnType<TSelect>,
          ReturnType<typeof useQueryKey>
        >,
        OmitQueryOptionKeys
      >
  >;

  type UseQueryParams<TSelect extends BaseSelectFunction> = Simplify<
    SharedQueryParams<TSelect> &
      Omit<
        UseQueryOptions<TQueryData, TError, ReturnType<TSelect>, ReturnType<typeof useQueryKey>>,
        OmitQueryOptionKeys
      >
  >;

  return {
    rootName: rootName,
    name: name,
    baseQueryKey: baseQueryKey,
    useQueryVariables: useQueryVariables,
    useQueryKey: useQueryKey,
    useQueryFn: useQueryFn,
    useQueryOptions: (params: TParams) => {
      return {
        queryKey: useQueryKey(params),
        queryFn: useQueryFn(params),
      };
    },
    useQuery<TSelect extends BaseSelectFunction = DefaultSelectFunction>(
      params: UseQueryParams<TSelect>
    ): UseQueryResult<ReturnType<TSelect>, TError> {
      return useQuery({
        ...params,
        queryKey: useQueryKey(params),
        queryFn: useQueryFn(params),
      });
    },
    useSuspenseQuery<TSelect extends BaseSelectFunction = DefaultSelectFunction>(
      params: UseSuspenseQueryParams<TSelect>
    ): UseSuspenseQueryResult<ReturnType<TSelect>, TError> {
      return useSuspenseQuery({
        ...params,
        queryKey: useQueryKey(params),
        queryFn: useQueryFn(params),
      });
    },
    // NB(alex): Duplicates `this.useSuspenseQuery` but wasn't sure how to extend it without losing types.
    useData<TSelect extends BaseSelectFunction = DefaultSelectFunction>(
      params: UseSuspenseQueryParams<TSelect>
    ): ReturnType<TSelect> {
      const { data } = useSuspenseQuery({
        ...params,
        queryKey: useQueryKey(params),
        queryFn: useQueryFn(params),
      });
      return data;
    },
    // NB(alex): Duplicates `this.useSuspenseQuery` but wasn't sure how to extend it without losing types.
    useDataRequired<TSelect extends BaseSelectFunction = DefaultSelectFunction>(
      params: UseSuspenseQueryParams<TSelect>
    ): Simplify<NonNullable<ReturnType<TSelect>>> {
      const { data } = useSuspenseQuery({
        ...params,
        queryKey: useQueryKey(params),
        queryFn: useQueryFn(params),
      });

      if (data === null) {
        throw new RequiredButNotFoundError();
      }

      return data;
    },
    // Still need to figure out the correct pattern here...
    useRefreshQuery(params: TParams, options?: UseRefreshQueryOptions) {
      return useRefreshQuery(useQueryKey(params), options);
    },
    useRefreshQueries(options?: UseRefreshQueryOptions) {
      return useRefreshQuery(baseQueryKey, options);
    },
    // This may be used to invalidate all queries based on the root name.
    // This should only be used when there is a root name.
    // (If there is no root name, this hook will fallback to the same behavior as `useRefreshQueries`).
    useRefreshQueriesByRootName(options?: UseRefreshQueryOptions) {
      const queryKey = rootName ? [rootName] : baseQueryKey;
      return useRefreshQuery(queryKey, options);
    },
  };
};

export default makeQueryHooks;
