import { ChannelRep } from "modules/chat/reps/ChannelRep";
import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";

type ChannelApi = {
  get: (channelId: string) => Promise<ChannelRep | null>;
  listByBusiness: (businessGuid: string) => Promise<ChannelRep[]>;
  search: (businessGuid: string, chatUserId: string) => Promise<ChannelRep[]>;
};

const builder: ApiBuilder<ChannelApi> = (api) => ({
  get: async (channelId) => {
    const url = `/chat/channels/${channelId}`;
    return await api.get<ChannelRep>(url);
  },
  listByBusiness: async (businessGuid) => {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/chat/channels?${queryParams}`;
    return (await api.get<ChannelRep[]>(url))!;
  },
  search: async (businessGuid, chatUserId) => {
    const queryParams = new URLSearchParams({ businessGuid, chatUserId });
    const url = `/chat/channels?${queryParams}`;
    return (await api.get<ChannelRep[]>(url))!;
  },
});

const useChannelApi = () => useBackendV2Api(builder);

export default useChannelApi;
