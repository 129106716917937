import { captureException } from "@sentry/react";
import checkInsightsAppEnabled from "modules/insights-app/checkInsightsAppEnabled";
import { useCallback, useState } from "react";
import { notify } from "ui/feedback/Toast";
import sleep from "utils/async/sleep";

import { shopifyConnectionRedirectUrlQueryHooks } from "../queries/useShopifyConnectionRedirectUrl";

import { SupportedRedirectUrl } from "./shopify-supported-redirect-url";

type Variables = {
  storeName: string;
  redirectPathKey: SupportedRedirectUrl;
};

const useConnectShopifyStore = () => {
  const isInsightsApp = checkInsightsAppEnabled();
  const fetchShopifyConnectionRedirectUrl = shopifyConnectionRedirectUrlQueryHooks.useFetchQuery();
  const [isLoading, setIsLoading] = useState(false);

  const connectShopifyStore = useCallback(
    async ({ storeName, redirectPathKey }: Variables) => {
      setIsLoading(true);
      if (isInsightsApp) {
        window.location.assign("https://apps.shopify.com/2f8373253660d802d07fa892e81c07c6");
      } else {
        try {
          // NB(alex): Hard-coded localhost override because the server rejects localhost.
          const origin = window.location.origin.includes("localhost")
            ? "https://app.staging.highbeam.co"
            : window.location.origin;
          const redirectUrl = await fetchShopifyConnectionRedirectUrl({
            callbackUrl: `${origin}/loading-shopify?redirect=${redirectPathKey}`,
            storeName: storeName,
          });
          window.location.assign(redirectUrl.url);
        } catch (err) {
          if (err instanceof Error) {
            const errorMessage = `${err.message}. Please try again or contact support.`;
            notify("error", errorMessage);
          } else {
            notify("error", "An unknown error occurred. Please try again or contact support.");
          }
          captureException(err);
        }
      }

      // Artificially prolong the loading state or it stops loading while the browser redirects.
      await sleep(2000);
      setIsLoading(false);
      return void null;
    },
    [fetchShopifyConnectionRedirectUrl, isInsightsApp]
  );

  return { connectShopifyStore, isLoading };
};

export default useConnectShopifyStore;
