import { AuthorizationQueryParams } from "@highbeam/unit-node-sdk";
import useBusinessUnitCoCustomerId from "modules/business/queries/useBusinessUnitCoCustomerId";
import { useUnitApiOrThrow } from "modules/unit-co-customer-token/queries/useUnitApi";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";

const ROOT_UNIT_AUTHORIZATIONS_QUERY_KEY = "unitAuthorizations--root";

const unitAuthorizationsQueryHooks = makeQueryHooksV2({
  useQueryFnParams: () => {
    const unitApi = useUnitApiOrThrow();
    return { unitApi };
  },
  useDefaultParams: () => {
    const customerId = useBusinessUnitCoCustomerId({ required: true });
    return { customerId };
  },
  makeQueryKey: (params: AuthorizationQueryParams) => {
    return ["unitAuthorizations", params] as const;
  },
  makeQueryFn: ({ unitApi }, params) => {
    return () => {
      return unitApi.authorizations.list({
        sort: "-createdAt",
        ...params,
      });
    };
  },
});

export { ROOT_UNIT_AUTHORIZATIONS_QUERY_KEY, unitAuthorizationsQueryHooks };
