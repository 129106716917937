import dayjs from "dayjs";

import {
  FinancialAccountSyncStatusRep,
  IntelligenceStatusRep,
  ShopifySyncStatusRep,
} from "../reps/IntelligenceStatusRep";

const filterFinancialAccountsBeingCleaned = (
  financialAccounts: FinancialAccountSyncStatusRep[]
) => {
  return financialAccounts.filter((fa) => {
    const allTransactionsEnriched = fa.totalEnrichedTransactions === fa.totalTransactions;

    const accountCreatedWithinLastHour = dayjs(fa.normalizedAccountCreatedAt).isAfter(
      dayjs().subtract(1, "hour")
    );

    return !allTransactionsEnriched || accountCreatedWithinLastHour;
  });
};

const getIntelligenceStatusWithFinancialAccountsBeingCleaned = (
  intelligenceStatus: IntelligenceStatusRep
) => {
  return {
    ...intelligenceStatus,
    financialAccounts: filterFinancialAccountsBeingCleaned(intelligenceStatus.financialAccounts),
  };
};

const finishedCleaningEveryFinancialAccount = (
  financialAccounts: FinancialAccountSyncStatusRep[]
) => {
  return financialAccounts.every((financialAccount) => {
    const wasCreatedWithinLastHour = dayjs(financialAccount.normalizedAccountCreatedAt).isAfter(
      dayjs().subtract(1, "hour")
    );
    const isFullyCleaned =
      financialAccount.totalEnrichedTransactions === financialAccount.totalTransactions;

    // If older than an hour, we ignore accounts that have 0 transactions.
    if (wasCreatedWithinLastHour) {
      return isFullyCleaned && financialAccount.totalTransactions !== 0;
    } else {
      return isFullyCleaned;
    }
  });
};

const finishedCleaningShopifyOrders = (shopifyStatus: ShopifySyncStatusRep | null) => {
  return !shopifyStatus || shopifyStatus.ordersSynced;
};

const finishedCleaningIntelligenceData = (intelligenceStatus: IntelligenceStatusRep) => {
  return (
    finishedCleaningEveryFinancialAccount(intelligenceStatus.financialAccounts) &&
    finishedCleaningShopifyOrders(intelligenceStatus.shopifyStatus)
  );
};

export {
  filterFinancialAccountsBeingCleaned,
  getIntelligenceStatusWithFinancialAccountsBeingCleaned,
  finishedCleaningEveryFinancialAccount,
  finishedCleaningShopifyOrders,
  finishedCleaningIntelligenceData,
};
