import React from "react";
import cn from "utils/tailwind/cn";

import styles from "./DotsLoader.module.scss";

type Props = {
  dots: number;
  small?: boolean;
  className?: string;
};

const DotsLoader: React.FC<Props> = ({ dots, small = false, className }) => {
  const dotLoader = Array(dots)
    .fill(undefined)
    .map((_, i) => <div key={i} className={cn(styles.dot, small && styles.small)} />);

  return <div className={cn(styles.dots, small && styles.small, className)}>{dotLoader}</div>;
};

export default DotsLoader;
