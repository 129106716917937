import { capitalize } from "lodash-es";
import { maskAccountNumber } from "utils/account";
import { removeTextFromEndAndTrim } from "utils/string";

type Params = {
  accountName: string;
  accountNumber?: string;
  status?: string;
};

const getAccountDisplayName = ({ accountName, accountNumber, status }: Params) => {
  // TODO(gautam): Remove this hack once HB-4936 is completed.
  const accountNameWithoutThread = removeTextFromEndAndTrim(accountName, "(Thread)");
  return `${accountNameWithoutThread}${accountNumber ? ` ${maskAccountNumber(accountNumber)}` : ""}${status ? ` (${capitalize(status.toLowerCase())})` : ""}`;
};

export default getAccountDisplayName;
