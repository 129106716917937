import { Minus, WarningCircle } from "@phosphor-icons/react";
import AccountLabel from "components/Accounts/AccountLabel";
import TransactionFlexpaneParties from "components/common/transaction-flexpane/TransactionFlexpaneParties";
import TransactionFlexpaneStatus from "components/common/transaction-flexpane/TransactionFlexpaneStatus";
import { ACH_CUTOFF_WARNING } from "config/constants/banking";
import { FEATURE_FLAGS } from "flags";
import { useOpenBankAccount } from "modules/bank-accounts/queries/useBankAccount";
import useConfirm from "modules/confirmation-modal/useConfirm";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import { usePayeeQuery } from "modules/payees/queries/usePayee";
import useRejectPaymentMutation from "modules/payment-approvals/mutations/useRejectPaymentMutation";
import usePaymentApproval from "modules/payment-approvals/queries/usePaymentApproval";
import getPaymentApprovalMethodName from "modules/payment-approvals/utils/getPaymentApprovalMethodName";
import { FC } from "react";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import ProgressBar from "ui/data-visualization/ProgressBar";
import Shimmer from "ui/feedback/Shimmer";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import Dropdown from "ui/inputs/Dropdown";
import Helper from "ui/inputs/Helper";
import Flexpane from "ui/overlay/Flexpane";
import Text from "ui/typography/Text";
import { percentage } from "utils/string";

import ApprovePaymentButton from "./components/ApprovePaymentButton";
import PaymentApprovalAmount from "./components/PaymentApprovalAmount";
import PaymentApprovalInvoice from "./components/PaymentApprovalInvoice";
import PaymentApprovalMetadata from "./components/PaymentApprovalMetadata";
import PaymentApprovalPayeeNotificationSection from "./components/PaymentApprovalPayeeNotificationSection";
import PaymentApprovalSubmittedBy from "./components/PaymentApprovalSubmittedBy";
import PaymentApprovalFlexpaneProvider, {
  usePaymentApprovalFlexpaneContext,
} from "./context/PaymentApprovalFlexpaneProvider";
import styles from "./PaymentApprovalFlexpane.module.scss";

const PaymentApprovalParties = () => {
  const { paymentApprovalGuid } = usePaymentApprovalFlexpaneContext();
  const paymentApproval = usePaymentApproval(paymentApprovalGuid, { required: true });
  const bankAccount = useOpenBankAccount(paymentApproval.bankAccountGuid);
  const { data: payee } = usePayeeQuery(paymentApproval.payeeGuid);

  if (!bankAccount || !paymentApproval) {
    return null;
  }

  const longMethodName = getPaymentApprovalMethodName(paymentApproval).long;

  return (
    <TransactionFlexpaneParties
      from={<AccountLabel bankAccount={bankAccount} />}
      status={
        <TransactionFlexpaneStatus
          pillColor="purple-100"
          status="Needs approval"
          methodName={longMethodName}
        />
      }
      to={
        <Text color={colors.grey[900]} size={14} weight="medium">
          {payee === undefined ? <Shimmer /> : payee === null ? <Minus /> : payee.name}
        </Text>
      }
    />
  );
};

const PaymentLimitExceededWarning = () => {
  const {
    paymentApprovalGuid,
    transferFrom,
    limitToDisplay,
    currentLimitPlusAmount,
    isApproachingMonthlyLimit,
  } = usePaymentApprovalFlexpaneContext();
  const paymentApproval = usePaymentApproval(paymentApprovalGuid, { required: true });
  const methodName = getPaymentApprovalMethodName(paymentApproval);
  const allowBankAccountUpdate = useFeatureFlag(
    FEATURE_FLAGS["payment-flow-allow-bank-account-update"]
  );

  if (!limitToDisplay || !currentLimitPlusAmount || !transferFrom) {
    return null;
  }

  const warningContent = allowBankAccountUpdate ? (
    <Text size={14} color={colors.yellow[800]}>
      This payment exceeds the {isApproachingMonthlyLimit ? "monthly " : "daily "}
      {methodName.long} limit for{" "}
      <strong>{transferFrom.accountDisplayName || transferFrom.accountName}</strong>. Please
      consider rejecting this payment and resending it from a different account.
    </Text>
  ) : (
    <Text size={14} color={colors.yellow[800]}>
      This payment exceeds the {isApproachingMonthlyLimit ? "monthly " : "daily "}
      {methodName.long} limit for{" "}
      <strong>{transferFrom.accountDisplayName || transferFrom.accountName}</strong>.{" "}
      {isApproachingMonthlyLimit
        ? "Please consider rejecting this payment and resending it as a wire. "
        : "Please consider rejecting this payment and resending it from a different account, or wait until tomorrow to approve it."}
    </Text>
  );

  return (
    <div className={styles.warningContainer}>
      <div>
        <WarningCircle size={20} color={colors.yellow[800]} />
      </div>
      <div className={styles.warningTitleContainer}>
        {warningContent}
        <div className={styles.progressBarContainer}>
          <ProgressBar
            className={styles.progressBar}
            percent={percentage(currentLimitPlusAmount, limitToDisplay)}
            color={colors.orange[400]}
          />
          <div>
            <div className={styles.amountContainer}>
              <MoneyAmount
                size={14}
                weight="medium"
                color={colors.orange[600]}
                centsTextSize={14}
                centsTextWeight="regular"
                cents={currentLimitPlusAmount}
              />

              <Text size={14}>/</Text>

              <MoneyAmount size={14} color={colors.grey[500]} cents={limitToDisplay} withCents />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PaymentAccount = () => {
  const { supportedTransferOptions, transferFrom, setTransferFrom, limitToDisplay } =
    usePaymentApprovalFlexpaneContext();
  const allowBankAccountUpdate = useFeatureFlag(
    FEATURE_FLAGS["payment-flow-allow-bank-account-update"]
  );

  if (!limitToDisplay || (allowBankAccountUpdate && !supportedTransferOptions.length)) {
    return null;
  }

  return (
    <Flexpane.Section>
      <PaymentLimitExceededWarning />
      {allowBankAccountUpdate && supportedTransferOptions.length > 0 && (
        <>
          <Dropdown
            value={transferFrom}
            onChange={(from) => setTransferFrom(from)}
            id="send-from"
            label="Send from"
            options={supportedTransferOptions}
            isSearchable={false}
          />
          {transferFrom && (
            <Helper className="text-xs">
              Account balance:{" "}
              <MoneyAmount weight="medium" size={12} cents={transferFrom.availableBalanceInCents} />
            </Helper>
          )}
        </>
      )}
    </Flexpane.Section>
  );
};

const RejectPaymentButton = () => {
  const { mutateAsync: rejectPayment, isPending } = useRejectPaymentMutation();
  const { paymentApprovalGuid } = usePaymentApprovalFlexpaneContext();
  const paymentApproval = usePaymentApproval(paymentApprovalGuid, { required: true });
  const { close: closePaymentApprovalFlexpane } = usePaymentApprovalFlexpaneContext();
  const confirm = useConfirm();

  return (
    <Button
      variant="danger"
      onClick={async () => {
        const confirmed = await confirm({
          header: "Confirm payment rejection",
          message: "Are you sure you want to reject this payment?",
          buttonLabel: "Reject payment",
          variant: "danger",
        });

        if (!confirmed) return;

        try {
          await rejectPayment({ paymentGuid: paymentApproval.guid });
          closePaymentApprovalFlexpane();
          notify("success", "Payment rejected");
        } catch (err) {
          notify("error", "There was an error rejecting the payment. Please try again.");
        }
      }}
      isLoading={isPending}
    >
      Reject
    </Button>
  );
};

type Props = {
  paymentApprovalGuid?: string;
  onClose: () => void;
};

const PaymentApprovalFlexpane: FC<Props> = ({ paymentApprovalGuid, onClose }) => {
  const isOpen = Boolean(paymentApprovalGuid);

  return (
    <Flexpane isOpen={isOpen} onClose={onClose}>
      {paymentApprovalGuid && (
        <PaymentApprovalFlexpaneProvider paymentApprovalGuid={paymentApprovalGuid} close={onClose}>
          <Flexpane.Header onClose={onClose}>Review draft payment</Flexpane.Header>

          <Flexpane.Section>
            <PaymentApprovalParties />
          </Flexpane.Section>

          <PaymentAccount />

          <Flexpane.Section>
            <PaymentApprovalSubmittedBy />
          </Flexpane.Section>

          <Flexpane.Section>
            <PaymentApprovalAmount />
          </Flexpane.Section>

          <Flexpane.Section>
            <PaymentApprovalMetadata />
          </Flexpane.Section>

          <PaymentApprovalPayeeNotificationSection />

          <PaymentApprovalInvoice />

          <Flexpane.Section>
            <div className={styles.buttonContainer}>
              <ApprovePaymentButton />
              <RejectPaymentButton />
            </div>
            <Text color={colors.grey[600]} size={14}>
              By clicking Approve and send, I authorize the above amount to be debited from the bank
              account shown above. {ACH_CUTOFF_WARNING}
            </Text>
          </Flexpane.Section>
        </PaymentApprovalFlexpaneProvider>
      )}
    </Flexpane>
  );
};

export default PaymentApprovalFlexpane;
