import { NormalizedAccountRep } from "modules/insights/reps/NormalizedAccountRep";

import {
  NormalizedAccountAugmented,
  NormalizedHighbeamAccountAugmented,
  NormalizedPlaidAccountAugmented,
} from "./augment-normalized-account";

// Base rep

export const checkIsHighbeamNormalizedAccountRep = (normalizedAccount: NormalizedAccountRep) => {
  return normalizedAccount.institutionName === "Highbeam";
};

export const checkIsHighbeamHighYieldNormalizedAccountRep = (
  normalizedAccount: NormalizedAccountRep
) => {
  return (
    checkIsHighbeamNormalizedAccountRep(normalizedAccount) &&
    normalizedAccount.subtype === "High Yield" &&
    normalizedAccount.source === "Unit"
  );
};

export const checkIsPlaidNormalizedAccountRep = (normalizedAccount: NormalizedAccountRep) => {
  return normalizedAccount.source === "Plaid";
};

// Augmented

export const checkIsHighbeamNormalizedAccountAugmented = (
  normalizedAccount: NormalizedAccountAugmented
): normalizedAccount is NormalizedHighbeamAccountAugmented => {
  return normalizedAccount.repType === "highbeam-account";
};

export const checkIsHighbeamHighYieldNormalizedAccountAugmented = (
  normalizedAccount: NormalizedAccountAugmented
): normalizedAccount is NormalizedHighbeamAccountAugmented => {
  return (
    checkIsHighbeamNormalizedAccountAugmented(normalizedAccount) &&
    normalizedAccount.rep.highbeamType.name === "HighYield"
  );
};

export const checkIsPlaidNormalizedAccountAugmented = (
  normalizedAccount: NormalizedAccountAugmented
): normalizedAccount is NormalizedPlaidAccountAugmented => {
  return normalizedAccount.repType === "plaid-account";
};
