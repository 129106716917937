import useCapitalTransactionLimitsApi from "modules/capital-account-transactions/api/useCapitalTransactionLimitsApi";
import { ROOT_CAPITAL_ACCOUNTS_QUERY_KEY } from "modules/capital-accounts/queries/useCapitalAccounts";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

const drawdownPreApprovedLimitQueryHooks = makeQueryHooks({
  rootName: ROOT_CAPITAL_ACCOUNTS_QUERY_KEY,
  name: "drawdownPreApprovedLimit",
  useQueryVariables: (params: { businessGuid: string; capitalAccountGuid: string }) => params,
  useQueryFnMaker: ({ businessGuid, capitalAccountGuid }) => {
    const capitalTransactionLimitsApi = useCapitalTransactionLimitsApi();

    return async () => {
      const { preApprovedLimit } = await capitalTransactionLimitsApi.get(
        businessGuid,
        capitalAccountGuid
      );

      return preApprovedLimit;
    };
  },
});

export default drawdownPreApprovedLimitQueryHooks;
