import { CaretRight } from "@phosphor-icons/react";
import { Command as CommandPrimitive } from "cmdk";
import { ComponentPropsWithoutRef, ComponentRef, FC, forwardRef } from "react";
import { Heading4 } from "ui/typography";
import cn from "utils/tailwind/cn";

const Cmdk = forwardRef<
  ComponentRef<typeof CommandPrimitive>,
  ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => (
  <CommandPrimitive ref={ref} className={cn("text-sm", className)} {...props} />
));

const CmdkInput = forwardRef<
  ComponentRef<typeof CommandPrimitive.Input>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Input>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Input
    ref={ref}
    className={cn("w-full rounded-lg p-4 outline-none", className)}
    {...props}
  />
));

const CmdkList = forwardRef<
  ComponentRef<typeof CommandPrimitive.List>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.List ref={ref} className={cn("", className)} {...props} />
));

const CmdkGroupHeading = forwardRef<
  ComponentRef<typeof Heading4>,
  ComponentPropsWithoutRef<typeof Heading4>
>(({ className, ...props }, ref) => (
  <Heading4
    ref={ref}
    className={cn("border-b border-grey-100 p-3 text-sm font-medium text-grey-800", className)}
    {...props}
  />
));

const CmdkGroup = forwardRef<
  ComponentRef<typeof CommandPrimitive.Group>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Group ref={ref} className={cn("", className)} {...props} />
));

const CmdkItem = forwardRef<
  ComponentRef<typeof CommandPrimitive.Item>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Item
    ref={ref}
    className={cn(
      "flex cursor-pointer items-center gap-x-4 border-b border-grey-100 p-3 data-[selected=true]:bg-grey-50",
      className
    )}
    {...props}
  />
));

const CmdkCaretRight: FC<{ className?: string }> = ({ className }) => {
  return (
    <CaretRight size={16} weight="light" className={cn("shrink-0 text-grey-400", className)} />
  );
};

export default Object.assign(Cmdk, {
  Input: CmdkInput,
  List: CmdkList,
  GroupHeading: CmdkGroupHeading,
  Group: CmdkGroup,
  Item: CmdkItem,
  CaretRight: CmdkCaretRight,
});
