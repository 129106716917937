import { TabConfig } from "utils/tabs/useTabState";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

import TabsTab from "./TabsTab";

type TabsJustify = "start" | "space-around";

type Props<TTabs extends TabConfig> = {
  className?: string;
  activeTab: keyof TTabs;
  setActiveTab: (tab: keyof TTabs) => void;
  tabs: TTabs;
  justify?: TabsJustify;
};

const TabsV2 = <TTabs extends TabConfig>({
  className,
  activeTab,
  setActiveTab,
  tabs,
  justify = "start",
}: Props<TTabs>) => {
  const tabsArray = Object.entries(tabs);
  return (
    <div
      className={cn(
        "flex w-full items-center border-b-2 border-b-grey-200 @container",
        variants(justify, {
          start: "justify-start",
          "space-around": "justify-around",
        }),
        className
      )}
    >
      {tabsArray.map(([tabName, { icon, label, count, badge }]) => {
        const isActive = tabName === activeTab;
        return (
          <button
            key={tabName}
            className={cn(
              "mb-[-2px] flex items-center justify-center border-b-2 border-b-grey-200",
              variants(justify, {
                start: "flex-none",
                "space-around": "flex-1",
              }),
              isActive && "border-b-purple-500"
            )}
            role="tab"
            type="button"
            aria-selected={isActive}
            onClick={() => setActiveTab(tabName)}
          >
            <TabsTab icon={icon} isActive={isActive} count={count} badge={badge}>
              {label}
            </TabsTab>
          </button>
        );
      })}
    </div>
  );
};

export default Object.assign(TabsV2, {
  Tab: TabsTab,
});
