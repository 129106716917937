import { Note } from "@phosphor-icons/react";
import Banner from "ui/data-display/Banner";
import IconTile from "ui/icons/IconTile";
import ButtonLink from "ui/inputs/ButtonLink";
import Text from "ui/typography/Text";

const VgsiTermsBanner: React.FC = () => (
  <Banner
    color="white"
    padding="lg"
    className={"mt-4 border-grey-200"}
    icon={<IconTile icon={<Note />} />}
    title="Changes to international wire payment terms"
    paragraph="We've updated our terms with our international wire payments provider. These changes do not affect fees or the payment process."
    button={
      <ButtonLink
        target="_blank"
        variant="secondary"
        to={`https://go.currencycloud.com/hubfs/Product/Legal/VGSI-Customer-Terms.pdf`}
        className="w-full tablet:w-auto"
      >
        <Text size={14} weight="medium">
          Review updates
        </Text>
      </ButtonLink>
    }
  />
);

export default VgsiTermsBanner;
