import useSearchParamNumber from "utils/search-params/useSearchParamNumber";

import { DEFAULT_PAGE_PARAM_KEY } from "./config";

type UsePageOptions = {
  pageParamKey?: string;
};

const usePage = (options?: UsePageOptions) =>
  useSearchParamNumber(options?.pageParamKey ?? DEFAULT_PAGE_PARAM_KEY, 1);

export default usePage;
