import ExchangeRep from "modules/chat/reps/ExchangeRep";
import { useCallback, useEffect, useMemo, useState } from "react";

import getIsAtBottom from "./getIsAtBottom";

// NB(alex): This took me a while to figure out. We only want to count the new exchanges **from
// other users** that have yet to be read. Instead of tallying the number of unread exchanges, it
// has turned out to be much easier to just keep track of the most recently read exchange, and to
// find that exchange's index, and to then get the number of exchanges that have come in since then.

type Params = {
  exchanges: ExchangeRep[];
  scrollContainerElement: HTMLDivElement | null;
};

const useUnreadExchangesCount = ({ exchanges, scrollContainerElement }: Params) => {
  const assistantExchangeIds = useMemo(
    () =>
      exchanges
        .filter((exchange) => exchange.assistantMessage !== null)
        .map((exchange) => exchange.id)
        .toReversed(),
    [exchanges]
  );

  const [lastReadExchangeId, setLastReadExchangeId] = useState<string>(assistantExchangeIds[0]);

  const lastReadExchangeIndex = assistantExchangeIds.indexOf(lastReadExchangeId);

  const [unreadExchangesCount, setUnreadExchangesCount] = useState(0);

  const resetUnreadExchangesCount = useCallback(() => {
    setLastReadExchangeId(assistantExchangeIds[0]);
    setUnreadExchangesCount(0);
  }, [assistantExchangeIds]);

  // Only set the exchanges count if not at bottom, otherwise reset state.
  useEffect(() => {
    if (scrollContainerElement) {
      const isAtBottom = getIsAtBottom(scrollContainerElement);
      if (!isAtBottom && lastReadExchangeId !== undefined) {
        setUnreadExchangesCount(lastReadExchangeIndex);
      } else {
        resetUnreadExchangesCount();
      }
    }
  }, [
    lastReadExchangeId,
    lastReadExchangeIndex,
    resetUnreadExchangesCount,
    scrollContainerElement,
    unreadExchangesCount,
  ]);

  return {
    unreadExchangesCount,
    resetUnreadExchangesCount,
  };
};

export default useUnreadExchangesCount;
