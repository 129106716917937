import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";
import { createURLSearchParamsFromObject } from "utils/search-params/create-url-search-params";

import { NormalizedAccountRep } from "../reps/NormalizedAccountRep";

export type SearchNormalizedAccountQueryParams = {
  businessGuid: string;
};

type NormalizedAccountApi = {
  search: (params: SearchNormalizedAccountQueryParams) => Promise<NormalizedAccountRep[]>;
};

const builder: ApiBuilder<NormalizedAccountApi> = (api) => ({
  search: async (params: SearchNormalizedAccountQueryParams) => {
    const qp = createURLSearchParamsFromObject(params);
    const url = `/insights/normalized-accounts?${qp}`;
    return (await api.get<NormalizedAccountRep[]>(url))!;
  },
});

const useNormalizedAccountApi = () => useBackendV2Api(builder);

export default useNormalizedAccountApi;
