import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";

import useIsAllowedToReadVendorCredits from "../hooks/useIsAllowedToReadVendorCredits";
import useVendorCreditsFeatureFlag from "../hooks/useVendorCreditsFeatureFlag";

const VendorCreditModal = lazy(() => import("./VendorCreditModal"));

type Props = {
  routePrefix?: string;
};

const VendorCreditModalRoutes: FC<Props> = ({ routePrefix = "/" }) => {
  const isVendorCreditsEnabled = useVendorCreditsFeatureFlag();
  const isAllowedToReadVendorCredits = useIsAllowedToReadVendorCredits();

  return (
    <Routes>
      {isAllowedToReadVendorCredits && (
        <Route
          path={`${routePrefix}:vendorCreditId`}
          element={isVendorCreditsEnabled ? <VendorCreditModal /> : null}
        />
      )}
    </Routes>
  );
};

export default VendorCreditModalRoutes;
