import { ReactNode, useEffect } from "react";
import { useRecoilValue } from "recoil";
import auth0ClientState from "state/auth/auth0Client";
import isAuthenticatedState from "state/auth/isAuthenticated";

type Props = {
  children: ReactNode;
};

const AuthenticationRequiredLayout: React.FC<Props> = ({ children }) => {
  const auth0 = useRecoilValue(auth0ClientState);
  const isAuthenticated = useRecoilValue(isAuthenticatedState);

  useEffect(() => {
    if (isAuthenticated) return;
    void auth0.loginWithRedirect({
      appState: {
        returnTo: `${window.location.pathname}${window.location.search}${window.location.hash}`,
      },
      authorizationParams: {
        redirectMethod: "replace",
        screen_hint: "login", // eslint-disable-line camelcase
      },
    });
  }, [auth0, isAuthenticated]);

  if (!isAuthenticated) {
    return null;
  }

  return <>{children}</>;
};

export default AuthenticationRequiredLayout;
