/**
 * Checks to see if an element can be focused.
 */
const isElementFocusable = (el: HTMLElement): boolean => {
  if (!el || el.hidden || el.offsetParent === null) return false; // Checks if element is visible
  const computedStyle = window.getComputedStyle(el);
  if (computedStyle.display === "none" || computedStyle.visibility === "hidden") return false;

  return el.tabIndex >= 0 && !el.hasAttribute("disabled");
};

/**
 * Gets all focusable elements on the page.
 */
const getFocusableElements = (container: HTMLElement = document.body): HTMLElement[] => {
  const focusableSelectors = [
    "a[href]",
    "button:not([disabled])",
    'input:not([disabled]):not([type="hidden"])',
    "select:not([disabled])",
    "textarea:not([disabled])",
    '[tabindex]:not([tabindex="-1"])',
    "details summary",
    '[contenteditable="true"]',
  ].join(",");

  return Array.from(container.querySelectorAll(focusableSelectors)).filter(
    (el): el is HTMLElement => el instanceof HTMLElement && isElementFocusable(el)
  );
};

export const getNextFocusableElement = () => {
  const activeElement = document.activeElement;

  if (!activeElement || !(activeElement instanceof HTMLElement)) {
    return null;
  }

  const focusableElements = getFocusableElements();
  const currentIndex = focusableElements.indexOf(activeElement);
  const nextIndex = (currentIndex + 1) % focusableElements.length;
  const nextElement = focusableElements[nextIndex];

  return nextElement;
};

/**
 * Focuses the next focusable element.
 */
export const focusNextElement = () => {
  const nextFocusableElement = getNextFocusableElement();
  if (!nextFocusableElement) return;
  nextFocusableElement.focus?.();
};

export const getPreviousFocusableElement = () => {
  // Get all focusable elements on the page
  const activeElement = document.activeElement;

  if (!activeElement || !(activeElement instanceof HTMLElement)) {
    return null;
  }

  const focusableElements = getFocusableElements();
  const currentIndex = focusableElements.indexOf(activeElement);
  const previousIndex = (currentIndex - 1 + focusableElements.length) % focusableElements.length;
  const previousElement = focusableElements[previousIndex];

  return previousElement;
};

/**
 * Focuses the previous focusable element.
 */
export const focusPreviousElement = () => {
  const previousFocusableElement = getPreviousFocusableElement();
  if (!previousFocusableElement) return;
  previousFocusableElement.focus?.();
};
