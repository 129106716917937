import { ChatElementRep } from "modules/chat/reps/ChatElementRep";
import React from "react";
import ShimmerV2 from "ui/feedback/ShimmerV2";

import ActionChatElement from "./ActionChatElement";
import BulletedListChatElement from "./BulletedListChatElement";
import Calculator from "./Calculator";
import CategoryChatElement from "./CategoryChatElement";
import ComingSoonChatElement from "./ComingSoonChatElement";
import DateChatElement from "./DateChatElement";
import LineChartChatElement from "./LineChartChatElement";
import LinkChatElement from "./LinkChatElement";
import MoneyChatElement from "./MoneyChatElement";
import MonthChatElement from "./MonthChatElement";
import NumberChatElement from "./NumberChatElement";
import NumberedListChatElement from "./NumberedListChatElement";
import ParagraphChatElement from "./ParagraphChatElement";
import PercentageChatElement from "./PercentageChatElement";
import RecommendConnectingDataChatElement from "./RecommendConnectingDataChatElement";
import TableChatElement from "./TableChatElement";
import TextChatElement from "./TextChatElement";
import TimestampChatElement from "./TimestampChatElement";
import YearChatElement from "./YearChatElement";
import YearMonthChatElement from "./YearMonthChatElement";

type Props = {
  element: ChatElementRep | null;
};

const ChatElement: React.FC<Props> = ({ element }) => {
  if (!element) return <ShimmerV2 />;

  switch (element.type) {
    case "BulletedList":
      return <BulletedListChatElement element={element} />;
    case "Calculator":
      return <Calculator element={element} />;
    case "Category":
      return <CategoryChatElement element={element} />;
    case "ComingSoon":
      return <ComingSoonChatElement element={element} />;
    case "Date":
      return <DateChatElement element={element} />;
    case "LineChart":
      return <LineChartChatElement element={element} />;
    case "Money":
      return <MoneyChatElement element={element} />;
    case "Month":
      return <MonthChatElement element={element} />;
    case "Number":
      return <NumberChatElement element={element} />;
    case "NumberedList":
      return <NumberedListChatElement element={element} />;
    case "Paragraph":
      return <ParagraphChatElement element={element} />;
    case "Percentage":
      return <PercentageChatElement element={element} />;
    case "RecommendConnectingData":
      return <RecommendConnectingDataChatElement element={element} />;
    case "Action":
      return <ActionChatElement element={element} />;
    case "Link":
      return <LinkChatElement element={element} />;
    case "Table":
      return <TableChatElement element={element} />;
    case "Text":
      return <TextChatElement element={element} />;
    case "Timestamp":
      return <TimestampChatElement element={element} />;
    case "Year":
      return <YearChatElement element={element} />;
    case "YearMonth":
      return <YearMonthChatElement element={element} />;
  }
};

export default ChatElement;
