import { FC, useRef } from "react";
import ModalV4, { ModalRef } from "ui/overlay/ModalV4";

import useDeleteConnectedStoreMutation from "../mutations/useDeleteConnectedStoreMutation";
import { ConnectedStoreType } from "../queries/useConnectedStores";

export type DeleteConnectedStoreModalParams = {
  connectedStoreGuid: string;
  connectedStoreType: ConnectedStoreType;
};

type Props = DeleteConnectedStoreModalParams & {
  onClose: () => void;
};

const DeleteConnectedStoreModal: FC<Props> = ({
  onClose,
  connectedStoreGuid,
  connectedStoreType,
}) => {
  const modalRef = useRef<ModalRef>(null);

  const { mutateAsync: deleteConnectedStore, isPending } = useDeleteConnectedStoreMutation({
    onSuccess: () => {
      modalRef.current?.closeModalWithAnimation();
    },
  });

  return (
    <ModalV4 onClose={onClose} modalRef={modalRef}>
      <ModalV4.Header>Disconnect this store?</ModalV4.Header>

      <ModalV4.Body>
        <ModalV4.Paragraph>
          Disconnecting this store will remove the connection that it has with Highbeam. Once
          disconnected, Highbeam won’t be able to get the store data.
        </ModalV4.Paragraph>
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.SubmitButton
          isLoading={isPending}
          onClick={() =>
            deleteConnectedStore({
              connectedStoreGuid,
              connectedStoreType,
            })
          }
        >
          Disconnect store
        </ModalV4.SubmitButton>
      </ModalV4.Footer>
    </ModalV4>
  );
};

export default DeleteConnectedStoreModal;
