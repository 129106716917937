import { ApiBuilder, useBackendV1Api } from "modules/highbeam-api/base";

type BusinessOwnerApi = {
  setBusinessOwner: (businessGuid: string, userGuid: string) => Promise<void | null>;
};

const builder: ApiBuilder<BusinessOwnerApi> = (api) => ({
  setBusinessOwner: async (businessGuid, userGuid) => {
    const url = `/businesses/${businessGuid}/owner/${userGuid}`;
    return api.put<void>(url);
  },
});

const useBusinessOwnerApi = () => useBackendV1Api(builder);

export default useBusinessOwnerApi;
