import { useSuspenseQueries } from "@tanstack/react-query";
import { getRutterPlatformDisplayName } from "utils/rutter";
import { isRutterCommercePlatformOrPaymentProcessorComplete } from "utils/rutter/typeguards";

import { ConnectedStoreType, getRutterConnectedStoreType } from "./useConnectedStores";
import useRutterBalancesQueryOptions from "./useRutterBalancesQueryOptions";
import { rutterConnectionsQueryHooks } from "./useRutterConnections";
import useShopifyBalancesQueryOptions from "./useShopifyBalancesQueryOptions";
import { shopifyConnectionsQueryHooks } from "./useShopifyConnections";

export type ConnectedStoreBalance = {
  shopName: string;
  hasSynced: boolean;
  balance: number;
  platformName: ConnectedStoreType;
};

const useConnectedStoreBalances = () => {
  const connectedStoreBalances = useSuspenseQueries({
    queries: [
      shopifyConnectionsQueryHooks.useQueryOptions({}),
      useShopifyBalancesQueryOptions(),
      rutterConnectionsQueryHooks.useQueryOptions({}),
      useRutterBalancesQueryOptions(),
    ],
    combine: ([
      { data: shopifyConnections },
      { data: shopifyBalances },
      { data: rutterConnections },
      { data: rutterBalances },
    ]) => {
      const shopifyStoreBalances: ConnectedStoreBalance[] = shopifyConnections
        .filter((connection) => connection.isShopifyPaymentsEnabled)
        .map((connection) => {
          const balance = shopifyBalances.reduce((total, balance) => {
            if (balance.connectionGuid === connection.guid) total += balance.amount;
            return total;
          }, 0);

          return {
            shopName: connection.shopName,
            hasSynced: connection.hasSyncedBalances,
            balance: balance,
            platformName: "Shopify",
          };
        });

      const rutterStoreBalances: ConnectedStoreBalance[] = rutterConnections
        .filter(isRutterCommercePlatformOrPaymentProcessorComplete)
        .map((connection) => {
          const balance = rutterBalances.reduce((total, balance) => {
            if (balance.connectionGuid === connection.guid) total += balance.amount;
            return total;
          }, 0);
          return {
            shopName: connection.shopName || getRutterPlatformDisplayName(connection.platformName),
            hasSynced: connection.hasSyncedBalances,
            balance,
            platformName: getRutterConnectedStoreType(connection.platformName),
          };
        });

      return [...shopifyStoreBalances, ...rutterStoreBalances];
    },
  });

  return connectedStoreBalances;
};

export default useConnectedStoreBalances;
