import aiChatChartExample from "modules/onboarding/assets/ai-chat-chart-example.webp";
import ConnectYourAccountsView from "modules/onboarding/components/onboarding-connections/ConnectYourAccountsView";
import ButtonLink from "ui/inputs/ButtonLink";

import { onboardingRoutes } from "./config";
import OnboardingSideBySide from "./OnboardingSideBySide";

const ConnectAccountsExplainerPage = () => {
  return (
    <>
      <OnboardingSideBySide>
        <OnboardingSideBySide.Left>
          <OnboardingSideBySide.LeftPadding>
            <ConnectYourAccountsView className="mx-auto mt-8 max-w-xl @xl:mt-24 @3xl:mt-44">
              <ConnectYourAccountsView.BanksAndCardsIconTile />
              <ConnectYourAccountsView.Heading />
              <ConnectYourAccountsView.YouWillGetCard />
              <ConnectYourAccountsView.Footer>
                <ButtonLink variant="ghost" to={onboardingRoutes["/onboarding/verify-business"]}>
                  Finish later
                </ButtonLink>
                <ButtonLink variant="primary" to={onboardingRoutes["/onboarding/connect-accounts"]}>
                  Connect accounts
                </ButtonLink>
              </ConnectYourAccountsView.Footer>
            </ConnectYourAccountsView>
          </OnboardingSideBySide.LeftPadding>
        </OnboardingSideBySide.Left>

        <OnboardingSideBySide.Right>
          <div className="mx-auto mt-24 max-w-md @xs:px-4 @xl:mt-12 @xl:max-w-xl @3xl:mt-24 @3xl:max-w-2xl">
            <img src={aiChatChartExample} alt="AI chat chart example" className="h-full w-full" />
          </div>
        </OnboardingSideBySide.Right>
      </OnboardingSideBySide>
    </>
  );
};

export default ConnectAccountsExplainerPage;
