import { DotsThreeCircle } from "@phosphor-icons/react";
import { FC } from "react";
import { Paragraph } from "ui/typography";

import BaseAiTaskModal, { BaseAiTaskModalProps } from "./BaseAiTaskModal";

type Props = Omit<BaseAiTaskModalProps, "variant">;

const EditPendingModal: FC<Props> = ({
  isOpen,
  onClose,
  title,
  onConfirm,
  onCancel,
  className,
  children,
  task,
}) => {
  const config = {
    title: title || "Setup in progress",
    confirmButtonText: "",
    confirmButtonVariant: "primary" as const,
    confirmButtonClassName: "",
    cancelButtonText: "Close",
  };

  return (
    <BaseAiTaskModal
      isOpen={isOpen}
      onClose={onClose}
      title={title || config.title}
      config={config}
      onConfirm={onConfirm}
      onCancel={onCancel}
      className={className}
      onConfirmClick={() => {}}
      task={task}
    >
      <div className="mb-6">
        <div className="mb-4 flex items-center gap-3">
          <DotsThreeCircle size={24} />
          <Paragraph className="text-base px-2 font-[500]">Edit pending</Paragraph>
        </div>
        <Paragraph className="text-s mt-4 flex font-[400]">
          Our team will review your edits and make sure the AI updates your task correctly. The task
          will remain on in the meantime.
        </Paragraph>
        <Paragraph className="text-s mt-4 flex font-[400]">
          Reviews typically last 1 business day or less!
        </Paragraph>
      </div>
      {children}
    </BaseAiTaskModal>
  );
};

export default EditPendingModal;
