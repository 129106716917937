import { ApiBuilder, useBackendV1Api } from "modules/highbeam-api/base";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";

type CapitalAccountApi = {
  getAll: (businessGuid: string) => Promise<CapitalAccountRep.Complete[]>;
  update: (
    businessGuid: string,
    capitalAccountGuid: string,
    update: CapitalAccountRep.Update
  ) => Promise<CapitalAccountRep.Complete | null>;
  updateRepaymentAccount: (
    businessGuid: string,
    capitalAccountGuid: string,
    update: CapitalAccountRep.UpdateRepaymentAccount
  ) => Promise<CapitalAccountRep.Complete | null>;
  getSummary: (
    businessGuid: string,
    capitalAccountGuid: string
  ) => Promise<CapitalAccountSummaryRep.Complete>;
};

const builder: ApiBuilder<CapitalAccountApi> = (api) => ({
  getAll: async (businessGuid) => {
    const qp = new URLSearchParams({ businessGuid });
    const url = `/capital-accounts?${qp}`;
    return (await api.get<CapitalAccountRep.Complete[]>(url))!;
  },
  update: async (businessGuid, capitalAccountGuid, update) => {
    const qp = new URLSearchParams({ businessGuid });
    const url = `/capital-accounts/${capitalAccountGuid}?${qp}`;
    return (await api.patch<CapitalAccountRep.Complete>(url, update))!;
  },
  updateRepaymentAccount: async (businessGuid, capitalAccountGuid, update) => {
    const qp = new URLSearchParams({ businessGuid });
    const url = `/capital-accounts/${capitalAccountGuid}/details/repayment-account?${qp}`;
    return (await api.patch<CapitalAccountRep.Complete>(url, update))!;
  },
  getSummary: async (businessGuid, capitalAccountGuid) => {
    const qp = new URLSearchParams({ businessGuid });
    const url = `/capital-accounts/${capitalAccountGuid}/summary?${qp}`;
    return (await api.get<CapitalAccountSummaryRep.Complete>(url))!;
  },
});

const useCapitalAccountApi = () => useBackendV1Api(builder);

export default useCapitalAccountApi;
