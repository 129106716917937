import { useUnitApiOrThrow } from "modules/unit-co-customer-token/queries/useUnitApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

type Params = {
  authorizationId: string;
  includeNonAuthorized?: boolean;
};

const cardAuthorizationQueryHooks = makeQueryHooks({
  name: "cardAuthorization",
  useQueryVariables: (params: Params) => {
    return {
      authorizationId: params.authorizationId,
      includeNonAuthorized: params.includeNonAuthorized,
    };
  },
  useQueryFnMaker: ({ authorizationId, includeNonAuthorized = true }) => {
    const unitApi = useUnitApiOrThrow();

    return async () => {
      return unitApi.authorizations.get(authorizationId, includeNonAuthorized);
    };
  },
});

export default cardAuthorizationQueryHooks;
