import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import useNewCreditApplication from "modules/credit-application/queries/useNewCreditApplication";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import CreditApplicationDocumentRep from "reps/CreditApplicationDocumentRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import uploadFile from "utils/uploadFile";

export type CreateCreditApplicationDocumentArguments = {
  file: File;
  type: CreditApplicationDocumentRep.DocumentType;
  notes: string | null;
};

type Options = UseMutationOptions<
  CreditApplicationDocumentRep.Complete | undefined,
  Error,
  CreateCreditApplicationDocumentArguments,
  undefined
>;

const useUploadCreditApplicationDocument = (options?: Options) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const newCreditApplication = useNewCreditApplication();
  const creditApplicationGuid = newCreditApplication.guid;

  // NB(alex): please consult #eng-frontend before using react-query
  return useMutation({
    mutationFn: async ({ file, notes, type }) => {
      const createDocumentResponse = await highbeamApi.creditApplication.createDocument(
        businessGuid,
        creditApplicationGuid,
        {
          notes,
          type,
          fileName: file.name,
        }
      );
      if (createDocumentResponse) {
        const { signedUploadUrl } = createDocumentResponse;
        const uploadResponse = await uploadFile(file, signedUploadUrl);
        if (uploadResponse) {
          return createDocumentResponse;
        }
      }
      return undefined;
    },
    ...options,
  });
};

export default useUploadCreditApplicationDocument;
