import { FC } from "react";
import { useSearchParams } from "react-router-dom";

import { DEFAULT_PAGE_PARAM_KEY } from "./config";
import Pagination from "./Pagination";

const makeLinkHref = (page: number, pageParamKey: string, currentSearchParams: URLSearchParams) => {
  // Preserve the current search params in the href (besides the page param).
  const newSearchParams = new URLSearchParams(currentSearchParams);
  newSearchParams.set(pageParamKey, page.toString());
  return `?${newSearchParams.toString()}`;
};

const makeEllipsisKey = () => `ellipsis-${Math.random()}`;

// Random string values are used to represent ellipses.
type PageNumberItem = number | string;

const makePageNumberItems = (page: number, totalPages: number, spread = 2): PageNumberItem[] => {
  const pageNumbers: PageNumberItem[] = [];

  const startPage = page - spread;
  const endPage = page + spread;

  if (startPage > 1) {
    if (startPage > 2) {
      pageNumbers.push(1, makeEllipsisKey());
    } else {
      pageNumbers.push(1);
    }
  }

  // eslint-disable-next-line functional/no-let
  for (let n = startPage; n <= endPage; n++) {
    if (n > 0 && n <= totalPages) {
      pageNumbers.push(n);
    }
  }

  if (endPage < totalPages) {
    if (endPage < totalPages - 1) {
      pageNumbers.push(makeEllipsisKey(), totalPages);
    } else {
      pageNumbers.push(totalPages);
    }
  }

  return pageNumbers;
};

type Props = {
  page: number;
  totalPages: number;
  pageParamKey?: string;
};

const PaginationGenerator: FC<Props> = ({
  page,
  totalPages,
  pageParamKey = DEFAULT_PAGE_PARAM_KEY,
}) => {
  const previousPage = page - 1;
  const nextPage = page + 1;
  const isFirstPage = page === 1;
  const isLastPage = page === totalPages;
  const pageNumberItems = makePageNumberItems(page, totalPages);
  const [searchParams] = useSearchParams();

  return (
    <Pagination>
      {!isFirstPage && (
        <Pagination.PreviousPageLink to={makeLinkHref(previousPage, pageParamKey, searchParams)} />
      )}

      {pageNumberItems.map((pageNumberItem) => {
        if (typeof pageNumberItem === "string") {
          return <Pagination.Ellipsis key={pageNumberItem} />;
        }

        return (
          <Pagination.PageLink
            key={pageNumberItem}
            to={makeLinkHref(pageNumberItem, pageParamKey, searchParams)}
            isActive={pageNumberItem === page}
          >
            {pageNumberItem}
          </Pagination.PageLink>
        );
      })}

      {!isLastPage && (
        <Pagination.NextPageLink to={makeLinkHref(nextPage, pageParamKey, searchParams)} />
      )}
    </Pagination>
  );
};

export default PaginationGenerator;
