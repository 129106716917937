import { CaretDown } from "@phosphor-icons/react";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { useMotionSafe } from "utils/device/useMediaQuery";
import cn from "utils/tailwind/cn";

const TRANSITION_DURATION = 150;

type Props = {
  heading: ReactNode;
  children: ReactNode;
};

const CollapsibleAccordion: FC<Props> = ({ heading, children }) => {
  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [shouldRenderContent, setShouldRenderContent] = useState(isOpen);

  const motionSafe = useMotionSafe();

  const toggleIsOpen = () => {
    setIsOpen((currVal) => !currVal);
  };

  /**
   * It's not possible to do vertical animations in CSS,
   * so we do some magic in the [useEffect] to dynamically set the height to a specific value.
   */
  useEffect(() => {
    const ref = contentWrapperRef.current;
    if (!ref) return;
    if (isOpen) {
      ref.style.height = `${ref.scrollHeight}px`;
      setShouldRenderContent(true);
      setTimeout(
        () => {
          ref.style.height = "auto";
        },
        motionSafe ? TRANSITION_DURATION : 0
      );
    } else {
      ref.style.height = `${ref.scrollHeight}px`;
      setTimeout(() => {
        ref.style.height = "0";
      }, 0);

      setTimeout(
        () => {
          setShouldRenderContent(false);
        },
        motionSafe ? TRANSITION_DURATION : 0
      );
    }
  }, [isOpen, motionSafe]);

  return (
    <div
      className={cn(
        "flex flex-col overflow-hidden border-b border-b-grey-100 px-0 py-2 last-of-type:border-b-0",
        isOpen && "overflow-visible"
      )}
    >
      <button
        onClick={toggleIsOpen}
        className="flex items-center justify-between px-0 py-3 text-sm text-grey-600"
      >
        {heading}

        <CaretDown
          size={16}
          className={cn(
            "shrink-0 text-grey-500 motion-safe:duration-150",
            isOpen ? "-rotate-180" : "rotate-0"
          )}
        />
      </button>
      <div className="motion-safe:duration-150" ref={contentWrapperRef}>
        <div className={cn("pb-8 pl-0 pr-8 pt-2", !shouldRenderContent && "invisible")}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default CollapsibleAccordion;
