import { useUnitApiOrThrow } from "modules/unit-co-customer-token/queries/useUnitApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

import { ROOT_CARDS_QUERY_NAME } from "./useCards";

const useCardPinStatusQueryOptions = (cardId: string) => {
  const unitApi = useUnitApiOrThrow();

  return useQueryOptions({
    queryKey: [ROOT_CARDS_QUERY_NAME, cardId, "pin-status"],
    queryFn: () => {
      return unitApi.cards.getPinStatus(cardId);
    },
  });
};

export default useCardPinStatusQueryOptions;
