import { Statement } from "@highbeam/unit-node-sdk";
import { DownloadSimple } from "@phosphor-icons/react";
import emptyImg from "assets/empty-state.svg";
import env from "env";
import { BankAccountBarByUnitCoDepositAccountId } from "modules/bank-accounts/components/BankAccountBar";
import { useBankAccountsByUnitAccountIdMap } from "modules/bank-accounts/queries/useBankAccounts";
import useBusiness from "modules/business/queries/useBusiness";
import { useUnitCoCustomerTokenOrThrow } from "modules/unit-co-customer-token/queries/useUnitCoCustomerToken";
import { unitStatementsQueryHooks } from "modules/unit-co-statements/queries/useUnitStatements";
import formatStatementPeriod from "modules/unit-co-statements/utils/formatStatementPeriod";
import { useState } from "react";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import Button from "ui/inputs/Button";
import NotFoundCell from "ui/table/cells/NotFoundCell";
import EmptyState from "ui/table/EmptyState";
import Table, { TableColumnAlignment } from "ui/table/Table";
import Text from "ui/typography/Text";
import { downloadBlob } from "utils/download";

import styles from "./AccountsStatements.module.scss";

type Props = {
  accountId?: string;
};

const getStatementFileName = (statement: Statement, accountNumberLast4Digits: string) =>
  `highbeam-statement-${accountNumberLast4Digits}-${statement.attributes.period}.pdf`;

const AccountStatements: React.FC<Props> = ({ accountId }) => {
  const { unitCoCustomerId: customerId } = useBusiness();
  const { data, isLoading } = unitStatementsQueryHooks.useQuery({
    params: {
      customerId: customerId ?? undefined,
      accountId,
    },
  });

  const statements = data?.data ?? [];

  const unitCoCustomerToken = useUnitCoCustomerTokenOrThrow();
  const [downloadingStatementId, setDownloadingStatementId] = useState<string | null>();

  const bankAccountsByUnitAccountIdMap = useBankAccountsByUnitAccountIdMap();

  const downloadStatement = async (statement: Statement) => {
    setDownloadingStatementId(statement.id);

    // TODO(alex): Figure out how to access ensureQueryData (makeQueryHooksV2)
    const bankAccount = bankAccountsByUnitAccountIdMap.get(statement.relationships.account.data.id);

    const accountNumberLast4Digits = bankAccount?.accountNumber.slice(-4) ?? "";

    // TODO: Use Unit SDK
    const res = await fetch(`${env.UNIT_API_ORIGIN}/statements/${statement.id}/pdf`, {
      headers: {
        Authorization: `Bearer ${unitCoCustomerToken}`,
      },
    });
    const blob = await res.blob();
    downloadBlob(
      getStatementFileName(statement, accountNumberLast4Digits),
      window.URL.createObjectURL(blob)
    );

    setDownloadingStatementId(null);
  };

  if (!isLoading && statements.length === 0)
    return (
      <EmptyState
        image={emptyImg}
        primaryText="No statements available."
        secondaryText="Once you have statements, they will be shown here."
      />
    );

  return (
    <Table
      rowKey="id"
      isLoading={isLoading}
      className={styles.table}
      columns={[
        {
          title: "Period",
          cellRender: (statement: Statement) => (
            <Text size={14}>{formatStatementPeriod(statement.attributes.period)}</Text>
          ),
        },
        {
          title: "Account",
          cellRender: (statement) => {
            return (
              <BankAccountBarByUnitCoDepositAccountId
                unitCoDepositAccountId={statement.relationships.account.data.id}
                notFoundFallback={<NotFoundCell />}
              />
            );
          },
        },
        {
          title: "Action",
          align: TableColumnAlignment.RIGHT,
          cellClassName: styles.action,
          cellRender: (statement: Statement) => {
            const isDownloading = downloadingStatementId === statement.id;
            return (
              <Button
                size="sm"
                variant="tertiary"
                onClick={() => downloadStatement(statement)}
                disabled={isDownloading}
              >
                {isDownloading ? <AnimatedSpinner /> : <DownloadSimple size={14} />}
                Download
              </Button>
            );
          },
        },
      ]}
      data={statements}
    />
  );
};

export default AccountStatements;
