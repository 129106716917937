import useExchangeApi from "modules/chat/api/useExchangeApi";
import { useMemo } from "react";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

type Params = {
  channelId: string;
  parentExchangeId: string;
};

const chatThreadExchangesQueryHooks = makeQueryHooks({
  name: "chatThreadExchanges",
  useQueryVariables: (params: Params) => {
    return params;
  },
  useQueryFnMaker: ({ channelId, parentExchangeId }) => {
    const exchangeApi = useExchangeApi();
    return () => exchangeApi.searchThread(channelId, parentExchangeId);
  },
});

export const useChatThreadExchanges = (params: Params) => {
  const exchanges = chatThreadExchangesQueryHooks.useData({ ...params, refetchInterval: 1000 });
  return useMemo(() => exchanges.toReversed(), [exchanges]);
};

export const useRefreshChatThreadExchanges = () => {
  return chatThreadExchangesQueryHooks.useRefreshQueries();
};
