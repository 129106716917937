import { CaretRight } from "@phosphor-icons/react";
import useCreateChatChannelExchangeMutation from "modules/chat/mutations/useCreateChatChannelExchangeMutation";
import {
  chatChannelsQueryHooks,
  getMvpFirstChatChannel,
} from "modules/chat/queries/useChatChannels";
import { useCurrentChatUserQuery } from "modules/chat/queries/useChatUsers";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Span } from "ui/typography";

import { useCommandPaletteContext } from "../context";

import Cmdk from "./cmdk";

const AskAiCommandItem: FC = () => {
  const { data: currentChatUser } = useCurrentChatUserQuery();
  const navigate = useNavigate();
  const { search, cleanup } = useCommandPaletteContext();

  const { data: mvpChatChannel } = chatChannelsQueryHooks.useQuery({
    params: { chatUserId: currentChatUser?.id! },
    enabled: Boolean(currentChatUser),
    select: getMvpFirstChatChannel,
  });

  const { mutateAsync: createChatChannelExchange, isPending } =
    useCreateChatChannelExchangeMutation({
      onSuccess: (data) => {
        navigate(`/ai/${data.channelId}`);
      },
    });

  if (!mvpChatChannel || !search) {
    return null;
  }

  return (
    <Cmdk.Item
      onSelect={() => {
        createChatChannelExchange({
          type: "UserPlaintext",
          channelId: mvpChatChannel.id,
          parentId: null,
          content: search,
        });
        cleanup();
      }}
      disabled={isPending}
      className="flex cursor-pointer items-center gap-x-4 border-b border-grey-100 p-3 last:border-b-0 hover:bg-grey-50 data-[selected=true]:bg-grey-50"
    >
      <Span className="text-grey-600">Ask AI</Span>
      <CaretRight size={16} weight="light" className="text-grey-400" />
      <Span className="font-medium text-grey-900">{search}</Span>
    </Cmdk.Item>
  );
};

export default AskAiCommandItem;
