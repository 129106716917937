import useHasPermission from "../useHasPermission";

const useIsAllowedToNavigateToCardsRoutes = () => {
  const isAllowedToReadCards = useHasPermission("card:read");
  const isAllowedToReadOwnCards = useHasPermission("card:readOwn");
  const isAllowedToReadAnyCards = useHasPermission("card:readAny");
  return isAllowedToReadCards || isAllowedToReadOwnCards || isAllowedToReadAnyCards;
};

export default useIsAllowedToNavigateToCardsRoutes;
