import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";
import AssignedBillStatsRep from "reps/AssignedBillStatsRep";

type AssignedBillStatsApi = {
  getByBusinessMember: (
    businessGuid: string,
    businessMemberGuid: string
  ) => Promise<AssignedBillStatsRep>;
};

const builder: ApiBuilder<AssignedBillStatsApi> = (api) => ({
  getByBusinessMember: async (businessGuid, businessMemberGuid) => {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
      businessMemberGuid: businessMemberGuid,
    });
    const url = `/accounts-payable/assigned-bill-stats?${qp}`;
    return (await api.get<AssignedBillStatsRep>(url))!;
  },
});

const useAssignedBillStatsApi = () => useBackendV2Api(builder);

export default useAssignedBillStatsApi;
