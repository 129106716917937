import BusinessMemberRep from "reps/BusinessMemberRep";
import { notify } from "ui/feedback/Toast";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import useBusinessMemberApi from "../api/useBusinessMemberApi";
import { useRefreshAllBusinessMembersQueries } from "../queries/businessMembersQueryHooks";

type Variables = {
  businessGuid: string;
  memberGuid: string;
};

const useRemoveBusinessMemberMutation = (
  additionalOptions?: MutationAdditionalOptions<BusinessMemberRep.Complete, Variables>
) => {
  const businessMemberApi = useBusinessMemberApi();
  const refreshAllBusinessMembersQueries = useRefreshAllBusinessMembersQueries();
  const { segmentTrack } = useSegment();

  return useMutationWithDefaults(
    {
      mutationFn: async ({ businessGuid, memberGuid }: Variables) => {
        return businessMemberApi.delete(businessGuid, memberGuid);
      },
      onError: () => {
        notify("error", "Failed to remove user");
      },
      onSuccess: async () => {
        await refreshAllBusinessMembersQueries();
        notify("success", "User removed");
        segmentTrack(SEGMENT_EVENTS.BUSINESS_MEMBER_REMOVED);
      },
    },
    additionalOptions || {}
  );
};

export default useRemoveBusinessMemberMutation;
