import { FC } from "react";
import Drawer from "ui/overlay/Drawer";
import { useIsMobile } from "utils/device/useMediaQuery";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

import { useSidePanelContext } from "./context";
import SidePanelPanelBlock from "./SidePanelPanelBlock";

const SidePanelPanel: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  const isMobile = useIsMobile();
  const { isOpen, onOpenToggle, side } = useSidePanelContext();

  if (isMobile) {
    return (
      <Drawer direction={side} open={isOpen} onOpenChange={onOpenToggle}>
        <Drawer.Content className={cn("w-full", className)}>{children}</Drawer.Content>
      </Drawer>
    );
  } else {
    return <SidePanelPanelBlock className={className}>{children}</SidePanelPanelBlock>;
  }
};

export default SidePanelPanel;
