import {
  Airplane,
  ArrowsLeftRight,
  ArrowUUpLeft,
  Bank,
  Briefcase,
  Buildings,
  Calculator,
  Camera,
  Clipboard,
  Cloud,
  CreditCard,
  CurrencyCircleDollar,
  CurrencyDollar,
  DotsThreeCircle,
  FileText,
  Gift,
  Globe,
  GlobeHemisphereWest,
  Hammer,
  Handshake,
  Heart,
  House,
  Icon,
  IconProps,
  Medal,
  Megaphone,
  Money,
  Package,
  Plug,
  Question,
  Receipt,
  Scales,
  ShieldCheck,
  ShoppingCart,
  Storefront,
  Table as TableIcon,
  Toolbox,
  Truck,
  UserCircle,
  Users,
  Warehouse,
  Wrench,
} from "@phosphor-icons/react";
import { InsightsCategory } from "modules/insights/reps/InsightsCategoryRep";
import { FC } from "react";

const mapCategoryToIcon: { [key in InsightsCategory]: Icon } = {
  SalesRetail: ShoppingCart,
  SalesWebsite: Globe,
  SalesWholesale: Storefront,
  SalesReturns: ArrowUUpLeft,
  CashManagementBankInterest: Bank,
  CashManagementRewards: Medal,
  MarketingAgencyFee: Briefcase,
  MarketingPaidMedia: Megaphone,
  MarketingPhotographyAndAssets: Camera,
  MarketingPromotions: Gift,
  FinanceFeesBankFees: CurrencyDollar,
  FinanceFeesMerchantFees: CreditCard,
  ProductionAndDevelopmentProductSuppliers: Package,
  ProductionAndDevelopmentOther: Toolbox,
  ProductionAndDevelopmentThirdPartyInventory: Warehouse,
  FulfillmentShipping: Truck,
  FulfillmentOther: Clipboard,
  PeopleBenefits: Heart,
  PeopleContractors: Handshake,
  PeoplePayroll: Users,
  PeoplePayrollTaxes: Receipt,
  RentAndUtilitiesRent: House,
  RentAndUtilitiesOther: Buildings,
  RentAndUtilitiesUtilities: Plug,
  RetailStorefrontExpenses: Storefront,
  ProfessionalServicesAccountingAndFinance: Calculator,
  ProfessionalServicesConsultants: UserCircle,
  ProfessionalServicesRepairsAndMaintenance: Wrench,
  ProfessionalServicesLegal: Scales,
  OtherConsignmentPayouts: CurrencyCircleDollar,
  OtherEquipmentAndSupplies: Hammer,
  OtherInsurance: ShieldCheck,
  OtherSoftwareAndWebHosting: Cloud,
  OtherTeamExpenses: Users,
  OtherTravel: Airplane,
  OtherOther: DotsThreeCircle,
  TaxesCustoms: GlobeHemisphereWest,
  TaxesIncomeTax: FileText,
  TaxesSalesTax: FileText,
  FinancingCreditCards: CreditCard,
  FinancingCreditCardInterest: CurrencyDollar,
  FinancingLoan: Money,
  FinancingEquityInvestment: Money,
  TransfersInternalTransfer: ArrowsLeftRight,
  UncategorizedApPlatform: TableIcon,
  Uncategorized: Question,
};

const getInsightsCategoryIcon = (category: InsightsCategory) => {
  return mapCategoryToIcon[category];
};

type Props = IconProps & {
  category: InsightsCategory;
};

const InsightsCategoryIcon: FC<Props> = ({ category, ...props }) => {
  const Icon = getInsightsCategoryIcon(category);
  return <Icon {...props} />;
};

export { mapCategoryToIcon };

export default InsightsCategoryIcon;
