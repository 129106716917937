import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";

import usePayeeClassCodeApi from "../api/usePayeeClassCodeApi";

const ROOT_QUERY_KEY = "payee-class-code";

type Params = {
  payeeGuid?: string;
};

const payeeClassCodeQueryHooks = makeQueryHooksV2({
  makeQueryKey: (params: Params) => {
    return [ROOT_QUERY_KEY, params] as const;
  },
  useQueryFnParams: () => {
    const payeeClassCodeApi = usePayeeClassCodeApi();
    return { payeeClassCodeApi };
  },
  makeQueryFn: ({ payeeClassCodeApi }, { payeeGuid }) => {
    return () => (payeeGuid ? payeeClassCodeApi.getByPayeeGuid(payeeGuid) : null);
  },
});

const usePayeeClassCode = (payeeGuid?: string) =>
  payeeClassCodeQueryHooks.useData({ params: { payeeGuid } });

export default payeeClassCodeQueryHooks;
export { usePayeeClassCode };
