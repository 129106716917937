import {
  ROOT_UNIT_AUTHORIZATIONS_QUERY_KEY,
  unitAuthorizationsQueryHooks,
} from "modules/unit/queries/useUnitAuthorizations";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";

const DEFAULT_AUTHORIZATIONS_PAGE_SIZE = 10;

type Params = {
  accountType?: "deposit" | "credit";
  page: number;
  pageSize: number;
};

const cardAuthorizationsQueryHooks = makeQueryHooksV2({
  useQueryFnParams: () => {
    const fetchUnitAuthorizations = unitAuthorizationsQueryHooks.useFetchQuery();
    return { fetchUnitAuthorizations };
  },
  useDefaultParams: () => {
    return {
      pageSize: DEFAULT_AUTHORIZATIONS_PAGE_SIZE,
    };
  },
  makeQueryKey: (params: Params) => {
    return [ROOT_UNIT_AUTHORIZATIONS_QUERY_KEY, "cardAuthorizations", params] as const;
  },
  makeQueryFn: ({ fetchUnitAuthorizations }, { page, pageSize, accountType }) => {
    return () => {
      return fetchUnitAuthorizations({
        status: "Authorized",
        offset: pageSize * (page - 1),
        limit: pageSize,
        includeNonAuthorized: true,
        ...(accountType ? { filter: { accountType } } : {}),
      });
    };
  },
});

export { DEFAULT_AUTHORIZATIONS_PAGE_SIZE, cardAuthorizationsQueryHooks };
